import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Text, ProcessingSpinner } from '../'
import { Styles } from './Styles'
import { Metrics, useColors } from '../../Themes'

const _renderButton = ({
  id,
  bold,
  type,
  title,
  image,
  textSize,
  primary,
  onClick,
  fetching,
  disabled,
  icon,
  iconCenter,
  iconRight,
  iconRightSize,
  iconLeftSize,
  background,
  backgroundHover,
  gradientColor,
  border,
  textColor,
  center,
  radius,
  rounded,
  expand,
  condensed,
  formNoValidate,
  style,
  noUpperCase,
  variant
}) => {
  const Colors = useColors()
  const backgroundColorHover = backgroundHover || Colors.transparent
  const borderColor = gradientColor
    ? Colors.transparent
    : border || background || Colors.primary
  const borderRadius = radius
    ? Metrics.radius * radius || Metrics.radius
    : rounded
    ? '100%'
    : null
  const backgroundColor =
    variant === 'text'
      ? 'transparent'
      : primary
      ? background
        ? background
        : Colors.primary
      : Colors.backgroundOpacity2
  const text = disabled ? Colors.grey3 : primary ? textColor : Colors.secondary
  const buttonTextSize = textSize || 'button'
  const styles = Styles({
    Colors,
    Metrics,
    primary,
    background,
    backgroundColor,
    backgroundColorHover,
    borderColor,
    condensed
  })
  // console.log(Colors.lightTheme)
  return (
    <button
      id={id}
      type={type || 'button'}
      onClick={onClick}
      disabled={fetching || disabled}
      formNoValidate={formNoValidate || null}
      className={css(
        styles.button,
        disabled || fetching ? styles.disabled : null
      )}
      style={{
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: primary
        //   ? backgroundColor
        //   : Colors.background
        height: Metrics.base * 5,
        cursor: 'pointer',
        minWidth: Metrics.base,
        maxWidth: Metrics.base * 40,
        // borderRadius: 100,
        width: expand ? '100%' : null,
        margin: center ? 'auto' : null,
        // border: primary || !background ? 'none' : `1px solid ${Colors.primary}`,
        // ':enabled': {
        //   backgroundColor: primary
        //     ? backgroundColor
        //     : Colors.background
        //   border:
        //     primary || !background ? 'none' : `1px solid ${Colors.primary}`
        // },
        // ':focus': {
        //  outline: 0
        // },
        ...style
      }}
    >
      {fetching ? (
        <ProcessingSpinner color={text} />
      ) : (
        [
          icon ? (
            <Icon
              key={1}
              size={iconLeftSize || 'medium'}
              name={icon}
              color={text}
              style={styles.icon}
            />
          ) : image && !icon ? (
            <img key={1} alt='Profile' src={image} style={styles.image} />
          ) : iconCenter ? (
            <Icon
              key={1}
              name={iconCenter}
              color={text}
              style={styles.iconCenter}
            />
          ) : null,
          bold ? (
            <Text
              bold
              key={2}
              font={buttonTextSize}
              disabled={disabled}
              color={text}
            >
              {title.toUpperCase()}
            </Text>
          ) : noUpperCase ? (
            <Text
              key={2}
              font={buttonTextSize}
              disabled={disabled}
              color={text}
            >
              {title}
            </Text>
          ) : (
            <Text
              key={2}
              font={buttonTextSize}
              disabled={disabled}
              color={text}
            >
              {title.toUpperCase()}
            </Text>
          )
        ]
      )}
      {iconRight ? (
        <Icon
          key={1}
          size={iconRightSize || 'medium'}
          name={iconRight}
          color={text}
          style={styles.iconRight}
        />
      ) : null}
    </button>
  )
}

const Button = (props) => {
  const { link, aRef, base64, download, hide } = props
  if (link) {
    return <Link to={link}>{_renderButton(props)}</Link>
  } else if (aRef) {
    return (
      <a href={aRef} download={download}>
        {_renderButton(props)}
      </a>
    )
  } else if (base64) {
    return (
      <a href={`data:application/pdf;base64,${base64}`} download={download}>
        {_renderButton(props)}
      </a>
    )
  } else if (hide) {
    return null
  } else {
    return _renderButton(props)
  }
}

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string,
  center: PropTypes.bool,
  radius: PropTypes.bool,
  rounded: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}

export default Button
