import React, { useState } from 'react'
import { Metrics, useColors } from '../../Themes'
import {
  Button,
  FlexBox,
  Status,
  Subsection,
  ProcessingSpinner
} from '../../Components'
import { Heading, Title } from '../../Components/Typography'
import LargeDivider from '../../Components/LargeDivider'
import {
  selectedAssetId,
  selectedDriverId,
  selectedEventId,
  selectedTripId
} from '../../Graphql/GraphQLClient'
import TripCard from '../../Components/Custom/SelectionPanel/Trip/TripCard'
import DriverCard from '../../Components/Custom/SelectionPanel/Driver/DriverCard'
import AssetCard from '../../Components/Custom/SelectionPanel/Asset/AssetCard'
import NoneSelected from '../../Components/Custom/NoneSelected'
import { checkVal } from '../../Helpers/Functions'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useHistory, useLocation } from 'react-router-dom'
import { useCreateCoachingEvent } from '../../Graphql/Mutations/useCreateCoachingEvent'
import AssignEventToCoachingModal from './AssignEventToCoachingModal'
import { InsetCardContainer } from '../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import { useNavigation } from '../../Components/CustomHooks/useNavigation'
import { useReactiveVar } from '@apollo/client'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'

const handleAddToDriverCoaching = async ({
  driverId,
  eventId,
  createCoachingEvent
}) => {
  const createCoachingEventVariables = {
    driverId,
    eventId
  }
  await createCoachingEvent({
    variables: createCoachingEventVariables,
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (data) => {}
  })
}

const SidePanel = ({ eventId }) => {
  const Colors = useColors()
  const { data: event } = useEvent(eventId)
  const { mainContainerHeight } = useAppDimensions()
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const [createCoachingEvent, { createCoachingEventLoading }] =
    useCreateCoachingEvent()
  const [showCoachingEventModal, setShowCoachingEventModal] = useState(false)
  const navigateTo = useNavigation()

  return (
    <FlexBox
      style={{
        width: Metrics.sideBarWidth,
        height: mainContainerHeight,
        padding: Metrics.base * 2,
        backgroundColor: 'white',
        borderLeft: `1px solid ${Colors.dividerColor}`
      }}
    >
      {checkVal(eventId) ? (
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          {event && (
            <FlexBox
              style={{
                width: '100%',
                paddingTop: Metrics.base * 2,
                paddingBottom: Metrics.base * 2
              }}
            >
              <InsetCardContainer name='Linked Trip'>
                <TripCard
                  tripId={event.tripId}
                  onClick={() => [
                    selectedTripId(event.tripId),
                    navigateTo({
                      pathname: '/trip-history/map-view/events',
                      queryParams: [{ name: 'trip', id: event.tripId }]
                    })
                  ]}
                />
              </InsetCardContainer>
            </FlexBox>
          )}
          {/*<FlexBox
            direction='column'
            style={{
              width: '100%',
              alignItems: 'flex-start',
              padding: Metrics.base * 2
            }}
          >
            <Button
              condensed
              title='View In Trip History'
              onClick={() => {
                if (event && event.trip) {
                  selectedTripId(event.trip._id),
                  history.push('/trip-history')
                }
              }}
              textColor='white'
              background='red'
              style={{ marginBottom: Metrics.base * 2 }}
            />
            {createCoachingEventLoading ? (
              <ProcessingSpinner />
            ) : (
              <Button
                condensed
                title='Add to Coaching Material'
                onClick={() => {
                  if (event && event.driver) {
                    handleAddToDriverCoaching({
                      driverId: event.driver._id,
                      eventId,
                      createCoachingEvent
                    })
                    // setShowCoachingEventModal(true)
                    //show modal with table of drivers and add event to coaching
                  }
                }}
                disabled={event && event.driver ? false : true}
                textColor='white'
                background='red'
                style={{ marginRight: Metrics.base * 2 }}
              />
            )}
              </FlexBox>*/}
          {/* The modal below is for if we want to let events be assigned to any driver - not only the designated one */}
          {/* {showCoachingEventModal && (
            <AssignEventToCoachingModal
              setShowCoachingEventModal={setShowCoachingEventModal}
            />
          )} */}
          {event && (
            <FlexBox
              style={{
                width: '100%',
                paddingTop: Metrics.base * 2,
                paddingBottom: Metrics.base * 2
              }}
            >
              <InsetCardContainer name='Linked Driver'>
                <DriverCard
                  driverId={event.driver ? event.driver._id : null}
                  navigateTo={navigateTo}
                  onClick={() => [
                    selectedDriverId(event.driver._id),
                    navigateTo({
                      pathname: '/fleet-management/drivers',
                      queryParams: [{ name: 'diver', id: event.driver._id }]
                    })
                  ]}
                />
              </InsetCardContainer>
            </FlexBox>
          )}
          {event && (
            <FlexBox
              style={{
                width: '100%',
                paddingTop: Metrics.base * 2,
                paddingBottom: Metrics.base * 2
              }}
            >
              <InsetCardContainer name='Linked Asset'>
                <AssetCard
                  assetId={event.asset ? event.asset._id : null}
                  navigateTo={navigateTo}
                  onClick={() => [
                    selectedAssetId(event.asset._id),
                    navigateTo({
                      pathname: '/fleet-management/assets',
                      queryParams: [{ name: 'asset', id: event.asset._id }]
                    })
                  ]}
                />
              </InsetCardContainer>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <NoneSelected helperText='Select an event from the list on the left' />
      )}
    </FlexBox>
  )
}

export default SidePanel
