import React, { useState, useEffect } from 'react'
import {
  Text,
  FlexBox,
  Subheading,
  MaterialInput,
  Button,
  Icon,
  Form,
  ModalAlert
} from '../../../'
import { Metrics, useColors } from '../../../../Themes'
import { Dialog, Radio } from '@material-ui/core'
import {
  eventFilters,
  filters,
  selectedEventId,
  selectedOtherEventId,
  selectedTripTotalEventId
} from '../../../../Graphql/GraphQLClient'
import { useFilters } from '../../../../Graphql/Queries/useFilters'
import { useReactiveVar } from '@apollo/client'
import { checkVal } from '../../../../Helpers/Functions'
import { Link } from 'react-router-dom'
import DriverSelectionTable from '../Driver/DriverSelectionTable'
import Subsection from '../../../Subsection'
import { useAppDimensions } from '../../../../Graphql/Queries/useAppDimensions'
import DriverCard from '../Driver/DriverCard'
import { useHistory, useLocation } from 'react-router-dom'
import { InsetCardContainer } from '../Cards/CardsContainer'
import AssetCard from '../Asset/AssetCard'
import TripCard from '../Trip/TripCard'
import AssetSelectionTable from '../Asset/AssetSelectionTable'
import TripSelectionTable from '../Trip/TripSelectionTable'
import SearchBar from '../SearchBar'
import { useUser } from '../../../../Graphql/Queries/useUser'
import { useAssetGroups } from '../../../../Graphql/Queries/Asset/useAssetGroups'
// import { getStartOfDay, getEndOfDay } from '../../Helpers/Functions'

const eventTypeOptions = [
  { value: 'PERCENT_SPEEDING', label: 'Percent Speeding' },
  { value: 'TRIP_DISTANCE', label: 'Trip Distance' },
  { value: 'TRAVEL_TIME', label: 'Travel Time' },
  { value: 'DRIVING_TIME', label: 'Driving Time' },
  { value: 'CONTINUOUS_DRIVING', label: 'Continuous Driving' },
  { value: 'NIGHT_DRIVING', label: 'Night Driving' },
  { value: 'PHONE_MOTION', label: 'Phone Use' },
  { value: 'SPEEDING', label: 'Speeding' },
  { value: 'HARSH_BRAKING', label: 'Harsh Braking' },
  { value: 'HARSH_CORNERING', label: 'Harsh Cornering' },
  { value: 'HARSH_ACCELERATION', label: 'Harsh Acceleration' },
  { value: 'VEHICLE_INSPECTION', label: 'Vehicle Inspection' },
  { value: 'QUIZ_SCORE', label: 'Quiz Score' },
  { value: 'VEHICLE_CRASH', label: 'Crash' },
  { value: 'VEHICLE_INCIDENT', label: 'Incident' },
  { value: 'VEHICLE_SERVICE', label: 'Vehicle Service' }
]

export const tripTotalsOptions = [
  { value: 'PERCENT_SPEEDING', label: 'Percent Speeding' },
  { value: 'TRIP_DISTANCE', label: 'Trip Distance' },
  { value: 'TRAVEL_TIME', label: 'Travel Time' },
  { value: 'DRIVING_TIME', label: 'Driving Time' },
  { value: 'CONTINUOUS_DRIVING', label: 'Continuous Driving' },
  { value: 'NIGHT_DRIVING', label: 'Night Driving' }
]

export const inTripOptions = [
  { value: 'SPEEDING', label: 'Speeding' },
  { value: 'HARSH_BRAKING', label: 'Harsh Braking' },
  { value: 'HARSH_CORNERING', label: 'Harsh Cornering' },
  { value: 'HARSH_ACCELERATION', label: 'Harsh Acceleration' },
  { value: 'PHONE_MOTION', label: 'Phone Use' }
]

export const otherOptions = [
  { value: 'VEHICLE_INSPECTION', label: 'Vehicle Inspection' },
  { value: 'QUIZ_SCORE', label: 'Quiz Score' },
  { value: 'VEHICLE_CRASH', label: 'Crash' },
  { value: 'VEHICLE_INCIDENT', label: 'Incident' },
  { value: 'VEHICLE_SERVICE', label: 'Vehicle Service' }
]

export const checkArrs = (array1, array2) => {
  if (array1.sort().join(',') === array2.sort().join(',')) {
    return 0
  }
  return 1
}

export const getValCount = (str) => {
  return checkVal(str) ? 1 : 0
}

const filterByTypeNameOptions = [
  { value: 'driver', label: 'Driver' },
  { value: 'asset', label: 'Asset' },
  { value: 'trip', label: 'Trip' }
  // { value: 'device', label: 'Device' }
]

const zeroToTen = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' }
]

export const SelectScoreBetween = ({ value, handleChange }) => {
  return (
    <FlexBox direction='row' style={{ width: '100%' }}>
      <MaterialInput
        type='select'
        name='maxScore'
        label={`Min Score`}
        value={value.minScore}
        selectValues={zeroToTen.map((minScore) => minScore.value)}
        selectDisplayValues={zeroToTen.map((minScore) => minScore.label)}
        handleChange={(e) => {
          handleChange({
            ...value,
            minScore: e
          })
        }}
      />
      <Icon
        name='arrow_right_alt'
        style={{
          padding: '0px 8px'
        }}
        size='medium'
        // color={Colors.grey3}
      />
      <MaterialInput
        type='select'
        name='minScore'
        label={`Max Score`}
        value={value.maxScore}
        selectValues={zeroToTen
          .map((maxScore) => {
            if (value.minScore && maxScore.value > value.minScore) {
              return maxScore.value
            } else if (!value.minScore) {
              return maxScore.value
            }
            return null
          })
          .filter(Number)}
        selectDisplayValues={zeroToTen
          .map((maxScore) => {
            if (value.minScore && maxScore.value > value.minScore) {
              return maxScore.label
            } else if (!value.minScore) {
              return maxScore.label
            }
            return null
          })
          .filter(Number)}
        handleChange={(e) => {
          handleChange({
            ...value,
            maxScore: e
          })
        }}
      />
    </FlexBox>
  )
}

const getTypesFilter = (oldFilter, newFilter) => {
  if (checkArrs(oldFilter, newFilter) === 0) {
    return oldFilter
  }
  return newFilter
}

export const handleClearFilters = () => {
  const oldFilters = eventFilters()
  const inTripFilter = oldFilters.inTripTypes
  const tripTotalsFilter = oldFilters.tripTotalsTypes
  const otherFilter = oldFilters.otherTypes
  eventFilters({
    ...oldFilters,
    assetId: null,
    driverId: null,
    deviceId: null,
    tripId: null,
    searchString: null,
    tripTotalsTypes: getTypesFilter(
      tripTotalsFilter,
      tripTotalsOptions.map((option) => option.value)
    ),
    inTripTypes: getTypesFilter(
      inTripFilter,
      inTripOptions.map((option) => option.value)
    ),
    otherTypes: getTypesFilter(
      otherFilter,
      otherOptions.map((option) => option.value)
    ),
    eventTypes: null,
    minScore: null,
    maxScore: null
  })
}

export const SelectObjectCard = ({ filterByTypeName, onClick }) => {
  const Colors = useColors()
  return (
    <FlexBox
      onClick={onClick}
      Colors={Colors}
      selectable
      style={{
        width: '100%',
        marginTop: Metrics.base * 2,
        marginBottom: Metrics.base * 2,
        cursor: 'pointer',
        borderRadius: Metrics.radius
      }}
    >
      <InsetCardContainer name={filterByTypeName}>
        <FlexBox style={{ width: '100%', height: Metrics.base * 8 }}>
          <Text>Click to select</Text>
        </FlexBox>
      </InsetCardContainer>
    </FlexBox>
  )
}

const EventFilters = ({
  tabId,
  drivers,
  assets,
  devices,
  showDateFilter,
  showTypeFilter,
  showScoreFilter,
  showDriverFilter,
  showAssetFilter,
  showDeviceFilter,
  showTripFilter,
  direction,
  innerFormStyles,
  minWidth,
  maxInputWidth,
  singleOrgSelect,
  searchInputRef
}) => {
  const Colors = useColors()
  const {
    assetId,
    driverId,
    deviceId,
    tripId,
    filterByTypeName,
    tripTotalsTypes,
    inTripTypes,
    eventTypes,
    otherTypes,
    minScore,
    maxScore,
    hasVideo,
    searchString
  } = useReactiveVar(eventFilters)
  const { orgId, orgIds, groupId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  const { data: assetGroups, loading } = useAssetGroups()
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    if (assetGroups && assetGroups.length > 0) {
      setGroupOptions([...assetGroups, { name: 'Ungrouped', _id: 'NO_GROUP' }])
    }
  }, [assetGroups])
  const { startDate, endDate } = useReactiveVar(filters)
  const innerFormStyle = {
    minHeight: 0,
    flexGrow: 1,
    paddingLeft: Metrics.base * 3,
    paddingRight: Metrics.base * 3,
    ...innerFormStyles
  }
  const [showSelectDriverModal, setShowSelectDriverModal] = useState(false)
  const [showSelectAssetModal, setShowSelectAssetModal] = useState(false)
  const [showSelectTripModal, setShowSelectTripModal] = useState(false)
  const { mainContainerWidth } = useAppDimensions()
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  return (
    <>
      <FlexBox
        style={{
          width: '100%',
          minWidth: minWidth ? minWidth : 'auto'
        }}
      >
        <Form
          hideSubmit
          formbox={false}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: 'none',
            boxShadow: 'none',
            width: '100%'
          }}
          maxInputWidth={maxInputWidth}
          innerFormStyles={{ paddingBottom: 0 }}
          hideFormBox
          darkmode={true}
          direction={direction ? direction : 'column'}
          data={[
            {
              name: 'searchTrips',
              renderInput: (props) => {
                return (
                  <SearchBar
                    title='Events'
                    inputString={searchString}
                    setInputString={(value) => {
                      eventFilters({ ...eventFilters(), searchString: value })
                    }}
                    searchInputRef={searchInputRef}
                  />
                )
              }
            },
            {
              name: 'orgId',
              value: orgId ? orgId : [],
              controlled: true,
              handleChange: (value) => {
                filters({ ...filters(), orgId: [...value] })
              },
              label: 'Organisation',
              type: 'select',
              style: innerFormStyle,
              options: orgOptions
                ? orgOptions.map((option) => ({
                    label: option.name,
                    value: option._id
                  }))
                : [],
              display: () =>
                singleOrgSelect && user && user.userType === 'ADMIN'
            },
            {
              name: 'orgIds',
              value: orgIds ? orgIds : [],
              controlled: true,
              handleChange: (value) => {
                filters({ ...filters(), orgIds: [...value] })
              },
              label: 'Organisation',
              type: 'multipleSelect',
              style: innerFormStyle,
              options: orgOptions
                ? orgOptions.map((option) => ({
                    label: option.name,
                    value: option._id
                  }))
                : [],
              display: () =>
                !singleOrgSelect && user && user.userType === 'ADMIN'
            },
            {
              name: 'groupIds',
              value: groupId ? groupId : [],
              controlled: true,
              handleChange: (value) => {
                filters({ ...filters(), groupId: value })
              },
              label: 'Asset Group',
              type: 'select',
              style: innerFormStyle,
              options: groupOptions
                ? groupOptions.map((option) => ({
                    label: option.name,
                    value: option._id
                  }))
                : [],
              display: () => !singleOrgSelect
            },
            {
              name: 'filterByTypeName',
              value: filterByTypeName,
              controlled: true,
              style: innerFormStyle,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), filterByTypeName: value })
              },
              label: 'Filter by',
              type: 'select',
              options: filterByTypeNameOptions,
              display: () =>
                showAssetFilter ||
                showDriverFilter ||
                showTripFilter ||
                showDeviceFilter
            },
            {
              name: 'driver',
              renderInput: (props) => {
                return driverId ? (
                  <InsetCardContainer
                    name='Select Driver'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                  >
                    <DriverCard
                      driverId={driverId}
                      onClick={() => setShowSelectDriverModal(true)}
                    />
                  </InsetCardContainer>
                ) : (
                  <SelectObjectCard
                    onClick={() => setShowSelectDriverModal(true)}
                    filterByTypeName={filterByTypeName}
                    driverId={driverId}
                    urlSearchParams={urlSearchParams}
                    history={history}
                  />
                )
              },
              display: () => filterByTypeName === 'driver' && showDriverFilter
            },
            {
              name: 'asset',
              renderInput: (props) => {
                return assetId ? (
                  <InsetCardContainer
                    name='Select Asset'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                  >
                    <AssetCard
                      assetId={assetId}
                      onClick={() => setShowSelectAssetModal(true)}
                    />
                  </InsetCardContainer>
                ) : (
                  <SelectObjectCard
                    onClick={() => setShowSelectAssetModal(true)}
                    filterByTypeName={filterByTypeName}
                  />
                )
              },
              display: () => filterByTypeName === 'asset' && showAssetFilter
            },
            {
              name: 'trip',
              renderInput: (props) => {
                return tripId ? (
                  <InsetCardContainer
                    name='Select Trip'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                  >
                    <TripCard
                      tripId={tripId}
                      onClick={() => setShowSelectTripModal(true)}
                    />
                  </InsetCardContainer>
                ) : (
                  <SelectObjectCard
                    onClick={() => setShowSelectTripModal(true)}
                    filterByTypeName={filterByTypeName}
                  />
                )
              },
              display: () => filterByTypeName === 'trip' && showTripFilter
            },
            {
              name: 'inTripTypes',
              value: inTripTypes ? inTripTypes : [],
              controlled: true,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), inTripTypes: value })
                selectedEventId(null)
              },
              label: 'Event types',
              type: 'multipleSelect',
              style: innerFormStyle,
              options: inTripOptions,
              display: () => tabId === 'in-trip'
            },
            {
              name: 'tripTotalsTypes',
              value: tripTotalsTypes ? tripTotalsTypes : [],
              controlled: true,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), tripTotalsTypes: value })
                selectedTripTotalEventId(null)
              },
              label: 'Event types',
              type: 'multipleSelect',
              style: innerFormStyle,
              options: tripTotalsOptions,
              display: () => tabId === 'trip-totals'
            },
            {
              name: 'otherTypes',
              value: otherTypes ? otherTypes : [],
              controlled: true,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), otherTypes: value })
                selectedOtherEventId(null)
              },
              label: 'Event types',
              type: 'multipleSelect',
              style: innerFormStyle,
              options: otherOptions,
              display: () => tabId === 'other'
            },
            {
              name: 'eventTypes',
              value: eventTypes ? eventTypes : [],
              controlled: true,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), eventTypes: value })
              },
              label: 'Event types',
              type: 'multipleSelect',
              style: innerFormStyle,
              options: eventTypeOptions,
              display: () => !tabId && showTypeFilter
            },
            {
              name: 'scoreRange',
              label: 'Score Range',
              style: innerFormStyle,
              value: {
                minScore: minScore ? minScore : '',
                maxScore: maxScore ? maxScore : ''
              },
              controlled: true,
              handleChange: (value) => {
                eventFilters({
                  ...eventFilters(),
                  minScore: value.minScore,
                  maxScore: value.maxScore
                })
                selectedEventId(null)
                selectedTripTotalEventId(null)
                selectedOtherEventId(null)
              },
              display: () => showScoreFilter,
              renderInput: (props) => {
                return (
                  <SelectScoreBetween
                    value={props.value}
                    handleChange={props.handleChange}
                    Colors={Colors}
                  />
                )
              }
            },
            {
              name: 'dateRange',
              type: 'dateBetween',
              label: 'Date range',
              style: innerFormStyle,
              value: {
                startDate,
                endDate
              },
              controlled: true,
              handleChange: (value) => {
                filters({
                  ...filters(),
                  startDate: value.startDate,
                  endDate: value.endDate
                })
                selectedEventId(null)
                selectedTripTotalEventId(null)
                selectedOtherEventId(null)
              },
              display: () => showDateFilter
            },
            {
              name: 'hasVideo',
              value: hasVideo,
              controlled: true,
              handleChange: (value) => {
                eventFilters({ ...eventFilters(), hasVideo: value })
                selectedEventId(null)
              },
              label: 'Has Video Clip(s)',
              type: 'checkbox',
              style: innerFormStyle,
              display: () => tabId === 'in-trip'
            }
          ]}
        />
      </FlexBox>
      <Dialog
        open={showSelectDriverModal}
        maxWidth='md'
        fullWidth
        // style={{ width: mainContainerWidth, maxWidth: mainContainerWidth }}
        onClose={() => setShowSelectDriverModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DriverSelectionTable
          setDriverId={(id) => {
            eventFilters({
              ...eventFilters(),
              driverId: id,
              assetId: null,
              tripId: null
            })
            selectedEventId(null)
            selectedTripTotalEventId(null)
            selectedOtherEventId(null)
            setShowSelectDriverModal(false)
          }}
        />
      </Dialog>
      <Dialog
        open={showSelectAssetModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowSelectAssetModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <AssetSelectionTable
          setAssetId={(id) => {
            eventFilters({
              ...eventFilters(),
              assetId: id,
              driverId: null,
              tripId: null
            })
            selectedEventId(null)
            selectedTripTotalEventId(null)
            selectedOtherEventId(null)
            setShowSelectAssetModal(false)
          }}
        />
      </Dialog>
      <Dialog
        open={showSelectTripModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowSelectTripModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <TripSelectionTable
          setTripId={(id) => {
            eventFilters({
              ...eventFilters(),
              tripId: id,
              assetId: null,
              driverId: null
            })
            selectedEventId(null)
            selectedTripTotalEventId(null)
            selectedOtherEventId(null)
            setShowSelectTripModal(false)
          }}
        />
      </Dialog>
    </>
  )
}
export default EventFilters
