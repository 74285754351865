import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const UPDATE_DRIVER = gql`
  mutation updateDriver(
    $id: String!
    $orgId: String
    $firstName: String
    $lastName: String
    $dob: GraphqlDateTime
    $gender: String
    $idNumber: String
    $isActive: Boolean
    $nationality: String
    $cellphoneNumber: String
    $medicalAidNumber: String
    $licenseNumber: String
    $driverRestriction: [Int]
    $vehicleRestriction: [Int]
    $professionalDrivingPermit: [String]
    $professionalDrivingPermitExpiryDate: GraphqlDateTime
    $doe: GraphqlDateTime
    $userType: String
    $code: String
    $firstIssue: GraphqlDateTime
  ) {
    updateDriver(
      id: $id
      orgId: $orgId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      gender: $gender
      idNumber: $idNumber
      isActive: $isActive
      nationality: $nationality
      cellphoneNumber: $cellphoneNumber
      medicalAidNumber: $medicalAidNumber
      licenseNumber: $licenseNumber
      driverRestriction: $driverRestriction
      vehicleRestriction: $vehicleRestriction
      professionalDrivingPermit: $professionalDrivingPermit
      professionalDrivingPermitExpiryDate: $professionalDrivingPermitExpiryDate
      doe: $doe
      userType: $userType
      code: $code
      firstIssue: $firstIssue
    ) {
      _id
      organization {
        _id
        name
      }
      firstName
      lastName
    }
  }
`

export const useUpdateDriver = () => {
  const [updateDriver, { loading: updateDriverLoading }] = useMutation(
    UPDATE_DRIVER,
    {
      mutationName: 'updateDriver',
      successMessage: 'Driver updated successfully',
      errorMessage: 'Driver update failed'
    }
  )

  return [updateDriver, { updateDriverLoading }]
}
