import { useEffect, useState } from 'react'
import { Metrics } from '../../Themes'
import { useReactiveVar } from '@apollo/client'
import { appDimensions as appDimensionsVar } from '../GraphQLClient'

const getMainContainerWidth = ({
  appWidth,
  sidePanelExpanded,
  selectionPanelExpanded
}) => {
  const sidePanelWidth = sidePanelExpanded
    ? Metrics.sidePanelExpandedWidth
    : Metrics.sidePanelWidth
  const selectionPanelWidth = selectionPanelExpanded
    ? Metrics.selectionPanelExpandedWidth
    : Metrics.selectionPanelWidth
  return appWidth - (sidePanelWidth + selectionPanelWidth)
}

export const useAppDimensions = () => {
  const sidePanelExpanded = false
  const selectionPanelExpanded = true
  const appDimensions = useReactiveVar(appDimensionsVar)

  const [width, setWidth] = useState(appDimensions && appDimensions.width)
  const [height, setHeight] = useState(appDimensions && appDimensions.height)

  const [mainContainerWidth, setMainContainerWidth] = useState(
    getMainContainerWidth({
      appWidth: appDimensions.width,
      sidePanelExpanded,
      selectionPanelExpanded
    })
  )
  const [mainContainerHeight, setMainContainerHeight] = useState(
    appDimensions && appDimensions.height
  )

  const [mapWidth, setMapWidth] = useState(null)

  useEffect(() => {
    const mcWidth = getMainContainerWidth({
      appWidth: appDimensions.width,
      sidePanelExpanded,
      selectionPanelExpanded
    })
    setWidth(appDimensions.width)
    setHeight(appDimensions.height)
    setMainContainerWidth(mcWidth)
    setMainContainerHeight(appDimensions.height)
    setMapWidth(mcWidth - Metrics.base * 6 - Metrics.sideBarWidth)
  }, [
    appDimensions.width,
    appDimensions.height,
    sidePanelExpanded,
    selectionPanelExpanded
  ])

  return { width, height, mainContainerWidth, mainContainerHeight, mapWidth }
}
