import React from 'react'
import { Colors } from '../../Themes'

const Divider = (props) => {
  return (
    <div
      style={{
        width: '100%',
        borderBottom: `${props.height}px ${props.dotted ? 'dotted' : 'solid'} ${
          props.color
        }`
      }}
    />
  )
}

Divider.defaultProps = {
  color: Colors.dividerColor,
  height: 1
}

export default Divider
