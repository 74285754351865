import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import TripMetrics from './TripMetrics'
import TripSafetyMetrics from './TripSafetyMetrics'

const Trips = ({ totalCountsData, totalCountsLoading }) => {
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        height: '100%',
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      <TripMetrics
        totalCountsData={totalCountsData}
        totalCountsLoading={totalCountsLoading}
      />
      <FlexBox
        style={{
          width: '100%',
          padding: Metrics.base,
          paddingTop: Metrics.base * 4,
          justifyContent: 'flex-start'
        }}
      >
        <Text
          style={{
            fontSize: 20,
            minWidth: 150
          }}
        >
          Trip Safety
        </Text>
        <FlexBox
          style={{
            flex: 1,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            marginRight: Metrics.base * 2
          }}
        ></FlexBox>
      </FlexBox>
      <TripSafetyMetrics />
    </FlexBox>
  )
}

export default Trips
