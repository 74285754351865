import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'
import { GET_ASSET_GROUPS } from '../Queries/Asset/useAssetGroups'

export const CREATE_ASSET_GROUP = gql`
  mutation createAssetGroup(
    $assetIds: [String!]
    $description: String
    $name: String!
    $orgId: String
  ) {
    createAssetGroup(
      assetIds: $assetIds
      description: $description
      name: $name
      orgId: $orgId
    ) {
      _id
      name
      description
    }
  }
`

export const UPDATE_ASSET_GROUP = gql`
  mutation updateAssetGroup(
    $id: String!
    $name: String!
    $description: String
    $isActive: Boolean
  ) {
    updateAssetGroup(
      id: $id
      name: $name
      description: $description
      isActive: $isActive
    ) {
      _id
      name
      description
    }
  }
`
export const useMutateAssetGroup = () => {
  const [createAssetGroup, { loading: createLoading }] = useMutation(
    CREATE_ASSET_GROUP,
    {
      mutationName: 'createAssetGroup',
      refetchQueries: ['assetGroups'],
      successMessage: 'Asset group created successfully',
      errorMessage: 'Asset group creation failed'
    }
  )

  const [updateAssetGroup, { loading: updateLoading }] = useMutation(
    UPDATE_ASSET_GROUP,
    {
      mutationName: 'updateAssetGroup',
      refetchQueries: ['assetGroups', 'assetsPaginated'],
      successMessage: 'Asset group updated successfully',
      errorMessage: 'Asset group update failed'
    }
  )

  return {
    updateAssetGroup,
    createAssetGroup,
    loading: createLoading || updateLoading
  }
}
