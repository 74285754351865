import React from 'react'
import { FlexBox, Subsection } from '../../../../Components'
import { Metrics } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import CategorySummary from './CategorySummary'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import { useCompliantScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useCompliantScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'
import { DescriptionContent } from '../../Trip/Breakdown'
import TrendIndicator from '../../TrendIndicator'

const Compliant = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts
}) => {
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useCompliantScoreHistory({ driverId })

  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({
    driverId,
    scoreHistoryData,
    category: 'COMPLIANCE'
  })

  return (
    <FlexBox direction='column'>
      {/*<CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        eventsCounts={eventTypeCounts ? eventTypeCounts : {}}
        totalEvents={events && events.length > 0 ? events.length : 0}
      />*/}
      <FlexBox>
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            paddingLeft: Metrics.base * 4,
            paddingRight: Metrics.base * 4,
            flex: 1
          }}
        >
          <TrendIndicator
            size='small'
            style={{ marginBottom: Metrics.base * 2 }}
            direction='row'
            value={previousWeekChange ? previousWeekChange.toFixed(2) : 0}
            suffix='pts'
            label={'Previous Week'}
          />
          <TrendIndicator
            size='small'
            style={{ marginBottom: Metrics.base * 2 }}
            direction='row'
            value={previousMonthChange ? previousMonthChange.toFixed(2) : 0}
            suffix='pts'
            label={'Previous Month'}
          />
          <TrendIndicator
            size='small'
            direction='row'
            value={previousYearChange ? previousYearChange.toFixed(2) : 0}
            suffix='pts'
            label={'Previous Year'}
          />
        </FlexBox>
        <DescriptionContent
          points={[
            `This score takes into account legal and compliance aspects for a trip. This score combines vehicle license, driver license and inspection events.  `,
            `This accounts for 5% of the Combined Trip Safety Score.`,
            `Drivers who score low in the Compliance Category are conducting trips with expired vehicle or drivers licenses and are not doing pre or post-trip inspections on their vehicles. `
          ]}
        />
      </FlexBox>
      <ScoreVsTimeChart
        data={scoreHistory}
        currentScore={score}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot events={events} loading={eventsLoading} />
      <EventsVsTimeChart events={events} groupBy='category' keys={[]} />
    </FlexBox>
  )
}

export default Compliant
