import { useEffect, useState } from 'react'
import { useSubscription } from '../../Components/CustomHooks/useSubscription'
import gql from 'graphql-tag'
import { formatDate, formatDateTime } from '../../Helpers/Functions'

export const alarmFragment = gql`
  fragment alarmFragment on Alarm {
    _id
    category
    type
    latitude
    longitude
    startTime
    endTime
  }
`

export const GET_ALARM_SUBSCRIPTION = gql`
  subscription alarmUpdated($deviceId: String) {
    alarmUpdated(deviceId: $deviceId) {
      ...alarmFragment
    }
  }
  ${alarmFragment}
`

export const alarmDataTransform = ({ data }) => {
  const lat = data.latLong
    ? data.latLong.lat
    : data.latitude
    ? data.latitude
    : null
  const lng = data.latLong
    ? data.latLong.lng
    : data.longitude
    ? data.longitude
    : null
  return {
    ...data,
    id: data._id ? data._id : null,
    category: data.category ? data.category : null,
    type: data.type ? data.type : null,
    deviceId: data.device ? data.device.deviceId : null,
    location: { lat, lng },
    startTime: data.startTime ? formatDateTime(new Date(data.startTime)) : null,
    endTime: data.endTime ? formatDateTime(new Date(data.endTime)) : null
  }
}

export const useAlarmsSubscription = (deviceId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useSubscription(
    GET_ALARM_SUBSCRIPTION,
    {
      variables: { deviceId },
      subscriptionName: 'alarmUpdated',
      errorMessage: 'Failed to fetch Alarm',
      dataTransform: (data) => alarmDataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
