import React, { useEffect, useRef, useState } from 'react'
import { useEventIdsInTrip } from '../../../../../Graphql/Queries/Event/useEventIdsInTrip'
import EventsList from '../EventsList'
import FiltersContainer from '../../FiltersContainer'
import { useRouteMatch, useLocation } from 'react-router-dom'
import EventFilters, {
  checkArrs,
  getValCount,
  handleClearFilters,
  inTripOptions
} from '../EventFilters'
import { NetworkStatus, useReactiveVar } from '@apollo/client'
import {
  eventFilters,
  selectedEventId,
  selectedTabs
} from '../../../../../Graphql/GraphQLClient'
import TabRoutes from '../../../../TabRoutes'
import {
  checkVal,
  removeTrailingSlashes
} from '../../../../../Helpers/Functions'
import EventCardInTrip from './EventCardInTrip'
import { handleUrlIdChange } from '../..'

const getActiveFiltersCount = (filters) => {
  let count = 0
  count += checkArrs(
    filters.inTripTypes,
    inTripOptions.map((option) => option.value)
  )
  count += getValCount(filters.assetId)
  count += getValCount(filters.driverId)
  count += getValCount(filters.deviceId)
  count += getValCount(filters.tripId)
  count += getValCount(filters.minScore)
  count += getValCount(filters.maxScore)
  return count
}

const EventsListInTrip = ({ tabId }) => {
  const { data, eventIds, totalCount, fetchMore, networkStatus } =
    useEventIdsInTrip({ tabId })
  const filters = useReactiveVar(eventFilters)
  const { path } = useRouteMatch()
  const { eventMoreFiltersTab } = useReactiveVar(selectedTabs)
  const eventId = useReactiveVar(selectedEventId)
  const [insertedTopCardId, setInsertedTopCardId] = useState(null)
  const location = useLocation()
  const searchInputRef = useRef()

  useEffect(() => {
    handleUrlIdChange({
      location,
      selectedId: eventId,
      IdName: 'event',
      setSelectedId: selectedEventId
    })
  }, [location])

  /** If there is no selected card, select index 0
   * If there is a selected card, and it is not visible in the list,
   * insert it at the top of the list
   */
  useEffect(() => {
    if (eventIds && eventIds.length > 0) {
      if (!eventIds.includes(eventId)) {
        if (checkVal(eventId)) {
          setInsertedTopCardId(eventId)
        } else {
          selectedEventId(eventIds[0])
          setInsertedTopCardId(null)
        }
      }
    }
  }, [eventIds])

  return (
    <TabRoutes
      renderTabBar={false}
      renderContainer={false}
      defaultId={eventMoreFiltersTab ? eventMoreFiltersTab : null}
      tabsData={[
        { id: 'select-card', name: 'Select Card' },
        { id: 'more-filters', name: 'More Filters' }
      ]}
    >
      <EventsList
        tabId='in-trip'
        CardComponent={EventCardInTrip}
        insertedTopCardId={insertedTopCardId}
        eventIds={data}
        eventId={eventId}
        totalCount={totalCount}
        fetchMore={fetchMore}
        networkStatus={networkStatus}
        activeFiltersCount={getActiveFiltersCount(filters)}
        moreFiltersPath={`${removeTrailingSlashes(path)}/more-filters`}
        setSearchInputString={(value) => [
          eventFilters({ ...eventFilters(), searchString: value }),
          selectedEventId(null)
        ]}
      />
      <FiltersContainer
        title='Events Filters'
        totalCount={totalCount}
        loading={
          networkStatus === NetworkStatus.loading ||
          networkStatus === NetworkStatus.setVariables
        }
        searchInputRef={searchInputRef}
        moreFiltersTabName='eventMoreFiltersTab'
        handleClearFilters={handleClearFilters}
        returnToResultsPath={`${removeTrailingSlashes(path)}/select-card`}
      >
        <EventFilters
          tabId={tabId}
          title='Event Filters'
          results={data}
          showDateFilter
          showTypeFilter
          showScoreFilter
          showDriverFilter
          showAssetFilter
          showDeviceFilter
          showTripFilter
          searchInputRef={searchInputRef}
        />
      </FiltersContainer>
    </TabRoutes>
  )
}

export default EventsListInTrip
