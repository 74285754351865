import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import {
  useCreateDriver,
  CREATE_DRIVER
} from '../../Graphql/Mutations/useCreateDriver'
import { GET_ASSETS } from '../../Graphql/Queries/Asset/useAssets'
import {
  Button,
  FlexBox,
  ModalAlert,
  Form,
  Subsection,
  FormGraphQL,
  TableGraphQL,
  ProcessingSpinner
} from '../../Components'
import { Metrics, useColors } from '../../Themes'
import { useLinkDriverToAsset } from '../../Graphql/Mutations/useLinkDriverToAsset'
import AssetSelectionTable from '../../Components/Custom/SelectionPanel/Asset/AssetSelectionTable'

const handleLinkAsset = ({
  driverId,
  assetId,
  setSubmitting,
  linkDriverToAsset,
  handleClose
}) => {
  setSubmitting(true)

  const linkDriverMutationVariables = {
    driverId,
    assetId
  }

  linkDriverToAsset({
    variables: linkDriverMutationVariables,
    onCompleted: (data) => {
      setSubmitting(false)
      handleClose()
    },
    onError: (data) => {
      console.log(data)
      setSubmitting(false)
      handleClose()
    }
  })
}

const LinkAssetToDriver = ({
  setShowLinkAssetModal,
  driverId,
  showLinkAssetModal
}) => {
  const Colors = useColors()
  const [submittingRowClick, setSubmittingRowClick] = useState(false)

  const [linkDriverToAsset, { loading: linkDriverToAssetLoading }] =
    useLinkDriverToAsset()

  return (
    <ModalAlert
      title={`Link Asset to Driver`}
      body='Select an asset to link to this driver'
      openDialog={showLinkAssetModal}
      setOpenDialog={setShowLinkAssetModal}
    >
      {submittingRowClick ? (
        <div
          style={{
            border: `1px solid ${Colors.subsectionBackground}`,
            borderRadius: Metrics.radius,
            marginTop: Metrics.base * 2
          }}
        >
          <div style={{ height: 200 }}>
            <ProcessingSpinner loading={'Submitting'} mb={3} size={36} />
          </div>
        </div>
      ) : (
        <AssetSelectionTable
          setAssetId={(id) => {
            handleLinkAsset({
              driverId,
              assetId: id,
              setSubmitting: setSubmittingRowClick,
              linkDriverToAsset,
              handleClose: () => setShowLinkAssetModal(false)
            })
          }}
        />
      )}
    </ModalAlert>
  )
}

export default LinkAssetToDriver
