import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import {
  statusDataTransform,
  statusFragment
} from '../Queries/useStatusByDeviceId'
import { useSubscription } from '../../Components/CustomHooks/useSubscription'

export const GET_STATUS_UPDATED = gql`
  subscription statusUpdated($internalDeviceId: String) {
    statusUpdated(internalDeviceId: $internalDeviceId) {
      ...statusFragment
    }
  }
  ${statusFragment}
`

export const useStatusUpdated = (internalDeviceId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: subscriptionData, loading: subscriptionLoading } =
    useSubscription(GET_STATUS_UPDATED, {
      variables: { internalDeviceId },
      subscriptionName: 'statusUpdated',
      errorMessage: 'Failed to fetch Status',
      dataTransform: (data) => statusDataTransform({ data }),
      skip: !internalDeviceId
    })

  useEffect(() => {
    setLoading(subscriptionLoading)
  }, [subscriptionLoading])

  useEffect(() => {
    subscriptionData && setData(subscriptionData)
  }, [subscriptionData])

  return { data, loading }
}
