import { useD3 } from '../CustomHooks/useD3'
import React, { useState } from 'react'
import * as d3 from 'd3'
import { Metrics, useColors, Utils } from '../../Themes'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'
import { Tooltip, withStyles } from '@material-ui/core'
import EventCard, {
  handleEventCardOnClick
} from '../Custom/SelectionPanel/Event/EventCard'
import {
  otherTypes,
  tripTotalTypes,
  selectedEventId
} from '../../Graphql/GraphQLClient'
import EventCardOther from '../Custom/SelectionPanel/Event/Other/EventCardOther'
import EventCardInTrip from '../Custom/SelectionPanel/Event/InTrip/EventCardInTrip'

const GraphEventComponent = ({
  id,
  StyledTooltip,
  Colors,
  selected,
  navigateTo,
  type
}) => {
  return (
    <StyledTooltip
      key={id}
      interactive
      title={
        otherTypes.includes(type) ? (
          <EventCardOther
            eventId={id}
            onClick={() => handleEventCardOnClick({ type, id, navigateTo })}
            backgroundColor={Colors.subsectionBackground}
            navigateTo={navigateTo}
          />
        ) : tripTotalTypes.includes(type) ? (
          <EventCardInTrip
            eventId={id}
            onClick={() => handleEventCardOnClick({ type, id, navigateTo })}
            backgroundColor={Colors.subsectionBackground}
            navigateTo={navigateTo}
          />
        ) : (
          <EventCard
            eventId={id}
            onClick={() => handleEventCardOnClick({ type, id, navigateTo })}
            backgroundColor={Colors.subsectionBackground}
            navigateTo={navigateTo}
          />
        )
      }
      placement='top'
    >
      <g>
        <circle
          className={`dot-${id}-outer`}
          onClick={() => {
            if (selected) {
              // handleUnselect()
            } else {
              // handleSelect()
            }
          }}
        />
        <circle className={`dot-${id}-inner`} />
      </g>
    </StyledTooltip>
  )
}

const EventScatterPlot = ({
  data = [],
  startDate,
  endDate,
  maxYValue,
  chartWidth = 300,
  chartHeight = 250,
  loading,
  navigateTo
}) => {
  const Colors = useColors()
  const [StyledTooltip] = useState(
    withStyles((theme) => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  const margin = { top: 15, right: 20, bottom: 20, left: 30 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth

      const y = d3
        .scaleLinear()
        .domain([0, maxYValue ? maxYValue : d3.max(data, (d) => d.value)])
        .nice()
        .range([height - margin.bottom, margin.top])
      const x = d3
        .scaleTime()
        .domain(
          startDate && endDate
            ? [startDate, endDate]
            : d3.extent(data, (d) => d.date)
        )
        .range([margin.left, width - margin.right])

      const xAxis = (g) =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .attr('color', Colors.secondaryOpacity1)
          .call(
            d3
              .axisBottom(x)
              .ticks(width / 80)
              .tickSizeOuter(0)
          )

      const yAxis = (g) =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .attr('color', Colors.secondaryOpacity1)
          .call(d3.axisLeft(y))
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .select('.tick:last-of-type text')
              .clone()
              .attr('x', 3)
              .attr('text-anchor', 'start')
              .attr('font-weight', 'bold')
              .text(data.y)
          )

      svg.select('.x-axis').call(xAxis)
      svg.select('.y-axis').call(yAxis)

      for (var i = 0; i < data.length; i++) {
        svg
          .select(`.dot-${data[i]._id}-outer`)
          .attr('cx', x(data[i].date))
          .attr('cy', y(data[i].value))
          .attr('r', 5)
          .style('stroke', data[i].color)
          .style('fill', 'white')
        svg
          .select(`.dot-${data[i]._id}-inner`)
          .attr('cx', x(data[i].date))
          .attr('cy', y(data[i].value))
          .attr('r', 3)
          .style('fill', data[i].color)
        // .style('stroke', Colors.primary)
      }

      const ruleX = svg
        .select('.rule-x')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 5)
        .attr('y1', margin.top - 6)
        .attr('y2', height - margin.bottom - 1)
        .attr('x1', -1)
        .attr('x2', -1)

      const ruleY = svg
        .select('.rule-y')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 5)
        .attr('y1', -1)
        .attr('y2', -1)
        .attr('x1', margin.left - 6)
        .attr('x2', width - margin.right - 1)

      svg.on('mousemove touchmove', (event) => {
        const x = d3.pointer(event, svg.node())[0] + 0.5
        const y = d3.pointer(event, svg.node())[1] + 0.5
        if (x > margin.left && x < width - margin.right)
          ruleX.attr('x1', x).attr('x2', x)
        if (y > margin.top && y < height - margin.bottom)
          ruleY.attr('y1', y).attr('y2', y)
      })
    },
    [data.length, chartWidth, chartHeight, startDate, endDate]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth + Metrics.base * 2,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        ref={ref}
        style={{
          height: chartHeight,
          width: chartWidth + Metrics.base * 2,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <rect
          x={plotAreaX}
          y={plotAreaY}
          width={plotAreaWidth}
          height={plotAreaHeight}
          fill={Colors.background}
          stroke={Colors.dividerColor}
        ></rect>
        <line className='rule-x' />
        <line className='rule-y' />
        {data.map((a, i) => (
          <GraphEventComponent
            key={a._id}
            svgRef={ref}
            id={a._id}
            index={i}
            StyledTooltip={StyledTooltip}
            Colors={Colors}
            type={a.type}
            navigateTo={navigateTo}
          />
        ))}
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
            // backgroundColor: 'red'
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      )}
    </div>
  )
}

export default EventScatterPlot
