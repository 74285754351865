import React, { useEffect, useRef, useState } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  assetFilters,
  liveViewClusters,
  liveViewMapCenter,
  liveViewMapIds,
  selectedAssetId,
  selectedDeviceId,
  selectedTabs
} from '../../../../Graphql/GraphQLClient'
import { useAssetIds } from '../../../../Graphql/Queries/Asset/useAssetIds'
import AssetCard from './AssetCard'
import { useRouteMatch } from 'react-router-dom'
import { NetworkStatus, useReactiveVar } from '@apollo/client'
import FiltersContainer from '../FiltersContainer'
import AssetFilters, { handleClearFilters } from './AssetFilters'
import { getValCount } from '../Event/EventFilters'
import TabRoutes from '../../../TabRoutes'
import { checkVal, removeTrailingSlashes } from '../../../../Helpers/Functions'
import { useNavigation } from '../../../CustomHooks/useNavigation'
import AssetClusterCard from './AssetClusterCard'
import LiveAssetCard from './LiveAssetCard'

const getActiveFiltersCount = (filters) => {
  let count = 0
  if (filters.safetyCategories && filters.safetyCategories.length > 0) count++
  if (filters.assetTypes && filters.assetTypes.length > 0) count++
  count += getValCount(filters.minSafetyScore)
  count += getValCount(filters.maxSafetyScore)
  return count
}

const getDistance = (point1, point2) => {
  const latDist = Math.abs(Number(point1.lat) - Number(point2.lat))
  const lngDist = Math.abs(Number(point1.lng) - Number(point2.lng))
  return Math.sqrt(latDist * latDist + lngDist * lngDist)
}

const getCardListData = (clusters) => {
  const data = []
  for (var i = 0; i < clusters.length; i++) {
    if (clusters[i].type === 'Feature') {
      data.push({
        ...clusters[i],
        _id: checkVal(clusters[i].id) ? clusters[i].id : clusters[i].deviceId,
        points:
          clusters[i].properties && clusters[i].properties.point_count
            ? clusters[i].properties.point_count
            : 1,
        location:
          clusters[i].geometry && clusters[i].geometry.coordinates
            ? clusters[i].geometry.coordinates
            : { lat: null, lng: null },
        type: 'CLUSTER'
      })
    } else {
      data.push({
        ...clusters[i],
        _id: clusters[i].assetId,
        location:
          clusters[i].geometry && clusters[i].geometry.coordinates
            ? clusters[i].geometry.coordinates
            : { lat: null, lng: null },
        type: 'ASSET'
      })
    }
  }
  return data.sort((a, b) => {
    if (checkVal(a.points) && checkVal(b.points)) {
      return b.points - a.points
    } else {
      const aDist = getDistance(
        { lat: a.location[1], lng: a.location[0] },
        liveViewMapCenter()
      )
      const bDist = getDistance(
        { lat: b.location[1], lng: b.location[0] },
        liveViewMapCenter()
      )
      return aDist - bDist
    }
  })
}

const LiveAssetsList = () => {
  const [data, setData] = useState([])
  const assetId = useReactiveVar(selectedAssetId)
  const { path } = useRouteMatch()
  const filters = useReactiveVar(assetFilters)
  const { assetMoreFiltersTab } = useReactiveVar(selectedTabs)
  const navigateTo = useNavigation()
  const searchInputRef = useRef()

  const { totalCount } = useAssetIds()

  const clusters = useReactiveVar(liveViewClusters)

  useEffect(() => {
    if (clusters && clusters.length > 0) {
      setData(getCardListData(clusters))
    }
  }, [clusters])

  return (
    <TabRoutes
      renderTabBar={false}
      defaultId={assetMoreFiltersTab ? assetMoreFiltersTab : null}
      tabsData={[
        { id: 'select-card', name: 'Select Card' },
        { id: 'more-filters', name: 'More Filters' }
      ]}
    >
      <>
        <CardsHeader
          title='Assets'
          number={totalCount ? totalCount : 0}
          items={data}
          searchInputString={filters.searchString}
          setSearchInputString={(value) => [
            assetFilters({ ...assetFilters(), searchString: value }),
            selectedAssetId(null),
            selectedDeviceId(null)
          ]}
          searchInputRef={searchInputRef}
          moreFiltersPath={`${removeTrailingSlashes(path)}/more-filters`}
          activeFiltersCount={getActiveFiltersCount(filters)}
          moreFiltersTabName='assetMoreFiltersTab'
        />
        <CardsContainer>
          <CardsList items={data} itemsName='Assets' selectedItemId={assetId}>
            {({ items, item, index }) =>
              item.type === 'CLUSTER' ? (
                <AssetClusterCard
                  data={item}
                  navigateTo={navigateTo}
                  index={index}
                  key={index}
                  selected={item._id === assetId}
                  borderBottom={
                    index + 1 >= items.length ||
                    (items[index + 1] && items[index + 1]._id !== assetId)
                  }
                />
              ) : (
                <LiveAssetCard
                  data={item}
                  assetId={item._id}
                  navigateTo={navigateTo}
                  index={index}
                  key={index}
                  selected={item._id === assetId}
                  borderBottom={
                    index + 1 >= items.length ||
                    (items[index + 1] && items[index + 1]._id !== assetId)
                  }
                />
              )
            }
          </CardsList>
        </CardsContainer>
      </>
      <FiltersContainer
        totalCount={totalCount}
        title='Asset Filters'
        searchInputRef={searchInputRef}
        moreFiltersTabName='assetMoreFiltersTab'
        handleClearFilters={handleClearFilters}
        returnToResultsPath={`${removeTrailingSlashes(path)}/select-card`}
      >
        <AssetFilters searchInputRef={searchInputRef} />
      </FiltersContainer>
    </TabRoutes>
  )
}

export default LiveAssetsList
