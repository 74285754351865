import React from 'react'
import { FlexBox, Text, Subsection, Icon, Status } from '../../Components'
import InfoBadge from '../../Components/Custom/InfoBadge'
import SubsectionDivider from '../../Components/Custom/SubsectionDivider'
import { useSelectedTabs } from '../../Graphql/Queries/useSelectedTabs'
import { Metrics, useColors, Images } from '../../Themes'
import { useDriver } from '../../Graphql/Queries/Driver/useDriver'
import { selectedDriverId } from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'

const DriverScoreView = ({ Colors, driver }) => {
  const starRating = [1, 1, 1, 1, 0.5]
  return (
    <Subsection
      style={{
        width: '100%',
        height: '100%',
        marginTop: Metrics.base * 2,
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        value='Driver Score View'
      />
      <SubsectionDivider />
      <FlexBox
        justifyContent='space-between'
        direction='row'
        style={{ padding: Metrics.base * 3 }}
      >
        <div />
        <FlexBox>
          <Icon
            name='star'
            color={Colors.primaryOpacity2}
            size='massive'
            style={{
              marginLeft: Metrics.base * 3,
              marginRight: Metrics.base * 9
            }}
          />
          <FlexBox direction='column'>
            <Text font='display' style={{ marginBottom: Metrics.base * 2 }}>
              25/450
            </Text>
            <Text font='subheading'> Driver Ranking</Text>
          </FlexBox>
        </FlexBox>
        <FlexBox>
          <Text font='display' style={{ marginRight: Metrics.base * 9 }}>
            5.0
          </Text>
          <FlexBox direction='column'>
            <FlexBox direction='row' style={{ marginBottom: Metrics.base * 3 }}>
              {starRating &&
                starRating.map((star) => {
                  return (
                    <>
                      <Icon
                        name='star'
                        color={Colors.primaryOpacity2}
                        size='huge'
                        style={{ marginLeft: Metrics.base }}
                      />
                    </>
                  )
                })}
            </FlexBox>
            <FlexBox direction='row'>
              <Text font='subheading' style={{ marginRight: Metrics.base * 3 }}>
                Safety Status:
              </Text>
              <Status
                status='Excellent'
                getStatusColors={() => '#F88F15'}
                style={{ marginRight: Metrics.base * 8 }}
              ></Status>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <div />
      </FlexBox>
      <SubsectionDivider />
    </Subsection>
  )
}

const DriverCV = ({ driver, Colors }) => {
  const starRating = [1, 1, 1, 1, 0.5]

  return (
    <Subsection
      style={{
        width: '100%',
        height: '100%',
        marginTop: Metrics.base * 2,
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        value='Driver CV'
        actionButton='Export CV'
        handleButtonClick={() => {
          console.log('export cv')
        }}
      />
      <SubsectionDivider />
      <FlexBox
        justifyContent='space-evenly'
        direction='column'
        alignItems='none'
        style={{ marginTop: Metrics.base * 6, marginBottom: Metrics.base * 6 }}
      >
        <FlexBox
          direction='row'
          justifyContent='space-evenly'
          style={{ width: '100%' }}
        >
          <FlexBox direction='column' style={{ width: '40%' }}>
            {/* start column here */}
            <Text font='heading' style={{ paddingBottom: Metrics.base * 2 }}>
              {driver.firstName + ' ' + driver.lastName}
            </Text>
            <Text font='title' style={{ marginBottom: Metrics.base * 6 }}>
              Heavy-duty Vehicle Driver
            </Text>
            <img
              style={{
                width: 192,
                height: 192,
                borderRadius: 120,
                border: `1px solid ${Colors.primary}`,
                marginBottom: Metrics.base * 5
              }}
              alt='driver profile'
              src={Images.driverProfile}
            ></img>
            <FlexBox
              alignItems='flex-start'
              direction='column'
              style={{ marginBottom: Metrics.base * 30 }}
            >
              <Text
                font='subheading'
                style={{ marginBottom: Metrics.base * 3 }}
              >
                ABOUT ME{' '}
              </Text>
              <Text font='body'>
                {' '}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt <br /> ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud <br />{' '}
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                <br /> consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse <br /> cillum dolore eu fugiat nulla
                pariatur.
              </Text>
            </FlexBox>
            {/* need to end here */}
          </FlexBox>
          <SubsectionDivider rowDivider style={{ height: '90%' }} />
          <FlexBox direction='column' style={{ width: '40%' }}>
            <FlexBox direction='column' alignItems='flex-start'>
              <img
                src={Images.CybeleLogotype}
                alt='Cybelelogo'
                style={{
                  width: 250,
                  marginTop: -Metrics.base,
                  // marginLeft: Metrics.base * 2,
                  marginBottom: Metrics.base * 2
                }}
              />
              <Text
                font='title'
                style={{
                  marginBottom: Metrics.base * 2,
                  width: '100%'
                }}
              >
                Certified Driver
              </Text>
              <FlexBox
                direction='column'
                style={{
                  borderBottom: `1px solid ${Colors.dividerColor}`,
                  borderTop: `1px solid ${Colors.dividerColor}`,
                  marginTop: Metrics.base * 1,
                  marginBottom: Metrics.base * 1,
                  paddingTop: Metrics.base * 2,
                  paddingBottom: Metrics.base * 2,
                  width: '100%'
                }}
              >
                <FlexBox
                  direction='row'
                  style={{
                    marginBottom: Metrics.base,
                    width: '100%',
                    paddingBottom: Metrics.base * 2
                  }}
                >
                  {starRating &&
                    starRating.map((star) => {
                      return (
                        <>
                          <Icon
                            name='star'
                            color={Colors.primaryOpacity2}
                            size='large'
                            style={{ marginLeft: Metrics.base, width: '100%' }}
                          />
                        </>
                      )
                    })}
                </FlexBox>

                <FlexBox
                  direction='row'
                  style={{ width: '100%', paddingBottom: Metrics.base * 2 }}
                >
                  <Text
                    font='heading'
                    style={{ marginRight: Metrics.base * 1 }}
                  >
                    5.0
                  </Text>
                  <Text font='title' style={{ width: '100%' }}>
                    Star safety rating
                  </Text>{' '}
                </FlexBox>
              </FlexBox>
              <FlexBox
                direction='row'
                alignItems='center'
                style={{ paddingBottom: Metrics.base * 2 }}
              >
                <Text
                  font='body'
                  style={{
                    marginleft: Metrics.base * 4
                  }}
                >
                  Safety Status:
                </Text>
                <Status
                  status='Excellent'
                  getStatusColors={() => '#F88F15'}
                  style={{ marginBottom: Metrics.base * 12 }}
                ></Status>
              </FlexBox>
              <FlexBox direction='row'>
                <Icon
                  name='star'
                  color={Colors.primaryOpacity2}
                  size='veryBig'
                  style={
                    {
                      // marginLeft: Metrics.base * 10
                      // marginRight: Metrics.base * 9
                    }
                  }
                />
                <FlexBox direction='column'>
                  <Text
                    font='title'
                    style={{
                      marginleft: Metrics.base * 3,
                      marginBottom: Metrics.base * 1
                    }}
                  >
                    Awarded a gold medal for safe driving
                  </Text>
                  <Text font='body'>November 12 2020</Text>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        {/* skills and experience */}

        <FlexBox
          direction='row'
          justifyContent='space-evenly'
          alignItems='center'
          style={{ width: '100%' }}
        >
          <FlexBox direction='column' style={{ width: '40%' }}>
            <FlexBox
              alignItems='flex-start'
              direction='column'
              // style={{ width: '40%' }}
            >
              <Text
                font='subheading'
                style={{ marginBottom: Metrics.base * 3 }}
              >
                SKILLS
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                {' '}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt <br /> ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                <br /> exercitation ullamco laboris nisi ut aliquip ex ea
                commodo
                <br /> consequat.
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                Duis aute irure dolor in reprehenderit in voluptate velit esse{' '}
                <br /> cillum dolore eu fugiat nulla pariatur.
              </Text>
            </FlexBox>
          </FlexBox>
          <SubsectionDivider rowDivider />
          <FlexBox direction='column' style={{ width: '40%' }}>
            <FlexBox alignItems='flex-start' direction='column'>
              <Text
                font='subheading'
                style={{ marginBottom: Metrics.base * 3 }}
              >
                EXPERIENCE
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                {' '}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt <br /> ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                <br /> exercitation ullamco laboris nisi ut aliquip ex ea
                commodo
                <br /> consequat.
              </Text>
              <Text font='body' style={{ marginBottom: Metrics.base * 3 }}>
                Duis aute irure dolor in reprehenderit in voluptate velit esse{' '}
                <br /> cillum dolore eu fugiat nulla pariatur.
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

const Recognition = (props) => {
  const driverId = useReactiveVar(selectedDriverId)
  const { data: driver } = useDriver(driverId)
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        marginTop: Metrics.base * 2,
        justifyContent: 'flex-start'
      }}
    >
      {driver && (
        <DriverScoreView
          Colors={Colors}
          driver={driver}
          // style={{ height: '50%' }}
        />
      )}
      {driver && <DriverCV driver={driver} Colors={Colors} />}
    </FlexBox>
  )
}
export default Recognition
