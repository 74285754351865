import { StyleSheet } from 'aphrodite'

import { Metrics, Utils } from '../../Themes'

export const time = 3000

const animationKeyframes = {
  '0%': {
    top: -300,
    opacity: 0
  },
  '10%': {
    top: Metrics.base * 4,
    opacity: 1
  },
  '95%': {
    top: Metrics.base * 4,
    opacity: 1
  },
  '100%': {
    top: -300,
    opacity: 1
  }
}

export const inlineStyles = {
  text: {
    display: 'inline-block',
    textAlign: 'inherit'
  }
}

export default StyleSheet.create({
  mainContainer: {
    ...Utils.shadow.large,
    opacity: 1,
    position: 'fixed',
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: Utils.boxShadow,
    paddingTop: Metrics.base * 1.5,
    paddingBottom: Metrics.base * 1.5,
    paddingLeft: Metrics.base * 5,
    paddingRight: Metrics.base * 5,
    borderRadius: Metrics.radius,
    animationName: animationKeyframes,
    animationDuration: `${time}ms`,
    animationTimingFunction: 'ease-in-out',
    left: '50%',
    transform: 'translate(-50%, 0)',
    textAlign: 'center'
    /*

    '@media (min-width: 31.188em)': {
      // right: Metrics.base * 2,
      // maxWidth: Metrics.base * 40
      left: '50%',
      transform: 'translate(-50%, 0)',
      textAlign: 'center'
      // width: `calc(100% - ${Metrics.base * 6}px)`
    },

    '@media (max-width: 31.188em)': {
      left: '50%',
      transform: 'translate(-50%, 0)',
      textAlign: 'center',
      width: `calc(100% - ${Metrics.base * 6}px)`
    }
    */
  }
})
