import React, { useState, useEffect, useRef } from 'react'
import { Metrics, useColors } from '../../../Themes'
import { FlexBox } from '../../../Components'
import { useVideoClipByEventId } from '../../../Graphql/Queries/useVideoClipByEventId'
import NoneSelected from '../NoneSelected'
import MaterialInput from '../../MaterialInput'
import ResponsiveGrid from '../../ResponsiveGrid'
import { checkVal } from '../../../Helpers/Functions'

const ratio = 0.81905

const layoutOptions = [
  { label: '1x1', value: 1 },
  { label: '2x2', value: 4 },
  { label: '3x3', value: 9 }
]

const VideoPlayer = ({
  eventId,
  size,
  autoplay = 'autoplay',
  noBorder,
  onTimeUpdate,
  maxSize = 640,
  forwardRef,
  onLoadedMetadata,
  onSeeking,
  onPause
}) => {
  const { data: videoClips } = useVideoClipByEventId(eventId)
  const [srcArray, setSrcArray] = useState([])
  const Colors = useColors()
  const [layout, setLayout] = useState(1)
  const [channel, setChannel] = useState(0)

  /*
  const player = document.getElementById(
    `video_player_${eventId}_${channel}_${layout}_${0}`
  )
  */

  /*
  useEffect(() => {
    if (player) {
      player.currentTime = time
      console.log(player, player.currentTime)
    }
  }, [time])
  */

  useEffect(() => {
    if (videoClips && videoClips.length > 0) {
      setSrcArray(
        videoClips.sort((a, b) => {
          const aStrings = a.fileName.split(eventId)
          const bStrings = b.fileName.split(eventId)
          if (aStrings[1] && bStrings[1]) {
            if (aStrings[1] < bStrings[1]) {
              return -1
            }
            if (aStrings[1] > bStrings[1]) {
              return 1
            }
          }
          return 0
        })
      )
    } else {
      setSrcArray([])
    }
  }, [videoClips, eventId])

  const numColumns = layout === 1 ? 1 : layout === 4 ? 2 : 3
  const frameSize = size && Math.min(size / numColumns, maxSize)
  const layoutArr = [...Array(layout).keys()]
  const borderWidth = layout === 1 ? 2 : 1

  return (
    <FlexBox
      style={{
        height: '100%',
        width: '100%',
        minHeight: 100,
        borderBottom: `1px solid ${Colors.dividerColor}`
      }}
    >
      {srcArray && srcArray.length > 0 ? (
        <FlexBox direction='column' style={{ width: '100%', height: '100%' }}>
          <FlexBox style={{ width: '100%' }}>
            {layout === 1 && (
              <MaterialInput
                type='select'
                label='channel'
                styles={{
                  outerContainer: {
                    marginRight: Metrics.base * 2,
                    marginLeft: Metrics.base * 4,
                    marginTop: Metrics.base
                  }
                }}
                value={channel}
                selectValues={srcArray.map((_, index) => index)}
                selectDisplayValues={srcArray.map(
                  (_, index) => `CH ${index + 1}`
                )}
                handleChange={(value) => setChannel(value)}
              />
            )}
            <MaterialInput
              type='select'
              label='layout'
              styles={{
                outerContainer: {
                  marginRight: Metrics.base * 2,
                  marginLeft: Metrics.base * 4,
                  marginTop: Metrics.base
                }
              }}
              value={layout}
              selectValues={layoutOptions.map((option) => option.value)}
              selectDisplayValues={layoutOptions.map((option) => option.label)}
              handleChange={(value) => setLayout(value)}
            />
          </FlexBox>
          <FlexBox
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: '#4C525B',
              borderRadius: Metrics.radius,
              overflow: 'hidden'
            }}
          >
            <ResponsiveGrid maxColumns={numColumns} spacing={0}>
              {layoutArr.map((index) => {
                return (
                  <FlexBox
                    style={{
                      width: frameSize ? frameSize - borderWidth : '100%',
                      height: frameSize ? frameSize * ratio - borderWidth : 500,
                      flexDirection: 'row',
                      backgroundColor: '#1C2533'
                    }}
                  >
                    <video
                      onTimeUpdate={onTimeUpdate}
                      onLoadedMetadata={onLoadedMetadata}
                      onSeeking={onSeeking}
                      onPause={onPause}
                      id={`video_player_${eventId}_${srcArray[channel]._id}`}
                      key={srcArray[channel]._id}
                      ref={forwardRef}
                      width='100%'
                      height='100%'
                      autoplay={autoplay}
                      muted
                      controls
                      style={{
                        border: noBorder
                          ? 'none'
                          : `${borderWidth}px solid ${Colors.primary}`
                      }}
                    >
                      <source
                        src={
                          layout === 1
                            ? srcArray[channel].fileName
                            : srcArray[index] && srcArray[index].fileName
                        }
                        type='video/mp4'
                      />
                    </video>
                  </FlexBox>
                )
              })}
            </ResponsiveGrid>
          </FlexBox>
        </FlexBox>
      ) : (
        <NoneSelected
          displayText='No Video Clips'
          helperText='Could not fetch video recordings for this event.'
        />
      )}
    </FlexBox>
  )
}

export default VideoPlayer
