import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { eventDataTransform, eventFragment } from '../Event/useEvent'

export const coachingEventFragment = gql`
  fragment coachingEventFragment on CoachingEvent {
    _id
    driver {
      _id
    }
    event {
      ...eventFragment
    }
  }
  ${eventFragment}
`

export const GET_COACHING_EVENT = gql`
  query coachingEvent($id: String!) {
    coachingEvent(id: $id) {
      ...coachingEventFragment
    }
  }
  ${coachingEventFragment}
`

export const coachingEventDataTransform = ({ data }) => {
  if (data) {
    return {
      ...data,
      event: data.event && eventDataTransform({ data: data.event })
    }
  }
}

export const useCoachingEvent = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_COACHING_EVENT,
    {
      variables: { id },
      queryName: 'coachingEvent',
      errorMessage: 'Failed to fetch Coaching Event',
      dataTransform: (data) => coachingEventDataTransform({ data }),
      skip: !id
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
