import gql from 'graphql-tag'
import { deviceFragment } from '../Queries/Device/useDevice'

export const CREATE_DEVICE = gql`
  mutation createDevice($name: String, $siteId: Int, $unitId: Int) {
    createDevice(name: $name, siteId: $siteId, unitId: $unitId) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`
