import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import { UPDATE_NOTIFICATION } from '../../Graphql/Mutations/updateNotification'
import { useMutation } from '@apollo/client'
import axios from 'axios'
import { Metrics, useColors } from '../../Themes'

import {
  Button,
  FlexBox,
  ModalAlert,
  Form,
  Subsection,
  FormGraphQL,
  ProcessingSpinner,
  Text,
  Dropzone,
  UploadDocuments
} from '../'

const renderLoading = () => {
  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <ProcessingSpinner mt={25} mb={3} size={36} loading={'loading'} />
    </div>
  )
}

const AddDocumentModal = ({
  setShowAddDocumentModal,
  driverId,
  assetId,
  inspectionId,
  incidentId,
  crashId,
  history,
  typeOptions,
  showAddDocumentModal
}) => {
  // const [updateNotification] = useMutation(UPDATE_NOTIFICATION)
  const [file, setFile] = useState(null)
  return (
    <ModalAlert
      title={`New Document`}
      body='Enter details below'
      openDialog={showAddDocumentModal}
      setOpenDialog={setShowAddDocumentModal}
    >
      <UploadDocuments
        fileData={file ? file : null}
        renderLoading={() => renderLoading()}
        setShowAddDocumentModal={setShowAddDocumentModal}
        typeOptions={typeOptions}
        driverId={driverId ? driverId : null}
        assetId={assetId ? assetId : null}
        inspectionId={inspectionId ? inspectionId : null}
        incidentId={incidentId ? incidentId : null}
        crashId={crashId ? crashId : null}
        style={{ padding: Metrics.base * 2 }}
      />
    </ModalAlert>
  )
}

export default AddDocumentModal
