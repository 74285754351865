import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import DriverMetrics from './DriverMetrics'
import DriverSafetyMetrics from './DriverSafetyMetrics'

const Drivers = ({ totalCountsData, totalCountsLoading }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      fillSpace
      style={{
        height: '100%',
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      <DriverMetrics
        totalCountsData={totalCountsData}
        totalCountsLoading={totalCountsLoading}
      />
      <FlexBox
        style={{
          width: '100%',
          padding: Metrics.base,
          paddingTop: Metrics.base * 4,
          justifyContent: 'flex-start'
        }}
      >
        <Text
          style={{
            fontSize: 20,
            minWidth: 150
          }}
        >
          Driver Safety
        </Text>
        <FlexBox
          style={{
            flex: 1,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            marginRight: Metrics.base * 2
          }}
        ></FlexBox>
        {/*<Text
          style={{
            fontSize: 14,
            color: Colors.textSecondary
          }}
        >
          Calculated 31/01/2022
        </Text>*/}
      </FlexBox>
      <DriverSafetyMetrics />
    </FlexBox>
  )
}

export default Drivers
