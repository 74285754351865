import React from 'react'
import { Text, Subsection } from '../'
import { Colors, Metrics, Utils } from '../../Themes'
import Styles from './Styles'
// import { darkmode } from '../../../Themes/Clients/blockHarvest'
import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../../Graphql/Queries/localQueries'

const FormBox = ({
  id,
  children,
  styles,
  style,
  title,
  font,
  noShadow,
  editable
}) => {
  const { data } = useQuery(GET_DARKMODE)
  const darkmode = data ? data.darkmode : true
  const boxShadow = noShadow ? 'none' : Utils.boxShadowLight
  return (
    <Subsection
      id={id}
      style={
        styles && styles.innerContainer
          ? {
              ...Styles.formBoxContainer,
              ...styles.innerContainer,
              // boxShadow,
              ...style
            }
          : {
              ...Styles.formBoxContainer,
              // boxShadow,
              ...style
            }
      }
    >
      {title ? (
        <div
          style={{
            // backgroundColor: 'white',
            paddingTop: editable ? 0 : Metrics.base * 3,
            paddingLeft: Metrics.base * 2,
            // paddingBottom: Metrics.base,
            borderBottom: `1px solid ${
              darkmode
                ? Colors.darkTheme.background
                : Colors.lightTheme.background
            }`,
            marginBottom: editable ? Metrics.base * 3 : 0
          }}
        >
          <Text
            font={font || 'subheading'}
            bold
            color={
              darkmode
                ? Colors.darkTheme.secondary
                : Colors.lightTheme.secondary
            }
            // marginLeft={2}
            // marginBottom={2}
            style={{
              paddingBottom: Metrics.base * 2

              // borderBottom: `1px dotted ${Colors.primaryTransparent}`
            }}
          >
            {title}
          </Text>
        </div>
      ) : null}
      <div
        style={{
          ...Styles.innerContainer,
          // backgroundColor: 'red',
          height: '100%',
          // display: 'flex',
          // justifyContent: 'center',
          // marginBottom: -Metrics.base
          paddingBottom: Metrics.base * 2
        }}
      >
        {children}
      </div>
    </Subsection>
  )
}

export default FormBox
