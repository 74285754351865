import React, { useState, useEffect } from 'react'
import { Metrics, useColors } from '../../Themes'
import { FlexBox, Subsection, Text } from '../../Components'
import AreaChart from '../../Components/D3Charts/AreaChart'
import { Slider } from '@material-ui/core'
import { Display, Title } from '../../Components/Typography'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { checkVal } from '../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import {
  liveViewStopTime,
  liveViewTime,
  selectedDeviceId
} from '../../Graphql/GraphQLClient'
import { useLocationUpdated } from '../../Graphql/Subscriptions/useLocationUpdated'
import LiveSpeedChart from '../../Components/D3Charts/LiveSpeedChart'
import {
  getSpeedChartData,
  getSpeedLimitData
} from '../TripHistory/EventBreakdown'
import LocationHistoryVsTime from './LocationHistoryVsTime'
import Akima from 'akima-interpolator'
import useRecentLocations from './useRecentLocations'
import useRecentLocationsAllDevices from './useRecentLocationsAllDevices'

const getPositionData = (positions, timeVals) => {
  const data = []
  for (var i = 0; i < positions.length; i++) {
    data.push({
      date: new Date(timeVals[i]),
      value: positions[i]
    })
  }
  return data
}

var akima = new Akima()
const getSplineData = (timeVals, positions) => {
  const splineFunc = akima.createInterpolator(timeVals, positions)
  const data = []
  const timeDiff = timeVals[0] - timeVals[timeVals.length - 1]
  const numSteps = 100
  const timeStep = timeDiff / numSteps
  for (var i = 0; i < numSteps; i++) {
    const date = new Date(timeVals[timeVals.length - 1] + timeStep * i)
    data.push({
      date,
      value: splineFunc(date.getTime())
    })
  }
  return data
}

const getSplineDataTest = (timeVals, positions) => {
  const splineFunc = akima.createInterpolator(timeVals, positions)
  const data = []
  for (var i = 0; i < timeVals.length; i++) {
    data.push({
      date: new Date(timeVals[i]),
      value: splineFunc(timeVals[i])
    })
  }
  return data
}

const timeDiff = 50000
const timeStep = 1000

const LiveSpeedPlot = ({ locations }) => {
  const Colors = useColors()
  const [speedChartData, setSpeedChartData] = useState(null)
  const [latsData, setLatsData] = useState([])
  const [lngData, setLngData] = useState([])
  const [lngSplineData, setLngSplineData] = useState([])
  const [latSplineData, setLatSplineData] = useState([])
  const [speedLimitData, setSpeedLimitData] = useState([])
  const deviceId = useReactiveVar(selectedDeviceId)
  // const { data: status } = useStatusByDeviceId(deviceId)

  const timeCursor = useReactiveVar(liveViewTime)
  const stopTime = useReactiveVar(liveViewStopTime)

  const [timeNow, setTimeNow] = useState(new Date().getTime())
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - timeDiff)
  )
  const [endDate, setEndDate] = useState(
    new Date(new Date().getTime() + timeDiff)
  )

  useEffect(() => {
    setTimeout(() => {
      setTimeNow(new Date().getTime())
      setStartDate(new Date(new Date().getTime() - timeDiff))
      setEndDate(new Date(new Date().getTime() + timeDiff))
    }, timeStep)
  }, [timeNow])

  useEffect(() => {
    if (locations && locations.length > 0) {
      const temp = getSpeedChartData(locations)
      setSpeedChartData(temp)
      setSpeedLimitData(getSpeedLimitData(temp))
      const latVals = locations.map((item) =>
        item.location && item.location.lat ? item.location.lat : 0
      )
      const lngVals = locations.map((item) =>
        item.location && item.location.lng ? item.location.lng : 0
      )
      const timeValsNew = locations.map((item) =>
        item.timestamp ? Number(item.timestamp) : 0
      )
      setLatsData(getPositionData(latVals, timeValsNew))
      setLngData(getPositionData(lngVals, timeValsNew))
      locations &&
        locations.length > 4 &&
        setLngSplineData(getSplineData(timeValsNew, lngVals))
      locations &&
        locations.length > 4 &&
        setLatSplineData(getSplineData(timeValsNew, latVals))
    }
  }, [locations])

  const { mainContainerWidth } = useAppDimensions()

  return (
    <FlexBox
      style={{
        width: mainContainerWidth - Metrics.base * 4,
        marginBottom: Metrics.base * 2
      }}
    >
      {locations && locations.length > 0 ? (
        <>
          <FlexBox
            direction='column'
            style={{
              alignItems: 'flex-start',
              paddingBottom: 0,
              // height: 600,
              width: '100%'
            }}
          >
            <FlexBox
              direction='column'
              style={{
                alignItems: 'flex-start'
              }}
            >
              <LiveSpeedChart
                timeCursor={timeCursor}
                stopTime={stopTime}
                data={speedChartData ? speedChartData : []}
                speedLimitData={speedLimitData}
                startDate={startDate}
                endDate={endDate}
                chartWidth={mainContainerWidth - Metrics.base * 6}
                chartHeight={180}
                color={Colors.primaryOpacity3}
                strokeColor={Colors.primary}
              />
              {/*<LocationHistoryVsTime
                timeCursor={currentMapViewTime}
                data={latsData}
                splineData={latSplineData}
                startDate={startDate}
                endDate={endDate}
                chartWidth={mainContainerWidth - Metrics.base * 6}
                chartHeight={180}
              />
              <LocationHistoryVsTime
                timeCursor={currentMapViewTime}
                data={lngData}
                splineData={lngSplineData}
                startDate={startDate}
                endDate={endDate}
                chartWidth={mainContainerWidth - Metrics.base * 6}
                chartHeight={180}
              />*/}
            </FlexBox>
          </FlexBox>
          {/*<HorizonChart />*/}
        </>
      ) : (
        ''
      )}
    </FlexBox>
  )
}

export default LiveSpeedPlot
