import React, { Component } from 'react'

import { Colors } from '../../Themes'
import { Col, EmptyState, Row } from '../'
import { inlineStyles } from './Styles'

class NotFound extends Component {
  render() {
    return (
      <Row
        center='xs'
        middle='xs'
        noMargin
        style={{
          ...inlineStyles.mainContainer,
          backgroundColor: Colors.primary
        }}
      >
        <Col>
          <EmptyState
            light
            body='The page you are looking for does not exist'
          />
        </Col>
      </Row>
    )
  }
}

export default NotFound
