import React from 'react'
import { Metrics, useColors } from '../../Themes'

const LargeDivider = ({ vertical }) => {
  const Colors = useColors()
  const border = `1px solid ${Colors.dividerColor}`
  return (
    <div
      style={{
        width: vertical ? Metrics.largeDividerHeight : '100%',
        height: vertical ? '100%' : Metrics.largeDividerHeight,
        backgroundColor: Colors.background,
        borderBottom: vertical ? 'none' : border,
        borderTop: vertical ? 'none' : border,
        borderLeft: vertical ? border : 'none',
        borderRight: vertical ? border : 'none'
      }}
    />
  )
}

export default LargeDivider
