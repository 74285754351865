import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { avgAndCoordinatesFragment } from './useTotalDistances'

export const GET_ASSETS_AGE = gql`
  query assetsAge($orgIdsFilter: [String], $assetGroupId: String) {
    assetsAge(orgIdsFilter: $orgIdsFilter, assetGroupId: $assetGroupId) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

export const useAssetsAge = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(GET_ASSETS_AGE, {
    variables: {
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      assetGroupId: groupId
    },
    queryName: 'assetsAge',
    errorMessage: 'Failed to fetch Assets Ages',
    dataTransform: (data) => data
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
