import React, { useEffect, useState } from 'react'
import { FlexBox, InfoBadge, Subsection } from '../../../../Components'
import { Metrics, useColors } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import CategorySummary from './CategorySummary'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import { useContextualScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useContextualScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'
import CategorySummarySingleEvent from './CategorySummarySingleEvent'
import { getEventCategoryCountsTripTotals } from './RestedFocused'
import { checkVal } from '../../../../Helpers/Functions'

const Contextual = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts,
  driverSafetyData
}) => {
  const Colors = useColors()
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useContextualScoreHistory({ driverId })

  const [averageNightDrivingPercentage, setAverageNightDrivingPercentage] =
    useState()
  const [averageDayDrivingPercentage, setAverageDayDrivingPercentage] =
    useState()
  const [totalNightDrivingHours, setTotalNightDrivingHours] = useState()
  const [totalDayDrivingHours, setTotalDayDrivingHours] = useState()

  useEffect(() => {
    setAverageNightDrivingPercentage(
      driverSafetyData &&
        driverSafetyData.averageNightDrivingPercentage &&
        driverSafetyData.averageNightDrivingPercentage.toFixed(2)
    )
    setAverageDayDrivingPercentage(
      driverSafetyData &&
        driverSafetyData.averageDayDrivingPercentage &&
        driverSafetyData.averageDayDrivingPercentage.toFixed(2)
    )
    setTotalNightDrivingHours(
      driverSafetyData &&
        driverSafetyData.totalNightDrivingHours &&
        driverSafetyData.totalNightDrivingHours.toFixed(2)
    )
    setTotalDayDrivingHours(
      driverSafetyData &&
        driverSafetyData.totalDayDrivingHours &&
        driverSafetyData.totalDayDrivingHours.toFixed(2)
    )
  }, [driverSafetyData])

  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({
    driverId,
    scoreHistoryData,
    category: 'GENERAL_SAFETY'
  })

  return (
    <FlexBox direction='column'>
      {(averageDayDrivingPercentage ||
        totalDayDrivingHours ||
        averageNightDrivingPercentage ||
        totalNightDrivingHours) && (
        <FlexBox
          direction='row'
          style={{
            width: '100%',
            marginBottom: Metrics.base * 4,
            paddingBottom: Metrics.base * 4,
            borderBottom: `1px solid ${Colors.dividerColor}`
          }}
        >
          {checkVal(averageDayDrivingPercentage) && (
            <InfoBadge
              size='small'
              value={`${averageDayDrivingPercentage}%`}
              label={'Average day driving'}
            />
          )}
          {checkVal(totalDayDrivingHours) && (
            <InfoBadge
              size='small'
              value={`${Math.round(totalDayDrivingHours * 100) / 100} hours`}
              label={'Total day driving'}
            />
          )}
          {checkVal(averageNightDrivingPercentage) && (
            <InfoBadge
              size='small'
              value={`${averageNightDrivingPercentage}%`}
              label={'Average night driving'}
            />
          )}
          {checkVal(totalNightDrivingHours) && (
            <InfoBadge
              size='small'
              value={`${totalNightDrivingHours} hours`}
              label={'Total night driving'}
            />
          )}
        </FlexBox>
      )}
      <CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        showEventTypes={[
          'TRIP_DISTANCE',
          'NIGHT_DRIVING',
          'VEHICLE_INCIDENT',
          'VEHICLE_CRASH'
        ]}
        eventsCounts={
          eventTypeCounts
            ? {
                TRIP_DISTANCE: eventTypeCounts.TRIP_DISTANCE,
                NIGHT_DRIVING: eventTypeCounts.NIGHT_DRIVING,
                VEHICLE_INCIDENT: eventTypeCounts.VEHICLE_INCIDENT,
                VEHICLE_CRASH: eventTypeCounts.VEHICLE_CRASH
              }
            : {}
        }
        totalEvents={events && events.length > 0 ? events.length : 0}
      />
      {/*<CategorySummarySingleEvent
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        totalEventCount={events && events.length > 0 ? events.length : 0}
        eventCategoryCounts={getEventCategoryCountsTripTotals(events)}
        type='tripTotals'
      />*/}
      <ScoreVsTimeChart
        data={scoreHistory}
        currentScore={score}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot
        events={events}
        // colorType='severity'
        loading={eventsLoading}
      />
      <EventsVsTimeChart
        events={events}
        loading={eventsLoading}
        groupBy='type'
        keys={[
          'TRIP_DISTANCE',
          'NIGHT_DRIVING',
          'VEHICLE_INCIDENT',
          'VEHICLE_CRASH'
        ]}
      />
    </FlexBox>
  )
}

export default Contextual
