import React from 'react'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { removeTrailingSlashes } from '../../Helpers/Functions'
import TabRoutesContainer from './TabRoutesContainer'
import TabRoutingBar from './TabRoutingBar'

const getPath = ({ path, id }) => {
  return `${removeTrailingSlashes(path)}/${id}`
}

const getIsDefault = ({ id, defaultId, index }) => {
  if (defaultId) {
    if (defaultId === id) return true
  } else if (index === 0) return true
  return false
}

export const getDefaultIndex = ({ children, tabsData, defaultId }) => {
  for (var i = 0; i < children.length; i++) {
    const id = tabsData[i].id
    if (getIsDefault({ id, defaultId, index: i })) return i
  }
  return 0
}

export const getShowDefaultRoute = (tabIds, urlIds) => {
  for (var i = 0; i < urlIds.length; i++) {
    if (tabIds.includes(urlIds[i])) {
      return false
    }
  }
  return true
}

const TabRoutes = ({
  children,
  tabsData,
  defaultId,
  renderTabBar = true,
  style,
  containerStyle,
  onChange = () => {}
}) => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const tabIds = tabsData.map((item) => item.id)
  const urlIds = location.pathname.split('/')
  const showDefaultRoute = getShowDefaultRoute(tabIds, urlIds)
  return (
    <TabRoutesContainer style={style}>
      {renderTabBar && (
        <TabRoutingBar
          tabsData={tabsData}
          defaultId={defaultId}
          onChange={onChange}
        />
      )}
      <Switch>
        {children.map((child, index) =>
          tabsData[index].hidden ? (
            ''
          ) : tabsData[index].alwaysMounted ? (
            <Route
              children={({ match, ...rest }) => child}
              path={getPath({ path: path, id: tabsData[index].id })}
            />
          ) : (
            <Route path={getPath({ path: path, id: tabsData[index].id })}>
              {child}
            </Route>
          )
        )}
      </Switch>
      {showDefaultRoute && (
        <Route path={getPath({ path: path, id: '' })}>
          {children[getDefaultIndex({ children, tabsData, defaultId })]}
        </Route>
      )}
    </TabRoutesContainer>
  )
}

export default TabRoutes
