import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const assetSafetyFragment = gql`
  fragment assetSafetyFragment on AssetSafety {
    _id
    maintenanceCategoryNormScore100
    kmSinceLastInspection
    lastInspectionRawScore
    lastInspectionNormScore
    lastInspection {
      _id
    }
    kmSinceLastService
    lastServiceRawScore
    lastServiceNormScore
    lastService {
      _id
    }
    totalKilometers
    legalCategoryNormScore100
    expiryDays
    historyCategoryNormScore100
    incidentCount
    incidentNormScore
    crashCount
    crashNormScore
    assetSafetyScore100
    assetSafetyCategory
    createdAt
    modelVersion
  }
`

export const GET_ASSET_SAFETY_BY_ASSET_ID = gql`
  query assetSafetyByAssetId($assetId: String!) {
    assetSafetyByAssetId(assetId: $assetId) {
      ...assetSafetyFragment
    }
  }
  ${assetSafetyFragment}
`

const dataTransform = ({ data }) => {
  const totalScore = data.assetSafetyScore100
  const maintenanceScore = data.maintenanceCategoryNormScore100
  const legalScore = data.legalCategoryNormScore100
  const historyScore = data.historyCategoryNormScore100

  return {
    data,
    totalScore,
    maintenanceScore,
    legalScore,
    historyScore
  }
}

const getVariables = (assetId) => {
  let variables = {
    assetId
  }
  return variables
}

export const useAssetSafetyByAssetId = (assetId) => {
  const [data, setData] = useState(null)

  const [totalScore, setTotalScore] = useState(null)
  const [maintenanceScore, setMaintenanceScore] = useState(null)
  const [legalScore, setLegalScore] = useState(null)
  const [historyScore, setHistoryScore] = useState(null)

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ASSET_SAFETY_BY_ASSET_ID,
    {
      variables: getVariables(assetId),
      skip: !assetId,
      queryName: 'assetSafetyByAssetId',
      errorMessage: 'Failed to fetch Asset Safety',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData && queryData.data) {
      setData(queryData.data)

      setTotalScore(queryData.totalScore)
      setMaintenanceScore(queryData.maintenanceScore)
      setLegalScore(queryData.legalScore)
      setHistoryScore(queryData.historyScore)
    } else {
      setData(null)
    }
  }, [queryData])

  return {
    data,
    totalScore,
    maintenanceScore,
    legalScore,
    historyScore,
    loading
  }
}
