import React, { useState, useEffect } from 'react'
import {
  FlexBox,
  Text,
  Subsection,
  Button,
  Status,
  DataDisplay
} from '../../../Components'
import { Grid } from '@material-ui/core'
import InfoBadge from '../../../Components/Custom/InfoBadge'
import SubsectionDivider from '../../../Components/Custom/SubsectionDivider'
import { useSelectedDriver } from '../../../Graphql/Queries/Driver/useSelectedDriver'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import { Metrics, useColors, Images } from '../../../Themes'
import { useLatestInspection } from '../../../Graphql/Queries/Inspection/useLatestInspection'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import AddDocumentModal from '../../../Components/Custom/AddDocumentModal'
import { useDocumentsByInspectionId } from '../../../Graphql/Queries/Inspection/useDocumentsByInspectionId'
import {
  BeforeStart,
  AncillaryEquipment,
  AfterStart,
  TrailerInspectionData
} from './InspectionData/InspectionData'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import NoneSelected from '../../../Components/Custom/NoneSelected'

const inspectionAttentionValueKeys = {
  ATTENTION_IMMEDIATE: 'Immediate Attention',
  ATTENTION_LATER_DATE: 'Attention at a Later Date',
  ATTENTION_UNECESSARY: 'Vehicle in good condition'
}

const inspectionFieldValueKeys = {
  GOOD: 'Good Condition',
  REPAIR: 'Repair Required',
  REPLACE: 'Replace Required'
}

const InteriorExteriorSection = ({ latestInspection, Colors }) => {
  return (
    <Subsection
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={
          latestInspection &&
          latestInspection.reportedMaintenanceStatus &&
          latestInspection.reportedMaintenanceStatus === 'ATTENTION_UNECESSARY'
            ? Images.verifiedUser
            : Images.error
        }
        // leadIcon='check'
        value='Before Engine Start'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />
      {latestInspection && latestInspection._id ? (
        <BeforeStart latestInspection={latestInspection} Colors={Colors} />
      ) : (
        <Text marginTop={2} marginBottom={2} style={{ textAlign: 'center' }}>
          No Before Start inspection data available
        </Text>
      )}
      <SubsectionDivider />
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='Ancillary Equipment'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <AncillaryEquipment
          latestInspection={latestInspection}
          Colors={Colors}
        />
      ) : (
        <Text marginTop={2} marginBottom={2} style={{ textAlign: 'center' }}>
          No Ancillary Equipment inspection data available
        </Text>
      )}
      <SubsectionDivider />

      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='After Engine Start'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <AfterStart latestInspection={latestInspection} Colors={Colors} />
      ) : (
        <Text marginTop={2} marginBottom={2} style={{ textAlign: 'center' }}>
          No After Start inspection data available
        </Text>
      )}
      <SubsectionDivider />

      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='Trailer'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <TrailerInspectionData
          latestInspection={latestInspection}
          Colors={Colors}
        />
      ) : (
        <Text marginTop={2} marginBottom={2} style={{ textAlign: 'center' }}>
          No Trailer inspection data available
        </Text>
      )}
      <SubsectionDivider />
    </Subsection>
  )
}

const ImageGrid = ({ inspectionImages }) => {
  return (
    <Grid container spacing={3} justifyContent='center' alignItems='flex-start'>
      {inspectionImages.map((image, index) => {
        return (
          <Grid item xs={6}>
            <img
              src={image.url}
              alt={`Inspection Img ${index}`}
              style={{
                borderRadius: Metrics.radius,
                width: '100%'
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const TruckImages = ({ Colors, latestInspection, inspectionImages }) => {
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)

  return (
    <Subsection
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        value='Images'
        actionButton='Upload Images'
        handleButtonClick={() => setShowAddDocumentModal(true)}
      />
      {latestInspection._id && showAddDocumentModal && (
        <AddDocumentModal
          typeOptions={'inspection'}
          showAddDocumentModal={showAddDocumentModal}
          setShowAddDocumentModal={setShowAddDocumentModal}
          inspectionId={latestInspection._id}
        />
      )}
      <SubsectionDivider />

      {checkVal(inspectionImages) &&
      latestInspection._id &&
      inspectionImages.length > 0 ? (
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base * 3
          }}
        >
          <ImageGrid
            inspectionImages={inspectionImages}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </FlexBox>
      ) : (
        <Text
          font='subheading'
          style={{ padding: Metrics.base * 2, textAlign: 'center' }}
        >
          No Images Uploaded
        </Text>
      )}
    </Subsection>
  )
}

const Inspections = (props) => {
  const Colors = useColors()
  const assetId = useReactiveVar(selectedAssetId)
  // const [assetId, setAssetId] = useState()
  const { data: latestInspection } = useLatestInspection(assetId)
  const { data: inspectionDocuments } = useDocumentsByInspectionId({
    inspectionId: latestInspection._id,
    documentType: 'INSPECTION_MAIN'
  })
  const [inspectionImages, setInspectionImages] = useState([])
  const { mainContainerWidth } = useAppDimensions()

  useEffect(() => {
    if (checkVal(inspectionDocuments)) {
      setInspectionImages(inspectionDocuments)
    }
  }, [inspectionDocuments])

  return (
    <FlexBox fillSpace>
      {assetId ? (
        <FlexBox
          direction='Column'
          justifyContent='space-evenly'
          alignItems='center'
          style={{
            width: mainContainerWidth,
            padding: Metrics.base * 2
          }}
        >
          <Subsection
            style={{
              width: '100%',
              padding: Metrics.base * 2,
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {latestInspection && latestInspection._id ? (
              <FlexBox
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <Text font='subheading'>
                  Inspection Date:{' '}
                  {formatDateTime(new Date(latestInspection.createdAt))}
                </Text>
                <FlexBox direction='row'>
                  <Text
                    font='subheading'
                    style={{ marginRight: Metrics.base * 2 }}
                  >
                    Maintenance:{' '}
                  </Text>
                  <Status
                    status={
                      inspectionAttentionValueKeys[
                        latestInspection.reportedMaintenanceStatus
                      ]
                    }
                    getStatusColors={() =>
                      Colors[latestInspection.reportedMaintenanceStatus]
                    }
                    // style={{ marginRight: Metrics.base * 8 }}
                  />
                </FlexBox>
                <FlexBox></FlexBox>
              </FlexBox>
            ) : (
              <Text font='subheading'>No Inspection Data Found</Text>
            )}
            <Button
              // condensed
              title='Add New Inspection'
              onClick={() => props.history.push('/new-inspection')}
              disabled={!assetId}
              textColor='white'
              background='red'
              style={{ marginRight: Metrics.base * 2, alignSelf: 'flex-end' }}
            />
          </Subsection>
          <FlexBox
            direction='row'
            alignItems='flex-start'
            style={{
              width: '100%',
              marginTop: Metrics.base * 2
            }}
          >
            {assetId && latestInspection && (
              <FlexBox style={{ flex: 1, paddingRight: Metrics.base }}>
                <InteriorExteriorSection
                  latestInspection={latestInspection}
                  Colors={Colors}
                />
              </FlexBox>
            )}
            {assetId && latestInspection && (
              <FlexBox style={{ flex: 1, paddingLeft: Metrics.base }}>
                <TruckImages
                  latestInspection={latestInspection}
                  inspectionImages={inspectionImages}
                  Colors={Colors}
                />
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      ) : (
        <NoneSelected helperText='Please select an asset from the list on the left' />
      )}
    </FlexBox>
  )
}
export default Inspections
