import { useEffect, useState } from 'react'
import { UPDATE_NOTIFICATION } from '../../Graphql/Mutations'
import { useMutation as useApolloMutation } from '@apollo/client'

/*
export const updateCache = ({
  cache,
  data,
  QUERY,
  queryVariables,
  listQueryName
}) => {
  const newRef = cache.writeQuery({
    data: data,
    query: QUERY,
    variables: queryVariables
  })
  listQueryName &&
    cache.modify({
      fields: {
        [listQueryName](existingItems = []) {
          return [...existingItems, newRef]
        }
      }
    })
}
*/

// QUERY: gql GraphQL query string - e.g. GET_DOCUMENT_BY_DRIVER_ID
// data: mutationData.mutationDataName
// listQueryName: name of the query to update - e.g. documentByDeviceId
// variables: the query variables
export const updateCacheListWithVariables = (
  QUERY,
  { cache, data, listQueryName, variables }
) => {
  try {
    const queryData = cache.readQuery({
      query: QUERY,
      variables: variables
    })
    cache.writeQuery({
      query: QUERY,
      variables: variables,
      data: { [listQueryName]: [...queryData[listQueryName], data] }
    })
    return [...queryData[listQueryName], data]
  } catch (e) {
    console.log(e)
  }
}

/*
export const updateCacheList = ({ cache, data, listQueryName }) => {
  console.log('????', listQueryName, data)
  cache.modify({
    fields: {
      [listQueryName](existingRefs = [], { readField, storeFieldName }) {
        const existingIndex = existingRefs.some((ref, index) => {
          if(readField('_id', ref) === data._id) return index
          return false
        })
        if (checkVal(existingIndex)) {
          const newRefs = [...existingRefs]
          newRefs.splice(existingIndex, 1, )
          return existingRefs
        }
        const newRef = { __ref: cache.identify(data) }
        console.log('hihiii', [...existingRefs, newRef])
        return [...existingRefs, newRef]
      }
    }
  })
}
*/

export const updateCacheList = ({ cache, data, listQueryName }) => {
  cache.modify({
    fields: {
      [listQueryName](existingRefs = [], { readField, storeFieldName }) {
        // Quick safety check - if the new comment is already
        // present in the cache, we don't need to add it again.
        if (existingRefs.some((ref) => readField('_id', ref) === data._id)) {
          return existingRefs
        }
        const newRef = { __ref: cache.identify(data) }
        return [...existingRefs, newRef]
      }
    }
  })
}

export const useMutation = (
  MUTATION,
  {
    mutationName,
    variablesTransform = (variables) => variables,
    dataTransform = () => {},
    errorMessage,
    successMessage,
    refetchQueries,
    writeQueryOnUpdate = false,
    writeToListOnUpdate = false,
    QUERY,
    getQueryVariables,
    queryVariables,
    queryName,
    listQueryName,
    update
  }
) => {
  const [updateNotification] = useApolloMutation(UPDATE_NOTIFICATION)
  const [loading, setLoading] = useState([])
  const [mutateApollo, { loading: mutationLoading, ...props }] =
    useApolloMutation(MUTATION, {
      update(cache, { data: mutationData }) {
        update && update(cache, { data: mutationData })
        writeToListOnUpdate &&
          listQueryName &&
          updateCacheList({
            cache,
            data: mutationData[mutationName],
            QUERY,
            queryName,
            getQueryVariables,
            listQueryName
          })
      },
      refetchQueries
    })

  const mutate = ({
    variables,
    onCompleted = () => {},
    onError = () => {}
  }) => {
    mutateApollo({ variables: variablesTransform(variables) })
      .then((data) => {
        updateNotification({
          variables: {
            show: true,
            level: 'success',
            text: successMessage ? successMessage : `Success: ${mutationName}`
          }
        })
        onCompleted(data)
        return dataTransform(data)
      })
      .catch((error) => {
        updateNotification({
          variables: {
            show: true,
            level: 'danger',
            text: errorMessage ? errorMessage : error.message
          }
        })
        onError(error)
        return dataTransform(error)
      })
  }

  useEffect(() => {
    setLoading(mutationLoading)
  }, [mutationLoading])

  return [mutate, { loading, props }]
}
