import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'

export const GET_IS_DEVICE_ONLINE = gql`
  query isDeviceOnline($jsession: String, $VVTdeviceId: String) {
    isDeviceOnline(jsession: $jsession, VVTdeviceId: $VVTdeviceId)
  }
`

export const deviceDataTransform = ({ data }) => {
  return {
    ...data
  }
}

export const useIsDeviceOnline = ({ jsession, VVTdeviceId }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_IS_DEVICE_ONLINE,
    {
      variables: { jsession, VVTdeviceId },
      queryName: 'isDeviceOnline',
      errorMessage: 'Failed to fetch Device Status',
      dataTransform: (data) => data,
      skip: !jsession || !VVTdeviceId
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    checkVal(queryData) ? setData(queryData) : setData(null)
  }, [queryData])

  return { data, loading }
}
