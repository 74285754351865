import { StyleSheet } from 'aphrodite'

import { Metrics, Colors, Fonts } from '../../../Themes'

export const inlineStyles = {
  title: {
    padding: Metrics.base * 2,
    paddingBottom: Metrics.base * 1.5,
    ...Fonts.heading
  },
  body: {
    padding: Metrics.base * 2,
    paddingTop: 0,
    ...Fonts.body
  }
}

const mainContainerAnimationIn = {
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
}

const mainContainerAnimationOut = {
  '0%': {
    opacity: 1
  },
  '100%': {
    opacity: 0
  }
}

const containerAnimationIn = {
  '0%': {
    opacity: 0,
    transform: `translateY(${Metrics.base * 3}px)`
  },
  '100%': {
    opacity: 1,
    transform: 'translateY(0px)'
  }
}

export default StyleSheet.create({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2000,
    animationName: mainContainerAnimationIn,
    animationDuration: Metrics.speed.fast,
    animationTimingFunction: 'ease',
    backdropFilter: 'blur(5px)'
  },
  mainContainerAnimateOut: {
    animationName: mainContainerAnimationOut,
    animationDuration: Metrics.speed.fast,
    animationTimingFunction: 'ease'
  },
  container: {
    minWidth: Metrics.base * 39,
    // maxWidth: Metrics.base * 110,
    maxHeight: Metrics.base * 74,
    // backgroundColor: Colors.backgroundTintLight,
    overflow: 'scroll'
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 24,
    marginBottom: 24
  },
  button: {
    width: 120,
    height: 40,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.primary
  },
  buttonGrey: {
    width: 120,
    height: 40,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.grey6
  },
  buttonPrimary: {
    width: 120,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: Colors.primary
  }
})
