import React, { useEffect, useRef, useState } from 'react'
import { css } from 'aphrodite'
import FlexBox from '../../../FlexBox'
import { Metrics, useColors, Utils } from '../../../../Themes'
import { Styles } from '../Styles'
import { LinearProgress, withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Text from '../../../Text'
import IconButton from '../../../IconButton'
import Icon from '../../../Icon'
import ProcessingSpinner from '../../../Processing/Spinner'

export const handleSelectCard = ({
  typeName,
  id,
  navigateTo,
  setSelectedId
}) => {
  setSelectedId(id)
  navigateTo({ queryParams: [{ name: `${typeName}-id`, id }] })
}

export const ToolTipMenuContainer = ({ children, style }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        width: 200,
        paddingTop: Metrics.base,
        paddingBottom: Metrics.base,
        ...style
      }}
    >
      {children}
    </FlexBox>
  )
}

export const TooltipMenuItem = ({
  text,
  onClick = () => {},
  Colors,
  iconName,
  loading,
  disabled,
  disabledTexted
}) => {
  return (
    <FlexBox
      direction='column'
      onClick={
        !disabled
          ? (e) => {
              onClick()
              e.stopPropagation()
            }
          : () => {}
      }
      style={{
        width: '100%',
        padding: Metrics.base,
        paddingTop: 0,
        paddingBottom: 0
        // borderBottom: `1px solid ${Colors.dividerColor}`,
      }}
    >
      {loading ? (
        <ProcessingSpinner color={text} />
      ) : (
        <FlexBox
          selectable={!disabled}
          Colors={Colors}
          style={{
            padding: Metrics.base * 2,
            width: '100%',
            borderRadius: Metrics.radius,
            justifyContent: 'flex-start'
          }}
        >
          {' '}
          {iconName && (
            <Icon
              style={{ marginRight: Metrics.base * 2 }}
              name={iconName}
              size={20}
              color={disabled ? Colors.dividerColor : Colors.primary}
            />
          )}
          <Text>{disabled && disabledTexted ? disabledTexted : text}</Text>{' '}
        </FlexBox>
      )}
    </FlexBox>
  )
}

const Card = ({
  loading,
  titleContent,
  typeName,
  setSelectedId,
  navigateTo,
  bodyContent,
  topRightContent,
  topRightLoading,
  bottomRightContent,
  bottomRightLoading,
  bottomLeftContent,
  TooltipContent,
  children,
  data,
  id,
  selected,
  onClick,
  width,
  borderBottom,
  backgroundColor,
  noBoxShadow,
  cardHeight = 90
}) => {
  const Colors = useColors()
  const [showTooltip, setShowTooltip] = useState(false)
  const shadow = Colors.isDarkmode ? Utils.boxShadowDark : Utils.boxShadowLight
  const styles = Styles({
    Colors,
    Metrics,
    selected,
    width,
    borderBottom,
    backgroundColor,
    noBoxShadow,
    cardHeight,
    shadow
  })
  useEffect(() => {
    if (id && selected) {
      var selectedElement = document.getElementById(id)
      var topPos = selectedElement && selectedElement.offsetTop - cardHeight
      document.getElementById('selection_panel_scroll_container').scrollTop =
        topPos
    }
  }, [])
  const [BorderLinearProgress] = useState(
    withStyles(() => ({
      root: {
        borderRadius: 2
      },
      colorPrimary: {
        backgroundColor: Colors.dividerColor
      },
      bar: {
        // borderRadius: 5,
        backgroundColor: Colors.selectHighlightOpacity2
      }
    }))(LinearProgress)
  )
  const [StyledTooltip] = useState(
    withStyles((theme) => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  const tooltipRef = useRef()
  return (
    <FlexBox
      className={css(styles.card)}
      id={id}
      onClick={
        onClick
          ? onClick
          : () =>
              handleSelectCard({
                typeName,
                id,
                navigateTo,
                setSelectedId
              })
      }
    >
      <FlexBox direction='column' className={css(styles.cardInner)}>
        <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
          <FlexBox
            style={{
              marginBottom: Metrics.base
            }}
          >
            {!loading ? (
              titleContent && titleContent
            ) : (
              <BorderLinearProgress
                style={{
                  height: 12,
                  width: 120,
                  marginBottom: Metrics.base / 2
                }}
              />
            )}
          </FlexBox>
          {!topRightLoading ? (
            topRightContent && topRightContent
          ) : (
            <BorderLinearProgress style={{ height: 10, width: 40 }} />
          )}
        </FlexBox>
        {!loading ? (
          bodyContent && bodyContent
        ) : (
          <BorderLinearProgress
            style={{ height: 10, width: 90, marginBottom: Metrics.base }}
          />
        )}
        {/*children */}
        <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
          {!loading ? (
            bottomLeftContent && bottomLeftContent
          ) : (
            <BorderLinearProgress style={{ height: 8, width: 70 }} />
          )}
          <FlexBox>
            {!bottomRightLoading ? (
              bottomRightContent && bottomRightContent
            ) : (
              <BorderLinearProgress style={{ height: 8, width: 70 }} />
            )}
            {TooltipContent && (
              <StyledTooltip
                interactive
                ref={tooltipRef}
                title={
                  <TooltipContent
                    setShowTooltip={(value) => {
                      if (tooltipRef && tooltipRef.current)
                        tooltipRef.current.open = value
                    }}
                  />
                }
                placement='right'
              >
                <IconButton
                  iconName='link'
                  color={Colors.primary}
                  style={{ marginLeft: Metrics.base * 2 }}
                />
              </StyledTooltip>
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default Card
