import { hexToRgb, getRgb } from '../Helpers/Functions'
import { useColors } from './useColors'
import { Images } from './Images'

const baseColors = {
  primary: '#268AFF',
  primaryTransparent: 'rgba(71, 155, 114, 0.5)',
  lightSelectHighlight: '#D1F1E633',
  primaryLight: '#76B295',
  secondary: 'rgb(113,165,94)',
  blueSelect: 'rgb(0,130,230)',
  third: '#5C796B',
  spot1: '#59C946',
  spot2: '#FFC465',
  spot3: '#FF9965',
  gradient: '#4A5877',
  white: '#ffffff',
  opacity1: '#FFFFFFbb',
  backgroundTint: 'rgb(200,40,40)', //darkmode ? '#222D3B' : '#D8E4E6',
  backgroundTintLight: 'rgb(200,10,220)', // darkmode ? '#37414E' : '#FFFFFF',
  placeholderColor: 'rgba(71, 155, 114, 0.6)',
  disabled: '#485362',
  danger: '#f44336',
  success: '#4caf50',

  // Hardware Alarms
  IGNITION_OFF: '#268AFF',
  IGNITION_ON: '#268AFF',
  SPEEDING: '#F5D561',
  TRIP_DISTANCE: '#268AFF',
  DRIVING_TIME: '#32944E',
  TRAVEL_TIME: '#7DE36B',
  HARSH_MOVEMENT: '#EA7331',
  PARK_END: '#594F95',
  PARK_START: '#594F95',

  // Events
  speeding: '#F5D561',
  speedingText: '#DDAC00',
  phoneMotion: '#83A5FF',
  phoneMotionText: '#5382FF',
  harshBraking: '#FFB762',
  harshBrakingText: '#FFB762',
  harshCornering: '#FF7F57',
  harshCorneringText: '#DB6540',
  harshAccelerating: '#7F73C9',
  harshAcceleratingText: '#594F95',

  PHONE_MOTION: '#83A5FF',
  BRAKING: '#FFB762',
  HARSH_BRAKING: '#FFB762',
  CORNERING: '#FF9F69',
  HARSH_CORNERING: '#FF9F69',
  ACCELERATING: '#7F73C9',
  HARSH_ACCELERATING: '#7F73C9',
  HARSH_ACCELERATION: '#7F73C9',

  PERCENT_SPEEDING: '#FFC683',
  CONTINUOUS_DRIVING: '#8F19A3',
  NIGHT_DRIVING: '#3F2951',

  CRASH: '#f44336',
  VEHICLE_CRASH: '#f44336',
  INCIDENT: '#FF813A',
  VEHICLE_INCIDENT: '#FF813A',

  QUIZ_SCORE: '#971EF6',

  // Severity
  SEVERE: '#D43300',
  HEAVY: '#FF813A',
  MODERATE: '#FFBD81',
  LIGHT: '#FFC465',
  VERY_LIGHT: '#86CB99',

  VERY_LONG: '#D43300',
  LONG: '#FF813A',
  MEDIUM: '#FFBD81',
  SHORT: '#FFC465',
  VERY_SHORT: '#86CB99',

  ACCEPTABLE: '#FFC465',
  NEEDS_IMPROVEMENT: '#FFBD81',
  UNACCEPTABLE: '#FF813A',
  WRECKLESS: '#D43300',
  // UNACCEPTABLE: '#FFBD81',

  // safety
  EXCELLENT: '#54A3FF',
  EXCELLENT_DRIVER: '#54A3FF',
  SAFE: '#5FC27A',
  LOW_RISK: '#FFD18C',
  ADEQUATE: '#FFD18C',
  MODERATE_RISK: '#FFA36E',
  HIGH_RISK: '#EE5F3F',

  // scores

  good: '#86CB99',
  average: '#FFB358',
  bad: '#FF6F5B',

  // inspection
  ATTENTION_IMMEDIATE: '#EE5F3F',
  ATTENTION_LATER_DATE: '#FFD18C',
  ATTENTION_UNECESSARY: '#86CB99',
  ATTENTION_UNNECESSARY: '#86CB99',
  PRE_TRIP: '#268AFF',
  POST_TRIP: '#7F73C9',
  GOOD: '#86CB99',
  REPAIR: '#FFD18C',
  REPLACE: '#EE5F3F',

  //coaching
  CREATED: '#268AFF',
  ASSIGNED: '#7F73C9',
  INPROGRESS: '#FFD18C',
  COMPLETED: '#86CB99'
}

const darkThemeColors = {
  primary: '#268AFF',
  secondary: '#A2ACB7',
  background: '#0E1C2A', // '#111A27', // '#17212F', // '#141E2C',
  textSecondary: '#8193AF',
  spot1: '#59C946',
  spot2: '#FFC465',
  spot3: '#FF9965',
  subsectionBackground: '#1D2633', // '#1F2C3C', // '#283241', // '#28303D', // '#2A3341', // '#202A37', // '#1D2633',
  selectHighlight: '#182F4A', // '#2F4342', // '#243634',
  dividerColor: '#36414F', // '#323B47',
  disabled: '#485362',
  overlay: '#151D27AA'
}
const lightThemeColors = {
  primary: '#268AFF',
  secondary: '#22557D', // '#627284',
  background: '#F9FBFF', // '#E7F0F8', // 'rgb(215,226,236)', // '#B8D0E2',
  textSecondary: '#8193AF',
  spot1: '#59C946',
  spot2: '#FFC465',
  spot3: '#FF9965',
  subsectionBackground: '#FFFFFF', // '#FCFDFE',
  selectHighlight: '#E8F3FF',
  dividerColor: '#E5E7E9', // '#C7D7E4',
  disabled: '#808EA2',
  overlay: '#A4B3B5AA'
}

const generateOpacityVariants = (name, color, number) => {
  // console.log(getRgb(color))
  const output = {}
  let colorConverted = color
  if (color.charAt(0) === '#') colorConverted = hexToRgb(colorConverted)
  else colorConverted = getRgb(colorConverted)
  let count = 0
  for (var i = number; i >= 0; i--) {
    output[name + 'Opacity' + count] = `rgba(${colorConverted.r}, ${
      colorConverted.g
    }, ${colorConverted.b}, ${i / number})`
    count++
  }
  // console.log(output)
  return output
}

const baseOpacityVariants = {
  ...generateOpacityVariants('speeding', baseColors.speeding, 5),
  ...generateOpacityVariants('SPEEDING', baseColors.speeding, 5),
  ...generateOpacityVariants('harshBraking', baseColors.harshBraking, 5),
  ...generateOpacityVariants('HARSH_BRAKING', baseColors.harshBraking, 5),
  ...generateOpacityVariants('harshCornering', baseColors.harshCornering, 5),
  ...generateOpacityVariants('HARSH_CORNERING', baseColors.harshCornering, 5),
  ...generateOpacityVariants(
    'harshAccelerating',
    baseColors.harshAccelerating,
    5
  ),
  ...generateOpacityVariants(
    'HARSH_ACCELERATING',
    baseColors.harshAccelerating,
    5
  ),
  ...generateOpacityVariants(
    'HARSH_ACCELERATION',
    baseColors.harshAccelerating,
    5
  ),
  ...generateOpacityVariants('PHONE_MOTION', baseColors.PHONE_MOTION, 5),
  ...generateOpacityVariants(
    'PERCENT_SPEEDING',
    baseColors.PERCENT_SPEEDING,
    5
  ),
  ...generateOpacityVariants('TRIP_DISTANCE', baseColors.TRIP_DISTANCE, 5),
  ...generateOpacityVariants('TRAVEL_TIME', baseColors.TRAVEL_TIME, 5),
  ...generateOpacityVariants('DRIVING_TIME', baseColors.DRIVING_TIME, 5),
  ...generateOpacityVariants(
    'CONTINUOUS_DRIVING',
    baseColors.CONTINUOUS_DRIVING,
    5
  ),
  ...generateOpacityVariants('NIGHT_DRIVING', baseColors.NIGHT_DRIVING, 5),

  ...generateOpacityVariants('EXCELLENT', baseColors.EXCELLENT, 5),
  ...generateOpacityVariants('SAFE', baseColors.SAFE, 5),
  ...generateOpacityVariants('ADEQUATE', baseColors.ADEQUATE, 5),
  ...generateOpacityVariants('MODERATE_RISK', baseColors.MODERATE_RISK, 5),
  ...generateOpacityVariants('HIGH_RISK', baseColors.HIGH_RISK, 5)
}

const Colors = {
  ...baseColors,
  ...baseOpacityVariants,
  darkTheme: {
    ...darkThemeColors,
    ...generateOpacityVariants('primary', darkThemeColors.primary, 5),
    ...generateOpacityVariants('secondary', darkThemeColors.secondary, 5),
    ...generateOpacityVariants('background', darkThemeColors.background, 5),
    ...generateOpacityVariants(
      'textSecondary',
      darkThemeColors.textSecondary,
      5
    ),
    ...generateOpacityVariants('dividerColor', darkThemeColors.dividerColor, 5),
    ...generateOpacityVariants('spot1', darkThemeColors.spot1, 5),
    ...generateOpacityVariants('spot2', darkThemeColors.spot2, 5),
    ...generateOpacityVariants('spot3', darkThemeColors.spot3, 5),
    ...generateOpacityVariants(
      'subsectionBackground',
      darkThemeColors.subsectionBackground,
      5
    ),
    ...generateOpacityVariants(
      'selectHighlight',
      darkThemeColors.selectHighlight,
      5
    ),
    ...generateOpacityVariants('speeding', baseColors.speeding, 5),
    ...generateOpacityVariants('harshBraking', baseColors.harshBraking, 5),
    ...generateOpacityVariants('harshCornering', baseColors.harshCornering, 5),
    ...generateOpacityVariants(
      'harshAccelerating',
      baseColors.harshAccelerating,
      5
    ),
    ...baseOpacityVariants
  },
  lightTheme: {
    ...lightThemeColors,
    ...generateOpacityVariants('primary', lightThemeColors.primary, 5),
    ...generateOpacityVariants('secondary', lightThemeColors.secondary, 5),
    ...generateOpacityVariants('background', lightThemeColors.background, 5),
    ...generateOpacityVariants(
      'textSecondary',
      lightThemeColors.textSecondary,
      5
    ),
    ...generateOpacityVariants(
      'dividerColor',
      lightThemeColors.dividerColor,
      5
    ),
    ...generateOpacityVariants('spot1', darkThemeColors.spot1, 5),
    ...generateOpacityVariants('spot2', darkThemeColors.spot2, 5),
    ...generateOpacityVariants('spot3', darkThemeColors.spot3, 5),
    ...generateOpacityVariants(
      'subsectionBackground',
      lightThemeColors.subsectionBackground,
      5
    ),
    ...generateOpacityVariants(
      'selectHighlight',
      lightThemeColors.selectHighlight,
      5
    ),
    ...baseOpacityVariants
  }
}

const base = 8
const radius = 5

const Metrics = {
  version: '1.0',
  base,
  radius,
  speed: {
    lightning: '100ms',
    fast: '300ms',
    medium: '450ms',
    slow: '600ms'
  },
  window: {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight
  },
  navBarHeight: base * 13,
  tabBarHeight: base * 7,
  largeDividerHeight: base * 5,
  sidePanelWidth: base * 8,
  sidePanelExpandedWidth: base * 27,
  selectionPanelWidth: base * 8,
  selectionPanelExpandedWidth: base * 40,
  sideBarWidth: base * 40
}

const FontsVariables = {
  fontFamilyOpenSans: 'Open Sans, sans-serif',
  fontFamilyRoboto: `Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
  fontFamilyIbmPlexSans: 'IBM Plex Sans, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  //
  displaySize: 45,
  headingSize: 30,
  subheadingSize: 19,
  titleSize: 16,
  bodySize: 14,
  captionSize: 11,
  statusSize: 10,
  buttonSize: 13,
  //
  tinySize: 9,
  smallSize: 11,
  paragraphSize: 12,
  medium: 14,
  menuSize: 16
}

const font = {
  // lineHeight: '100%',
  fontFamily: FontsVariables.fontFamilyRoboto
}

// Export each use case
const Fonts = {
  display: {
    ...font,
    fontWeight: FontsVariables.fontWeightLight,
    fontSize: FontsVariables.displaySize
  },
  heading: {
    ...font,
    fontWeight: FontsVariables.fontWeightLight,
    fontSize: FontsVariables.headingSize
  },
  subheading: {
    ...font,
    fontWeight: FontsVariables.fontWeightRegular,
    fontSize: FontsVariables.subheadingSize
  },
  title: {
    ...font,
    fontSize: FontsVariables.titleSize,
    fontWeight: FontsVariables.fontWeightBold
  },
  body: {
    ...font,
    fontWeight: FontsVariables.fontWeightLight,
    fontSize: FontsVariables.bodySize
  },
  caption: {
    ...font,
    fontWeight: FontsVariables.fontWeightRegular,
    fontSize: FontsVariables.captionSize
    // letterSpacing: '1px'
  },
  small: {
    ...font,
    fontWeight: FontsVariables.fontWeightLight,
    fontSize: FontsVariables.smallSize
    // letterSpacing: '1px'
  },
  status: {
    ...font,
    fontWeight: FontsVariables.fontWeightBold,
    fontSize: FontsVariables.statusSize
    // letterSpacing: '1px'
  },
  button: {
    ...font,
    fontWeight: FontsVariables.fontWeightBold,
    fontSize: FontsVariables.buttonSize
  },
  secondaryButton: {
    ...font,
    fontWeight: FontsVariables.fontWeightRegular,
    fontSize: FontsVariables.tinySize,
    letterSpacing: '0.8px',
    textAlign: 'center'
  },
  tiny: {
    ...font,
    fontWeight: FontsVariables.fontWeightRegular,
    fontSize: FontsVariables.tinySize
  },
  success: {
    ...font,
    fontSize: FontsVariables.bodySize
  },
  tableCellText: {
    ...font,
    fontSize: FontsVariables.paragraphSize
  },
  tableHeaderText: {
    ...font,
    fontSize: 15,
    fontWeight: FontsVariables.fontWeightBold //Fonts.fontWeightRegular
  },
  menu: {
    ...font,
    fontSize: FontsVariables.menuSize,
    fontWeight: FontsVariables.fontWeightRegular
  },
  medium: {
    ...font,
    fontWeight: FontsVariables.fontWeightRegular,
    fontSize: FontsVariables.medium
  }
}

const Utils = {
  icon: {
    tiny: {
      fontSize: Metrics.base
    },
    small: {
      fontSize: Metrics.base * 2
    },
    regular: {
      fontSize: Metrics.base * 2.5
    },
    large: {
      fontSize: Metrics.base * 3.5
    },
    jumbo: {
      fontSize: Metrics.base * 4.5
    },
    huge: {
      fontSize: Metrics.base * 7.5
    },
    veryBig: {
      fontSize: Metrics.base * 12.5
    },
    massive: {
      fontSize: Metrics.base * 20
    }
  },
  shadow: {
    small: {
      boxShadow: `0 1px 4px 0 ${Colors.black}10`
    },
    regular: {
      boxShadow: `0 2px 8px 0 ${Colors.black}10`
    },
    medium: {
      boxShadow: `0 4px 16px 0 ${Colors.black}10`
    },
    large: {
      boxShadow: `0 8px 32px 0 ${Colors.black}10`
    }
  },
  gradient: {
    backgroundColor: Colors.primary,
    background: `linear-gradient(135deg,${Colors.primary} 0%, ${Colors.successTransparent} 50%, ${Colors.grey6} 100%)`,
    filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr=${Colors.primary}, endColorstr=${Colors.secondary},GradientType=1 )`
  },
  boxShadow:
    '0 3px 1px rgba(47,137,128,0.1),0 4px 8px rgba(15, 48, 44,0.2),0 0 0 1px rgba(47,137,128,0.05)',
  boxShadowLight:
    // '0 3px 1px rgba(47,137,128,0.1),0 4px 8px rgba(15, 48, 44,0.1),0 0 0 1px rgba(47,137,128,0.05)'
    '0 16px 32px rgba(215, 223, 232,0.6)',
  boxShadowDark: '0 16px 32px rgba(6, 11, 18,0.3)'
}

export {
  useColors,
  Colors,
  Metrics,
  Images,
  Utils,
  font,
  FontsVariables,
  Fonts
}
