import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { abbreviateNumber } from '../../../Helpers/Functions'

export const labelAndCoordinatesFragment = gql`
  fragment labelAndCoordinatesFragment on LabelAndCoordinates {
    _id
    count
    models {
      _id
      count2
    }
  }
`
export const GET_ASSET_MODELS = gql`
  query assetsModels($orgIdsFilter: [String], $assetGroupId: String) {
    assetsModels(orgIdsFilter: $orgIdsFilter, assetGroupId: $assetGroupId) {
      ...labelAndCoordinatesFragment
    }
  }
  ${labelAndCoordinatesFragment}
`

const dataTransform = ({ data }) => {
  const keys = []
  const dataOut = []
  for (var i = 0; i < data.length; i++) {
    const a = data[i]
    dataOut.push({ make: a._id, makeCount: a.count })
    if (a && a.models) {
      for (var j = 0; j < a.models.length; j++) {
        keys.push(a.models[j]._id)
        dataOut[i] = {
          ...dataOut[i],
          [a.models[j]._id]: data[i].models[j].count2
        }
      }
    }
  }
  return { data: dataOut, modelKeys: keys }
}

export const useAssetModels = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ASSET_MODELS,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'assetsModels',
      errorMessage: 'Failed to assetsModels',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
