import React from 'react'
import SelectableFlexBox from './SelectableFlexBox'

const FlexBox = ({
  style,
  fillSpace,
  selectable,
  selected,
  direction,
  justifyContent,
  alignItems,
  children,
  id,
  forwardRef,
  ...props
}) => {
  const fillSpaceStyles = fillSpace ? { minHeight: 0, flexGrow: 1 } : {}
  return selectable ? (
    <SelectableFlexBox {...props} selected={selected} style={style}>
      {children}
    </SelectableFlexBox>
  ) : (
    <div
      id={id}
      ref={forwardRef}
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justifyContent || 'center',
        alignItems: alignItems || 'center',
        ...fillSpaceStyles,
        ...style
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export default FlexBox
