import React from 'react'
import FlexBox from '../../../FlexBox'
import { Metrics, useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Status from '../../../Status'
import Card, { handleSelectCard } from '../Cards/Card'
import { useStatusSummary } from '../../../../Graphql/Queries/useStatusSummary'
import { checkVal, hhmmss } from '../../../../Helpers/Functions'
import { useDriver } from '../../../../Graphql/Queries/Driver/useDriver'
import Icon from '../../../Icon'
import DriverCardTTList from './DriverCardTTList'
import {
  selectedDeviceId,
  selectedDriverId,
  selectedVVTDeviceId
} from '../../../../Graphql/GraphQLClient'
import useAkimaAnimation from '../../../../Containers/LiveMonitoring/useAkimaAnimation'
import useRecentLocations from '../../../../Containers/LiveMonitoring/useRecentLocations'

export const StatusContent = ({ data, status, Colors }) => {
  return (
    <FlexBox direction='row'>
      {data && (
        <Icon
          color={
            data.hasVVT ? Colors.primaryOpacity1 : Colors.secondaryOpacity2
          }
          style={{ marginRight: Metrics.base }}
          name={data.hasVVT ? 'videocam' : 'videocam_off'}
        />
      )}
      <Status
        status={
          status.isParked ? 'PARKED' : !status.isMoving ? 'STILL' : 'MOVING'
        }
        getStatusColors={({ value }) =>
          value === 'MOVING'
            ? Colors.primary
            : value === 'STILL'
            ? Colors.MODERATE_RISK
            : Colors.secondary
        }
      />
    </FlexBox>
  )
}

export const ErrorMessage = ({ text, Colors }) => {
  return (
    <FlexBox>
      <Text marginRight={1 / 2} color={Colors.secondaryOpacity2} font='caption'>
        {text}
      </Text>
      <Icon size='small' color={Colors.textSecondaryOpacity1} name='error' />
    </FlexBox>
  )
}

const LiveDriverCard = ({
  driverId,
  selected,
  navigateTo,
  onClick,
  borderBottom
}) => {
  const Colors = useColors()
  const { data, loading: driverLoading } = useDriver(driverId)
  const { data: status, loading: statusLoading } = useStatusSummary(
    data.deviceId
  )
  const locations = useRecentLocations({
    deviceId: data.deviceId,
    maxLength: 5
  })
  const { speed, signalDelay } = useAkimaAnimation({
    locations,
    timeStep: 5000
  })
  return (
    <Card
      id={driverId}
      data={data}
      selected={selected}
      typeName='driver'
      setSelectedId={selectedDriverId}
      navigateTo={navigateTo}
      onClick={
        onClick
          ? onClick
          : () => {
              selectedDeviceId(data.deviceId)
              selectedVVTDeviceId(data.VVTdeviceId ? data.VVTdeviceId : null)
              handleSelectCard({
                typeName: 'driver',
                id: driverId,
                navigateTo: navigateTo,
                setSelectedId: selectedDriverId
              })
            }
      }
      TooltipContent={({ setShowTooltip }) => (
        <DriverCardTTList
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      borderBottom={borderBottom}
      loading={driverLoading}
      titleContent={
        <>
          {status && !status.isParked && signalDelay && (
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                backgroundColor:
                  signalDelay > 120000
                    ? Colors.HIGH_RISK
                    : signalDelay > 20000
                    ? Colors.LOW_RISK
                    : Colors.SAFE,
                marginRight: Metrics.base
              }}
            />
          )}
          <Title color={Colors.primary}>{data && data.name}</Title>
        </>
      }
      bodyContent={
        <FlexBox justifyContent='space-between' style={{ width: '100%' }}>
          <Text marginBottom={1}>
            {data && data.description ? data.description : 'No Description'}
          </Text>
          <Text font='caption' color={Colors.secondaryOpacity2}>
            {signalDelay > 120000
              ? `No signal for ${hhmmss(signalDelay / 1000)}`
              : signalDelay > 20000
              ? 'Slow signal'
              : 'Good signal'}
          </Text>
        </FlexBox>
      }
      topRightContent={
        <StatusContent data={data} status={status} Colors={Colors} />
      }
      topRightLoading={driverLoading || statusLoading}
      bottomRightContent={
        <>
          {!checkVal(data.deviceId) ? (
            <ErrorMessage
              text={
                checkVal(data.assetId) ? 'No Linked Device' : 'No Linked Asset'
              }
              Colors={Colors}
            />
          ) : (
            signalDelay <= 120000 && (
              <Text color={Colors.secondary}>
                {checkVal(speed) ? `${Math.round(speed)} km/h` : ''}
              </Text>
            )
          )}
        </>
      }
      bottomRightLoading={driverLoading || statusLoading}
      bottomLeftContent={
        <Text font='caption' color={Colors.secondaryOpacity2}>
          Asset: {data.assetName}
        </Text>
      }
    />
  )
}

export default LiveDriverCard
