import React, { useEffect, useRef } from 'react'
import { useColors, Colors, Metrics } from '../../../Themes'
import { useState } from 'react'
import { FlexBox } from '../../'
import BarChart from '../../D3Charts/BarChart'
import DistributionBarChart from './DistributionBarChart'

const GeneralMetricsHistogram = ({ data, loading, children, style }) => {
  const Colors = useColors()
  const [width, setWidth] = useState(300)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  return (
    <FlexBox
      forwardRef={containerRef}
      style={{
        width: '100%',
        position: 'relative',
        justifyContent: 'center',
        ...style
      }}
    >
      <DistributionBarChart
        color={Colors.primary}
        color1={Colors.primary}
        color2={Colors.primary}
        chartWidth={width - Metrics.base * 2}
        chartHeight={200}
        data={data && data.length > 0 ? data : []}
        loading={loading}
      />
    </FlexBox>
  )
}

GeneralMetricsHistogram.defaultProps = {
  color: Colors.secondary
}

export default GeneralMetricsHistogram
