import React, { useState, useEffect } from 'react'
import { FlexBox, Button, Text, Subsection, Form } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { useInspectionsByAssetId } from '../../../Graphql/Queries/Inspection/inspectionsByAssetId'
import CardsContainer from '../../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import CardsList from '../../../Components/Custom/SelectionPanel/Cards/CardsList'
import InspectionCard from '../../../Components/Custom/SelectionPanel/InspectionCard.js'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import { Switch, Route } from 'react-router-dom'
import ViewInspection from './ViewInspection'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import NoneSelected from '../../../Components/Custom/NoneSelected'

const getFilterCount = (filters) => {
  let filterCount = 0
  Object.keys(filters).map((key) => {
    if (checkVal(filters[key]) && key !== 'assetId') {
      filterCount += 1
    }
  })
  return filterCount
}
const InspectiontTimeline = ({
  Colors,
  inspectionIds,
  inspectionsLoading,
  history
}) => {
  return (
    <FlexBox
      direction='row'
      fillSpace
      style={{
        width: '100%',
        marginLeft: Metrics.base * 2,
        justifyContent: 'flex-start',
        alignItems: 'stretch'
      }}
    >
      <FlexBox
        style={{ width: 1, backgroundColor: Colors.dividerColor }}
      ></FlexBox>
      <FlexBox
        style={{
          width: '90%',
          paddingBottom: Metrics.base * 4,
          paddingTop: Metrics.base * 2
        }}
      >
        <CardsContainer
          innerContainerStyle={{
            borderTop: 'none',
            borderBottom: 'none',
            backgroundColor: 'none'
          }}
          loading={inspectionsLoading}
        >
          <CardsList items={inspectionIds} itemsName='Inspections'>
            {({ items, item, index }) => (
              <>
                <FlexBox
                  direction='column'
                  style={{ alignItems: 'flex-start', width: '100%' }}
                >
                  <FlexBox
                    direction='row'
                    style={{ width: '100%', marginTop: Metrics.base * 3 }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 1,
                        backgroundColor: Colors.dividerColor
                      }}
                    ></div>
                    <InspectionCard
                      inspectionId={item._id}
                      index={index}
                      key={index}
                      selected={true}
                      noBoxShadow
                      backgroundColor={Colors.subsectionBackground}
                      borderBottom={
                        index + 1 >= items.length || items[index + 1]
                      }
                    />
                    <FlexBox
                      direction='column'
                      style={{ alignItems: 'flex-start', width: 300 }}
                    >
                      <Button
                        title='View In Detail'
                        textColor='white'
                        background='red'
                        onClick={() =>
                          history.push({
                            pathname: '/view-inspection',
                            search: `?id=${item._id}`
                            // state: { rowData: history.location.state.rowData }
                          })
                        }
                        style={{ marginBottom: Metrics.base }}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </>
            )}
          </CardsList>
        </CardsContainer>
      </FlexBox>
    </FlexBox>
  )
}
const InspectionHistory = (props) => {
  const assetId = useReactiveVar(selectedAssetId)
  const Colors = useColors(useColors)
  const [assetSelected, setAssetSelected] = useState()
  const [inspectionFilters, setInspectionFilters] = useState({})
  const { data: inspections, loading: inspectionLoading } =
    useInspectionsByAssetId(inspectionFilters)

  useEffect(() => {
    if (assetId) {
      setInspectionFilters({ ...inspectionFilters, assetId })
    }
  }, [assetId])

  const filterInspectionSchema = [
    {
      name: 'inspectionTypes',
      value: inspectionFilters.inspectionTypes
        ? inspectionFilters.inspectionTypes
        : [],
      controlled: true,
      handleChange: (value) => {
        setInspectionFilters({ ...inspectionFilters, inspectionTypes: value })
      },
      label: 'Inspection Types',
      type: 'multipleSelect',
      style: {
        width: '20%',
        alignSelf: 'left',
        marginRight: Metrics.base * 3,
        marginLeft: Metrics.base * 2
      },
      options: [
        { value: 'PRE_TRIP', label: 'Pre Trip' },
        { value: 'POST_TRIP', label: 'Post Trip' }
      ]
      // disabled: !(inspections && inspections.length > 0)
    },
    {
      name: 'reportedMaintenanceStatus',
      value: inspectionFilters.reportedMaintenanceStatus
        ? inspectionFilters.reportedMaintenanceStatus
        : [],
      controlled: true,
      style: { width: '20%', alignSelf: 'left', marginRight: Metrics.base * 3 },

      handleChange: (value) => {
        setInspectionFilters({
          ...inspectionFilters,
          reportedMaintenanceStatus: value
        })
      },
      label: 'Reported Maintenance Status',
      type: 'multipleSelect',
      options: [
        { value: 'ATTENTION_UNECESSARY', label: 'Good Condition' },
        { value: 'ATTENTION_LATER_DATE', label: 'Required at a later date' },
        { value: 'ATTENTION_IMMEDIATE', label: 'Immediate attention' }
      ]
      // disabled: !(inspections && inspections.length > 0)
    },
    {
      name: 'dateRange',
      type: 'dateBetween',
      label: 'Date range',
      style: { width: '20%', alignSelf: 'left', marginRight: Metrics.base * 3 },
      value: {
        startDate: inspectionFilters.startDate
          ? inspectionFilters.startDate
          : null,
        endDate: inspectionFilters.endDate ? inspectionFilters.endDate : null
      }, // filters.startTime,
      controlled: true,
      handleChange: (value) => {
        setInspectionFilters({
          ...inspectionFilters,
          startDate: value.startDate,
          endDate: value.endDate
        })
      }
    }
  ]

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      {assetId ? (
        <FlexBox
          fillSpace
          direction='column'
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Subsection
            style={{
              width: '100%',
              // paddingBottom: Metrics.base * 2,
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Text
              font='subheading'
              style={{
                textAlign: 'left',
                paddingLeft: Metrics.base * 2,
                paddingRight: Metrics.base * 2,
                paddingTop: Metrics.base * 1
              }}
            >
              Filter Inspections By:
            </Text>
            <Form
              hideSubmit
              formbox={false}
              style={{
                backgroundColor: 'none',
                boxShadow: 'none',
                width: '100%'
              }}
              innerFormStyles={{
                paddingBottom: 0,
                paddingLeft: Metrics.base * 2,
                justifyContent: 'flex-start'
              }}
              hideFormBox
              darkmode={true}
              direction='row'
              data={filterInspectionSchema}
            />
            <FlexBox direction='row' style={{ paddingLeft: Metrics.base * 2 }}>
              <Button
                condensed
                title='Clear Filters'
                textColor='white'
                background='red'
                onClick={() => {
                  setInspectionFilters({ assetId: inspectionFilters.assetId })
                }}
                style={{
                  marginBottom: Metrics.base
                }}
              />
              <Text
                font='body'
                color={Colors.primary}
                style={{
                  paddingBottom: Metrics.base * 1,
                  paddingLeft: Metrics.base * 2
                }}
              >
                Filters Active: {getFilterCount(inspectionFilters)}
              </Text>
            </FlexBox>
          </Subsection>

          {inspections && inspections.length > 0 ? (
            <InspectiontTimeline
              Colors={Colors}
              inspectionIds={inspections}
              inspectionLoading={inspectionLoading}
              history={props.history}
              style={{ width: '100%' }}
            />
          ) : (
            <FlexBox
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text
                font='heading'
                style={{
                  textAlign: 'left',
                  paddingTop: Metrics.base * 5
                }}
              >
                No Inspections Found
              </Text>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <NoneSelected helperText='Please select an asset from the list on the left' />
      )}
    </FlexBox>
  )
}
export default InspectionHistory
