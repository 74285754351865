import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import { FlexBox } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import BarChart from '../../../Components/Custom/D3Charts/BarChart.js'

let data = []

for (let i = 0; i < 101; i++) {
  data.push({
    x: i,
    y: Math.random() * 5 + 5
  })
}

const CurrentScoreDist = ({ title, data, loading }) => {
  const Colors = useColors()
  const [width, setWidth] = useState(200)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  return (
    <Subsection
      forwardRef={containerRef}
      style={{
        width: '100%',
        maxWidth: 700,
        height: '100%'
      }}
    >
      <FlexBox
        fillSpace
        direction='column'
        style={{
          width: '100%'
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            margin: Metrics.base * 2,
            fontWeight: 'bold'
          }}
        >
          {title}
        </Text>
        <BarChart
          loading={loading}
          data={data && data.length > 0 ? data : []}
          chartHeight={280}
          chartWidth={width - Metrics.base * 4}
        />
      </FlexBox>
    </Subsection>
  )
}

export default CurrentScoreDist
