import React from 'react'
import { FlexBox, MainLayout, Subsection } from '../../Components'
import { Display, Heading, Subheading } from '../../Components/Typography'
import { Images, Metrics, useColors } from '../../Themes'

const UpcomingFeatures = (props) => {
  const Colors = useColors()
  return (
    <MainLayout hideSelectionPanel {...props}>
      <FlexBox
        fillSpace
        style={{ width: '100%', alignItems: 'stretch', padding: Metrics.base }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <Subsection style={{ width: '100%', height: '100%' }}>
            <img
              alt='fuel'
              src={Images.fuelGraphic}
              style={{ width: 270, marginBottom: Metrics.base * 8 }}
            ></img>
            <Display style={{ marginBottom: Metrics.base * 2 }}>
              Fuel Theft Prevention
            </Display>
            <Subheading
              color={Colors.textSecondary}
              style={{ maxWidth: 400, textAlign: 'center' }}
            >
              Careful monitoring of fuel usage. <br />
              Recording and analysing suspicious stops.
            </Subheading>
            <Heading
              color={Colors.primary}
              style={{ marginTop: Metrics.base * 8 }}
            >
              Coming Soon
            </Heading>
          </Subsection>
        </FlexBox>
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <Subsection style={{ width: '100%', height: '100%' }}>
            <img
              alt='fuel'
              src={Images.timeGraphic}
              style={{ width: 270, marginBottom: Metrics.base * 8 }}
            ></img>
            <Display style={{ marginBottom: Metrics.base * 2 }}>
              Driver Fatigue Monitoring
            </Display>
            <Subheading
              color={Colors.textSecondary}
              style={{ maxWidth: 400, textAlign: 'center' }}
            >
              Individual driver fatigue analysis over 8h, 24h, 48h, week and
              month. <br />
              Warnings and alerts when set times exceeded.
            </Subheading>
            <Heading color='#23BC9D' style={{ marginTop: Metrics.base * 8 }}>
              Coming Soon
            </Heading>
          </Subsection>
        </FlexBox>
      </FlexBox>
    </MainLayout>
  )
}

export default UpcomingFeatures
