import React from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import {
  DataDisplay,
  FlexBox,
  InfoBadge,
  Status,
  Subsection,
  Text
} from '../../Components'
import { Display, Heading, Title } from '../../Components/Typography'
import { ModifiedPieChart } from '../../Components/Custom/ModifiedPieChart'
import LargeDivider from '../../Components/LargeDivider'
import { checkVal, hhmmss, formatDateTime } from '../../Helpers/Functions'
import { useEffect } from 'react'
import AreaChart from '../../Components/D3Charts/AreaChart'
import { useState } from 'react'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'

const getSpeedChartData = (values) => {
  const data = []
  for (var i = 0; i < values[0].length; i++) {
    checkVal(values[0][i]) &&
      checkVal(values[1][i]) &&
      data.push({
        date: new Date(parseInt(values[1][i])),
        value: parseInt(values[0][i])
      })
  }
  return data.sort((a, b) => a.date.getTime() - b.date.getTime())
}

const EventDetails = ({ eventId }) => {
  const Colors = useColors()
  const [speedChartData, setSpeedChartData] = useState()
  const { data: event } = useEvent(eventId)
  useEffect(() => {
    if (event && event.values && event.values.length === 2) {
      setSpeedChartData(getSpeedChartData(event.values))
    }
  }, [event])
  const { mainContainerWidth } = useAppDimensions()
  return (
    checkVal(eventId) &&
    event && (
      <Subsection style={{ width: '100%', paddingBottom: Metrics.base * 2 }}>
        <FlexBox
          style={{
            padding: Metrics.base * 2,
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`
          }}
        >
          <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
            <FlexBox>
              {event && event.type && Images[event.type] && (
                <img
                  width={35}
                  style={{
                    marginLeft: Metrics.base,
                    marginRight: Metrics.base * 2
                  }}
                  alt={`${event.type}_icon`}
                  src={Images[event.type]}
                ></img>
              )}
              <Heading>{event.name}</Heading>
            </FlexBox>
            <Status
              status={event.severityCategoryDisplayText}
              getStatusColors={() => Colors[event.category]}
            ></Status>
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{
            justifyContent: 'flex-start',
            paddingLeft: Metrics.base * 2,
            paddingTop: Metrics.base * 2,
            paddingBottom: Metrics.base * 2,
            width: '100%'
            // backgroundColor: 'red'
          }}
        >
          <div
            style={{
              height: 140,
              width: 140,
              position: 'relative',
              marginBottom: Metrics.base * 2
            }}
          >
            {/*<FlexBox
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }}
          >
            <Display>{event.severity}</Display>
        </FlexBox>*/}
            <ModifiedPieChart
              color={Colors[event.category]}
              hideLegend
              hideLabels
              padAngle={0}
              innerRadius={0.9}
              startAngle={-180}
              endAngle={180}
              enableArcLinkLabels={false}
              enableSlicesLabels={false}
              enableArcLabels={false}
              isInteractive
              data={[
                {
                  id: 'volumeDelivered',
                  label: 'label1',
                  value: event.normScore
                },
                {
                  id: 'volumeRemaining',
                  label: 'label2',
                  value: 10 - event.normScore
                }
              ]}
            >
              hi
            </ModifiedPieChart>
          </div>
          <FlexBox direction='column' style={{ padding: Metrics.base * 2 }}>
            <Display>
              {checkVal(event.normScore) && `${event.normScore.toFixed(2)}/10`}
            </Display>
            <Text marginTop={1}>Severity score</Text>
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              marginLeft: Metrics.base * 4,
              paddingLeft: Metrics.base * 4,
              alignItems: 'flex-start',
              borderLeft: `1px solid ${Colors.dividerColor}`
            }}
          >
            {event.type === 'SPEEDING' ? (
              <>
                <FlexBox style={{ width: 250 }}>
                  <InfoBadge
                    size='small'
                    value={event.avSpeed}
                    label={'Average Speed Above Limit'}
                    getDisplayVal={(value) =>
                      checkVal(value) ? `${Math.round(value)} km/h` : '-'
                    }
                  />
                  <InfoBadge
                    size='small'
                    value={event.maxSpeed}
                    label={'Max Speed Above Limit'}
                    getDisplayVal={(value) =>
                      checkVal(value) ? `${Math.round(value)} km/h` : '-'
                    }
                  />
                </FlexBox>
              </>
            ) : event.type === 'TRIP_DISTANCE' ? (
              <Text font='heading' color={Colors.secondaryOpacity3}>
                Distance: {'  '}
                {event.totalDistance > 0
                  ? event.totalDistance / 1000 + ' km'
                  : ''}
              </Text>
            ) : event.type === 'TRAVEL_TIME' ? (
              <Text font='heading' color={Colors.secondaryOpacity3}>
                Travel Time: {'  '}
                {hhmmss(event.totalTravelTime)}
              </Text>
            ) : event.type === 'DRIVING_TIME' ? (
              <Text font='heading' color={Colors.secondaryOpacity3}>
                Driving Time: {'  '}
                {hhmmss(event.totalTomTomTravelTime)}
              </Text>
            ) : event.type === 'PERCENT_SPEEDING' ? (
              <Text font='heading' color={Colors.secondaryOpacity3}>
                {event.rawScore}% of Trip Spent Speeding
              </Text>
            ) : (
              ''
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{
            width: '100%',
            borderTop: `1px solid ${Colors.dividerColor}`
          }}
        >
          <DataDisplay
            firstItem
            lastItem
            rowStyle={{ borderBottom: 'none' }}
            style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
            data={
              event &&
              (event.type === 'SPEEDING' || event.type === 'ACCELERATION')
                ? event.startTime
                : event.trip
                ? formatDateTime(new Date(event.trip.startTime))
                : '-'
            }
            label='Date'
            textColor={Colors.secondaryOpacity1}
          ></DataDisplay>
          {event &&
            (event.type === 'SPEEDING' || event.type === 'ACCELERATION') && (
              <DataDisplay
                firstItem
                lastItem
                rowStyle={{ borderBottom: 'none' }}
                data={hhmmss(event.durationSeconds)}
                label='duration'
                textColor={Colors.secondaryOpacity1}
              ></DataDisplay>
            )}
        </FlexBox>
        <LargeDivider />
        {/* 
      {event.type === 'SPEEDING' ? (
        <>
          <Text>Date: {event.startTime}</Text>
        </>
      ) : (
        <Text>Date: {event.trip ? event.trip.startTime : ''}</Text>
      )}
      {event.type === 'SPEEDING' ? (
        <Text>
          Duration: {'  '}
          {hhmmss(event.durationSeconds)}
        </Text>
      ) : event.type === 'TRIP_DISTANCE' ? (
        <Text>
          Distance: {'  '}
          {event.totalDistance / 1000 + ' km'}
        </Text>
      ) : event.type === 'TRAVEL_TIME' ? (
        <Text>
          Travel Time: {'  '}
          {hhmmss(event.totalTravelTime)}
        </Text>
      ) : event.type === 'DRIVING_TIME' ? (
        <Text>
          Driving Time: {'  '}
          {hhmmss(event.totalTomTomTravelTime)}
        </Text>
      ) : (
        ''
      )}
      */}
        {event.type === 'SPEEDING' && (
          <>
            <FlexBox
              style={{
                width: '100%',
                padding: Metrics.base * 2,
                borderBottom: `1px solid ${Colors.dividerColor}`
              }}
            >
              <Title>SPEED ABOVE LIMIT VS TIME</Title>
            </FlexBox>
            <FlexBox style={{}}>
              <AreaChart
                chartWidth={
                  mainContainerWidth - Metrics.sideBarWidth - Metrics.base * 12
                }
                color={Colors.primaryOpacity3}
                strokeColor={Colors.primary}
                data={speedChartData ? speedChartData : []}
              />
            </FlexBox>
          </>
        )}
      </Subsection>
    )
  )
}

export default EventDetails
