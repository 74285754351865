import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import AvgSafetyScore from '../../../Components/Custom/SafetyMetrics/AvgSafetyScore'
import AvgScoreVsTime from '../../../Components/Custom/SafetyMetrics/AvgScoreVsTime'
import CurrentScoreDist from '../../../Components/Custom/SafetyMetrics/CurrentScoreDist'
import DriverQuizScoreDist from '../../../Components/Custom/SafetyMetrics/DriverQuizScoreDist'
import { useDriverSafetyOverTime } from '../../../Graphql/Queries/Driver/useDriverSafetyOverTime'
import { useDriverSafetyAverages } from '../../../Graphql/Queries/Driver/useDriverSafetyAverages'
import { useDriverSafetyScoreDistribution } from '../../../Graphql/Queries/Driver/useDriverSafetyScoreDistribution'
import DriverRankingTable from './DriverRankingTable'

const DriverSafetyMetrics = () => {
  const Colors = useColors()
  const { data: driverSafetyOverTime, loading: driverSafetyOverTimeLoading } =
    useDriverSafetyOverTime({ period: 'year' })
  const { data: driverSafetyAverages, loading: driverSafetyAveragesLoading } =
    useDriverSafetyAverages()
  const {
    data: { average: currentScoreAvg, coordinates: currentScoreDist },
    loading: currentScoreDistLoading
  } = useDriverSafetyScoreDistribution({ period: 'none' })

  return (
    <>
      <FlexBox
        direction='row'
        fillspace
        style={{
          width: '100%',
          marginTop: Metrics.base * 2
        }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <AvgSafetyScore
            avgChangeMonth={
              driverSafetyAverages && driverSafetyAverages.averageDiffMonth
            }
            avgChangeWeek={
              driverSafetyAverages && driverSafetyAverages.averageDiffWeek
            }
            avgChangeYear={
              driverSafetyAverages && driverSafetyAverages.averageDiffYear
            }
            avgScore={
              driverSafetyAverages &&
              driverSafetyAverages.average &&
              Math.round(driverSafetyAverages.average)
            }
          />
        </FlexBox>
        <FlexBox style={{ flex: 3, padding: Metrics.base }}>
          <AvgScoreVsTime
            data={driverSafetyOverTime}
            loading={driverSafetyOverTimeLoading || driverSafetyAveragesLoading}
            id='driverSafetyOverTime'
            averageScore={driverSafetyAverages && driverSafetyAverages.average}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        style={{
          width: '100%',
          padding: Metrics.base
        }}
      >
        <DriverRankingTable />
      </FlexBox>
      <FlexBox
        direction='row'
        fillspace
        style={{
          width: '100%',
          alignItems: 'stretch'
        }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <CurrentScoreDist
            title='Current Scores Distribution'
            data={currentScoreDist}
            loading={currentScoreDistLoading}
          />
        </FlexBox>
        {/* <DriverQuizScoreDist />*/}
      </FlexBox>
    </>
  )
}

export default DriverSafetyMetrics
