import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CHANGE_SENSITIVITY = gql`
  mutation changeAllVideoFetchSensitivity(
    $orgId: String!
    $isActive: Boolean
    $thresholds: VideoThresholdsInput
  ) {
    changeAllVideoFetchSensitivity(
      orgId: $orgId
      isActive: $isActive
      thresholds: $thresholds
    )
  }
`
export const useChangeAllVideoFetchSensitivity = () => {
  const [changeSensitivity, { loading: changeSensitivityLoading }] =
    useMutation(CHANGE_SENSITIVITY, {
      mutationName: 'changeAllVideoFetchSensitivity',
      successMessage: 'Video Recording Thresholds updated',
      errorMessage: 'Failed to update video recording thresholds'
    })
  return [changeSensitivity, { changeSensitivityLoading }]
}
