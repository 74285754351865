import React, { useEffect, useState } from 'react'
import { FlexBox, MaterialInput, Status } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { NetworkStatus } from '@apollo/client'
import { Table } from '../../../Components/Table'
import { checkVal, thousandsSeparator } from '../../../Helpers/Functions'
import { useDrivers } from '../../../Graphql/Queries/Driver/useDrivers'
import { Heading } from '../../../Components/Typography'
import Button from '../../../Components/Button'
import { useTopAndBottomDrivers } from '../../../Graphql/Queries/Driver/useTopAndBottomDrivers'
import { getScoreCategory } from '../../Safety/Asset/Breakdown'
import { useTopAndBottomTrips } from '../../../Graphql/Queries/Trip/useTopAndBottomTrips'
import { selectedTripId } from '../../../Graphql/GraphQLClient'
import { useNavigation } from '../../../Components/CustomHooks/useNavigation'

const tripColumns = (Colors) => [
  {
    Header: 'Score',
    accessor: 'safetyScore',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Start Address',
    accessor: 'startAddress',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'End Address',
    accessor: 'endAddress',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Display ID',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Status',
    accessor: 'tripSafetyCategory',
    Aggregated: ({ cell: { value } }) => `-`,
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      )
  }
]

const getPath = (safetyCategory) => {
  if (safetyCategory === 'speedingCategoryNormScore100')
    return '/safety-score/trips/score-breakdown'
  if (safetyCategory === 'smoothDrivingCategoryNormScore100')
    return '/safety-score/trips/score-breakdown'
  if (safetyCategory === 'restedFocusedCategoryNormScore100')
    return '/safety-score/trips/score-breakdown'
  if (safetyCategory === 'complianceCategoryNormScore100')
    return '/safety-score/trips/score-breakdown'
  if (safetyCategory === 'generalSafetyCategoryNormScore100')
    return '/safety-score/trips/score-breakdown'
  return '/safety-score/trips'
}

const _handleRowClick = ({ row, navigateTo, safetyCategory }) => {
  selectedTripId(row.original.tripId)
  navigateTo({
    pathname: getPath(safetyCategory),
    queryParams: [{ name: 'trip', id: row.original.tripId }]
  })
  window.scrollTo(0, 0)
}

const getBorderLeftStyles = (cell, Colors) => {
  if (cell.row.original.safetyScore)
    return {
      borderLeft: `5px solid ${
        Colors[getScoreCategory(cell.row.original.safetyScore)]
      }`
    }
  return {}
}

const getBackgroundBarStyles = (row, Colors) => {
  if (row.original.safetyScore)
    return {
      backgroundColor: Colors[getScoreCategory(row.original.safetyScore)],
      width: `${row.original.safetyScore}%`
    }
  return {}
}

const orderByOptions = [
  { value: 'DESC', label: 'Top Trips' },
  { value: 'ASC', label: 'Problem Trips' }
]

const scoreOptions = [
  { value: 'tripSafetyScore100', label: 'Combined Safety Score' },
  { value: 'speedingCategoryNormScore100', label: 'Speeding Score' },
  { value: 'smoothDrivingCategoryNormScore100', label: 'Smooth Driving Score' },
  { value: 'restedFocusedCategoryNormScore100', label: 'Rested/Focused Score' },
  { value: 'complianceCategoryNormScore100', label: 'Compliant Score' },
  { value: 'generalSafetyCategoryNormScore100', label: 'Contextual Score' }
]

const TripRankingTable = () => {
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const Colors = useColors()
  const [orderBy, setOrderBy] = useState('DESC')
  const [safetyCategory, setSafetyCategory] = useState('tripSafetyScore100')
  const navigateTo = useNavigation()

  const {
    data: tripsData,
    totalCount,
    fetchMore,
    networkStatus,
    loading
  } = useTopAndBottomTrips({ orderBy, safetyCategory })

  const tripsLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  useEffect(() => {
    if (tripsData && tripsData.length > 0) {
      setData(tripsData)
    } else {
      setData([])
    }
  }, [tripsData])

  useEffect(() => {
    if (
      checkVal(totalCount) &&
      totalCount > 0 &&
      checkVal(pageSize) &&
      pageSize > 0
    ) {
      const tempPageCount = Math.ceil(totalCount / pageSize)
      setPageCount(tempPageCount)
      setPageIndex(Math.min(tempPageCount - 1, pageIndex))
    } else {
      setPageCount(1)
      setPageIndex(0)
    }
  }, [totalCount, pageSize])

  return (
    <Table
      style={{ width: '100%' }}
      handleRowClick={(row) =>
        _handleRowClick({ row, navigateTo, safetyCategory })
      }
      loading={tripsLoading}
      fetchingMore={fetchingMore}
      fetchMore={fetchMore}
      totalCount={totalCount}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      manualPagination={true}
      setPageIndex={setPageIndex}
      columns={tripColumns(Colors)}
      hideColumnFilters
      hideNewButton
      hideAssignButton
      showRefreshButton
      dataState={dataState}
      setData={setData}
      title={'Trips'}
      sortBy={[{ id: 'safetyScore', desc: orderBy === 'DESC' }]}
      defaultPageSize={10}
      getBackgroundBarStyles={(row) => getBackgroundBarStyles(row, Colors)}
      getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
      renderHeading={() => (
        <FlexBox
          style={{
            width: '100%',
            height: Metrics.base * 10,
            padding: Metrics.base * 2,
            justifyContent: 'space-between'
          }}
        >
          <Heading>
            {checkVal(totalCount)
              ? `${thousandsSeparator(totalCount)} Scored Trips`
              : 'Trips'}
          </Heading>
        </FlexBox>
      )}
      subHeaderContent={
        <FlexBox
          fillSpace
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base,
            paddingLeft: Metrics.base * 2
          }}
        >
          <MaterialInput
            type='radio'
            name='topOrProblemSelect'
            direction='row'
            value={orderBy}
            radioValues={orderByOptions.map((option) => option.value)}
            radioDisplayValues={orderByOptions.map((option) => option.label)}
            handleChange={(value) => setOrderBy(value)}
          />
          <MaterialInput
            type='select'
            name='safetScoreTypeSelect'
            value={safetyCategory}
            selectValues={scoreOptions.map((option) => option.value)}
            selectDisplayValues={scoreOptions.map((option) => option.label)}
            handleChange={(value) => setSafetyCategory(value)}
          />
        </FlexBox>
      }
    />
  )
}

export default TripRankingTable
