import React, { useState, useEffect } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useFilters } from '../useFilters'
import { eventFilters } from '../../GraphQLClient'
import { useReactiveVar } from '@apollo/client'

export const coachingEventFragment = gql`
  fragment coachingEventFragment on CoachingEvent {
    _id
    driver {
      _id
    }
    event {
      _id
      displayId
      type
      category
      normScore
    }
  }
`

export const GET_COACHING_EVENTS_BY_DRIVER_ID = gql`
  query coachingEventsByDriverId(
    $driverId: String!
    $skip: Int
    $limit: Int
    $typesFilter: [String]
    $startDate: Float
    $endDate: Float
    $scoreFilterGreater: Int
    $scoreFilterLess: Int
  ) {
    coachingEventsByDriverId(
      driverId: $driverId
      skip: $skip
      limit: $limit
      typesFilter: $typesFilter
      startDate: $startDate
      endDate: $endDate
      scoreFilterGreater: $scoreFilterGreater
      scoreFilterLess: $scoreFilterLess
    ) {
      ...coachingEventFragment
    }
  }
  ${coachingEventFragment}
`

export const coachingEventDataTransform = ({ data }) => {
  let res = []
  if (data) {
    res = data.map((item) => {
      return { ...item, id: item._id ? item._id : null }
    })
  }
  return res
}
const getCoachingEventsVariables = ({
  driverId,
  startDate,
  endDate,
  eventTypes,
  eventScoreGreaterThan,
  eventScoreLessThan
}) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (checkVal(driverId)) {
    variables.driverId = driverId
  }
  if (checkVal(startDate)) {
    variables.startDate = startDate
  }
  if (checkVal(endDate)) {
    variables.endDate = endDate
  }
  if (checkVal(eventTypes)) {
    variables.typesFilter = eventTypes
  }
  if (checkVal(eventScoreGreaterThan)) {
    variables.scoreFilterGreater = eventScoreGreaterThan
  }
  if (checkVal(eventScoreLessThan)) {
    variables.scoreFilterLess = eventScoreLessThan
  }
  return variables
}

export const useCoachingEventsByDriverId = (driverId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { startDate, endDate } = useFilters()

  const { eventTypes, minScore, maxScore } = useReactiveVar(eventFilters)

  const { data: queryData, loading: queryLoading } = useQuery(
    GET_COACHING_EVENTS_BY_DRIVER_ID,
    {
      variables: getCoachingEventsVariables({
        driverId,
        startDate,
        endDate,
        eventTypes,
        eventScoreGreaterThan: minScore,
        eventScoreLessThan: maxScore
      }),
      queryName: 'coachingEventsByDriverId',
      errorMessage: 'Failed to fetch Coaching Events',
      dataTransform: (data) => coachingEventDataTransform({ data }),
      skip: !driverId,
      fetchPolicy: 'cache-and-network'
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
