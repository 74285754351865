import React from 'react'
import { FlexBox } from '../../Components'

const FAQ = (props) => {
  return (
    <iframe
      style={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        border: 'none'
      }}
      title='Cybele FAQ from Hubspot'
      src='https://cybelefleet-25329612.hs-sites-eu1.com/knowledge'
    ></iframe>
  )
}

export default FAQ
