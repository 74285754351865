import React, { useState } from 'react'
import {
  eventFilters,
  selectedDriverId,
  tripFilters
} from '../../../../Graphql/GraphQLClient'
import { useColors } from '../../../../Themes'
import { AssetLink } from '../Asset/AssetCardTTList'
import { ToolTipMenuContainer, TooltipMenuItem } from '../Cards/Card'

export const LinkToAsset = ({
  data,
  text = 'Link to Asset',
  iconName = 'directions_bus',
  Colors,
  setShowLinkAssetModal
}) => {
  return (
    <>
      <TooltipMenuItem
        text={text}
        iconName={iconName}
        disabled={!data}
        Colors={Colors}
        onClick={() => {
          setShowLinkAssetModal(true)
        }}
      />
    </>
  )
}

export const DriverLink = ({
  driverId,
  text = 'Linked Driver',
  disabledTexted = 'No Linked Driver',
  iconName = 'person',
  Colors,
  navigateTo
}) => {
  return (
    <TooltipMenuItem
      text={text}
      disabledTexted={disabledTexted}
      iconName={iconName}
      disabled={!driverId}
      Colors={Colors}
      onClick={() => {
        selectedDriverId(driverId)
        navigateTo({
          pathname: '/fleet-management/drivers',
          queryParams: [{ name: 'driver-id', id: driverId }]
        })
      }}
    />
  )
}

const DriverCardTTList = ({
  pathname,
  data,
  navigateTo,
  setShowLinkAssetModal
}) => {
  const Colors = useColors()
  return (
    <ToolTipMenuContainer>
      {/*
      <TooltipMenuItem
        text='Live Monitoring'
        iconName={'remove_red_eye'}
        Colors={Colors}
        onClick={() => {
          selectedDriverId(data._id)
          navigateTo({
            pathname: '/live-monitoring/drivers',
            queryParams: [{ name: 'driver-id', id: data._id }]
          })
        }}
      />
      */}
      {pathname !== '/safety-score' && (
        <TooltipMenuItem
          text='Safety Report'
          iconName={'shield'}
          Colors={Colors}
          onClick={() => {
            selectedDriverId(data._id)
            navigateTo({
              pathname: '/safety-score/drivers',
              queryParams: [{ name: 'driver-id', id: data._id }]
            })
          }}
        />
      )}
      <DriverLink
        driverId={data._id}
        text='Fleet Management'
        iconName='people'
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <AssetLink
        assetId={data.assetId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <TooltipMenuItem
        text='Trips'
        iconName={'history'}
        Colors={Colors}
        onClick={() => {
          tripFilters({
            ...tripFilters(),
            filterByTypeName: 'driver',
            driverId: data._id
          })
          navigateTo({
            pathname: '/trip-history/more-filters'
          })
        }}
      />
      <TooltipMenuItem
        text='Events'
        iconName={'inbox'}
        Colors={Colors}
        onClick={() => {
          eventFilters({
            ...eventFilters(),
            filterByTypeName: 'driver',
            driverId: data._id
          })
          navigateTo({
            pathname: '/event-inbox/more-filters'
          })
        }}
      />
      <LinkToAsset
        data={data}
        Colors={Colors}
        setShowLinkAssetModal={setShowLinkAssetModal}
      />
    </ToolTipMenuContainer>
  )
}

export default DriverCardTTList
