import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { useReactiveVar } from '@apollo/client'
import { eventFilters, filters } from '../../GraphQLClient'
import {
  dataTransform,
  getEventsVariables,
  GET_EVENT_IDS
} from './useEventIdsInTrip'

export const useEventIdsTripsTotals = () => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const {
    assetId,
    driverId,
    tripId,
    tripTotalsTypes,
    minScore,
    maxScore,
    searchString
  } = useReactiveVar(eventFilters)

  const { startDate, endDate } = useReactiveVar(filters)

  const [data, setData] = useState([])
  const [eventIds, setEventIds] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_EVENT_IDS, {
    variables: getEventsVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId,
      searchString,
      startDate,
      endDate,
      tripId,
      driverId,
      assetId,
      eventTypes: tripTotalsTypes,
      minScore,
      maxScore
    }),
    queryName: 'events',
    errorMessage: 'Failed to fetch Events Ids',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setEventIds(queryData.eventIds)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, eventIds, totalCount, loading, fetchMore, networkStatus }
}
