import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display, Heading } from '../../Typography'
import ScoreCircleChart from '../ScoreCircleChart'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'
import TrendIndicator from '../../../Containers/Safety/TrendIndicator'

const AvgSafetyScore = ({
  avgScore,
  avgChangeWeek,
  avgChangeMonth,
  avgChangeYear
}) => {
  const Colors = useColors()
  return (
    <Subsection
      style={{
        //position: 'relative',
        height: 350,
        width: '100%'
        //...style
      }}
    >
      <FlexBox
        direction='column'
        style={{
          height: '100%',
          width: '100%',
          alignItems: 'flex-start'
        }}
      >
        <FlexBox
          direction='row'
          style={{
            width: '100%',
            padding: Metrics.base * 4,
            paddingLeft: Metrics.base * 6,
            paddingBottom: Metrics.base * 2,
            justifyContent: 'flex-start'
          }}
        >
          <FlexBox
            direction='column'
            style={{
              alignItems: 'left'
            }}
          >
            <Text
              style={{
                fontSize: 50,
                minWidth: 200,
                paddingBottom: Metrics.base
              }}
            >
              {avgScore}/100
            </Text>
            <Text
              style={{
                fontSize: 18,
                color: Colors.textSecondary
              }}
            >
              Average Safety Score
            </Text>
          </FlexBox>
          <FlexBox
            style={{
              paddingRight: Metrics.base
            }}
          >
            <ScoreCircleChart
              score={avgScore}
              maxScore={100}
              color={Colors[getScoreCategory(avgScore)]}
              backgroundColor={Colors.secondary}
              size={110}
            />
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{
            //marginLeft: Metrics.base * 10
            width: 250,
            marginLeft: Metrics.base * 4
          }}
        >
          <TrendIndicator
            size='medium'
            labelFont={'medium'}
            displayFont={'subheading'}
            value={Math.round(avgChangeWeek * 100) / 100}
            suffix='pts'
            label={'Previous Week'}
            direction={'row'}
          />
        </FlexBox>
        <FlexBox
          style={{
            //marginLeft: Metrics.base * 10
            width: 250,
            marginLeft: Metrics.base * 4
          }}
        >
          <TrendIndicator
            size='medium'
            labelFont={'medium'}
            displayFont={'subheading'}
            value={Math.round(avgChangeMonth * 100) / 100}
            suffix='pts'
            label={'Previous Month'}
            direction={'row'}
          />
        </FlexBox>
        <FlexBox
          style={{
            //marginLeft: Metrics.base * 10
            width: 200,
            marginLeft: Metrics.base * 4,
            paddingBottom: Metrics.base * 4
          }}
        >
          <TrendIndicator
            size='medium'
            labelFont={'medium'}
            displayFont={'subheading'}
            value={Math.round(avgChangeYear * 100) / 100}
            suffix='pts'
            label={'Previous Year'}
            direction={'row'}
          />
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

export default AvgSafetyScore
