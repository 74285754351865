import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import GeneralMetricsAvg from '../../../Components/Custom/GeneralMetrics/GeneralMetricsAvg'
import GeneralMetricsHistogram from '../../../Components/Custom/GeneralMetrics/GeneralMetricsHistogram'
import { useTotalDriversDistances } from '../../../Graphql/Queries/Driver/useTotalDriversDistances'
import { checkVal, thousandsSeparator } from '../../../Helpers/Functions'
import { useTimeDrivenDrivers } from '../../../Graphql/Queries/Driver/useTimeDrivenDrivers'

const DriverMetrics = ({ totalCountsData, totalCountsLoading }) => {
  const Colors = useColors()
  const [distanceInputValue, setDistanceInputValue] = useState('all')
  const [timeInputValue, setTimeInputValue] = useState('all')
  const {
    data: { average: totalDistancesAverage, coordinates: totalDistances },
    loading: totalDistancesLoading
  } = useTotalDriversDistances({ period: distanceInputValue })
  const {
    data: { average: timeDrivenAverage, coordinates: timeDriven },
    loading: timeDrivenLoading
  } = useTimeDrivenDrivers({ period: timeInputValue })
  return (
    <FlexBox direction='row' style={{ width: '100%' }}>
      <Subsection
        fillSpace
        style={{
          margin: Metrics.base
        }}
      >
        <GeneralMetricsCard
          title={'Distance Driven'}
          inputValue={distanceInputValue}
          setInputValue={setDistanceInputValue}
        >
          <FlexBox
            direction='column'
            fillSpace
            style={{
              width: '100%'
            }}
          >
            <GeneralMetricsAvg
              average={
                checkVal(totalDistancesAverage) &&
                thousandsSeparator(Math.round(totalDistancesAverage))
              }
              units={'km'}
              title={'distance driven'}
            />
            <GeneralMetricsHistogram
              data={totalDistances}
              loading={totalDistancesLoading}
            />
          </FlexBox>
        </GeneralMetricsCard>
      </Subsection>

      <Subsection
        fillSpace
        style={{
          margin: Metrics.base
        }}
      >
        <GeneralMetricsCard
          inputValue={timeInputValue}
          setInputValue={setTimeInputValue}
          title={'Time Driven'}
        >
          <FlexBox
            direction='column'
            fillSpace
            style={{
              width: '100%'
            }}
          >
            <GeneralMetricsAvg
              average={
                checkVal(timeDrivenAverage) &&
                thousandsSeparator(Math.round(timeDrivenAverage))
              }
              units={'hrs'}
              title={'hours driven'}
            />
            <GeneralMetricsHistogram
              data={timeDriven}
              loading={timeDrivenLoading}
            />
          </FlexBox>
        </GeneralMetricsCard>
      </Subsection>
    </FlexBox>
  )
}

export default DriverMetrics
