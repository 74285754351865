import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import {
  FlexBox,
  Icon,
  InfoBadge,
  ProcessingSpinner,
  Subsection,
  Text
} from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import Status from '../../../Components/Status'
import {
  Display,
  Heading,
  Subheading,
  Title,
  Title2
} from '../../../Components/Typography'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import {
  checkVal,
  hhmmss,
  thousandsSeparator
} from '../../../Helpers/Functions'
import { Images, Metrics, useColors } from '../../../Themes'
import TrendIndicator from '../TrendIndicator'
import { useSafetyScoreTrends } from '../Driver/useSafetyScoreTrends'
import EventsVsTimeScatterPlot from '../Driver/Categories/EventsVsTimeScatterPlot'
import EventsVsTimeChart from '../Driver/Categories/EventsVsTimeChart'
import { CategoryScore } from '../Driver/Overview'
import LargeDivider from '../../../Components/LargeDivider'
import { useAssetSafetyByAssetId } from '../../../Graphql/Queries/Asset/useAssetSafetyByAssetId'
import { useAssetScoreHistory } from '../../../Graphql/Queries/Asset/SafetyScoreHistory/useAssetScoreHistory'
import { useScoreHistory } from './Categories/useScoreHistory'
import ScoreVsTimeChart from '../Driver/Categories/ScoreVsTimeChart'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import { getScoreCategory } from './Breakdown'
import { ScoreExplanation } from '../Trip/Overview'

export const getTotalEvents = (eventTypeCounts) => {
  const keys = Object.keys(eventTypeCounts)
  var count = 0
  for (var i = 0; i < keys.length; i++) {
    const val = eventTypeCounts[keys[i]]
    if (checkVal(val) && !isNaN(Number(val))) count += Number(val)
  }
  return count
}

const AssetOverview = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const assetId = useReactiveVar(selectedAssetId)

  const {
    data: assetSafetyData,
    totalScore,
    maintenanceScore,
    legalScore,
    historyScore,
    loading: assetSafetyLoading
  } = useAssetSafetyByAssetId(assetId)

  const kmSinceLastService = assetSafetyData
    ? assetSafetyData.kmSinceLastService
    : 0
  const kmSinceLastInspection = assetSafetyData
    ? assetSafetyData.kmSinceLastInspection
    : 0
  const totalDistance = assetSafetyData ? assetSafetyData.totalKilometers : 0

  const assetSafetyCategory = assetSafetyData
    ? assetSafetyData.assetSafetyCategory
    : 'EXCELLENT'

  const loading = false

  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useAssetScoreHistory({ assetId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ assetId, scoreHistoryData })

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: mainContainerWidth - Metrics.base * 4,
        justifyContent: 'flex-start'
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        style={{
          width: '100%',
          // height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(assetId) ? (
          assetSafetyData ? (
            <>
              <FlexBox
                style={{
                  paddingLeft: Metrics.base * 2,
                  paddingTop: Metrics.base * 2,
                  paddingBottom: Metrics.base * 2,
                  width: '100%',
                  minHeight: 200
                }}
              >
                <FlexBox
                  fillSpace
                  style={{ flex: 1, alignItems: 'flex-start' }}
                  direction='column'
                >
                  <FlexBox
                    direction='column'
                    style={{
                      width: '100%',
                      alignItems: 'flex-start',
                      padding: Metrics.base * 2,
                      paddingLeft: Metrics.base * 3,
                      paddingBottom: Metrics.base * 4,
                      marginBottom: Metrics.base * 2,
                      borderBottom: `1px solid ${Colors.dividerColor}`
                    }}
                  >
                    <FlexBox
                      direction='row'
                      style={{ marginBottom: Metrics.base }}
                    >
                      <Status
                        status={assetSafetyCategory}
                        getStatusColors={({ status }) =>
                          Colors[assetSafetyCategory]
                        }
                      />
                    </FlexBox>
                    <FlexBox
                      direction='row'
                      style={{ marginBottom: Metrics.base }}
                    >
                      <Display>
                        {totalScore ? `${Math.round(totalScore)}/100` : '-'}
                      </Display>
                    </FlexBox>
                    <Title2 color={Colors.textSecondary} marginTop={1}>
                      Total Asset Score
                    </Title2>
                  </FlexBox>
                  <FlexBox style={{ marginLeft: Metrics.base * 2 }}>
                    <ScoreCircleChart
                      score={checkVal(totalScore) ? totalScore : null}
                      maxScore={100}
                      color={Colors[getScoreCategory(totalScore)]}
                      backgroundColor={Colors.secondary}
                      size={130}
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  direction='column'
                  style={{
                    flex: 3,
                    minWidth: 700,
                    borderLeft: `1px solid ${Colors.dividerColor}`
                  }}
                >
                  {loading ? (
                    <FlexBox style={{ width: 500 }}>
                      <ProcessingSpinner size={40} />
                    </FlexBox>
                  ) : (
                    <FlexBox
                      direction='row'
                      style={{ width: '100%', marginBottom: Metrics.base * 3 }}
                    >
                      <InfoBadge
                        size='small'
                        value={
                          kmSinceLastService
                            ? `${thousandsSeparator(kmSinceLastService)} km`
                            : '-'
                        }
                        label={'Since Last Service'}
                      />
                      <InfoBadge
                        size='small'
                        value={
                          kmSinceLastInspection
                            ? `${thousandsSeparator(kmSinceLastInspection)} km`
                            : '-'
                        }
                        label={'Since Last Inspection'}
                      />
                      <InfoBadge
                        size='small'
                        value={
                          totalDistance
                            ? `${thousandsSeparator(totalDistance)} km`
                            : '-'
                        }
                        label={'Total Distance'}
                      />
                      {/*<InfoBadge
                      size='small'
                      value={`${averageSpeed} km/h`}
                      label={'Average Speed'}
                    />*/}
                    </FlexBox>
                  )}
                  <FlexBox
                    style={{
                      width: '100%',
                      borderTop: `1px solid ${Colors.dividerColor}`,
                      borderBottom: `1px solid ${Colors.dividerColor}`
                    }}
                  >
                    <CategoryScore
                      score={maintenanceScore}
                      category='Maintenance'
                    />
                    <CategoryScore score={legalScore} category='Legal' />
                    <CategoryScore score={historyScore} category='History' />
                  </FlexBox>
                  <FlexBox
                    style={{ width: '100%', marginTop: Metrics.base * 3 }}
                  >
                    <FlexBox style={{ flex: 1 }}>
                      <InfoBadge
                        size='small'
                        value={0}
                        label={'Safety Events Recorded'}
                      />
                    </FlexBox>
                    <FlexBox style={{ flex: 1 }}>
                      <TrendIndicator
                        size='small'
                        value={Math.round(previousMonthChange * 100) / 100}
                        suffix='pts'
                        label={'Previous Month'}
                      />
                    </FlexBox>
                    <FlexBox style={{ flex: 1 }}>
                      <TrendIndicator
                        size='small'
                        value={Math.round(previousYearChange * 100) / 100}
                        suffix='pts'
                        label={'Previous Year'}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
              <ScoreVsTimeChart
                assetId={assetId}
                currentScore={totalScore ? totalScore : 0}
                data={scoreHistory ? scoreHistory : []}
                loading={scoreHistoryLoading}
              />
              <FlexBox
                direction='column'
                style={{
                  width: '100%',
                  borderTop: `1px solid ${Colors.dividerColor}`,
                  marginTop: Metrics.base * 2
                }}
              >
                <ScoreExplanation
                  name='assets'
                  title='The Total Asset Safety Score is the overall indicator of asset safety. It is a weighted sum of the three asset safety categories above.'
                />
              </FlexBox>
              {/*<EventsVsTimeScatterPlot />
            <EventsVsTimeChart
              groupBy='category'
              keys={['SEVERE', 'HEAVY', 'MODERATE', 'LIGHT', 'VERY_LIGHT']}
            />*/}
            </>
          ) : (
            <NoneSelected
              loading={assetSafetyLoading}
              height={
                mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
              }
              displayText='No Score'
              helperText='This asset has not yet been scored'
            />
          )
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select an asset from the list on the left'
          />
        )}
      </Subsection>
    </FlexBox>
  )
}

export default AssetOverview
