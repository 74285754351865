import { Metrics, Colors } from '../../Themes'

const inlineStyles = {
  mainContainer: {
    width: '100%'
  },
  formControl: {
    width: '100%',
    marginBottom: Metrics.base
  },
  dateBetweenContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  dataDisplayContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // paddingTop: Metrics.base,
    paddingBottom: Metrics.base,
    marginBottom: Metrics.base
    // borderBottom: `1px solid ${Colors.backgroundTint}`
  },
  fakeTextFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: Metrics.base,
    paddingBottom: 3,
    marginTop: Metrics.base,
    marginBottom: Metrics.base,
    borderBottom: `1px solid rgba(71, 155, 114, 0.3)`
  }
}

export default inlineStyles
