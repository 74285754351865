import { Colors, Metrics } from '../../Themes'

const Styles = {
  statusDotOuter: {
    width: 16,
    minWidth: 16,
    height: 16,
    marginRight: Metrics.base,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8
  },
  statusDotInner: {
    width: 8,
    height: 8,
    borderRadius: 5
  }
}

export default Styles
