import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import AvgSafetyScore from '../../../Components/Custom/SafetyMetrics/AvgSafetyScore'
import AvgScoreVsTime from '../../../Components/Custom/SafetyMetrics/AvgScoreVsTime'
import CurrentScoreDist from '../../../Components/Custom/SafetyMetrics/CurrentScoreDist'
import TripSafetyCard from '../../../Components/Custom/SafetyMetrics/TripSafetyCard'
import TripSafetyTable from '../../../Components/Custom/SafetyMetrics/TripSafetyTable'
import { useTripSafetyOverTime } from '../../../Graphql/Queries/Trip/useTripSafetyOverTime'
import { useTripSafetyScoreDistribution } from '../../../Graphql/Queries/Trip/useTripSafetyScoreDistribution'
import { useTripSafetyAverages } from '../../../Graphql/Queries/Trip/useTripSafetyAverages'
import TripRankingTable from './TripRankingTable'

const TripSafetyMetrics = () => {
  const Colors = useColors()
  const { data: tripSafetyOverTime, loading: tripSafetyOverTimeLoading } =
    useTripSafetyOverTime({ period: 'year' })
  const {
    data: { average: currentScoreAvg, coordinates: currentScoreDist },
    loading: currentScoreDistLoading
  } = useTripSafetyScoreDistribution({ period: 'none' })
  const { data: safetyAverages, loading: safetyAveragesLoading } =
    useTripSafetyAverages()
  return (
    <>
      <FlexBox
        direction='row'
        fillspace
        style={{
          width: '100%',
          marginTop: Metrics.base * 2
        }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <AvgSafetyScore
            avgChangeMonth={safetyAverages && safetyAverages.averageDiffMonth}
            avgChangeWeek={safetyAverages && safetyAverages.averageDiffWeek}
            avgChangeYear={safetyAverages && safetyAverages.averageDiffYear}
            avgScore={
              safetyAverages &&
              safetyAverages.average &&
              Math.round(safetyAverages.average)
            }
          />
        </FlexBox>
        <FlexBox style={{ flex: 3, padding: Metrics.base }}>
          <AvgScoreVsTime
            data={tripSafetyOverTime}
            loading={tripSafetyOverTimeLoading || safetyAveragesLoading}
            id='tripSafetyOverTime'
            averageScore={safetyAverages && safetyAverages.average}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox style={{ padding: Metrics.base, width: '100%' }}>
        <TripRankingTable />
      </FlexBox>
      <FlexBox
        direction='row'
        style={{
          width: '100%',
          justifyContent: 'stretch',
          alignItems: 'stretch'
        }}
      >
        <FlexBox style={{ flex: 2, padding: Metrics.base }}>
          <CurrentScoreDist
            data={currentScoreDist}
            loading={currentScoreDistLoading}
            title='Trip Safety Scores Distribution'
          />
        </FlexBox>
        <FlexBox style={{ flex: 3, padding: Metrics.base }}>
          <TripSafetyCard />
        </FlexBox>
      </FlexBox>
      {/*<Subsection
        style={{
          //margin: Metrics.base,
          marginLeft: Metrics.base * 4,
          marginRight: Metrics.base * 4

          //padding: Metrics.base * 2
        }}
      >
        <TripSafetyTable />
      </Subsection>*/}
    </>
  )
}

export default TripSafetyMetrics
