import React, { useState, useEffect } from 'react'
import { Metrics } from '../../Themes'
import { MainLayout, TabBar } from '../../Components'
import { checkVal, getUrlId, getUrlParam } from '../../Helpers/Functions'
import SelectedObjectHeader from '../../Components/Custom/SelectedObjectHeader'
import Overview from './Overview'
import InspectionHistory from './Inspections/InspectionHistory'
import ServiceHistory from './Services/ServiceHistory'
import CrashHistory from './Crashes/CrashHistory'
import IncidentHistory from './Incidents/IncidentHistory'
import Inspections from './Inspections/Inspections'
import Compliance from './Compliance'
import Recognition from './Recognition'
import {
  selectedAssetId,
  selectedDriverId,
  selectedTripId,
  selectedTabs
} from '../../Graphql/GraphQLClient'
import TabRoutes from '../../Components/TabRoutes'
import DriverInfo from '../../Components/Custom/InfoBadges/DriverInfo'
import AssetInfo from '../../Components/Custom/InfoBadges/AssetInfo'
import DeviceInfo from '../../Components/Custom/InfoBadges/DeviceInfo'
import { useReactiveVar } from '@apollo/client'
import DriverHeader from '../../Components/Custom/DriverHeader'
import AssetHeader from '../../Components/Custom/AssetHeader'

const Settings = (props) => {
  const { assetSettingsTabs, driverSettingsTabs } = useReactiveVar(selectedTabs)
  const driverId = useReactiveVar(selectedDriverId)
  const assetId = useReactiveVar(selectedAssetId)
  return (
    <MainLayout {...props}>
      <TabRoutes
        renderTabBar={false}
        tabsData={[
          { id: 'assets', name: 'Assets' },
          { id: 'drivers', name: 'Drivers' },
          { id: 'devices', name: 'Devices' }
        ]}
      >
        <>
          {checkVal(assetId) && <AssetHeader assetId={assetId} />}
          <TabRoutes
            defaultId={assetSettingsTabs ? assetSettingsTabs : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), assetSettingsTabs: id })
            }
            tabsData={[
              { id: 'overview', name: 'Overview' },
              { id: 'inspections', name: 'Inspections' },
              { id: 'inspection-history', name: 'Inspection History' },
              { id: 'service-history', name: 'Service History' },
              { id: 'crash-history', name: 'Crash History' },
              { id: 'incident-history', name: 'Incident History' }
            ]}
          >
            <AssetInfo />
            <Inspections {...props} />
            <InspectionHistory {...props} />
            <ServiceHistory {...props} />
            <CrashHistory {...props} />
            <IncidentHistory {...props} />
          </TabRoutes>
        </>
        <>
          {checkVal(driverId) && <DriverHeader driverId={driverId} />}
          <TabRoutes
            defaultId={driverSettingsTabs ? driverSettingsTabs : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), driverSettingsTabs: id })
            }
            tabsData={[
              { id: 'overview', name: 'Overview' },
              { id: 'recognition', name: 'Recognition', hidden: true }
            ]}
          >
            <DriverInfo />
            <Recognition {...props} />
          </TabRoutes>
        </>
        <>
          <DeviceInfo />
        </>
      </TabRoutes>
    </MainLayout>
  )
}
export default Settings
