import React, { useEffect, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { useReactiveVar } from '@apollo/client'
import { tripReplayCamera } from '../../Graphql/GraphQLClient'
import { FlexBox, Text } from '..'

var timerId
var throttleFunction = function (func, delay) {
  if (timerId) {
    return
  }
  timerId = setTimeout(function () {
    func()
    timerId = undefined
  }, delay)
}

export const googleMapsApiKey = 'AIzaSyCfvt-eUHjCQFO1vcHHCrp2_Fgeiwrkezk'

const updatePanorama = ({ panorama, data, center, heading }) => {
  panorama.setPosition(center)
  panorama.setPov({ heading, pitch: 0 })
}

function processSVData({
  data,
  status,
  panorama,
  center,
  heading,
  setHasStreetview
}) {
  if (status == window.google.maps.StreetViewStatus.OK) {
    setHasStreetview(true)
    updatePanorama({ data, center, heading, panorama })
  } else {
    setHasStreetview(false)
  }
}

const loadPano = ({ center, setSvClient, setPanorama, setMapHasLoaded }) => {
  const sv = new window.google.maps.StreetViewService()
  const pano = new window.google.maps.StreetViewPanorama(
    document.getElementById('pano'),
    {
      position: center,
      pov: {
        heading: 34,
        pitch: 10
      }
    }
  )
  setSvClient(sv)
  setPanorama(pano)
  setTimeout(setMapHasLoaded(true), 3000)
}

function TripReplayStreetView({ throttle = true, setMapHasLoaded = () => {} }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey
  })
  const [panorama, setPanorama] = useState()
  const [svClient, setSvClient] = useState(null)
  const [hasStreetview, setHasStreetview] = useState()
  const mapCamera = useReactiveVar(tripReplayCamera)
  const center = mapCamera && mapCamera.center
  const heading = mapCamera && mapCamera.heading

  useEffect(() => {
    if (isLoaded) {
      loadPano({ center, setSvClient, setPanorama, setMapHasLoaded })
    }
  }, [isLoaded])

  useEffect(() => {
    if (svClient && panorama && center) {
      throttle
        ? throttleFunction(
            () =>
              svClient.getPanoramaByLocation(center, 50, (data, status) =>
                processSVData({
                  data,
                  status,
                  panorama,
                  heading,
                  center,
                  setHasStreetview
                })
              ),
            1500
          )
        : svClient.getPanoramaByLocation(center, 50, (data, status) =>
            processSVData({
              data,
              status,
              panorama,
              heading,
              center,
              setHasStreetview
            })
          )
    }
  }, [center, heading, svClient, panorama])

  return isLoaded ? (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 500
      }}
    >
      <div
        id='pano'
        style={{
          width: '100%',
          height: 500
        }}
      ></div>
      <FlexBox
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 500,
          backgroundColor: '#4C525BAA',
          display: hasStreetview ? 'none' : true
        }}
      >
        <FlexBox style={{ width: '100%', height: '100%' }}>
          <Text font='caption' color='white'>
            No street view data for this location.
          </Text>
        </FlexBox>
      </FlexBox>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(TripReplayStreetView)
