import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { abbreviateNumber } from '../../../Helpers/Functions'
import { avgAndCoordinatesFragment } from '../Asset/useTotalDistances'
import { distributionDataTransform } from '../Asset/useAssetSafetyScoreDistribution'

export const GET_DISTRIBUTION = gql`
  query driverSafetyScoreDistribution(
    $orgIdsFilter: [String]
    $assetGroupId: String
  ) {
    driverSafetyScoreDistribution(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

export const useDriverSafetyScoreDistribution = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DISTRIBUTION,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'driverSafetyScoreDistribution',
      errorMessage: 'Failed to fetch driverSafetyScoreDistribution',
      dataTransform: (data) => distributionDataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
