import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const ADD_ASSETS_TO_GROUP = gql`
  mutation addAssetsToGroup($id: String!, $assetIds: [String!]) {
    addAssetsToGroup(id: $id, assetIds: $assetIds) {
      _id
    }
  }
`
export const useAddAssetsToGroup = () => {
  const [addAssets, { loading }] = useMutation(ADD_ASSETS_TO_GROUP, {
    mutationName: 'addAssetsToGroup',
    refetchQueries: ['assetsPaginated'],
    successMessage: 'Assets added successfully',
    errorMessage: 'Failed to add assets'
  })

  return [addAssets, { loading }]
}
