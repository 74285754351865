import React from 'react'
import { FlexBox } from '../../Components'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { checkVal } from '../../Helpers/Functions'
import { Metrics } from '../../Themes'
import LiveSpeedPlot from './LiveSpeedPlot'
import SideBar from './SideBar'
import VideoFeed from './VideoFeed'

const VideoFeedContainer = ({ deviceLocations, locations }) => {
  const { mainContainerHeight } = useAppDimensions()
  const mapHeight = mainContainerHeight - Metrics.base * 11 - 200
  return (
    <FlexBox
      fillSpace
      direction={'column'}
      style={{
        width: '100%',
        justifyContent: 'flex-start',
        padding: Metrics.base * 2
      }}
    >
      <FlexBox
        direction='row'
        style={{
          alignItems: 'flex-start',
          paddingBottom: 0,
          height: '100%',
          width: '100%'
        }}
      >
        <VideoFeed />
        <SideBar
          height={mapHeight}
          locations={locations}
          speedLimitTimeDelay={5000}
        ></SideBar>
      </FlexBox>
      <LiveSpeedPlot
        deviceLocations={deviceLocations}
        // currentMapViewTime={currentMapViewTime}
      />
    </FlexBox>
  )
}

export default React.memo(VideoFeedContainer)
