import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import { FlexBox } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import BarChart from '../../../Components/Custom/D3Charts/BarChart.js'
import AssetScatterPlot from '../D3Charts/AssetScatterPlot'
import MaterialInput from '../../MaterialInput'
import GroupByLegend from '../SafetyMetrics/GroupByLegend'

let data = []
//const Colors = useColors()
for (let i = 0; i < 18; i++) {
  let color = 'red'
  if (i % 3 == 0) {
    color = 'purple'
  } else if (i % 4) {
    color = 'green'
  } else if (i % 5) {
    color = 'orange'
  } else if (i % 6) {
    color = 'orange'
  } else {
    color = 'black'
  }
  data.push({
    _id: i,
    x: 5 * i + 3, //Math.floor(Math.random() * 60),
    y: 0.4 * i + Math.ceil(Math.random() * 2 - 2) + 2,
    color: color
  })
}
data.sort(function (a, b) {
  return a.x - b.x
})
const inputOptionsX = [
  { value: 0, label: 'Age' },
  { value: 1, label: 'Weight' },
  { value: 2, label: 'Capacity' }
]

const inputOptionsY = [
  { value: 0, label: 'Total Safety Score' }, //assetSafetyScore100
  { value: 1, label: 'History Score' }, //historyCategoryNormScore
  { value: 2, label: 'Incident Score' }, //incidentNormScore
  { value: 3, label: 'Last Inspection Score' }, //lastInspectionNormScore
  { value: 4, label: 'Legal Score' }, //legalCategoryNormScore
  { value: 5, label: 'Last Service Score' } //lastServiceNormScore
]

const inputOptionsGroup = [
  { value: 0, label: 'Make' },
  { value: 1, label: 'Model' }
]

const sixMonthsInMilisec = 1.5768e10

const AssetCorrelation = () => {
  const Colors = useColors()
  const loading = false
  const [inputValue, setInputValue] = useState()
  const [width, setWidth] = useState(300)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  return (
    <Subsection
      fillspace
      style={{
        marginTop: Metrics.base,
        marginRight: Metrics.base * 4,
        marginLeft: Metrics.base * 4,
        width: '100%',
        minWidth: 650,
        height: 500
      }}
    >
      <FlexBox
        fillSpace
        direction='column'
        style={{
          width: '100%'
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            margin: Metrics.base * 2
          }}
        >
          Asset Score Correlation
        </Text>
        <FlexBox
          direction='row'
          style={{
            width: '100%',
            //backgroundColor: 'red',
            justifyContent: 'space-left'
          }}
        >
          <FlexBox
            fillSpace
            forwardRef={containerRef}
            style={{
              marginLeft: Metrics.base * 2,
              width: '100%'
            }}
          >
            <AssetScatterPlot
              data={data}
              chartWidth={width - Metrics.base * 8}
              chartHeight={400}
              maxXValue={100}
              maxYValue={10}
              loading={loading}
              //   navigateTo={navigateTo}
            />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              marginRight: Metrics.base * 2,
              alignItems: 'flex-start'
            }}
          >
            <FlexBox direction='row'>
              <Text
                style={{
                  margin: Metrics.base,
                  fontSize: 18
                }}
              >
                x:
              </Text>
              <MaterialInput
                styles={{
                  width: 200,
                  fontSize: 10
                }}
                type='select'
                name='xAxis'
                label={`Filter X Axis`}
                value={inputValue}
                selectValues={inputOptionsX.map((a) => a.value)}
                selectDisplayValues={inputOptionsX.map((a) => a.label)}
                handleChange={(value) => {
                  setInputValue(value)
                }}
              />
            </FlexBox>
            <FlexBox direction='row'>
              <Text
                style={{
                  margin: Metrics.base,
                  fontSize: 18
                }}
              >
                y:
              </Text>
              <MaterialInput
                styles={{
                  width: 200,
                  fontSize: 10,
                  marginRight: Metrics.base * 4
                }}
                type='select'
                name='yAxis'
                label={`Filter Y Axis`}
                value={inputValue}
                selectValues={inputOptionsY.map((a) => a.value)}
                selectDisplayValues={inputOptionsY.map((a) => a.label)}
                handleChange={(value) => {
                  setInputValue(value)
                }}
              />
            </FlexBox>
            <MaterialInput
              styles={{
                width: 200,
                fontSize: 10,
                marginLeft: Metrics.base * 4
              }}
              type='select'
              name='groupBy'
              label={`Group By`}
              value={inputValue}
              selectValues={inputOptionsGroup.map((a) => a.value)}
              selectDisplayValues={inputOptionsGroup.map((a) => a.label)}
              handleChange={(value) => {
                setInputValue(value)
              }}
            />

            <GroupByLegend name={'Mercedez'} color={'HARSH_ACCELERATION'} />
            <GroupByLegend name={'Nissan'} color={'CRASH'} />
            <GroupByLegend name={'Volvo'} color={'TRAVEL_TIME'} />
            <GroupByLegend name={'Chevrolet'} color={'SPEEDING'} />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

export default AssetCorrelation
