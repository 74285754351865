import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { Text } from '../../Components'
import { useColors } from '../../Themes'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const ModifiedPieChart = ({
  hideLabels,
  hideLegend,
  data,
  color,
  colors,
  cornerRadius = 3,
  ...other
}) => {
  const Colors = useColors()
  return (
    <ResponsivePie
      tooltip={() => <></>}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={cornerRadius}
      {...other}
      data={data}
      margin={
        hideLegend
          ? { top: 8, right: 8, bottom: 8, left: 8 }
          : { top: 40, right: 80, bottom: 80, left: 80 }
      }
      // colors={{ scheme: 'blue_green' }}
      colors={
        colors ? colors : [color ? color : Colors.primary, Colors.background]
      }
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableRadialLabels={hideLabels ? false : true}
    />
  )
}
