import React, { useEffect, useState } from 'react'
import { Metrics, useColors } from '../../../Themes'
import { Table } from '../../../Components/Table'
import AlarmForm from './AlarmForm'
import { FlexBox, Status, Subsection, Text } from '../../../Components'
import { Display, Heading } from '../../../Components/Typography'
import { useAlarmsByDeviceId } from '../../../Graphql/Queries/useAlarmsByDeviceId'
import { useReactiveVar } from '@apollo/client'
import { selectedDeviceId } from '../../../Graphql/GraphQLClient'

const columns = (Colors) => [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      ),
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Category',
    accessor: 'category',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'End Time',
    accessor: 'endTime',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const getBorderLeftStyles = (cell, Colors) => {
  if (cell.value) return { borderLeft: `5px solid ${Colors[cell.value]}` }
  return {}
}

const LiveAlarms = () => {
  const Colors = useColors()
  const [dataState, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedObject, setSelectedObject] = useState({})
  const deviceId = useReactiveVar(selectedDeviceId)
  const { data: alarms, loading } = useAlarmsByDeviceId(deviceId)
  const [pageIndex, setPageIndex] = useState(0)

  useEffect(() => {
    setData(alarms)
  }, [deviceId, alarms])

  return (
    <>
      <Table
        handleRowClick={(row) => [
          // setShowModal(true),
          setSelectedObject(row.original)
        ]}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        style={{ width: '100%' }}
        loading={loading}
        columns={columns(Colors)}
        getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
        hideNewButton
        dataState={dataState}
        setData={setData}
        title='Hardware Alarms'
        sortBy={[{ id: 'id' }]}
        defaultPageSize={20}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
        showMutationModal={showModal}
        setShowMutationModal={setShowModal}
        MutationModalComponent={AlarmForm}
      />
    </>
  )
}

export default LiveAlarms
