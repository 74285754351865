import { css } from 'aphrodite'
import React, { Component } from 'react'

import { Colors } from '../../../Themes'
import Styles from './Styles'

export default class ProcessingBar extends Component {
  render() {
    const { color } = this.props

    return (
      <div
        className={css(Styles.mainContainer)}
        style={{ backgroundColor: color || Colors.secondary }}
      />
    )
  }
}
