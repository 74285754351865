import React, { useState } from 'react'
import { FlexBox, Subsection, Text } from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import TabRoutes from '../../../Components/TabRoutes'
import { getSelectedId } from '../../../Components/TabRoutes/TabRoutingBar'
import { useTabs } from '../../../Components/Tabs/useTabs'
import {
  Display,
  Heading,
  Subheading,
  Title2
} from '../../../Components/Typography'
import { selectedDriverId, selectedTabs } from '../../../Graphql/GraphQLClient'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import {
  checkVal,
  getSelectedObject,
  removeTrailingSlashes
} from '../../../Helpers/Functions'
import { Metrics, useColors } from '../../../Themes'
import Combined from './Categories/Combined'
import Compliant from './Categories/Compliant'
import Contextual from './Categories/Contextual'
import RestedFocused from './Categories/RestedFocused'
import SmoothDriving from './Categories/SmoothDriving'
import Speeding from './Categories/Speeding'
import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import ResponsiveGrid from '../../../Components/ResponsiveGrid'
import { useReactiveVar } from '@apollo/client'
import { getScoreCategory } from '../Asset/Breakdown'
import { DescriptionContent } from '../Trip/Breakdown'

export const CategoryScoreTabButton = ({
  score,
  name,
  loading,
  hideBorderRight,
  selected,
  linkToUrl
}) => {
  const Colors = useColors()
  return (
    <Link to={linkToUrl} style={{ width: '100%', height: '100%' }}>
      <FlexBox
        fillSpace
        selectable
        Colors={Colors}
        selected={selected}
        backgroundColor={Colors.subsectionBackground}
        style={{
          width: '100%',
          height: '100%',
          minHeight: 90,
          borderRadius: 10,
          justifyContent: 'space-between',
          paddingLeft: Metrics.base * 2,
          paddingRight: Metrics.base * 2
        }}
      >
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            padding: Metrics.base * 2
          }}
        >
          <Subheading
            loading={loading}
            loadingBarHeight={12}
            loadingBarWidth={50}
          >
            {checkVal(score) ? `${Math.round(score)}/100` : '-'}
          </Subheading>
          <Text
            loading={loading}
            loadingBarHeight={8}
            loadingBarWidth={120}
            color={Colors.textSecondary}
            marginTop={1}
          >
            {name}
          </Text>
        </FlexBox>
        <ScoreCircleChart
          score={checkVal(score) ? score : null}
          maxScore={100}
          color={Colors[getScoreCategory(score)]}
          backgroundColor={Colors.secondary}
          size={70}
        />
      </FlexBox>
    </Link>
  )
}

const DriverScoreBreakdown = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const driverId = useReactiveVar(selectedDriverId)
  // const { driverBehaviour: selectedTabId } = useSelectedTabs()

  const {
    data: driverSafetyData,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEvents,
    smoothDrivingEvents,
    restedFocusedEvents,
    complianceEvents,
    contextualEvents,
    loading: eventsLoading,
    combinedScore: drivingBehaviourScore
  } = useDriverSafetyByDriverId(driverId)

  const eventTypeCounts = driverSafetyData && driverSafetyData.eventTypeCounts

  const tabsData = [
    {
      id: 'combined-trip-score',
      name: 'Combined Trip Score',
      score: combinedScore,
      description:
        'Breakdown of mean combined trip score over 100 previous trips'
    },
    {
      id: 'speeding-score',
      name: 'Speeding Score',
      score: speedingScore,
      description: 'Breakdown of mean speeding score over 100 previous trips'
    },
    {
      id: 'smooth-driving-score',
      name: 'Smooth Driving Score',
      score: smoothDrivingScore,
      description:
        'Breakdown of mean smooth driving score over 100 previous trips'
    },
    {
      id: 'rested-focused-score',
      name: 'Rested/Focused Score',
      score: restedFocusedScore,
      description:
        'Breakdown of mean Rested/Focused score over 100 previous trips'
    },
    {
      id: 'compliant-score',
      name: 'Compliant Score',
      score: compliantScore,
      description: 'Breakdown of mean compliant score over 100 previous trips'
    },
    {
      id: 'contextual-score',
      name: 'Contextual Score',
      score: contextualScore,
      description: 'Breakdown of mean contextual score over 100 previous trips'
    }
  ]
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const pathIds = pathname.split('/')
  const selectedId = getSelectedId({ ids: pathIds, tabsData })
  const selectedTabObject = getSelectedObject({
    id: selectedId,
    key: 'id',
    list: tabsData
  })

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: mainContainerWidth - Metrics.base * 4,
        justifyContent: 'flex-start'
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      {/*checkVal(driverId) && (
        <Subsection style={{ marginTop: Metrics.base * 2 }}>
          <FlexBox
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              paddingLeft: Metrics.base * 5,
              paddingRight: Metrics.base * 5,
              paddingBottom: Metrics.base * 2
              // borderBottom: `1px solid ${Colors.dividerColor}`
            }}
          >
            <FlexBox
              style={{
                borderRadius: 8,
                minWidth: 300,
                minHeight: 90,
                maxHeight: 300,
                margin: Metrics.base * 2
              }}
            >
              <FlexBox
                direction='column'
                style={{
                  alignItems: 'flex-start',
                  padding: Metrics.base * 2,
                  marginRight: Metrics.base * 2
                }}
              >
                <Display
                  loading={eventsLoading}
                  loadingBarHeight={24}
                  loadingBarWidth={100}
                >
                  {checkVal(drivingBehaviourScore)
                    ? `${Math.round(drivingBehaviourScore)}/100`
                    : '-'}
                </Display>
                <Subheading
                  loading={eventsLoading}
                  loadingBarHeight={14}
                  loadingBarWidth={150}
                  color={Colors.textSecondary}
                  marginTop={1}
                >
                  Driving Behaviour
                </Subheading>
              </FlexBox>
              <ScoreCircleChart
                score={
                  checkVal(drivingBehaviourScore) ? drivingBehaviourScore : null
                }
                maxScore={100}
                color={Colors[getScoreCategory(drivingBehaviourScore)]}
                backgroundColor={Colors.secondary}
                size={90}
              />
            </FlexBox>
            <DescriptionContent
              points={[
                `This score is a rolling average of the 100 most recently scored trips for this driver. As a driver completes safer trips, his driving behaviour score will increase across the trip safety categories and totals.`,
                'This accounts for 66% of the Total Driver Safety Score.',
                'Drivers who score low in the Driving Behaviour Category are completing riskier trips on a daily basis.'
              ]}
            />
          </FlexBox>
        </Subsection>
            )*/}
      <Subsection
        style={{
          width: '100%',
          // height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(driverId) ? (
          <>
            <FlexBox
              direction='column'
              style={{
                padding: Metrics.base * 2,
                width: '100%',
                minHeight: 200
              }}
            >
              {selectedTabObject && (
                <FlexBox
                  style={{
                    padding: Metrics.base * 2,
                    paddingBottom: Metrics.base * 4,
                    marginBottom: Metrics.base * 2,
                    borderBottom: `1px solid ${Colors.dividerColor}`,
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                  }}
                >
                  <FlexBox
                    direction='column'
                    style={{
                      alignItems: 'flex-start'
                    }}
                  >
                    <Heading marginBottom={1}>
                      {selectedTabObject.name}:{' '}
                      {checkVal(selectedTabObject.score) &&
                        `${Math.round(selectedTabObject.score)}/100`}
                    </Heading>
                    <Title2>{selectedTabObject.description}</Title2>
                  </FlexBox>
                  {driverSafetyData && checkVal(driverSafetyData.createdAt) && (
                    <Text style={{ color: Colors.textSecondary }}>
                      Last Calculated:{' '}
                      <span style={{ color: Colors.secondary }}>
                        <b>
                          {new Date(
                            driverSafetyData.createdAt
                          ).toLocaleDateString()}
                        </b>
                      </span>
                    </Text>
                  )}
                </FlexBox>
              )}
              <FlexBox
                style={{
                  width: '100%',
                  borderBottom: `1px solid ${Colors.dividerColor}`,
                  paddingBottom: Metrics.base * 3,
                  marginBottom: Metrics.base * 4
                }}
              >
                <ResponsiveGrid style={{ width: '100%' }}>
                  {tabsData.map((item) => (
                    <FlexBox style={{ width: '100%' }}>
                      <CategoryScoreTabButton
                        loading={eventsLoading}
                        score={item.score}
                        selected={selectedId === item.id}
                        name={item.name}
                        linkToUrl={`${removeTrailingSlashes(url)}/${item.id}`}
                        Colors={Colors}
                        hideBorderRight
                      />
                    </FlexBox>
                  ))}
                </ResponsiveGrid>
              </FlexBox>
              <TabRoutes renderTabBar={false} tabsData={tabsData}>
                <Combined
                  score={combinedScore}
                  driverId={driverId}
                  events={[
                    ...speedingEvents,
                    ...smoothDrivingEvents,
                    ...restedFocusedEvents,
                    ...complianceEvents,
                    ...contextualEvents
                  ]}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <Speeding
                  score={speedingScore}
                  driverId={driverId}
                  events={speedingEvents}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <SmoothDriving
                  score={smoothDrivingScore}
                  driverId={driverId}
                  events={smoothDrivingEvents}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <RestedFocused
                  score={restedFocusedScore}
                  driverSafetyData={driverSafetyData}
                  driverId={driverId}
                  events={restedFocusedEvents}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <Compliant
                  score={compliantScore}
                  driverId={driverId}
                  events={complianceEvents}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <Contextual
                  score={contextualScore}
                  driverSafetyData={driverSafetyData}
                  driverId={driverId}
                  events={contextualEvents}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
              </TabRoutes>
            </FlexBox>
          </>
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select a driver from the list on the left'
          />
        )}
      </Subsection>
      {/*selectedTabId && <SelectedTabContent />*/}
    </FlexBox>
  )
}

export default DriverScoreBreakdown
