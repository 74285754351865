import React from 'react'
import { Subheading } from '../../Typography'
import FlexBox from '../../FlexBox'
import Button from '../../Button'
import { checkVal, thousandsSeparator } from '../../../Helpers/Functions'
import InfoBadge from '../../InfoBadge'
import { Metrics, useColors } from '../../../Themes'
import IconButton from '../../IconButton'
import { ProcessingSpinner, Text } from '../..'
import { filters, selectedTabs } from '../../../Graphql/GraphQLClient'
import { Link, useHistory } from 'react-router-dom'

const FiltersContainer = ({
  title,
  totalCount,
  children,
  loading,
  moreFiltersTabName,
  returnToResultsPath,
  handleClearFilters = () => {},
  searchInputRef
}) => {
  const Colors = useColors()
  const history = useHistory()
  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: Colors.background
      }}
    >
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'space-between',
          padding: Metrics.base * 2,
          paddingTop: Metrics.base * 3,
          paddingBottom: Metrics.base * 3,
          backgroundColor: Colors.subsectionBackground,
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        <FlexBox>
          <IconButton
            onClick={() => [
              history.push({
                pathname: returnToResultsPath
              }),
              selectedTabs({
                ...selectedTabs(),
                [moreFiltersTabName]: 'select-card'
              })
            ]}
            color={Colors.primary}
            iconName='arrow_back'
          />
          <Subheading marginLeft={3}>{title}</Subheading>
        </FlexBox>
        <Button
          condensed
          title='Reset'
          onClick={() => {
            handleClearFilters()
            if (searchInputRef && searchInputRef.current)
              searchInputRef.current.value = ''
            filters({
              ...filters(),
              groupId: null,
              orgIds: [],
              orgId: null,
              startDate: null,
              endDate: null
            })
          }}
          textColor='white'
          background='red'
          style={{ marginRight: Metrics.base * 2 }}
        />
      </FlexBox>
      <FlexBox
        style={{
          width: '100%',
          padding: Metrics.base * 2
        }}
      >
        {children}
      </FlexBox>
      {loading ? (
        <ProcessingSpinner size={50} />
      ) : (
        <FlexBox
          fillSpace
          direction='column'
          style={{ marginTop: -Metrics.base * 6 }}
        >
          <FlexBox direction='column'>
            <InfoBadge
              size='large'
              value={
                checkVal(totalCount) && !isNaN(totalCount)
                  ? thousandsSeparator(totalCount)
                  : 0
              }
              label={'Results'}
            />
            <Link
              to={returnToResultsPath}
              onClick={() =>
                moreFiltersTabName &&
                selectedTabs({
                  ...selectedTabs(),
                  [moreFiltersTabName]: 'select-card'
                })
              }
              style={{
                border: `1px solid ${Colors.dividerColor}`,
                borderRadius: Metrics.radius,
                marginBottom: Metrics.base,
                marginTop: Metrics.base * 4
              }}
            >
              <FlexBox
                selectable
                Colors={Colors}
                style={{
                  width: '100%',
                  height: '100%',
                  justifySelf: 'center',
                  borderRadius: Metrics.radius,
                  padding: Metrics.base
                }}
              >
                <Text color={Colors.secondary}>
                  <b>View Results List</b>
                </Text>
              </FlexBox>
            </Link>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default FiltersContainer
