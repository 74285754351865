import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Metrics, useColors, Utils } from '../../Themes'
import { FlexBox, ProcessingSpinner } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import MapView from './MapView'
import LiveAlarms from './LiveAlarms'
import { useSelectedTabs } from '../../Graphql/Queries/useSelectedTabs'
import {
  liveViewMapCenter,
  liveViewMapZoom,
  selectedDeviceId,
  selectedTabs,
  selectedVVTDeviceId,
  TOMTOM_API_KEY
} from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import TabRoutes from '../../Components/TabRoutes'
import VideoFeedContainer from './VideoFeedContainer'
import { useUpdateLiveMarkers } from './useUpdateLiveMarkers'
import { useDebounce } from '../../Components/CustomHooks/useDebounce'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'

const LiveView = () => {
  const deviceId = useReactiveVar(selectedDeviceId)
  const VVTdeviceId = useReactiveVar(selectedVVTDeviceId)
  const { liveMonitoring: selectedTab } = useSelectedTabs()

  const tabOnChange = useCallback((id) => {
    selectedTabs({ ...selectedTabs(), liveMonitoring: id })
  }, [])

  const [map, setMap] = useState(null)
  const [linkedAssetsData, setLinkedAssetsData] = useState({})
  const mapCenter = useReactiveVar(liveViewMapCenter)
  const mapZoom = useReactiveVar(liveViewMapZoom)
  const [mapBounds, setMapBounds] = useState(null)
  const mapElement = useRef()
  const [mapUserInteraction, setMapUserInteraction] = useState(false)
  const [deviceMarkers, setDeviceMarkers] = useState({})
  const [deviceIds, setDeviceIds] = useState([])
  const { loading: locationsLoading, locations: currentDeviceLocations } =
    useUpdateLiveMarkers({
      deviceIds,
      deviceId,
      map,
      deviceMarkers,
      mapUserInteraction,
      setMapUserInteraction
    })

  return (
    <TabRoutes
      defaultId={selectedTab ? selectedTab : null}
      onChange={tabOnChange}
      tabsData={[
        { id: 'map-view', name: 'Map View' },
        {
          id: 'video-feed',
          name: 'Video Feed',
          hidden: !checkVal(deviceId) || !checkVal(VVTdeviceId)
        },
        { id: 'live-alarms', name: 'Live Alarms' }
      ]}
      containerStyle={{
        justifyContent: 'flex-start',
        padding: Metrics.base * 2
      }}
    >
      <MapView
        map={map}
        setMap={setMap}
        mapElement={mapElement}
        mapCenter={mapCenter}
        mapZoom={mapZoom}
        mapBounds={mapBounds}
        setMapBounds={setMapBounds}
        deviceIds={deviceIds}
        setDeviceIds={setDeviceIds}
        linkedAssetsData={linkedAssetsData}
        setLinkedAssetsData={setLinkedAssetsData}
        mapUserInteraction={mapUserInteraction}
        setMapUserInteraction={setMapUserInteraction}
        deviceMarkers={deviceMarkers}
        setDeviceMarkers={setDeviceMarkers}
        locationsLoading={locationsLoading}
        currentDeviceLocations={currentDeviceLocations}
      />
      <VideoFeedContainer />
      <FlexBox
        fillSpace
        direction={'column'}
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          padding: Metrics.base * 2
        }}
      >
        <LiveAlarms />
      </FlexBox>
    </TabRoutes>
  )
}

export default LiveView
