import { StyleSheet } from 'aphrodite'

import { Metrics } from '../../../Themes'

export const inlineStyles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}

const mainContainerAnimationIn = {
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
}

export default StyleSheet.create({
  mainContainer: {
    position: 'fixed',
    zIndex: 9998,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    animationName: mainContainerAnimationIn,
    animationDuration: Metrics.speed.fast,
    animationTimingFunction: 'ease'
  }
})
