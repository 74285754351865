import React, { useState } from 'react'
import { FlexBox } from '../../../../Components'
import AreaChart from '../../../../Components/D3Charts/AreaChart'
import { useAppDimensions } from '../../../../Graphql/Queries/useAppDimensions'
import { Metrics, useColors } from '../../../../Themes'
import BarChart from '../../../../Components/Custom/BarChart'
import { checkVal } from '../../../../Helpers/Functions'
import EventScatterPlot from '../../../../Components/D3Charts/EventScatterPlot'
import { useHistory, useLocation } from 'react-router-dom'
import { useNavigation } from '../../../../Components/CustomHooks/useNavigation'
import { Title } from '../../../../Components/Typography'

const getData = ({ events, Colors, colorType = 'type' }) => {
  const data = []
  for (var i = 0; i < events.length; i++) {
    data.push({
      _id: events[i]._id,
      type: events[i].type,
      value: events[i].normScore,
      date: events[i].startDate,
      color:
        colorType === 'type'
          ? Colors[events[i].type]
          : Colors[events[i].category]
    })
  }
  return data
}

const sixMonthsInMilisec = 1.5768e10
const getEndDate = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 15)
}

const getStartDate = () => {
  const date = getEndDate()
  return new Date(date.getFullYear(), date.getMonth() - 6, 15)
}

const EventsVsTimeScatterPlot = ({ events, title, loading, colorType }) => {
  const { mainContainerWidth } = useAppDimensions()
  const Colors = useColors()
  const [now] = useState(new Date())
  const [sixMonthsAgo] = useState(
    new Date(new Date().getTime() - sixMonthsInMilisec)
  )
  const [endDate] = useState(getEndDate())
  const [startDate] = useState(getStartDate())
  const navigateTo = useNavigation()
  return (
    <FlexBox
      direction='column'
      style={{
        marginLeft: Metrics.base * 2,
        marginTop: Metrics.base * 2,
        marginBottom: Metrics.base * 2
      }}
    >
      <FlexBox
        style={{
          width: '100%'
          // padding: Metrics.base * 2,
          // borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        <Title>{title ? title : 'Events vs Time'}</Title>
      </FlexBox>
      <EventScatterPlot
        data={events ? getData({ events, Colors, colorType }) : []}
        chartWidth={mainContainerWidth - Metrics.base * 8}
        chartHeight={250}
        startDate={startDate}
        endDate={endDate}
        loading={loading}
        navigateTo={navigateTo}
      />
    </FlexBox>
  )
}

export default EventsVsTimeScatterPlot
