import React, { useEffect } from 'react'
import {
  liveViewDeviceLocations,
  liveViewSpeed,
  liveViewSpeedLimit,
  liveViewStopTime,
  liveViewTime
} from '../../Graphql/GraphQLClient'
import { useLastLocations } from '../../Graphql/Queries/useLastLocations'
import { checkVal } from '../../Helpers/Functions'
import { trackingZoom } from './MapView'
import useAkimaAnimation from './useAkimaAnimation'
import useRecentLocationsAllDevices from './useRecentLocationsAllDevices'

const updateDeviceMarkerPosition = ({
  newPosition,
  newHeading,
  deviceId,
  deviceMarkers
}) => {
  if (
    deviceMarkers[deviceId] &&
    checkVal(newPosition.lng) &&
    checkVal(newPosition.lat)
  ) {
    const tempMarker = deviceMarkers[deviceId]
    const newLngLat = [newPosition.lng, newPosition.lat]
    tempMarker.setLngLat(newLngLat)
    if (checkVal(newHeading)) {
      const newRot = (90 + newHeading) % 360
      tempMarker.setRotation(newRot)
    }
  }
}

var timerId
var throttleFunction = function (func, delay) {
  if (timerId) {
    return
  }
  timerId = setTimeout(function () {
    func()
    timerId = undefined
  }, delay)
}

const updateMarker = ({
  map,
  deviceId,
  deviceMarkers,
  mapUserInteraction,
  position,
  heading,
  speed,
  speedLimit,
  time,
  stopTime
}) => {
  if (position && checkVal(position.lng) && checkVal(position.lat)) {
    throttleFunction(() => {
      liveViewSpeed(speed)
      liveViewSpeedLimit(speedLimit)
      liveViewTime(time)
      liveViewStopTime(stopTime)
    }, 1000)
    updateDeviceMarkerPosition({
      newPosition: position,
      newHeading: heading,
      deviceId,
      deviceMarkers
    })
    if (!mapUserInteraction) {
      map.easeTo({
        center: [parseFloat(position.lng), parseFloat(position.lat)],
        bearing: heading % 360,
        zoom: trackingZoom,
        // duration: 500,
        easing: (time) => time
      })
    }
  }
}

export const useUpdateLiveMarkers = ({
  deviceIds,
  deviceId,
  map,
  deviceMarkers,
  mapUserInteraction
}) => {
  const { deviceLocations, loading } = useRecentLocationsAllDevices({
    deviceIds,
    maxLength: 10,
    deviceMarkers,
    map
  })
  const locations = deviceLocations && deviceLocations[deviceId]
  liveViewDeviceLocations(deviceLocations)

  // if selected device not in screen and no map user interaction
  // basically if navigating to live view with asset or device selected
  const isOffScreen = locations === undefined
  const { data: newlocations } = useLastLocations({
    deviceIds: [deviceId],
    skip: !isOffScreen || mapUserInteraction
  })
  useEffect(() => {
    if (
      newlocations &&
      newlocations[0] &&
      newlocations[0].locations &&
      newlocations[0].locations.length > 0
    ) {
      const sortedLocations = newlocations[0].locations.sort(
        (a, b) => a.timestamp - b.timestamp
      )
      const location = sortedLocations[sortedLocations.length - 1].location
      map.easeTo({
        center: [parseFloat(location.lng), parseFloat(location.lat)],
        zoom: trackingZoom
      })
    }
  }, [newlocations])

  // animate live point
  const { isActive } = useAkimaAnimation({
    locations,
    timeStep: 15,
    timeDelay: 30000,
    deviceId,
    mapUserInteraction,
    callback: ({ position, heading, speed, speedLimit, time, stopTime }) =>
      updateMarker({
        position,
        heading,
        speed,
        speedLimit,
        time,
        stopTime,
        map,
        deviceId,
        deviceMarkers,
        mapUserInteraction
      })
  })

  return { loading, locations, isAnimating: isActive }
}
