import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_COACHING_EVENT = gql`
  mutation createCoachingEvent($driverId: String!, $eventId: String!) {
    createCoachingEvent(driverId: $driverId, eventId: $eventId) {
      _id
      displayId
      driver {
        _id
      }
      isActive
      createdAt
      updatedAt
      event {
        _id
      }
    }
  }
`
export const useCreateCoachingEvent = () => {
  const [createCoachingEvent, { loading: createCoachingEventLoading }] =
    useMutation(CREATE_COACHING_EVENT, {
      mutationName: 'createCoachingEvent',
      successMessage: 'Coaching Event created successfully',
      errorMessage: 'Coaching Event creation failed',
      writeToListOnUpdate: true,
      listQueryName: 'coachingEventsByDriverId'
    })
  return [createCoachingEvent, { createCoachingEventLoading }]
}
