import { StyleSheet } from 'aphrodite'

export const Styles = ({
  Colors,
  Metrics,
  selected,
  width,
  borderBottom,
  backgroundColor,
  noBoxShadow,
  cardHeight,
  shadow
}) =>
  StyleSheet.create({
    card: {
      width: width ? width : '100%',
      minWidth: 250,
      backgroundColor:
        backgroundColor && !selected
          ? backgroundColor
          : noBoxShadow
          ? Colors.subsectionBackground
          : Colors.background,
      // padding: Metrics.base,
      paddingBottom: selected ? 0 : Metrics.base,
      paddingTop: selected ? 0 : Metrics.base,
      paddingLeft: selected ? Metrics.base - 1 : Metrics.base,
      paddingRight: selected ? Metrics.base - 1 : Metrics.base,
      //marginBottom: selected ? Metrics.base + 1 : 0,
      //marginTop: selected ? Metrics.base : 0,
      borderBottom: selected
        ? 'none'
        : borderBottom
        ? `1px solid ${Colors.dividerColor}`
        : 'none',
      cursor: 'pointer',
      zIndex: selected ? 100 : 1,
      // transition: 'padding-top 0.5s',
      ':hover': {
        background: selected
          ? Colors.background
          : Colors.selectHighlightOpacity1
      }
    },
    cardInner: {
      width: '100%',
      height: selected ? cardHeight + 2 + Metrics.base * 2 : cardHeight,
      alignItems: 'flex-start',
      backgroundColor: selected
        ? backgroundColor
          ? backgroundColor
          : Colors.selectHighlightOpacity1
        : 'rgba(0,0,0,0)',
      borderRadius: 10,
      border: selected ? `1px solid ${Colors.primaryOpacity3}` : 'none',
      boxShadow: selected && !noBoxShadow ? shadow : 'none',
      padding: Metrics.base * 2,
      transition: 'box-shadow 0.3s',
      ':hover': {
        background: selected ? Colors.selectHighlightOpacity1 : 'rgba(0,0,0,0)'
      }
    },
    tooltipMenuItem: {
      cursor: 'pointer',
      ':hover': {
        background: Colors.selectHighlightOpacity1
      }
    }
  })
