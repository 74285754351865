import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const tripSafetyFragment = gql`
  fragment tripSafetyFragment on TripSafety {
    _id
    numberSpeedingEventsScore
    averageSpeedingScore
    worstSpeedingScore
    percentSpeedingScore
    speedingEventsIDList {
      _id
      type
    }
    speedingCategoryNormScore100
    numberHarshAccScore
    numberHarshCorneringScore
    numberHarshBrakingScore
    averageAccScore
    averageCorneringScore
    averageBrakingScore
    worstAccScore
    worstCorneringScore
    worstBrakingScore
    accelerationCategoryNormScore100
    corneringCategoryNormScore100
    brakingCategoryNormScore100
    smoothDrivingEventIDList {
      _id
      type
    }
    smoothDrivingCategoryNormScore100
    restedFocusedCategoryNormScore100
    restedFocusedEventIDList {
      _id
      type
    }
    complianceCategoryNormScore100
    vehicleLicenseEventScore
    driverLicenseEventScore
    vehicleInspectionEventScore
    phoneMotionCategoryNormScore100
    averagePhoneMotionScore
    worstPhoneMotionScore
    numberPhoneMotionScore
    tipComplianceEventIDList {
      _id
      type
    }
    generalSafetyCategoryNormScore100
    generalSafetyIDList {
      _id
      type
    }
    tripSafetyScore100
    tripSafetyCategory
  }
`

export const GET_TRIP_SAFETY_BY_TRIP_ID = gql`
  query tripSafetyByTripId($tripId: String) {
    tripSafetyByTripId(tripId: $tripId) {
      ...tripSafetyFragment
    }
  }
  ${tripSafetyFragment}
`

const dataTransform = ({ data }) => {
  const combinedScore = data.tripSafetyScore100
  const speedingScore = data.speedingCategoryNormScore100
  const smoothDrivingScore = data.smoothDrivingCategoryNormScore100
  const restedFocusedScore = data.restedFocusedCategoryNormScore100
  const compliantScore = data.complianceCategoryNormScore100
  const contextualScore = data.generalSafetyCategoryNormScore100

  const speedingEventsIds = data.speedingEventsIDList
  const smoothDrivingEventsIds = data.smoothDrivingEventIDList
  const restedFocusedEventsIds = data.restedFocusedEventIDList
  const complianceEventsIds = data.tipComplianceEventIDList
  const contextualEventsIds = data.generalSafetyIDList
  return {
    data,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEventsIds,
    smoothDrivingEventsIds,
    restedFocusedEventsIds,
    complianceEventsIds,
    contextualEventsIds
  }
}

const getVariables = (tripId) => {
  let variables = {
    tripId
  }
  return variables
}

export const useTripSafetyByTripId = (tripId) => {
  const [data, setData] = useState(null)

  const [combinedScore, setCombinedScore] = useState(null)
  const [speedingScore, setSpeedingScore] = useState(null)
  const [smoothDrivingScore, setSmoothDrivingScore] = useState(null)
  const [restedFocusedScore, setRestedFocusedScore] = useState(null)
  const [compliantScore, setCompliantScore] = useState(null)
  const [contextualScore, setContextualScore] = useState(null)

  const [speedingEventsIds, setSpeedingEventsIds] = useState([])
  const [smoothDrivingEventsIds, setSmoothDrivingEventsIds] = useState([])
  const [restedFocusedEventsIds, setRestedFocusedEventsIds] = useState([])
  const [complianceEventsIds, setComplianceEventsIds] = useState([])
  const [contextualEventsIds, setContextualEventsIds] = useState([])

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_TRIP_SAFETY_BY_TRIP_ID,
    {
      variables: getVariables(tripId),
      skip: !tripId,
      queryName: 'tripSafetyByTripId',
      errorMessage: 'Failed to fetch Trip Safety',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData && queryData.data) {
      setData(queryData.data)

      setCombinedScore(queryData.combinedScore)
      setSpeedingScore(queryData.speedingScore)
      setSmoothDrivingScore(queryData.smoothDrivingScore)
      setRestedFocusedScore(queryData.restedFocusedScore)
      setCompliantScore(queryData.compliantScore)
      setContextualScore(queryData.contextualScore)

      setSpeedingEventsIds(queryData.speedingEventsIds)
      setSmoothDrivingEventsIds(queryData.smoothDrivingEventsIds)
      setRestedFocusedEventsIds(queryData.restedFocusedEventsIds)
      setComplianceEventsIds(queryData.complianceEventsIds)
      setContextualEventsIds(queryData.contextualEventsIds)
    } else {
      setData(null)
    }
  }, [queryData])

  return {
    data,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEventsIds,
    smoothDrivingEventsIds,
    restedFocusedEventsIds,
    complianceEventsIds,
    contextualEventsIds,
    loading
  }
}
