import React, { useEffect, useState } from 'react'
import { Metrics, useColors } from '../../Themes'
import {
  DataDisplay,
  FlexBox,
  InfoBadge,
  Subsection,
  Text,
  Button,
  ModalAlert
} from '../../Components'
import { ModifiedPieChart } from '../../Components/Custom/ModifiedPieChart'
import { Display, Subheading, Title } from '../../Components/Typography'
import { useStatusUpdated } from '../../Graphql/Subscriptions/useStatusUpdated'
import { useStatusByDeviceId } from '../../Graphql/Queries/useStatusByDeviceId'
import LargeDivider from '../../Components/LargeDivider'
import GaugeChart from '../../Components/D3Charts/GaugeChart'
import NoneSelected from '../../Components/Custom/NoneSelected'
import { checkVal } from '../../Helpers/Functions'
import tt from '@tomtom-international/web-sdk-services'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'
import {
  liveViewSpeed,
  liveViewSpeedLimit,
  selectedAssetId,
  selectedDeviceId
} from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import useAkimaAnimation from './useAkimaAnimation'
import useRecentLocations from './useRecentLocations'

const StatusDisplayList = ({ status, showAll }) => {
  const Colors = useColors()
  return (
    <>
      {status.ignition ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4, marginTop: Metrics.base }}
          status={status.ignitionOn ? 'ON' : 'OFF'}
          statusColor={status.ignitionOn ? Colors.SAFE : Colors.spot3}
          label='Ignition'
        />
      ) : (
        ''
      )}
      {status.mileage ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={(status.mileage / 1000).toFixed(2) + 'km'}
          label='ODO'
        />
      ) : status.totalOdometer ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={(status.totalOdometer / 1000).toFixed(2) + 'km'}
          label='Total Odometer'
        />
      ) : (
        ''
      )}
      {status.gpsFix ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={status.gpsFix}
          label='GPS Fix'
        />
      ) : status.fuelUsedGps ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={status.fuelUsedGps.toFixed(2) + 'L'}
          label='GPS: Fuel Used'
        />
      ) : (
        ''
      )}

      {/*status.altitude ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={status.altitude + 'm'}
          label='Altitude'
        />
      ) : status.batteryVoltage ? (
        <DataDisplay
          rowStyle={{ height: Metrics.base * 4 }}
          data={status.batteryVoltage.toFixed(2) + 'V'}
          label='Battery Voltage'
        />
      ) : (
        ''
      )*/}
      <DataDisplay
        rowStyle={{ height: Metrics.base * 4 }}
        status={status.vehicleNotMoving}
        statusColor={
          status.vehicleNotMoving === 'MOVING'
            ? Colors.primary
            : Colors.secondary
        }
        label='Movement'
      />

      {showAll && (
        <>
          {checkVal(status.hdd1) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.hdd1}
              label='HDD1'
            />
          )}
          {checkVal(status.hdd2) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.hdd2}
              label='HDD2'
            />
          )}
          {checkVal(status.heading) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.heading}
              label='Heading'
            />
          )}
          {checkVal(status.overspeed) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.overspeed + 'km/h'}
              label='Overspeed'
            />
          )}
          {checkVal(status.satellites) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.satellites}
              label='Satellites'
            />
          )}
          {checkVal(status.speed) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.speed + 'km/h'}
              label='Speed'
            />
          )}
          {checkVal(status.backward) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.backward}
              label='Backward'
            />
          )}
          {checkVal(status.brake) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.brake}
              label='Brake'
            />
          )}
          {checkVal(status.forward) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.forward}
              label='Forward'
            />
          )}
          {checkVal(status.gpsAntenna) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.gpsAntenna}
              label='GPS Antenna'
            />
          )}
          {checkVal(status.turnLeft) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.turnLeft}
              label='Turn Left'
            />
          )}
          {checkVal(status.turnRight) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.turnRight}
              label='Turn Right'
            />
          )}
          {checkVal(status.batteryCurrent) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.batteryCurrent.toFixed(2) + 'A'}
              label='Battery Current'
            />
          )}
          {checkVal(status.batteryVoltage) && status.altitude && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.batteryVoltage.toFixed(2) + 'V'}
              label='Battery Voltage'
            />
          )}
          {checkVal(status.externalVoltage) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.externalVoltage.toFixed(2) + 'V'}
              label='External Voltage'
            />
          )}
          {checkVal(status.fuelUsedGps) && status.gpsFix && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.fuelUsedGps.toFixed(2) + 'L'}
              label='Fuel Used Gps'
            />
          )}
          {checkVal(status.gsmSignal) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.gsmSignal}
              label='GSM Signal'
            />
          )}
          {checkVal(status.iButton) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.iButton}
              label='iButton'
            />
          )}
          {checkVal(status.totalOdometer) && status.mileage && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={(status.totalOdometer / 1000).toFixed(2) + 'km'}
              label='Total Odometer'
            />
          )}
          {checkVal(status.tripOdometer) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={(status.tripOdometer / 1000).toFixed(2) + 'km'}
              label='Trip Odometer'
            />
          )}
          {checkVal(status.gsnssStatus) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.gsnssStatus}
              label='GSNSS Status'
            />
          )}
          {checkVal(status.Towing) && (
            <DataDisplay
              rowStyle={{ height: Metrics.base * 4 }}
              data={status.Towing}
              label='Towing'
            />
          )}
        </>
      )}
    </>
  )
}

const AllStatusModal = ({
  status,
  setShowStatusModal,
  showStatusModal,
  Colors
}) => {
  return (
    <ModalAlert
      title={`Live Statuses`}
      body='Enter details below'
      openDialog={showStatusModal}
      setOpenDialog={setShowStatusModal}
      maxWidth='sm'
      // width={500}
    >
      <Subsection>
        <StatusDisplayList status={status} showAll={true} />
      </Subsection>
    </ModalAlert>
  )
}

const SpeedLimit = ({ value }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: 70,
        height: '100%',
        marginLeft: Metrics.base /*, backgroundColor: 'green'*/
      }}
    >
      <FlexBox
        style={{
          width: 40,
          height: 40,
          border: `4px solid rgba(220,0,0,0.4)`,
          borderRadius: 25,
          marginBottom: Metrics.base * 2,
          marginTop: Metrics.base * 2
        }}
      >
        <Text style={{ fontSize: 12 }} color={Colors.secondary}>
          <b>{value ? value : '-'}</b>
        </Text>
      </FlexBox>
      <Text
        font='caption'
        color={Colors.secondaryOpacity1} /*style={{ backgroundColor: 'red' }}*/
      >
        Speed Limit
      </Text>
    </FlexBox>
  )
}

const MaxSpeed = ({ value = 80 }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: 70,
        height: '100%',
        marginRightt: Metrics.base /*, backgroundColor: 'green'*/
      }}
    >
      <FlexBox
        style={{
          width: 40,
          height: 40,
          borderRadius: 5,
          border: `4px solid ${Colors.secondaryOpacity3}`,
          marginBottom: Metrics.base * 2,
          marginTop: Metrics.base * 2
        }}
      >
        <Text style={{ fontSize: 12 }} color={Colors.secondary}>
          <b>{value}</b>
        </Text>
      </FlexBox>
      <Text
        font='caption'
        color={Colors.secondaryOpacity1}
        style={{ textAlign: 'center' }}
      >
        Vehicle Max Speed
      </Text>
    </FlexBox>
  )
}

export const SpeedometerDisplay = ({
  speed: speedIn,
  speedLimit: speedLimitIn,
  vehicleMaxSpeed
}) => {
  const Colors = useColors()
  const lvSpeed = useReactiveVar(liveViewSpeed)
  const speed = checkVal(speedIn) ? speedIn : lvSpeed
  const lvSpeedLimit = useReactiveVar(liveViewSpeedLimit)
  const speedLimit = checkVal(speedLimitIn) ? speedLimitIn : lvSpeedLimit
  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <FlexBox
        style={{
          paddingTop: Metrics.base,
          paddingBottom: Metrics.base,
          marginBottom: Metrics.base,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          width: '100%'
          // backgroundColor: 'red'
        }}
      >
        <GaugeChart
          value={speed}
          orangeValue={speedLimit > 0 ? speedLimit : 60}
          redValue={80}
          maxValue={140}
        />
      </FlexBox>
      <FlexBox
        direction='row'
        style={{
          // alignItems: 'flex-start',
          width: '100%',
          paddingBottom: Metrics.base * 3,
          borderBottom: `1px solid ${Colors.dividerColor}`
          // padding: Metrics.base * 2
        }}
      >
        <SpeedLimit value={speedLimit} />
        <FlexBox style={{ width: 150 }}>
          <InfoBadge
            size='medium'
            // bold
            // fontWeight={350}
            displayTextProps={{
              fontWeight: 450,
              fontSize: 26,
              color: Colors.secondary
            }}
            value={Math.round(speed)}
            suffix='km/h'
            // label='SPEED'
            status={
              checkVal(speed) && checkVal(speedLimit)
                ? speed <= speedLimit
                  ? 'not speeding'
                  : 'speeding'
                : '-'
            }
            statusColor={speed >= speedLimit ? '#EC3C3C' : Colors.primary}
          />
        </FlexBox>
        <MaxSpeed value={vehicleMaxSpeed} />
      </FlexBox>
    </FlexBox>
  )
}

const SideBar = ({ height }) => {
  const Colors = useColors()
  const [showStatusModal, setShowStatusModal] = useState(false)
  const deviceId = useReactiveVar(selectedDeviceId)
  const { data: status } = useStatusByDeviceId(deviceId)
  const assetId = useReactiveVar(selectedAssetId)
  const { data: asset } = useAsset(assetId)

  const vehicleMaxSpeed = asset && asset.maxSpeed ? asset.maxSpeed : 80

  return (
    <Subsection
      style={{
        width: Metrics.sideBarWidth,
        height: height,
        marginLeft: Metrics.base * 2
      }}
    >
      {checkVal(deviceId) ? (
        <FlexBox
          direction='column'
          style={{
            height: height ? height : 650,
            width: '100%',
            justifyContent: 'flex-start'
          }}
        >
          <SpeedometerDisplay vehicleMaxSpeed={vehicleMaxSpeed} />
          {status && (
            <>
              <StatusDisplayList status={status} />
              <Button
                title='View All'
                condensed
                onClick={() => setShowStatusModal(true)}
                textColor='white'
                background='red'
                style={{ marginTop: Metrics.base * 2 }}
              />
            </>
          )}
        </FlexBox>
      ) : (
        <NoneSelected
          width={'70%'}
          helperText='Select a driver or vehicle from the list on the left'
        />
      )}
      {showStatusModal && (
        <AllStatusModal
          showStatusModal={showStatusModal}
          status={status}
          setShowStatusModal={setShowStatusModal}
          Colors={Colors}
        />
      )}
    </Subsection>
  )
}

export default SideBar
