import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { eventDataTransform, eventFragment } from './useEvent'
import gql from 'graphql-tag'
import { inTripTypes } from '../../GraphQLClient'

export const GET_EVENTS = gql`
  query events($tripId: String, $skip: Int, $limit: Int) {
    events(tripId: $tripId, skip: $skip, limit: $limit) {
      totalCount
      events {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`

const getTransformedEvent = (event) => {
  return {
    ...event,
    startDate: new Date(event.startDate.getTime() - 2000),
    endDate: new Date(event.startDate.getTime() + 2000)
  }
}

const dataTransform = ({ data }) => {
  const events = []
  const speeding = []
  const phoneMotion = []
  const harshBraking = []
  const harshAccelerating = []
  const harshCornering = []
  const tripTotals = []
  const inTrip = []
  let tripDistance = null
  let travelTime = null
  let drivingTime = null
  let percentSpeeding = null
  if (data.events) {
    for (var i = 0; i < data.events.length; i++) {
      const event = eventDataTransform({ data: data.events[i] })
      let transformedEvent = { ...event }
      if (event.type === 'SPEEDING') speeding.push(transformedEvent)
      if (event.type === 'PHONE_MOTION') phoneMotion.push(transformedEvent)
      if (event.type === 'HARSH_BRAKING') {
        transformedEvent = getTransformedEvent(event)
        harshBraking.push(transformedEvent)
      }
      if (event.type === 'HARSH_ACCELERATION') {
        transformedEvent = getTransformedEvent(event)
        harshAccelerating.push(transformedEvent)
      }
      if (event.type === 'HARSH_CORNERING') {
        transformedEvent = getTransformedEvent(event)
        harshCornering.push(transformedEvent)
      }
      if (inTripTypes.includes(event.type)) inTrip.push(transformedEvent)
      if (
        event.type === 'TRIP_DISTANCE' ||
        event.type === 'TRAVEL_TIME' ||
        event.type === 'DRIVING_TIME' ||
        event.type === 'PRECENT_SPEEDING'
      )
        tripTotals.push(event)
      if (event.type === 'TRIP_DISTANCE') tripDistance = event
      if (event.type === 'TRAVEL_TIME') travelTime = event
      if (event.type === 'DRIVING_TIME') drivingTime = event
      if (event.type === 'PRECENT_SPEEDING') percentSpeeding = event

      events.push(transformedEvent)
    }
  }
  return {
    events,
    totalCount: data.totalCount,
    speeding,
    phoneMotion,
    harshBraking,
    harshAccelerating,
    harshCornering,
    inTrip,
    tripTotals,
    tripDistance,
    travelTime,
    drivingTime,
    percentSpeeding
  }
}

const getEventsVariables = (tripId) => {
  let variables = {
    tripId,
    skip: 0,
    limit: 100000
  }
  return variables
}

export const useEventsByTripId = (tripId) => {
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState([])
  const [speeding, setSpeeding] = useState([])
  const [phoneMotion, setPhoneMotion] = useState([])
  const [harshBraking, setHarshBraking] = useState([])
  const [harshAccelerating, setHarshAccelerating] = useState([])
  const [harshCornering, setHarshCornering] = useState([])
  const [inTrip, setInTrip] = useState([])
  const [tripTotals, setTripTotals] = useState([])
  const [tripDistance, setTripDistance] = useState()
  const [travelTime, setTravelTime] = useState()
  const [drivingTime, setDrivingTime] = useState()
  const [precentSpeeding, setPercentSpeeding] = useState([])

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_EVENTS, {
    variables: getEventsVariables(tripId),
    skip: !tripId,
    queryName: 'events',
    errorMessage: 'Failed to fetch Events',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData && queryData.events) {
      // all data
      setData(queryData.events)
      setTotalCount(queryData.totalCount)
      // time-based
      setSpeeding(queryData.speeding)
      setPhoneMotion(queryData.phoneMotion)
      setHarshBraking(queryData.harshBraking)
      setHarshAccelerating(queryData.harshAccelerating)
      setHarshCornering(queryData.harshCornering)
      // all time-based
      setInTrip(
        queryData.inTrip && queryData.inTrip.length > 0
          ? queryData.inTrip.sort(
              (a, b) => a.startDate.getTime() - b.startDate.getTime()
            )
          : []
      )
      // all totals
      setTripTotals(queryData.tripTotals)
      // individual totals
      setTripDistance(queryData.tripDistance)
      setTravelTime(queryData.travelTime)
      setDrivingTime(queryData.drivingTime)
      setPercentSpeeding(queryData.precentSpeeding)
    }
  }, [queryData])

  return {
    data,
    totalCount,
    speeding,
    phoneMotion,
    harshBraking,
    harshAccelerating,
    harshCornering,
    inTrip,
    tripTotals,
    tripDistance,
    travelTime,
    drivingTime,
    precentSpeeding,
    loading
  }
}
