import React from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import Text from '../Text'
import Status from '../Status'
import { FlexBox, Icon } from '..'
import { useNavigation } from '../CustomHooks/useNavigation'
import SelectedObjectHeader from './SelectedObjectHeader'
import { checkVal } from '../../Helpers/Functions'
import { selectedTripId } from '../../Graphql/GraphQLClient'
import EventCardTTList from './SelectionPanel/Event/EventCardTTList'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import { Subheading } from '../Typography'

const EventHeader = ({ eventId, handleClose }) => {
  const Colors = useColors()
  const { data, loading: eventLoading } = useEvent(eventId)
  const navigateTo = useNavigation()
  return (
    <SelectedObjectHeader
      id={eventId}
      handleClose={handleClose}
      navigateTo={navigateTo}
      TooltipContent={({ setShowTooltip }) => (
        <EventCardTTList
          eventId={eventId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      loading={eventLoading}
      titleContent={
        <FlexBox>
          {data.type && Images[data.type] && (
            <img
              width={18}
              style={{ marginRight: Metrics.base }}
              alt={`${data.type}_icon`}
              src={Images[data.type]}
            />
          )}
          <Subheading
            loading={eventLoading}
            loadingBarWidth={100}
            loadingBarHeight={20}
            color='white'
          >
            {data && data.name}
          </Subheading>
        </FlexBox>
      }
      centerContent={
        data &&
        checkVal(data.displayId) && (
          <Text color='white'>{`Event ${data.displayId}`}</Text>
        )
      }
      rightContent={
        <FlexBox direction='row'>
          <FlexBox
            style={{
              marginLeft: Metrics.base * 2,
              borderRadius: Metrics.radius,
              backgroundColor: Colors.background
            }}
          >
            <Status
              status={data.severityCategoryDisplayText}
              getStatusColors={() => Colors[data.severityCategory]}
            ></Status>
          </FlexBox>
        </FlexBox>
      }
    />
  )
}

export default EventHeader
