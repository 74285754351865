import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const FORGOT_USER_PASSWORD_EMAIL = gql`
  mutation forgotUserPasswordEmail($userEmail: String!) {
    forgotUserPasswordEmail(userEmail: $userEmail)
  }
`
export const useForgotUserPasswordEmail = () => {
  const [sendEmail, { loading }] = useMutation(FORGOT_USER_PASSWORD_EMAIL, {
    mutationName: 'forgotUserPasswordEmail',
    successMessage: 'Email sent'
    // errorMessage: 'Failed to send email'
  })

  return [sendEmail, { loading }]
}
