import React, { useEffect, useState } from 'react'
import { FlexBox, Button, Form } from '../../../'
import { Metrics, useColors } from '../../../../Themes'
import {
  filters,
  selectedTripId,
  tripFilters
} from '../../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { SelectObjectCard, SelectScoreBetween } from '../Event/EventFilters'
import { safetyCategoriesOptions } from '../Driver/DriverFilters'
import { Dialog } from '@material-ui/core'
import DriverSelectionTable from '../Driver/DriverSelectionTable'
import AssetSelectionTable from '../Asset/AssetSelectionTable'
import { InsetCardContainer } from '../Cards/CardsContainer'
import DriverCard from '../Driver/DriverCard'
import AssetCard from '../Asset/AssetCard'
import SearchBar from '../SearchBar'
import { useUser } from '../../../../Graphql/Queries/useUser'
import { useAssetGroups } from '../../../../Graphql/Queries/Asset/useAssetGroups'

const filterByTypeNameOptions = [
  { value: 'driver', label: 'Driver' },
  { value: 'asset', label: 'Asset' }
  // { value: 'device', label: 'Device' }
]

export const handleClearFilters = () => {
  tripFilters({
    ...tripFilters(),
    searchString: null,
    minSafetyScore: null,
    maxSafetyScore: null,
    safetyCategories: null,
    driverId: null,
    assetId: null,
    deviceId: null,
    filterByTypeName: null
  })
}

const TripFilters = ({
  direction,
  innerFormStyles,
  minWidth,
  maxInputWidth,
  singleOrgSelect,
  searchInputRef
}) => {
  const [showSelectDriverModal, setShowSelectDriverModal] = useState(false)
  const [showSelectAssetModal, setShowSelectAssetModal] = useState(false)

  const {
    searchString,
    minSafetyScore,
    maxSafetyScore,
    safetyCategories,
    driverId,
    assetId,
    deviceId,
    filterByTypeName
  } = useReactiveVar(tripFilters)

  const { orgId, orgIds, groupId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  const { data: assetGroups, loading } = useAssetGroups()
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    if (assetGroups && assetGroups.length > 0) {
      setGroupOptions([...assetGroups, { name: 'Ungrouped', _id: 'NO_GROUP' }])
    }
  }, [assetGroups])

  const innerFormStyle = {
    minHeight: 0,
    flexGrow: 1,
    paddingLeft: Metrics.base * 3,
    paddingRight: Metrics.base * 3,
    ...innerFormStyles
  }

  return (
    <FlexBox
      style={{
        width: '100%',
        minWidth: minWidth ? minWidth : 'auto'
      }}
    >
      <Form
        hideSubmit
        formbox={false}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'none',
          boxShadow: 'none',
          width: '100%'
        }}
        maxInputWidth={maxInputWidth}
        innerFormStyles={{ paddingBottom: 0 }}
        hideFormBox
        darkmode={true}
        direction={direction ? direction : 'column'}
        data={[
          {
            name: 'searchTrips',
            renderInput: (props) => {
              return (
                <SearchBar
                  title='Trips'
                  inputString={searchString}
                  setInputString={(value) => {
                    tripFilters({ ...tripFilters(), searchString: value })
                  }}
                  searchInputRef={searchInputRef}
                />
              )
            }
          },
          {
            name: 'orgId',
            value: orgId ? orgId : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgId: [...value] })
            },
            label: 'Organisation',
            type: 'select',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'orgIds',
            value: orgIds ? orgIds : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgIds: [...value] })
            },
            label: 'Organisation',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'groupIds',
            value: groupId ? groupId : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), groupId: value })
            },
            label: 'Asset Group',
            type: 'select',
            style: innerFormStyle,
            options: groupOptions
              ? groupOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect
          },
          {
            name: 'safetyCategories',
            value: safetyCategories ? safetyCategories : [],
            controlled: true,
            handleChange: (value) => {
              tripFilters({ ...tripFilters(), safetyCategories: value })
              selectedTripId(null)
            },
            label: 'Safety Categories',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: safetyCategoriesOptions
          },
          {
            name: 'filterByTypeName',
            value: filterByTypeName,
            controlled: true,
            style: innerFormStyle,
            handleChange: (value) => {
              tripFilters({ ...tripFilters(), filterByTypeName: value })
            },
            label: 'Filter by',
            type: 'select',
            options: filterByTypeNameOptions
          },
          {
            name: 'driver',
            renderInput: (props) => {
              return driverId ? (
                <InsetCardContainer
                  name='Select Driver'
                  style={{
                    marginTop: Metrics.base * 2,
                    marginBottom: Metrics.base * 2
                  }}
                >
                  <DriverCard
                    driverId={driverId}
                    onClick={() => setShowSelectDriverModal(true)}
                  />
                </InsetCardContainer>
              ) : (
                <SelectObjectCard
                  onClick={() => setShowSelectDriverModal(true)}
                  filterByTypeName={filterByTypeName}
                />
              )
            },
            display: () => filterByTypeName === 'driver'
          },
          {
            name: 'asset',
            renderInput: (props) => {
              return assetId ? (
                <InsetCardContainer
                  name='Select Asset'
                  style={{
                    marginTop: Metrics.base * 2,
                    marginBottom: Metrics.base * 2
                  }}
                >
                  <AssetCard
                    assetId={assetId}
                    onClick={() => setShowSelectAssetModal(true)}
                  />
                </InsetCardContainer>
              ) : (
                <SelectObjectCard
                  onClick={() => setShowSelectAssetModal(true)}
                  filterByTypeName={filterByTypeName}
                />
              )
            },
            display: () => filterByTypeName === 'asset'
          }

          /*{
            name: 'scoreRange',
            label: 'Score Range',
            style: innerFormStyle,
            value: {
              minScore: minSafetyScore,
              maxScore: maxSafetyScore
            },
            controlled: true,
            handleChange: (value) => {
              tripFilters({
                ...tripFilters(),
                minSafetyScore: value.minScore,
                maxSafetyScore: value.maxScore
              })
            },
            renderInput: (props) => {
              return (
                <SelectScoreBetween
                  value={props.value}
                  handleChange={props.handleChange}
                  Colors={Colors}
                />
              )
            }
          }*/
        ]}
      />
      <Dialog
        open={showSelectDriverModal}
        maxWidth='md'
        fullWidth
        // style={{ width: mainContainerWidth, maxWidth: mainContainerWidth }}
        onClose={() => setShowSelectDriverModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DriverSelectionTable
          setDriverId={(id) => [
            tripFilters({ ...tripFilters(), driverId: id, assetId: null }),
            selectedTripId(null),
            setShowSelectDriverModal(false)
          ]}
        />
      </Dialog>
      <Dialog
        open={showSelectAssetModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowSelectAssetModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <AssetSelectionTable
          setAssetId={(id) => [
            tripFilters({ ...tripFilters(), assetId: id, driverId: null }),
            selectedTripId(null),
            setShowSelectAssetModal(false)
          ]}
        />
      </Dialog>
    </FlexBox>
  )
}
export default TripFilters
