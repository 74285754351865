import React, { useEffect, useState } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  selectedDeviceId,
  selectedVVTDeviceId
} from '../../../../Graphql/GraphQLClient'
import DeviceCard from './DeviceCard'
import { useDeviceIds } from '../../../../Graphql/Queries/Device/useDeviceIds'
import { useHistory, useLocation } from 'react-router-dom'
import { handleSelectedIdChange, handleUrlIdChange } from '..'
import { useReactiveVar } from '@apollo/client'

const DevicesList = ({
  CardComponent = DeviceCard,
  setShowDeviceAssetModal
}) => {
  const deviceId = useReactiveVar(selectedDeviceId)
  const [searchInputString, setSearchInputString] = useState(null)
  const {
    data: deviceIds,
    totalCount,
    fetchMore,
    networkStatus
  } = useDeviceIds(searchInputString)
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (deviceIds.length === 1) {
      selectedDeviceId(deviceIds[0].deviceId)
      selectedVVTDeviceId(
        deviceIds[0].VVTdeviceId ? deviceIds[0].VVTdeviceId : null
      )
    }
  }, [deviceIds])

  /*useEffect(() => {
    handleUrlIdChange({ location, selectedId: deviceId, IdName: 'device' })
  }, [location])*/

  useEffect(() => {
    handleSelectedIdChange({
      selectedId: deviceId,
      IdName: 'device',
      location,
      history,
      urlSearchParams
    })
  }, [deviceId, location])

  return (
    <>
      <CardsHeader
        title='Devices'
        number={totalCount}
        items={deviceIds}
        searchInputString={searchInputString}
        setSearchInputString={setSearchInputString}
        addButton={
          location && location.pathname === 'settings' ? 'Device' : null
        }
        hideFilterButton
        addButtonOnClick={() => setShowDeviceAssetModal(true)}
      />
      <CardsContainer fetchMore={fetchMore} networkStatus={networkStatus}>
        <CardsList
          items={deviceIds}
          itemsName='Devices'
          selectedItemId={deviceId}
        >
          {({ items, item, index }) => (
            <CardComponent
              data={item}
              deviceId={item._id}
              index={index}
              key={index}
              selected={item._id === deviceId}
              history={history}
              urlSearchParams={urlSearchParams}
              linkToPathName={location && location.pathname}
              borderBottom={
                index + 1 >= items.length ||
                (items[index + 1] && items[index + 1]._id !== deviceId)
              }
            />
          )}
        </CardsList>
      </CardsContainer>
    </>
  )
}

export default DevicesList
