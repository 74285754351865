import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useStatusUpdated } from '../Subscriptions/useStatusUpdated'
import { checkVal } from '../../Helpers/Functions'

export const statusFragment = gql`
  fragment statusFragment on Status {
    _id
    device {
      _id
      deviceId
    }
    altitude
    gpsFix
    hdd1
    hdd2
    heading
    ignition
    mileage
    overspeed
    satellites
    speed
    timestamp
    vehicleNotMoving
    backward
    brake
    forward
    gpsAntenna
    turnLeft
    turnRight
    batteryCurrent
    batteryVoltage
    externalVoltage
    fuelUsedGps
    gsmSignal
    iButton
    totalOdometer
    tripOdometer
    gsnssStatus
    Towing
  }
`

export const GET_STATUS_BY_DEVICE_ID = gql`
  query statusByDeviceId($internalDeviceId: String) {
    statusByDeviceId(internalDeviceId: $internalDeviceId) {
      ...statusFragment
    }
  }
  ${statusFragment}
`

export const statusDataTransform = ({ data }) => {
  const ignitionOn =
    data.ignition && (data.ignition === 1 || data.ignition === 'IGNITION_ON')
      ? true
      : false
  const isMoving = checkVal(data.vehicleNotMoving)
    ? data.vehicleNotMoving === 1 ||
      data.vehicleNotMoving === '1' ||
      data.vehicleNotMoving === 'MOVING'
    : ignitionOn
  return {
    ...data,
    isMoving,
    ignitionOn,
    isParked: !ignitionOn && !isMoving
  }
}

export const useStatusByDeviceId = (internalDeviceId) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    error
  } = useQuery(GET_STATUS_BY_DEVICE_ID, {
    variables: { internalDeviceId },
    queryName: 'statusByDeviceId',
    errorMessage: 'Failed to fetch Status',
    dataTransform: (data) => statusDataTransform({ data }),
    skip: !internalDeviceId
  })

  // console.log(error)

  const { data: subscriptionData, loading: subscriptionLoading } =
    useStatusUpdated(internalDeviceId)

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    } else {
      setData(null)
    }
  }, [queryData])

  useEffect(() => {
    subscriptionData && setData(subscriptionData)
  }, [subscriptionData])

  return { data, loading }
}
