import { StyleSheet } from 'aphrodite'
export const Styles = ({ Color1, Color2, Colors = {} }) =>
  StyleSheet.create({
    selectable: {
      fill: Color1,
      cursor: 'pointer',
      ':hover': {
        fill: Color2
      }
      // transition: 'height 0.2s'
    },
    dotOuter: {
      fill: 'white',
      stroke: Color1,
      cursor: 'pointer',
      ':hover': {
        stroke: Color2
      }
      // transition: 'height 0.2s'
    },
    dotInner: {
      fill: Color1
    },
    timeCursorOuter: {
      fill: '#00000000',
      stroke: 'none',
      cursor: 'pointer',
      transition: 'fill 0.2s',
      ':hover': {
        stroke: Colors.primaryOpacity3,
        fill: Colors.primaryOpacity4
      }
    }
  })
