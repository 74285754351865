import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import { FlexBox, InfoBadge, Text } from '../../../../Components'
import { Colors, Metrics, useColors } from '../../../../Themes'
import TrendIndicator from '../../TrendIndicator'
import { getTotalEvents } from './CategorySummary'

const SeverityPieChartTimeBased = ({ severityCounts }) => {
  const Colors = useColors()
  return (
    <div
      style={{
        margin: Metrics.base * 3,
        width: 110,
        height: 110
      }}
    >
      <ResponsivePie
        innerRadius={0.85}
        padAngle={0}
        cornerRadius={0}
        // startAngle={-180}
        // endAngle={180}
        enableArcLinkLabels={false}
        enableSlicesLabels={false}
        enableArcLabels={false}
        isInteractive
        data={[
          {
            id: 'severe',
            label: 'Severe',
            value: severityCounts.SEVERE
          },
          {
            id: 'heavy',
            label: 'Heavy',
            value: severityCounts.HEAVY
          },
          {
            id: 'moderate',
            label: 'Moderate',
            value: severityCounts.MODERATE
          },
          {
            id: 'light',
            label: 'Light',
            value: severityCounts.LIGHT
          },
          {
            id: 'veryLight',
            label: 'Very Light',
            value: severityCounts.VERY_LIGHT
          }
        ]}
        margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
        // colors={{ scheme: 'blue_green' }}
        colors={[
          Colors.SEVERE,
          Colors.HEAVY,
          Colors.MODERATE,
          Colors.LIGHT,
          Colors.VERY_LIGHT
        ]}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableRadialLabels={false}
      />
    </div>
  )
}

const SeverityPieChartTripTotals = ({ severityCounts }) => {
  const Colors = useColors()
  return (
    <div
      style={{
        margin: Metrics.base * 3,
        width: 110,
        height: 110
      }}
    >
      <ResponsivePie
        innerRadius={0.85}
        padAngle={0}
        cornerRadius={0}
        // startAngle={-180}
        // endAngle={180}
        enableArcLinkLabels={false}
        enableSlicesLabels={false}
        enableArcLabels={false}
        isInteractive
        data={[
          {
            id: 'veryLong',
            label: 'Very Long',
            value: severityCounts.VERY_LONG
          },
          {
            id: 'long',
            label: 'Long',
            value: severityCounts.LONG
          },
          {
            id: 'medium',
            label: 'Medium',
            value: severityCounts.MEDIUM
          },
          {
            id: 'shrot',
            label: 'Short',
            value: severityCounts.SHORT
          },
          {
            id: 'veryShort',
            label: 'Very Short',
            value: severityCounts.VERY_SHORT
          }
        ]}
        margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
        // colors={{ scheme: 'blue_green' }}
        colors={[
          Colors.VERY_LONG,
          Colors.LONG,
          Colors.MEDIUM,
          Colors.SHORT,
          Colors.VERY_SHORT
        ]}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableRadialLabels={false}
      />
    </div>
  )
}

const SeverityType = ({ color, name }) => {
  return (
    <FlexBox style={{ marginBottom: Metrics.base }}>
      <div
        style={{
          width: 10,
          height: 10,
          backgroundColor: color,
          marginRight: Metrics.base * 2
        }}
      />
      <Text>{name}</Text>
    </FlexBox>
  )
}

const CategorySummarySingleEvent = ({
  eventCategoryCounts,
  eventsCounts,
  previousWeekChange,
  previousMonthChange,
  previousYearChange,
  type = 'timeBased'
}) => {
  const totalEvents = getTotalEvents({
    showEventTypes: ['SPEEDING'],
    eventsCounts
  })
  return (
    <FlexBox style={{ width: '100%' }}>
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          paddingLeft: Metrics.base * 4,
          flex: 2
        }}
      >
        <TrendIndicator
          size='small'
          direction='row'
          value={previousWeekChange ? previousWeekChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Week'}
        />
        <TrendIndicator
          size='small'
          direction='row'
          value={previousMonthChange ? previousMonthChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Month'}
        />
        <TrendIndicator
          size='small'
          direction='row'
          value={previousYearChange ? previousYearChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Year'}
        />
      </FlexBox>
      <FlexBox
        style={{
          flex: 2
          // justifyContent: 'flex-start'
          // paddingLeft: Metrics.base * 4
        }}
      >
        <InfoBadge
          size='medium'
          value={totalEvents}
          label={'Events Recorded'}
        />
        {type === 'tripTotals' ? (
          <SeverityPieChartTripTotals severityCounts={eventCategoryCounts} />
        ) : (
          <SeverityPieChartTimeBased severityCounts={eventCategoryCounts} />
        )}
      </FlexBox>
      {type === 'tripTotals' ? (
        <>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 4
            }}
          >
            <SeverityType name='Very Long' color={Colors.VERY_LONG} />
            <SeverityType name='Long' color={Colors.LONG} />
            <SeverityType name='Medium' color={Colors.MEDIUM} />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 4
            }}
          >
            <SeverityType name='Short' color={Colors.SHORT} />
            <SeverityType name='Very Short' color={Colors.VERY_SHORT} />
          </FlexBox>
        </>
      ) : (
        <>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 4
            }}
          >
            <SeverityType name='Severe' color={Colors.SEVERE} />
            <SeverityType name='Heavy' color={Colors.HEAVY} />
            <SeverityType name='Moderate' color={Colors.MODERATE} />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 4
            }}
          >
            <SeverityType name='Light' color={Colors.LIGHT} />
            <SeverityType name='Very Light' color={Colors.VERY_LIGHT} />
          </FlexBox>
        </>
      )}
    </FlexBox>
  )
}

export default CategorySummarySingleEvent
