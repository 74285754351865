import React, { useEffect } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  eventFilters,
  filters,
  selectedEventId
} from '../../../../Graphql/GraphQLClient'
import EventCard from './EventCard'
import { FlexBox, MaterialInput } from '../../..'
import { Metrics } from '../../../../Themes'
import { useFilters } from '../../../../Graphql/Queries/useFilters'
import { useReactiveVar } from '@apollo/client'
import { useNavigation } from '../../../CustomHooks/useNavigation'
import { checkVal } from '../../../../Helpers/Functions'

const EventsList = ({
  CardComponent = EventCard,
  eventIds,
  eventId,
  fetchMore,
  networkStatus,
  activeFiltersCount,
  moreFiltersPath,
  totalCount,
  setSearchInputString,
  insertedTopCardId
}) => {
  const { startDate, endDate } = useFilters()
  const { searchString } = useReactiveVar(eventFilters)
  const navigateTo = useNavigation()

  return (
    <>
      <CardsHeader
        title='Events'
        number={totalCount ? totalCount : 0}
        items={eventIds}
        searchInputString={searchString}
        setSearchInputString={setSearchInputString}
        moreFiltersPath={moreFiltersPath}
        activeFiltersCount={activeFiltersCount}
        moreFiltersTabName='eventMoreFiltersTab'
      />
      <FlexBox
        style={{
          padding: Metrics.base * 2,
          paddingTop: 0,
          paddingRight: Metrics.base * 3
        }}
      >
        <MaterialInput
          label={'Date Range'}
          type='dateBetween'
          value={{ startDate, endDate }}
          handleChange={(value) => [
            filters({
              ...filters(),
              startDate: value.startDate,
              endDate: value.endDate
            })
          ]}
        />
      </FlexBox>
      <CardsContainer fetchMore={fetchMore} networkStatus={networkStatus}>
        <CardsList
          items={eventIds}
          itemsName='Events'
          selectedItemId={eventId}
          insertedTopCard={
            checkVal(insertedTopCardId) && (
              <CardComponent
                data={{ _id: insertedTopCardId }}
                eventId={insertedTopCardId}
                key={insertedTopCardId}
                selected={insertedTopCardId === eventId}
                navigateTo={navigateTo}
                borderBottom={
                  1 >= eventIds.length ||
                  (eventIds[1] && eventIds[1]._id !== eventId)
                }
              />
            )
          }
        >
          {({ items, item, index }) =>
            insertedTopCardId !== item._id ? (
              <CardComponent
                eventId={item._id}
                index={index}
                key={index}
                selected={item._id === eventId}
                navigateTo={navigateTo}
                borderBottom={
                  index + 1 >= items.length ||
                  (items[index + 1] && items[index + 1]._id !== eventId)
                }
              />
            ) : null
          }
        </CardsList>
      </CardsContainer>
    </>
  )
}

export default EventsList
