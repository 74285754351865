import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import { FlexBox } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display, Heading } from '../../../Components/Typography'
import BarChart from '../../../Components/Custom/D3Charts/BarChart.js'

let data = []

for (let i = 0; i < 100; i++) {
  data.push({
    x: Math.floor(Math.random() * 100),
    y: Math.ceil(Math.random() * 10)
  })
}

data.sort(function (a, b) {
  return a.x - b.x
})

const DriverQuizScoreDist = () => {
  const Colors = useColors()
  const [width, setWidth] = useState(300)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  return (
    <Subsection
      forwardRef={containerRef}
      fillspace
      style={{
        marginRight: Metrics.base * 4,
        width: '100%',
        height: 350
      }}
    >
      <FlexBox
        direction='column'
        style={{
          width: '100%'
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            margin: Metrics.base * 2
          }}
        >
          Driver Quiz Score Distribution
        </Text>
        <BarChart
          data={data}
          chartHeight={210}
          chartWidth={width - Metrics.base * 8}
          color={'#7F73C9'}
        />
        <FlexBox
          direction='row'
          style={{
            width: '100%',
            justifyContent: 'center'
          }}
        >
          <Heading
            style={{
              //padding: Metrics.base * 2,
              paddingRight: Metrics.base * 2,
              maxWidth: 150,
              minWidth: 80
            }}
          >
            7.5/10
          </Heading>
          <Text
            color={Colors.textSecondary}
            style={{
              fontSize: 14
              //minWidth: 100,
              //margin: Metrics.base * 2
            }}
          >
            Average Score
          </Text>
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

export default DriverQuizScoreDist
