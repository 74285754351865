import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_TRIP_SAFETY_BY_TRIP_ID = gql`
  query tripSafetyByTripId($tripId: String!) {
    tripSafetyByTripId(tripId: $tripId) {
      tripSafetyCategory
      tripSafetyScore100
    }
  }
`

const dataTransform = ({ data }) => {
  const category = data.tripSafetyCategory
  const score = data.tripSafetyScore100
  return {
    category,
    score
  }
}

const getVariables = (tripId) => {
  let variables = {
    tripId
  }
  return variables
}

export const useTripSafetyCategory = (tripId) => {
  const [category, setCategory] = useState(null)
  const [score, setScore] = useState(null)
  const [loading, setLoading] = useState([])

  const { data: queryData, loading: queryLoading } = useQuery(
    GET_TRIP_SAFETY_BY_TRIP_ID,
    {
      variables: getVariables(tripId),
      skip: !tripId,
      queryName: 'tripSafetyByTripId',
      errorMessage: 'Failed to fetch Trip Category',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setCategory(queryData.category)
      setScore(queryData.score)
    } else {
      setCategory(null)
    }
  }, [queryData])

  return {
    category,
    score,
    loading
  }
}
