import { useEffect, useState } from 'react'
import { useQuery } from '../../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_ASSET_SCORE_AT_DATE = gql`
  query assetScoreAtDate(
    $assetId: String
    $endDate: Float
    $assetCategoryFilter: String
  ) {
    assetScoreAtDate(
      assetId: $assetId
      endDate: $endDate
      assetCategoryFilter: $assetCategoryFilter
    )
  }
`

const dataTransform = ({ data }) => {
  return data
}

const getVariables = ({ assetId, date, category }) => {
  let variables = {
    assetId,
    endDate: date,
    assetCategoryFilter: category
  }
  return variables
}

export const useAssetScoreAtDate = ({ assetId, date, category }) => {
  const [data, setData] = useState(null)

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ASSET_SCORE_AT_DATE,
    {
      variables: getVariables({ assetId, date, category }),
      skip: !assetId || !date,
      queryName: 'assetScoreAtDate',
      errorMessage: 'Failed to fetch Asset Score',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return {
    data,
    loading
  }
}
