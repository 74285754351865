import { useD3 } from '../CustomHooks/useD3'
import React, { useState } from 'react'
import * as d3 from 'd3'
import { data as dataIn } from './areaData'
import { FontsVariables, Metrics, useColors } from '../../Themes'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useEffect } from 'react'
import { checkVal } from '../../Helpers/Functions'

/*
    Utility methods 
  */
const percToDeg = (perc) => {
  return perc * 360
}

const percToRad = (perc) => {
  return degToRad(percToDeg(perc))
}

const degToRad = (deg) => {
  return (deg * Math.PI) / 180
}

/**
 * Helper function that returns the `d` value
 * for moving the needle
 **/
var recalcPointerPos = function (perc) {
  var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY
  thetaRad = percToRad(perc / 2)
  centerX = 0
  centerY = 0
  topX = centerX - this.len * Math.cos(thetaRad)
  topY = centerY - this.len * Math.sin(thetaRad)
  leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2)
  leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2)
  rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2)
  rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2)
  return (
    'M ' +
    leftX +
    ' ' +
    leftY +
    ' L ' +
    topX +
    ' ' +
    topY +
    ' L ' +
    rightX +
    ' ' +
    rightY
  )
}

const createNeedle = ({ width, repaintGauge, Colors }) => {
  function Needle(el) {
    this.el = el
    this.len = width / 3
    this.radius = this.len / 12
  }

  Needle.prototype.render = function () {
    this.el
      .select('.needle-center')
      .attr('fill', Colors.secondary)
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', this.radius)
    return this.el
      .select('.needle')
      .attr('fill', Colors.secondary)
      .attr('d', recalcPointerPos.call(this, 0))
  }

  Needle.prototype.moveTo = function (perc) {
    var self,
      oldValue = this.perc || 0

    this.perc = perc
    self = this

    // Reset pointer position
    this.el
      .transition()
      .delay(100)
      .ease(d3.easeQuad)
      .duration(200)
      .select('.needle')
      .tween('reset-progress', function () {
        return function (percentOfPercent) {
          var progress = oldValue + percentOfPercent * (perc - oldValue)

          repaintGauge(progress)
          return d3
            .select(this)
            .attr('d', recalcPointerPos.call(self, progress))
        }
      })

    /*
    this.el
      .transition()
      .delay(300)
      .ease(d3.easeBackOut)
      .duration(600)
      .select('.needle')
      .tween('progress', function () {
        return function (percentOfPercent) {
          var progress = percentOfPercent * perc

          repaintGauge(progress)
          return d3
            .select(this)
            .attr('d', recalcPointerPos.call(self, progress))
        }
      })
      */
  }
  return Needle
}

const GaugeChart = ({
  data = [],
  size = 250,
  maxValue = 150,
  value = 60,
  orangeValue,
  redValue
}) => {
  const Colors = useColors()
  const { mainContainerWidth } = useAppDimensions()
  const [needle, setNeedle] = useState(null)

  useEffect(() => {
    if (needle && checkVal(value)) {
      const percent = value / maxValue
      needle.moveTo(percent)
    }
  }, [needle, value, maxValue])
  // const vehicleMaxSpeed
  const numTicks = 5
  const ref = useD3(
    (svg) => {
      const height = size
      const width = size
      const margin = {
        top: 10,
        right: 50,
        bottom: 30,
        left: 0
      }

      const innerWidth = width - margin.left - margin.right
      const innerHeight = height - margin.top - margin.bottom

      const numSections = 1
      const sectionPerc = 1 / numSections / 2
      const padRad = 0.02
      const chartInset = 5

      // Orientation of gauge:
      const totalPercent = 0.75

      const radius = Math.min(innerWidth, innerHeight) / 2
      const barWidth = (30 * innerWidth) / 300

      const chart = svg
        .select('.chart')
        .attr(
          'transform',
          'translate(' +
            (width + margin.left) / 2 +
            ', ' +
            (height + margin.top) / 2 +
            ')'
        )
      chart
        .select('.chart-background')
        .attr('fill', Colors.background)
        .attr('stroke', Colors.dividerColor)
      chart
        .select('.chart-red')
        // .attr('fill', '#E70000')
        .attr('stroke', Colors.dividerColor)
        .attr('opacity', 0.7)
      chart
        .select('.chart-orange')
        .attr('fill', '#FF6914')
        .attr('stroke', Colors.dividerColor)
        .attr('opacity', 0.7)
      chart.select('.chart-filled').attr('fill', Colors.primary)
      // .attr('stroke', Colors.dividerColor)
      chart
        .select('.chart-empty')
        .attr('fill', 'white')
        .attr('stroke', Colors.dividerColor)

      const arc1 = d3
        .arc()
        .outerRadius(radius - chartInset)
        .innerRadius(radius - chartInset - barWidth)
      const arc2 = d3
        .arc()
        .outerRadius(radius - chartInset)
        .innerRadius(radius - chartInset - barWidth)

      const arc3 = d3
        .arc()
        .outerRadius(radius * 1.1 - chartInset)
        .innerRadius(radius * 0.9 - chartInset - barWidth)
        .startAngle(percToRad(-0.25))
        .endAngle(percToRad(0.25))

      const arc4 = d3
        .arc()
        .outerRadius(radius * 1.1 - chartInset)
        .innerRadius(radius * 0.9 - chartInset - barWidth)
        .startAngle(percToRad((redValue / maxValue - 0.5) / 2))
        .endAngle(percToRad(0.25))

      const arc5 = d3
        .arc()
        .outerRadius(radius * 1.1 - chartInset)
        .innerRadius(radius * 0.9 - chartInset - barWidth)
        .startAngle(percToRad((orangeValue / maxValue - 0.5) / 2))
        .endAngle(percToRad(0.25))

      chart.select('.chart-background').attr('d', arc3)
      chart.select('.chart-orange').attr('d', arc5)
      chart.select('.chart-red').attr('d', arc4)

      const repaintGauge = (perc) => {
        var next_start = totalPercent
        const arc1StartRad = percToRad(next_start)
        var arc1EndRad = arc1StartRad + percToRad(perc / 2)
        next_start += perc / 2

        const arc2StartRad = percToRad(next_start)
        const arc2EndRad = arc2StartRad + percToRad((1 - perc) / 2)

        arc1.startAngle(arc1StartRad).endAngle(arc1EndRad)

        arc2.startAngle(arc2StartRad + padRad).endAngle(arc2EndRad)

        chart.select('.chart-filled').attr('d', arc1)
        // chart.select('.chart-empty').attr('d', arc2)
      }

      // a linear scale that maps domain values to a percent from 0..1
      const scale = d3.scaleLinear().range([0, 1]).domain([0, maxValue])

      const ticks = scale.ticks(numTicks)
      const tickData = d3.range(numTicks).map(() => 1 / numTicks)

      const lg = svg
        .select('.labels')
        .attr('class', 'label')
        .attr(
          'transform',
          'translate(' +
            (width + margin.left) / 2 +
            ', ' +
            (height + margin.top) / 2 +
            ')'
        )
      lg.selectAll('text')
        .data(ticks)
        .enter()
        .append('text')
        .attr('transform', function (d) {
          var ratio = scale(d)
          const offset = 50 * (ratio / 10)
          var newAngle = -90 + ratio * 180 - offset
          return 'rotate(' + newAngle + ') translate(0,' + (-radius - 15) + ')'
        })
        .attr('font-size', 12)
        .attr('font-family', FontsVariables.fontFamilyRoboto)
        .attr('fill', Colors.secondaryOpacity1)
        .text(d3.format('d'))

      const Needle = createNeedle({ width, repaintGauge, Colors })
      const n = new Needle(chart)
      n.render()
      setNeedle(n)

      // set the gradient
      svg
        .select('.linearGradient1')
        .attr('id', 'area-gradient-1')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -100)
        .selectAll('stop')
        .data([
          {
            offset: '0%',
            color: 'red'
          },
          {
            offset: '100%',
            color: '#FF6914AA'
          }
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset
        })
        .attr('stop-color', function (d) {
          return d.color
        })
      svg
        .select('.linearGradient2')
        .attr('id', 'area-gradient-2')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', 0)
        .attr('y2', -100)
        .selectAll('stop')
        .data([
          {
            offset: '0%',
            color: 'red'
          },
          {
            offset: '100%',
            color: '#FF6914AA'
          }
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset
        })
        .attr('stop-color', function (d) {
          return d.color
        })
    },
    [data.length, maxValue, orangeValue, redValue]
  )

  return (
    <svg
      ref={ref}
      style={{
        height: size * 0.55,
        width: size
        // boxShadow: 'inset 0 0 10px #000000'
        // backgroundColor: 'red'
      }}
    >
      <g className='chart'>
        <path className='chart-background' />
        <path className='chart-red' style={{ fill: 'url(#area-gradient-1)' }} />
        <linearGradient className='linearGradient1' />
        <linearGradient className='linearGradient2' />
        <path
          className='chart-orange'
          style={{ fill: 'url(#area-gradient-2)' }}
        />
        <path className='chart-filled' />
        <path className='chart-empty' />
        <circle className='needle-center' />
        <path className='needle' />
      </g>
      <g className='labels' />
    </svg>
  )
}

export default GaugeChart
