import React, { useState } from 'react'
import ButtonLinks from '../ButtonLinks'
import Subsection from '../Subsection'
import { checkVal } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'
/**
 * Takes in an array of the following format"
 * [
 * {name:'Name of tab to display',
 *  tabRender: function rendering tab content}
 * ]
 */

const getInitialTabIndex = (tabs, selectedIndex) => {
  if (selectedIndex && tabs[selectedIndex] && !tabs[selectedIndex].disabled)
    return Number(selectedIndex)
  for (var i in tabs) {
    // console.log(tabs[i].disabled, Number(i))
    if (!tabs[i].disabled) return Number(i)
  }
  return -1
}

const TabBar = ({
  tabs,
  selectedIndex,
  setSelectedIndex = () => {},
  showTabBar = true,
  containerStyle,
  tabContainerStyle,
  textColor,
  hoverColor,
  tabInnerStyle,
  textSize,
  bold,
  fillSpace = true
}) => {
  const Colors = useColors()
  const initialIndex = getInitialTabIndex(tabs, selectedIndex)
  if (selectedIndex !== initialIndex) setSelectedIndex(initialIndex)
  const [selectedTab, setSelectedTab] = useState(initialIndex)
  const renderTabs = () => {
    return (
      <div style={{ width: '100%', height: Metrics.base * 7 }}>
        <FlexBox
          style={{
            height: Metrics.tabBarHeight,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            paddingLeft: Metrics.base * 5,
            flexDirection: 'row',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            ...tabInnerStyle
          }}
        >
          {tabs.map((a, index) => {
            if (a.hidden) return ''
            return (
              <ButtonLinks
                noUpperCase
                cond={
                  checkVal(selectedIndex)
                    ? index === Number(selectedIndex)
                    : index === Number(selectedTab)
                }
                key={index}
                bold={bold}
                icon={a.icon}
                disabled={a.disabled}
                title={a.name}
                textColor={textColor ? textColor : Colors.secondary}
                textSize={textSize}
                style={{ marginRight: Metrics.base * 2 }}
                borderColorHover={hoverColor ? hoverColor : Colors.secondary}
                onClick={() => [setSelectedIndex(index), setSelectedTab(index)]}
              />
            )
          })}
        </FlexBox>
      </div>
    )
  }
  const index = checkVal(selectedIndex) ? selectedIndex : selectedTab
  return (
    <FlexBox
      fillSpace={fillSpace}
      direction={'column'}
      style={{ width: '100%', ...containerStyle }}
    >
      {index >= 0 && index < tabs.length
        ? tabs[index].renderHeader
          ? tabs[index].renderHeader()
          : null
        : null}
      {showTabBar && renderTabs()}
      <FlexBox
        fillSpace={fillSpace}
        direction={'column'}
        style={{ width: '100%', ...tabContainerStyle }}
      >
        {index >= 0 && index < tabs.length
          ? tabs[index].tabRender
            ? tabs[index].tabRender()
            : null
          : null}
      </FlexBox>
    </FlexBox>
  )
}

export default TabBar
