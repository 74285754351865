import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '../../Components/CustomHooks/useQuery'

const queryName = 'getLocationAddress'
const typeName = 'AddressFromLatLongObject'
const defaultValue = null
const errorMessage = 'Failed to fetch location address'

export const addressFragment = gql`
  fragment addressFragment on ${typeName} {
    closest_suburb
  country
  municipality
  province
  road
  suburb
  surface_type
  town
  }
`

const QUERY = gql`
  query ${queryName} ($lat: Float 
    $long: Float) {
    ${queryName} (lat: $lat 
        long: $long) {
      ...addressFragment
    }
  }
  ${addressFragment}
`

export const dataTransform = (data) => {
  return { ...data }
}

export const useGetLocationAddress = (location) => {
  const [data, setData] = useState(defaultValue)
  const { data: queryData, loading } = useQuery(QUERY, {
    queryName,
    variables: { lat: location[1], long: location[0] },
    dataTransform,
    errorMessage,
    skip: !location
  })

  useEffect(() => {
    if (queryData) setData(queryData)
    else setData(defaultValue)
  }, [queryData])

  return { data, loading }
}
