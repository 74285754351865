import React, { useState, useEffect } from 'react'
import { Images, Metrics, useColors, Utils } from '../../Themes'
import { FlexBox, ProcessingSpinner, Text } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import {
  liveViewClusters,
  liveViewDefaultCenter,
  liveViewDefaultZoom,
  liveViewMapCenter,
  liveViewMapZoom,
  selectedAssetId,
  selectedDeviceId,
  TOMTOM_API_KEY
} from '../../Graphql/GraphQLClient'
import SideBar from './SideBar'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import LiveViewMap from '../../Components/Custom/LiveViewMap'
import { useReactiveVar } from '@apollo/client'
import LiveSpeedPlot from './LiveSpeedPlot'
import { ButtonBase } from '@material-ui/core'
import { useLiveViewClusterAssets } from '../../Graphql/Queries/Asset/useLiveViewClusterAssets'
import { useUpdateLiveMarkers } from './useUpdateLiveMarkers'
import { useDevice } from '../../Graphql/Queries/Device/useDevice'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'
import { default as ttMaps } from '@tomtom-international/web-sdk-maps'
import { useDebounce } from '../../Components/CustomHooks/useDebounce'

const handleMarkerClick = ({ deviceId, assetId, setMapUserInteraction }) => {
  selectedDeviceId(deviceId)
  selectedAssetId(assetId)
  setMapUserInteraction(false)
}

export const trackingZoom = 16
const maxZoom = trackingZoom
const defaultMaxDist = 1500000
const clusterRadiusPixels = 60

export const getLinePoints = ({ deviceId, locations, width, color }) => {
  return {
    points: locations.map(
      (a) => a.longitude && a.latitude && [a.longitude, a.latitude]
    ),
    lineId: `${deviceId}-location-history`,
    width,
    color
  }
}

const markerData = {
  anchor: 'center',
  onClick: (deviceId, assetId, driverId) =>
    handleMarkerClick(deviceId, assetId, driverId),
  icon: Images.truckIcon1,
  width: 60,
  heigth: 80
}

const getClusterMaxDistance = (map) => {
  return (
    map && parseInt(map.getBounds().getNorthEast().distanceTo(map.getCenter()))
  )
}

const getBoundingBox = (mapBounds) => {
  return mapBounds && mapBounds._sw && mapBounds._ne
    ? [
        mapBounds._sw.lng,
        mapBounds._sw.lat,
        mapBounds._ne.lng,
        mapBounds._ne.lat
      ]
    : []
}

const getIdsFromClusters = (clusters) => {
  const deviceIds = []
  const linkedAssetsData = {}
  for (var i = 0; i < clusters.length; i++) {
    if (checkVal(clusters[i].deviceId) && clusters[i].type !== 'Feature') {
      deviceIds.push(clusters[i].deviceId)
      linkedAssetsData[clusters[i].deviceId] = {
        id: clusters[i].assetId,
        name: clusters[i].assetName
      }
    }
  }
  return {
    deviceIds: deviceIds.sort((a, b) => (a < b ? -1 : 1)),
    linkedAssetsData
  }
}

var timerId
var throttleFunction = function (func, delay) {
  if (timerId) {
    return
  }
  timerId = setTimeout(function () {
    func()
    timerId = undefined
  }, delay)
}

const setMapVariables = ({ map, setMapCenter, setMapZoom, setMapBounds }) => {
  setMapCenter(map.getCenter())
  setMapZoom(map.getZoom())
  setMapBounds(map.getBounds())
}

const initialiseMap = ({
  mapElement,
  defaultCenter,
  defaultZoom,
  setMapLoaded,
  setMapVariablesDB,
  setMapCenter,
  setMapZoom,
  setMapBounds,
  mapUserInteraction,
  setMapUserInteraction
}) => {
  let map = ttMaps.map({
    key: TOMTOM_API_KEY,
    container: mapElement.current,
    center: defaultCenter,
    zoom: defaultZoom
  })
  map.on('load', () => {
    setMapLoaded(true)
    setMapVariablesDB({ map, setMapCenter, setMapZoom, setMapBounds })
  })
  map.on('mousedown', () => {
    if (!mapUserInteraction) setMapUserInteraction(true)
  })
  map.on('dblclick', () => {
    if (!mapUserInteraction) setMapUserInteraction(true)
  })
  map.on('wheel', () => {
    if (!mapUserInteraction) setMapUserInteraction(true)
  })
  map.on('zoom', () => {
    throttleFunction(
      () => setMapVariables({ map, setMapCenter, setMapZoom, setMapBounds }),
      6000
    )
  })
  map.on('zoomend', () => {
    setMapVariablesDB({ map, setMapCenter, setMapZoom, setMapBounds })
  })
  map.on('moveend', () => {
    setMapVariablesDB({ map, setMapCenter, setMapZoom, setMapBounds })
  })
  map.on('move', () => {
    throttleFunction(
      () => setMapVariables({ map, setMapCenter, setMapZoom, setMapBounds }),
      6000
    )
  })
  return map
}

const MapView = ({
  map,
  setMap,
  mapElement,
  mapCenter,
  mapZoom,
  mapBounds,
  setMapBounds,
  deviceIds,
  setDeviceIds,
  mapUserInteraction,
  setMapUserInteraction,
  setDeviceMarkers,
  locationsLoading,
  currentDeviceLocations,
  linkedAssetsData,
  setLinkedAssetsData
}) => {
  const [mapLoaded, setMapLoaded] = useState(false)
  const deviceId = useReactiveVar(selectedDeviceId)
  const [markers, setMarkers] = useState(null)
  const [clusterMarkers, setClusterMarkers] = useState([])
  const [deviceIdsChanged, setDeviceIdsChanged] = useState(null)

  const { mainContainerHeight, mainContainerWidth, mapWidth } =
    useAppDimensions()
  const width = checkVal(deviceId)
    ? mapWidth
    : mainContainerWidth - Metrics.base * 4

  const height =
    mainContainerHeight - Metrics.base * 11 + (checkVal(deviceId) ? -200 : 0) // - (checkVal(deviceId) ? Metrics.base * 13 : 0)
  const Colors = useColors()

  useEffect(() => {
    map && map.resize()
  }, [width, height])

  const assetId = useReactiveVar(selectedAssetId)
  const { data: asset } = useAsset(assetId)
  const selectedAssetDeviceId = asset && asset.deviceId
  // make sure device selected is linked to asset selected
  useEffect(() => {
    if (checkVal(selectedAssetDeviceId)) {
      selectedDeviceId(selectedAssetDeviceId)
    }
  }, [selectedAssetDeviceId])

  const [clusterQueryParams, setClusterQueryParams] = useState({
    centerPoint: mapCenter,
    maxDistance: defaultMaxDist,
    clusterRadius: clusterRadiusPixels,
    maxZoom: maxZoom,
    currentZoom: parseInt(mapZoom),
    boundingBox: getBoundingBox(mapBounds)
  })
  const { data: clusters, loading: clustersLoading } =
    useLiveViewClusterAssets(clusterQueryParams)
  const clustersChanged = JSON.stringify(clusters)

  useEffect(() => {
    map &&
      setClusterQueryParams({
        centerPoint: mapCenter,
        maxDistance: getClusterMaxDistance(map),
        clusterRadius: clusterRadiusPixels,
        maxZoom: maxZoom,
        currentZoom: parseInt(mapZoom),
        boundingBox: getBoundingBox(mapBounds)
      })
  }, [mapCenter, mapZoom, mapBounds])

  useEffect(() => {
    if (clusters && clusters.length > 0) {
      const {
        deviceIds: tempDeviceIds,
        linkedAssetsData: tempLinkedAssetsData
      } = getIdsFromClusters(clusters)
      setDeviceIds(tempDeviceIds)
      setDeviceIdsChanged(JSON.stringify(tempDeviceIds))
      setLinkedAssetsData(tempLinkedAssetsData)
    }
  }, [clusters])

  useEffect(() => {
    if (clusters && clusters.length > 0) {
      liveViewClusters(clusters)
    } else liveViewClusters([])
  }, [clusters])

  /*
  // make sure correct device id selected
  const { data: asset } = useAsset(assetId)
  const { data: driver } = useDriver(driverId)
  const path = location && location.pathname
  useEffect(() => {
    if (path.includes('drivers')) {
      if (driver && checkVal(driver.deviceId)) {
        selectedDeviceId(driver.deviceId)
        selectedVVTDeviceId(driver.VVTdeviceId)
      } else {
        selectedDeviceId(null)
        selectedVVTDeviceId(null)
      }
    } else {
      if (asset && checkVal(asset.deviceId)) {
        selectedDeviceId(asset.deviceId)
        selectedVVTDeviceId(asset.VVTdeviceId)
      } else {
        selectedDeviceId(null)
        selectedVVTDeviceId(null)
      }
    }
  }, [asset, driver, selectedTabId, path])
  */

  // reset user interaction for recenter button
  useEffect(() => {
    if (checkVal(deviceId)) {
      setMapUserInteraction(false)
    } else {
      liveViewMapCenter(liveViewDefaultCenter)
      liveViewMapZoom(liveViewDefaultZoom)
      map && map.resize()
      map &&
        map.easeTo({
          bearing: 0,
          zoom: liveViewDefaultZoom,
          center: liveViewDefaultCenter
        })
    }
  }, [deviceId])

  const setMapVariablesDB = useDebounce({
    func: setMapVariables,
    milliseconds: 600,
    immediate: false
  })

  useEffect(() => {
    let tempMap
    if (mapElement && mapElement.current) {
      tempMap = initialiseMap({
        mapElement,
        defaultCenter: mapCenter,
        defaultZoom: mapZoom,
        setMapLoaded,
        setMapVariablesDB,
        setMapCenter: liveViewMapCenter,
        setMapZoom: liveViewMapZoom,
        setMapBounds,
        mapUserInteraction,
        setMapUserInteraction
      })
      setMap(tempMap)
    }
    return () => tempMap && tempMap.remove()
  }, [mapElement])

  return (
    <FlexBox
      fillSpace
      direction={'column'}
      style={{
        width: '100%',
        justifyContent: 'flex-start',
        padding: Metrics.base * 2
      }}
    >
      <FlexBox
        direction='row'
        style={{
          alignItems: 'flex-start',
          paddingBottom: 0,
          height: '100%',
          width: '100%'
        }}
      >
        <div
          style={{
            position: 'relative',
            width,
            height,
            borderRadius: Metrics.radius
          }}
        >
          <LiveViewMap
            map={map}
            mapZoom={mapZoom}
            markerData={markerData}
            deviceIds={deviceIds}
            linkedAssetsData={linkedAssetsData}
            markers={markers}
            setDeviceMarkers={setDeviceMarkers}
            setMarkers={setMarkers}
            locationsLoading={locationsLoading}
            setMapUserInteraction={setMapUserInteraction}
            clusters={clusters}
            clustersLoading={clustersLoading}
            clusterMarkers={clusterMarkers}
            setClusterMarkers={setClusterMarkers}
            clustersChanged={clustersChanged}
            deviceIdsChanged={deviceIdsChanged}
          >
            <div
              ref={mapElement}
              id='tomtom_map_div'
              key='193nbd8asernqw3e91'
              className='mapDiv'
              style={{
                position: 'relative',
                width: width ? width : '100%',
                height: height ? height : 600,
                borderRadius: Metrics.radius,
                boxShadow: Colors.isDarkmode
                  ? Utils.boxShadowDark
                  : Utils.boxShadowLight
              }}
            >
              {/*locationsLoading && (
                <FlexBox
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: Colors.backgroundOpacity3
                  }}
                >
                  <ProcessingSpinner size={40} />
                </FlexBox>
                )*/}
            </div>
          </LiveViewMap>
          {checkVal(deviceId) && mapUserInteraction && (
            <FlexBox
              style={{
                position: 'absolute',
                bottom: 40,
                width: '100%',
                height: 25
              }}
            >
              <ButtonBase
                onClick={() => setMapUserInteraction(false)}
                style={{
                  width: 100,
                  height: '100%',
                  borderRadius: Metrics.radius,
                  border: `1px solid ${Colors.primary}`,
                  backgroundColor: Colors.background,
                  boxShadow: Utils.boxShadowLight
                }}
              >
                <Text>Recenter</Text>
              </ButtonBase>
            </FlexBox>
          )}
        </div>
        {checkVal(deviceId) && (
          <SideBar height={height} /*locations={locations}*/></SideBar>
        )}
      </FlexBox>
      <LiveSpeedPlot locations={currentDeviceLocations} />
    </FlexBox>
  )
}

export default MapView
