import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { inspectionDataTransform, inspectionFragment } from './useInspection'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'

export const GET_LATEST_INSPECTION = gql`
  query latestInspection($assetId: String!, $skip: Int, $limit: Int) {
    latestInspection(assetId: $assetId, skip: $skip, limit: $limit) {
      ...inspectionFragment
    }
  }
  ${inspectionFragment}
`

const dataTransform = ({ data }) => {
  if (data) {
    return {
      ...data
    }
  }
}

const getInspectionVariables = (assetId) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (checkVal(assetId)) {
    variables = { ...variables, assetId }
  }
  return variables
}

export const useLatestInspection = (assetId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_LATEST_INSPECTION,
    {
      variables: getInspectionVariables(assetId),
      queryName: 'latestInspection',
      errorMessage: 'Failed to fetch latest inspection',
      skip: !assetId,
      fetchPolicy: 'cache-and-network',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return { data, loading }
}
