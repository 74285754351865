import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const SET_NEW_PASSWORD = gql`
  mutation setNewPassword($username: String!, $password: String!) {
    setNewPassword(username: $username, password: $password)
  }
`
export const useSetNewPassword = () => {
  const [setNewPassword, { loading }] = useMutation(SET_NEW_PASSWORD, {
    mutationName: 'setNewPassword',
    successMessage: 'Password set successfully',
    errorMessage: 'Failed to set password'
  })

  return [setNewPassword, { loading }]
}
