import { Metrics, Colors } from '../../Themes'

export const Styles = {
  header: {
    position: 'fixed',
    zIndex: 900,
    top: 0,
    left: 0,
    right: 0,
    padding: Metrics.base * 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderBottom: `1px solid ${Colors.darkTheme.primaryOpacity2}`,
    height: Metrics.navBarHeight
  },
  headerInnerContainer: {
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  loginLogo: {
    width: 50,
    height: 50
  },
  logo: {
    height: Metrics.navBarHeight - Metrics.base * 5,
    marginTop: -Metrics.base * 2
    // width: 180
  }
}
