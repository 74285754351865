import React from 'react'
import { useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Card from '../Cards/Card'
import { useDevice } from '../../../../Graphql/Queries/Device/useDevice'
import {
  selectedDeviceId,
  selectedVVTDeviceId
} from '../../../../Graphql/GraphQLClient'

const DeviceCard = ({ deviceId, selected, onClick, borderBottom }) => {
  const Colors = useColors()
  const { data, loading: deviceLoading } = useDevice(deviceId)

  return (
    <Card
      data={data}
      selected={selected}
      onClick={
        onClick
          ? onClick
          : () => [
              selectedDeviceId(data ? data._id : null),
              selectedVVTDeviceId(data.VVTdeviceId ? data.VVTdeviceId : null)
            ]
      }
      borderBottom={borderBottom}
      loading={deviceLoading}
      titleContent={<Title color={Colors.primary}>{data && data.name}</Title>}
      bodyContent={<Text marginBottom={1}>{data && data.deviceType}</Text>}
      topRightLoading={deviceLoading}
      bottomLeftContent={
        <Text font='caption' color={Colors.secondaryOpacity3}>
          Device: {data.name}
        </Text>
      }
    />
  )
}

export default DeviceCard
