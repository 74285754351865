import React from 'react'
import { FlexBox, InfoBadge } from '../../../../Components'
import { useLegalScoreHistory } from '../../../../Graphql/Queries/Asset/SafetyScoreHistory/useLegalScoreHistory'
import { checkVal } from '../../../../Helpers/Functions'
import { useColors, Metrics } from '../../../../Themes'
import ScoreVsTimeChart from '../../Driver/Categories/ScoreVsTimeChart'
import { DescriptionContent } from '../../Trip/Breakdown'
import { useScoreHistory } from './useScoreHistory'
import { Subheading } from '../../../../Components/Typography'

const Legal = ({ assetId, assetSafetyData, score }) => {
  const Colors = useColors()
  const expiryDays = assetSafetyData ? assetSafetyData.expiryDays : 0
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useLegalScoreHistory({ assetId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ assetId, scoreHistoryData, category: 'LEGAL' })
  return (
    <FlexBox direction='column'>
      <FlexBox
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`,
          marginTop: -Metrics.base * 3,
          marginBottom: Metrics.base * 4,
          paddingBottom: Metrics.base
        }}
      >
        <DescriptionContent
          points={[
            `This score indicates if an asset is fully licensed. An asset with an expired license or one that is close to expiring will score lower in this category.`,
            'This accounts for 30% of the Total Asset Safety Score.'
          ]}
        />
      </FlexBox>
      <FlexBox
        direction='row'
        style={{ width: '100%', marginBottom: Metrics.base * 3 }}
      >
        {expiryDays === 'No License' ? (
          <Subheading>No Licence</Subheading>
        ) : (
          <InfoBadge
            size='small'
            status={expiryDays < 0 ? 'LICENCE EXPIRED' : 'LICENCE VALID'}
            statusColor={expiryDays < 0 ? Colors.HIGH_RISK : Colors.SAFE}
            value={checkVal(expiryDays) && `${Math.abs(expiryDays)} days`}
            label={
              expiryDays < 0 ? 'Since Licence Expiry' : 'Until Licence Expiry'
            }
          />
        )}
        {/*<InfoBadge
                      size='small'
                      value={`${averageSpeed} km/h`}
                      label={'Average Speed'}
                    />*/}
      </FlexBox>
      <ScoreVsTimeChart
        currentScore={score}
        data={scoreHistory}
        loading={scoreHistoryLoading}
      />
    </FlexBox>
  )
}

export default Legal
