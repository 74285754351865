import React, { useEffect, useState } from 'react'
import { FlexBox, Subsection, Text } from '../../Components'
import { Metrics, useColors } from '../../Themes'
import SideBar from './SideBar'
import { useCMSjsession } from '../../Graphql/Queries/useCMSjsession'
import axios from 'axios'
import { useReactiveVar } from '@apollo/client'
import { selectedVVTDeviceId } from '../../Graphql/GraphQLClient'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useIsDeviceOnline } from '../../Graphql/Queries/Device/useIsDeviceOnline'

const ratio = 0.78

const VideoFeed = (props) => {
  const { data: jsessionToken, loading: jsessionTokenLoading } =
    useCMSjsession()
  const [videoURIChannel0, setVideoURI0] = useState('')
  const [isDeviceOffline, setIsDeviceOffline] = useState(true)
  const VVTdeviceId = useReactiveVar(selectedVVTDeviceId)

  const { data: isDeviceOnline } = useIsDeviceOnline({
    jsession: jsessionToken,
    VVTdeviceId
  })
  console.log('isDeviceOnline', isDeviceOnline)

  useEffect(() => {
    // By VEHICLE ID : https://fleetcamonline.com/808gps/open/player/video.html?lang=en&vehiIdno=${vehicleId}&jsession=
    // BY DEVICE ID : https://fleetcamonline.com/808gps/open/player/video.html?lang=en&devIdno=${VVTdeviceId}&jsession=
    if (
      jsessionToken &&
      VVTdeviceId &&
      VVTdeviceId.length > 0 &&
      jsessionToken.length > 0 &&
      isDeviceOnline === 0
    ) {
      setIsDeviceOffline(false)
      console.log(
        `https://fleetcamonline.com/808gps/open/player/video.html?lang=en&devIdno=${VVTdeviceId}&close=60&jsession=${jsessionToken}`
      )
      //TODO for loop response.data.channels and set the channel=index to get all available channels
      setVideoURI0(
        `https://fleetcamonline.com/808gps/open/player/video.html?lang=en&devIdno=${VVTdeviceId}&close=60&jsession=${jsessionToken}`
      )
    } else {
      setIsDeviceOffline(true)
      setVideoURI0('')
    }
  }, [jsessionToken, VVTdeviceId, isDeviceOnline])
  const { mainContainerWidth } = useAppDimensions()
  const width = mainContainerWidth - Metrics.sideBarWidth - Metrics.base * 6
  return (
    <Subsection
      style={{
        flex: 1,
        width: width,
        height: width * ratio,
        marginBottom: Metrics.base * 2
      }}
    >
      {isDeviceOffline && <Text>Device is currently offline</Text>}
      {videoURIChannel0 && jsessionToken && (
        <iframe
          width='100%'
          height='100%'
          style={{ borderRadius: Metrics.radius }}
          src={videoURIChannel0}
          title='Channel 1'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          type='application/x-mpegURL'
          sandbox
        ></iframe>
      )}
    </Subsection>
  )
}

export default VideoFeed
