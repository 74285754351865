import React, { useState } from 'react'
import { Form } from '../'
import { useMutation } from '../CustomHooks/useMutation'

// // adds form object to GraphQL cache + list data
// const addObject = (store, object, listQuery, setListData) => {
//   if (listQuery) {
//     const data = store.readQuery({
//       query: listQuery.query,
//       variables: listQuery.variables
//     })
//     data[listQuery.name].push(object)
//     store.writeQuery({
//       query: listQuery.query,
//       variables: listQuery.variables,
//       data
//     })
//     setListData(data[listQuery.name])
//   }
// }

// // deletes form object from GraphQL cache + list data
// const deleteObject = (store, index, listQuery, setListData) => {
//   if (listQuery) {
//     const data = store.readQuery({
//       query: listQuery.query,
//       variables: listQuery.variables
//     })
//     data[listQuery.name].splice(index, 1)
//     store.writeQuery({
//       query: listQuery.query,
//       variables: listQuery.variables,
//       data
//     })
//     setListData(data[listQuery.name])
//   }
// }

const defaultHandleSubmit = ({
  data,
  setSubmitting,
  mutate,
  onSuccess,
  onError
}) => {
  console.log('here', data)
  setSubmitting(true)
  mutate({
    variables: data,
    onComplete: (data) => {
      setSubmitting(false)
      onSuccess()
    },
    onError: (error) => {
      setSubmitting(false)
      onError()
    }
  })
  onSuccess()
  // .then(({ data }) => {
  //   console.log(data)
  //   setSubmitting(false)
  //   onSuccess()
  // })
  // .catch((error) => {
  //   console.log(error)

  //   setSubmitting(false)
  //   onError()
  // })
}

// const handleDelete = ({
//   mutate,
//   more,
//   setSubmitting,
//   setListData,
//   onDelete,
//   onDeleteError,
//   notificationShow,
//   objectName
// }) => {
//   setSubmitting(true)
//   mutate({ variables: { ...more, isActive: false } })
//     .then(({ data }) => {
//       setSubmitting(false)
//       notificationShow({ text: `${objectName} Deleted` })
//       onDelete()
//     })
//     .catch((error) => {
//       setSubmitting(false)
//       notificationShow({
//         text: `Error deleting ${objectName}`,
//         level: 'danger'
//       })
//       onDeleteError()
//     })
// }

const FormGraphQL = ({
  style,
  object,
  formSchema,
  createMutation,
  updateMutation,
  handleSubmit,
  onSuccess,
  onDelete,
  onError,
  notificationShow,
  submitButtonTitle,
  objectName,
  handleCancel,
  listQuery,
  setListData,
  setListObject,
  disabled,
  showReset,
  hideCancel,
  hideFormBox,
  writeToListOnUpdate = false,
  listQueryName,
  update
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const more = object ? { id: object._id } : {}
  const formData = formSchema.map((a) => {
    return {
      ...a,
      initialValue: object ? object[a.name] : ''
    }
  })
  const mutation = object ? updateMutation : createMutation
  const mutationName = mutation ? mutation.definitions[0].name.value : null

  const [mutate] = useMutation(mutation, {
    mutationName,
    update,
    writeToListOnUpdate,
    listQueryName: listQueryName // listQuery.name
  })
  return (
    <div>
      <Form
        style={style}
        disabled={disabled}
        onSubmit={(data) =>
          handleSubmit
            ? handleSubmit({
                data: { ...data, ...more },
                setSubmitting,
                mutate,
                onSuccess,
                onError
              })
            : defaultHandleSubmit({
                data: { ...data, ...more },
                setSubmitting,
                mutate,
                onSuccess,
                onError
              })
        }
        submitButtonTitle={
          submitButtonTitle
            ? submitButtonTitle
            : object
            ? `UPDATE ${objectName}`
            : `CREATE ${objectName}`
        }
        showChanges={object ? true : false}
        data={formData}
        isSubmitting={isSubmitting}
        setSubmitting={setSubmitting}
        cancelButton={!hideCancel}
        handleCancel={handleCancel}
        // hideFormBox
        showReset={showReset}
      >
        {/* {object && (
                <div style={inlineStyles.deleteButtonContainer}>
                  <MaterialButton
                    onClick={() =>
                      handleDelete({
                        mutate,
                        more,
                        setSubmitting,
                        onDelete,
                        onError,
                        notificationShow,
                        objectName
                      })
                    }
                    variant='text'
                    startIcon={<Icon name='delete' color={Colors.grey2} />}
                    disabled={disabled || isSubmitting}
                    style={inlineStyles.deleteButton}
                  >
                    delete {objectName}
                  </MaterialButton>
                </div>
              )} */}
      </Form>
    </div>
  )
}

FormGraphQL.defaultProps = {
  onSuccess: () => {},
  onDelete: () => {},
  onDeleteError: () => {},
  onError: () => {},
  setListData: () => {},
  setListObject: () => {},
  notificationShow: () => {},
  submitButtonTitle: 'SUBMIT'
}

export default FormGraphQL
