import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_DEVICE = gql`
  mutation createDevice(
    $deviceId: String
    $assetId: String
    $driverId: String
    $deviceType: String
    $orgId: String
    $manufacturerType: String
    $name: String
    $deviceStatusType: String
    $isActive: Boolean
    $imei: String
    $simNum: String
    $model: String
    $engineHours: Float
  ) {
    createDevice(
      deviceId: $deviceId
      assetId: $assetId
      driverId: $driverId
      deviceType: $deviceType
      orgId: $orgId
      manufacturerType: $manufacturerType
      name: $name
      deviceStatusType: $deviceStatusType
      isActive: $isActive
      imei: $imei
      simNum: $simNum
      model: $model
      engineHours: $engineHours
    ) {
      _id
      organization {
        _id
        name
      }
      deviceId
      asset {
        _id
      }
      deviceType
      manufacturerType
      name
      deviceStatusType
      isActive
      imei
      simNum
      model
      engineHours
    }
  }
`
export const useCreateDevice = () => {
  const [createDevice, { loading: createDeviceLoading }] = useMutation(
    CREATE_DEVICE,
    {
      mutationName: 'createDevice',
      successMessage: 'Device created successfully',
      errorMessage: 'Device creation failed'
    }
  )

  return [createDevice, { createDeviceLoading }]
}
