import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'

import { Metrics, useColors } from '../../../Themes'
import { Text } from '../../'
import Styles, { inlineStyles } from './Styles'

const ProcessingSpinner = ({
  color,
  size,
  textColor,
  loading,
  mt,
  mb,
  mr,
  ml
}) => {
  const Colors = useColors()
  const marginStyles = {
    marginTop: mt ? Metrics.base * mt : null,
    marginBottom: mb ? Metrics.base * mb : null,
    marginLeft: ml ? Metrics.base * ml : null,
    marginRight: mr ? Metrics.base * mr : null
  }
  return (
    <div style={inlineStyles.spinnerContainer}>
      <div
        className={css(Styles.mainContainer)}
        style={{
          borderColor: color || Colors.primary,
          borderRightColor: `${color || Colors.third}55`,
          width: size,
          height: size,
          ...marginStyles
        }}
      />
      {loading ? (
        <Text
          marginTop={1}
          color={textColor || Colors.secondary}
          font={'subheading'}
        >
          {loading}
        </Text>
      ) : null}
    </div>
  )
}

export default ProcessingSpinner

ProcessingSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
}

ProcessingSpinner.defaultProps = {
  size: Metrics.base * 2
}
