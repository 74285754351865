import React from 'react'
import { Images, Metrics, useColors } from '../../../../../Themes'
import Text from '../../../../Text'
import { Title } from '../../../../Typography'
import Status from '../../../../Status'
import Card from '../../Cards/Card'
import { checkVal, hhmmss } from '../../../../../Helpers/Functions'
import { useEvent } from '../../../../../Graphql/Queries/Event/useEvent'
import EventCardTTList from '../EventCardTTList'
import { selectedOtherEventId } from '../../../../../Graphql/GraphQLClient'
import { FlexBox } from '../../../..'
import { ModifiedPieChart } from '../../../ModifiedPieChart'

const EventCardOther = ({
  eventId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom,
  navigateTo,
  onClick
}) => {
  const Colors = useColors()
  const { data, loading: eventLoading } = useEvent(eventId)

  return (
    <Card
      id={eventId}
      data={data}
      selected={selected}
      typeName={'other-event'}
      setSelectedId={selectedOtherEventId}
      navigateTo={navigateTo}
      onClick={() => {}} //{onClick}
      TooltipContent={({ setShowTooltip }) => (
        <EventCardTTList
          eventId={eventId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={eventLoading}
      titleContent={<Title color={Colors.primary}>{data.name}</Title>}
      cardHeight={220}
      bodyContent={
        <>
          <div
            style={{
              height: 100,
              width: 100,
              position: 'relative',
              marginBottom: Metrics.base * 2
            }}
          >
            <ModifiedPieChart
              color={Colors[data.type]}
              hideLegend
              hideLabels
              padAngle={0}
              innerRadius={0.9}
              startAngle={-180}
              endAngle={180}
              enableArcLinkLabels={false}
              enableSlicesLabels={false}
              enableArcLabels={false}
              isInteractive
              data={[
                {
                  id: 'volumeDelivered',
                  label: 'label1',
                  value: data.normScore
                },
                {
                  id: 'volumeRemaining',
                  label: 'label2',
                  value: 100 - data.normScore
                }
              ]}
            ></ModifiedPieChart>
          </div>

          <Text marginBottom={1} marginTop={1 / 2}>
            {data.createdAtTime}
          </Text>
        </>
      }
      topRightContent={
        <Status
          status={data.severityCategoryDisplayText}
          getStatusColors={() => Colors[data.severityCategory]}
        ></Status>
      }
      topRightLoading={eventLoading}
      bottomLeftContent={
        <Text font='caption' color={Colors.secondaryOpacity3}>
          Event: {data.displayId}
        </Text>
      }
      bottomRightContent={
        checkVal(data.normScore) && (
          <FlexBox>
            <img
              width={13}
              style={{ marginRight: Metrics.base / 2, opacity: '0.8' }}
              alt={'shield'}
              src={Images.shieldIcon}
            />
            <Text>
              {Math.round(data.normScore * 100) / 100}/
              {data && data.type === 'QUIZ_SCORE' ? 100 : 10}
            </Text>
          </FlexBox>
        )
      }
    />
  )
}

export default EventCardOther
