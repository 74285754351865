import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_INSPECTION = gql`
  mutation createInspection(
    $assetId: String
    $driverId: String
    $inspectedAt: String
    $comments: String
    $reportedMaintenanceStatus: String
    $inspectionType: String
    $beforeStart: BeforeStartInput
    $ancillaryEquipment: AncillaryEquipmentInput
    $startEngine: StartEngineInput
    $trailerInspection: TrailerInspectionInput
  ) {
    createInspection(
      assetId: $assetId
      driverId: $driverId
      inspectedAt: $inspectedAt
      comments: $comments
      reportedMaintenanceStatus: $reportedMaintenanceStatus
      inspectionType: $inspectionType
      beforeStart: $beforeStart
      ancillaryEquipment: $ancillaryEquipment
      startEngine: $startEngine
      trailerInspection: $trailerInspection
    ) {
      _id
      reportedMaintenanceStatus
      inspectionType
    }
  }
`
export const useCreateInspection = () => {
  const [createInspection, { loading: createInspectionLoading }] = useMutation(
    CREATE_INSPECTION,
    {
      mutationName: 'createInspection',
      successMessage: 'Inspection created successfully',
      errorMessage: 'Inspection creation failed'
    }
  )
  return [createInspection, { createInspectionLoading }]
}
