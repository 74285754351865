import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

const eventFragment = gql`
  fragment eventFragment on Event {
    _id
    normScore
    category
    startTime
    createdAt
    type
  }
`

export const driverSafetyFragment = gql`
  fragment driverSafetyFragment on DriverSafety {
    _id
    tripSafetyMean100
    tripSafetyStats
    speedingCategoryMean100
    speedingCategoryStats
    speedingEventsIDList {
      ...eventFragment
    }
    smoothDrivingCategoryMean100
    smoothDrivingCategoryStats
    smoothDrivingEventIDList {
      ...eventFragment
    }
    restedFocusedCategoryMean100
    restedFocusedCategoryStats
    restedFocusedEventIDList {
      ...eventFragment
    }
    complianceCategoryMean100
    complianceCategoryStats
    tripComplianceEventIDList {
      ...eventFragment
    }
    generalSafetyCategoryMean100
    generalSafetyCategoryStats
    generalSafetyIDList {
      ...eventFragment
    }
    healthAttitudeCategoryNormScore100
    latestHealthCheckNormScore100
    healthCheckStats
    healthCheckIDList {
      _id
    }
    latestAttitudeNormScore100
    attitudeStats
    attitudeIDList {
      _id
    }
    educationCategoryNormScore100
    numberLessonsNormScore100
    numberLessonsStats
    lessonsIDList {
      _id
    }
    numberQuizNormScore100
    #numberQuizScoreStats
    quizNormScore100
    quizScoreStats
    quizIDList {
      ...eventFragment
    }
    driverSafetyScore100
    driverSafetyCategory
    createdAt
    modelVersion
    tripIDList {
      _id
      displayId
      startAddress
      endAddress
      tripSafetyScore
      tripSafetyCategory
    }
    driver {
      _id
    }
    totalTrips
    tripCategoryCounts {
      EXCELLENT
      MODERATE_RISK
      ADEQUATE_RISK
      SAFE
      HIGH_RISK
    }
    eventTypeCounts {
      SPEEDING
      HARSH_BRAKING
      HARSH_CORNERING
      HARSH_ACCELERATION
      PHONE_USE
      PERCENT_SPEEDING
      TRIP_DISTANCE
      TRAVEL_TIME
      DRIVING_TIME
      NIGHT_DRIVING
      CONTINUOUS_DRIVING
      VEHICLE_CRASH
      VEHICLE_INCIDENT
    }
    numberQuizAllTime
    numberQuizLastMonth
    totalKilometers
    totalHoursDriven
    averageNightDrivingPercentage
    averageDayDrivingPercentage
    totalNightDrivingHours
    totalDayDrivingHours
  }
  ${eventFragment}
`

export const GET_DRIVER_SAFETY_BY_DRIVER_ID = gql`
  query driverSafetyByDriverId($driverId: String!) {
    driverSafetyByDriverId(driverId: $driverId) {
      ...driverSafetyFragment
    }
  }
  ${driverSafetyFragment}
`

const eventsTransform = (eventList) => {
  return eventList
    ? eventList.map((event) => ({
        ...event,
        startDate: !isNaN(parseInt(event.startTime))
          ? new Date(parseInt(event.startTime))
          : new Date(parseInt(event.createdAt))
      }))
    : []
}

const dataTransform = ({ data }) => {
  const totalScore = data.driverSafetyScore100
  const healthAttitudeScore = data.healthAttitudeCategoryNormScore100
  const educationScore = data.educationCategoryNormScore100

  const combinedScore = data.tripSafetyMean100
  const speedingScore = data.speedingCategoryMean100
  const smoothDrivingScore = data.smoothDrivingCategoryMean100
  const restedFocusedScore = data.restedFocusedCategoryMean100
  const compliantScore = data.complianceCategoryMean100
  const contextualScore = data.generalSafetyCategoryMean100

  const speedingEvents = eventsTransform(data.speedingEventsIDList)
  const smoothDrivingEvents = eventsTransform(data.smoothDrivingEventIDList)
  const restedFocusedEvents = eventsTransform(data.restedFocusedEventIDList)
  const complianceEvents = eventsTransform(data.tripComplianceEventIDList)
  const contextualEvents = eventsTransform(data.generalSafetyIDList)

  const quizEvents = eventsTransform(data.quizIDList)
  return {
    data,
    totalScore,
    healthAttitudeScore,
    educationScore,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEvents,
    smoothDrivingEvents,
    restedFocusedEvents,
    complianceEvents,
    contextualEvents,
    quizEvents
  }
}

const getVariables = (driverId) => {
  let variables = {
    driverId
  }
  return variables
}

export const useDriverSafetyByDriverId = (driverId) => {
  const [data, setData] = useState(null)

  const [totalScore, setTotalScore] = useState(null)
  const [healthAttitudeScore, setHealthAttitudeScore] = useState(null)
  const [educationScore, setEducationScore] = useState(null)

  const [combinedScore, setCombinedScore] = useState(null)
  const [speedingScore, setSpeedingScore] = useState(null)
  const [smoothDrivingScore, setSmoothDrivingScore] = useState(null)
  const [restedFocusedScore, setRestedFocusedScore] = useState(null)
  const [compliantScore, setCompliantScore] = useState(null)
  const [contextualScore, setContextualScore] = useState(null)

  const [speedingEvents, setSpeedingEvents] = useState([])
  const [smoothDrivingEvents, setSmoothDrivingEvents] = useState([])
  const [restedFocusedEvents, setRestedFocusedEvents] = useState([])
  const [complianceEvents, setComplianceEvents] = useState([])
  const [contextualEvents, setContextualEvents] = useState([])

  const [quizEvents, setQuizEvents] = useState([])

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DRIVER_SAFETY_BY_DRIVER_ID,
    {
      variables: getVariables(driverId),
      skip: !driverId,
      queryName: 'driverSafetyByDriverId',
      errorMessage: 'Failed to fetch Driver Safety',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData && queryData.data) {
      setData(queryData.data)

      setTotalScore(queryData.totalScore)
      setHealthAttitudeScore(queryData.healthAttitudeScore)
      setEducationScore(queryData.educationScore)

      setCombinedScore(queryData.combinedScore)
      setSpeedingScore(queryData.speedingScore)
      setSmoothDrivingScore(queryData.smoothDrivingScore)
      setRestedFocusedScore(queryData.restedFocusedScore)
      setCompliantScore(queryData.compliantScore)
      setContextualScore(queryData.contextualScore)

      setSpeedingEvents(queryData.speedingEvents)
      setSmoothDrivingEvents(queryData.smoothDrivingEvents)
      setRestedFocusedEvents(queryData.restedFocusedEvents)
      setComplianceEvents(queryData.complianceEvents)
      setContextualEvents(queryData.contextualEvents)

      setQuizEvents(queryData.quizEvents)
    } else {
      setData(null)
    }
  }, [queryData])

  return {
    data,
    totalScore,
    healthAttitudeScore,
    educationScore,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEvents,
    smoothDrivingEvents,
    restedFocusedEvents,
    complianceEvents,
    contextualEvents,
    quizEvents,
    loading
  }
}
