import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const videoThresholdsFragment = gql`
  fragment videoThresholdsFragment on VideoThresholds {
    SPEEDING
    HARSH_CORNERING
    HARSH_ACCELERATION
    HARSH_BRAKING
  }
`

export const videoFetchSettingsFragment = gql`
  fragment videoFetchSettingsFragment on VideoFetchSettings {
    _id
    internalDeviceId
    deviceId
    isActive
    thresholds {
      ...videoThresholdsFragment
    }
  }
  ${videoThresholdsFragment}
`

export const GET_VIDEO_FETCH_SENSITIVITY = gql`
  query videoFetchSensitivity($internalDeviceId: String!) {
    videoFetchSensitivity(internalDeviceId: $internalDeviceId) {
      ...videoFetchSettingsFragment
    }
  }
  ${videoFetchSettingsFragment}
`

const dataTransform = ({ data }) => {
  const thresholds = data && data.thresholds ? data.thresholds : {}
  return {
    ...data,
    ...thresholds
  }
}

export const useVideoFetchSensitivity = (id) => {
  const [data, setData] = useState(null)
  const { data: queryData, loading } = useQuery(GET_VIDEO_FETCH_SENSITIVITY, {
    variables: { internalDeviceId: id },
    queryName: 'videoFetchSensitivity',
    errorMessage: 'Failed to fetch Device Video Recoring Thresholds',
    dataTransform: (data) => dataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    queryData ? setData(queryData) : setData(null)
  }, [queryData])

  return { data, loading }
}
