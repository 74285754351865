import React from 'react'
import { MainLayout } from '../../Components'
import TabRoutes from '../../Components/TabRoutes'
import Groups from './Groups'
import Thresholds from './Thresholds'
import User from './User'

const Settings = (props) => {
  return (
    <MainLayout {...props} hideSelectionPanel={true}>
      <TabRoutes
        style={{ width: '100%', height: '100%' }}
        // defaultId={selectedTab ? selectedTab : null}
        // onChange={(id) => selectedTabs({ ...selectedTabs(), tripHistory: id })}
        tabsData={[
          { id: 'user', name: 'User' },
          { id: 'thresholds', name: 'Thresholds' },
          { id: 'groups', name: 'Groups' }
        ]}
      >
        <User {...props} />
        <Thresholds {...props} />
        <Groups {...props} />
      </TabRoutes>
    </MainLayout>
  )
}

export default Settings
