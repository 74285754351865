import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'
import { Fonts, Metrics } from '../../Themes'
import Styles from './Styles'
import { useColors } from '../../Themes'
import { LinearProgress, withStyles } from '@material-ui/core'

const Text = ({
  font,
  color,
  tag,
  bold,
  align,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  children,
  style,
  disabled,
  loading,
  loadingBarWidth,
  loadingBarHeight,
  loadingBackgroundColor,
  loadingBarColor,
  ...props
}) => {
  let displayFont = font ? Fonts[font] : Fonts.body
  const Colors = useColors()

  const LoadingBar =
    loadingBarHeight && loadingBarWidth ? (
      withStyles(() => ({
        root: {
          borderRadius: 2
        },
        colorPrimary: {
          backgroundColor: loadingBackgroundColor
            ? loadingBackgroundColor
            : Colors.dividerColor
        },
        bar: {
          // borderRadius: 5,
          backgroundColor: loadingBarColor
            ? loadingBarColor
            : Colors.selectHighlightOpacity2
        }
      }))(LinearProgress)
    ) : (
      <></>
    )

  let displayStyles = {
    marginTop: Metrics.base * marginTop || null,
    marginRight: Metrics.base * marginRight || null,
    marginBottom: Metrics.base * marginBottom || null,
    marginLeft: Metrics.base * marginLeft || null,
    color: disabled
      ? Colors.secondaryOpacity3
      : color
      ? color
      : Colors.secondary,
    textAlign: align,
    ...displayFont,
    ...style
  }

  switch (tag) {
    case 'h1':
      return (
        <h1
          {...props}
          className={css(Styles.text)}
          style={{ ...displayStyles }}
        >
          {children}
        </h1>
      )

    case 'h2':
      return (
        <h2
          {...props}
          className={css(Styles.text)}
          style={{ ...displayStyles }}
        >
          {children}
        </h2>
      )

    case 'h3':
      return (
        <h3
          {...props}
          className={css(Styles.text)}
          style={{ ...displayStyles }}
        >
          {children}
        </h3>
      )

    case 'h4':
      return (
        <h4
          {...props}
          className={css(Styles.text)}
          style={{ ...displayStyles }}
        >
          {children}
        </h4>
      )

    case 'label':
      return (
        <label
          {...props}
          className={css(Styles.text)}
          style={{ ...displayStyles }}
        >
          {children}
        </label>
      )

    case 'unit':
      return (
        <p {...props} className={css(Styles.text)} style={{ ...displayStyles }}>
          {children.slice(0, -1)}
          <sup>{children.slice(-1)}</sup>
        </p>
      )

    case 'unitColon':
      return (
        <p {...props} className={css(Styles.text)} style={{ ...displayStyles }}>
          {children.slice(0, -2)}
          <sup>{children.slice(children.length - 2, children.length - 1)}</sup>
          {children.slice(-1)}
        </p>
      )

    case 'unitBracket':
      return (
        <p {...props} className={css(Styles.text)} style={{ ...displayStyles }}>
          {children.slice(0, -3)}
          <sup>
            {children.substring(children.length - 3, children.length - 2)}
          </sup>
          {children.slice(-2)}
        </p>
      )

    default:
      return loading && loadingBarWidth && loadingBarHeight ? (
        <LoadingBar
          style={{
            marginBottom: Metrics.base,
            marginTop: Metrics.base,
            height: loadingBarHeight,
            width: loadingBarWidth
          }}
        />
      ) : (
        <p {...props} className={css(Styles.text)} style={{ ...displayStyles }}>
          {children}
        </p>
      )
  }
}

Text.propTypes = {
  font: PropTypes.string,
  color: PropTypes.string,
  tag: PropTypes.string,
  bold: PropTypes.bool,
  align: PropTypes.string,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  children: PropTypes.any,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}

Text.defaultProps = {
  // color: Colors.font,
  align: 'left'
}

export default Text
