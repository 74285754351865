import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useFilters } from '../useFilters'
import { useReactiveVar } from '@apollo/client'
import { eventFilters, filters } from '../../GraphQLClient'

export const GET_EVENT_IDS = gql`
  query events(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $tripId: String
    $typesFilter: [String]
    $driverId: String
    $assetId: String
    $startDate: Float
    $endDate: Float
    $scoreFilterGreater: Int
    $scoreFilterLess: Int
    $hasVideo: Boolean
    $skip: Int
    $limit: Int
  ) {
    events(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      tripId: $tripId
      typesFilter: $typesFilter
      driverId: $driverId
      assetId: $assetId
      startDate: $startDate
      endDate: $endDate
      scoreFilterLess: $scoreFilterLess
      scoreFilterGreater: $scoreFilterGreater
      hasVideo: $hasVideo
      skip: $skip
      limit: $limit
    ) {
      totalCount
      events {
        _id
        displayId
      }
    }
  }
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data: data.events && data.events.map((a) => a)
  }
}

const getEventsVariables = ({
  orgIdsFilter,
  groupId,
  searchString,
  startDate,
  endDate,
  tripId,
  driverId,
  assetId,
  eventTypes,
  minScore,
  maxScore,
  hasVideo
}) => {
  let variables = {
    skip: 0,
    limit: 10
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables.assetGroupId = groupId
  }
  if (checkVal(searchString)) {
    variables.searchString = searchString
  }
  if (checkVal(startDate)) {
    variables.startDate = startDate
  }
  if (checkVal(endDate)) {
    variables.endDate = endDate
  }
  if (checkVal(tripId)) {
    variables.tripId = tripId
  }
  if (checkVal(driverId)) {
    variables.driverId = driverId
  }
  if (checkVal(assetId)) {
    variables.assetId = assetId
  }
  if (checkVal(eventTypes)) {
    variables.typesFilter = eventTypes
  }
  if (checkVal(minScore)) {
    variables.scoreFilterGreater = minScore
  }
  if (checkVal(maxScore)) {
    variables.scoreFilterLess = maxScore
  }
  if (checkVal(hasVideo)) {
    variables.hasVideo = hasVideo
  }
  return variables
}

export const useEventIds = ({ tabId, variables, skip }) => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const {
    assetId,
    driverId,
    deviceId,
    tripId,
    filterByTypeName,
    tripTotalsTypes,
    inTripTypes,
    otherTypes,
    eventTypes,
    minScore,
    maxScore,
    hasVideo,
    searchString
  } = useReactiveVar(eventFilters)

  const { startDate, endDate } = useReactiveVar(filters)

  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_EVENT_IDS, {
    variables: getEventsVariables(
      variables
        ? variables
        : {
            orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
            groupId,
            searchString,
            startDate,
            endDate,
            tripId,
            driverId,
            assetId,
            eventTypes,
            minScore,
            maxScore,
            hasVideo
          }
    ),
    queryName: 'events',
    errorMessage: 'Failed to fetch Events Ids',
    dataTransform: (data) => dataTransform({ data }),
    skip
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
