import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'

export const GET_COACHING_EVENT_BY_EVENT_ID = gql`
  query coachingEventByEventId($eventId: String!) {
    coachingEventByEventId(eventId: $eventId)
  }
`

export const coachingEventByEventIdDataTransform = ({ data }) => {
  return data
}

export const useCoachingEventByEventId = (id) => {
  const [data, setData] = useState(0)
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_COACHING_EVENT_BY_EVENT_ID,
    {
      variables: { eventId: id },
      queryName: 'coachingEventByEventId',
      errorMessage: 'Failed to fetch Event in Coaching',
      dataTransform: (data) => {
        coachingEventByEventIdDataTransform({ data })
      },
      skip: !id
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    checkVal(queryData) && setData(queryData)
  }, [queryData])

  return { data, loading }
}
