import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const inspectionItemFragment = gql`
  fragment inspectionItemFragment on InspectionItem {
    inspectionCheckType
    comment
    itemImage {
      _id
      url
    }
  }
`

export const inspectionFragment = gql`
  fragment inspectionFragment on Inspection {
    _id
    displayId
    asset {
      _id
      name
    }
    inspectedAt
    driver {
      _id
      firstName
      lastName
    }
    createdAt
    updatedAt
    comments
    reportedMaintenanceStatus
    inspectionType
    inspectionImages {
      _id
    }
    extraInspectionImages {
      _id
    }
    # isActive
    #BEFORE STARTING ------------------------------------------------------------------------------------------------
    engineOilLevel {
      ...inspectionItemFragment
    }
    engineOilLeaks {
      ...inspectionItemFragment
    }
    waterLevel {
      ...inspectionItemFragment
    }
    waterLeaks {
      ...inspectionItemFragment
    }
    fuelLeaksCap {
      ...inspectionItemFragment
    }
    fanBelts {
      ...inspectionItemFragment
    }
    hoses {
      ...inspectionItemFragment
    }
    brakeFluidLevel {
      ...inspectionItemFragment
    }
    clutchFluidLevel {
      ...inspectionItemFragment
    }
    battery {
      ...inspectionItemFragment
    }
    wheelNuts {
      ...inspectionItemFragment
    }
    tyres {
      ...inspectionItemFragment
    } #pressure, valve, caps, treads, sidewalls
    brakes {
      ...inspectionItemFragment
    } # drain air storage tanks
    bodyCondition {
      ...inspectionItemFragment
    }
    rearUnderrun {
      ...inspectionItemFragment
    }
    chevron {
      ...inspectionItemFragment
    }
    reflectorsAndStrips {
      ...inspectionItemFragment
    }
    mirrors {
      ...inspectionItemFragment
    }
    windows {
      ...inspectionItemFragment
    }
    otherBeforeStarting #different comments for different parts of inspection?
    # --------------------------------------------------------------------------------------------------------------------
    #ANCILLARY EQUIPMENT ------------------------------------------------------------------------------------------------
    jack {
      ...inspectionItemFragment
    }
    wheelChocks {
      ...inspectionItemFragment
    }
    fireExtinguisher {
      ...inspectionItemFragment
    }
    spareFusesBulbsFanbelts {
      ...inspectionItemFragment
    }
    warningTriangles {
      ...inspectionItemFragment
    }
    placarding {
      ...inspectionItemFragment
    }
    permitsAndDocuments {
      ...inspectionItemFragment
    }
    tachographCharts {
      ...inspectionItemFragment
    }
    loadAndFastenings {
      ...inspectionItemFragment
    }
    firstAidKit {
      ...inspectionItemFragment
    }
    wheelNutsAncillary {
      ...inspectionItemFragment
    }
    otherAncillary
    # ------------------------------------------------------------------------------------------------
    #START ENGINE ------------------------------------------------------------------------------------------------
    handBrakeAction {
      ...inspectionItemFragment
    }
    footBrakeAction {
      ...inspectionItemFragment
    }
    clutchPedalPlay {
      ...inspectionItemFragment
    }
    steering {
      ...inspectionItemFragment
    } #freeplay
    horn {
      ...inspectionItemFragment
    }
    windscreenWipersAndWashers {
      ...inspectionItemFragment
    }
    warningLights {
      ...inspectionItemFragment
    }
    gauges {
      ...inspectionItemFragment
    } #fuel level
    airPressureBuzzerGauges {
      ...inspectionItemFragment
    }
    lights {
      ...inspectionItemFragment
    }
    indicators {
      ...inspectionItemFragment
    }
    auxiliaryEquipment {
      ...inspectionItemFragment
    }
    otherEngineStart
    # ------------------------------------------------------------------------------------------------
    #TRAILER ------------------------------------------------------------------------------------------------
    trailers {
      _id
      name
    }
    trailerNo
    hookUpAndFifthWheel {
      ...inspectionItemFragment
    }
    airHoseConnections {
      ...inspectionItemFragment
    }
    brakesTrailer {
      ...inspectionItemFragment
    }
    wheelNutsTrailer {
      ...inspectionItemFragment
    }
    tyresTrailer {
      ...inspectionItemFragment
    }
    semiTrailerLandingGear {
      ...inspectionItemFragment
    }
    towBarSafetyChains {
      ...inspectionItemFragment
    }
    bodyConditionTrailer {
      ...inspectionItemFragment
    }
    rearUnderrunTrailer {
      ...inspectionItemFragment
    }
    chevronTrailer {
      ...inspectionItemFragment
    }
    reflectorsTrailer {
      ...inspectionItemFragment
    }
    otherTrailer
  }
  ${inspectionItemFragment}
`

export const GET_INSPECTION = gql`
  query inspection($id: String!) {
    inspection(id: $id) {
      ...inspectionFragment
    }
  }
  ${inspectionFragment}
`

export const inspectionDataTransform = (data) => {
  if (data) {
    return {
      ...data
    }
  }
}

export const useInspection = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_INSPECTION, {
    variables: { id },
    queryName: 'inspection',
    errorMessage: 'Failed to fetch Inspection',
    dataTransform: (data) => inspectionDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
