import React, { useEffect, useState } from 'react'
import { FlexBox, Subsection, InfoBadge } from '../../../../Components'
import { Metrics, useColors } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import CategorySummary from './CategorySummary'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import { useRestedFocusedScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useRestedFocusedScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'
import CategorySummarySingleEvent from './CategorySummarySingleEvent'
import { checkVal } from '../../../../Helpers/Functions'

export const getEventCategoryCountsTripTotals = (events) => {
  const eventCategoryCounts = {
    VERY_LONG: 0,
    LONG: 0,
    MEDIUM: 0,
    SHORT: 0,
    VERY_SHORT: 0
  }
  for (var i = 0; i < events.length; i++) {
    eventCategoryCounts[events[i].category] += 1
  }
  return eventCategoryCounts
}

const RestedFocused = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts
}) => {
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useRestedFocusedScoreHistory({ driverId })

  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({
    driverId,
    scoreHistoryData,
    category: 'RESTED_FOCUSED'
  })

  return (
    <FlexBox direction='column'>
      {/*<CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        eventsCounts={
          eventTypeCounts
            ? {
                DRIVING_TIME: eventTypeCounts.DRIVING_TIME
              }
            : {}
        }
        totalEvents={events && events.length > 0 ? events.length : 0}
    />*/}
      <CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        showEventTypes={['DRIVING_TIME', 'PHONE_MOTION', 'CONTINUOUS_DRIVING']}
        eventsCounts={
          eventTypeCounts
            ? {
                DRIVING_TIME: eventTypeCounts.DRIVING_TIME,
                PHONE_MOTION: eventTypeCounts.PHONE_MOTION,
                CONTINUOUS_DRIVING: eventTypeCounts.CONTINUOUS_DRIVING
              }
            : {}
        }
        totalEvents={events && events.length > 0 ? events.length : 0}
        // eventCategoryCounts={getEventCategoryCountsTripTotals(events)}
        // type='tripTotals'
      />
      <ScoreVsTimeChart
        data={scoreHistory}
        currentScore={score}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot
        events={events}
        // colorType='severity'
        loading={eventsLoading}
      />
      <EventsVsTimeChart
        events={events}
        loading={eventsLoading}
        groupBy='type'
        keys={['DRIVING_TIME', 'PHONE_MOTION', 'CONTINUOUS_DRIVING']}
      />
    </FlexBox>
  )
}

export default RestedFocused
