import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const locationFragment = gql`
  fragment locationFragment on Location {
    _id
    timestamp
    longitude
    latitude
    heading
    speed
  }
`

export const GET_LOCATION = gql`
  query location($id: String) {
    location(id: $id) {
      ...locationFragment
      device {
        _id
        deviceId
      }
    }
  }
  ${locationFragment}
`

export const locationDataTransform = ({ data }) => {
  const lat = data.latLong
    ? data.latLong.lat
    : data.latitude
    ? data.latitude
    : null
  const lng = data.latLong
    ? data.latLong.lng
    : data.longitude
    ? data.longitude
    : null
  return {
    ...data,
    VVTdeviceId: data.device ? data.device.deviceId : null,
    deviceId: data.device ? data.device._id : null,
    location: { lat, lng },
    timestamp: new Date(data.timestamp).getTime()
  }
}

export const useLocation = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_LOCATION, {
    variables: { id },
    queryName: 'location',
    errorMessage: 'Failed to fetch Location',
    dataTransform: (data) => locationDataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
