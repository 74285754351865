import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import {
  useCreateDriver,
  CREATE_DRIVER
} from '../../Graphql/Mutations/useCreateDriver'
import { GET_DRIVERS } from '../../Graphql/Queries/Driver/useDrivers'
import {
  Button,
  FlexBox,
  ModalAlert,
  Form,
  Subsection,
  FormGraphQL,
  TableGraphQL,
  ProcessingSpinner
} from '../../Components'
import { Metrics, useColors } from '../../Themes'
import { useLinkDriverToAsset } from '../../Graphql/Mutations/useLinkDriverToAsset'
import DriverSelectionTable from '../../Components/Custom/SelectionPanel/Driver/DriverSelectionTable'
import { Dialog } from '@material-ui/core'

const handleLinkDriver = ({
  assetId,
  driverId,
  setSubmitting,
  linkDriverToAsset,
  handleClose
}) => {
  setSubmitting(true)

  const linkDriverMutationVariables = {
    driverId,
    assetId
  }

  linkDriverToAsset({
    variables: linkDriverMutationVariables,
    // refetchQueries: [{ query: GET_JOB_CARDS, query: GET_JOB_CARD }],
    onCompleted: (data) => {
      setSubmitting(false)
      handleClose()
    },
    onError: (data) => {
      console.log(data)
      setSubmitting(false)
      handleClose()
    }
  })
}

const LinkDriverToAsset = ({
  setShowLinkDriverModal,
  assetId,
  showLinkDriverModal
}) => {
  const Colors = useColors()
  const [submittingRowClick, setSubmittingRowClick] = useState(false)

  const [linkDriverToAsset, { loading: linkDriverToAssetLoading }] =
    useLinkDriverToAsset()

  return (
    <Dialog
      open={showLinkDriverModal}
      maxWidth='md'
      fullWidth
      // style={{ width: mainContainerWidth, maxWidth: mainContainerWidth }}
      onClose={() => setShowLinkDriverModal(false)}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      {submittingRowClick ? (
        <div
          style={{
            border: `1px solid ${Colors.subsectionBackground}`,
            borderRadius: Metrics.radius,
            marginTop: Metrics.base * 2
          }}
        >
          <div style={{ height: 200 }}>
            <ProcessingSpinner loading={'Submitting'} mb={3} size={36} />
          </div>
        </div>
      ) : (
        <DriverSelectionTable
          setDriverId={(id) => {
            handleLinkDriver({
              assetId,
              driverId: id,
              setSubmitting: setSubmittingRowClick,
              linkDriverToAsset,
              handleClose: () => setShowLinkDriverModal(false)
            })
          }}
        />
      )}
    </Dialog>
  )
}

export default LinkDriverToAsset
