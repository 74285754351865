import React from 'react'
import { FlexBox, InfoBadge } from '../../../../Components'
import { useHistoryScoreHistory } from '../../../../Graphql/Queries/Asset/SafetyScoreHistory/useHistoryScoreHistory'
import { Metrics, useColors } from '../../../../Themes'
import ScoreVsTimeChart from '../../Driver/Categories/ScoreVsTimeChart'
import { DescriptionContent } from '../../Trip/Breakdown'
import { useScoreHistory } from './useScoreHistory'

const History = ({ assetId, assetSafetyData, score }) => {
  const Colors = useColors()
  const incidentCount = assetSafetyData ? assetSafetyData.incidentCount : 0
  const incidentNormScore = assetSafetyData
    ? assetSafetyData.incidentNormScore
    : 0
  const crashCount = assetSafetyData ? assetSafetyData.crashCount : 0
  const crashNormScore = assetSafetyData ? assetSafetyData.crashNormScore : 0
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useHistoryScoreHistory({ assetId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ assetId, scoreHistoryData, category: 'HISTORY' })
  return (
    <FlexBox direction='column'>
      <FlexBox
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`,
          marginTop: -Metrics.base * 3,
          marginBottom: Metrics.base * 4,
          paddingBottom: Metrics.base
        }}
      >
        <DescriptionContent
          points={[
            `This score indicates the history of incidents or crashes for an asset. Assets that are involved in many incidents or crashes over their usable life will score lower in this category.`,
            'This accounts for 20% of the Total Asset Safety Score.'
          ]}
        />
      </FlexBox>
      <FlexBox
        direction='row'
        style={{ width: '100%', marginBottom: Metrics.base * 3 }}
      >
        <InfoBadge
          size='small'
          value={`${incidentCount}`}
          label={'Incidents'}
        />
        <InfoBadge
          size='small'
          value={`${Math.round(incidentNormScore * 100) / 100}/10`}
          label={'Incident Score'}
        />
        <InfoBadge size='small' value={`${crashCount}`} label={'Crashes'} />
        <InfoBadge
          size='small'
          value={`${Math.round(crashNormScore * 100) / 100}/10`}
          label={'Crashes Score'}
        />
      </FlexBox>
      <ScoreVsTimeChart
        currentScore={score}
        data={scoreHistory}
        loading={scoreHistoryLoading}
      />
    </FlexBox>
  )
}

export default History
