import React, { useEffect, useState } from 'react'
import { Metrics, useColors } from '../../Themes'
import { MainLayout, TabBar, FlexBox, Status } from '../../Components'
import { Table } from '../../Components/Table'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { formatDateTime } from '../../Helpers/Functions'
import { useCoachingLessonsByDriverId } from '../../Graphql/Queries/Coaching/useCoachingLessonsByDriverId'
import { selectedDriverId } from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'

const lessonsColumns = (Colors) => [
  {
    Header: 'ID',
    accessor: 'displayId'

    // Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Name',
    accessor: 'name'
    // Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Status',
    accessor: 'coachingStatus',
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      ),
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Score',
    accessor: 'score'
    // Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) =>
      value ? formatDateTime(new Date(value)) : '-',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Date Completed',
    accessor: 'completedAt',
    Cell: ({ cell: { value } }) =>
      value ? formatDateTime(new Date(value)) : '-',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const getBorderLeftStyles = (cell, Colors) => {
  if (cell.value) return { borderLeft: `5px solid ${Colors[cell.value]}` }
  return {}
}

const Lessons = (props) => {
  const Colors = useColors()
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState({})
  const driverId = useReactiveVar(selectedDriverId)
  const { data: events, loading } = useCoachingLessonsByDriverId(driverId)

  useEffect(() => {
    setData(events)
  }, [events])

  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      <Table
        handleRowClick={(row) => {
          props.history.push({
            pathname: '/view-lesson',
            search: `?id=${row.original._id}`
          })
        }}
        loading={loading}
        columns={lessonsColumns(Colors)}
        getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
        style={{ width: '100%' }}
        hideNewButton
        dataState={dataState}
        setData={setData}
        title='Lessons'
        sortBy={[{ id: 'createdAt', desc: true }]}
        defaultPageSize={20}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
      />
    </FlexBox>
  )
}
export default Lessons
