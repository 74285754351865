import React, { useState, useEffect } from 'react'
import { FlexBox, Button, Text, Subsection, Form } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { useServiceByAssetId } from '../../../Graphql/Queries/useServiceByAssetId'
import CardsContainer from '../../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import CardsList from '../../../Components/Custom/SelectionPanel/Cards/CardsList'
import ServiceCard from '../../../Components/Custom/SelectionPanel/ServiceCard.js'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import NoneSelected from '../../../Components/Custom/NoneSelected'

const getFilterCount = (filters) => {
  let filterCount = 0
  Object.keys(filters).map((key) => {
    if (checkVal(filters[key]) && key !== 'assetId') {
      filterCount += 1
    }
  })
  return filterCount
}
const ServiceTimeline = ({ Colors, serviceIds, servicesLoading, history }) => {
  return (
    <FlexBox
      direction='row'
      fillSpace
      style={{
        width: '100%',
        marginLeft: Metrics.base * 2,
        justifyContent: 'flex-start',
        alignItems: 'stretch'
      }}
    >
      <FlexBox
        style={{ width: 1, backgroundColor: Colors.dividerColor }}
      ></FlexBox>
      <FlexBox
        style={{
          width: '90%',
          paddingBottom: Metrics.base * 4,
          paddingTop: Metrics.base * 2
        }}
      >
        <CardsContainer
          innerContainerStyle={{
            borderTop: 'none',
            borderBottom: 'none',
            backgroundColor: 'none'
          }}
          loading={servicesLoading}
        >
          <CardsList items={serviceIds} itemsName='Services'>
            {({ items, item, index }) => (
              <>
                <FlexBox
                  direction='column'
                  style={{ alignItems: 'flex-start', width: '100%' }}
                >
                  <FlexBox
                    direction='row'
                    style={{ width: '100%', marginTop: Metrics.base * 3 }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 1,
                        backgroundColor: Colors.dividerColor
                      }}
                    ></div>
                    <ServiceCard
                      serviceId={item._id}
                      index={index}
                      key={index}
                      selected={true}
                      noBoxShadow
                      backgroundColor={Colors.subsectionBackground}
                      borderBottom={
                        index + 1 >= items.length || items[index + 1]
                      }
                    />
                  </FlexBox>
                </FlexBox>
              </>
            )}
          </CardsList>
        </CardsContainer>
      </FlexBox>
    </FlexBox>
  )
}
const ServiceHistory = (props) => {
  const assetId = useReactiveVar(selectedAssetId)
  const Colors = useColors(useColors)
  const [assetSelected, setAssetSelected] = useState()
  const [serviceFilters, setServiceFilters] = useState({})
  const { data: services, loading: servicesLoading } =
    useServiceByAssetId(serviceFilters)

  useEffect(() => {
    if (assetId) {
      setServiceFilters({ ...serviceFilters, assetId })
    }
  }, [assetId])

  const filterServiceSchema = [
    {
      name: 'dateRange',
      type: 'dateBetween',
      label: 'Date range',
      style: { width: '20%', alignSelf: 'left', marginRight: Metrics.base * 3 },
      value: {
        startDate: serviceFilters.startDate ? serviceFilters.startDate : null,
        endDate: serviceFilters.endDate ? serviceFilters.endDate : null
      }, // filters.startTime,
      controlled: true,
      handleChange: (value) => {
        setServiceFilters({
          ...serviceFilters,
          startDate: value.startDate,
          endDate: value.endDate
        })
      }
    }
  ]

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      {assetId ? (
        <FlexBox
          fillSpace
          direction='column'
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Subsection
            style={{
              width: '100%',
              // paddingBottom: Metrics.base * 2,
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Text
              font='subheading'
              style={{
                textAlign: 'left',
                paddingLeft: Metrics.base * 2,
                paddingRight: Metrics.base * 2,
                paddingTop: Metrics.base * 1
              }}
            >
              Filter Services By:
            </Text>
            <Form
              hideSubmit
              formbox={false}
              style={{
                backgroundColor: 'none',
                boxShadow: 'none',
                width: '100%'
              }}
              innerFormStyles={{
                paddingBottom: 0,
                paddingLeft: Metrics.base * 2,
                justifyContent: 'flex-start'
              }}
              hideFormBox
              darkmode={true}
              direction='row'
              data={filterServiceSchema}
            />
            <FlexBox direction='row' style={{ paddingLeft: Metrics.base * 2 }}>
              <Button
                condensed
                title='Clear Filters'
                textColor='white'
                background='red'
                onClick={() => {
                  setServiceFilters({ assetId: serviceFilters.assetId })
                }}
                style={{
                  marginBottom: Metrics.base
                }}
              />
              <Text
                font='body'
                color={Colors.primary}
                style={{
                  paddingBottom: Metrics.base * 1,
                  paddingLeft: Metrics.base * 2
                }}
              >
                Filters Active: {getFilterCount(serviceFilters)}
              </Text>
            </FlexBox>
          </Subsection>

          {services && services.length > 0 ? (
            <ServiceTimeline
              Colors={Colors}
              serviceIds={services}
              servicesLoading={servicesLoading}
              history={props.history}
              style={{ width: '100%' }}
            />
          ) : (
            <FlexBox
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text
                font='heading'
                style={{
                  textAlign: 'left',
                  paddingTop: Metrics.base * 5
                }}
              >
                No Services Found
              </Text>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <NoneSelected helperText='Please select an asset from the list on the left' />
      )}
    </FlexBox>
  )
}
export default ServiceHistory
