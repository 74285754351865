import { useReactiveVar } from '@apollo/client'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FlexBox, MaterialInput } from '../../Components'
import Button from '../../Components/Button'
import AssetMultipleSelectionTable from '../../Components/Custom/SelectionPanel/Asset/AssetMultipleSelectionTable'
import {
  Display,
  Heading,
  Subheading,
  Title
} from '../../Components/Typography'
import { filters } from '../../Graphql/GraphQLClient'
import { useAddAssetsToGroup } from '../../Graphql/Mutations/useAddAssetsToGroup'
import { useMutateAssetGroup } from '../../Graphql/Mutations/useMutateAssetGroup'
import { useRemoveAssetsFromGroup } from '../../Graphql/Mutations/useRemoveAssetsFromGroup'
import { useUser } from '../../Graphql/Queries/useUser'
import { checkVal } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'

const assetGroupActionOptions = [
  { value: 'VIEW', label: 'View Assets' },
  { value: 'ADD', label: 'Add Assets' },
  { value: 'REMOVE', label: 'Remove Assets' }
]

const handleCreateGroup = ({
  orgId,
  description,
  name,
  handleClose,
  createGroup,
  selectedIds
}) => {
  const variables = { orgId, description, name, assetIds: selectedIds }
  return createGroup({
    variables,
    onCompleted: () => handleClose()
  })
}

const HeaderContent = ({
  defaultSelected,
  totalCount,
  setDefaultSelected,
  selectedIds,
  selectedOrgId,
  handleClose,
  createAssetGroup
}) => {
  const Colors = useColors()
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        height: Metrics.base * 20,
        padding: Metrics.base * 2,
        backgroundColor: Colors.subsectionBackground
      }}
    >
      <FlexBox
        direction='row'
        style={{
          justifyContent: 'space-between',
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`,
          padding: Metrics.base * 2
        }}
      >
        <Heading>New Group</Heading>
        <FlexBox>
          <Subheading color={Colors.primary} marginRight={4}>
            {checkVal(totalCount)
              ? `${totalCount} ${'Ungrouped'} Assets`
              : 'Assets'}
          </Subheading>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={defaultSelected}
                onChange={(event) => {
                  setDefaultSelected(event.target.checked)
                }}
                style={{
                  color: Colors.primary
                }}
              />
            }
            label='Select All'
          />
        </FlexBox>
        <FlexBox style={{ direction: 'row' }}>
          <Button
            primary
            title={`Create Group ${
              selectedIds && selectedIds.length > 0
                ? `(${selectedIds.length})`
                : ''
            }`}
            background={Colors.primary}
            style={{
              width: 250,
              marginLeft: Metrics.base * 2,
              marginRight: Metrics.base * 2
            }}
            bold
            textColor={Colors.background}
            disabled={
              !checkVal(description) ||
              !checkVal(name) ||
              !selectedIds ||
              selectedIds <= 0
            }
            onClick={() =>
              handleCreateGroup({
                orgId: selectedOrgId,
                description,
                name,
                handleClose,
                createGroup: createAssetGroup,
                selectedIds
              })
            }
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction='row'
        style={{
          justifyContent: 'flexStart',
          width: '100%',
          padding: Metrics.base * 2
        }}
      >
        <MaterialInput
          name='groupName'
          id='group-name'
          // variant='outlined'
          label='Group Name'
          styles={{
            outerContainer: {
              flex: 1,
              marginRight: Metrics.base
            }
          }}
          value={name}
          defaultValue={name}
          handleChange={(value) => setName(value)}
        />
        <MaterialInput
          name='groupDescription'
          id='group-description'
          // variant='outlined'
          label='Group Description'
          styles={{
            outerContainer: {
              flex: 1,
              marginLeft: Metrics.base
            }
          }}
          value={description}
          defaultValue={description}
          handleChange={(value) => setDescription(value)}
        />
      </FlexBox>
    </FlexBox>
  )
}

const NewAssetGroup = ({ handleClose }) => {
  const Colors = useColors()
  const [defaultSelected, setDefaultSelected] = useState(true)
  const [selectedIds, setSelectedIds] = useState([])
  const { createAssetGroup, loading: createAssetsLoading } =
    useMutateAssetGroup()
  const { orgId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []

  useEffect(() => {
    if (orgOptions && orgOptions.length > 0) {
      filters({ ...filters(), orgId: orgOptions[0]._id })
    }
  }, [orgOptions])
  const selectedOrgId = checkVal(orgId)
    ? orgId
    : orgOptions && checkVal(orgOptions[0])
    ? orgOptions[0]
    : null
  return (
    <AssetMultipleSelectionTable
      disableOrgFilter={false}
      orgId={selectedOrgId}
      groupId={'NO_GROUP'}
      defaultSelected={defaultSelected}
      setSelectedIds={setSelectedIds}
      selectionColor={Colors.primary}
      renderHeading={(totalCount) => (
        <HeaderContent
          defaultSelected={defaultSelected}
          totalCount={totalCount}
          setDefaultSelected={setDefaultSelected}
          selectedIds={selectedIds}
          selectedOrgId={selectedOrgId}
          handleClose={handleClose}
          createAssetGroup={createAssetGroup}
        />
      )}
    />
  )
}

export default NewAssetGroup
