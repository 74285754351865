import { css } from 'aphrodite'
import React from 'react'
import { Metrics } from '../../Themes'
import { Styles } from './Styles'

const SelectableFlexBox = ({
  style,
  fillSpace,
  selectable,
  selected,
  Colors,
  backgroundColor,
  selectedBackgroundColor,
  selectHighlightColor,
  direction,
  justifyContent,
  alignItems,
  children,
  ...props
}) => {
  const fillSpaceStyles = fillSpace ? { minHeight: 0, flexGrow: 1 } : {}
  return (
    <div
      className={css(
        Styles({
          Colors,
          Metrics,
          backgroundColor,
          selectedBackgroundColor,
          selectHighlightColor,
          selected
        }).selectable
      )}
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justifyContent || 'center',
        alignItems: alignItems || 'center',
        ...fillSpaceStyles,
        ...style
      }}
      {...props}
    >
      {children}
    </div>
  )
}

export default SelectableFlexBox
