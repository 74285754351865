import { useEffect, useState } from 'react'
import { UPDATE_NOTIFICATION } from '../../Graphql/Mutations'
import {
  useSubscription as useApolloSubscription,
  useMutation
} from '@apollo/client'

export const useSubscription = (
  SUBSCRIPTION,
  {
    variables,
    subscriptionName,
    dataTransform = (data) => data,
    errorMessage,
    tempData,
    skip
  }
) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION)

  const {
    data: subscriptionData,
    loading: subscriptionLoading,
    error: subscriptionError
  } = useApolloSubscription(SUBSCRIPTION, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: tempData ? true : skip,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const actualData =
    subscriptionData && subscriptionData[subscriptionName]
      ? subscriptionData[subscriptionName]
      : null

  useEffect(() => {
    setLoading(subscriptionLoading)
  }, [subscriptionLoading])

  useEffect(() => {
    if (subscriptionError) {
      setError(subscriptionError)
      updateNotification({
        variables: {
          show: true,
          level: 'danger',
          text: errorMessage
            ? errorMessage
            : `${subscriptionName} Failed to fetch`
        }
      })
    }
  }, [subscriptionError, updateNotification, errorMessage, subscriptionName])

  useEffect(() => {
    if (actualData || tempData) {
      setData(tempData ? dataTransform(tempData) : dataTransform(actualData))
    }
  }, [actualData])

  return { data, loading, error }
}
