import React, { useState } from 'react'
import { Metrics, useColors, Colors } from '../../../Themes'
import { FlexBox, MaterialInput, Text } from '../../'
import { Heading } from '../../Typography'

const GeneralMetricsAvg = ({ average, title, data, units, style }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='row'
      fillSpace
      style={{
        justifyContent: 'flex-start',
        width: '100%',
        paddingLeft: Metrics.base * 4
      }}
    >
      <FlexBox>
        <Heading
          style={{
            paddingRight: Metrics.base * 2,
            paddingBottom: Metrics.base * 2,
            maxWidth: 250,
            minWidth: 100
            //backgroundColor: 'red'
          }}
        >
          {average} {units}
        </Heading>
      </FlexBox>
      <FlexBox>
        <Text
          style={{
            fontSize: 12,
            minWidth: 50,
            maxWidth: 120,
            paddingLeft: Metrics.base * 2,
            paddingBottom: Metrics.base * 2,
            //backgroundColor: 'red',
            paddingRight: Metrics.base * 2
          }}
        >
          Average {title}
        </Text>
      </FlexBox>
    </FlexBox>
  )
}

GeneralMetricsAvg.defaultProps = {
  color: Colors.secondary
}

export default GeneralMetricsAvg
