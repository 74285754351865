import React, { useState, useEffect } from 'react'
import {
  FlexBox,
  Text,
  Subsection,
  Button,
  Status,
  DataDisplay,
  MainLayout
} from '../../Components'
import SecondarySidePanel from '../../Components/SidePanel/SecondarySidePanel'
import SelectedObjectHeader from '../../Components/Custom/SelectedObjectHeader'

import InfoBadge from '../../Components/Custom/InfoBadge'
import SubsectionDivider from '../../Components/Custom/SubsectionDivider'
import { Metrics, useColors, Images } from '../../Themes'
import { useCoachingLesson } from '../../Graphql/Queries/Coaching/useCoachingLesson'
import { checkVal, formatDateTime } from '../../Helpers/Functions'
import EventToCoachingCard from '../../Components/Custom/EventToCoachingCard'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import LargeDivider from '../../Components/LargeDivider'
import { useChangeLessonStatus } from '../../Graphql/Mutations/useChangeLessonStatus'

import {
  Display,
  Heading,
  Subheading,
  Title,
  Title2
} from '../../Components/Typography'
import { useHistory } from 'react-router-dom'

const getUrlId = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('id')
}

export const EventTotalCounts = ({
  score,
  category,
  hideBorderRight,
  imgSrc,
  imgAlt
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace
      style={{
        borderRight: hideBorderRight
          ? 'none'
          : `1px solid ${Colors.dividerColor}`,
        minHeight: 130,
        maxHeight: 300
      }}
    >
      <img
        width={35}
        style={{
          marginRight: Metrics.base
        }}
        alt={imgAlt}
        src={imgSrc}
      />
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          padding: Metrics.base * 2
        }}
      >
        <Heading>{checkVal(score) ? score : '-'}</Heading>
        <Title2 color={Colors.textSecondary} marginTop={1}>
          {category}
        </Title2>
      </FlexBox>
    </FlexBox>
  )
}
const LessonEventTotals = ({ lesson, Colors }) => {
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  let speedCount = 0
  let accelerationCount = 0
  let percentSpeedCount = 0
  let tripDistanceCount = 0
  let travelTimeCount = 0
  let drivingTimeCount = 0
  let harshCorneringCount = 0

  if (lesson && lesson.lessonEvents) {
    lesson.lessonEvents.forEach(({ coachingEvent }) => {
      if (coachingEvent && coachingEvent.event) {
        switch (coachingEvent.event.type) {
          case 'SPEEDING':
            speedCount = speedCount + 1
            break
          case 'PERCENT_SPEEDING':
            percentSpeedCount += 1
            break
          case 'TRIP_DISTANCE':
            tripDistanceCount += 1
            break
          case 'TRAVEL_TIME':
            travelTimeCount += 1
            break
          case 'DRIVING_TIME':
            drivingTimeCount += 1
            break
          case 'ACCELERATION':
            accelerationCount += 1
            break
          case 'HARSH_CORNERING':
            harshCorneringCount += 1
            break
          default:
            break
        }
      }
    })
  }
  return (
    <FlexBox
      // fillSpace
      direction='column'
      justifyContent='space-evenly'
      style={{
        width: '90%'
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        style={{
          width: '100%',
          height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        <InfoBadge
          style={{
            width: '100%',
            alignItems: 'left',
            justifyContent: 'flex-start'
          }}
          noBoxShadow
          value='Lesson Event Counters'
        />{' '}
        <LargeDivider />
        <FlexBox style={{ width: '100%' }}>
          <EventTotalCounts
            score={speedCount}
            category='Speeding'
            imgSrc={Images.SPEEDING}
            imgAlt={'speeding_icon'}
          />
          <EventTotalCounts
            score={percentSpeedCount}
            category='Percent Speeding'
            imgSrc={Images.error}
            imgAlt={'percent_speeding_icon'}
          />
        </FlexBox>
        <LargeDivider />
        <FlexBox style={{ width: '100%' }}>
          <EventTotalCounts
            score={tripDistanceCount}
            category='Trip Distance'
            imgSrc={Images.error}
            imgAlt={'trip_distance_icon'}
          />
          <EventTotalCounts
            score={travelTimeCount}
            category='Travel Time'
            imgSrc={Images.error}
            imgAlt={'travel_time_icon'}
          />
        </FlexBox>
        <LargeDivider />
        <FlexBox style={{ width: '100%' }}>
          <EventTotalCounts
            score={drivingTimeCount}
            category='Driving Time'
            imgSrc={Images.error}
            imgAlt={'speeding_icon'}
          />
          <EventTotalCounts
            score={harshCorneringCount}
            category='Harsh Cornering'
            imgSrc={Images.HARSH_CORNERING}
            imgAlt={'harsh_cornering_icon'}
          />
        </FlexBox>
        <LargeDivider />
        <FlexBox style={{ width: '100%' }}>
          <EventTotalCounts
            score={accelerationCount}
            category='Acceleration'
            imgSrc={Images.HARSH_ACCELERATING}
            imgAlt={'acceleration_icon'}
          />
        </FlexBox>
        <LargeDivider />
      </Subsection>
    </FlexBox>
  )
}
const handleChangeStatus = async ({ lesson, changeLessonStatus }) => {
  if (lesson) {
    const changesStatusMutationVariables = {
      lessonId: lesson.id,
      lessonStatus: 'ASSIGNED'
    }

    await changeLessonStatus({
      variables: changesStatusMutationVariables,
      onCompleted: (data) => {
        console.log(data)
      },
      onError: (data) => {}
    })
  }
}

const LessonEvents = ({ lesson, Colors, history }) => {
  const [changeLessonStatus] = useChangeLessonStatus()
  return (
    <FlexBox
      fillSpace
      style={{
        width: '100%',
        padding: Metrics.base * 2,
        alignItems: 'flex-start'
      }}
    >
      <Subsection
        style={{
          width: '100%',
          height: '100%',
          justifyContent: 'flex-start'
        }}
      >
        <FlexBox style={{ width: '100%' }}>
          <Button
            condensed
            title=''
            icon='arrow_back'
            onClick={() => history.goBack()}
            textColor='white'
            background='red'
            style={{
              marginLeft: Metrics.base * 3,
              marginRight: Metrics.base * 3
            }}
          />
          <InfoBadge
            style={{
              width: '100%',
              justifyContent: 'flex-start'
            }}
            noBoxShadow
            actionButton='Assign to Driver'
            handleButtonClick={() => {
              handleChangeStatus({ lesson, changeLessonStatus })
            }}
            // leadIcon='check'
            value={lesson.name}
          />
        </FlexBox>
        <SubsectionDivider />
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            backgroundColor: Colors.background,
            borderRight: `1px solid ${Colors.dividerColor}`,
            borderLeft: `1px solid ${Colors.dividerColor}`
          }}
        >
          {checkVal(lesson) &&
            checkVal(lesson.lessonEvents) &&
            lesson.lessonEvents.map((event) => {
              return (
                <EventToCoachingCard
                  eventId={event.coachingEvent._id}
                  lesson={event}
                />
              )
            })}
        </FlexBox>
      </Subsection>
    </FlexBox>
  )
}

const ViewLesson = (props) => {
  const Colors = useColors()
  const id = getUrlId()
  const { data: lesson } = useCoachingLesson(id)
  const [lessonData, setLessonDate] = useState()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const history = useHistory()

  useEffect(() => {
    if (lesson) {
      setLessonDate(lesson)
    }
  }, [lesson])

  return (
    <FlexBox style={{ width: '100%', backgroundColor: Colors.background }}>
      {checkVal(lessonData) && (
        <LessonEvents lesson={lessonData} Colors={Colors} history={history} />
      )}
    </FlexBox>
  )
}
export default ViewLesson
