import React, { useEffect, useState } from 'react'
import { Metrics, useColors } from '../../Themes'
import {
  FlexBox,
  Status,
  MaterialInput,
  Text,
  Button,
  Subsection
} from '../../Components'
import { Table } from '../../Components/Table'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { useFilters } from '../../Graphql/Queries/useFilters'
import FilterQueries from '../../Components/Custom/FilterQueries'
import { checkVal, formatDateTime } from '../../Helpers/Functions'
import { filters, selectedDriverId } from '../../Graphql/GraphQLClient'
import SubsectionDivider from '../../Components/Custom/SubsectionDivider'
import EventToCoachingCard from '../../Components/Custom/EventToCoachingCard'
import { useCoachingEventsByDriverId } from '../../Graphql/Queries/Coaching/useCoachingEventsByDriverId'
import { useCreateCoachingLesson } from '../../Graphql/Mutations/useCreateCoachingLesson'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { Checkbox } from '@material-ui/core'
import EventFilters from '../../Components/Custom/SelectionPanel/Event/EventFilters'
import { useReactiveVar } from '@apollo/client'

const eventTypeOptions = [
  { value: 'PERCENT_SPEEDING', label: 'Percent Speeding' },
  { value: 'TRIP_DISTANCE', label: 'Trip Distance' },
  { value: 'TRAVEL_TIME', label: 'Travel Time' },
  { value: 'DRIVING_TIME', label: 'Driving Time' },
  { value: 'SPEEDING', label: 'Speeding' },
  { value: 'ACCELERATION', label: 'Acceleration' }
]

const eventColumns = (Colors) => [
  {
    Header: 'Selected',
    accessor: 'selected',
    Aggregated: ({ cell: { value } }) => `-`,
    Cell: ({ cell: { value, row } }) => (
      <FlexBox>
        <Checkbox
          size='small'
          checked={value}
          style={{
            color: Colors.primary
          }}
        />
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={row.original.event.type}
          getStatusColors={() => Colors[row.original.event.type]}
        />
        {/*<Text>{row.original.event.type}</Text>*/}
      </FlexBox>
    )
  },
  {
    Header: 'Score',
    accessor: 'event.normScore',
    Cell: ({ cell: { value } }) => (value ? value.toFixed(2) : '-'),
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Category',
    accessor: 'event.category',
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      ),
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const getBorderLeftStyles = (cell, Colors) => {
  if (
    cell &&
    cell.row &&
    cell.row.original &&
    cell.row.original.event &&
    cell.row.original.event.type
  )
    return { borderLeft: `5px solid ${Colors[cell.row.original.event.type]}` }
  return {}
}

const updateSubmitedRows = ({ dataState, setData }) => {
  let newData = []

  if (checkVal(dataState)) {
    newData = dataState.filter((row) => {
      return !row.selected
    })
  }
  setData(newData)
}

const handleSubmit = async ({
  driverId,
  coachingStatus,
  createCoachingLesson,
  eventsWithComments,
  setEventsWithComments,
  setEventsToBeAdded,
  setCreatingLesson,
  lessonName,
  dataState,
  setData
}) => {
  const createCoachingLessonVariables = {
    driverId,
    coachingStatus,
    name: lessonName,
    lessonEvents: [...eventsWithComments]
  }

  await createCoachingLesson({
    variables: createCoachingLessonVariables,
    onCompleted: (data) => {
      updateSubmitedRows({ dataState, setData })
      setEventsWithComments([])
      setEventsToBeAdded([])
      setCreatingLesson(false)
    },
    onError: (data) => {}
  })
}

// Method to populate an object array using the checkbox array, in the form [{coachingEventId: id, comment: String}], called on changing to create lesson page
const populateEventsCommentsArray = ({
  eventsToBeAdded,
  setEventsWithComments,
  eventsWithComments,
  setCreatingLesson
}) => {
  let finalArr = []
  if (eventsToBeAdded && eventsToBeAdded.length > 0) {
    // check if the checkbox array is not empty
    if (eventsWithComments && eventsWithComments.length > 0) {
      // checks if the object array for mutation is not empty
      if (eventsToBeAdded.length >= eventsWithComments.length) {
        // if checkbox array is bigger then need to add items to object array
        let tempArray = eventsWithComments.map((event) => {
          return event.coachingEventId
        })
        tempArray = eventsToBeAdded.filter((id) => {
          return !tempArray.includes(id)
        })
        tempArray.forEach((id) => {
          setEventsWithComments([
            ...eventsWithComments,
            {
              coachingEventId: id,
              comment: ''
            }
          ])
        })
      } else if (eventsToBeAdded.length <= eventsWithComments.length) {
        // if object array is bigger then have to remove objects from object array
        let tempArray = eventsWithComments.filter((id) => {
          return !eventsToBeAdded.includes(id.coachingEventId)
        })
        tempArray.forEach((id) => {
          let index = eventsWithComments.findIndex(
            (c) => c.coachingEventId === id
          )
          eventsWithComments.splice(index, 1)
        })
        setEventsWithComments([...eventsWithComments])
      }
    } else {
      // if the object array is empty can populate it with all the items from the checkbox array
      eventsToBeAdded.forEach((eventId) => {
        finalArr = [...finalArr, { coachingEventId: eventId, comment: '' }]
      })
      setEventsWithComments([...finalArr])
    }
  } else if (eventsToBeAdded.length === 0 && eventsWithComments.length > 0) {
    // if checkbox array is empty but object array isnt it needs to be cleared
    setEventsWithComments([])
  }
}

const EventTable = ({
  loading,
  Colors,
  dataState,
  setData,
  selectedObject,
  setSelectedObject,
  setCreatingLesson,
  checkboxArray,
  setCheckboxArray,
  setEventsWithComments,
  eventsWithComments
}) => {
  const numEvents = dataState && dataState.length
  return (
    <>
      <FlexBox style={{ width: '100%' }}>
        <EventFilters
          showDateFilter
          showTypeFilter
          showScoreFilter
          direction={'row'}
          maxInputWidth={270}
          innerFormStyles={{ padding: Metrics.base }}
        />
      </FlexBox>
      <Table
        loading={loading}
        hideColumnHeaders={true}
        columns={eventColumns(Colors)}
        handleRowClick={(row, updateMyData) => {
          setSelectedObject(row.original)
          updateMyData(row.index, 'selected', !row.values.selected)
        }}
        getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
        hideNewButton
        dataState={dataState}
        setData={setData}
        style={{ width: '100%' }}
        title={numEvents ? `${numEvents} Events` : 'Events'}
        sortBy={[{ id: 'id' }]}
        defaultPageSize={20}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
        checkboxSelect
        checkboxArray={checkboxArray}
        setCheckboxArray={setCheckboxArray}
        headerContentRight={
          <FlexBox>
            <Button
              disabled={!(checkboxArray && checkboxArray.length > 0)}
              title='Create Lesson'
              onClick={() => {
                setCreatingLesson(true)
              }}
              textColor='white'
              background='red'
              style={{ marginRight: Metrics.base * 2 }}
            />
          </FlexBox>
        }
      />
    </>
  )
}

const CreateLesson = ({
  driverId,
  setCreatingLesson,
  eventsToBeAdded,
  setEventsToBeAdded,
  Colors,
  eventsWithComments,
  setEventsWithComments,
  dataState,
  setData
}) => {
  const [createCoachingLesson] = useCreateCoachingLesson()
  const [lessonName, setLessonName] = useState('')
  return (
    <Subsection style={{ width: '100%' }}>
      <FlexBox
        direction='column'
        style={{
          width: '100%'
        }}
      >
        <FlexBox
          direction='row'
          style={{
            width: '100%',
            justifyContent: 'space-between',
            padding: Metrics.base * 3
          }}
        >
          <FlexBox style={{ width: '100%', justifyContent: 'flex-start' }}>
            <Button
              condensed
              title=''
              icon='arrow_back'
              onClick={() => setCreatingLesson(false)}
              textColor='white'
              background='red'
              style={{ marginRight: Metrics.base * 5 }}
            />
            <Text font='heading' style={{ marginRight: Metrics.base * 1 }}>
              {' '}
              Lesson:
            </Text>
            <MaterialInput
              type='textarea'
              name='name'
              value={lessonName}
              label={`Name`}
              handleChange={(e) => {
                setLessonName(e)
              }}
            />
          </FlexBox>
          <FlexBox style={{ width: '100%', justifyContent: 'space-evenly' }}>
            <Button
              title='Save Draft'
              condensed
              onClick={() =>
                handleSubmit({
                  driverId,
                  coachingStatus: 'CREATED',
                  createCoachingLesson,
                  eventsWithComments,
                  setEventsWithComments,
                  setEventsToBeAdded,
                  setCreatingLesson,
                  lessonName,
                  dataState,
                  setData
                })
              }
              textColor='white'
              background='red'
              style={{ marginRight: Metrics.base * 2 }}
            />
            <Button
              title='Send to Driver'
              onClick={() =>
                handleSubmit({
                  driverId,
                  coachingStatus: 'ASSIGNED',
                  createCoachingLesson,
                  eventsWithComments,
                  setEventsWithComments,
                  setEventsToBeAdded,
                  setCreatingLesson,
                  lessonName,
                  dataState,
                  setData
                })
              }
              textColor='white'
              background='red'
              // style={{ marginRight: Metrics.base * 2 }}
            />
          </FlexBox>
        </FlexBox>
        <SubsectionDivider />
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            backgroundColor: Colors.background,
            borderRight: `1px solid ${Colors.dividerColor}`,
            borderLeft: `1px solid ${Colors.dividerColor}`
          }}
        >
          {checkVal(eventsToBeAdded) &&
            eventsToBeAdded.map((event) => {
              return (
                <EventToCoachingCard
                  eventId={event}
                  setEventsToBeAdded={setEventsToBeAdded}
                  eventsToBeAdded={eventsToBeAdded}
                  eventsWithComments={eventsWithComments}
                  setEventsWithComments={setEventsWithComments}
                  fromCreateLesson
                />
              )
            })}
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

const EventQueue = (props) => {
  const Colors = useColors()
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState({})
  const driverId = useReactiveVar(selectedDriverId)
  const [selectedId, setSelectedId] = useState('')
  const { data: events, loading } = useCoachingEventsByDriverId(selectedId)
  const [creatingLesson, setCreatingLesson] = useState(false)
  const [eventsToBeAdded, setEventsToBeAdded] = useState([])
  const { eventTypes: eventTypeFilter } = useFilters()
  const [eventsWithComments, setEventsWithComments] = useState([])

  useEffect(() => {
    setEventsToBeAdded([])
    setEventsWithComments([])
    setSelectedId(driverId)
  }, [driverId])

  useEffect(() => {
    if (events) {
      setData(events)
    }
  }, [events])

  useEffect(() => {
    setSelectedObject(null)
    if (creatingLesson) {
      populateEventsCommentsArray({
        eventsToBeAdded,
        setEventsWithComments,
        eventsWithComments,
        setCreatingLesson
      })
    }
  }, [creatingLesson])

  return (
    <FlexBox
      direction='column'
      style={{ width: '100%', height: '100%', padding: Metrics.base * 2 }}
    >
      {!creatingLesson ? (
        <EventTable
          loading={loading}
          Colors={Colors}
          dataState={dataState}
          setData={setData}
          selectedObject={selectedObject}
          setSelectedObject={setSelectedObject}
          setCreatingLesson={setCreatingLesson}
          checkboxArray={eventsToBeAdded}
          setCheckboxArray={setEventsToBeAdded}
          setEventsWithComments={setEventsWithComments}
          eventsWithComments={eventsWithComments}
        />
      ) : (
        <CreateLesson
          driverId={driverId}
          setCreatingLesson={setCreatingLesson}
          eventsToBeAdded={eventsToBeAdded}
          setEventsToBeAdded={setEventsToBeAdded}
          Colors={Colors}
          eventsWithComments={eventsWithComments}
          setEventsWithComments={setEventsWithComments}
          dataState={dataState}
          setData={setData}
        />
      )}
    </FlexBox>
  )
}
export default EventQueue
