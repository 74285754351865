import React from 'react'
import { Text } from '../'
import { exportExcelData } from '../../Helpers/Functions'
import { useColors } from '../../Themes'
import { Styles } from './Styles'

const ExportExcelButton = ({ data, label = 'Export Excel' }) => {
  const Colors = useColors()
  return (
    <div
      style={{
        ...Styles.exportButton,
        border: `1px solid ${Colors.background}`
      }}
      onClick={() => exportExcelData(data)}
    >
      <Text>{label}</Text>
    </div>
  )
}

export default ExportExcelButton
