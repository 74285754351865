import React from 'react'
import { FlexBox, Subsection } from '../../../../Components'
import { Metrics } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import CategorySummary from './CategorySummary'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import { useSmoothDrivingScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useSmoothDrivingScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'

const SmoothDriving = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts
}) => {
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useSmoothDrivingScoreHistory({ driverId })

  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ driverId, scoreHistoryData, category: 'SPEEDING' })

  return (
    <FlexBox direction='column'>
      <CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        showEventTypes={[
          'HARSH_BRAKING',
          'HARSH_CORNERING',
          'HARSH_ACCELERATION'
        ]}
        eventsCounts={
          eventTypeCounts
            ? {
                HARSH_BRAKING: eventTypeCounts.HARSH_BRAKING,
                HARSH_CORNERING: eventTypeCounts.HARSH_CORNERING,
                HARSH_ACCELERATION: eventTypeCounts.HARSH_ACCELERATION
              }
            : {}
        }
        totalEvents={events && events.length > 0 ? events.length : 0}
      />
      <ScoreVsTimeChart
        data={scoreHistory}
        currentScore={score}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot events={events} loading={eventsLoading} />
      <EventsVsTimeChart
        events={events}
        loading={eventsLoading}
        groupBy='type'
        keys={['HARSH_BRAKING', 'HARSH_ACCELERATION', 'HARSH_CORNERING']}
      />
    </FlexBox>
  )
}

export default SmoothDriving
