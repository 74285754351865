import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import { FlexBox, InfoBadge, Text } from '../../../../Components'
import { Metrics, useColors } from '../../../../Themes'
import TrendIndicator from '../../TrendIndicator'
import { checkVal } from '../../../../Helpers/Functions'
import NoneSelected from '../../../../Components/Custom/NoneSelected'
import {
  inTripTypes,
  tripTotalTypes,
  otherTypes
} from '../../../../Graphql/GraphQLClient'

const EventsPieChart = ({ eventsCounts }) => {
  const Colors = useColors()
  return (
    <div
      style={{
        margin: Metrics.base * 3,
        width: 110,
        height: 110
      }}
    >
      <ResponsivePie
        innerRadius={0.85}
        padAngle={0}
        cornerRadius={0}
        // startAngle={-180}
        // endAngle={180}
        enableArcLinkLabels={false}
        enableSlicesLabels={false}
        enableArcLabels={false}
        isInteractive
        data={[
          {
            id: 'speeding',
            label: 'Speeding',
            value: eventsCounts.SPEEDING
          },
          {
            id: 'harshBraking',
            label: 'Harsh Braking',
            value: eventsCounts.HARSH_BRAKING
          },
          {
            id: 'harshCornering',
            label: 'Harsh Cornering',
            value: eventsCounts.HARSH_CORNERING
          },
          {
            id: 'harshAccelerating',
            label: 'Harsh Accelerating',
            value: eventsCounts.HARSH_ACCELERATION
          },

          {
            id: 'percentSpeeding',
            label: 'Percent Speeding',
            value: eventsCounts.PERCENT_SPEEDING
          },
          {
            id: 'tripDistance',
            label: 'Trip Distance',
            value: eventsCounts.TRIP_DISTANCE
          },
          {
            id: 'travelTime',
            label: 'Travel Time',
            value: eventsCounts.TRAVEL_TIME
          },
          {
            id: 'drivingTime',
            label: 'Driving Time',
            value: eventsCounts.DRIVING_TIME
          }
        ]}
        margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
        // colors={{ scheme: 'blue_green' }}
        colors={[
          Colors.SPEEDING,
          Colors.HARSH_BRAKING,
          Colors.HARSH_CORNERING,
          Colors.HARSH_ACCELERATION,

          Colors.PERCENT_SPEEDING,
          Colors.TRIP_DISTANCE,
          Colors.TRAVEL_TIME,
          Colors.DRIVING_TIME
        ]}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        enableRadialLabels={false}
      />
    </div>
  )
}

const EventType = ({ type, Colors, showEventTypes, count, name }) => {
  const showType = showEventTypes.includes(type)
  return (
    <FlexBox style={{ marginBottom: Metrics.base }}>
      <div
        style={{
          width: 10,
          height: 10,
          border: !showType ? `1px solid ${Colors.dividerColor}` : 'none',
          backgroundColor: showType ? Colors[type] : Colors.background,
          marginRight: Metrics.base * 2
        }}
      />
      <Text font='caption' style={{ maxWidth: 120 }}>
        <b>{checkVal(count) ? count : 0}</b>{' '}
        <span style={{ color: Colors.textSecondary }}>
          {name && name.toUpperCase()}
        </span>
      </Text>
    </FlexBox>
  )
}

export const getTotalEvents = ({ showEventTypes, eventsCounts }) => {
  console.log('eventsCounts', eventsCounts)
  let total = 0
  for (var i = 0; i < showEventTypes.length; i++) {
    const eventCount = eventsCounts[showEventTypes[i]]
    if (checkVal(eventCount) && !isNaN(eventCount)) {
      total += eventCount
    }
  }
  return total
}

const CategorySummary = ({
  eventsCounts,
  previousWeekChange,
  previousMonthChange,
  previousYearChange,
  showEventTypes = [...inTripTypes, ...tripTotalTypes, ...otherTypes]
}) => {
  const Colors = useColors()
  const totalEvents = getTotalEvents({ showEventTypes, eventsCounts })
  return (
    <FlexBox style={{ width: '100%' }}>
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          paddingLeft: Metrics.base * 4,
          flex: 1
        }}
      >
        <TrendIndicator
          size='small'
          style={{ marginBottom: Metrics.base * 2 }}
          direction='column'
          value={previousWeekChange ? previousWeekChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Week'}
        />
        <TrendIndicator
          size='small'
          style={{ marginBottom: Metrics.base * 2 }}
          direction='column'
          value={previousMonthChange ? previousMonthChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Month'}
        />
        <TrendIndicator
          size='small'
          direction='column'
          value={previousYearChange ? previousYearChange.toFixed(2) : 0}
          suffix='pts'
          label={'Previous Year'}
        />
      </FlexBox>
      {checkVal(totalEvents) && totalEvents > 0 ? (
        <>
          <FlexBox
            style={{
              flex: 2
              // justifyContent: 'flex-start'
              // paddingLeft: Metrics.base * 4
            }}
          >
            <InfoBadge
              size='medium'
              value={totalEvents}
              label={'Events Recorded'}
            />
            <EventsPieChart eventsCounts={eventsCounts} />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 2
            }}
          >
            <EventType
              type='SPEEDING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.SPEEDING}
              name='Speeding'
            />
            <EventType
              type='HARSH_BRAKING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.HARSH_BRAKING}
              name='Harsh Braking'
            />
            <EventType
              type='HARSH_CORNERING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.HARSH_CORNERING}
              name='Harsh Cornering'
            />
            <EventType
              type='HARSH_ACCELERATION'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.HARSH_ACCELERATION}
              name='Harsh Acceleration'
            />
            <EventType
              type='PHONE_MOTION'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.PHONE_MOTION}
              name='Phone Use'
            />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 2
            }}
          >
            <EventType
              type='PERCENT_SPEEDING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.PERCENT_SPEEDING}
              name='Percent Speeding'
            />
            <EventType
              type='TRIP_DISTANCE'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.TRIP_DISTANCE}
              name='Trip Distance'
            />
            <EventType
              type='TRAVEL_TIME'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.TRAVEL_TIME}
              name='Travel Time'
            />
            <EventType
              type='DRIVING_TIME'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.DRIVING_TIME}
              name='Driving Time'
            />
            <EventType
              type='NIGHT_DRIVING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.NIGHT_DRIVING}
              name='Night Driving'
            />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              flex: 1,
              alignItems: 'flex-start',
              paddingLeft: Metrics.base * 2
            }}
          >
            <EventType
              type='VEHICLE_CRASH'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.VEHICLE_CRASH}
              name='Crash'
            />
            <EventType
              type='VEHICLE_INCIDENT'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.VEHICLE_INCIDENT}
              name='Incident'
            />
            <EventType
              type='CONTINUOUS_DRIVING'
              Colors={Colors}
              showEventTypes={showEventTypes}
              count={eventsCounts.CONTINUOUS_DRIVING}
              name='Continuous Driving'
            />
          </FlexBox>
        </>
      ) : (
        <NoneSelected
          displayText='No Events'
          helperText='0 Events of this category in last 100 trips'
        />
      )}
    </FlexBox>
  )
}

export default CategorySummary
