import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { inspectionDataTransform, inspectionFragment } from './useInspection'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'

export const GET_INSPECTIONS_BY_ASSET_ID = gql`
  query inspectionsByAssetId(
    $assetId: String!
    $reportedMaintenanceStatus: [String]
    $inspectionTypes: [String]
    $startDate: Float
    $endDate: Float
    $skip: Int
    $limit: Int
  ) {
    inspectionsByAssetId(
      assetId: $assetId
      reportedMaintenanceStatus: $reportedMaintenanceStatus
      inspectionTypes: $inspectionTypes
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
    ) {
      _id
    }
  }
`

const dataTransform = ({ data }) => {
  return data.map((a) => inspectionDataTransform(a))
}

const getInspectionVariables = ({
  assetId,
  reportedMaintenanceStatus,
  inspectionTypes,
  startDate,
  endDate
}) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (checkVal(assetId)) {
    variables.assetId = assetId
  }
  if (checkVal(reportedMaintenanceStatus)) {
    variables.reportedMaintenanceStatus = reportedMaintenanceStatus
  }

  if (checkVal(inspectionTypes)) {
    variables.inspectionTypes = inspectionTypes
  }
  if (checkVal(startDate)) {
    variables.startDate = startDate
  }
  if (checkVal(endDate)) {
    variables.endDate = endDate
  }
  return variables
}

export const useInspectionsByAssetId = (inspectionVariables) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_INSPECTIONS_BY_ASSET_ID,
    {
      variables: getInspectionVariables(inspectionVariables),
      queryName: 'inspectionsByAssetId',
      skip: !inspectionVariables.assetId,
      errorMessage: 'Failed to fetch Inspections',
      dataTransform: (data) => dataTransform({ data })
    }
  )
  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return { data, loading }
}
