import React, { useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import {
  FlexBox,
  Form,
  MaterialInput,
  Subsection,
  Text
} from '../../Components'
import Button from '../../Components/Button'
import LargeDivider from '../../Components/LargeDivider'
import { Heading, InfoMessage, Subheading } from '../../Components/Typography'
import { useChangeAllVideoFetchSensitivity } from '../../Graphql/Mutations/useChangeAllVideoFetchSensitivity'
import { useUser } from '../../Graphql/Queries/useUser'
import { checkVal } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'

const thresholdOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' }
]

const getFormValues = (edit) => [
  {
    name: 'isActive',
    label: 'Enable Video Clip Saving',
    initialValue: true,
    type: 'checkbox',
    disabled: !edit
  },
  {
    name: 'SPEEDING',
    label: 'Speeding score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_BRAKING',
    label: 'Harsh Braking score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_CORNERING',
    label: 'Harsh Cornering score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_ACCELERATION',
    label: 'Harsh Acceleration score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  }
]

const Thresholds = () => {
  const Colors = useColors()
  const [edit, setEdit] = useState(false)
  const resetRef = useRef()
  const [changeSensitivity, { changeSensitivityLoading }] =
    useChangeAllVideoFetchSensitivity()
  const [isSubmitting, setSubmitting] = useState(false)
  const [orgId, setOrgId] = useState(null)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  useEffect(() => {
    if (orgOptions && orgOptions.length > 0) {
      setOrgId(orgOptions[0]._id)
    }
  }, [orgOptions])
  return (
    <FlexBox fillSpace style={{ width: '100%' }}>
      <Subsection style={{ minWidth: 500 }}>
        <Heading marginTop={4} marginBottom={2}>
          Video Recording Thresholds
        </Heading>
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            alignItems: 'flex-start',
            paddingLeft: Metrics.base * 4,
            paddingRight: Metrics.base * 4
          }}
        >
          {user && user.userType === 'ADMIN' ? (
            <MaterialInput
              type='select'
              label='Organisation'
              showClearButton
              styles={{
                outerContainer: {
                  marginTop: Metrics.base * 2,
                  width: 150
                  // marginLeft: Metrics.base * 4,
                  // marginTop: Metrics.base
                }
              }}
              fetching={userLoading}
              value={orgId}
              selectValues={orgOptions.map((org) => org._id)}
              selectDisplayValues={orgOptions.map((org) => org.name)}
              handleChange={(value) => setOrgId(value)}
            />
          ) : (
            ''
          )}
          <Subheading marginTop={4} marginBottom={2}>
            Set event score thresholds for all devices
          </Subheading>
          <Text font='caption'>
            This will update the event score thresholds that trigger downloading
            and saving of video clips from live streaming devices.
          </Text>
          <div style={{ height: 5, width: 100 }}></div>
          <InfoMessage>
            Warning, this will set recording thresholds for all devices,
            overriding individual device changes that may have been made.
          </InfoMessage>
          <Form
            hideFormBox
            style={{ width: '100%', marginBottom: 0, boxShadow: 'none' }}
            // editable={edit}
            resetRef={resetRef}
            cancelButton
            handleCancel={() => {
              setEdit(false)
              resetRef && resetRef.current && resetRef.current.click()
            }}
            disabled={!checkVal(orgId)}
            onSubmit={(data) => {
              setSubmitting(true)
              changeSensitivity({
                variables: {
                  orgId: orgId,
                  isActive: data.isActive,
                  thresholds:
                    checkVal(data.SPEEDING) &&
                    checkVal(data.HARSH_ACCELERATION) &&
                    checkVal(data.HARSH_CORNERING) &&
                    checkVal(data.HARSH_BRAKING)
                      ? {
                          SPEEDING: Number(data.SPEEDING),
                          HARSH_ACCELERATION: Number(data.HARSH_ACCELERATION),
                          HARSH_CORNERING: Number(data.HARSH_CORNERING),
                          HARSH_BRAKING: Number(data.HARSH_BRAKING)
                        }
                      : null
                },
                onCompleted: () => {
                  setEdit(false)
                  setSubmitting(false)
                  window.location.reload()
                },
                onError: () => {
                  setSubmitting(false)
                }
              })
            }}
            isSubmitting={isSubmitting}
            setSubmitting={setSubmitting}
            submitButtonTitle={'submit'}
            data={getFormValues(edit)}
            hideSubmit={!edit}
          >
            {!edit && (
              <Button
                primary
                id='logout-button'
                style={{
                  marginTop: Metrics.base * 2
                }}
                onClick={() => setEdit(true)}
                textColor='white'
                title='Set thresholds'
                background={Colors.primary}
              />
            )}
          </Form>
        </FlexBox>
      </Subsection>
    </FlexBox>
  )
}

export default Thresholds
