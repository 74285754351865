import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { abbreviateNumber } from '../../../Helpers/Functions'
import { avgAndCoordinatesFragment } from '../Asset/useTotalDistances'

export const GET_TOTAL_TIME_DRIVEN_DRIVERS = gql`
  query timeDrivenDrivers(
    $period: String
    $maxTicks: Int
    $orgIdsFilter: [String] #$assetGroupId: String
  ) {
    timeDrivenDrivers(
      period: $period
      maxTicks: $maxTicks
      orgIdsFilter: $orgIdsFilter #assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

const dataTransform = ({ data }) => {
  return (
    data && {
      average: data.average,
      coordinates:
        data.coordinates &&
        data.coordinates.map((a) => ({ x: abbreviateNumber(a.x), y: a.y }))
    }
  )
}

export const useTimeDrivenDrivers = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_TOTAL_TIME_DRIVEN_DRIVERS,
    {
      variables: {
        period,
        maxTicks: 20,
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'timeDrivenDrivers',
      errorMessage: 'Failed to fetch Times',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
