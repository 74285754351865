import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useCoachingEventByEventId } from '../Coaching/useCoachingEventByEventId'
import { checkVal } from '../../../Helpers/Functions'

export const eventFragment = gql`
  fragment eventFragment on Event {
    _id
    startLong
    startLat
    endlong
    endLat
    startTime
    endTime
    values
    maxSpeed
    avSpeed
    duration
    maxSpeed
    category
    type
    typeType
    avSpeed
    durationSeconds
    totalDistance
    totalTravelTime
    totalTomTomTravelTime
    rawScore
    normScore
    tripId
    trip {
      _id
      startTime
      displayId
    }
    driver {
      _id
      displayId
    }
    asset {
      _id
      displayId
    }
    displayId
    createdAt
    diff
    threshold
    value
    rawXValues {
      timestamp
      value
    }
    rawYValues {
      timestamp
      value
    }
    rawZValues {
      timestamp
      value
    }
    violationTimestamp
    violationLat
    violationLong
    dayDrivingHours
    nightDrivingHours
    percentDayDriving
    percentNightDriving
  }
`

export const GET_EVENT = gql`
  query event($id: String!) {
    event(id: $id) {
      ...eventFragment
    }
  }
  ${eventFragment}
`

export const getDisplayText = (str) => {
  return str
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
}

export const eventDataTransform = ({ data }) => {
  if (data) {
    return {
      ...data,
      id: data._id ? data._id : null,
      name: data.type && getDisplayText(data.type),
      startlong: data.startLong ? data.startLong : null,
      startLat: data.startLat ? data.startLat : null,
      endlong: data.endLong ? data.endLong : null,
      endLat: data.endLat ? data.endLat : null,
      startDate: data.startTime ? new Date(parseInt(data.startTime)) : null,
      endDate: new Date(parseInt(data.endTime)),
      startTime: data.startTime
        ? new Date(parseInt(data.startTime)).toLocaleString('en-GB')
        : null,
      endTime: data.endTime
        ? new Date(parseInt(data.endTime)).toLocaleString('en-GB')
        : null,
      createdAtTime: data.createdAt
        ? new Date(parseInt(data.createdAt)).toLocaleString('en-GB')
        : null,
      values: data.values ? data.values : null,
      duration: data.duration ? data.duration : null,
      maxSpeed: data.maxSpeed ? data.maxSpeed : null,
      avSpeed: data.avSpeed ? data.avSpeed : null,
      durationSeconds: data.durationSeconds ? data.durationSeconds : null,
      score: data.score ? data.score : null,
      assetId: data.asset ? data.asset._id : null,
      driverId: data.driver ? data.driver._id : null,
      tripId: data.tripId ? data.tripId : null,
      trip: data.trip ? data.trip : null,
      type: data.type ? data.type : null,
      category: data.category ? data.category : null,
      totalDistance: data.totalDistance ? data.totalDistance : null,
      totalTravelTime: data.totalTravelTime ? data.totalTravelTime : null,
      totalTomTomTravelTime: data.totalTomTomTravelTime
        ? data.totalTomTomTravelTime
        : null,
      severityCategoryDisplayText:
        data.category && getDisplayText(data.category),
      severityCategory: data.category
    }
  }
}

export const useEvent = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_EVENT, {
    variables: { id },
    queryName: 'event',
    errorMessage: 'Failed to fetch Event',
    dataTransform: (data) => eventDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
