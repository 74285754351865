import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { checkVal } from '../../../Helpers/Functions'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'

export const topBottomDriversFragment = gql`
  fragment topBottomDriversFragment on TopBottomDrivers {
    _id
    driverId
    displayId
    firstName
    lastName
    dob
    driverSafetyScore100
    driverSafetyCategory
    safetyScore
    tripSafetyMean100
    speedingCategoryMean100
    smoothDrivingCategoryMean100
    restedFocusedCategoryMean100
    complianceCategoryMean100
    generalSafetyCategoryMean100
  }
`

export const GET_DRIVERS = gql`
  query topAndBottomDriverSafeties(
    $sortByOrder: String
    $driverSafetyCategory: String
    $orgIdsFilter: [String]
    $skip: Int
    $limit: Int
  ) {
    topAndBottomDriverSafeties(
      sortByOrder: $sortByOrder
      driverSafetyCategory: $driverSafetyCategory
      orgIdsFilter: $orgIdsFilter
      skip: $skip
      limit: $limit
    ) {
      count
      drivers {
        ...topBottomDriversFragment
      }
    }
  }
  ${topBottomDriversFragment}
`

export const driverDataTransform = ({ data }) => {
  return {
    ...data,
    name: `${data.firstName} ${data.lastName}`,
    safetyScore: checkVal(data.safetyScore)
      ? Number(data.safetyScore).toFixed(2)
      : 0,
    driverSafetyCategory: getScoreCategory(data.safetyScore)
  }
}

const dataTransform = ({ data }) => {
  return {
    count: data.count,
    data:
      data.drivers && data.drivers.map((a) => driverDataTransform({ data: a }))
  }
}

export const getVariables = ({
  groupId,
  orgIdsFilter,
  orderBy,
  safetyCategory,
  skip = 0,
  limit = 10
}) => {
  let variables = {
    skip,
    limit
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(orderBy)) {
    variables = { ...variables, sortByOrder: orderBy }
  }
  if (checkVal(safetyCategory)) {
    variables = { ...variables, driverSafetyCategory: safetyCategory }
  }
  return variables
}

export const useTopAndBottomDrivers = ({ orderBy, safetyCategory }) => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DRIVERS, {
    variables: getVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      orderBy,
      safetyCategory,
      groupId
    }),
    queryName: 'topAndBottomDriverSafeties',
    errorMessage: 'Failed to fetch Drivers',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.count)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
