import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import {
  FlexBox,
  Icon,
  InfoBadge,
  ProcessingSpinner,
  Subsection,
  Text
} from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import Status from '../../../Components/Status'
import {
  Display,
  Heading,
  Subheading,
  Title,
  Title2
} from '../../../Components/Typography'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import {
  checkVal,
  hhmmss,
  thousandsSeparator
} from '../../../Helpers/Functions'
import { Images, Metrics, useColors } from '../../../Themes'
import TrendIndicator from '../TrendIndicator'
import { useSafetyScoreTrends } from '../Driver/useSafetyScoreTrends'
import EventsVsTimeScatterPlot from '../Driver/Categories/EventsVsTimeScatterPlot'
import EventsVsTimeChart from '../Driver/Categories/EventsVsTimeChart'
import { useReactiveVar } from '@apollo/client'
import { selectedDriverId } from '../../../Graphql/GraphQLClient'
import LargeDivider from '../../../Components/LargeDivider'
import { getScoreCategory } from '../Asset/Breakdown'
import { DescriptionContent } from '../Trip/Breakdown'

export const getTotalEvents = (eventTypeCounts) => {
  const keys = Object.keys(eventTypeCounts)
  var count = 0
  for (var i = 0; i < keys.length; i++) {
    const val = eventTypeCounts[keys[i]]
    if (checkVal(val) && !isNaN(Number(val))) count += Number(val)
  }
  return count
}

const Education = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const driverId = useReactiveVar(selectedDriverId)

  const {
    data: driverSafetyData,
    educationScore,
    quizEvents,
    loading
  } = useDriverSafetyByDriverId(driverId)

  const quizScore = driverSafetyData && driverSafetyData.quizNormScore100
  const quizNumTotal =
    driverSafetyData && driverSafetyData.numberQuizAllTime
      ? driverSafetyData.numberQuizAllTime
      : 0
  const quizNumLastMonth =
    driverSafetyData && driverSafetyData.numberQuizLastMonth
      ? driverSafetyData.numberQuizLastMonth
      : 0

  const totalTrips = driverSafetyData ? driverSafetyData.totalTrips : 0
  const totalDist = driverSafetyData ? driverSafetyData.totalKilometers : 0
  const totalHoursDriven = driverSafetyData
    ? driverSafetyData.totalHoursDriven
    : 0
  const eventTypeCounts = driverSafetyData
    ? driverSafetyData.eventTypeCounts
    : 0

  const driverSafetyCategory = driverSafetyData
    ? driverSafetyData.driverSafetyCategory
    : 0

  const { previousMonthChange, previousYearChange } = useSafetyScoreTrends({
    driverId
  })

  return (
    <FlexBox
      fillSpace
      direction='column'
      justifyContent='flex-start'
      style={{
        width: mainContainerWidth - Metrics.base * 4
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        style={{
          width: '100%',
          // height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(driverId) ? (
          <>
            <FlexBox
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                paddingLeft: Metrics.base * 5,
                paddingRight: Metrics.base * 5,
                paddingBottom: Metrics.base * 2,
                borderBottom: `1px solid ${Colors.dividerColor}`
              }}
            >
              <FlexBox
                style={{
                  borderRadius: 8,
                  minWidth: 300,
                  minHeight: 90,
                  maxHeight: 300,
                  margin: Metrics.base * 2
                }}
              >
                <FlexBox
                  direction='column'
                  style={{
                    alignItems: 'flex-start',
                    padding: Metrics.base * 2,
                    marginRight: Metrics.base * 2
                  }}
                >
                  <Display
                    loading={loading}
                    loadingBarHeight={24}
                    loadingBarWidth={100}
                  >
                    {checkVal(educationScore)
                      ? `${Math.round(educationScore)}/100`
                      : '-'}
                  </Display>
                  <Subheading
                    loading={loading}
                    loadingBarHeight={14}
                    loadingBarWidth={150}
                    color={Colors.textSecondary}
                    marginTop={1}
                  >
                    Education Score
                  </Subheading>
                </FlexBox>
                <ScoreCircleChart
                  score={checkVal(educationScore) ? educationScore : null}
                  maxScore={100}
                  color={Colors[getScoreCategory(educationScore)]}
                  backgroundColor={Colors.secondary}
                  size={90}
                />
              </FlexBox>
              <DescriptionContent
                points={[
                  `This score indicates how well a driver is learning from his lessons. It is the average score of a driver in all of the educational quizzes that he completes. As a driver scores better in his quizzes his Education Score increases.`,
                  'This accounts for 33% of the Total Driver Safety Score.',
                  'Drivers who score low in the Education Category are not responding well to feedback and learning from their mistakes in quizzes. '
                ]}
              />
            </FlexBox>
            <FlexBox
              direction='row'
              style={{
                width: '100%',
                marginTop: Metrics.base * 2,
                marginBottom: Metrics.base * 4
              }}
            >
              <InfoBadge
                size='small'
                value={checkVal(quizScore) ? quizScore.toFixed(2) : null}
                getDisplayVal={(value) => `${value}/100`}
                label={'Mean Quiz Score'}
              />
              <InfoBadge
                size='small'
                value={quizNumTotal}
                getDisplayVal={(value) => `${value}`}
                label={'Total number of quizzes completed'}
              />
              <InfoBadge
                size='small'
                value={`${quizNumLastMonth} `}
                getDisplayVal={(value) => `${value}`}
                label={'Quizzes completed last month'}
              />
            </FlexBox>
            <LargeDivider />
            <EventsVsTimeScatterPlot
              events={quizEvents}
              title='Quiz Score Events vs Time'
            />
            {/*<EventsVsTimeChart
              events={quizEvents}
              groupBy='category'
              keys={['SEVERE', 'HEAVY', 'MODERATE', 'LIGHT', 'VERY_LIGHT']}
            />*/}
          </>
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select a driver from the list on the left'
          />
        )}
      </Subsection>
    </FlexBox>
  )
}

export default Education
