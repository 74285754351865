import React, { useState, useEffect } from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import { Display, Heading, Subheading, Title, Title2 } from '../Typography'
import { Status, Text, FlexBox, Button, MaterialInput, Subsection } from '../'
import { hhmmss, checkVal, formatDateTime } from '../../Helpers/Functions'
import { useCoachingEvent } from '../../Graphql/Queries/Coaching/useCoachingEvent'
import { ProcessingSpinner } from '../'
import { ModifiedPieChart } from './ModifiedPieChart'
import SubsectionDivider from './SubsectionDivider'
import { getDisplayText } from '../../Graphql/Queries/Event/useEvent'
import VideoPlayer from './VideoPlayer'
import SpeedingDetails, {
  SpeedingEventInfo
} from '../../Containers/EventInbox/SpeedingDetails'
import { HarshEventInfo } from '../../Containers/EventInbox/HarshEventDetails'
import { tripTotalTypes } from '../../Graphql/GraphQLClient'
import { TripTotalsEventInfo } from '../../Containers/EventInbox/EventDetailsTripTotals'
import TripReplayStreetView from './TripReplayStreetView'
import { useVideoClipByEventId } from '../../Graphql/Queries/useVideoClipByEventId'
import EventIdStreetView from './EventIdStreetView'

const EventDetails = ({
  coachingEvent,
  Colors,
  setEventsToBeAdded,
  eventsToBeAdded,
  eventsWithComments,
  setEventsWithComments,
  fromCreateLesson
}) => {
  const { data: videoClips } = useVideoClipByEventId(coachingEvent.event._id)
  return (
    <>
      {checkVal(coachingEvent) && coachingEvent.event && (
        <FlexBox
          style={{
            width: '100%',
            minHeight: Metrics.base * 20,
            // justifyContent: 'space-around',
            padding: Metrics.base * 2,
            borderLeft: `5px solid ${Colors[coachingEvent.event.type]}`,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            backgroundColor: Colors.subsectionBackground,
            alignItems: 'stretch'
          }}
        >
          {videoClips && videoClips.length > 0 ? (
            <FlexBox style={{ flex: 1 }}>
              <VideoPlayer eventId={coachingEvent.event._id} size={450} />
            </FlexBox>
          ) : coachingEvent.event.type === 'SPEEDING' ||
            coachingEvent.event.type === 'HARSH_BRAKING' ||
            coachingEvent.event.type === 'HARSH_CORNERING' ||
            coachingEvent.event.type === 'HARSH_ACCELERATION' ? (
            <FlexBox style={{ flex: 1 }}>
              <EventIdStreetView eventId={coachingEvent.event._id} />
            </FlexBox>
          ) : (
            ''
          )}
          <FlexBox
            direction='column'
            fillSpace
            style={{
              flex: 1,
              borderLeft: `1px solid ${Colors.dividerColor}`,
              justifyContent: 'flex-start'
            }}
          >
            <FlexBox
              direction='column'
              style={{
                width: '100%',
                borderBottom: `1px solid ${Colors.dividerColor}`
              }}
            >
              {coachingEvent.event.type === 'SPEEDING' ? (
                <SpeedingEventInfo event={coachingEvent.event} />
              ) : coachingEvent.event.type === 'HARSH_BRAKING' ||
                coachingEvent.event.type === 'HARSH_CORNERING' ||
                coachingEvent.event.type === 'HARSH_ACCELERATION' ? (
                <HarshEventInfo event={coachingEvent.event} />
              ) : tripTotalTypes.includes(coachingEvent.event.type) ? (
                <TripTotalsEventInfo event={coachingEvent.event} />
              ) : (
                ''
              )}
            </FlexBox>
          </FlexBox>
          {fromCreateLesson && (
            <Button
              title='Remove'
              condensed
              onClick={() => {
                if (eventsToBeAdded.includes(coachingEvent._id)) {
                  eventsToBeAdded.splice(
                    eventsToBeAdded.indexOf(coachingEvent._id),
                    1
                  )
                  setEventsToBeAdded([...eventsToBeAdded])
                  let index = eventsWithComments.findIndex(
                    (c) => c.coachingEventId === coachingEvent._id
                  )
                  eventsWithComments.splice(index, 1)
                  setEventsWithComments([...eventsWithComments])
                }
              }}
              textColor='white'
              background='red'
              style={{ marginRight: Metrics.base * 2 }}
            />
          )}
        </FlexBox>
      )}
    </>
  )
}

const getCommentValue = ({ eventsWithComments, coachingEvent }) => {
  let comment = ''
  let objIndex = eventsWithComments.findIndex(
    (obj) => obj.coachingEventId === coachingEvent._id
  )
  if (objIndex > -1) {
    comment = eventsWithComments[objIndex].comment
  }
  return comment
}

const EventComment = ({
  eventsWithComments,
  coachingEvent,
  setEventsWithComments,
  fromCreateLesson
}) => {
  return (
    <FlexBox
      style={{
        width: '70%',
        marginBottom: Metrics.base * 6
      }}
    >
      {checkVal(eventsWithComments) &&
        checkVal(coachingEvent) &&
        coachingEvent.event && (
          <MaterialInput
            type='textarea'
            name='eventComment'
            value={
              eventsWithComments &&
              coachingEvent &&
              eventsWithComments.length > 0
                ? getCommentValue({ eventsWithComments, coachingEvent })
                : ''
            }
            label={`Comments:`}
            handleChange={(e) => {
              //Find index of specific object using findIndex method.
              let objIndex = eventsWithComments.findIndex(
                (obj) => obj.coachingEventId === coachingEvent._id
              )
              eventsWithComments[objIndex].comment = e
              setEventsWithComments([...eventsWithComments])
            }}
          />
        )}
    </FlexBox>
  )
}

const EventToCoachingCard = ({
  eventId,
  lesson,
  setEventsToBeAdded,
  eventsToBeAdded,
  eventsWithComments,
  setEventsWithComments,
  fromCreateLesson
}) => {
  const Colors = useColors()
  const { data: coachingEvent, loading: eventLoading } =
    useCoachingEvent(eventId)

  return (
    <>
      {!eventLoading ? (
        <FlexBox direction='column' style={{ width: '100%' }}>
          {checkVal(coachingEvent) && coachingEvent.event && (
            <EventDetails
              coachingEvent={coachingEvent}
              Colors={Colors}
              eventsToBeAdded={eventsToBeAdded}
              setEventsToBeAdded={setEventsToBeAdded}
              eventsWithComments={eventsWithComments}
              setEventsWithComments={setEventsWithComments}
              fromCreateLesson={fromCreateLesson}
            />
          )}
          {checkVal(coachingEvent) &&
            coachingEvent.event &&
            fromCreateLesson && (
              <EventComment
                eventsWithComments={eventsWithComments}
                coachingEvent={coachingEvent}
                setEventsWithComments={setEventsWithComments}
              />
            )}
          {!fromCreateLesson && checkVal(lesson) && coachingEvent.event && (
            <FlexBox style={{ width: '70%', marginBottom: Metrics.base * 6 }}>
              <MaterialInput
                type='textarea'
                name='eventComment'
                value={lesson.comment ? lesson.comment : '-'}
                label={`Comments:`}
                disabled={true}
              />
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <FlexBox style={{ width: '100%', minHeight: Metrics.base * 20 }}>
          <ProcessingSpinner size={36} color={Colors.primary} />
        </FlexBox>
      )}
    </>
  )
}
export default EventToCoachingCard
