import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'

const avgAndCoordinatesFragment = gql`
  fragment avgAndCoordinatesFragment on AverageAndCoordinatesEvents {
    average
    coordinates {
      x
      y
    }
  }
`

export const GET_EVENT_TYPE_GRAPH = gql`
  query eventTypeGraph(
    $eventType: String
    $orgIdsFilter: [String] #$assetGroupId: String
  ) {
    eventTypeGraph(
      eventType: $eventType
      orgIdsFilter: $orgIdsFilter #assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

const dataTransform = ({ data }) => {
  return (
    data && {
      average: data.average,
      coordinates:
        data.coordinates &&
        data.coordinates.map((a) => ({ ...a, x: new Date(a.x) }))
    }
  )
}

export const useEventTypeGraph = ({ eventType }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_EVENT_TYPE_GRAPH,
    {
      variables: {
        eventType,
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'eventTypeGraph',
      errorMessage: 'Failed to fetch eventTypeGraph',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
