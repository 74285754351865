import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const serviceFragment = gql`
  fragment serviceFragment on VehicleService {
    _id
    createdAt
    asset {
      _id
      name
    }
    vehicleServicedDate
    serviceDocument {
      _id
      filename
      documentType
      url
    }
  }
`

export const GET_VEHICLE_SERVICE = gql`
  query vehicleService($id: String!) {
    vehicleService(id: $id) {
      ...serviceFragment
    }
  }
  ${serviceFragment}
`

export const serviceDataTransform = (data) => {
  if (data) {
    return {
      ...data
    }
  }
}

export const useService = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_VEHICLE_SERVICE,
    {
      variables: { id },
      queryName: 'vehicleService',
      errorMessage: 'Failed to fetch Service',
      dataTransform: (data) => serviceDataTransform({ data }),
      skip: !id
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
