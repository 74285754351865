import React from 'react'
import { useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Status from '../../../Status'
import Card from '../Cards/Card'
import TripCardTTList from './TripCardTTList'
import { useTripSafetyCategory } from '../../../../Graphql/Queries/Trip/useTripSafetyCategory'
import { selectedTripId } from '../../../../Graphql/GraphQLClient'
import { useTripCardData } from '../../../../Graphql/Queries/Trip/useTripCardData'

const TripCard = ({ tripId, selected, navigateTo, onClick, borderBottom }) => {
  const Colors = useColors()
  const { data, loading: tripLoading } = useTripCardData(tripId)
  const { category, loading: categoryLoading } = useTripSafetyCategory(tripId)
  return (
    <Card
      id={tripId}
      data={data}
      selected={selected}
      cardHeight={135}
      typeName='trip'
      setSelectedId={selectedTripId}
      navigateTo={navigateTo}
      onClick={onClick}
      TooltipContent={({ setShowTooltip }) => (
        <TripCardTTList
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      borderBottom={borderBottom}
      loading={tripLoading}
      titleContent={
        <Title color={Colors.primary}>
          Trip {data && data.displayId ? data.displayId : ''}
        </Title>
      }
      bodyContent={
        <>
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 270
            }}
            marginBottom={1 / 2}
            marginTop={1}
          >
            {data.startAddress ? data.startAddress : 'Start Address Unknown'}
          </Text>
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: 270
            }}
            marginBottom={1}
          >
            {data.endAddress ? data.endAddress : 'End Address Unknown'}
          </Text>
        </>
      }
      topRightContent={
        <Status
          status={category ? category : 'no safety score'}
          getStatusColors={({ status }) =>
            Colors[status] ? Colors[status] : Colors.textSecondary
          }
        />
      }
      topRightLoading={categoryLoading}
      // bottomRightContent={}
      bottomRightLoading={tripLoading}
      bottomLeftContent={
        <Text font='caption' color={Colors.secondaryOpacity3}>
          Start Date: {data.startTimeDisplay}
        </Text>
      }
    />
  )
}

export default TripCard
