import { useEffect, useState } from 'react'
import { useQuery } from '../../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_DRIVER_SCORE_AT_DATE = gql`
  query driverScoreAtDate(
    $driverId: String
    $endDate: Float
    $drivingCategoryFilter: String
  ) {
    driverScoreAtDate(
      driverId: $driverId
      endDate: $endDate
      drivingCategoryFilter: $drivingCategoryFilter
    )
  }
`

const dataTransform = ({ data }) => {
  return data
}

const getVariables = ({ driverId, date, category }) => {
  let variables = {
    driverId,
    endDate: date,
    drivingCategoryFilter: category
  }
  return variables
}

export const useDriverScoreAtDate = ({ driverId, date, category }) => {
  const [data, setData] = useState(null)

  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DRIVER_SCORE_AT_DATE,
    {
      variables: getVariables({ driverId, date, category }),
      skip: !driverId || !date || !category,
      queryName: 'driverScoreAtDate',
      errorMessage: 'Failed to fetch Driver Score',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return {
    data,
    loading
  }
}
