import { useState, useEffect } from 'react'
import Akima from 'akima-interpolator'
import { checkVal } from '../../Helpers/Functions'

var akima = new Akima()

export const getAkimaSplineFunctions = (locations) => {
  const lngs = []
  const lats = []
  const headings = []
  const speeds = []
  const timeStamps = []
  const locationsSorted = locations.sort((a, b) => a.timestamp - b.timestamp)
  for (var i = 0; i < locationsSorted.length; i++) {
    timeStamps.push(locationsSorted[i].timestamp)
    lngs.push(locationsSorted[i].longitude)
    lats.push(locationsSorted[i].latitude)
    headings.push(locationsSorted[i].heading)
    speeds.push(locationsSorted[i].speed)
  }
  var lng = akima.createInterpolator(timeStamps, lngs)
  var lat = akima.createInterpolator(timeStamps, lats)
  var heading = akima.createInterpolator(timeStamps, headings)
  var speed = akima.createInterpolator(timeStamps, speeds)

  return { lng, lat, heading, speed }
}

const getLimit = (location) => {
  return location && location.locationCache && location.locationCache.speedLimit
}

const getSpeedLimitAtTime = (locations, time) => {
  var index = -1
  var smallestDiff = 10000
  for (var i = 0; i < locations.length; i++) {
    const timeDiff = Math.abs(locations[i].timestamp - time)
    if (timeDiff < smallestDiff) {
      smallestDiff = timeDiff
      index = i
    }
  }
  if (index === -1) {
    const mostRecent = locations[locations.length - 1]
    return mostRecent && getLimit(mostRecent)
  }
  return locations[index] && getLimit(locations[index])
}

let position, heading, speed, speedLimit, signalDelay, animationTimeout
const animationFrame = ({
  time,
  timeStep,
  stopTime,
  timeDelay,
  akimaFunctions,
  locations,
  callback
}) => {
  animationTimeout = setTimeout(
    () =>
      animationFrame({
        time: new Date().getTime() - timeDelay,
        timeStep,
        stopTime,
        timeDelay,
        akimaFunctions,
        locations,
        callback
      }),
    timeStep
  )
  signalDelay = time + timeDelay - stopTime
  if (time < stopTime) {
    if (akimaFunctions) {
      position = {
        lng: akimaFunctions.lng(time),
        lat: akimaFunctions.lat(time)
      }
      heading = Math.max(Math.min(akimaFunctions.heading(time), 360), 0)
      speed = Math.max(akimaFunctions.speed(time), 0)
      speedLimit =
        locations && locations.length > 0
          ? getSpeedLimitAtTime(locations, time)
          : null
    }
  } else {
    if (locations && locations.length > 0) {
      const mostRecent = locations[locations.length - 1]
      position = { lng: mostRecent.longitude, lat: mostRecent.latitude }
      heading = mostRecent.heading
      speed = mostRecent.speed
      speedLimit = getLimit(mostRecent)
    }
  }
  callback({
    position,
    heading,
    speed,
    speedLimit,
    time,
    stopTime,
    signalDelay
  })
}

const useAkimaAnimation = ({
  locations,
  timeStep = 1000,
  timeDelay = 20000,
  deviceId,
  mapUserInteraction,
  callback
}) => {
  const [isActive, setIsActive] = useState()
  const [stopTime, setStopTime] = useState(null)
  const [akimaFunctions, setAkimaFunctions] = useState(null)

  useEffect(() => {
    if (locations && locations.length > 0) {
      setStopTime(locations[locations.length - 1].timestamp)
      if (locations.length > 4) {
        setAkimaFunctions(getAkimaSplineFunctions(locations))
      }
    }
  }, [locations])

  useEffect(() => {
    clearTimeout(animationTimeout)
    if (
      checkVal(deviceId) &&
      // !mapUserInteraction &&
      akimaFunctions &&
      stopTime
    ) {
      setIsActive(true)
      animationFrame({
        time: new Date().getTime() - timeDelay,
        stopTime,
        timeStep,
        timeDelay,
        akimaFunctions,
        locations,
        callback
      })
    } else {
      setIsActive(false)
    }
    return () => clearTimeout(animationTimeout)
  }, [deviceId, akimaFunctions, mapUserInteraction, stopTime])

  return { isActive }
}

export default useAkimaAnimation
