import React, { useEffect } from 'react'
import { NavButton, Subsection } from '../..'
import { Metrics, useColors } from '../../../Themes'
import { useMutation } from '@apollo/client'
import { UPDATE_SIDE_PANEL_EXPANDED } from '../../../Graphql/Mutations'
import FleetManagementSelectionPanel from './FleetManagementSelectionPanel'
import TripsList from './Trip/TripsList'
import { checkVal } from '../../../Helpers/Functions'
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import TabRoutes from '../../TabRoutes'
import AssetsList from './Asset/AssetsList'
import DriversList from './Driver/DriversList'
import LiveAssetCard from './Asset/LiveAssetCard'
import LiveDriverCard from './Driver/LiveDriverCard'
import DriverCard from './Driver/DriverCard'
import EventsListInTrip from './Event/InTrip/EventsListInTrip'
import EventsListTripsTotals from './Event/TripTotals/EventsListTripsTotals'
import LiveAssetsList from './Asset/LiveAssetsList'

export const shouldUpdateId = ({ updated, saved }) => {
  if (!checkVal(updated)) return false
  if (updated === saved) return false
  return true
}

export const getUrlId = ({ location, name }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  return urlSearchParams.get(`${name}-id`)
}

export const handleUrlIdChange = ({
  location,
  selectedId,
  IdName,
  setSelectedId
}) => {
  const urlId = getUrlId({ location, name: IdName })
  if (shouldUpdateId({ updated: urlId, saved: selectedId })) {
    setSelectedId(urlId)
  }
}

export const handleSelectedIdChange = ({
  selectedId,
  IdName,
  location,
  history,
  urlSearchParams
}) => {
  const urlId = getUrlId({ location, name: IdName })
  if (shouldUpdateId({ updated: selectedId, saved: urlId })) {
    urlSearchParams.set(`${IdName}-id`, selectedId)
    return history.push({
      search: urlSearchParams.toString()
    })
  }
}

const SelectionPanel = ({ expanded }) => {
  const Colors = useColors()
  return (
    <Subsection
      style={{
        height: Metrics.window.height, // - Metrics.navBarHeight,
        maxHeight: Metrics.window.height,
        flexDirection: 'column',
        position: 'fixed',
        width: expanded
          ? Metrics.selectionPanelExpandedWidth
          : Metrics.selectionPanelWidth,
        backgroundColor: Colors.subsectionBackground, // Colors.subsectionBackgroundOpacity3,
        borderLeft: 'none',
        borderRadius: 0,
        borderRight: `1px solid ${Colors.dividerColor}`,
        zIndex: 1,
        boxShadow: 'none'
      }}
    >
      <Switch>
        <Route path='/live-monitoring'>
          {/*<TabRoutes
            tabsData={[
              { id: 'assets', name: 'Assets' },
              { id: 'drivers', name: 'Drivers' }
            ]}
          >
            <AssetsList CardComponent={LiveAssetCard} />
            <DriversList CardComponent={LiveDriverCard} />
          </TabRoutes>*/}
          <LiveAssetsList />
        </Route>
        <Route path='/safety-score'>
          <TabRoutes
            tabsData={[
              { id: 'drivers', name: 'Drivers' },
              { id: 'trips', name: 'Trips' },
              { id: 'assets', name: 'Assets' }
            ]}
          >
            <DriversList />
            <TripsList onlyScoredTrips={true} />
            <AssetsList />
          </TabRoutes>
        </Route>
        <Route path='/event-inbox'>
          <TabRoutes
            tabsData={[
              { id: 'in-trip', name: 'In Trip' },
              { id: 'trip-totals', name: 'Trip Totals' }
              // { id: 'other', name: 'Other' }
            ]}
          >
            <EventsListInTrip tabId='in-trip' />
            <EventsListTripsTotals tabId='trip-totals' />
            {/*<EventsListOther tabId='other' />*/}
          </TabRoutes>
        </Route>
        <Route path='/coaching'>
          <DriversList cardComponent={DriverCard} />
        </Route>
        <Route path='/trip-history'>
          <TripsList />
        </Route>
        <Route path='/fleet-management'>
          <FleetManagementSelectionPanel />
        </Route>
      </Switch>

      {/*<div style={{ width: '100%' }}>
        <NavButton
          iconName={expanded ? 'chevron_left' : 'chevron_right'}
          title={expanded ? 'collapse' : 'expand'}
          style={{
            backgroundColor: Colors.background,
            border: `1px solid ${Colors.dividerColor}`
          }}
          onClick={() => updateSidePanelExpanded()}
          expanded={false}
        />
        </div>*/}
    </Subsection>
  )
}

export default React.memo(SelectionPanel)
