import React, { useState } from 'react'
import {
  eventFilters,
  selectedAssetId,
  selectedDeviceId,
  tripFilters
} from '../../../../Graphql/GraphQLClient'
import { useColors } from '../../../../Themes'
import { ToolTipMenuContainer, TooltipMenuItem } from '../Cards/Card'
import { DriverLink } from '../Driver/DriverCardTTList'

export const LinkToDriver = ({
  data,
  text = 'Link to Driver',
  iconName = 'person',
  Colors,
  setShowLinkDriverModal
}) => {
  return (
    <TooltipMenuItem
      text={text}
      iconName={iconName}
      disabled={!data}
      Colors={Colors}
      onClick={() => {
        setShowLinkDriverModal(true)
      }}
    />
  )
}

export const AssetLink = ({
  assetId,
  text = 'Linked Asset',
  disabledTexted = 'No Linked Asset',
  iconName = 'directions_bus',
  Colors,
  navigateTo
}) => {
  return (
    <TooltipMenuItem
      text={text}
      iconName={iconName}
      disabled={!assetId}
      disabledTexted={disabledTexted}
      Colors={Colors}
      onClick={() => {
        selectedAssetId(assetId)
        navigateTo({
          pathname: '/fleet-management/assets',
          queryParams: [{ name: 'asset-id', id: assetId }]
        })
      }}
    />
  )
}

export const DeviceLink = ({
  deviceId,
  text = 'Linked Device',
  disabledTexted = 'No Linked Device',
  iconName = 'settings_input_antenna',
  Colors,
  navigateTo
}) => {
  return (
    <TooltipMenuItem
      text={text}
      disabledTexted={disabledTexted}
      iconName={iconName}
      disabled={!deviceId}
      Colors={Colors}
      onClick={() => {
        selectedDeviceId(deviceId)
        navigateTo({
          pathname: '/fleet-management/devices',
          queryParams: [{ name: 'device-id', id: deviceId }]
        })
      }}
    />
  )
}

const AssetCardTTList = ({
  pathname,
  data,
  navigateTo,
  setShowLinkDriverModal
}) => {
  const Colors = useColors()
  return (
    <ToolTipMenuContainer>
      <TooltipMenuItem
        text='Live Monitoring'
        iconName={'remove_red_eye'}
        Colors={Colors}
        onClick={() => {
          selectedAssetId(data._id)
          navigateTo({
            pathname: '/live-monitoring/assets',
            queryParams: [{ name: 'asset-id', id: data._id }]
          })
        }}
      />
      {pathname !== '/safety-score' && (
        <TooltipMenuItem
          text='Safety Report'
          iconName={'shield'}
          Colors={Colors}
          onClick={() => {
            selectedAssetId(data._id)
            navigateTo({
              pathname: '/safety-score/assets',
              queryParams: [{ name: 'asset-id', id: data._id }]
            })
          }}
        />
      )}
      <AssetLink
        assetId={data._id}
        text='Fleet Management'
        iconName='people'
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <DriverLink
        driverId={data.driverId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <DeviceLink
        deviceId={data.deviceId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <TooltipMenuItem
        text='Trips'
        iconName={'history'}
        Colors={Colors}
        onClick={() => {
          tripFilters({
            ...tripFilters(),
            filterByTypeName: 'asset',
            assetId: data._id
          })
          navigateTo({
            pathname: '/trip-history/more-filters'
          })
        }}
      />
      <TooltipMenuItem
        text='Events'
        iconName={'inbox'}
        Colors={Colors}
        onClick={() => {
          eventFilters({
            ...eventFilters(),
            filterByTypeName: 'asset',
            assetId: data._id
          })
          navigateTo({
            pathname: '/event-inbox/more-filters'
          })
        }}
      />
      <LinkToDriver
        data={data}
        Colors={Colors}
        setShowLinkDriverModal={setShowLinkDriverModal}
      />
    </ToolTipMenuContainer>
  )
}

export default AssetCardTTList
