export const Styles = ({ Colors, Metrics }) => {
  return {
    dataDisplayContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      // paddingTop: Metrics.base,
      paddingBottom: Metrics.base,
      marginBottom: Metrics.base,
      paddingLeft: Metrics.base * 3,
      paddingRight: Metrics.base * 3
    }
  }
}
