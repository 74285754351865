import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { abbreviateNumber, checkVal } from '../../../Helpers/Functions'
import { avgAndCoordinatesFragment } from '../Asset/useTotalDistances'

export const GET_DISTRIBUTION = gql`
  query assetSafetyScoreDistribution(
    $orgIdsFilter: [String]
    $assetGroupId: String
  ) {
    assetSafetyScoreDistribution(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

export const distributionDataTransform = ({ data }) => {
  const tempCoordinates = data && data.coordinates
  const output = []
  for (var i = 0; i < 100; i++) {
    for (var j = 0; j < tempCoordinates.length; j++) {
      if (tempCoordinates[j].x === i) output[i] = { ...tempCoordinates[j] }
    }
    if (!checkVal(output[i])) output[i] = { x: i, y: 0 }
  }
  return (
    data && {
      average: data.average,
      coordinates: output
    }
  )
}

export const useAssetSafetyScoreDistribution = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DISTRIBUTION,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'assetSafetyScoreDistribution',
      errorMessage: 'Failed to fetch assetSafetyScoreDistribution',
      dataTransform: (data) => distributionDataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
