import { useEffect, useState } from 'react'
import { useSubscription } from '../../Components/CustomHooks/useSubscription'
import { locationDataTransform, locationFragment } from '../Queries/useLocation'
import gql from 'graphql-tag'

export const GET_LOCATION_UPDATED = gql`
  subscription locationUpdated($internalDeviceIds: [String]) {
    locationUpdated(internalDeviceIds: $internalDeviceIds) {
      ...locationFragment
      locationCache {
        speedLimit
      }
      device {
        _id
        deviceId
        #asset {
        #_id
        #driver {
        #  _id
        #  firstName
        #  lastName
        #}
        #}
      }
    }
  }
  ${locationFragment}
`

const dataTransform = ({ data }) => {
  return locationDataTransform({ data })
}

export const useLocationUpdated = (internalDeviceIds) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useSubscription(
    GET_LOCATION_UPDATED,
    {
      variables: { internalDeviceIds },
      subscriptionName: 'locationUpdated',
      errorMessage: 'Failed to fetch Location',
      dataTransform: (data) => dataTransform({ data }),
      skip: !internalDeviceIds
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
