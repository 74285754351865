import React, { useState } from 'react'
import { FlexBox } from '../../../../Components'
import AreaChart from '../../../../Components/D3Charts/AreaChart'
import { useAppDimensions } from '../../../../Graphql/Queries/useAppDimensions'
import { Metrics, useColors } from '../../../../Themes'
import { getScoreCategory } from '../../Asset/Breakdown'

const getTempData = (num) => {
  const data = []
  const sixMonthsInMilisec = 1.5768e10
  const sixMonthsAgo = new Date().getTime() - sixMonthsInMilisec
  for (var i = 0; i < num; i++) {
    const date = sixMonthsAgo + Math.random() * sixMonthsInMilisec
    data.push({
      date: new Date(date),
      value:
        ((date - sixMonthsAgo) / sixMonthsInMilisec) *
          (Math.random() * 20 + 20) +
        30 +
        (Math.random() * 10 - 5)
    })
  }
  return data.sort((a, b) => a.date.getTime() - b.date.getTime())
}

const sixMonthsInMilisec = 1.5768e10

const ScoreVsTimeChart = ({ assetId, data, currentScore }) => {
  const { mainContainerWidth } = useAppDimensions()
  const [now] = useState(new Date())
  const [sixMonthsAgo] = useState(
    new Date(new Date().getTime() - sixMonthsInMilisec)
  )
  const Colors = useColors()
  const loading = false
  return (
    <FlexBox
      style={{
        marginLeft: Metrics.base * 2,
        marginTop: Metrics.base * 2,
        marginBottom: Metrics.base * 2
      }}
    >
      <AreaChart
        key={assetId}
        id={assetId}
        currentScore={currentScore}
        dependencyArr={[currentScore, assetId]}
        maxYValue={100}
        chartWidth={mainContainerWidth - Metrics.base * 8}
        chartHeight={250}
        strokeColor={Colors[getScoreCategory(currentScore)]}
        gradientColor1={Colors[`${getScoreCategory(currentScore)}Opacity5`]}
        col1={`${getScoreCategory(currentScore)}Opacity5`}
        col2={`${getScoreCategory(currentScore)}Opacity1`}
        gradientColor2={Colors[`${getScoreCategory(currentScore)}Opacity1`]}
        data={data ? data : []}
        startDate={sixMonthsAgo}
        endDate={now}
        loading={loading}
      />
    </FlexBox>
  )
}

export default ScoreVsTimeChart
