import React, { useState } from 'react'
import { Metrics, useColors, Colors } from '../../../Themes'
import { FlexBox, MaterialInput, Text } from '../..'

const inputOptions = [
  { value: 'day', label: 'Last Day' },
  { value: 'month', label: 'Last Month' },
  { value: 'year', label: 'Last Year' },
  { value: 'all', label: 'All Time' }
]

const GeneralMetricsCard = ({
  inputValue,
  setInputValue,
  title,
  children,
  style,
  hideTimeFilter
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        //position: 'relative',
        height: 340,
        minWidth: 300
        //...style
      }}
    >
      <FlexBox
        direction='row'
        style={{
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: Metrics.base * 4,
          paddingTop: Metrics.base * 2,
          paddingBottom: Metrics.base * 2
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            marginTop: Metrics.base * 2
          }}
        >
          {title}
        </Text>
        {!hideTimeFilter && (
          <FlexBox
            style={{
              minWidth: 150,
              paddingRight: Metrics.base * 4,
              marginTop: Metrics.base * 2
            }}
          >
            <MaterialInput
              styles={{ fontSize: 10 }}
              type='select'
              name='eventScoreGreaterThan'
              label={`Time Filter`}
              value={inputValue}
              selectValues={inputOptions.map((a) => a.value)}
              selectDisplayValues={inputOptions.map((a) => a.label)}
              handleChange={(value) => {
                setInputValue(value)
              }}
            />
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox style={{ width: '100%' }}>{children}</FlexBox>
    </FlexBox>
  )
}

GeneralMetricsCard.defaultProps = {
  color: Colors.secondary
}

export default GeneralMetricsCard
