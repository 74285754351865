import React from 'react'
import FlexBox from '../../FlexBox'
import { Metrics, useColors } from '../../../Themes'
import Icon from '../../Icon'
import { useDebounce } from '../../CustomHooks/useDebounce'
import { MaterialInput } from '../..'

const SearchBar = ({ title, inputString, setInputString, searchInputRef }) => {
  const Colors = useColors()
  const debouncedFunc = useDebounce({
    func: setInputString,
    milliseconds: 600,
    immediate: false
  })
  return (
    <FlexBox
      style={{
        width: '100%'
        // padding: Metrics.base * 2,
        // marginTop: Metrics.base * 2
      }}
    >
      <Icon
        name='search'
        color={Colors.primaryOpacity2}
        size='large'
        style={{ marginRight: Metrics.base }}
      />
      <MaterialInput
        name='search bar'
        key={`Search ${title}`}
        id='outlined-search'
        variant='outlined'
        label={`Search ${title}`}
        styles={{
          outerContainer: {
            width: '100%'
            // marginTop: -Metrics.base * 2
          }
        }}
        inputRef={searchInputRef}
        // value={inputString}
        defaultValue={inputString}
        handleChange={(value) => [debouncedFunc(value)]}
      />
    </FlexBox>
  )
}

export default SearchBar
