import { useEffect, useState } from 'react'
import { useDriverScoreAtDate } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useDriverScoreAtDate'
import { checkVal } from '../../../../Helpers/Functions'

const weekInMilliseconds = 6.048e8
const monthInMilliseconds = 2.628e9

export const getScoreHistory = ({ data, now, scoreSixMonthsAgo, id }) => {
  const sortedData = data
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .filter((a) => checkVal(a.score))
  const noScoreSixMonthAgo =
    !checkVal(scoreSixMonthsAgo) || scoreSixMonthsAgo === -1
  if (noScoreSixMonthAgo) {
    return [
      {
        date: new Date(now - monthInMilliseconds * 6),
        value: 50,
        id: 'noScoreSixMonthAgo1' + id
      },
      {
        date: new Date(data[0].date.getTime() - weekInMilliseconds / 2),
        value: 50,
        id: 'noScoreSixMonthAgo2' + id
      },
      ...sortedData,
      {
        date: new Date(now),
        value: data[data.length - 1].value,
        id: 'noScoreSixMonthAgo2' + id
      }
    ]
  }
  return sortedData
}

export const getChange = ({ latestScore, data }) => {
  return latestScore - (!checkVal(data) || data === -1 ? 50 : data)
}

export const useScoreHistory = ({ driverId, scoreHistoryData, category }) => {
  const [scoreHistory, setScoreHistory] = useState()
  const [previousWeekChange, setPreviousWeekChange] = useState()
  const [previousMonthChange, setPreviousMonthChange] = useState()
  const [previousYearChange, setPreviousYearChange] = useState()

  const [now] = useState(new Date().getTime())

  const { data: scoreSixMonthsAgo } = useDriverScoreAtDate({
    driverId,
    date: now - monthInMilliseconds * 6,
    category
  })
  const { data: scoreLastWeekData } = useDriverScoreAtDate({
    driverId,
    date: now - weekInMilliseconds,
    category
  })
  const { data: scoreLastMonthData } = useDriverScoreAtDate({
    driverId,
    date: now - monthInMilliseconds,
    category
  })
  const { data: scoreLastYearData } = useDriverScoreAtDate({
    driverId,
    date: now - monthInMilliseconds,
    category
  })

  const dataChangedCheck =
    scoreHistoryData && scoreHistoryData[0] && scoreHistoryData[0]._id
      ? scoreHistoryData[0]._id
      : false

  useEffect(() => {
    if (
      scoreHistoryData &&
      scoreHistoryData.length > 0 &&
      checkVal(scoreSixMonthsAgo)
    ) {
      setScoreHistory(
        getScoreHistory({
          data: scoreHistoryData,
          now,
          scoreSixMonthsAgo,
          id: dataChangedCheck
        })
      )
    }
  }, [scoreHistoryData, dataChangedCheck, scoreSixMonthsAgo, now, driverId])

  useEffect(() => {
    if (scoreHistoryData && scoreHistoryData.length > 0) {
      const latestScore = scoreHistoryData[scoreHistoryData.length - 1].value
      setPreviousWeekChange(getChange({ latestScore, data: scoreLastWeekData }))
      setPreviousMonthChange(
        getChange({ latestScore, data: scoreLastMonthData })
      )
      setPreviousYearChange(getChange({ latestScore, data: scoreLastYearData }))
    }
  }, [
    scoreHistoryData,
    dataChangedCheck,
    scoreLastWeekData,
    scoreLastMonthData,
    scoreLastYearData
  ])

  return {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  }
}
