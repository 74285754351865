import React from 'react'
import { FlexBox } from '../../Components'
import {
  Display,
  Heading,
  Subheading,
  Title
} from '../../Components/Typography'
import { checkVal, thousandsSeparator } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'
import Subsection from '../Subsection'
import Text from '../Text'
import { ErrorMessage } from '../Typography'

const DataCard = ({
  title = 'Title',
  loading,
  value,
  units = 'UNITS',
  description = '',
  type,
  size = 'medium',
  style,
  hideColorBar,
  color,
  roundToInt
}) => {
  const Colors = useColors()
  const data =
    checkVal(value) && !isNaN(value)
      ? thousandsSeparator(roundToInt ? Math.round(value) : value.toFixed(2))
      : 'NO DATA'
  return (
    <Subsection
      direction='row'
      style={{
        flex: 1,
        maxWidth: 700,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        ...style
      }}
    >
      {!hideColorBar && (
        <div
          style={{
            // height: '100%',
            borderRadius: Metrics.radius,
            width: Metrics.base,
            backgroundColor: color ? color : Colors.primary
          }}
        />
      )}
      <FlexBox
        direction='column'
        fillSpace
        style={{
          justifyContent: 'flex-start',
          flex: 1
        }}
      >
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base
            // borderBottom: `1px solid ${Colors.dividerColor}`
          }}
        >
          {size === 'small' ? (
            <Text color={Colors.primary} marginLeft={2}>
              {title}
            </Text>
          ) : (
            <Subheading color={Colors.primary} marginLeft={2}>
              {title}
            </Subheading>
          )}
        </FlexBox>
        <FlexBox
          fillSpace
          style={{
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2,
            width: '100%',
            justifyContent: 'flex-start',
            borderBottom: `1px solid ${Colors.dividerColor}`
          }}
        >
          {data === 'NO DATA' && !loading ? (
            <ErrorMessage>Data unavailable</ErrorMessage>
          ) : (
            <>
              {size === 'small' ? (
                <Heading
                  loading={loading}
                  loadingBarHeight={12}
                  loadingBarWidth={50}
                >
                  {data}
                </Heading>
              ) : (
                <Display
                  loading={loading}
                  loadingBarHeight={18}
                  loadingBarWidth={100}
                >
                  {data}
                </Display>
              )}
              {size === 'small' ? (
                <Text marginLeft={2} color={Colors.textSecondary}>
                  {units}
                </Text>
              ) : (
                <Subheading marginLeft={2} color={Colors.textSecondary}>
                  {units}
                </Subheading>
              )}
            </>
          )}
        </FlexBox>
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base * 2
          }}
        >
          <Text font='caption' marginLeft={2} color={Colors.textSecondary}>
            {description}
          </Text>
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}
export default DataCard
