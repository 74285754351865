import React from 'react'
import { FlexBox, InfoBadge } from '../../../../Components'
import { useMaintenanceScoreHistory } from '../../../../Graphql/Queries/Asset/SafetyScoreHistory/useMaintenanceScoreHistory'
import { useAssetSafetyByAssetId } from '../../../../Graphql/Queries/Asset/useAssetSafetyByAssetId'
import { checkVal, thousandsSeparator } from '../../../../Helpers/Functions'
import { Metrics, useColors } from '../../../../Themes'
import ScoreVsTimeChart from '../../Driver/Categories/ScoreVsTimeChart'
import { DescriptionContent } from '../../Trip/Breakdown'
import { useScoreHistory } from './useScoreHistory'

const Maintenance = ({ assetId, assetSafetyData, score }) => {
  const Colors = useColors()
  const kmSinceLastService = assetSafetyData
    ? assetSafetyData.kmSinceLastService
    : 0
  const lastServiceNormScore = assetSafetyData
    ? assetSafetyData.lastServiceNormScore
    : 0
  const kmSinceLastInspection = assetSafetyData
    ? assetSafetyData.kmSinceLastInspection
    : 0
  const lastInspectionNormScore = assetSafetyData
    ? assetSafetyData.lastInspectionNormScore
    : 0
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useMaintenanceScoreHistory({ assetId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ assetId, scoreHistoryData, category: 'LEGAL' })
  return (
    <FlexBox direction='column'>
      <FlexBox
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`,
          marginTop: -Metrics.base * 3,
          marginBottom: Metrics.base * 4,
          paddingBottom: Metrics.base
        }}
      >
        <DescriptionContent
          points={[
            `This score indicates how well maintained an assets is, as well as how regularly it is inspected. Assets that have low distances traveled since their last service or inspection will score better in this category.`,
            'This accounts for 50% of the Total Asset Safety Score.'
          ]}
        />
      </FlexBox>
      <FlexBox
        direction='row'
        style={{ width: '100%', marginBottom: Metrics.base * 3 }}
      >
        <InfoBadge
          size='small'
          value={
            checkVal(kmSinceLastService) &&
            `${thousandsSeparator(kmSinceLastService)} km`
          }
          label={'Since Last Service'}
        />
        <InfoBadge
          size='small'
          value={`${Math.round(lastServiceNormScore * 100) / 100}/10`}
          label={'Last Service Score'}
        />
        <InfoBadge
          size='small'
          value={
            checkVal(kmSinceLastInspection) &&
            `${thousandsSeparator(kmSinceLastInspection)} km`
          }
          label={'Since Last Inspection'}
        />
        <InfoBadge
          size='small'
          value={`${Math.round(lastInspectionNormScore * 100) / 100}/10`}
          label={'Last Inspection Score'}
        />
      </FlexBox>
      <ScoreVsTimeChart
        currentScore={score}
        data={scoreHistory}
        loading={scoreHistoryLoading}
      />
    </FlexBox>
  )
}

export default Maintenance
