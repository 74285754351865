import React from 'react'
import { Metrics, useColors } from '../../Themes'
import Text from '../Text'
import Status from '../Status'
import Card from '../Custom/SelectionPanel/Cards/Card'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'
import { FlexBox, Icon } from '..'
import { useNavigation } from '../CustomHooks/useNavigation'
import AssetCardTTList from './SelectionPanel/Asset/AssetCardTTList'
import { useAssetSafetyCategory } from '../../Graphql/Queries/Asset/useAssetSafetyCategory'
import SelectedObjectHeader from './SelectedObjectHeader'
import { checkVal } from '../../Helpers/Functions'
import { ErrorMessage } from './SelectionPanel/Driver/LiveDriverCard'
import {
  selectedAssetId,
  selectedDeviceId,
  selectedDriverId
} from '../../Graphql/GraphQLClient'
import { useDriverSafetyCategory } from '../../Graphql/Queries/Driver/useDriverSafetyCategory'
import { useDriver } from '../../Graphql/Queries/Driver/useDriver'
import DriverCardTTList from './SelectionPanel/Driver/DriverCardTTList'

const DriverHeader = ({ driverId }) => {
  const Colors = useColors()
  const { category, loading: categoryLoading } =
    useDriverSafetyCategory(driverId)
  const { data, loading: driverLoading } = useDriver(driverId)
  const navigateTo = useNavigation()
  return (
    <SelectedObjectHeader
      id={driverId}
      handleClose={() => [selectedDeviceId(null), selectedDriverId(null)]}
      navigateTo={navigateTo}
      TooltipContent={({ setShowTooltip }) => (
        <DriverCardTTList
          driverId={driverId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      loading={driverLoading}
      statusLoading={categoryLoading}
      title={data && data.name}
      profilePicUrl={data && data.profilePictureUrl}
      centerContent={
        <Text color='white'>
          {data && data.displayId && `Driver ${data.displayId}`}
        </Text>
      }
      rightContent={
        <FlexBox direction='row'>
          {data && checkVal(data.assetName) ? (
            <Text font='caption' color={Colors.backgroundOpacity1}>
              Asset: {data.assetName}
            </Text>
          ) : (
            <FlexBox>
              <Text
                marginRight={1 / 2}
                color={Colors.backgroundOpacity1}
                font='caption'
              >
                No linked asset
              </Text>
              <Icon
                size='small'
                color={Colors.backgroundOpacity1}
                name='error'
              />
            </FlexBox>
          )}
          <FlexBox
            style={{
              marginLeft: Metrics.base * 2,
              borderRadius: Metrics.radius,
              backgroundColor: Colors.background
            }}
          >
            <Status
              status={category ? category : 'no safety score'}
              getStatusColors={({ status }) =>
                Colors[status] ? Colors[status] : Colors.textSecondary
              }
            />
          </FlexBox>
        </FlexBox>
      }
    />
  )
}

export default DriverHeader
