import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { FlexBox, MainLayout } from '../../Components'
import TabRoutes from '../../Components/TabRoutes'
import { getSelectedId } from '../../Components/TabRoutes/TabRoutingBar'
import { useTotalCounts } from '../../Graphql/Queries/useTotalCounts'
import { removeTrailingSlashes } from '../../Helpers/Functions'
import { Images, Metrics, useColors } from '../../Themes'
import Assets from './Assets/'
import AssetTotalsTabButton from './Assets/AssetTotalsTabButton'
import Drivers from './Drivers/'
import DriverTotalsTabButton from './Drivers/DriverTotalsTabButton'
import Trips from './Trips/'
import TripTotalsTabButton from './Trips/TripTotalsTabButton'

export const TabButton = ({ children, selected, linkToUrl }) => {
  const Colors = useColors()
  return (
    <Link
      to={linkToUrl}
      style={{
        width: '100%',
        height: '100%',
        margin: Metrics.base
      }}
    >
      <FlexBox
        fillSpace
        selectable
        Colors={Colors}
        selected={selected}
        selectedBackgroundColor={Colors.selectHighlightOpacity4}
        selectHighlightColor={Colors.selectHighlightOpacity4}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: Metrics.radius
        }}
      >
        {children}
      </FlexBox>
    </Link>
  )
}

const Dashboard = (props) => {
  const Colors = useColors()
  const { data: totalCountsData, loading: totalCountsLoading } = useTotalCounts(
    { period: 'all' }
  )
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const pathIds = pathname.split('/')
  const tabsData = [
    { id: 'drivers', name: 'Drivers' },
    { id: 'assets', name: 'Assets' },
    { id: 'trips', name: 'Trips' }
  ]
  const selectedId = getSelectedId({ ids: pathIds, tabsData })
  return (
    <MainLayout {...props} hideSelectionPanel={true}>
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          backgroundColor: Colors.primary,
          paddingTop: Metrics.base * 15,
          paddingBottom: Metrics.base * 25,
          position: 'relative'
        }}
      >
        <img
          src={Images.CybeleB}
          alt='Cybele B'
          style={{
            width: 700,
            position: 'absolute',
            zIndex: 0,
            top: 0,
            left: 500
          }}
          // style={Styles.logo}
        />
        <FlexBox
          style={{
            paddingLeft: Metrics.base * 12,
            paddingRight: Metrics.base * 12,
            maxWidth: 2000,
            marginBottom: Metrics.base * 4,
            width: '100%',
            justifyContent: 'flex-start'
          }}
        >
          <img
            src={Images.CybeleLogotypeAlt}
            alt='Cybele Logo'
            style={{
              width: 400,
              marginTop: -Metrics.base,
              marginLeft: Metrics.base * 2
            }}
            // style={Styles.logo}
          />
        </FlexBox>
        <FlexBox
          style={{
            width: '100%',
            paddingLeft: Metrics.base * 12,
            paddingRight: Metrics.base * 12,
            maxWidth: 2000,
            zIndex: 1
          }}
        >
          <FlexBox style={{ flex: 1 }}>
            <TabButton
              selected={selectedId === 'drivers'}
              name='Drivers'
              linkToUrl={`${removeTrailingSlashes(url)}/drivers`}
              Colors={Colors}
              hideBorderRight
            >
              <DriverTotalsTabButton
                data={totalCountsData}
                loading={totalCountsLoading}
                selected={selectedId === 'drivers'}
              />
            </TabButton>
          </FlexBox>
          <FlexBox style={{ flex: 1 }}>
            <TabButton
              selected={selectedId === 'assets'}
              name='Assets'
              linkToUrl={`${removeTrailingSlashes(url)}/assets`}
              Colors={Colors}
              hideBorderRight
            >
              <AssetTotalsTabButton
                data={totalCountsData}
                loading={totalCountsLoading}
                selected={selectedId === 'assets'}
              />
            </TabButton>
          </FlexBox>
          <FlexBox style={{ flex: 1 }}>
            <TabButton
              data={totalCountsData}
              loading={totalCountsLoading}
              selected={selectedId === 'trips'}
              name='Trips'
              linkToUrl={`${removeTrailingSlashes(url)}/trips`}
              Colors={Colors}
              hideBorderRight
            >
              <TripTotalsTabButton
                data={totalCountsData}
                loading={totalCountsLoading}
                selected={selectedId === 'trips'}
              />
            </TabButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          marginTop: -Metrics.base * 25,
          paddingLeft: Metrics.base * 16,
          paddingRight: Metrics.base * 16,
          maxWidth: 1800,
          position: 'relative'
        }}
      >
        <TabRoutes
          style={{
            width: '100%'
            // justifyContent: 'center'
          }}
          renderTabBar={false}
          // defaultId={selectedTab ? selectedTab : null}
          // onChange={(id) => selectedTabs({ ...selectedTabs(), tripHistory: id })}
          tabsData={tabsData}
        >
          <Drivers
            {...props}
            totalCountsData={totalCountsData}
            totalCountsLoading={totalCountsLoading}
          />
          <Assets
            {...props}
            totalCountsData={totalCountsData}
            totalCountsLoading={totalCountsLoading}
          />
          <Trips
            {...props}
            totalCountsData={totalCountsData}
            totalCountsLoading={totalCountsLoading}
          />
        </TabRoutes>
      </FlexBox>
    </MainLayout>
  )
}

export default Dashboard
