import React from 'react'
import ReactDOM from 'react-dom'
import App from './Containers/App'
import { ApolloProvider } from '@apollo/client/react'
import { client } from './Graphql/GraphQLClient'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const sentryReleaseVersion =
  process.env.NODE_ENV === 'production'
    ? process.env.SENTRY_RELEASE_VERSION
    : 'cybele-web-app@local'
process.env.NODE_ENV === 'production' &&
  Sentry.init({
    release: sentryReleaseVersion,
    dsn: 'https://15639e37d78c4aba8618911aa8a867ab@o368513.ingest.sentry.io/5942993',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
