import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import { FlexBox } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import BarChart from '../../../Components/Custom/D3Charts/BarChart.js'
import AreaChart from '../D3Charts/AreaChart'
import InfoBadge from '../../InfoBadge'
import Status from '../../Status'
import MaterialInput from '../../MaterialInput'
import { ModifiedPieChart } from '../../../Components/Custom/ModifiedPieChart'
import { useTripSafetyScoreDistribution } from '../../../Graphql/Queries/Trip/useTripSafetyScoreDistribution'
import RunChart from '../../D3Charts/RunChart'
import { useEventTypeGraph } from '../../../Graphql/Queries/Event/useEventTypeGraph'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'
import { checkVal } from '../../../Helpers/Functions'

export const getEventCategory = (score) => {
  if (score >= 8) return 'SEVERE'
  if (score >= 6) return 'HEAVY'
  if (score >= 4) return 'MODERATE'
  if (score >= 2) return 'LIGHT'
  return 'VERY_LIGHT'
}

let data = []
//const Colors = useColors()
const sixMonthsInMilisec = 1.5768e10
for (let i = 0; i < 10; i++) {
  data.push({
    x: new Date(new Date().getTime() - sixMonthsInMilisec + i * 1750000000),
    y: Math.ceil(Math.random() * 40) + 20
  })
}
data.sort(function (a, b) {
  return a.x - b.x
})

const inputOptions = [
  { value: 'SPEEDING', label: 'SPEEDING' },
  { value: 'PHONE_MOTION', label: 'PHONE_MOTION' },
  { value: 'HARSH_BRAKING', label: 'HARSH_BRAKING' },
  { value: 'HARSH_ACCELERATION', label: 'HARSH_ACCELERATION' },
  { value: 'HARSH_CORNERING', label: 'HARSH_CORNERING' },
  { value: 'PERCENT_SPEEDING', label: 'PERCENT_SPEEDING' },
  { value: 'TRIP_DISTANCE', label: 'TRIP_DISTANCE' },
  { value: 'TRAVEL_TIME', label: 'TRAVEL_TIME' },
  { value: 'DRIVING_TIME', label: 'DRIVING_TIME' },
  { value: 'CONTINUOUS_DRIVING', label: 'CONTINUOUS_DRIVING' },
  { value: 'NIGHT_DRIVING', label: 'NIGHT_DRIVING' }
]

const TripSafetyCard = ({ data }) => {
  const [inputValue, setInputValue] = useState('SPEEDING')
  const Colors = useColors()
  const [width, setWidth] = useState(300)
  const containerRef = useRef()

  const {
    data: { average: eventScoreAvg, coordinates: eventScoreOverTime },
    loading: eventScoreOverTimeLoading
  } = useEventTypeGraph({ eventType: inputValue })

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  console.log('eventScoreOverTime', eventScoreOverTime)
  return (
    <Subsection
      fillspace
      style={{
        width: '100%'
      }}
    >
      <FlexBox
        //fillspace
        direction='row'
        style={{
          //alignItems: 'space-between',
          width: '100%',
          Height: 60
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 18,
            width: 150,
            margin: Metrics.base * 2,
            paddingLeft: Metrics.base * 4
          }}
        >
          Average
        </Text>
        <MaterialInput
          styles={{
            fontSize: 10,
            marginTop: Metrics.base,
            marginBottom: Metrics.base * 2,
            minWidth: 100,
            maxWidth: 160,
            alignSelf: 'left'
          }}
          type='select'
          name='TripSafetyCardFilter'
          label={`Safety Score Type`}
          value={inputValue}
          selectValues={inputOptions.map((a) => a.value)}
          selectDisplayValues={inputOptions.map((a) => a.label)}
          handleChange={(value) => {
            setInputValue(value)
          }}
        />
        {/*<Status
          fillspace={false}
          style={{
            height: 25,
            margin: Metrics.base * 2,
            marginRight: Metrics.base * 4,
            borderRadius: Metrics.base
          }}
          status={'ADEQUATE'}
          getStatusColors={({ status }) => Colors[status]}
        />*/}
      </FlexBox>

      <div
        style={{
          borderBottom: `1px solid ${Colors.dividerColor}`,
          width: '100%',
          height: 0,
          backgroundColor: Colors.background,
          marginRight: Metrics.base * 2,
          marginLeft: Metrics.base * 2
        }}
      ></div>
      <FlexBox
        fillspace
        direction='row'
        style={{
          width: '100%'
        }}
      >
        <FlexBox
          direction='column'
          style={{
            alignItems: 'left',
            //height: 300,
            //width: 400,
            //margin: Metrics.base * 4,
            marginLeft: Metrics.base * 2
          }}
        >
          <FlexBox
            direction='row'
            style={{
              width: '100%'
              //paddingBottom: Metrics.base * 2
              //justifyContent: 'space-between'
            }}
          >
            <FlexBox
              style={{
                paddingRight: Metrics.base
              }}
            >
              <div
                style={{
                  height: 120,
                  width: 120,
                  position: 'relative',
                  margin: Metrics.base * 2
                }}
              >
                <ModifiedPieChart
                  color={Colors[getEventCategory(eventScoreAvg)]}
                  hideLegend
                  hideLabels
                  padAngle={0}
                  innerRadius={0.9}
                  startAngle={-180}
                  endAngle={180}
                  enableArcLinkLabels={false}
                  enableSlicesLabels={false}
                  enableArcLabels={false}
                  isInteractive
                  data={[
                    {
                      id: 'eventScore',
                      label: 'Event Score',
                      value: eventScoreAvg
                    },
                    {
                      id: 'empty',
                      label: '',
                      value: 10 - eventScoreAvg
                    }
                  ]}
                />
              </div>
            </FlexBox>
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              alignItems: 'left',
              marginLeft: Metrics.base * 4,
              marginTop: Metrics.base * 2,
              marginBottom: Metrics.base * 4
            }}
          >
            <Text
              style={{
                fontSize: 40,
                minWidth: 100,
                paddingBottom: Metrics.base
              }}
            >
              {checkVal(eventScoreAvg) ? eventScoreAvg.toFixed(2) : ''}/10
            </Text>
            <Text
              style={{
                fontSize: 18,
                color: Colors.textSecondary
              }}
            >
              Average Score
            </Text>
          </FlexBox>
          {/*<FlexBox
            direction='row'
            style={{
              //marginLeft: Metrics.base * 10
              //width: 250,
              justifyContent: 'flex-start',
              marginLeft: Metrics.base * 6
            }}
          >
            <Text
              style={{
                fontSize: 50,
                minWidth: 120,
                marginBottom: Metrics.base * 4,
                alignSelf: 'left'
              }}
            >
              4:25
            </Text>
            <Text
              style={{
                fontSize: 14,
                maxWidth: 80,
                paddingBottom: Metrics.base * 4,
                alignSelf: 'left'
              }}
            >
              Average Time
            </Text>
            </FlexBox>*/}
        </FlexBox>
        <FlexBox
          direction='column'
          forwardRef={containerRef}
          //fillSpace
          style={{
            padding: Metrics.base,
            paddingBottom: Metrics.base * 6,
            width: '100%'
            // backgroundColor: 'blue'
          }}
        >
          <Text
            color={Colors.textSecondary}
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              minWidth: 100,
              margin: Metrics.base * 2
            }}
          >
            Average Score vs Time
          </Text>
          <RunChart
            id={`eventVsTimeScoreChart-${inputValue}`}
            xAccessor='x'
            yAccessor='y'
            data={eventScoreOverTime}
            chartWidth={width - Metrics.base * 8}
            chartHeight={250}
            maxYValue={10}
            color={Colors[inputValue]}
            gradientColor1={Colors[`${inputValue}Opacity5`]}
            gradientColor2={Colors[`${inputValue}Opacity3`]}
            loading={eventScoreOverTimeLoading}
            showPoints={false}
          />
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

export default TripSafetyCard
