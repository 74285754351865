import { Colors, Metrics } from '../../Themes'

const Styles = {
  outContainer: {
    width: '100%',
    marginBottom: 8,
    minWidth: 250,
    padding: '0px 8px'
  },
  innerContainer: {
    paddingTop: Metrics.base
    //paddingBottom: Metrics.base * 3
  },
  formBoxContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // backgroundColor: Colors.backgroundTintLight,
    // borderRadius: 5,
    display: 'block',
    marginBottom: Metrics.base * 2,
    overflow: 'hidden'
  }
}

export default Styles
