import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Row extends Component {
  render () {
    const { start, center, end, top, middle, bottom, around, between, reverse, padding, marginTop, marginBottom, children, style } = this.props
    const classNames = `
      row
      ${start ? `start-${start}` : ''}
      ${center ? `center-${center}` : ''}
      ${end ? `end-${end}` : ''}
      ${top ? `top-${top}` : ''}
      ${middle ? `middle-${middle}` : ''}
      ${bottom ? `bottom-${bottom}` : ''}
      ${around ? `around-${around}` : ''}
      ${between ? `between-${between}` : ''}
      ${reverse ? `reverse` : ''}
    `

    return (
      <div className={classNames.replace(/(\n)/g, '').trim()} style={{paddingLeft: padding ? '.5rem' : null, paddingRight: padding ? '.5rem' : null, margin: 0, marginTop, marginBottom, ...style}}>
        {children}
      </div>
    )
  }
}

Row.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  children: PropTypes.any.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ])
}
