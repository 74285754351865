import React from 'react'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import HarshEventDetails from './HarshEventDetails'
import PhoneMotionDetails from './PhoneMotionDetails'
import SpeedingDetails from './SpeedingDetails'

const EventDetailsInTrip = ({ eventId }) => {
  const { data: event } = useEvent(eventId)

  if (!event) return ''
  return event.type === 'SPEEDING' ? (
    <SpeedingDetails eventId={eventId} event={event} />
  ) : event.type === 'PHONE_MOTION' ? (
    <PhoneMotionDetails eventId={eventId} event={event} />
  ) : (
    <HarshEventDetails eventId={eventId} event={event} />
  )
}

export default EventDetailsInTrip
