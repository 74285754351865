import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_COACHING_LESSON = gql`
  mutation createCoachingLesson(
    $driverId: String!
    $lessonEvents: [LessonEventInput]
    $coachingStatus: String
    $name: String
  ) {
    createCoachingLesson(
      driverId: $driverId
      lessonEvents: $lessonEvents
      coachingStatus: $coachingStatus
      name: $name
    ) {
      _id
      displayId
      name
      driver {
        _id
      }
      coachingStatus
      lessonEvents {
        coachingEvent {
          _id
          event {
            _id
          }
        }
      }
    }
  }
`
export const useCreateCoachingLesson = () => {
  const [createCoachingLesson, { loading: createCoachingLessonLoading }] =
    useMutation(CREATE_COACHING_LESSON, {
      mutationName: 'createCoachingLesson',
      successMessage: 'Coaching Lesson created successfully',
      errorMessage: 'Coaching Lesson creation failed',
      writeToListOnUpdate: true,
      listQueryName: 'coachingLessonsByDriverId'
    })
  return [createCoachingLesson, { createCoachingLessonLoading }]
}
