import React from 'react'
import { FlexBox, Subsection } from '../../../../Components'
import { Metrics, useColors } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import CategorySummary from './CategorySummary'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import { useCombinedScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useCombinedScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'
import { Display, Subheading } from '../../../../Components/Typography'
import { checkVal } from '../../../../Helpers/Functions'
import { getScoreCategory } from '../../Asset/Breakdown'
import ScoreCircleChart from '../../../../Components/Custom/ScoreCircleChart'
import { DescriptionContent } from '../../Trip/Breakdown'

const Combined = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts
}) => {
  const Colors = useColors()
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useCombinedScoreHistory({ driverId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ driverId, scoreHistoryData, category: 'SPEEDING' })
  // console.log('eventTypeCounts', eventTypeCounts)
  return (
    <FlexBox direction='column'>
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          paddingLeft: Metrics.base * 2,
          paddingRight: Metrics.base * 2,
          paddingBottom: Metrics.base * 2,
          marginTop: -Metrics.base * 2,
          marginBottom: Metrics.base * 2,
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        <DescriptionContent
          points={[
            `This score is a rolling average of the 100 most recently scored trips for this driver. As a driver completes safer trips, his driving behaviour score will increase across the trip safety categories and totals.`,
            'This accounts for 66% of the Total Driver Safety Score.',
            'Drivers who score low in the Driving Behaviour Category are completing riskier trips on a daily basis.'
          ]}
        />
      </FlexBox>
      <CategorySummary
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        eventsCounts={eventTypeCounts ? eventTypeCounts : {}}
      />
      <ScoreVsTimeChart
        currentScore={score}
        data={scoreHistory}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot events={events} loading={eventsLoading} />
      <EventsVsTimeChart
        events={events}
        groupBy='type'
        keys={[
          'SPEEDING',
          'HARSH_BRAKING',
          'HARSH_ACCELERATION',
          'HARSH_CORNERING',
          'PERCENT_SPEEDING',
          'TRIP_DISTANCE',
          'TRAVEL_TIME',
          'DRIVING_TIME',
          'PHONE_MOTION',
          'CONTINUOUS_DRIVING',
          'NIGHT_DRIVING',
          'CRASH',
          'INCIDENT'
        ]}
      />
    </FlexBox>
  )
}

export default Combined
