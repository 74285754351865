import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import { CREATE_ASSET } from '../../Graphql/Mutations/useCreateAsset'
import { UPDATE_ASSET } from '../../Graphql/Mutations/useUpdateAsset'
import { useAssets } from '../../Graphql/Queries/Asset/useAssets'

import { Button, FlexBox, ModalAlert, Form, Subsection, FormGraphQL } from '../'
import { select } from 'd3'

const drivingPermitValues = [
  { label: 'G', value: 'GOODS' },
  { label: 'P', value: 'PASSENGER' },
  { label: 'D', value: 'DANGEROUS_GOODS' }
]

const licenceCodeValues = [
  { label: 'A1: Motorcycle up to 125cc', value: 'A1' },
  { label: 'A: Motorcycle, tricycle or quadrucycle above 125cc', value: 'A' },
  { label: 'B: Light motor vehicle (not articulated)', value: 'B' },
  { label: 'B5: B with authorization for a tractor', value: 'B5' },
  {
    label: 'B7: B with authorization to mobile machinery',
    value: 'B7'
  },
  { label: 'C1: Heavy motor vehicle (not articulated)', value: 'C1' },
  {
    label: 'C4: C1 with authorization to drive buses ',
    value: 'C4'
  },
  { label: 'C: Extra heavy vehicle (not articulated)', value: 'C' },
  {
    label:
      'EB: Combination- articulated vehicle up to 3500kg or rigid vehicle above 3500kg with trailer above 750kg ',
    value: 'EB'
  },
  {
    label:
      'EC1 Combination- articulated vehicle up to 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
    value: 'EC1'
  },
  {
    label:
      'EC: Combination- articulated vehicle above 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
    value: 'EC'
  }
]

// const prdpCodeValues = [
//   { label: 'Heavy goods vehicle, loaded or empty', value: 'HEAVY_GOODS' },
//   { label: 'Breakdown vehicle', value: 'BREAKDOWN' },
//   { label: 'Bus above 3 500 kg GVM', value: 'BUS_ABOVE_3500KG' },
//   {
//     label: 'Bus or minibus with seating for more than 12 including the driver',
//     value: 'BUS_SEATING_12'
//   },
//   {
//     label: 'Taxi or other vehicle carrying paying passengers (eg an ambulance)',
//     value: 'CARRYING_PAYING_PASSENGERS'
//   },
//   {
//     label:
//       'Vehicle carrying more than 12, including the driver, whether or not it has enough seats and irrespective of its weight',
//     value: 'VEHICLE_CARRYING_ABOVE_12'
//   }
// ]
const subTypeValues = [
  { label: 'Vehicles > 3500Kg, loaded or empty', value: 'HCV' },
  { label: 'Breakdown vehicle', value: 'BREAKDOWN' },
  {
    label: 'Curtain Side',
    value: 'CURTAIN_SIDE'
  },
  {
    label: 'Interlink',
    value: 'INTERLINK'
  },
  {
    label: 'Tautliner',
    value: 'TAUTLINER'
  },
  {
    label: 'Semi',
    value: 'SEMI'
  },
  {
    label: 'Flat Deck',
    value: 'FLAT_DECK'
  },
  {
    label: 'Skeletal',
    value: 'SKELETAL'
  },
  {
    label: 'Tanker',
    value: 'TANKER'
  },
  {
    label: 'Car/Sedan',
    value: 'CAR_SEDAN'
  },
  {
    label: 'Suv',
    value: 'SUV'
  },
  {
    label: 'Vehicles < 3500Kg',
    value: 'LDV'
  }
]

const handleSubmit = ({ data, setSubmitting, mutate, onSuccess, onError }) => {
  if (data) {
    if (!data.maxSpeed) {
      data.maxSpeed = null
    }
    if (!data.gvm) {
      data.gvm = null
    }
    if (!data.tare) {
      data.tare = null
    }
  }

  setSubmitting(true)
  mutate({
    variables: data,
    onComplete: (data) => {
      setSubmitting(false)
      onSuccess()
    },
    onError: (error) => {
      setSubmitting(false)
      onError()
    }
  })
  onSuccess()
}

const AddAssetModal = ({ setShowAddAssetModal, object, showAddAssetModal }) => {
  const { data: driverOrganizations } = useOrganizations()
  const [organizations, setOrganizations] = useState([])
  const { data: assetsToLink } = useAssets({ assetType: ['TRAILER'] })
  const [linkAssets, setLinkAssets] = useState([])

  useEffect(() => {
    if (driverOrganizations && driverOrganizations.length > 0) {
      let orgs = []
      driverOrganizations.forEach((organization) => {
        const values = { value: organization._id, label: organization.name }
        orgs = [...orgs, values]
      })
      setOrganizations(orgs)
    }
  }, [driverOrganizations])

  useEffect(() => {
    if (assetsToLink && assetsToLink.length > 0) {
      let assetsForLinking = []
      assetsToLink.forEach((asset) => {
        const values = {
          value: asset._id,
          label: asset.assetType + ': ' + asset.name
        }
        assetsForLinking = [...assetsForLinking, values]
      })
      setLinkAssets(assetsForLinking)
    }
  }, [assetsToLink])

  const assetFormFields = [
    {
      name: 'orgId',
      required: false,
      label: 'Organization',
      type: 'select',
      options: organizations
    },
    {
      name: 'assetType',
      required: false,
      label: 'Asset Type',
      type: 'select',
      options: [
        { value: 'TRUCK', label: 'Truck' },
        { value: 'TRAILER', label: 'Trailer' },
        { value: 'CAR', label: 'Car' },
        { value: 'FLATBED', label: 'Flatbed' },
        { value: 'STEP_DECK', label: 'Step Deck' },
        { value: 'REFRIGERATED', label: 'Refrigerated' },
        { value: 'DRY', label: 'Dry' },
        { value: 'VEHICLE_CARRIER', label: 'Vehicle Carrier' },
        { value: 'DUMP', label: 'Dump' },
        { value: 'TANKER', label: 'Tanker' },
        { value: 'LDV', label: 'Light Delivery Vehicle' },
        { value: 'HCV', label: 'Heavy Commercial Vehicle' }
      ]
    },
    {
      name: 'subVehicleType',
      required: false,
      label: 'Sub Vehicle Type',
      type: 'select',
      options: subTypeValues
    },
    {
      name: 'driven',
      required: false,
      label: 'Driven Vehicle',
      type: 'select',
      options: [
        { value: 'SELF_PROPPELLED', label: 'Self-Proppelled' },
        { value: 'TRUCK_TRACTOR', label: 'Truck tractor' }
      ]
    },
    {
      name: 'vin',
      required: false,
      // type: 'date',
      label: 'VIN'
    },
    {
      name: 'registrationNum',
      required: false,
      // type: 'date',
      label: 'Registration Number'
    },
    {
      name: 'dateOfPurchase',
      required: false,
      type: 'date',
      label: 'Date of Purchase'
    },
    {
      name: 'name',
      required: false,
      // type: 'checkbox',
      label: 'Name'
    },

    {
      name: 'fuelType',
      required: false,
      label: 'Fuel Type',
      type: 'select',
      options: [
        { value: 'PETROL', label: 'Petrol' },
        { value: 'DIESEL', label: 'Diesel' }
      ]
    },
    {
      name: 'maxSpeed',
      required: false,
      label: 'Max Speed',
      type: 'number',
      editable: true
    },
    {
      name: 'assetNo',
      required: false,
      label: 'Asset Number'
    },
    {
      name: 'licenseNumber',
      required: false,
      label: 'License Number'
    },
    {
      name: 'controlNumber',
      required: false,
      label: 'Control Number'
    },
    {
      name: 'registeringAuthority',
      required: false,
      label: 'Registering Authority'
    },
    {
      name: 'engineNumber',
      required: false,
      label: 'Engine Number'
    },
    {
      name: 'prdpCategory',
      required: false,
      label: 'Professional Driving Permit Category',
      type: 'multipleSelect',
      options: drivingPermitValues
    },
    {
      name: 'tare',
      required: false,
      label: 'Tare',
      type: 'number'
    },
    {
      name: 'gvm',
      required: false,
      label: 'Gross Vehicle Mass',
      type: 'number'
    },
    {
      name: 'vehicleMake',
      required: false,
      label: 'Make of Vehicle'
    },
    {
      name: 'vehicleModel',
      required: false,
      label: 'Vehicle Model'
    },
    {
      name: 'vehicleYear',
      required: false,
      label: 'Vehicle Year'
    },
    {
      name: 'vehicleDescription',
      required: false,
      label: 'Description of Vehicle'
    },
    {
      name: 'vehicleColour',
      required: false,
      label: 'Vehicle Colour'
    },
    {
      name: 'specialMarkings',
      required: false,
      label: 'Special Markings'
    },
    {
      name: 'branch',
      required: false,
      label: 'Branch'
    },

    {
      name: 'doe',
      required: false,
      type: 'date',
      label: 'Licence Expiry Date'
    },
    {
      name: 'nvc',
      required: false,
      label: 'NVC'
    },
    {
      name: 'isActive',
      required: false,
      type: 'checkbox',
      label: 'Is Active'
    }
  ]

  return (
    <ModalAlert
      title={`New Asset`}
      body='Enter details below'
      openDialog={showAddAssetModal}
      setOpenDialog={setShowAddAssetModal}
    >
      <FormGraphQL
        hideFormBox
        formSchema={assetFormFields}
        createMutation={CREATE_ASSET}
        updateMutation={UPDATE_ASSET}
        handleSubmit={({ data, setSubmitting, mutate, onSuccess, onError }) =>
          handleSubmit({ data, setSubmitting, mutate, onSuccess, onError })
        }
        onSuccess={() => setShowAddAssetModal(false)}
        onError={() => setShowAddAssetModal(false)}
        handleCancel={() => setShowAddAssetModal(false)}
        object={object ? object : null}
      />
    </ModalAlert>
  )
}

export default AddAssetModal
