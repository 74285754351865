import React from 'react'
import { Text } from '../'
import { useColors, Metrics } from '../../Themes'
const ProgressBar = (props) => {
  const Colors = useColors()
  const { bgcolor, completed } = props

  const containerStyles = {
    height: 20,
    width: '70%',
    backgroundColor: '#e0e0de',
    borderRadius: 5,
    marginTop: 30,
    margin: 10
  }

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    maxWidth: '100%',
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right'
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'normal'
  }

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <Text
          style={{
            // marginBottom: Metrics.base,
            // padding: 5,
            paddingTop: 1,
            marginRight: 2,
            textAlign: 'right',
            fontWeight: 'normal',
            color: Colors.background
          }}
        >
          {`${completed}%`}{' '}
        </Text>
      </div>
    </div>
  )
}

export default ProgressBar
