import React from 'react'
import {
  eventFilters,
  selectedEventId,
  selectedTripId
} from '../../../../Graphql/GraphQLClient'
import { useColors } from '../../../../Themes'
import { AssetLink, DeviceLink } from '../Asset/AssetCardTTList'
import { ToolTipMenuContainer, TooltipMenuItem } from '../Cards/Card'
import { DriverLink } from '../Driver/DriverCardTTList'

const TripCardTTList = ({ pathname, data, navigateTo }) => {
  const Colors = useColors()
  return (
    <ToolTipMenuContainer>
      {pathname !== '/trip-history' && (
        <TooltipMenuItem
          text='Trip History'
          iconName={'history'}
          Colors={Colors}
          onClick={() => {
            selectedTripId(data._id)
            navigateTo({
              pathname: '/trip-history',
              queryParams: [{ name: 'trip-id', id: data._id }]
            })
          }}
        />
      )}
      {pathname !== '/safety-score' && (
        <TooltipMenuItem
          text='Safety Report'
          iconName={'shield'}
          Colors={Colors}
          onClick={() => {
            selectedTripId(data._id)
            navigateTo({
              pathname: '/safety-score/trips',
              queryParams: [{ name: 'trip-id', id: data._id }]
            })
          }}
        />
      )}
      <AssetLink
        text='Asset'
        assetId={data.assetId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <DriverLink
        text='Driver'
        driverId={data.driverId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <DeviceLink
        text='Device'
        deviceId={data.deviceId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <TooltipMenuItem
        text='Events'
        iconName={'inbox'}
        Colors={Colors}
        onClick={() => {
          eventFilters({
            ...eventFilters(),
            filterByTypeName: 'trip',
            tripId: data._id
          })
          selectedEventId(null)
          navigateTo({
            pathname: '/event-inbox/in-trip/more-filters',
            clearQueryParams: true
          })
        }}
      />
    </ToolTipMenuContainer>
  )
}

export default TripCardTTList
