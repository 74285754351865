import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../Helpers/Functions'

export const GET_FILTERS = gql`
  query filters {
    filters @client {
      startDate
      endDate
      assetId
      driverId
      deviceId
      assetOrDriverOrDevice
      eventTypes
      eventScoreGreaterThan
      eventScoreLessThan
      assetTypes
      driverSearchString
      assetSearchString
      tripSearchString
      eventSearchString
    }
  }
`

export const useFilters = () => {
  const [data, setData] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [assetId, setAssetId] = useState(null)
  const [driverId, setDriverId] = useState(null)
  const [deviceId, setDeviceId] = useState(null)
  const [assetOrDriverOrDevice, setAssetOrDriverOrDevice] = useState(null)
  const [eventTypes, setEventTypes] = useState(null)
  const [eventScoreGreaterThan, setEventScoreGreaterThan] = useState(null)
  const [eventScoreLessThan, setEventScoreLessThan] = useState(null)
  const [assetTypes, setAssetTypes] = useState(null)
  const [driverSearchString, setDriverSearchString] = useState(null)
  const [assetSearchString, setAssetSearchString] = useState(null)
  const [tripSearchString, setTripSearchString] = useState(null)
  const [eventSearchString, setEventSearchString] = useState(null)

  const { data: filters } = useQuery(GET_FILTERS, {
    queryName: 'filters'
  })

  useEffect(() => {
    if (typeof filters !== 'undefined') {
      setData(filters)
      setStartDate(checkVal(filters.startDate) ? filters.startDate : null)
      setEndDate(checkVal(filters.endDate) ? filters.endDate : null)
      setAssetId(checkVal(filters.assetId) ? filters.assetId : null)
      setDriverId(checkVal(filters.driverId) ? filters.driverId : null)
      setDeviceId(checkVal(filters.deviceId) ? filters.deviceId : null)
      setAssetOrDriverOrDevice(
        checkVal(filters.assetOrDriverOrDevice)
          ? filters.assetOrDriverOrDevice
          : null
      )
      setEventTypes(checkVal(filters.eventTypes) ? filters.eventTypes : null)
      setEventScoreGreaterThan(
        checkVal(filters.eventScoreGreaterThan)
          ? filters.eventScoreGreaterThan
          : null
      )
      setEventScoreLessThan(
        checkVal(filters.eventScoreLessThan) ? filters.eventScoreLessThan : null
      )
      setAssetTypes(checkVal(filters.assetTypes) ? filters.assetTypes : null)
      setDriverSearchString(
        checkVal(filters.driverSearchString) ? filters.driverSearchString : null
      )
      setAssetSearchString(
        checkVal(filters.assetSearchString) ? filters.assetSearchString : null
      )
      setTripSearchString(
        checkVal(filters.tripSearchString) ? filters.tripSearchString : null
      )
      setEventSearchString(
        checkVal(filters.eventSearchString) ? filters.eventSearchString : null
      )
    }
  }, [filters])

  return {
    data,
    startDate,
    endDate,
    assetId,
    driverId,
    deviceId,
    assetOrDriverOrDevice,
    eventTypes,
    eventScoreGreaterThan,
    eventScoreLessThan,
    assetTypes,
    driverSearchString,
    assetSearchString,
    tripSearchString,
    eventSearchString
  }
}
