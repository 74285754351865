import React from 'react'
import { Metrics, useColors } from '../../../Themes'
import { FlexBox, Subsection, TabBar, Text } from '../../../Components'
import TripDetails from './TripDetails'
import EventDetails from './EventDetails'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import { selectedTabs, selectedTripId } from '../../../Graphql/GraphQLClient'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import { checkVal } from '../../../Helpers/Functions'
import TabRoutes from '../../../Components/TabRoutes'
import { useReactiveVar } from '@apollo/client'

const SideBar = (props) => {
  const { tripHistoryMapTab: selectedTab } = useReactiveVar(selectedTabs)
  const tripId = useReactiveVar(selectedTripId)
  return (
    <Subsection
      style={{
        width: Metrics.sideBarWidth,
        height: props.height ? props.height : '100%',
        marginLeft: Metrics.base * 2
      }}
    >
      {props.videoReplay && checkVal(tripId) ? (
        <TripDetails {...props} />
      ) : checkVal(tripId) ? (
        <>
          <TabRoutes
            defaultId={selectedTab ? selectedTab : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), tripHistoryMapTab: id })
            }
            tabsData={[
              { id: 'trip-details', name: 'Trip Details' },
              { id: 'event-details', name: 'Event Details' }
            ]}
          >
            <TripDetails {...props} />
            <EventDetails {...props} />
          </TabRoutes>
          {/*
        <TabBar
          tabs={[
            {
              tabRender: () => {
                return <TripDetails {...props} />
              },
              name: 'Details'
            },
            {
              tabRender: () => {
                return <EventDetails {...props} />
              },
              name: 'Events'
            }
          ]}
          selectedIndex={tripHistoryMapTab === 'details' ? 0 : 1}
          setSelectedIndex={(index) => [
            selectedTabs({
              ...selectedTabs(),
              tripHistoryMapTab: index === 0 ? 'details' : 'events'
            })
          ]}
          tabInnerStyle={{
            // marginTop: Metrics.base * 2
            borderRadius: 0
          }}
        />*/}
        </>
      ) : (
        <NoneSelected helperText='Select a trip from the list on the left' />
      )}
    </Subsection>
  )
}

export default SideBar
