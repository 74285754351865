import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { driverDataTransform, driverFragment } from './useDriver'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { driverFilters, filters } from '../../GraphQLClient'
import { getDriversVariables } from './useDriverIds'

export const GET_DRIVERS = gql`
  query driversPaginated(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $searchString: String
    $safetyCategory: [String]
    $skip: Int
    $limit: Int
  ) {
    driversPaginated(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      searchString: $searchString
      safetyCategory: $safetyCategory
      skip: $skip
      limit: $limit
    ) {
      totalCount
      drivers {
        ...driverFragment
      }
    }
  }
  ${driverFragment}
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data:
      data.drivers && data.drivers.map((a) => driverDataTransform({ data: a }))
  }
}

export const useDrivers = () => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const { searchString, safetyCategories } = useReactiveVar(driverFilters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DRIVERS, {
    variables: getDriversVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId,
      searchString,
      safetyCategory: safetyCategories
    }),
    queryName: 'driversPaginated',
    errorMessage: 'Failed to fetch Drivers',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
