import React from 'react'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'

const SubsectionDivider = ({ rowDivider }) => {
  const Colors = useColors()

  return !rowDivider ? (
    <FlexBox
      style={{
        width: '100%',
        backgroundColor: Colors.background,
        borderBottom: `1px solid ${Colors.dividerColor}`,
        borderTop: `1px solid ${Colors.dividerColor}`,
        padding: Metrics.base * 2
      }}
    />
  ) : (
    <FlexBox
      style={{
        backgroundColor: Colors.subsectionBackground,
        borderRight: `1px solid ${Colors.dividerColor}`,
        borderLeft: `1px solid ${Colors.dividerColor}`,
        paddingTop: Metrics.base * 21,
        paddingBottom: Metrics.base * 21
      }}
    />
  )
}
export default SubsectionDivider
