import { useD3 } from '../CustomHooks/useD3'
import React from 'react'
import * as d3 from 'd3'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'

const LiveSpeedChart = ({
  data = [],
  speedLimitData = [],
  strokeColor,
  gradientColors,
  startDate,
  endDate,
  maxYValue = 140,
  chartWidth = 300,
  chartHeight = 250,
  loading,
  timeCursor,
  stopTime
}) => {
  const Colors = useColors()
  const margin = { top: 15, right: 20, bottom: 20, left: 30 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth

      const curve = d3.curveLinear
      const y = d3
        .scaleLinear()
        .domain([0, maxYValue ? maxYValue : d3.max(data, (d) => d.value)])
        .nice()
        .range([height - margin.bottom, margin.top])
      const x = d3
        .scaleTime()
        .domain(
          startDate && endDate
            ? [startDate, endDate]
            : d3.extent(data, (d) => d.date)
        )
        .range([margin.left, width - margin.right])

      const area = d3
        .area()
        .curve(curve)
        .x((d) => x(d.date))
        .y0(y(0))
        .y1((d) => y(d.value))

      const areaSpeedLimit = d3
        .area()
        .curve(curve)
        .x((d) => x(d.date))
        .y0(y(0))
        .y1((d) => y(d.speedLimit))

      const xAxis = (g) =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .attr('color', Colors.secondaryOpacity1)
          .call(
            d3
              .axisBottom(x)
              .ticks(width / 80)
              .tickSizeOuter(0)
          )

      const yAxis = (g) =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .attr('color', Colors.secondaryOpacity1)
          .call(d3.axisLeft(y))
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .select('.tick:last-of-type text')
              .clone()
              .attr('x', 3)
              .attr('text-anchor', 'start')
              .attr('font-weight', 'bold')
              .text(data.y)
          )

      svg.select('.x-axis').call(xAxis)
      svg.select('.y-axis').call(yAxis)

      svg
        .select('.area-path')
        .datum(data)
        .attr('stroke', strokeColor)
        .attr('d', area)

      svg
        .select('.area-path-speed-limit')
        .datum(data)
        .attr('stroke', Colors.HIGH_RISK)
        .attr('stroke-dasharray', 2)
        .attr('opacity', 0.6)
        .attr('d', areaSpeedLimit)

      for (var i = 0; i < speedLimitData.length; i++) {
        svg
          .select(`.speed-limit-line-${i}-start`)
          .attr('x1', x(speedLimitData[i].x1))
          .attr('y1', y(140))
          .attr('x2', x(speedLimitData[i].x1))
          .attr('y2', y(0))
          .attr('stroke', Colors.dividerColorOpacity2)
          .attr('stroke-width', 1)

        svg
          .select(`.speed-limit-line-${i}-end`)
          .attr('x1', x(speedLimitData[i].x2))
          .attr('y1', y(140))
          .attr('x2', x(speedLimitData[i].x2))
          .attr('y2', y(0))
          .attr('stroke', Colors.dividerColorOpacity2)
          .attr('stroke-width', 1)

        svg
          .select(`.speed-limit-line-${i}-back`)
          .attr('x', x(speedLimitData[i].x1))
          .attr('y', y(140))
          .attr('height', y(10))
          .attr('width', x(speedLimitData[i].x2) - x(speedLimitData[i].x1))
          .attr('fill', Colors.subsectionBackground)

        svg
          .select(`.speed-limit-line-${i}`)
          .attr('x1', x(speedLimitData[i].x1))
          .attr('y1', y(speedLimitData[i].y1))
          .attr('x2', x(speedLimitData[i].x2))
          .attr('y2', y(speedLimitData[i].y2))
          .attr('stroke', Colors.HIGH_RISK)
          .attr('stroke-width', 1)
      }

      svg
        .select(`.time-cursor`)
        .attr('x1', x(timeCursor))
        .attr('y1', y(140))
        .attr('x2', x(timeCursor))
        .attr('y2', y(0))
        .attr('stroke', Colors.MODERATE_RISK)
        .attr('stroke-width', 2)

      svg
        .select(`.stop-time`)
        .attr('x1', x(stopTime))
        .attr('y1', y(140))
        .attr('x2', x(stopTime))
        .attr('y2', y(0))
        .attr('stroke', Colors.HIGH_RISK)
        .attr('stroke-width', 2)

      svg
        .select('.max-speed-line')
        .attr('stroke', Colors.MODERATE_RISK)
        .attr('stroke-dasharray', 5)
        .attr('y1', y(80))
        .attr('y2', y(80))
        .attr('x1', margin.left)
        .attr('x2', width - margin.right)

      const ruleX = svg
        .select('.rule-x')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 2)
        .attr('y1', margin.top - 6)
        .attr('y2', height - margin.bottom - 1)
        .attr('x1', -1)
        .attr('x2', -1)

      const ruleY = svg
        .select('.rule-y')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 2)
        .attr('y1', -1)
        .attr('y2', -1)
        .attr('x1', margin.left - 6)
        .attr('x2', width - margin.right - 1)

      svg.on('mousemove touchmove', (event) => {
        const x = d3.pointer(event, svg.node())[0] + 0.5
        const y = d3.pointer(event, svg.node())[1] + 0.5
        if (x > margin.left && x < width - margin.right)
          ruleX.attr('x1', x).attr('x2', x)
        if (y > margin.top && y < height - margin.bottom)
          ruleY.attr('y1', y).attr('y2', y)
      })

      // set the gradient
      svg
        .select('.linearGradient')
        .attr('id', 'area-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', y(0))
        .attr('x2', 0)
        .attr('y2', y(120))
        .selectAll('stop')
        .data([
          {
            offset: '0%',
            color: gradientColors ? gradientColors[0] : Colors.primaryOpacity5
          },
          {
            offset: '100%',
            color: gradientColors ? gradientColors[1] : Colors.primaryOpacity2
          }
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset
        })
        .attr('stop-color', function (d) {
          return d.color
        })
    },
    [data.length, chartWidth, chartHeight, startDate, endDate]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth + Metrics.base * 2,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        ref={ref}
        style={{
          height: chartHeight,
          width: chartWidth + Metrics.base * 2,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <rect
          x={plotAreaX}
          y={plotAreaY}
          width={plotAreaWidth}
          height={plotAreaHeight}
          fill={Colors.background}
          stroke={Colors.dividerColor}
        ></rect>
        {speedLimitData.map((line, index) => {
          return (
            <>
              <rect className={`speed-limit-line-${index}-back`} />
              <line className={`speed-limit-line-${index}-start`} />
              <line className={`speed-limit-line-${index}-end`} />
              <line className={`speed-limit-line-${index}`} />
            </>
          )
        })}
        <path className='area-path-speed-limit' style={{ fill: 'none' }} />
        <path className='area-path' style={{ fill: 'url(#area-gradient)' }} />
        <linearGradient className='linearGradient' />
        <line className='max-speed-line' />
        <line className='rule-x' />
        <line className='rule-y' />
        <line
          x1={plotAreaX}
          y1={plotAreaY}
          x2={plotAreaX}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <line
          x1={plotAreaX + plotAreaWidth}
          y1={plotAreaY}
          x2={plotAreaX + plotAreaWidth}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <line className='time-cursor' />
        <line className='stop-time' />
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
            // backgroundColor: 'red'
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      )}
    </div>
  )
}

export default LiveSpeedChart
