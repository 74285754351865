import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_DRIVER_SAFETY_BY_DRIVER_ID = gql`
  query driverSafetyByDriverId($driverId: String!) {
    driverSafetyByDriverId(driverId: $driverId) {
      driverSafetyCategory
      driverSafetyScore100
    }
  }
`

const dataTransform = ({ data }) => {
  const category = data.driverSafetyCategory
  const score = data.driverSafetyScore100
  return {
    category,
    score
  }
}

const getVariables = (driverId) => {
  let variables = {
    driverId
  }
  return variables
}

export const useDriverSafetyCategory = (driverId) => {
  const [category, setCategory] = useState(null)
  const [score, setScore] = useState(null)
  const [loading, setLoading] = useState([])

  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DRIVER_SAFETY_BY_DRIVER_ID,
    {
      variables: getVariables(driverId),
      skip: !driverId,
      queryName: 'driverSafetyByDriverId',
      errorMessage: 'Failed to fetch Driver Category',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setCategory(queryData.category)
      setScore(queryData.score)
    } else {
      setCategory(null)
    }
  }, [queryData])

  return {
    category,
    score,
    loading
  }
}
