import { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { getAssetsVariables } from './useAssetIds'
import { useReactiveVar } from '@apollo/client'
import { assetFilters, filters } from '../../GraphQLClient'
import { checkVal } from '../../../Helpers/Functions'

const queryName = 'liveViewClusterAssets'
const defaultValue = []
const errorMessage = 'Failed to fetch Asset locations'
const typeName = 'Cluster'

export const clusterFragment = gql`
  fragment clusterFragment on ${typeName} {
    type
    id
    properties {
        cluster
        cluster_id
        point_count
        point_count_abbreviated
    }
    geometry {
        type
        coordinates
    }
    assetId
    assetName
    deviceId
  }
`

const QUERY = gql`
  query ${queryName} (
    $centerPoint: [Float]
    $maxDistance: Float
    $clusterRadius: Int
    $maxZoom: Int
    $boundingBox: [Float]
    $currentZoom: Int
    $orgIdsFilter: [String]
    $assetGroupId: String
    $safetyCategory: [String]
    $assetType: [String]
    $searchString: String
    $skip: Int
    $limit: Int
    ){
    ${queryName} (
      centerPoint: $centerPoint
        maxDistance: $maxDistance
        clusterRadius: $clusterRadius
        maxZoom: $maxZoom
        boundingBox: $boundingBox
        currentZoom: $currentZoom
        orgIdsFilter: $orgIdsFilter
        assetGroupId: $assetGroupId
        safetyCategory: $safetyCategory
        assetType: $assetType
        searchString: $searchString
        skip: $skip
        limit: $limit
        ){
      ...clusterFragment
    }
  }
  ${clusterFragment}
`

export const dataTransform = (data) => {
  return (
    data &&
    data.map((a) => {
      return { ...a }
    })
  )
}

export const useLiveViewClusterAssets = ({
  centerPoint,
  maxDistance,
  clusterRadius,
  maxZoom,
  currentZoom,
  boundingBox
}) => {
  const [data, setData] = useState(defaultValue)
  const { assetTypes, searchString, safetyCategories } =
    useReactiveVar(assetFilters)
  const { groupId, orgIds } = useReactiveVar(filters)
  const {
    data: queryData,
    loading,
    ...others
  } = useQuery(QUERY, {
    queryName,
    variables: {
      ...getAssetsVariables({
        groupId,
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        searchString,
        assetTypes,
        safetyCategory: safetyCategories
      }),
      skip: 0,
      limit: checkVal(searchString) ? 10 : 1000,
      centerPoint: checkVal(searchString)
        ? null
        : centerPoint && [centerPoint.lng, centerPoint.lat],
      maxDistance: checkVal(searchString) ? null : maxDistance,
      clusterRadius: checkVal(searchString) ? null : clusterRadius,
      maxZoom: checkVal(searchString) ? null : maxZoom,
      currentZoom: checkVal(searchString) ? null : currentZoom,
      boundingBox: checkVal(searchString) ? null : boundingBox
    },
    errorMessage,
    dataTransform,
    skip: !centerPoint
  })

  useEffect(() => {
    if (queryData && queryData.length > 0) setData(queryData)
    else setData(defaultValue)
  }, [queryData])

  return { data, loading, ...others }
}
