import { useEffect, useState, useMemo } from 'react'
import { UPDATE_NOTIFICATION } from '../../Graphql/Mutations'
import { useQuery as useApolloQuery, useMutation } from '@apollo/client'
import { checkVal } from '../../Helpers/Functions'
import { handleLogout } from '../Header'
import { client } from '../../Graphql/GraphQLClient'

export const useQuery = (
  QUERY,
  {
    variables,
    queryName,
    dataTransform = (data) => data,
    errorMessage,
    tempData,
    skip,
    ...queryProps
  }
) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION)

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch,
    fetchMore,
    ...props
  } = useApolloQuery(QUERY, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: tempData ? true : skip,
    // fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    ...queryProps
  })

  const actualData =
    checkVal(queryData) && checkVal(queryData[queryName])
      ? queryData[queryName]
      : null

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryError) {
      setError(queryError)
      if (queryError && queryError.message === 'TokenExpiredError') {
        handleLogout(client)
      }
      updateNotification({
        variables: {
          show: true,
          level: 'danger',
          text: errorMessage ? errorMessage : `${queryName} Failed to fetch`
        }
      })
    }
  }, [queryError, updateNotification, errorMessage, queryName])

  useEffect(() => {
    if (checkVal(actualData) || tempData) {
      setData(tempData ? dataTransform(tempData) : dataTransform(actualData))
    } else {
      setData(null)
    }
  }, [actualData])

  return { data, loading, error, fetchMore, refetch, ...props }
}
