import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const crashFragment = gql`
  fragment crashFragment on VehicleCrash {
    _id
    createdAt
    asset {
      _id
      name
      registrationNum
    }
    driver {
      _id
      firstName
      lastName
      idNumber
    }
    vehicleCrashDate
    document {
      _id
      filename
      documentType
      url
    }
    crashImages {
      _id
      filename
      documentType
      url
    }
    description
    latitude
    longitude
  }
`

export const GET_VEHICLE_CRASH = gql`
  query vehicleCrash($id: String!) {
    vehicleCrash(id: $id) {
      ...crashFragment
    }
  }
  ${crashFragment}
`

export const crashDataTransform = (data) => {
  if (data) {
    return {
      ...data
    }
  }
}

export const useCrash = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_VEHICLE_CRASH, {
    variables: { id },
    queryName: 'vehicleCrash',
    errorMessage: 'Failed to fetch Crash',
    dataTransform: (data) => crashDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
