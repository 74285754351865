import React from 'react'
import { Text, IconButton, Button, FlexBox, Icon } from '../'
import { useMutation } from '@apollo/client'
// import { GET_USER, GET_DARKMODE } from '../../Graphql/Queries'
import { UPDATE_DARKMODE } from '../../Graphql/Mutations'
import { Images, Metrics, useColors } from '../../Themes'
import { Styles } from './Styles'
import NavigationMenu from '../NavigationMenu'
import { client } from '../../Graphql/GraphQLClient'

export const handleLogout = (client) => {
  window.location = '/'
  localStorage.clear()
  client.resetStore()
}

const Header = (props) => {
  /*
  const userId = localStorage.getItem('userId')
  const { data: userData } = useQuery(GET_USER, {
    variables: { id: userId }
  })
  const user = userData ? userData.user : {}
  let role = user.userType ? user.userType.toUpperCase() : ''
  */

  // const { data } = useQuery(GET_DARKMODE)
  // const darkmode = data ? data.darkmode : true

  const [updateDarkmode] = useMutation(UPDATE_DARKMODE)
  const userName = localStorage.getItem('username')
  const Colors = useColors()

  return (
    <div
      style={{
        ...Styles.header,
        backgroundColor:
          props.darkmode || props.public
            ? Colors.darkTheme.secondary
            : Colors.lightTheme.secondary,
        backgroundImage: props.noImage
          ? 'none'
          : `url(${
              props.darkmode
                ? Images.headerBackgroundDark
                : Images.headerBackgroundDark
            })`
      }}
    >
      <div
        style={{
          ...Styles.headerInnerContainer,
          backgroundColor: props.noImage
            ? 'transparent'
            : props.darkmode
            ? 'rgba(255,255,255,0.07)'
            : 'rgba(255,255,255,0.2)'
        }}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 16
            }}
          >
            <img
              alt='ecs Logo'
              src={
                props.darkmode || props.public
                  ? Images.headerLogoDarkTheme
                  : Images.headerLogoLightTheme
              }
              style={{ ...Styles.logo }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              alt='SmartRoad Reporting'
              src={
                props.darkmode
                  ? Images.staticAridLogoDark
                  : Images.staticAridLogoDark
              }
              style={{
                height: Metrics.navBarHeight - Metrics.base * 10,
                marginLeft: Metrics.base * 4
                // width: 180
              }}
            />
          </div>
          {/*}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              // backgroundColor: 'red',
              alignItems: 'center'
            }}
          >
            <Text
              font='caption'
              marginLeft={4}
              color={
                props.darkmode || props.public
                  ? 'rgba(255,255,255,0.8)'
                  : Colors.primary
              }
            >
              | version: {Metrics.version}
            </Text>
          </div>
            */}
        </div>
        <div style={{ display: 'flex' }}>
          {!props.public && (
            <IconButton
              onClick={() => [
                localStorage.setItem('darkmode', !props.darkmode),
                updateDarkmode()
              ]}
              iconColor={
                props.darkmode
                  ? Colors.darkTheme.secondaryOpacity2
                  : Colors.lightTheme.secondaryOpacity1
              }
              iconName='brightness_medium'
            />
          )}
          {/*
          {props.public && (
            <Button
              title={'Login'}
              onClick={() => props.history.push('/login')}
              background={Colors.darkTheme.primary}
              style={{
                //marginLeft: Metrics.base * 2,
                marginRight: Metrics.base * 2
              }}
              textColor={Colors.white}
            />
          )}
          <Text>j32hewitson@gmail.com</Text>*/}
          <NavigationMenu
            links={[
              {
                title: 'Logout',
                onClick: () => handleLogout(client),
                icon: 'logout'
              }
            ]}
          >
            <FlexBox style={{ marginLeft: Metrics.base * 4 }}>
              <Icon name='person' color={Colors.secondary} size='large' />
              <Text font='title' marginLeft={1} marginRight={1}>
                {userName ? userName : 'User Menu'}
              </Text>
              <Icon
                name='expand_more'
                color={Colors.secondary}
                style={{ marginRight: Metrics.base * 4 }}
              />
            </FlexBox>
          </NavigationMenu>
        </div>
      </div>
    </div>
  )
}

export default Header
