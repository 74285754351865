import React, { useEffect, useRef, useState } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  filters,
  selectedTabs,
  selectedTripId,
  tripFilters
} from '../../../../Graphql/GraphQLClient'
import TripCard from './TripCard'
import { useTripIds } from '../../../../Graphql/Queries/Trip/useTripIds'
import { FlexBox, MaterialInput } from '../../..'
import { Metrics } from '../../../../Themes'
import { useFilters } from '../../../../Graphql/Queries/useFilters'
import { useRouteMatch } from 'react-router-dom'
import { NetworkStatus, useReactiveVar } from '@apollo/client'
import FiltersContainer from '../FiltersContainer'
import TripFilters, { handleClearFilters } from './TripFilters'
import { getValCount } from '../Event/EventFilters'
import TabRoutes from '../../../TabRoutes'
import { checkVal, removeTrailingSlashes } from '../../../../Helpers/Functions'
import { useNavigation } from '../../../CustomHooks/useNavigation'

const getActiveFiltersCount = (filters) => {
  let count = 0
  if (filters.safetyCategories && filters.safetyCategories.length > 0) count++
  count += getValCount(filters.minSafetyScore)
  count += getValCount(filters.maxSafetyScore)
  count += getValCount(filters.driverId)
  count += getValCount(filters.assetId)
  count += getValCount(filters.deviceId)
  return count
}

const TripsList = (props) => {
  const tripId = useReactiveVar(selectedTripId)
  const { data, tripIds, totalCount, fetchMore, networkStatus } = useTripIds({
    onlyScoredTrips: props.onlyScoredTrips
  })
  const [insertedTopCardId, setInsertedTopCardId] = useState(null)
  const { path } = useRouteMatch()
  const tripFiltersVar = useReactiveVar(tripFilters)
  const { tripMoreFiltersTab } = useReactiveVar(selectedTabs)
  const navigateTo = useNavigation()
  const searchInputRef = useRef()

  /** If there is no selected card, select index 0
   * If there is a selected card, and it is not visible in the list,
   * insert it at the top of the list
   */
  useEffect(() => {
    if (tripIds && tripIds.length > 0) {
      if (!tripIds.includes(tripId)) {
        if (checkVal(tripId)) {
          setInsertedTopCardId(tripId)
        } else {
          selectedTripId(tripIds[0])
          setInsertedTopCardId(null)
        }
      }
    }
  }, [tripIds])

  const { startDate, endDate } = useFilters()
  return (
    <TabRoutes
      renderTabBar={false}
      defaultId={tripMoreFiltersTab ? tripMoreFiltersTab : null}
      tabsData={[
        { id: 'select-card', name: 'Select Card' },
        { id: 'more-filters', name: 'More Filters' }
      ]}
    >
      <>
        <CardsHeader
          title='Trips'
          number={totalCount}
          items={data}
          searchInputString={tripFiltersVar && tripFiltersVar.searchString}
          setSearchInputString={(value) => [
            tripFilters({ ...tripFilters(), searchString: value }),
            selectedTripId(null)
          ]}
          searchInputRef={searchInputRef}
          moreFiltersPath={`${removeTrailingSlashes(path)}/more-filters`}
          activeFiltersCount={getActiveFiltersCount(tripFiltersVar)}
          moreFiltersTabName='tripMoreFiltersTab'
        />
        <FlexBox
          style={{
            padding: Metrics.base * 2,
            paddingTop: 0,
            paddingRight: Metrics.base * 3
          }}
        >
          <MaterialInput
            label={'Date Range'}
            type='dateBetween'
            value={{ startDate, endDate }}
            handleChange={(value) => [
              filters({
                ...filters(),
                startDate: value.startDate,
                endDate: value.endDate
              })
            ]}
          />
        </FlexBox>
        <CardsContainer fetchMore={fetchMore} networkStatus={networkStatus}>
          <CardsList
            items={data}
            itemsName='Trips'
            selectedItemId={tripId}
            insertedTopCard={
              checkVal(insertedTopCardId) && (
                <TripCard
                  data={{ _id: insertedTopCardId }}
                  tripId={insertedTopCardId}
                  key={insertedTopCardId}
                  selected={insertedTopCardId === tripId}
                  navigateTo={navigateTo}
                  borderBottom={
                    1 >= data.length || (data[1] && data[1]._id !== tripId)
                  }
                />
              )
            }
          >
            {({ items, item, index }) => {
              return insertedTopCardId !== item._id ? (
                <TripCard
                  data={item}
                  tripId={item._id}
                  key={item._id}
                  selected={item._id === tripId}
                  navigateTo={navigateTo}
                  borderBottom={
                    index + 1 >= items.length ||
                    (items[index + 1] && items[index + 1]._id !== tripId)
                  }
                />
              ) : null
            }}
          </CardsList>
        </CardsContainer>
      </>
      <FiltersContainer
        totalCount={totalCount}
        title='Trip Filters'
        loading={
          networkStatus === NetworkStatus.loading ||
          networkStatus === NetworkStatus.setVariables
        }
        searchInputRef={searchInputRef}
        moreFiltersTabName='tripMoreFiltersTab'
        handleClearFilters={handleClearFilters}
        returnToResultsPath={`${removeTrailingSlashes(path)}/select-card`}
      >
        <TripFilters searchInputRef={searchInputRef} />
      </FiltersContainer>
    </TabRoutes>
  )
}

export default TripsList
