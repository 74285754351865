import { Metrics } from '../Themes'
import numbro from 'numbro'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export const getUrlId = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return Number(urlParams.get('id'))
}

export const getUrlParam = (name) => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(name)
}

export function normalizeFixCaret(e) {
  setTimeout(() => {
    e.target.selectionStart = e.target.value.length
  })
}

export const debounce = (func, wait, immediate) => {
  var timeout

  return function executedFunction() {
    var context = this
    var args = arguments

    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

/*
 * Always use checkVal when checking if a value exists
 * Issue with if(val) or val &&, is they evaluate to false when val is 0
 */
export const checkVal = (val) => {
  return !(
    val === '' ||
    typeof val === 'undefined' ||
    val === null ||
    (typeof val === 'number' && isNaN(val))
  )
}

/**
 * @param {string} url
 */
export const removeTrailingSlashes = (url) => {
  return url.replace(/\/+$/, '') //Removes one or more trailing slashes from URL
}

export const removeDuplicateObjects = (array, key) => {
  var a = array.concat()
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i][key] === a[j][key]) a.splice(j--, 1)
    }
  }
  return a
}

export function scroll(scrollTo, offset, timeout) {
  setTimeout(() => {
    let scrollDistance = scrollTo

    if (isNaN(scrollDistance)) {
      scrollDistance =
        document.getElementById(scrollTo).offsetTop -
        (offset || Metrics.base * 2)
    }

    window.scroll({ top: scrollDistance, left: 0, behavior: 'smooth' })
  }, timeout || 0)
}

export function filter(data, key, term) {
  let filteredData = data.filter(
    (item) => item[key].toLowerCase().indexOf(term.toLowerCase()) > -1
  )

  return filteredData.length > 0 ? filteredData : 0
}

export function sort(data, key, order) {
  return data.sort((a, b) =>
    order === 'desc' ? b[key] - a[key] : a[key] - b[key]
  )
}

export const getSelectedObject = ({ id, key, list }) => {
  const r = list.filter((a) => a[key] === id)
  if (!r || r.length === 0) return null
  return r[0]
}

export const findSelectedObject = ({ id, key, list }) => {
  for (var i in list) {
    if (id === list[i][key]) return { object: list[i], index: i }
  }
  return { object: null, index: -1 }
}

export function toRand(value) {
  let randValue = value / 100
  return 'R' + randValue.toFixed(2)
}

export function getYears() {
  let years = []
  const year = new Date().getFullYear()
  for (let i = 0; i < 10; i++) {
    years.push(year + i)
  }
  return years
}

export function centsToRands(price) {
  return parseFloat(price / 100).toFixed(2)
}

export function dateConversion(date) {
  let res = date.split('-')
  return res[2] + '/' + res[1] + '/' + res[0]
}

export function textBooleanConverter(bool) {
  if (typeof bool === 'boolean') {
    return bool
  }
  switch (bool) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return false
  }
}

export function mustBeTrueOrFalse(bool) {
  if (bool === 'true' || bool === 'false') {
    return true
  } else {
    return false
  }
}

export function thousandsSeparator(num) {
  let number = num.toString()
  let decimals = 0
  if (number.includes('.')) {
    decimals = 2
  }
  return numbro(parseFloat(number)).format({
    thousandSeparated: true,
    mantissa: decimals
  })
}

export function thousandsSep(num) {
  if (num < 1000) {
    if (num < 100) {
      if (num < 10) {
        if (num < 0) {
          if (num > -10) {
            return (
              '-0.00' + -num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            )
          }
          if (num > -100) {
            return (
              '-0.0' + -num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            )
          }
          return '-0.' + -num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        }
        return '0.00' + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      return '0.0' + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
    return '0.' + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  } else {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export function formatDateTime(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var seconds = date.getSeconds()
  // var milli = date.getMilliseconds()
  var day = date.getDate()
  var month = date.getMonth() + 1
  day = day < 10 ? '0' + day : day
  month = month < 10 ? '0' + month : month
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  // milli = milli < 10 ? '00' + milli : milli < 100 ? '0' + milli : milli
  var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm
  // + '.' + milli + ' ' + ampm
  return strTime + ', ' + day + '/' + month + '/' + date.getFullYear()
}

export function formatDate(data) {
  let date = new Date(data)
  var day = date.getDate()
  var month = date.getMonth() + 1
  day = day < 10 ? '0' + day : day
  month = month < 10 ? '0' + month : month
  return day + '/' + month + '/' + date.getFullYear()
}

export const hhmmss = (durationSeconds) => {
  if (durationSeconds) {
    let newTime = new Date(durationSeconds * 1000)
    newTime = newTime.toISOString().substr(11, 8)
    return newTime
  }
}

export const timeUntilDate = ({ dateStart, dateEnd }) => {
  let dateUntil = new Date(dateEnd)
  const start = dateStart ? new Date(dateStart) : new Date()

  const one_day = 1000 * 60 * 60 * 24
  const daysLeft = Math.ceil((dateUntil.getTime() - start.getTime()) / one_day)

  return daysLeft
}

export function getCaptureText(captureType) {
  return captureType === 'FIXED_IMAGE'
    ? 'Fixed Images'
    : captureType === 'VIDEO'
    ? 'Video'
    : captureType === 'TIMELAPSE'
    ? 'Timelapse'
    : null
}

export function calcTolerance(perc, quantity) {
  return Math.round(Math.abs(Number(perc) * 10 * Number(quantity)), 3)
}

export function getVolumeDelivered(documents) {
  let volDelivered = 0
  let flag = false
  for (let i = 0; i < documents.length; i++) {
    let doc = documents[i]
    if (doc.type === 'WEIGHBRIDGE_SLIP' || doc.type === 'FOLIO_TRANSFER') {
      flag = true
      volDelivered += parseFloat(doc.value)
    }
  }
  if (flag) return volDelivered.toFixed(1)
  else return ''
}

export function formatTimestamp(d, full = true) {
  var date = new Date(parseInt(d))
  // 24 HOUR -------------------------
  var hours = date.getHours()
  var minutes = date.getMinutes()
  // var seconds = date.getSeconds()
  // var milli = date.getMilliseconds()
  var day = date.getDate()
  var month = date.getMonth() + 1
  day = day < 10 ? '0' + day : day
  month = month < 10 ? '0' + month : month
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  // seconds = seconds < 10 ? '0' + seconds : seconds
  // milli = milli < 10 ? '00' + milli : milli < 100 ? '0' + milli : milli
  var strTime = hours + ':' + minutes //+ ':' + seconds + ':' + milli
  if (full) return date.getFullYear() + '/' + month + '/' + day + ', ' + strTime
  else return date.getFullYear() + '/' + month + '/' + day
  // return strTime + ', ' + day + '/' + month + '/' + date.getFullYear()
  // ---------------------------------
  // 12 HOUR -------------------------
  // var hours = date.getHours()
  // var minutes = date.getMinutes()
  // var seconds = date.getSeconds()
  // // var milli = date.getMilliseconds()
  // var day = date.getDate()
  // var month = date.getMonth() + 1
  // day = day < 10 ? '0' + day : day
  // month = month < 10 ? '0' + month : month
  // var ampm = hours >= 12 ? 'PM' : 'AM'
  // hours = hours % 12
  // hours = hours ? hours : 12 // the hour '0' should be '12'
  // minutes = minutes < 10 ? '0' + minutes : minutes
  // seconds = seconds < 10 ? '0' + seconds : seconds
  // // milli = milli < 10 ? '00' + milli : milli < 100 ? '0' + milli : milli
  // var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm
  // // + '.' + milli + ' ' + ampm
  // return date.getFullYear() + '/' + month + '/' + day + ', ' + strTime
}

export function getDate() {
  let today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 //January is 0!
  let yyyy = today.getFullYear()

  dd < 10
    ? (dd = '0' + dd)
    : mm < 10
    ? (mm = '0' + mm)
    : (today = dd + '/' + mm + '/' + yyyy)
  return today
}
const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
export function getDateInWords() {
  let today = new Date()
  let dd = today.getDate()
  let mm = month[today.getMonth()] //January is 0!
  let yyyy = today.getFullYear()

  today =
    dd === 1
      ? mm + ' ' + dd + 'st, ' + yyyy
      : dd === 2
      ? mm + ' ' + dd + 'nd, ' + yyyy
      : dd === 3
      ? mm + ' ' + dd + 'rd, ' + yyyy
      : dd === 21
      ? mm + ' ' + dd + 'st, ' + yyyy
      : dd === 22
      ? mm + ' ' + dd + 'nd, ' + yyyy
      : dd === 23
      ? mm + ' ' + dd + 'rd, ' + yyyy
      : dd === 31
      ? mm + ' ' + dd + 'st, ' + yyyy
      : mm + ' ' + dd + 'th, ' + yyyy
  return today
}

export function getDateInWordsProp(date) {
  let fullDate
  let dd = date.getDate()
  let mm = month[date.getMonth()] //January is 0!
  let yyyy = date.getFullYear()

  fullDate =
    dd === 1
      ? mm + ' ' + dd + 'st, ' + yyyy
      : dd === 2
      ? mm + ' ' + dd + 'nd, ' + yyyy
      : dd === 3
      ? mm + ' ' + dd + 'rd, ' + yyyy
      : dd === 21
      ? mm + ' ' + dd + 'st, ' + yyyy
      : dd === 22
      ? mm + ' ' + dd + 'nd, ' + yyyy
      : dd === 23
      ? mm + ' ' + dd + 'rd, ' + yyyy
      : dd === 31
      ? mm + ' ' + dd + 'st, ' + yyyy
      : mm + ' ' + dd + 'th, ' + yyyy
  return fullDate
}
/**
 * Replace all occurences in a string
 * @param {String} str the string that contains the things to be replace
 * @param {String} find the thing to replace
 * @param {String} replace the thing it will be replaced with
 */
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

export function blobToFile(blob, filename) {
  blob.lastModified = new Date()
  blob.name = filename
  blob.__proto__ = File.prototype
  return blob
}
/**
 * Converts A camel case string to title case
 * @param {String} str the string to convert
 */
export function camelToTitle(str) {
  var result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function formatCellNumber(str) {
  const phoneNumber = parsePhoneNumberFromString(str)

  return phoneNumber.formatInternational()
}

export function validateEmail(value) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
}

export function validateID(id) {
  let idNumber = id.toString()
  let idValid = true
  let idError = ''
  if (idNumber.length !== 13 || !isNumber(idNumber)) {
    idError = 'ERROR: ID number invalid - not a valid number'

    idValid = false
  }

  // get first 6 digits as a valid date
  let tempDate = new Date(
    idNumber.substring(0, 2),
    idNumber.substring(2, 4) - 1,
    idNumber.substring(4, 6)
  )

  let id_date = tempDate.getDate()
  let id_month = tempDate.getMonth()
  let id_year = tempDate.getFullYear()

  let fullDate =
    (id_date < 10 ? '0' + id_date : id_date) +
    '-' +
    (id_month < 10 ? '0' + (id_month + 1) : id_month + 1) +
    '-' +
    id_year
  if (
    !(
      tempDate.getYear() === parseInt(idNumber.substring(0, 2)) &&
      id_month === parseInt(idNumber.substring(2, 4) - 1) &&
      id_date === parseInt(idNumber.substring(4, 6))
    )
  ) {
    idError = 'ERROR: ID number invalid - date numbers not valid'

    idValid = false
  }

  // get the gender
  let genderCode = idNumber.substring(6, 10)
  let gender = parseInt(genderCode) < 5000 ? 'Female' : 'Male'

  // get country ID for citizenship
  let citizenship = parseInt(idNumber.substring(10, 11)) === 0 ? 'Yes' : 'No'

  // apply Luhn formula for check-digits
  let tempTotal = 0
  let checkSum = 0
  let multiplier = 1
  for (let i = 0; i < 13; ++i) {
    tempTotal = parseInt(idNumber.charAt(i)) * multiplier
    if (tempTotal > 9) {
      tempTotal =
        parseInt(tempTotal.toString().charAt(0)) +
        parseInt(tempTotal.toString().charAt(1))
    }
    checkSum = checkSum + tempTotal
    multiplier = multiplier % 2 === 0 ? 1 : 2
  }
  if (checkSum % 10 !== 0) {
    idError = 'ERROR: ID number invalid - check digit is not valid'

    idValid = false
  }
  return { idValid, idError, fullDate, gender, citizenship }
}

const componentToHex = (c) => {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null
}

export const getRgb = (color) => {
  const rgbAarr = color.replace(/[^\d,.]/g, '').split(',')
  return {
    r: parseInt(rgbAarr[0]),
    g: parseInt(rgbAarr[1]),
    b: parseInt(rgbAarr[2])
  }
}

export const exportExcelData = (csvData) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const ws = XLSX.utils.json_to_sheet(csvData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, 'defect-export' + fileExtension)
}

export const abbreviateNumber = (number) => {
  var SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E']
  var tier = (Math.log10(Math.abs(number)) / 3) | 0
  if (tier === 0) return number
  var postfix = SI_POSTFIXES[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  var formatted = scaled.toFixed(1) + ''
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2)
  return formatted + postfix
}

export const convertToGMT = (time) => {
  var d = new Date()
  var n = d.getTimezoneOffset()
  return parseInt(time) + n * 60000
}

export const convertFromTimezone = (time, gmtOffset = 0) => {
  var d = new Date()
  var n = d.getTimezoneOffset()
  return parseInt(time) - 3600000 * gmtOffset - 3600000 * (n / 60)
}

export const convertToTimezone = (time, gmtOffset = 0) => {
  return convertToGMT(parseInt(time)) + 3600000 * gmtOffset
}
