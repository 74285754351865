import React from 'react'
import { Metrics, useColors } from '../../Themes'
import Text from '../Text'
import Status from '../Status'
import { FlexBox, Icon } from '..'
import { useNavigation } from '../CustomHooks/useNavigation'
import SelectedObjectHeader from './SelectedObjectHeader'
import { checkVal } from '../../Helpers/Functions'
import { selectedTripId } from '../../Graphql/GraphQLClient'
import { useTripSafetyCategory } from '../../Graphql/Queries/Trip/useTripSafetyCategory'
import TripCardTTList from './SelectionPanel/Trip/TripCardTTList'
import { useTripCardData } from '../../Graphql/Queries/Trip/useTripCardData'

const TripHeader = ({ tripId }) => {
  const Colors = useColors()
  const { category, loading: categoryLoading } = useTripSafetyCategory(tripId)
  const { data, loading: tripLoading } = useTripCardData(tripId)
  const navigateTo = useNavigation()
  return (
    <SelectedObjectHeader
      id={tripId}
      handleClose={() => selectedTripId(null)}
      navigateTo={navigateTo}
      TooltipContent={({ setShowTooltip }) => (
        <TripCardTTList
          tripId={tripId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      loading={tripLoading}
      statusLoading={categoryLoading}
      title={`Trip ${data && data.displayId}`}
      // centerContent={<Text color='white'>{data && data.description}</Text>}
      rightContent={
        <FlexBox direction='row'>
          {/*data && checkVal(data.assetName) ? (
            <Text font='caption' color={Colors.backgroundOpacity1}>
              Asset: {data.assetName}
            </Text>
          ) : (
            <FlexBox>
              <Text
                marginRight={1 / 2}
                color={Colors.backgroundOpacity1}
                font='caption'
              >
                No linked asset
              </Text>
              <Icon
                size='small'
                color={Colors.backgroundOpacity1}
                name='error'
              />
            </FlexBox>
          )*/}
          <FlexBox
            style={{
              marginLeft: Metrics.base * 2,
              borderRadius: Metrics.radius,
              backgroundColor: Colors.background
            }}
          >
            <Status
              status={category ? category : 'no safety score'}
              getStatusColors={({ status }) =>
                Colors[status] ? Colors[status] : Colors.textSecondary
              }
            />
          </FlexBox>
        </FlexBox>
      }
    />
  )
}

export default TripHeader
