import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Colors, Metrics } from '../../Themes'
import { Icon, Text, Row, Col } from '../'
import { inlineStyles } from './Styles'

export default class EmptyState extends Component {
  render() {
    const { icon, title, body, light, noHeight } = this.props

    return (
      <Row
        center='xs'
        middle='xs'
        style={!noHeight ? inlineStyles.mainContainer : null}
      >
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: Metrics.window.height
          }}
        >
          <Icon
            name={icon}
            color={light ? Colors.white : Colors.grey3}
            style={inlineStyles.icon}
          />
          <Text
            tag='h1'
            font='display'
            color={light ? Colors.white : Colors.font}
            align='center'
            marginTop={0.5}
          >
            {title}
          </Text>
          <Text
            tag='h2'
            color={light ? Colors.white : Colors.grey3}
            align='center'
            marginTop={1}
          >
            {body}
          </Text>
        </Col>
      </Row>
    )
  }
}

EmptyState.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  light: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}

EmptyState.defaultProps = {
  icon: 'visibility_off',
  title: 'Nothing to see here',
  body: 'No relating results found...',
  light: false
}
