import React, { useEffect, useState } from 'react'
import { FlexBox, MaterialInput, Status } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { NetworkStatus } from '@apollo/client'
import { Table } from '../../../Components/Table'
import { checkVal, thousandsSeparator } from '../../../Helpers/Functions'
import { useDrivers } from '../../../Graphql/Queries/Driver/useDrivers'
import { Heading } from '../../../Components/Typography'
import Button from '../../../Components/Button'
import { useTopAndBottomDrivers } from '../../../Graphql/Queries/Driver/useTopAndBottomDrivers'
import { getScoreCategory } from '../../Safety/Asset/Breakdown'
import { useNavigation } from '../../../Components/CustomHooks/useNavigation'
import { selectedDriverId } from '../../../Graphql/GraphQLClient'

const driverColumns = (Colors) => [
  {
    Header: 'Score',
    accessor: 'safetyScore',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Name',
    accessor: 'name',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Display ID',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Status',
    accessor: 'driverSafetyCategory',
    Aggregated: ({ cell: { value } }) => `-`,
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      )
  }
]

const getPath = (safetyCategory) => {
  if (safetyCategory === 'driverSafetyScore100') return '/safety-score/drivers'
  if (safetyCategory === 'tripSafetyMean100')
    return '/safety-score/drivers/driver-behaviour'
  if (safetyCategory === 'speedingCategoryMean100')
    return '/safety-score/drivers/driver-behaviour/speeding-score'
  if (safetyCategory === 'smoothDrivingCategoryMean100')
    return '/safety-score/drivers/driver-behaviour/smooth-driving-score'
  if (safetyCategory === 'restedFocusedCategoryMean100')
    return '/safety-score/drivers/driver-behaviour/rested-focused-score'
  if (safetyCategory === 'complianceCategoryMean100')
    return '/safety-score/drivers/driver-behaviour/compliant-score'
  if (safetyCategory === 'generalSafetyCategoryMean100')
    return '/safety-score/drivers/driver-behaviour/contextual-score'
  return '/safety-score/drivers'
}

const _handleRowClick = ({ row, navigateTo, safetyCategory }) => {
  selectedDriverId(row.original.driverId)
  navigateTo({
    pathname: getPath(safetyCategory),
    queryParams: [{ name: 'driver', id: row.original.driverId }]
  })
  window.scrollTo(0, 0)
}

const getBorderLeftStyles = (cell, Colors) => {
  if (cell.row.original.safetyScore)
    return {
      borderLeft: `5px solid ${
        Colors[getScoreCategory(cell.row.original.safetyScore)]
      }`
    }
  return {}
}

const getBackgroundBarStyles = (row, Colors) => {
  if (row.original.safetyScore)
    return {
      backgroundColor: Colors[getScoreCategory(row.original.safetyScore)],
      width: `${row.original.safetyScore}%`
    }
  return {}
}

const orderByOptions = [
  { value: 'DESC', label: 'Top Drivers' },
  { value: 'ASC', label: 'Problem Drivers' }
]

const scoreOptions = [
  { value: 'driverSafetyScore100', label: 'Combined Safety Score' },
  { value: 'tripSafetyMean100', label: 'Driving Behaviour Score' },
  { value: 'speedingCategoryMean100', label: 'Speeding Score' },
  { value: 'smoothDrivingCategoryMean100', label: 'Smooth Driving Score' },
  { value: 'restedFocusedCategoryMean100', label: 'Rested/Focused Score' },
  { value: 'complianceCategoryMean100', label: 'Compliant Score' },
  { value: 'generalSafetyCategoryMean100', label: 'Contextual Score' }
]

const DriverRankingTable = () => {
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const Colors = useColors()
  const [orderBy, setOrderBy] = useState('DESC')
  const [safetyCategory, setSafetyCategory] = useState('driverSafetyScore100')
  const navigateTo = useNavigation()

  const {
    data: driversData,
    totalCount,
    fetchMore,
    networkStatus,
    loading
  } = useTopAndBottomDrivers({ orderBy, safetyCategory })

  const driversLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  useEffect(() => {
    if (driversData && driversData.length > 0) {
      setData(driversData)
    } else {
      setData([])
    }
  }, [driversData])

  useEffect(() => {
    if (
      checkVal(totalCount) &&
      totalCount > 0 &&
      checkVal(pageSize) &&
      pageSize > 0
    ) {
      const tempPageCount = Math.ceil(totalCount / pageSize)
      setPageCount(tempPageCount)
      setPageIndex(Math.min(tempPageCount - 1, pageIndex))
    } else {
      setPageCount(1)
      setPageIndex(0)
    }
  }, [totalCount, pageSize])

  return (
    <Table
      style={{ width: '100%' }}
      handleRowClick={(row) =>
        _handleRowClick({ row, navigateTo, safetyCategory })
      }
      loading={driversLoading}
      fetchingMore={fetchingMore}
      fetchMore={fetchMore}
      totalCount={totalCount}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      manualPagination={true}
      setPageIndex={setPageIndex}
      columns={driverColumns(Colors)}
      hideColumnFilters
      hideNewButton
      hideAssignButton
      showRefreshButton
      dataState={dataState}
      setData={setData}
      title={'Drivers'}
      sortBy={[{ id: 'safetyScore', desc: orderBy === 'DESC' }]}
      defaultPageSize={10}
      getBackgroundBarStyles={(row) => getBackgroundBarStyles(row, Colors)}
      getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
      renderHeading={() => (
        <FlexBox
          style={{
            width: '100%',
            height: Metrics.base * 10,
            padding: Metrics.base * 2,
            justifyContent: 'space-between'
          }}
        >
          <Heading>
            {checkVal(totalCount)
              ? `${thousandsSeparator(totalCount)} Scored Drivers`
              : 'Drivers'}
          </Heading>
        </FlexBox>
      )}
      subHeaderContent={
        <FlexBox
          fillSpace
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base,
            paddingLeft: Metrics.base * 2
          }}
        >
          <MaterialInput
            type='radio'
            name='topOrProblemSelect'
            direction='row'
            value={orderBy}
            radioValues={orderByOptions.map((option) => option.value)}
            radioDisplayValues={orderByOptions.map((option) => option.label)}
            handleChange={(value) => setOrderBy(value)}
          />
          <MaterialInput
            type='select'
            name='safetScoreTypeSelect'
            value={safetyCategory}
            selectValues={scoreOptions.map((option) => option.value)}
            selectDisplayValues={scoreOptions.map((option) => option.label)}
            handleChange={(value) => setSafetyCategory(value)}
          />
        </FlexBox>
      }
    />
  )
}

export default DriverRankingTable
