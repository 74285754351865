import React, { useState } from 'react'
import { NavButton, Subsection, Divider, IconButton, FlexBox, Text } from '../'
import { Images, Metrics, useColors } from '../../Themes'
import { useMutation } from '@apollo/client'
import { UPDATE_SIDE_PANEL_EXPANDED } from '../../Graphql/Mutations'
import {
  SidebarPrimaryLinks,
  SidebarSecondaryLinks
} from '../../Containers/App/Routes'
import { useHistory, useRouteMatch } from 'react-router-dom'

const changePath = (history, path) => {
  history.push(path)
}

const NavSidePanel = ({ sidePanelExpanded }) => {
  const history = useHistory()
  const match = useRouteMatch()
  const Colors = useColors()
  const [updateSidePanelExpanded] = useMutation(UPDATE_SIDE_PANEL_EXPANDED)
  const activePage = match.url.split(/\/|\?/)[1]
  const selectedStyle = {
    color: Colors.secondary,
    backgroundColor: Colors.selectHighlight,
    borderRadius: Metrics.base
  }
  const renderLinks = (links) => {
    return links.map((link, index) => {
      link.style = '/' + activePage === link.path ? selectedStyle : null
      return (
        <div key={link.path} style={{ width: '100%' }}>
          <NavButton
            title={link.title}
            key={link.path}
            iconName={link.icon}
            iconColor={link.color ? link.color : null}
            style={link.style}
            onClick={
              link.onClick ? link.onClick : () => changePath(history, link.path)
            }
            expanded={sidePanelExpanded}
          >
            {link.title}
          </NavButton>
          {/*<Divider color={Colors.background} />*/}
        </div>
      )
    })
  }
  return (
    <Subsection
      style={{
        height: Metrics.window.height, // - Metrics.navBarHeight,
        flexDirection: 'column',
        position: 'fixed',
        width: sidePanelExpanded ? Metrics.base * 27 : Metrics.base * 8,
        backgroundColor: Colors.subsectionBackground, // Colors.subsectionBackgroundOpacity3,
        // border: `2px solid ${Colors.subsectionBackground}`,
        borderLeft: 'none',
        borderRadius: 0,
        borderRight: `1px solid ${Colors.dividerColor}`,
        zIndex: 1,
        boxShadow: 'none'
      }}
    >
      {/*}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%'
          // height: Metrics.base * 4
        }}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
              marginTop: Metrics.base * 7 - Metrics.base * 2,
              marginLeft: -(Metrics.base * 2),
              borderRadius: Metrics.base * 2,
              height: Metrics.base * 4,
              width: Metrics.base * 4,
              backgroundColor: Colors.subsectionBackground,
              border: `1px solid ${Colors.background}`
              // boxShadow: Utils.boxShadowLight
            }}
          >
            <IconButton
              iconName={
                sidePanelExpanded
                  ? 'keyboard_arrow_left'
                  : 'keyboard_arrow_right'
              }
              onClick={() => {
                // props.setSidePanelExpanded(
                // props.sidePanelExpanded ? false : true
                // )
                updateSidePanelExpanded()
              }}
              // iconColor={Colors.backgroundTint}
            ></IconButton>
          </div>
        </div>
      </div>
            */}
      <a href='/dashboard'>
        <img
          width={40}
          style={{
            marginTop: Metrics.base * 2,
            marginBottom: Metrics.base * 2
          }}
          alt='Cybele Emblem'
          src={Images.CybeleEmblemBeta}
        ></img>
      </a>
      {renderLinks(SidebarPrimaryLinks)}
      <FlexBox
        direction='column'
        style={{
          justifyContent: 'flex-end',
          marginTop: Metrics.base,
          marginBottom: Metrics.base,
          paddingBottom: Metrics.base * 2,
          borderRadius: Metrics.radius,
          flex: 1,
          width: '100%',
          backgroundColor: Colors.subsectionBackgroundOpacity3 //'rgba(255,255,255,0.8)'
        }}
      >
        {/* <Text
          font='body' // 'small'
          marginBottom={3}
          style={{ transform: 'rotate(-90deg)', opacity: 0.8 }}
        >
          <i>Beta</i>
        </Text> */}
        <FlexBox
          style={{
            height: 170,
            width: Metrics.base * 5
            // backgroundColor: 'red'
          }}
        >
          <img
            width={170}
            style={{ transform: 'rotateZ(-90deg)', opacity: 1 }}
            alt='Cybele Logotype'
            src={Images.CybeleLogotype}
          ></img>
        </FlexBox>
      </FlexBox>
      {/*<Divider color={Colors.background} />*/}
      {renderLinks(SidebarSecondaryLinks)}
      <div style={{ width: '100%' }}>
        <NavButton
          iconName={sidePanelExpanded ? 'chevron_left' : 'chevron_right'}
          title={sidePanelExpanded ? 'collapse' : 'expand'}
          style={{ backgroundColor: Colors.background }}
          onClick={() => updateSidePanelExpanded()}
          expanded={false}
        />
      </div>
    </Subsection>
  )
}

export default React.memo(NavSidePanel)
