import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useStatusUpdated } from '../Subscriptions/useStatusUpdated'
import { statusDataTransform } from './useStatusByDeviceId'

export const GET_STATUS_BY_DEVICE_ID = gql`
  query statusByDeviceId($internalDeviceId: String) {
    statusByDeviceId(internalDeviceId: $internalDeviceId) {
      vehicleNotMoving
      speed
      ignition
    }
  }
`
export const useStatusSummary = (internalDeviceId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    error
  } = useQuery(GET_STATUS_BY_DEVICE_ID, {
    variables: { internalDeviceId },
    queryName: 'statusByDeviceId',
    errorMessage: 'Failed to fetch Status',
    dataTransform: (data) => statusDataTransform({ data }),
    skip: !internalDeviceId
  })

  // console.log(error)

  const { data: subscriptionData, loading: subscriptionLoading } =
    useStatusUpdated(internalDeviceId)

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  useEffect(() => {
    subscriptionData && setData(subscriptionData)
  }, [subscriptionData])

  return { data, loading }
}
