import { StyleSheet } from 'aphrodite'

export const Styles = ({
  Colors,
  Metrics,
  background,
  primary,
  backgroundColor,
  condensed
}) =>
  StyleSheet.create({
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: condensed ? Metrics.base * 3.5 : Metrics.base * 5,
      paddingLeft: condensed ? Metrics.base * 2 : Metrics.base * 4,
      paddingRight: condensed ? Metrics.base * 2 : Metrics.base * 4,
      cursor: 'pointer',
      // borderWidth: 2,
      // borderStyle: 'solid',
      minWidth: Metrics.base,
      transition: Metrics.speed.lightning,
      // border: 'none',
      borderRadius: 5,
      border:
        primary || !background
          ? 'none'
          : `1px solid ${condensed ? Colors.dividerColor : Colors.primary}`,

      ':enabled': {
        backgroundColor: backgroundColor
      },
      ':focus': {
        outline: 0
      },
      ':hover:enabled': {
        background: condensed ? Colors.selectHighlight : Colors.primaryOpacity1
        // boxShadow:
        //  '0 3px 1px rgba(47,137,128,0.1),0 4px 8px rgba(15, 48, 44,0.3),0 0 0 1px rgba(47,137,128,0.02)'
      }
    },
    disabled: {
      cursor: 'not-allowed',
      backgroundColor: Colors.backgroundOpacity2,
      border: 'none'
      // border: `1px solid ${Colors.primary}`
    },
    icon: {
      marginRight: Metrics.spacing
    },
    image: {
      marginRight: Metrics.spacing,
      width: 28,
      height: 28
    },
    iconCenter: {
      marginRight: 0
    },
    iconRight: {
      marginLeft: Metrics.base
    }
  })
