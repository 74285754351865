import React, { useEffect } from 'react'
import FlexBox from '../../FlexBox'
import { Images, Metrics, useColors } from '../../../Themes'
import Text from '../../Text'
import { Title } from '../../Typography'
import Status from '../../Status'
import Card, { TooltipMenuItem } from './Cards/Card'
import { selectedServiceId } from '../../../Graphql/GraphQLClient'
import { hhmmss, formatDateTime } from '../../../Helpers/Functions'
import { useService } from '../../../Graphql/Queries/useService'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Link } from '@material-ui/core'

const ServiceCard = ({
  serviceId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom
}) => {
  const Colors = useColors()
  const { data: service, loading: serviceLoading } = useService(serviceId)
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const urlSearchParams = new URLSearchParams(location.search)

  return (
    <Card
      data={service}
      selected={selected}
      onClick={() => [
        selectedServiceId(selected ? null : service.data._id)
        //   urlSearchParams.set('event-id', service.data._id),
        //   history.push({
        //     search: urlSearchParams.toString()
        //   })
      ]}
      TooltipContent={({ setShowTooltip }) => (
        <FlexBox direction='column' style={{ width: 200 }}>
          <TooltipMenuItem
            text='View in detail'
            Colors={Colors}
            // onClick={() => {
            //   urlSearchParams.set('event-id', service.data._id)
            //   history.push({
            //     pathname: '/trip-history',
            //     search: urlSearchParams.toString()
            //   })
            // }}
          />
          <TooltipMenuItem text='item 2' Colors={Colors} />
          <TooltipMenuItem text='item 3' Colors={Colors} />
        </FlexBox>
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={serviceLoading}
      titleContent={
        <>
          {/* {service.data.type && (
            <img
              width={25}
              style={{ marginRight: Metrics.base }}
              alt={`${service.data.type}_icon`}
              src={Images[service.data.type]}
            />
          )} */}
          <Title style={{ marginLeft: Metrics.base }} color={Colors.primary}>
            {service.data &&
              service.data.vehicleServicedDate &&
              formatDateTime(new Date(service.data.vehicleServicedDate))}
          </Title>
        </>
      }
      bodyContent={
        <Text
          marginBottom={1}
          marginTop={1 / 2}
          style={{ paddingRight: Metrics.base }}
        >
          {service.data && service.data._id
            ? 'Service ID: ' + service.data._id
            : 'Service ID: -'}{' '}
        </Text>
      }
      topRightContent={
        <Status
          status={
            service.data &&
            service.data.vehicleServicedDate &&
            formatDateTime(new Date(service.data.vehicleServicedDate))
          }
          getStatusColors={() => 'green'}
        ></Status>
      }
      topRightLoading={serviceLoading}
      bottomLeftContent={
        service.data &&
        service.data.serviceDocument &&
        service.data.serviceDocument.url ? (
          <Link
            underline='always'
            color={Colors.primary}
            style={{
              color: Colors.primary,
              marginBottom: Metrics.base * 2,
              marginRight: Metrics.base / 2,
              marginLeft: Metrics.base / 2
            }}
            target='_blank'
            href={service.data.serviceDocument.url}
          >
            <Text color={Colors.primary}>
              {service.data.serviceDocument.filename}
            </Text>
          </Link>
        ) : (
          ''
        )
      }
    />
  )
}

export default ServiceCard
