import { Grid } from '@material-ui/core'
import React from 'react'
import { FlexBox } from '..'
import { checkVal } from '../../Helpers/Functions'
import { Colors, Metrics } from '../../Themes'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'

const getNumColumns = (
  appWidth,
  maxColumnsMobile,
  maxColumnsTablet,
  maxColumns,
  numItems
) => {
  const numColumns =
    appWidth < Metrics.widthBreakpoint2
      ? maxColumnsMobile
      : appWidth < Metrics.widthBreakpoint1
      ? maxColumnsTablet
      : maxColumns
  return Math.min(numItems, numColumns)
}

const getPaddingRight = (spacing, numColumns, numItems, index) => {
  if ((index + 1) % numColumns === 0) return 0
  if (index === numItems - 1) return 0
  return spacing
}

const getPaddingLeft = (spacing, numColumns, numItems, index) => {
  if (index === 0) return 0
  if (numColumns === 1) return 0
  if ((index + 1) % numColumns === 1) return 0
  return spacing
}

const getPaddingBottom = (spacing, numColumns, numItems, index) => {
  const numRows = Math.ceil(numItems / numColumns)
  if (index + 1 > numRows * numColumns - numColumns) return 0
  // if (Math.ceil(numRows / (index + 1)) === numColumns) return 0
  return spacing
}

const getPaddingTop = (spacing, numColumns, numItems, index) => {
  if (index < numColumns) return 0
  return spacing
}

const ResponsiveGrid = ({
  children,
  spacing = Metrics.base,
  itemStyle,
  maxColumns = 3,
  maxColumnsMobile = 1,
  maxColumnsTablet = 2,
  layout,
  layoutTablet,
  layoutMobile,
  style
}) => {
  const { width: appWidth } = useAppDimensions()
  const renderItems = []
  if (children.length > 0) {
    for (var i = 0; i < children.length; i++) {
      if (children[i] !== null && typeof children[i] === 'object') {
        renderItems.push(children[i])
      }
    }
  }
  const numColumns = getNumColumns(
    appWidth,
    maxColumnsMobile,
    maxColumnsTablet,
    maxColumns,
    renderItems.length
  )
  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      style={{
        // width: '100%',
        // height: '100%',
        // backgroundColor: 'red',
        // alignItems: 'center',
        // justifyContent: 'center',
        ...style
      }}
      // spacing={0}
    >
      {renderItems &&
        renderItems.map((item, index) => (
          <Grid
            container
            // item
            //alignContent='stretch'
            justify='center'
            alignItems='center'
            xs={
              layout && layout.length === renderItems.length
                ? appWidth > Metrics.widthBreakpoint1
                  ? layout[index]
                  : appWidth > Metrics.widthBreakpoint2 && layoutTablet
                  ? layoutTablet[index]
                  : layoutMobile
                  ? layoutMobile[index]
                  : 12 / numColumns
                : 12 / numColumns
            }
            key={index}
            style={{
              // display: 'flex',
              paddingRight: getPaddingRight(
                spacing,
                numColumns,
                renderItems.length,
                index
              ),
              paddingLeft: getPaddingLeft(
                spacing,
                numColumns,
                renderItems.length,
                index
              ),
              paddingBottom: getPaddingBottom(
                spacing,
                numColumns,
                renderItems.length,
                index
              ),
              paddingTop: getPaddingTop(
                spacing,
                numColumns,
                renderItems.length,
                index
              ),
              height: '100%',
              ...itemStyle
              /*
              backgroundColor: `rgb(255,${
                (254 / renderItems.length) * (index + 1)
              },255)`
              */
            }}
          >
            {item}
          </Grid>
        ))}
    </Grid>
  )
}

export default ResponsiveGrid
