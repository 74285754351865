import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDropzone from 'react-dropzone'
import { Text } from '../'
import { Colors } from '../../Themes'
import { inlineStyles } from './Styles'

const defaultAcceptedFileTypes =
  'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf'
const acceptedFileTypesArray = defaultAcceptedFileTypes
  .split(',')
  .map((item) => {
    return item.trim()
  })

export default class Dropzone extends Component {
  verifyFile = (files, rejectedFiles, onDrop) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      rejectedFiles.map((file) => {
        let currentFileType = file.type
        if (!acceptedFileTypesArray.includes(currentFileType)) {
          alert(
            'This file is not allowed. Only .png, .jpg, .jpeg and .pdf files are permitted.'
          )
          return false
        } else {
          return true
        }
      })
    }

    if (files && files.length > 0) {
      files.map((file) => {
        let currentFileType = file.type
        if (!acceptedFileTypesArray.includes(currentFileType)) {
          alert(
            'This file is not allowed. Only .png, .jpg, .jpeg and .pdf files are permitted.'
          )
          return false
        } else {
          return true
        }
      })
      return onDrop(files)
    }
  }

  render() {
    const { onDrop, accept, style } = this.props
    return (
      <ReactDropzone
        disablePreview
        accept={accept || defaultAcceptedFileTypes}
        multiple={true}
        style={{
          ...inlineStyles.style,
          ...style
        }}
        activeStyle={inlineStyles.activeStyle}
        acceptStyle={inlineStyles.acceptStyle}
        rejectStyle={inlineStyles.rejectStyle}
        disabledStyle={inlineStyles.disabledStyle}
        onDrop={(files, rejectedFiles) =>
          this.verifyFile(files, rejectedFiles, onDrop)
        }
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              style={
                isDragActive
                  ? {
                      ...inlineStyles.innerContainer,
                      border: `2px dashed ${Colors.primary}`
                    }
                  : {
                      ...inlineStyles.innerContainer,
                      border: `1px dashed ${Colors.primary}`
                    }
              }
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Text align='center'>Drop files here...</Text>
              ) : (
                <Text align='center'>{'Drop files here...'}</Text>
              )}
            </div>
          )
        }}
      </ReactDropzone>
    )
  }
}

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}
