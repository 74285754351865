import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { avgAndCoordinatesFragment } from './useTotalDistances'

export const GET_AVERAGE_SPEED = gql`
  query averageSpeed(
    $maxTicks: Int
    $orgIdsFilter: [String]
    $assetGroupId: String
  ) {
    averageSpeed(
      maxTicks: $maxTicks
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

export const useAverageSpeed = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_AVERAGE_SPEED,
    {
      variables: {
        maxTicks: 20,
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'averageSpeed',
      errorMessage: 'Failed to fetch Average Speed',
      dataTransform: (data) => data
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
