import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      _id
      jwt
      username
      #role {
      #  id
      #  Name
      #}
    }
  }
`
