import React, { useEffect, useState } from 'react'
import {
  Button,
  MaterialInput,
  Text,
  FlexBox,
  Icon,
  ProcessingSpinner
} from '../../Components'
import { useMutation } from '@apollo/client'
import { Images, useColors, Metrics } from '../../Themes'
import { LOGIN } from '../../Graphql/Mutations'
import { MainLayout, Subsection } from '../../Components'
import { Formik } from 'formik'
import { Display, Heading, Subheading } from '../../Components/Typography'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useDebounce } from '../../Components/CustomHooks/useDebounce'
import { useUserByUsername } from '../../Graphql/Queries/useUserByUsername'
import { validateEmail } from '../../Helpers/Functions'
import { useSetNewPassword } from '../../Graphql/Mutations/useSetNewPassword'
import { handleLogin } from './Login'

export const Copyright = () => {
  const Colors = useColors()
  return (
    <Text font='caption' color={Colors.textSecondary}>
      Copyright ©
      <Link
        style={{ color: Colors.textSecondary }}
        target='_blank'
        href='https://www.cybelefleet.tech'
      >
        {' '}
        Cybele{' '}
      </Link>
      {new Date().getFullYear()} | Version {Metrics.version}
    </Text>
  )
}

const handleLoginButton = ({
  login,
  setNewPassword,
  history,
  updateNotification,
  values
}) => {
  setNewPassword({
    variables: values,
    onCompleted: (successfull) =>
      successfull &&
      login({ variables: values })
        .then(({ data }) => handleLogin(data.login, history))
        .catch((e) =>
          updateNotification({
            variables: {
              show: true,
              level: 'danger',
              text: e.message
            }
          })
        )
  })
}

const validationSchema = () => {
  return Yup.object().shape({
    username: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .max(64)
      .required('This field is required'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password', {})], 'Passwords must match')
      .required('This field is required')
  })
}

const Welcome = ({ history }) => {
  const [login, { loading }] = useMutation(LOGIN)
  const [setNewPassword, { setNewPasswordLoading }] = useSetNewPassword()
  const Colors = useColors()
  const [username, setUsername] = useState(null)
  const { data: user, loading: userLoading } = useUserByUsername(username)
  const debouncedFunc = useDebounce({
    func: setUsername,
    milliseconds: 1000,
    immediate: false
  })
  return (
    <MainLayout publicLayout>
      <FlexBox
        fillSpace
        direction='column'
        style={{
          backgroundColor: Colors.subsectionBackground,
          width: '100%',
          flex: 1
        }}
      >
        <FlexBox direction='row'>
          <img
            alt='Cybele Emblem'
            width={70}
            src={Images.CybeleEmblem}
            style={{
              marginTop: -Metrics.base,
              marginRight: Metrics.base * 3
            }}
          ></img>
          <img
            src={Images.CybeleLogotype}
            alt='Cybele Logo'
            style={{
              width: 400,
              marginTop: -Metrics.base,
              marginLeft: Metrics.base * 2
            }}
            // style={Styles.logo}
          />
        </FlexBox>
        <Display marginTop={3}>Welcome!</Display>
      </FlexBox>
      <FlexBox
        fillSpace
        style={{
          backgroundColor: Colors.background,
          borderTop: `1px solid ${Colors.dividerColor}`,
          width: '100%',
          flex: 1
        }}
      >
        <Subsection
          style={{
            width: '60%',
            paddingBottom: Metrics.base * 4,
            flexDirection: 'column'
          }}
        >
          <FlexBox
            style={{
              height: Metrics.navBarHeight,
              width: '100%',
              display: 'flex',
              borderBottom: `1px solid ${Colors.dividerColor}`,
              padding: Metrics.base * 2,
              marginBottom: Metrics.base * 6
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
                padding: Metrics.base * 2,
                justifyContent: 'space-between',
                backgroundColor: Colors.selectHighlightOpacity2
              }}
            >
              <FlexBox
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <FlexBox>
                  <Icon
                    name='lock'
                    style={{
                      marginRight: Metrics.base * 2
                    }}
                    size='large'
                    color={Colors.primaryOpacity2}
                  />
                  <Subheading>User Activation</Subheading>
                </FlexBox>
                <Copyright />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <Formik
            initialValues={{
              username: '', // email ? email : '',
              password: '',
              retypePassword: ''
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              isValid
            }) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ width: '80%' }}>
                  <MaterialInput
                    id='login-email'
                    name='login-email'
                    label='Email *'
                    value={values.username}
                    handleChange={(value) => [
                      setFieldValue('username', value),
                      debouncedFunc(value)
                    ]}
                    onBlur={() => setFieldTouched('username')}
                    error={touched.username && errors.username}
                  />
                  {user && user.userFound && !user.isActive && (
                    <>
                      <MaterialInput
                        id='login-password'
                        name='login-password'
                        label='Choose Password *'
                        type='password'
                        value={values.password}
                        handleChange={(value) =>
                          setFieldValue('password', value)
                        }
                        onBlur={() => setFieldTouched('password')}
                        error={touched.password && errors.password}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleLoginButton({
                              login,
                              setNewPassword,
                              history,
                              updateNotification: () => {},
                              values,
                              updateIsTourOpen: () => {}
                            })
                          }
                        }}
                      />
                      <MaterialInput
                        id='retype-password'
                        name='retype-password'
                        label='Re-Enter Password *'
                        type='password'
                        value={values.retypePassword}
                        handleChange={(value) =>
                          setFieldValue('retypePassword', value)
                        }
                        onBlur={() => setFieldTouched('retypePassword')}
                        error={touched.retypePassword && errors.retypePassword}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleLoginButton({
                              login,
                              setNewPassword,
                              history,
                              updateNotification: () => {},
                              values,
                              updateIsTourOpen: () => {}
                            })
                          }
                        }}
                      />
                    </>
                  )}
                  <Button
                    primary
                    id='login-button'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                    onClick={() =>
                      handleLoginButton({
                        login,
                        setNewPassword,
                        history,
                        updateNotification: () => {},
                        values,
                        updateIsTourOpen: () => {}
                      })
                    }
                    textColor='white'
                    center
                    fetching={isSubmitting || loading}
                    expand
                    radius
                    title='Login'
                    type='submit'
                    disabled={
                      !isValid ||
                      values.username === '' ||
                      values.password === '' ||
                      values.retypePassword === '' ||
                      values.password !== values.retypePassword ||
                      isSubmitting ||
                      loading
                    }
                    background={Colors.primary}
                  />
                </div>
              </div>
            )}
          </Formik>
          {userLoading ? (
            <ProcessingSpinner />
          ) : (
            !user &&
            validateEmail(username) && (
              <FlexBox>
                <Icon
                  size='small'
                  color={Colors.textSecondaryOpacity1}
                  name='error'
                />
                <Text marginLeft={1} marginRight={1}>
                  No User Found.
                </Text>
              </FlexBox>
            )
          )}
          {user && user.isActive && (
            <FlexBox>
              <Icon
                size='small'
                color={Colors.textSecondaryOpacity1}
                name='error'
              />
              <Text marginLeft={1} marginRight={1}>
                User already active.{' '}
              </Text>
              <Link to='/'>
                <Text color={Colors.primary} bold>
                  <u>Login</u>
                </Text>
              </Link>
            </FlexBox>
          )}
        </Subsection>
      </FlexBox>
    </MainLayout>
  )
}

export default Welcome
