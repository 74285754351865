import { useEffect, useState } from 'react'
import { useQuery } from '../../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../../Helpers/Functions'

export const historyFragment = gql`
  fragment historyFragment on DriverSafety {
    _id
    generalSafetyCategoryMean100
    createdAt
  }
`

export const GET_DRIVER_SAFETYS = gql`
  query driverSafetys(
    $driverId: String
    $skip: Int
    $limit: Int
    $drivingCategoryFilter: String
    $startDate: Float
    $endDate: Float
  ) {
    driverSafetys(
      driverId: $driverId
      skip: $skip
      limit: $limit
      drivingCategoryFilter: $drivingCategoryFilter
      startDate: $startDate
      endDate: $endDate
    ) {
      ...historyFragment
    }
  }
  ${historyFragment}
`

const dataTransform = ({ data }) => {
  return (
    data &&
    data.map((a) => {
      return {
        ...a,
        score: a.generalSafetyCategoryMean100,
        value: a.generalSafetyCategoryMean100,
        date: new Date(a.createdAt)
      }
    })
  )
}

const getVariables = ({
  driverId,
  startDate,
  endDate,
  skip = 0,
  limit = 10000
}) => {
  let variables = {
    driverId,
    drivingCategoryFilter: 'GENERAL_SAFETY',
    skip,
    limit
  }
  if (checkVal(startDate)) {
    variables = { ...variables, startDate }
  }
  if (checkVal(endDate)) {
    variables = { ...variables, endDate }
  }
  return variables
}

export const useContextualScoreHistory = ({ driverId, startDate, endDate }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DRIVER_SAFETYS,
    {
      variables: getVariables({
        driverId,
        startDate,
        endDate
      }),
      skip: !driverId,
      queryName: 'driverSafetys',
      errorMessage: 'Failed to fetch Driver Safety CONTEXTUAL History',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return {
    data,
    loading
  }
}
