import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { useColors, Metrics } from '../../Themes'
import { ProcessingSpinner } from '../'
import Subsection from '../Subsection'

export const googleMapsApiKey = 'AIzaSyCfvt-eUHjCQFO1vcHHCrp2_Fgeiwrkezk'
const libraries = ['visualization']

const containerStyle = {
  width: '100%',
  height: '640px',
  borderRadius: Metrics.radius
}

const GoogleMapComp = ({
  zoom,
  center,
  loading,
  onLoad,
  onUnmount,
  children
}) => {
  const Colors = useColors()
  return (
    <>
      <Subsection
        style={{
          position: 'relative',
          width: '100%'
        }}
      >
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            options={{
              streetViewControl: false,
              backgroundColor: Colors.background
              // mapTypeId: 'satellite'
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {children}
          </GoogleMap>
        </LoadScript>
        {loading && (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: Colors.backgroundOpacity3
            }}
          >
            <ProcessingSpinner size={40} />
          </div>
        )}
      </Subsection>
    </>
  )
}

export default React.memo(GoogleMapComp)
