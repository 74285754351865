import React, { useState, useEffect } from 'react'
import { Form } from '../../../Components'

const drivingPermitValues = [
  { label: 'G', value: 'GOODS' },
  { label: 'P', value: 'PASSENGER' },
  { label: 'D', value: 'DANGEROUS_GOODS' }
]

const driverRestrictionsValues = [
  { label: 'None', value: '0' },
  { label: 'Glasses/Contact Lenses', value: '1' },
  { label: 'Artificial Limb', value: '2' }
]

const vehicleRestrictionValues = [
  { label: 'None', value: '0' },
  { label: 'Automatic Transmission', value: '1' },
  { label: 'Electrically Powered', value: '2' },
  { label: 'Physically Disabled', value: '3' },
  {
    label: 'Bus Above 16000 kg GVM (on EC1 licence)',
    value: '4'
  },
  { label: 'Tractor only (on B licence)', value: '5' },
  {
    label: 'Mobile equipment only (on B licence)',
    value: '7'
  }
]

const licenceCodeValues = [
  { label: 'A1: Motorcycle up to 125cc', value: 'A1' },
  { label: 'A: Motorcycle, tricycle or quadrucycle above 125cc', value: 'A' },
  { label: 'B: Light motor vehicle (not articulated)', value: 'B' },
  { label: 'B5: B with authorization for a tractor', value: 'B5' },
  {
    label: 'B7: B with authorization to mobile machinery',
    value: 'B7'
  },
  { label: 'C1: Heavy motor vehicle (not articulated)', value: 'C1' },
  {
    label: 'C4: C1 with authorization to drive buses ',
    value: 'C4'
  },
  { label: 'C: Extra heavy vehicle (not articulated)', value: 'C' },
  {
    label:
      'EB: Combination- articulated vehicle up to 3500kg or rigid vehicle above 3500kg with trailer above 750kg ',
    value: 'EB'
  },
  {
    label:
      'EC1 Combination- articulated vehicle up to 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
    value: 'EC1'
  },
  {
    label:
      'EC: Combination- articulated vehicle above 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
    value: 'EC'
  }
]

const getFormValues = (documentType, setDocumentType, typeOptions, object) => {
  let documentSchemaType = ''
  if (documentType) {
    documentSchemaType = documentType
  } else {
    documentSchemaType = null
  }
  let formSchema = {}

  const driverLicenceFormFields = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'countryOfIssue',
      required: true,
      label: 'Country of Issue'
    },
    {
      name: 'controlNumber',
      required: true,
      label: 'Control Number'
    },
    {
      name: 'dob',
      required: true,
      type: 'date',
      label: 'Date of Birth'
    },
    {
      name: 'dateIssued',
      required: true,
      type: 'date',
      label: 'Date Issued'
    },
    {
      name: 'doe',
      required: true,
      type: 'date',
      label: 'Expiry Date'
    },
    {
      name: 'licenceCode',
      required: true,
      label: 'Driver Licence Code',
      type: 'multipleSelect',
      options: licenceCodeValues
    },
    {
      name: 'professionalDrivingPermit',
      required: true,
      label: 'Professional Driving Permit',
      type: 'multipleSelect',
      options: drivingPermitValues
    },
    {
      name: 'professionalDrivingPermitExpiryDate',
      required: true,
      type: 'date',
      label: 'Professional Permit Expiry Date'
    },
    {
      name: 'driverRestrictions',
      required: true,
      label: 'Driver Restrictions',
      type: 'multipleSelect',
      options: driverRestrictionsValues
    },
    {
      name: 'vehicleRestrictions',
      required: true,
      label: 'Vehicle Restrictions',
      type: 'multipleSelect',
      options: vehicleRestrictionValues
    }
  ]

  const vehicleLicence = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },

    {
      name: 'controlNumber',
      required: true,
      label: 'Control Number'
    },
    {
      name: 'registeringAuthority',
      required: true,
      label: 'Registering Authority'
    },
    {
      name: 'vin',
      required: true,
      label: 'VIN'
    },
    {
      name: 'registrationNumber',
      required: true,
      label: 'Registration Number'
    },
    {
      name: 'licenseNo',
      required: false,
      label: 'Licence Number'
    },
    {
      name: 'professionalDrivingPermit',
      required: false,
      label: 'Professional Driving Permit Code',
      type: 'multipleSelect',
      options: drivingPermitValues
    },
    {
      name: 'vehicleCategory',
      required: false,
      label: 'Vehicle Category',
      type: 'multipleSelect',
      options: licenceCodeValues
    },
    {
      name: 'engineNumber',
      required: true,
      label: 'Engine Number'
    },
    {
      name: 'gvm',
      required: true,
      label: 'Gross Vehicle Mass'
    },
    {
      name: 'tare',
      required: true,
      label: 'Tare'
    },
    {
      name: 'vehicleMake',
      required: true,
      label: 'Make of Vehicle'
    },
    {
      name: 'vehicleModel',
      required: false,
      label: 'Vehicle Model'
    },
    {
      name: 'vehicleYear',
      required: false,
      label: 'Vehicle Year'
    },
    {
      name: 'testingStation',
      required: true,
      label: 'Testing Station'
    },
    {
      name: 'examinerIdentity',
      required: true,
      label: 'Examiner`s Identity'
    },
    {
      name: 'dateOfTest',
      required: true,
      type: 'date',
      label: 'Date of Test'
    },
    {
      name: 'maxPersons',
      required: true,
      label: 'Max Number of Persons'
    },
    {
      name: 'doe',
      required: true,
      type: 'date',
      label: 'Expiry Date'
    },
    {
      name: 'nvc',
      required: true,
      label: 'NVC'
    }
  ]

  const vehicleService = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'vehicleServicedDate',
      required: true,
      type: 'date',
      label: 'Date of Service'
    }
  ]

  const vehicleIncident = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'vehicleIncidentDate',
      required: true,
      type: 'date',
      label: 'Date of Incident'
    }
  ]

  const vehicleCrash = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'vehicleCrashDate',
      required: true,
      type: 'date',
      label: 'Date of Crash'
    }
  ]

  const driverIDSchema = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'firstName',
      required: true,
      label: 'First Name'
    },
    {
      name: 'surname',
      required: true,
      label: 'Surname'
    },
    {
      name: 'idNumber',
      required: true,
      label: 'ID Number'
    },
    {
      name: 'gender',
      required: true,
      label: 'Gender',
      type: 'select',
      options: [
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
        { label: 'Other', value: 'Other' }
      ]
    },
    {
      name: 'nationality',
      required: true,
      label: 'Nationality'
    },

    {
      name: 'countryOfBirth',
      required: true,
      label: 'Country of Birth'
    },
    {
      name: 'citizenshipStatus',
      required: true,
      label: 'Citizenship Status',
      type: 'select',
      options: [
        { label: 'South African', value: 'SOUTH_AFRICAN' },
        { label: 'Permanent Resident', value: 'PERMANENT_RESIDENT' }
      ]
    },
    {
      name: 'dob',
      required: true,
      type: 'date',
      label: 'Date of Birth'
    }
  ]

  const passportSchema = [
    {
      name: 'documentType',
      required: true,
      label: 'Document Type',
      type: 'select',
      options: typeOptions,
      // initialValue: documentSchemaType,
      handleChange: (data) => setDocumentType(data)
    },
    {
      name: 'firstName',
      required: true,
      label: 'First Name'
    },
    {
      name: 'surname',
      required: true,
      label: 'Surname'
    },
    {
      name: 'dob',
      required: true,
      type: 'date',
      label: 'Date of Birth'
    },
    {
      name: 'passportNumber',
      required: true,
      label: 'Passport Number'
    },
    {
      name: 'visaExpiryDate',
      required: true,
      type: 'date',
      label: 'Visa Expiry Date'
    },
    {
      name: 'gender',
      required: true,
      label: 'Gender',
      type: 'select',
      options: [
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
        { label: 'Other', value: 'Other' }
      ]
    },
    {
      name: 'nationality',
      required: true,
      label: 'Nationality'
    },

    {
      name: 'countryOfBirth',
      required: true,
      label: 'Country of Birth'
    },
    {
      name: 'citizenshipStatus',
      required: true,
      label: 'Citizenship Status',
      type: 'select',
      options: [
        { label: 'South African', value: 'SOUTH_AFRICAN' },
        { label: 'Permanent Resident', value: 'PERMANENT_RESIDENT' }
      ]
    },
    {
      name: 'dateOfIssue',
      required: true,
      type: 'date',
      label: 'Date of Issue'
    },
    {
      name: 'dateOfExpiry',
      required: true,
      type: 'date',
      label: 'Date of Expiry'
    },
    {
      name: 'issuingCountry',
      required: true,
      label: 'Issuing Country'
    },
    {
      name: 'issuingAuthority',
      required: true,
      label: 'Issuing Authority'
    }
  ]

  switch (documentSchemaType) {
    case 'DRIVER_LICENCE_FRONT':
      formSchema = driverLicenceFormFields
      break
    case 'VEHICLE_LICENCE':
      formSchema = vehicleLicence
      break
    case 'VEHICLE_SERVICE':
      formSchema = vehicleService
      break
    case 'DRIVER_ID':
      formSchema = driverIDSchema
      break
    case 'DRIVER_PASSPORT':
      formSchema = passportSchema
      break

    default:
      formSchema = [
        {
          name: 'documentType',
          // required: true,
          label: 'Document Type',
          type: 'select',
          options: typeOptions,
          handleChange: (data) => setDocumentType(data)
        }
      ]
      break
  }

  const formData =
    formSchema && formSchema.length > 0
      ? formSchema.map((a) => {
          return {
            ...a,
            initialValue: a.initialValue ? a.initialValue : '' //object[a.name] : ''
          }
        })
      : [
          {
            name: 'documentType',
            required: true,
            label: 'Document Type',
            type: 'select',
            options: typeOptions,
            // initialValue: documentSchemaType,
            handleChange: (data) => setDocumentType(data)
          }
        ]
  return formData
}

const DocumentForm = ({
  handleChange,
  handleDrop,
  acceptedFiles,
  removeFile,
  _id,
  filename,
  typeOptions,
  handleDownloadDocument,
  disabled,
  object,
  handleCancel,
  handleSubmit,
  isSubmitting,
  driverId,
  assetId,
  inspectionId,
  incidentId,
  crashId
}) => {
  const [formValues, setFormValues] = useState([])
  const [documentType, setDocumentType] = useState('')
  useEffect(() => {
    setFormValues(
      getFormValues(documentType, setDocumentType, typeOptions, handleChange)
    )
  }, [documentType])

  return (
    <Form
      hideFormBox
      style={{ width: '100%', marginBottom: 0, boxShadow: 'none' }}
      disabled={disabled}
      onSubmit={(data) =>
        handleSubmit({
          ...data,
          driverId: driverId ? driverId : null,
          assetId: assetId ? assetId : null,
          inspectionId: inspectionId ? inspectionId : null,
          incidentId: incidentId ? incidentId : null,
          crashId: crashId ? crashId : null
        })
      }
      submitButtonTitle={'submit'}
      showChanges={object ? true : false}
      data={formValues}
      isSubmitting={isSubmitting}
      // setSubmitting={false}
      cancelButton={false} //!hideCancel}
      handleCancel={handleCancel}
      // handleChange={handleChange}
      // hideFormBox
      // showReset={showReset}
      _id={_id}
      filename={filename}
      acceptedFiles={acceptedFiles}
      handleDrop={handleDrop}
      removeFile={removeFile}
      handleDownloadDocument={handleDownloadDocument}
      documentType={documentType}
    />
  )
}

export default DocumentForm
