import React, { useState, useEffect } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useFilters } from '../useFilters'
import { eventFilters } from '../../GraphQLClient'
import { useReactiveVar } from '@apollo/client'

export const vehicleLicenceFragment = gql`
  fragment vehicleLicenceFragment on VehicleLicence {
    _id
    controlNumber
    registeringAuthority
    vin
    registrationNumber
    engineNumber
    gvm
    tare
    vehicleMake
    testingStation
    examinerIdentity
    dateOfTest
    maxPersons
    doe
    isActive
    asset {
      _id
    }
    document {
      documentType
      _id
      url
    }
  }
`

export const GET_VEHICLE_LICENCES = gql`
  query vehicleLicences(
    $assetId: String
    $isActive: Boolean
    $skip: Int
    $limit: Int
  ) {
    vehicleLicences(
      assetId: $assetId
      isActive: $isActive
      skip: $skip
      limit: $limit
    ) {
      ...vehicleLicenceFragment
    }
  }
  ${vehicleLicenceFragment}
`

export const vehicleLicenceTransform = ({ data }) => {
  let res = []
  if (data) {
    res = data.map((item) => {
      return { ...item, id: item._id ? item._id : null }
    })
  }
  return res
}
const getVehicleLicenceVariables = (vehicleLicenceVariables) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (vehicleLicenceVariables && vehicleLicenceVariables.assetId) {
    variables = {
      ...variables,
      assetId: vehicleLicenceVariables.assetId
    }
  }
  if (
    vehicleLicenceVariables &&
    typeof vehicleLicenceVariables.isActive !== 'undefined'
  ) {
    variables = {
      ...variables,
      isActive: true
    }
  }

  return variables
}

export const useVehicleLicences = (vehicleLicenceVariables) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])

  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_VEHICLE_LICENCES, {
    variables: getVehicleLicenceVariables(vehicleLicenceVariables),
    queryName: 'vehicleLicences',
    errorMessage: 'Failed to fetch vehicle licence',
    dataTransform: (data) => vehicleLicenceTransform({ data }),
    skip: !(vehicleLicenceVariables && vehicleLicenceVariables.assetId)
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
