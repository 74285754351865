import React, { useState, useEffect } from 'react'
import {
  FlexBox,
  Text,
  Subsection,
  Button,
  Status,
  DataDisplay,
  NavButton,
  IconButton
} from '../../../Components'
import InfoBadge from '../../../Components/Custom/InfoBadge'
import SubsectionDivider from '../../../Components/Custom/SubsectionDivider'
import { Metrics, useColors, Images } from '../../../Themes'
import { useIncident } from '../../../Graphql/Queries/useIncident'
import AddDocumentModal from '../../../Components/Custom/AddDocumentModal'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import { Grid, Link } from '@material-ui/core'
import SecondarySidePanel from '../../../Components/SidePanel/SecondarySidePanel'
import { useHistory } from 'react-router-dom'
// import SelectedObjectHeader from '../../Components/Custom/SelectedObjectHeader'

import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { Subheading } from '../../../Components/Typography'

const getUrlId = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('id')
}

const IncidentInfo = ({
  asset,
  latestIncident,
  Colors,
  setShowAddDocumentModal
}) => {
  return (
    <Subsection
      style={{
        width: '45%',
        height: '100%',
        marginTop: Metrics.base * 2,
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.error}
        // leadIcon='check'
        value='Incident Information'
        // actionButton='Edit Items'
        // handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />
      {latestIncident && latestIncident._id ? (
        <FlexBox direction='column'>
          <DataDisplay
            firstItem={true}
            label='Asset Name'
            data={latestIncident.asset ? latestIncident.asset.name : '-'}
          />
          <DataDisplay
            firstItem={true}
            label='Asset Registration Num.'
            data={
              latestIncident.asset ? latestIncident.asset.registrationNum : '-'
            }
          />
          <DataDisplay
            firstItem={true}
            label='Driver Name'
            data={
              latestIncident.driver
                ? latestIncident.driver.firstName +
                  ' ' +
                  latestIncident.driver.lastName
                : '-'
            }
          />
          <DataDisplay
            firstItem={true}
            label='Driver ID'
            data={latestIncident.driver ? latestIncident.driver.idNumber : '-'}
          />
          <DataDisplay
            label='Coordinates of Incident'
            data={
              latestIncident.latitude
                ? latestIncident.latitude + ',' + latestIncident.longitude
                : '-'
            }
          />
          <DataDisplay
            lastItem={true}
            label='Description of Incident'
            data={latestIncident.description ? latestIncident.description : '-'}
          />
        </FlexBox>
      ) : (
        <Text font='subheading' style={{ textAlign: 'center' }}>
          No Before Start incident data available
        </Text>
      )}

      <SubsectionDivider />
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        // leadIcon='check'
        value='Incident Document'
        actionButton='Upload'
        handleButtonClick={() => setShowAddDocumentModal(true)}
      />
      <SubsectionDivider />

      {latestIncident && (
        <Link
          underline='always'
          color={Colors.primary}
          style={{
            // color: Colors.primary,
            padding: Metrics.base * 2,
            marginBottom: Metrics.base * 2,
            marginRight: Metrics.base / 2,
            marginLeft: Metrics.base / 2
          }}
          target='_blank'
          href={latestIncident.document ? latestIncident.document.url : ''}
        >
          <Text color={Colors.primary} font={'subheading'}>
            {latestIncident.document
              ? latestIncident.document.documentType
              : ''}
          </Text>
        </Link>
      )}
    </Subsection>
  )
}

const ImageGrid = ({ incidentImages }) => {
  return (
    <Grid container spacing={3} justifyContent='center' alignItems='flex-start'>
      {incidentImages.map((image) => {
        return (
          <Grid item xs={12} md={6}>
            <img
              src={image.url}
              alt='IncidentPhoto'
              style={{
                borderRadius: Metrics.base * 2,
                width: 300,
                height: 300
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const IncidentImages = ({
  Colors,
  incidentImages,
  setShowAddDocumentModal
}) => {
  return (
    <Subsection
      style={{
        width: '45%',
        height: '100%',
        marginTop: Metrics.base * 2
        // justifyContent: 'center',
        // alignItems: 'flex-start'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        value='Images'
        // actionButton='Upload Images'
        // handleButtonClick={() => setShowAddDocumentModal(true)}
      />
      <SubsectionDivider />

      {checkVal(incidentImages) && incidentImages.length > 0 ? (
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base * 3
          }}
        >
          <ImageGrid
            incidentImages={incidentImages}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </FlexBox>
      ) : (
        <Text
          font='subheading'
          style={{ padding: Metrics.base * 2, textAlign: 'center' }}
        >
          No Images Uploaded
        </Text>
      )}
    </Subsection>
  )
}

const ViewIncident = (props) => {
  const Colors = useColors()
  const id = getUrlId()
  const { data: incident, refetch } = useIncident(id)
  const [viewIncident, setViewIncident] = useState()
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  //   const { data: incidentDocuments } = useDocumentsByIncidentId(id)
  const [incidentImages, setIncidentImages] = useState([])
  const { mainContainerWidth } = useAppDimensions()
  const history = useHistory()
  useEffect(() => {
    if (checkVal(incident) && checkVal(incident.data)) {
      setIncidentImages(incident.data.incidentImages)
    }
  }, [incident])

  useEffect(() => {
    if (incident) {
      setViewIncident(incident.data)
    }
  }, [incident])

  useEffect(() => {
    if (incident && showAddDocumentModal === false) {
      refetch()
    }
  }, [showAddDocumentModal])
  return (
    <FlexBox
      direction='Column'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%' // mainContainerWidth
      }}
    >
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          backgroundColor: Colors.subsectionBackground,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          height: Metrics.tabBarHeight
        }}
      >
        <IconButton
          iconStyle={{
            marginLeft: Metrics.base * 2,
            marginRight: Metrics.base * 4
          }}
          iconName={'arrow_back'}
          onClick={() =>
            history.push({ pathname: '/fleet-management/incident-history' })
          }
        >
          {'Go Back'}
        </IconButton>
        <Subheading>View Incident Detail</Subheading>
      </FlexBox>
      <FlexBox
        direction='Column'
        justifyContent='space-evenly'
        alignItems='center'
        style={{
          width: '100%'
        }}
      >
        {/* {checkVal(lesson) && checkVal(lesson.driver) && (
          <SelectedObjectHeader
            pageName='coachingRadioTabs'
            id={lesson.driver._id}
          />
        )} */}

        {incident && checkVal(incident.data) && (
          <Subsection
            style={{
              width: '92.5%',
              padding: Metrics.base * 2,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            <Text font='subheading'>
              {' '}
              Incident Date:{' '}
              {formatDateTime(new Date(incident.data.vehicleIncidentDate))}
            </Text>
          </Subsection>
        )}

        <FlexBox
          direction='row'
          justifyContent='space-evenly'
          alignItems='flex-start'
          style={{
            width: '100%',
            marginTop: Metrics.base * 2
          }}
        >
          {incident && checkVal(incident.data) && (
            <IncidentInfo
              latestIncident={viewIncident}
              Colors={Colors}
              setShowAddDocumentModal={setShowAddDocumentModal}
            />
          )}

          {incident && checkVal(incident.data) && (
            <IncidentImages
              incidentImages={incidentImages}
              Colors={Colors}
              setShowAddDocumentModal={setShowAddDocumentModal}
            />
          )}
          {incident && checkVal(incident.data) && showAddDocumentModal && (
            <AddDocumentModal
              typeOptions={'incident'}
              showAddDocumentModal={showAddDocumentModal}
              setShowAddDocumentModal={setShowAddDocumentModal}
              incidentId={incident.data._id}
              driverId={incident.data.driver._id}
              assetId={incident.data.asset._id}
            />
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
export default ViewIncident
