import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { Dialog } from '@material-ui/core'
import React, { Component, useState } from 'react'
import { Colors, Metrics, useColors } from '../../../Themes'
import { Text, Button } from '../../'
import Styles, { inlineStyles } from './Styles'
import { useQuery } from '@apollo/client'

const _onClick = ({ onClick, wait, setAnimateOut }) => {
  if (wait) {
    onClick()
  } else {
    setAnimateOut(true)
    setTimeout(() => {
      onClick()
    }, 250)
  }
}

const ModalAlert = ({
  title,
  titleColor,
  body,
  primary,
  bodyColor,
  buttonLeftOnClick,
  buttonLeftText,
  buttonLeftTextColor,
  buttonLeftFetching,
  buttonRightOnClick,
  buttonRightText,
  buttonRightTextColor,
  buttonRightFetching,
  buttonCancelOnClick,
  buttonCancelText,
  buttonCancelTextColor,
  backgroundColor,
  children,
  cancelButton,
  twoButton,
  actualButtons,
  childBackground,
  buttonLeftDisabled,
  buttonRightDisabled,
  width,
  height,
  noMargin,
  wait,
  handleClose,
  openDialog,
  setOpenDialog,
  maxWidth = 'md'
}) => {
  const [animateOut, setAnimateOut] = useState(false)
  const Colors = useColors()
  return (
    <Dialog
      open={openDialog}
      maxWidth={maxWidth}
      fullWidth
      onClose={() => setOpenDialog(false)}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      {' '}
      <div
        cy={'modalAlert'}
        className={css(Styles.container)}
        style={{
          backgroundColor: childBackground || 'transparent',
          width: width ? width : null,
          height: height ? height : null,
          maxHeight: height ? height : null,
          maxWidth: width ? width : null,
          minWidth: 350,
          margin: noMargin ? 0 : null
        }}
      >
        <div
          style={{
            backgroundColor: Colors.primary,
            // borderBottom: `1px solid ${Colors.primary}`,
            marginBottom: 0
          }}
        >
          {title ? (
            typeof title === 'object' ? (
              <div style={{ width: '100%' }}>{title}</div>
            ) : (
              <Text
                cy={'modalTitle'}
                align='center'
                bold
                color={titleColor || 'white'}
                style={inlineStyles.title}
              >
                {title}
              </Text>
            )
          ) : null}
          {body ? (
            <Text
              cy={'modalBody'}
              font='caption'
              align='center'
              color={bodyColor || Colors.grey4}
              style={inlineStyles.body}
            >
              {body}
            </Text>
          ) : null}
        </div>
        {children || null}
        {cancelButton ? (
          <div className={css(Styles.buttonsContainer)}>
            <div
              className={
                primary ? css(Styles.buttonPrimary) : css(Styles.buttonGrey)
              }
              onClick={() =>
                _onClick({ onClick: buttonCancelOnClick, wait, setAnimateOut })
              }
            >
              <Text
                font='button'
                bold
                align='center'
                color={buttonCancelTextColor || Colors.grey2}
              >
                {buttonCancelText.toUpperCase()}
              </Text>
            </div>
          </div>
        ) : null}
        {twoButton ? (
          <div className={css(Styles.buttonsContainer)}>
            <div
              className={css(Styles.buttonGrey)}
              onClick={() =>
                _onClick({ onClick: buttonLeftOnClick, wait, setAnimateOut })
              }
            >
              <Text
                font='button'
                bold
                align='center'
                color={buttonLeftTextColor || Colors.grey6}
              >
                {buttonLeftText.toUpperCase()}
              </Text>
            </div>
            <div
              className={css(Styles.button)}
              onClick={() =>
                _onClick({ onClick: buttonRightOnClick, wait, setAnimateOut })
              }
            >
              <Text
                font='button'
                bold
                align='center'
                color={buttonRightTextColor || Colors.primary}
              >
                {buttonRightText.toUpperCase()}
              </Text>
            </div>
          </div>
        ) : null}
        {actualButtons ? (
          <div className={css(Styles.buttonsContainer)}>
            <Button
              style={{ width: 120, margin: 0, borderRadius: 8 }}
              title={buttonLeftText.toUpperCase()}
              disabled={buttonLeftDisabled}
              fetching={buttonLeftFetching}
              onClick={() =>
                _onClick({ onClick: buttonLeftOnClick, wait, setAnimateOut })
              }
            />
            <Button
              style={{ width: 120, margin: 0, borderRadius: 8 }}
              title={buttonRightText.toUpperCase()}
              disabled={buttonRightDisabled}
              fetching={buttonRightFetching}
              bold
              onClick={() =>
                _onClick({ onClick: buttonRightOnClick, wait, setAnimateOut })
              }
            />
          </div>
        ) : null}
      </div>
    </Dialog>
  )
}

ModalAlert.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  body: PropTypes.string,
  bodyColor: PropTypes.string,
  buttonLeftOnClick: PropTypes.func,
  buttonLeftText: PropTypes.string,
  buttonLeftTextColor: PropTypes.string,
  buttonRightOnClick: PropTypes.func,
  buttonRightText: PropTypes.string,
  buttonRightTextColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  childBackground: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}

export default ModalAlert
