import React, { useState, useEffect } from 'react'
import { FlexBox, DataDisplay, Subsection, Text, ProgressBar } from '../../'
import InfoBadge from '../InfoBadge'
import { Metrics, useColors, Images } from '../../../Themes'
import { Link } from '@material-ui/core'
import SubsectionDivider from '../SubsectionDivider'
import AddDriverModal from '../AddDriverModal'
import AddDocumentModal from '../AddDocumentModal'
import { useDocumentsByDriverId } from '../../../Graphql/Queries/useDocumentsByDriverId'
import { useDriverLicences } from '../../../Graphql/Queries/Document/useDriverLicences'
import {
  formatDate,
  formatDateTime,
  timeUntilDate,
  checkVal
} from '../../../Helpers/Functions'
import { useDriver } from '../../../Graphql/Queries/Driver/useDriver'
import { useReactiveVar } from '@apollo/client'
import {
  selectedAssetId,
  selectedDriverId
} from '../../../Graphql/GraphQLClient'
import LinkAssetToDriver from '../../../Containers/FleetManagement/LinkAssetToDriver'
import { InsetCardContainer } from '../SelectionPanel/Cards/CardsContainer'
import AssetCard from '../SelectionPanel/Asset/AssetCard'
import { useNavigation } from '../../CustomHooks/useNavigation'
import NoneSelected from '../NoneSelected'
import { InfoMessage, Title } from '../../Typography'
import { useInviteDriverToApp } from '../../../Graphql/Mutations/useInviteDriverToApp'

const LicenceInfo = ({
  Colors,
  driversLicenceFront,
  driversLicenceBack,
  driversLicenceInfo,
  setShowAddDocumentModal,
  driver
}) => {
  const [licenceExpiryPercent, setLicenceExpiry] = useState([])
  const [timeUntilExpiry, setTimeUntilExpiry] = useState([])
  const [prdpExpiryPercent, setPrdpExpiry] = useState([])
  const [timeUntilPrdpExpiry, setTimeUntilPrdpExpiry] = useState([])

  const percentageUntilExpiry = ({ doe, dateIssued }) => {
    const timeFromToday = timeUntilDate({ dateEnd: doe })
    const dateStart = new Date(doe).getTime() - dateIssued
    const issueToExpiry = timeUntilDate({ dateStart: dateStart, dateEnd: doe })
    let percent = 100 - (timeFromToday / issueToExpiry) * 100

    if (percent < 0) {
      percent = 0
    }
    return Math.floor(percent)
  }

  const licenceExpiry = ({
    driver,
    doe,
    dateIssued,
    setExpiryPercent,
    setExpiryTime
  }) => {
    if (driver && doe && dateIssued) {
      let percent = percentageUntilExpiry({
        dateIssued,
        doe: doe ? doe : null
      })
      if (percent >= 100) {
        setExpiryPercent([percent, Colors.HIGH_RISK])
      } else if (percent < 100 && percent > 49) {
        setExpiryPercent([percent, Colors.MODERATE_RISK])
      } else {
        setExpiryPercent([percent, Colors.SAFE])
      }

      let timeUntil = timeUntilDate({
        dateEnd: doe
      })

      if (timeUntil > 0) {
        setExpiryTime([timeUntil, 'VALID'])
      } else {
        setExpiryTime([timeUntil * -1, 'EXPIRED'])
      }
    } else {
      setExpiryPercent([0, Colors.disabled])
      setExpiryTime([0, 'NOT_AVAILABLE'])
    }
  }

  useEffect(() => {
    licenceExpiry({
      driver,
      doe: driver && driver.doe,
      dateIssued: 157784760000,
      setExpiryPercent: setLicenceExpiry,
      setExpiryTime: setTimeUntilExpiry
    })
    licenceExpiry({
      driver,
      doe: driver && driver.professionalDrivingPermitExpiryDate,
      dateIssued: 63113904000,
      setExpiryPercent: setPrdpExpiry,
      setExpiryTime: setTimeUntilPrdpExpiry
    })
  }, [driver])

  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%',
        marginTop: Metrics.base * 2
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Licence'
        // leadIcon
        actionButton='Upload Licence'
        btnDisabled={!driver._id}
        handleButtonClick={() => setShowAddDocumentModal(true)}
        status='Valid'
        leadImg={
          timeUntilExpiry[1] === 'VALID' && timeUntilPrdpExpiry[1] === 'VALID'
            ? Images.verifiedUser
            : Images.error
        }
        // label='Driver details'
      />
      <SubsectionDivider />
      <FlexBox direction='column' style={{ width: '100%' }}>
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            marginBottom: Metrics.base * 2,
            marginTop: Metrics.base * 2
          }}
        >
          {checkVal(driversLicenceFront) ? (
            <FlexBox direction='column' style={{ width: '100%' }}>
              {driversLicenceFront.includes('.pdf') ? (
                <embed src={driversLicenceFront} width='400' height='620px' />
              ) : (
                <img
                  src={driversLicenceFront}
                  onError={() => {
                    console.log('error')
                  }}
                  alt='Licence'
                  width='70%'
                  height='70%'
                  style={{
                    padding: 10
                  }}
                />
              )}
            </FlexBox>
          ) : (
            <FlexBox
              direction='column'
              style={{
                width: '100%',
                // marginBottom: Metrics.base * 2,
                marginTop: Metrics.base * 2
              }}
            >
              <Text
                font={'subheading'}
                style={{
                  padding: Metrics.base * 3,
                  borderBottom: '1px solid'
                }}
              >
                Please Upload Front of Driver's Licence
              </Text>
            </FlexBox>
          )}
          {checkVal(driversLicenceBack) ? (
            <FlexBox direction='column' style={{ width: '100%' }}>
              {driversLicenceBack.includes('.pdf') ? (
                <embed src={driversLicenceBack} width='400' height='620px' />
              ) : (
                <img
                  src={driversLicenceBack}
                  onError={() => {
                    console.log('error')
                  }}
                  alt='Licence'
                  width='70%'
                  height='70%'
                  style={{
                    padding: 10
                  }}
                />
              )}
            </FlexBox>
          ) : (
            <FlexBox
              direction='column'
              style={{
                width: '100%',
                marginBottom: Metrics.base * 2,
                marginTop: Metrics.base * 2
              }}
            >
              <Text
                font={'subheading'}
                style={{
                  borderTop: '1px solid',

                  padding: Metrics.base * 3
                }}
              >
                Please Upload Back of Driver's Licence
              </Text>
            </FlexBox>
          )}
        </FlexBox>
        <SubsectionDivider />

        {driver && (
          <FlexBox
            direction='column'
            style={{ width: '100%', marginTop: Metrics.base * 2 }}
          >
            <ProgressBar
              completed={licenceExpiryPercent[0]}
              bgcolor={licenceExpiryPercent[1]}
              // style={{ paddingTop: Metrics.base * 2 }}
            />
            <Text
              font='subheading'
              style={{
                textAlign: 'center',
                marginLeft: Metrics.base * 3,
                paddingBottom: Metrics.base * 3,
                paddingTop: Metrics.base * 1
              }}
            >
              {timeUntilExpiry[1] === 'EXPIRED'
                ? "Driver's licence expired: " +
                  timeUntilExpiry[0] +
                  ' days ago'
                : timeUntilExpiry[1] === 'VALID'
                ? "Driver's licence valid for: " + timeUntilExpiry[0] + ' days'
                : "No driver's licence expiry date available"}
            </Text>

            <ProgressBar
              completed={prdpExpiryPercent[0]}
              bgcolor={prdpExpiryPercent[1]}
              // style={{ paddingTop: Metrics.base * 2 }}
            />
            <Text
              font='subheading'
              style={{
                textAlign: 'center',
                marginLeft: Metrics.base * 3,
                paddingBottom: Metrics.base * 3,
                paddingTop: Metrics.base * 1
              }}
            >
              {timeUntilPrdpExpiry[1] === 'EXPIRED'
                ? 'Professional driving permit expired: ' +
                  timeUntilPrdpExpiry[0] +
                  ' days ago'
                : timeUntilPrdpExpiry[1] === 'VALID'
                ? 'Professional driving permit valid for: ' +
                  timeUntilPrdpExpiry[0] +
                  ' days'
                : 'No Professional driving permit expiry date available'}
            </Text>
          </FlexBox>
        )}
      </FlexBox>
    </Subsection>
  )
}
const LinkedAsset = ({ Colors, setShowLinkAssetModal, asset, driverId }) => {
  const assetId = asset && asset._id
  const navigateTo = useNavigation()
  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        // justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%',
        marginTop: Metrics.base * 2
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Linked Asset'
        // leadIcon
        btnDisabled={!driverId}
        actionButton='Link Asset'
        handleButtonClick={() => {
          setShowLinkAssetModal(true)
        }}
        // label='Driver details'
      />
      <FlexBox
        direction='column'
        style={{ width: '100%', padding: Metrics.base * 2 }}
      >
        <InsetCardContainer name='Linked Asset'>
          <AssetCard
            assetId={assetId}
            onClick={() => [
              selectedAssetId(assetId),
              navigateTo({
                pathname: '/fleet-management/assets',
                queryParams: [{ name: 'asset', id: assetId }]
              })
            ]}
            backgroundColor={Colors.background}
          />
        </InsetCardContainer>
      </FlexBox>
    </Subsection>
  )
}

const InviteDriverToApp = ({ driver }) => {
  const Colors = useColors()
  const [sendInvite, { loading }] = useInviteDriverToApp()
  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        // justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%',
        marginTop: Metrics.base * 2
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Mobile App'
        // leadIcon
        btnDisabled={loading || !(driver && checkVal(driver.email))}
        actionButton='Send Invite'
        handleButtonClick={() => {
          sendInvite({ variables: { userEmail: driver && driver.email } })
        }}
        // label='Driver details'
      />
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          padding: Metrics.base * 2,
          borderTop: `1px solid ${Colors.dividerColor}`
        }}
      >
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            paddingBottom: Metrics.base * 2,
            marginBottom: Metrics.bsae * 2
          }}
        >
          {!(driver && checkVal(driver.email)) && (
            <InfoMessage>No driver email</InfoMessage>
          )}
        </FlexBox>
        <Title style={{ marginTop: Metrics.base * 2 }}>
          Driver not yet invited
        </Title>
      </FlexBox>
    </Subsection>
  )
}

const DriverInfo = () => {
  const Colors = useColors()
  const driverId = useReactiveVar(selectedDriverId)
  const { data: driver, refetch: driverRefetch } = useDriver(driverId)
  const [showAddDriverModal, setShowAddDriverModal] = useState(false)
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  const [licenceVariables, setLicenceVariables] = useState()

  const [driversLicenceFront, setDriversLicenceFront] = useState()
  const [driversLicenceBack, setDriversLicenceBack] = useState()
  const [driversLicenceInfo, setDriversLicenceInfo] = useState()
  const { data: documents } = useDocumentsByDriverId(driver)
  const [linkAssetModal, setShowLinkAssetModal] = useState(false)
  const { data: driverLicence, refetch: licenceRefetch } =
    useDriverLicences(licenceVariables)

  const excludeLinks = [
    'VEHICLE_INCIDENT_IMAGES',
    'VEHICLE_INCIDENT',
    'VEHICLE_CRASH_IMAGES',
    'VEHICLE_CRASH',
    'INSPECTION_EXTRAS',
    'INSPECTION_MAIN'
  ]

  useEffect(() => {
    if (checkVal(driverId)) {
      setLicenceVariables({ driverId: driverId, isActive: true })
    }
  }, [driverId])

  useEffect(() => {
    if (driverLicence && driverLicence.length > 0) {
      setDriversLicenceInfo(driverLicence[0])
      if (driverLicence[0].document) {
        setDriversLicenceFront([driverLicence[0].document.url])
      }
      if (driverLicence[0].licenceBackDocument) {
        setDriversLicenceBack([driverLicence[0].licenceBackDocument.url])
      }
    } else {
      setDriversLicenceFront()
      setDriversLicenceBack()
      setDriversLicenceInfo()
    }
  }, [driverId, driverLicence])

  useEffect(() => {
    licenceRefetch()
    driverId && setLicenceVariables({ driverId: driverId, isActive: true })
  }, [documents])

  useEffect(() => {
    if (!showAddDriverModal) {
      driverRefetch()
    }
  }, [showAddDriverModal])

  useEffect(() => {
    driverRefetch()
    driverId && setLicenceVariables({ driverId: driverId, isActive: true })
  }, [driversLicenceFront])

  return (
    <FlexBox
      direction='row'
      alignItems='flex-start'
      style={{
        width: '100%',
        paddingLeft: Metrics.base * 2,
        paddingRight: Metrics.base * 2
      }}
    >
      {driverId ? (
        <>
          <FlexBox style={{ flex: 1, paddingRight: Metrics.base }}>
            <Subsection style={{ width: '100%', marginTop: Metrics.base * 2 }}>
              <InfoBadge
                style={{
                  width: '100%',
                  alignItems: 'left',
                  justifyContent: 'flex-start'
                }}
                noBoxShadow
                //   color={getImportanceScoreColor(aggregatedScore)}
                // colorOpacity={1}
                value='Driver details'
                // leadIcon
                btnDisabled={!driverId}
                actionButton='Edit Details'
                handleButtonClick={() => setShowAddDriverModal(true)}
                // status='Driving'
                // label='Driver details'
              />
              {showAddDriverModal && (
                <AddDriverModal
                  showAddDriverModal={showAddDriverModal}
                  setShowAddDriverModal={setShowAddDriverModal}
                  object={driver}
                />
              )}
              <SubsectionDivider />
              <FlexBox direction='column' style={{ width: '100%' }}>
                <DataDisplay
                  firstItem={true}
                  label='Name'
                  data={
                    driver.firstName
                      ? driver.firstName + ' ' + driver.lastName
                      : '-'
                  }
                />
                <DataDisplay
                  label='Date of Birth'
                  data={driver.dob ? formatDate(driver.dob) : ''}
                />
                <DataDisplay
                  label='Licence Number'
                  data={driver.licenseNumber ? driver.licenseNumber : ''}
                  lastItem={true}
                />
                <DataDisplay
                  label='Licence Expiry Date'
                  data={driver.doe ? formatDate(driver.doe) : ''}
                  lastItem={true}
                />
                <DataDisplay
                  label='Licence Code'
                  data={driver.code ? driver.doe : ''}
                  lastItem={true}
                />
                <DataDisplay
                  label='Licence First Issue'
                  data={driver.firstIssue ? formatDate(driver.firstIssue) : ''}
                  lastItem={true}
                />
                <DataDisplay
                  label='PrDP'
                  data={
                    driver.professionalDrivingPermit
                      ? driver.professionalDrivingPermit.map((value, index) => {
                          if (
                            index + 1 ===
                            driver.professionalDrivingPermit.length
                          ) {
                            return value
                          } else {
                            return value + ' | '
                          }
                        })
                      : '-'
                  }
                  lastItem={true}
                />
                <DataDisplay
                  label='PrDP Expiry Date'
                  data={
                    driver.professionalDrivingPermitExpiryDate
                      ? formatDate(driver.professionalDrivingPermitExpiryDate)
                      : ''
                  }
                  lastItem={true}
                />
                <DataDisplay
                  label='Driver Restrictions'
                  data={
                    driver.driverRestriction
                      ? driver.driverRestriction.map((value, index) => {
                          if (index + 1 === driver.driverRestriction.length) {
                            return value
                          } else {
                            return value + ' | '
                          }
                        })
                      : '-'
                  }
                  lastItem={true}
                />
                <DataDisplay
                  label='Driver Vehicle Restrictions'
                  data={
                    driver.vehicleRestriction
                      ? driver.vehicleRestriction.map((value, index) => {
                          if (index + 1 === driver.vehicleRestriction.length) {
                            return value
                          } else {
                            return value + ' | '
                          }
                        })
                      : '-'
                  }
                  lastItem={true}
                />
                {/*<DataDisplay
              label='Driver Licence Codes'
              data={
                driver.licenceCode
                  ? driver.licenceCode.map((value, index) => {
                      if (index + 1 === driver.licenceCode.length) {
                        return value
                      } else {
                        return value + ' | '
                      }
                    })
                  : '-'
              }
              lastItem={true}
            />*/}
                {/*<DataDisplay
              label='Date of Employment'
              data={driver.doe ? formatDate(driver.doe) : '-'}
              lastItem={true}
            />*/}
                {/*<DataDisplay
              label='Employee Reference no.'
              data={driver.employeeRefNumber ? driver.employeeRefNumber : '-'}
              lastItem={true}
            />*/}
                <DataDisplay
                  label='ID Number'
                  data={driver.idNumber ? driver.idNumber : '-'}
                  lastItem={true}
                />
                <DataDisplay
                  label='Gender'
                  data={driver.gender ? driver.gender : '-'}
                  lastItem={true}
                />
                <DataDisplay
                  label='Nationality'
                  data={driver.nationality ? driver.nationality : '-'}
                  lastItem={true}
                />
                <DataDisplay
                  label='Medical Aid Number'
                  data={driver.medicalAidNumber ? driver.medicalAidNumber : '-'}
                  lastItem={true}
                />
                <DataDisplay
                  label='Cell Number'
                  data={driver.cellphoneNumber ? driver.cellphoneNumber : '-'}
                  lastItem={true}
                />
                {/*<DataDisplay
              label='Emergency Contact'
              data={driver.emergencyContact ? driver.emergencyContact : '-'}
              lastItem={true}
            />*/}
                <SubsectionDivider />
                <InfoBadge
                  style={{
                    width: '100%',
                    alignItems: 'left',
                    justifyContent: 'flex-start'
                  }}
                  //   color={getImportanceScoreColor(aggregatedScore)}
                  // colorOpacity={1}
                  value='Docs and Links'
                  // leadIcon
                  btnDisabled={!driverId}
                  noBoxShadow
                  actionButton='Edit Items'
                  handleButtonClick={() => setShowAddDocumentModal(true)}
                  // status='Driving'
                  // label='Driver details'
                />
                {showAddDocumentModal && (
                  <AddDocumentModal
                    typeOptions={'driver'}
                    showAddDocumentModal={showAddDocumentModal}
                    setShowAddDocumentModal={setShowAddDocumentModal}
                    driverId={driver._id}
                  />
                )}
                <SubsectionDivider />
                <FlexBox
                  direction='column'
                  alignItems='left'
                  style={{
                    width: '100%',
                    marginTop: Metrics.base * 2,
                    marginLeft: Metrics.base * 6
                  }}
                >
                  {documents &&
                    documents.map((link) => {
                      return (
                        <>
                          {link.isActive &&
                            !excludeLinks.includes(link.documentType) && (
                              <Link
                                underline='always'
                                color={Colors.primary}
                                style={{
                                  // color: Colors.primary,
                                  marginBottom: Metrics.base * 2,
                                  marginRight: Metrics.base / 2,
                                  marginLeft: Metrics.base / 2
                                }}
                                target='_blank'
                                href={link.url}
                              >
                                <Text color={Colors.primary}>
                                  {link.documentType}
                                </Text>
                              </Link>
                            )}
                        </>
                      )
                    })}
                </FlexBox>
              </FlexBox>
            </Subsection>
          </FlexBox>
          <FlexBox
            direction='column'
            style={{ flex: 1, paddingLeft: Metrics.base }}
          >
            <LicenceInfo
              Colors={Colors}
              driver={driver}
              driversLicenceFront={driversLicenceFront}
              driversLicenceBack={driversLicenceBack}
              driversLicenceInfo={driversLicenceInfo}
              setShowAddDocumentModal={setShowAddDocumentModal}
            />
            <FlexBox direction='column' style={{ width: '100%' }}>
              <LinkedAsset
                asset={driver && driver.asset ? driver.asset : null}
                Colors={Colors}
                setShowLinkAssetModal={setShowLinkAssetModal}
                driverId={driverId}
              ></LinkedAsset>
              {linkAssetModal && (
                <LinkAssetToDriver
                  showLinkAssetModal={linkAssetModal}
                  setShowLinkAssetModal={setShowLinkAssetModal}
                  driverId={driverId}
                />
              )}
              <InviteDriverToApp driver={driver} />
            </FlexBox>
          </FlexBox>
        </>
      ) : (
        <Subsection
          style={{ marginTop: Metrics.base * 2, minHeight: 500, width: '100%' }}
        >
          <NoneSelected helperText='Select a device from the list on the left' />
        </Subsection>
      )}
    </FlexBox>
  )
}

export default DriverInfo
