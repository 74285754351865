import { useReactiveVar } from '@apollo/client'
import { Dialog } from '@material-ui/core'
import React, { useState } from 'react'
import { FlexBox, TableGraphQL } from '../../Components'
import { filters } from '../../Graphql/GraphQLClient'
import { GET_ASSET_GROUPS } from '../../Graphql/Queries/Asset/useAssetGroups'
import { useUser } from '../../Graphql/Queries/useUser'
import { checkVal } from '../../Helpers/Functions'
import { Metrics } from '../../Themes'
import AssetGroup from './AssetGroup'
import AssetGroupForm from './AssetGroupForm'
import NewAssetGroup from './NewAssetGroup'

const columns = [
  /*{
    Header: 'ID',
    accessor: '_id',
    Aggregated: ({ cell: { value } }) => `-`
  },*/
  {
    Header: 'Name',
    accessor: 'name',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Description',
    accessor: 'description',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const cleanDetails = ({ data }) => {
  const temp = data.map((group) => {
    return {
      ...group
    }
  })
  return temp
}

const _handleRowClick = ({
  row,
  setShowModal,
  setGroupId,
  setGroupOrgId,
  setGroupName,
  setGroupDescription
}) => {
  setShowModal(true)
  setGroupId(row.original._id)
  setGroupOrgId(row.original.organization._id)
  setGroupName(row.original.name)
  setGroupDescription(row.original.description)
}

const Groups = () => {
  const [dataState, setData] = useState([])
  const [showAssetsModal, setShowAssetsModal] = useState(false)
  const [showNewGroupModal, setShowNewGroupModal] = useState(false)
  const [showGroupDetailsModal, setShowGroupDetailsModal] = useState(false)
  const [groupId, setGroupId] = useState(null)
  const [groupOrgId, setGroupOrgId] = useState(null)
  const [groupName, setGroupName] = useState(null)
  const [groupDescription, setGroupDescription] = useState(null)
  const { orgId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  return (
    <FlexBox
      style={{
        width: '100%',
        paddingLeft: Metrics.base * 2,
        paddingRight: Metrics.base * 2
      }}
    >
      <TableGraphQL
        style={{ width: '100%' }}
        handleRowClick={(row) =>
          _handleRowClick({
            row,
            setShowModal: setShowAssetsModal,
            setGroupId,
            setGroupOrgId,
            setGroupName,
            setGroupDescription
          })
        }
        handleNewButton={() => [
          setShowNewGroupModal(true),
          setGroupId(null),
          setGroupOrgId(null),
          setGroupName(null),
          setGroupDescription(null)
        ]}
        columns={columns}
        hideAssignButton
        hideColumnFilters
        dataState={dataState}
        setData={setData}
        query={GET_ASSET_GROUPS}
        queryName='assetGroups'
        variables={{ skip: 0, limit: 10000 }}
        title='Asset Groups'
        objectName='Group'
        formatData={(data) =>
          cleanDetails({
            data
          })
        }
        sortBy={[{ _id: '_id' }]}
        defaultPageSize={20}
      />
      <Dialog
        open={showAssetsModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowAssetsModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <AssetGroup
          groupId={groupId}
          groupOrgId={groupOrgId}
          groupName={groupName}
          setShowEditModal={setShowGroupDetailsModal}
        />
      </Dialog>
      <Dialog
        open={showNewGroupModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowNewGroupModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <NewAssetGroup handleClose={() => setShowNewGroupModal(false)} />
      </Dialog>
      <Dialog
        open={showGroupDetailsModal}
        maxWidth='md'
        fullWidth
        onClose={() => setShowGroupDetailsModal(false)}
        scroll='paper'
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <AssetGroupForm
          handleClose={() => setShowGroupDetailsModal(false)}
          orgId={
            checkVal(orgId)
              ? orgId
              : orgOptions && checkVal(orgOptions[0])
              ? orgOptions[0]
              : null
          }
          object={
            checkVal(groupId)
              ? {
                  id: groupId,
                  name: groupName,
                  description: groupDescription
                }
              : null
          }
        />
      </Dialog>
    </FlexBox>
  )
}

export default Groups
