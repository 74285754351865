import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Colors, Utils } from '../../Themes'

export default class Icon extends Component {
  render() {
    const { name, color, size, style } = this.props

    let displaySize = {}

    switch (size) {
      case 'tiny':
        displaySize = Utils.icon.tiny
        break

      case 'small':
        displaySize = Utils.icon.small
        break

      case 'regular':
        displaySize = Utils.icon.regular
        break

      case 'large':
        displaySize = Utils.icon.large
        break

      case 'jumbo':
        displaySize = Utils.icon.jumbo
        break

      case 'huge':
        displaySize = Utils.icon.huge
        break

      case 'veryBig':
        displaySize = Utils.icon.veryBig
        break

      case 'massive':
        displaySize = Utils.icon.massive
        break

      default:
        displaySize = Utils.icon.regular
        break
    }

    return (
      <i
        className='material-icons'
        style={{ color: color || Colors.font, ...displaySize, ...style }}
      >
        {name}
      </i>
    )
  }
}

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}
