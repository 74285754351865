import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import { FlexBox } from '../../../Components'
import React, { useEffect, useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import BarChart from '../../../Components/Custom/D3Charts/BarChart.js'
import AreaChart from '../D3Charts/AreaChart'
import RunChart from '../../D3Charts/RunChart'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'
import { checkVal } from '../../../Helpers/Functions'

/*
let data = []
const sixMonthsInMilisec = 1.5768e10
for (let i = 0; i < 10; i++) {
  data.push({
    date: new Date(new Date().getTime() - sixMonthsInMilisec + i * 1750000000),
    value: Math.ceil(Math.random() * 40) + 20
  })
}

data.sort(function (a, b) {
  return a - b
})
*/

const AvgScoreVsTime = ({ id, averageScore, data, loading }) => {
  const Colors = useColors()
  const [width, setWidth] = useState(300)
  const containerRef = useRef()
  useEffect(() => {
    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth)
    }
  }, [containerRef])
  return (
    <Subsection
      forwardRef={containerRef}
      style={{
        width: '100%',
        height: 350
      }}
    >
      <FlexBox
        fillspace
        direction='column'
        style={{
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            margin: Metrics.base * 2,
            fontWeight: 'bold'
          }}
        >
          Average Safety Score vs Time
        </Text>
        <RunChart
          id={`${id}-${Math.round(averageScore)}`}
          xAccessor='d'
          yAccessor='avg'
          data={checkVal(averageScore) ? data : []}
          chartWidth={width - Metrics.base * 8}
          chartHeight={250}
          maxYValue={100}
          color={Colors[getScoreCategory(averageScore)]}
          gradientColor1={Colors[`${getScoreCategory(averageScore)}Opacity5`]}
          gradientColor2={Colors[`${getScoreCategory(averageScore)}Opacity3`]}
          loading={loading}
          showPoints={false}
        />
      </FlexBox>
    </Subsection>
  )
}

export default AvgScoreVsTime
