import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Colors, Metrics } from '../../../Themes'
import { ProcessingSpinner, Row } from '../../'
import { inlineStyles } from './Styles'

export default class ProcessingContent extends Component {
  render() {
    const { backgroundColor, spinnerColor, spinnerSize } = this.props

    return (
      <Row
        center='xs'
        middle='xs'
        style={{ ...inlineStyles.mainContainer, backgroundColor }}
      >
        <ProcessingSpinner color={spinnerColor} size={spinnerSize} />
      </Row>
    )
  }
}

ProcessingContent.propTypes = {
  backgroundColor: PropTypes.string,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number
}

ProcessingContent.defaultProps = {
  backgroundColor: Colors.transparent,
  spinnerSize: Metrics.base * 4
}
