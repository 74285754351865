import {
  ApolloClient,
  ApolloLink,
  split,
  concat,
  HttpLink,
  InMemoryCache,
  makeVar
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import {
  GET_DARKMODE,
  GET_FILTERS,
  GET_NOTIFICATION_SHOW,
  GET_SIDE_PANEL_EXPANDED
} from './Queries/localQueries'
import { resolvers } from './resolvers'
import { Metrics } from '../Themes'

const { createUploadLink } = require('apollo-upload-client')

export const appDimensions = makeVar({
  width: Metrics.window.width,
  height: Metrics.window.height
})

export const selectedDriverId = makeVar(null)
export const selectedAssetId = makeVar(null)
export const selectedDeviceId = makeVar(null)
export const selectedTripId = makeVar(null)
export const selectedEventId = makeVar(null)
export const selectedTripTotalEventId = makeVar(null)
export const selectedOtherEventId = makeVar(null)
export const selectedVVTDeviceId = makeVar(null)
export const selectedInspectionId = makeVar(null)
export const selectedServiceId = makeVar(null)

export const tripReplayTime = makeVar(null)
export const tripReplaySeekBarTime = makeVar(null)
export const tripReplayAkimaFunctions = makeVar({
  lng: null,
  lat: null,
  heading: null,
  speed: null
})
export const tripReplaySpeedLimitData = makeVar([])

export const selectedIds = makeVar({
  device: null,
  driver: null,
  asset: null,
  trip: null,
  event: null,
  VVTdevice: null,
  inspection: null
})

export const liveViewMapIds = makeVar({})

export const liveViewClusters = makeVar([])
export const selectedClusterCard = makeVar(null)
export const liveViewSpeed = makeVar(null)
export const liveViewSpeedLimit = makeVar(null)
export const liveViewTime = makeVar(null)
export const liveViewStopTime = makeVar(null)
export const liveViewDeviceLocations = makeVar({})
export const liveViewDefaultCenter = {
  lat: -29,
  lng: 25
}
export const liveViewMapCenter = makeVar(liveViewDefaultCenter)
export const liveViewDefaultZoom = 5
export const liveViewMapZoom = makeVar(liveViewDefaultZoom)

export const filters = makeVar({
  groupId: null,
  orgId: null,
  orgIds: null,
  startDate: null,
  endDate: null,
  assetId: null,
  driverId: null,
  deviceId: null,
  assetOrDriverOrDevice: 'asset',
  eventTypes: null,
  eventScoreGreaterThan: null,
  eventScoreLessThan: null,
  assetTypes: null,
  driverSearchString: null,
  assetSearchString: null,
  tripSearchString: null,
  eventSearchString: null
})

export const inTripTypes = [
  'SPEEDING',
  'PHONE_MOTION',
  'HARSH_BRAKING',
  'HARSH_ACCELERATION',
  'HARSH_CORNERING'
]

export const tripTotalTypes = [
  'PERCENT_SPEEDING',
  'TRIP_DISTANCE',
  'TRAVEL_TIME',
  'DRIVING_TIME',
  'CONTINUOUS_DRIVING',
  'NIGHT_DRIVING'
]

export const otherTypes = [
  'VEHICLE_INSPECTION',
  'QUIZ_SCORE',
  'VEHICLE_CRASH',
  'VEHICLE_INCIDENT',
  'VEHICLE_SERVICE'
]

export const eventFilters = makeVar({
  searchString: null,
  assetId: null,
  driverId: null,
  deviceId: null,
  tripId: null,
  filterByTypeName: null,
  eventTypes: null,
  tripTotalsTypes: tripTotalTypes,
  inTripTypes: inTripTypes,
  otherTypes: otherTypes,
  minScore: null,
  maxScore: null,
  hasVideo: null
})

export const driverFilters = makeVar({
  searchString: null,
  minSafetyScore: null,
  maxSafetyScore: null,
  safetyCategories: null
})

export const assetFilters = makeVar({
  searchString: null,
  assetTypes: [],
  minSafetyScore: null,
  maxSafetyScore: null,
  safetyCategories: null
})

export const deviceFilters = makeVar({
  searchString: null
})

export const tripFilters = makeVar({
  searchString: null,
  driverId: null,
  assetId: null,
  deviceId: null,
  filterByTypeName: 'asset',
  minSafetyScore: null,
  maxSafetyScore: null,
  safetyCategories: null
})

export const tripReplayCamera = makeVar({
  center: {
    lat: -33.9498945,
    lng: 19.5030406
  },
  hasStreetview: false,
  heading: 0,
  zoom: null
})

export const mapCamera = makeVar({
  liveMonitoring: {
    center: {
      lat: -33.9498945,
      lng: 19.5030406
    },
    zoom: null
  },
  tripHistory: {
    center: {
      lat: -33.9498945,
      lng: 19.5030406
    },
    zoom: null
  }
})

export const selectedTabs = makeVar({
  liveMonitoring: 'map-view',
  liveMonitoringAD: 'asset',
  tripHistory: 'overview',
  tripHistoryMapTab: 'trip-details',
  settingsPage: 'Fleet Management',
  settingsAdminTab: 'model sensitivity',
  settingsFleetTab: 'overview',
  settingsADDTabs: 'asset',
  safetyScoreADTabs: 'Driver',
  safetyScoreOverviewSelectTabs: 'overview',
  safetyScoreMainTabs: 'scoreOverview',
  driverSafetyTabs: 'overview',
  assetSafetyTabs: 'overview',
  tripSafetyTabs: 'overview',
  assetSettingsTabs: 'overview',
  driverSettingsTabs: 'overview',
  eventTypeTab: 'time-based',
  coachingRadioTabs: 'queue',
  coachingTabs: 'event-queue',
  driverBehaviour: 'combined-trip-score',

  driverMoreFiltersTab: 'select-card',
  assetMoreFiltersTab: 'select-card',
  deviceMoreFiltersTab: 'select-card',
  tripMoreFiltersTab: 'select-card',
  eventMoreFiltersTab: 'select-card'
})

const customMerge = ({ existing, incoming, typeName }) => {
  const existingArr = existing && existing[typeName]
  const incomingArr = incoming && incoming[typeName]
  if (!existingArr[0] && incomingArr[0]) {
    return incoming
  }
  if (
    existingArr[0] &&
    incomingArr[0] &&
    existingArr[existingArr.length - 1].__ref !==
      incomingArr[incomingArr.length - 1].__ref
  ) {
    return {
      totalCount: incoming.totalCount,
      [typeName]: [...existingArr, ...incomingArr]
    }
  }
  return existing
}

const cache = new InMemoryCache({
  typePolicies: {
    TopBottomDrivers: {
      keyFields: ['_id', 'driverId', 'safetyScore']
    },
    TopBottomTrips: {
      keyFields: ['_id', 'tripId', 'safetyScore']
    },
    TopBottomAssets: {
      keyFields: ['_id', 'assetId', 'safetyScore']
    },
    Cluster: {
      keyFields: ['id', 'deviceId', 'assetId', 'type']
    },
    AddressFromLatLongObject: {
      keyFields: ['town', 'province']
    },
    Query: {
      fields: {
        drivers: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'safetyCategory'
          ],
          merge(existing = { drivers: [] }, incoming) {
            return customMerge({ existing, incoming, typeName: 'drivers' })
          }
        },
        driversPaginated: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'safetyCategory'
          ]
        },
        trips: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'startDate',
            'endDate',
            'driverId',
            'assetId',
            'searchString',
            'safetyCategory',
            'onlyScoredTrips'
          ],
          merge(existing = { trips: [] }, incoming) {
            return customMerge({ existing, incoming, typeName: 'trips' })
          }
        },
        tripsPaginated: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'startDate',
            'endDate',
            'driverId',
            'assetId',
            'searchString',
            'safetyCategory',
            'onlyScoredTrips'
          ]
        },
        assets: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'assetType',
            'safetyCategory'
          ],
          merge(existing = { assets: [] }, incoming) {
            return customMerge({ existing, incoming, typeName: 'assets' })
          }
        },
        assetsPaginated: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'assetType',
            'safetyCategory'
          ]
          // merge(existing = { assets: [] }, incoming) {
          //  return customMerge({ existing, incoming, typeName: 'assets' })
          // }
        },
        devices: {
          keyArgs: ['orgIdsFilter', 'assetGroupId', 'searchString'],
          merge(existing = { devices: [] }, incoming) {
            return customMerge({ existing, incoming, typeName: 'devices' })
          }
        },
        events: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'typesFilter',
            'startDate',
            'endDate',
            'driverId',
            'assetId',
            'tripId',
            'scoreFilterGreater',
            'scoreFilterLess',
            'hasVideo'
          ],
          merge(existing = { events: [] }, incoming) {
            return customMerge({ existing, incoming, typeName: 'events' })
          }
        },
        eventsPaginated: {
          keyArgs: [
            'orgIdsFilter',
            'assetGroupId',
            'searchString',
            'typesFilter',
            'startDate',
            'endDate',
            'driverId',
            'assetId',
            'tripId',
            'scoreFilterGreater',
            'scoreFilterLess'
          ]
        },
        topAndBottomDriverSafeties: {
          keyArgs: ['sortByOrder', 'driverSafetyCategory', 'orgIdsFilter']
        },
        topAndBottomTripSafeties: {
          keyArgs: ['sortByOrder', 'tripSafetyCategory', 'orgIdsFilter']
        },
        topAndBottomAssetSafeties: {
          keyArgs: ['sortByOrder', 'assetSafetyCategory', 'orgIdsFilter']
        },
        selectedIds: {
          read() {
            return selectedIds()
          }
        },
        selectedTabs: {
          read() {
            return selectedTabs()
          }
        },
        appDimensions: {
          read() {
            return appDimensions()
          }
        },
        mapCamera: {
          read() {
            return mapCamera()
          }
        },
        filters: {
          read() {
            return filters()
          }
        }
      }
    }
  }
})

export const getLink = (token) => {
  const authToken = token ? `Bearer ${token}` : ''
  const wsAuthToken = token ? token : ''

  const httpUri =
    process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? process.env.REACT_APP_API_URL
      : 'http://dev-cybele-loadbalancer-1314564212.af-south-1.elb.amazonaws.com/graphql' // 'https://api.cybelefleet.tech/graphql' //
  // 'http://localhost:4000/graphql'
  const wssUri =
    process.env.REACT_APP_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? process.env.REACT_APP_WS_URL
      : 'ws://dev-cybele-loadbalancer-1314564212.af-south-1.elb.amazonaws.com/graphql' // 'ws://api.cybelefleet.tech/graphql' //
  // 'ws://localhost:4000/graphql' //
  const httpLink = new HttpLink({
    uri: httpUri,
    options: {
      connectionParams: {
        authToken
      }
    }
  })

  const uploadLink = createUploadLink({
    httpUri
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: authToken
      }
    }))
    return forward(operation)
  })

  const wsLink = new WebSocketLink({
    uri: wssUri,
    options: {
      reconnect: true,
      connectionParams: {
        authToken: wsAuthToken
      }
    }
  })
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    concat(authMiddleware, httpLink, uploadLink)
  )
}

const client = new ApolloClient({
  cache,
  link: getLink(process.browser ? localStorage.getItem('token') : null),
  resolvers
})

export const TOMTOM_API_KEY =
  process.env.REACT_APP_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'staging'
    ? process.env.REACT_APP_TOMTOM_API_KEY
    : 'fifrkonX1U35SNCsSTOZfJT5XZjxPGiZ' // 'lLUTkrbBbrzeBiuqpxIaSen9Z6U5D2Bf'

const darkmode = localStorage.getItem('darkmode')

var d = new Date()
d.setDate(d.getDate() - 7)
d.setHours(0, 0, 0)
d.setMilliseconds(0)

const initialData = {
  notification: {
    show: false,
    level: '',
    text: '',
    __typename: 'Notification'
  },
  sidePanelExpanded: false,
  darkmode: !darkmode === null || darkmode === 'true'
}

const writeDefaults = (cache) => {
  cache.writeQuery({
    query: GET_NOTIFICATION_SHOW,
    data: { notification: initialData.notification }
  })
  cache.writeQuery({
    query: GET_SIDE_PANEL_EXPANDED,
    data: { sidePanelExpanded: initialData.sidePanelExpanded }
  })
  cache.writeQuery({
    query: GET_DARKMODE,
    data: { darkmode: initialData.darkmode }
  })
}

writeDefaults(cache)
client.onResetStore(() => writeDefaults(cache))

export { client, cache }
