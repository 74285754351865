import React, { useState, useEffect, useRef } from 'react'
import {
  FlexBox,
  DataDisplay,
  Subsection,
  Text,
  ProgressBar,
  ProcessingSpinner
} from '../../'
import InfoBadge from '../InfoBadge'
import { Metrics, useColors } from '../../../Themes'
import { Link } from '@material-ui/core'
import SubsectionDivider from '../SubsectionDivider'
import LinkAssetToDevice from '../../../Containers/FleetManagement/LinkAssetToDevice'
import AddDeviceModal from '../AddDeviceModal'
import { useDocumentsByDriverId } from '../../../Graphql/Queries/useDocumentsByDriverId'
import { useDevice } from '../../../Graphql/Queries/Device/useDevice'
import {
  selectedAssetId,
  selectedDeviceId
} from '../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { InsetCardContainer } from '../SelectionPanel/Cards/CardsContainer'
import AssetCard from '../SelectionPanel/Asset/AssetCard'
import { useNavigation } from '../../CustomHooks/useNavigation'
import { Heading, InfoMessage, Subheading, Display } from '../../Typography'
import LargeDivider from '../../LargeDivider'
import Form from '../../Form'
import Button from '../../Button'
import NoneSelected from '../NoneSelected'
import { useVideoFetchSensitivity } from '../../../Graphql/Queries/Device/useVideoFetchSensitivity'
import { useChangeVideoFetchSensitivity } from '../../../Graphql/Mutations/useChangeVideoFetchSensitivity'
import { checkVal } from '../../../Helpers/Functions'

const thresholdOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' }
]

const getFormValues = (edit, thresholds) => [
  {
    name: 'isActive',
    initialValue:
      thresholds && thresholds.isActive ? thresholds.isActive : null,
    label: 'Enable Video Clip Saving',
    type: 'checkbox',
    disabled: !edit
  },
  {
    name: 'SPEEDING',
    initialValue:
      thresholds && thresholds.SPEEDING ? thresholds.SPEEDING : null,
    label: 'Speeding score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_BRAKING',
    initialValue:
      thresholds && thresholds.HARSH_BRAKING ? thresholds.HARSH_BRAKING : null,
    label: 'Harsh Braking score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_CORNERING',
    initialValue:
      thresholds && thresholds.HARSH_CORNERING
        ? thresholds.HARSH_CORNERING
        : null,
    label: 'Harsh Cornering score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  },
  {
    name: 'HARSH_ACCELERATION',
    initialValue:
      thresholds && thresholds.HARSH_ACCELERATION
        ? thresholds.HARSH_ACCELERATION
        : null,
    label: 'Harsh Acceleration score threshold',
    type: 'select',
    options: thresholdOptions,
    disabled: !edit,
    display: ({ isActive }) => isActive
  }
]

const VideoRecoringThresholds = ({ deviceId }) => {
  const Colors = useColors()
  const [edit, setEdit] = useState(false)
  const resetRef = useRef()
  const { data: thresholds, loading } = useVideoFetchSensitivity(deviceId)
  const [changeSensitivity, { changeSensitivityLoading }] =
    useChangeVideoFetchSensitivity()
  const [isSubmitting, setSubmitting] = useState(false)
  return (
    <FlexBox fillSpace style={{ width: '100%', paddingTop: Metrics.base * 2 }}>
      <Subsection style={{ minWidth: 200 }}>
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            alignItems: 'flex-start',
            paddingLeft: Metrics.base * 4,
            paddingRight: Metrics.base * 4
          }}
        >
          <Heading marginTop={4} marginBottom={2}>
            Video Recording Thresholds
          </Heading>
        </FlexBox>
        <LargeDivider />
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            alignItems: 'flex-start',
            paddingLeft: Metrics.base * 4,
            paddingRight: Metrics.base * 4
          }}
        >
          <Subheading marginTop={4} marginBottom={2}>
            Set event score thresholds for this device
          </Subheading>
          <Text color={Colors.textSecondary} font='caption'>
            These are the thresholds that trigger downloading and saving of
            video clips from this device.
          </Text>
          {loading ? (
            <FlexBox style={{ height: 200, width: '100%' }}>
              <ProcessingSpinner />
            </FlexBox>
          ) : (
            <Form
              key={thresholds && thresholds._id}
              hideFormBox
              style={{ width: '100%', marginBottom: 0, boxShadow: 'none' }}
              // editable={edit}
              showChanges
              resetRef={resetRef}
              cancelButton
              handleCancel={() => {
                setEdit(false)
                resetRef && resetRef.current && resetRef.current.click()
              }}
              onSubmit={(data) => {
                setSubmitting(true)
                changeSensitivity({
                  variables: {
                    internalDeviceId: deviceId,
                    isActive: data.isActive,
                    thresholds:
                      checkVal(data.SPEEDING) &&
                      checkVal(data.HARSH_ACCELERATION) &&
                      checkVal(data.HARSH_CORNERING) &&
                      checkVal(data.HARSH_BRAKING)
                        ? {
                            SPEEDING: Number(data.SPEEDING),
                            HARSH_ACCELERATION: Number(data.HARSH_ACCELERATION),
                            HARSH_CORNERING: Number(data.HARSH_CORNERING),
                            HARSH_BRAKING: Number(data.HARSH_BRAKING)
                          }
                        : null
                  },
                  onCompleted: () => {
                    setEdit(false)
                    setSubmitting(false)
                  },
                  onError: () => {
                    setSubmitting(false)
                  }
                })
              }}
              isSubmitting={isSubmitting}
              setSubmitting={setSubmitting}
              submitButtonTitle={'submit'}
              data={getFormValues(edit, thresholds)}
              hideSubmit={!edit}
            >
              {!edit && (
                <Button
                  primary
                  id='logout-button'
                  style={{
                    marginTop: Metrics.base * 2
                  }}
                  onClick={() => setEdit(true)}
                  textColor='white'
                  title='Edit Settings'
                  background={Colors.primary}
                />
              )}
            </Form>
          )}
        </FlexBox>
      </Subsection>
    </FlexBox>
  )
}

const LinkedAsset = ({ Colors, setShowLinkAssetModal, device }) => {
  const assetId = device && device.asset && device.asset._id
  const navigateTo = useNavigation()
  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        // justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%',
        marginTop: Metrics.base * 2
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Linked Asset'
        btnDisabled={!(device && device._id)}
        // leadIcon
        actionButton='Link Asset'
        handleButtonClick={() => setShowLinkAssetModal(true)}
        // label='Driver details'
      />
      <FlexBox
        direction='column'
        style={{ width: '100%', padding: Metrics.base * 2 }}
      >
        <InsetCardContainer name='Linked Asset'>
          <AssetCard
            assetId={assetId}
            onClick={() => [
              selectedAssetId(assetId),
              navigateTo({
                pathname: '/fleet-management/assets',
                queryParams: [{ name: 'asset', id: assetId }]
              })
            ]}
            backgroundColor={Colors.background}
          />
        </InsetCardContainer>
      </FlexBox>
    </Subsection>
  )
}

const DataUsage = ({ dataUsage }) => {
  const Colors = useColors()
  return (
    <FlexBox fillSpace style={{ width: '100%', paddingTop: Metrics.base * 2 }}>
      <Subsection style={{ width: '100%' }}>
        <FlexBox
          direction='column'
          style={{
            width: '100%',
            alignItems: 'flex-start',
            paddingLeft: Metrics.base * 4,
            paddingRight: Metrics.base * 4
          }}
        >
          <Heading marginTop={4} marginBottom={2}>
            Data Usage
          </Heading>
        </FlexBox>
        <LargeDivider />
        <FlexBox
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: Metrics.base * 2,
            marginBottom: Metrics.base * 2,
            paddingLeft: Metrics.base * 4,
            alignItems: 'flex-end'
          }}
        >
          <Display>
            {checkVal(dataUsage.mb) ? dataUsage.mb.toFixed(2) : 0}
          </Display>
          <Subheading
            marginLeft={1}
            marginBottom={1}
            color={Colors.textSecondary}
          >
            MB
          </Subheading>
          <Text marginLeft={2} marginBottom={1.2} color={Colors.textSecondary}>
            This Month
          </Text>
        </FlexBox>
      </Subsection>
    </FlexBox>
  )
}

const DeviceInfo = () => {
  const Colors = useColors()
  const deviceId = useReactiveVar(selectedDeviceId)
  const { data: device } = useDevice(deviceId)
  const [linkAssetModal, setShowLinkAssetModal] = useState(false)
  const [showUpdateDeviceModal, setShowUpdateDeviceModal] = useState(false)
  console.log('device', device)
  const dataUsageInfo = device && device.dataUsage ? device.dataUsage : null
  return (
    <FlexBox
      direction='row'
      alignItems='flex-start'
      style={{
        width: '100%',
        paddingRight: Metrics.base * 2,
        paddingLeft: Metrics.base * 2
      }}
    >
      {deviceId ? (
        <>
          <FlexBox
            direction='column'
            style={{ flex: 1, paddingRight: Metrics.base }}
          >
            <Subsection style={{ width: '100%', marginTop: Metrics.base * 2 }}>
              <InfoBadge
                style={{
                  width: '100%',
                  alignItems: 'left',
                  justifyContent: 'flex-start'
                }}
                noBoxShadow
                //   color={getImportanceScoreColor(aggregatedScore)}
                // colorOpacity={1}
                btnDisabled={!deviceId}
                value='Device details'
                // leadIcon
                actionButton='Edit Details'
                handleButtonClick={() => setShowUpdateDeviceModal(true)}
                // status='Driving'
                // label='Driver details'
              />
              {showUpdateDeviceModal && (
                <AddDeviceModal
                  showAddDeviceModal={showUpdateDeviceModal}
                  setShowAddDeviceModal={setShowUpdateDeviceModal}
                  object={device}
                />
              )}
              {linkAssetModal && (
                <LinkAssetToDevice
                  showLinkAssetModal={linkAssetModal}
                  setShowLinkAssetModal={setShowLinkAssetModal}
                  deviceId={deviceId}
                />
              )}
              <SubsectionDivider />
              <FlexBox direction='column' style={{ width: '100%' }}>
                <DataDisplay
                  firstItem={true}
                  label='Name'
                  data={device.name ? device.name : '-'}
                />

                {/*<DataDisplay
              label='Type'
              data={device.deviceType ? device.deviceType : '-'}
            />*/}

                {/*<DataDisplay
              label='Manufacturer Type'
              data={device.manufacturerType ? device.manufacturerType : '-'}
              lastItem={true}
            />*/}
                {/*<DataDisplay
              label='Model'
              data={device.model ? device.model : '-'}
              lastItem={true}
            />*/}
                <DataDisplay
                  label='IMEI'
                  data={device.imei ? device.imei : '-'}
                  lastItem={true}
                />
                <DataDisplay
                  label='VVT ID'
                  data={device.deviceId ? device.deviceId : '-'}
                  lastItem={true}
                />
              </FlexBox>
            </Subsection>
            <VideoRecoringThresholds deviceId={deviceId} />
          </FlexBox>
          <FlexBox
            direction='column'
            style={{ flex: 1, paddingLeft: Metrics.base }}
          >
            <LinkedAsset
              device={device}
              Colors={Colors}
              setShowLinkAssetModal={setShowLinkAssetModal}
            ></LinkedAsset>
            {dataUsageInfo && (
              <DataUsage device={device} dataUsage={dataUsageInfo} />
            )}
          </FlexBox>
        </>
      ) : (
        <Subsection
          style={{ marginTop: Metrics.base * 2, minHeight: 500, width: '100%' }}
        >
          <NoneSelected helperText='Select a device from the list on the left' />
        </Subsection>
      )}
    </FlexBox>
  )
}

export default DeviceInfo
