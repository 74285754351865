import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { driverDataTransform, driverFragment } from './../Driver/useDriver'
import { assetDataTransform, assetFragment } from './../Asset/useAsset'

export const deviceFragment = gql`
  fragment deviceFragment on Device {
    _id
    organization {
      _id
      name
    }
    asset {
      ...assetFragment
      driver {
        ...driverFragment
      }
    }
    name
    imei
    deviceId
    isActive
    iccID1
    iccID2
    dataUsage {
      _id
      deviceId
      mb
      eventId
      channel
      createdAt
    }
  }
  ${assetFragment}
  ${driverFragment}
`

export const GET_DEVICE = gql`
  query device($id: String!) {
    device(id: $id) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`

export const deviceDataTransform = ({ data }) => {
  const asset = data.asset ? data.asset : null
  const driver = asset && asset.driver ? asset.driver : null
  return {
    ...data,
    driver: driver && driverDataTransform({ data: driver }),
    driverId: driver ? driver._id : null,
    driverName: driver ? `${driver.firstName} ${driver.lastName}` : null,
    asset: asset && assetDataTransform({ data: asset }),
    assetId: asset ? asset._id : null,
    assetName: asset ? asset.name : null,
    orgId: data.organization ? data.organization._id : null,
    VVTdeviceId: data.deviceId
  }
}

export const useDevice = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_DEVICE, {
    variables: { id },
    queryName: 'device',
    errorMessage: 'Failed to fetch Device',
    dataTransform: (data) => deviceDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
