import React from 'react'
import { FlexBox, Icon } from '..'
import { useColors } from '../../Themes'
import Text from '../Text'

export const Display = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <Text font='display' color={Colors.secondary} {...props}>
      {children}
    </Text>
  )
}

export const Heading = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <Text font='heading' color={Colors.secondary} {...props}>
      {children}
    </Text>
  )
}

export const Subheading = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <Text font='subheading' color={Colors.secondary} {...props}>
      {children}
    </Text>
  )
}

export const Title = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <Text font='title' color={Colors.primary} {...props}>
      {children}
    </Text>
  )
}

export const Title2 = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <Text style={{ fontSize: 18 }} color={Colors.textSecondary} {...props}>
      {children}
    </Text>
  )
}

export const ErrorMessage = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <FlexBox>
      <Icon size='small' color={Colors.textSecondaryOpacity1} name='error' />
      <Text
        {...props}
        marginLeft={1 / 2}
        color={Colors.secondaryOpacity2}
        font='caption'
      >
        {children}
      </Text>
    </FlexBox>
  )
}

export const InfoMessage = ({ children, ...props }) => {
  const Colors = useColors()
  return (
    <FlexBox>
      <Icon size='small' color={Colors.textSecondaryOpacity1} name='info' />
      <Text
        {...props}
        marginLeft={1 / 2}
        color={Colors.secondaryOpacity2}
        font='caption'
      >
        {children}
      </Text>
    </FlexBox>
  )
}
