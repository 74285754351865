import { Dialog, Tooltip, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { FlexBox, IconButton } from '..'
import { Metrics, useColors, Utils } from '../../Themes'
import { Heading, Subheading } from '../Typography'

const SelectedObjectHeader = ({
  titleContent,
  title,
  loading,
  handleClose,
  rightContent,
  centerContent,
  TooltipContent,
  profilePicUrl
}) => {
  const Colors = useColors()
  const [showProfilePic, setShowProfilePic] = useState(false)
  const [StyledTooltip] = useState(
    withStyles((theme) => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  return (
    <FlexBox
      direction='row'
      style={{
        justifyContent: 'space-between',
        width: '100%',
        height: Metrics.base * (profilePicUrl ? 8 : 5),
        backgroundImage: `linear-gradient(to right, ${Colors.primary} , #1A78E5)`
        // backgroundColor: Colors.primary
      }}
    >
      <FlexBox>
        <IconButton
          iconSize='small'
          onClick={handleClose}
          iconColor='white'
          iconName='close'
          iconStyle={{
            marginLeft: Metrics.base * 2,
            marginRight: Metrics.base
          }}
        />
        {profilePicUrl && (
          <>
            <Dialog
              open={showProfilePic}
              // maxWidth='md'
              // fullWidth
              // style={{ width: mainContainerWidth, maxWidth: mainContainerWidth }}
              onClose={() => setShowProfilePic(false)}
              scroll='paper'
              aria-labelledby='scroll-dialog-title'
              aria-describedby='scroll-dialog-description'
            >
              <FlexBox
                style={{
                  width: 350,
                  height: 400,
                  backgroundImage: `url(${profilePicUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              />
            </Dialog>
            <FlexBox
              onClick={() => setShowProfilePic(true)}
              style={{
                cursor: 'pointer',
                width: 45,
                height: 45,
                borderRadius: 25,
                backgroundImage: `url(${profilePicUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                border: `1px solid ${Colors.background}`,
                boxShadow: Utils.boxShadowDark,
                marginRight: Metrics.base * 2
              }}
            />
          </>
        )}
        {titleContent ? (
          titleContent
        ) : (
          <Subheading
            loading={loading}
            loadingBarWidth={100}
            loadingBarHeight={20}
            color='white'
          >
            {title}
          </Subheading>
        )}
      </FlexBox>
      {centerContent}
      <FlexBox>
        {rightContent}
        {TooltipContent && (
          <StyledTooltip
            interactive
            title={<TooltipContent setShowTooltip={() => {}} />}
            placement='right'
          >
            <IconButton
              iconName='link'
              iconColor='white'
              iconStyle={{
                marginLeft: Metrics.base * 2,
                marginRight: Metrics.base * 2
              }}
            />
          </StyledTooltip>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default SelectedObjectHeader
