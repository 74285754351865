import { useD3 } from '../CustomHooks/useD3'
import React, { useEffect, useState } from 'react'
import * as d3 from 'd3'
import { data as dataIn } from './areaData'
import { Metrics, useColors } from '../../Themes'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'
import { checkVal } from '../../Helpers/Functions'

// const data = dataIn.map((d) => ({ value: d.value, date: new Date(d.date) }))

const AreaChart = ({
  data = [],
  id,
  strokeColor,
  gradientColors,
  gradientColor1,
  gradientColor2,
  col1,
  col2,
  startDate,
  endDate,
  maxYValue,
  minYValue,
  chartWidth = 300,
  chartHeight = 250,
  loading
}) => {
  const Colors = useColors()
  const margin = { top: 15, right: 20, bottom: 20, left: 30 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const dataChangedCheck =
    data && data[0] && (data[0]._id || data[0].id)
      ? data[0]._id || data[0].id
      : false

  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth
      const curve = d3.curveLinear
      const y = d3
        .scaleLinear()
        .domain([
          minYValue ? minYValue : 0,
          maxYValue ? maxYValue : d3.max(data, (d) => d.value)
        ])
        .nice()
        .range([height - margin.bottom, margin.top])
      const x = d3
        .scaleTime()
        .domain(
          startDate && endDate
            ? [startDate, endDate]
            : d3.extent(data, (d) => d.date)
        )
        .range([margin.left, width - margin.right])

      const area = d3
        .area()
        .curve(curve)
        .x((d) => x(d.date))
        .y0(y(0))
        .y1((d) => y(d.value))

      const xAxis = (g) =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .attr('color', Colors.secondaryOpacity1)
          .call(
            d3
              .axisBottom(x)
              .ticks(width / 80)
              .tickSizeOuter(0)
          )

      const yAxis = (g) =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .attr('color', Colors.secondaryOpacity1)
          .call(d3.axisLeft(y))
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .select('.tick:last-of-type text')
              .clone()
              .attr('x', 3)
              .attr('text-anchor', 'start')
              .attr('font-weight', 'bold')
              .text(data.y)
          )

      svg.select('.x-axis').call(xAxis)
      svg.select('.y-axis').call(yAxis)

      svg
        .select('.area-path')
        .datum(data)
        .attr('stroke', strokeColor)
        .attr('d', area)

      // gridlines in y axis function
      function make_y_gridlines() {
        return d3.axisLeft(y).ticks(5)
      }

      // add the Y gridlines
      /*
      svg
        .append('g')
        .attr('class', 'grid')
        .attr('transform', `translate(${margin.left},0)`)
        .attr('color', Colors.dividerColor)
        .call(
          make_y_gridlines()
            .tickSize(-width + margin.left)
            .tickFormat('')
        )
        */

      const ruleX = svg
        .select('.rule-x')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 5)
        .attr('y1', margin.top - 6)
        .attr('y2', height - margin.bottom - 1)
        .attr('x1', -1)
        .attr('x2', -1)

      const ruleY = svg
        .select('.rule-y')
        .attr('stroke', Colors.secondaryOpacity3)
        .attr('stroke-dasharray', 5)
        .attr('y1', -1)
        .attr('y2', -1)
        .attr('x1', margin.left - 6)
        .attr('x2', width - margin.right - 1)

      svg.on('mousemove touchmove', (event) => {
        const x = d3.pointer(event, svg.node())[0] + 0.5
        const y = d3.pointer(event, svg.node())[1] + 0.5
        if (x > margin.left && x < width - margin.right)
          ruleX.attr('x1', x).attr('x2', x)
        if (y > margin.top && y < height - margin.bottom)
          ruleY.attr('y1', y).attr('y2', y)
      })

      // set the gradient
      svg
        .select(`.linearGradient`)
        .attr('id', 'area-gradient')
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', y(0))
        .attr('x2', 0)
        .attr('y2', y(maxYValue ? maxYValue : d3.max(data, (d) => d.value)))
        .selectAll('stop')
        .data([
          {
            offset: '0%',
            color: gradientColors
              ? gradientColors[0]
              : gradientColor1
              ? gradientColor1
              : Colors.primaryOpacity5
          },
          {
            offset: '100%',
            color: gradientColors
              ? gradientColors[1]
              : gradientColor2
              ? gradientColor2
              : Colors.primaryOpacity2
          }
        ])
        .enter()
        .append('stop')
        .attr('offset', function (d) {
          return d.offset
        })
        .attr('stop-color', function (d) {
          return d.color
        })
    },
    [
      data.length,
      gradientColor1,
      gradientColor2,
      dataChangedCheck,
      chartWidth,
      chartHeight,
      startDate,
      endDate
    ]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth + Metrics.base * 2,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        key={dataChangedCheck}
        ref={ref}
        style={{
          height: chartHeight,
          width: chartWidth + Metrics.base * 2,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <rect
          x={plotAreaX}
          y={plotAreaY}
          width={plotAreaWidth}
          height={plotAreaHeight}
          fill={Colors.background}
          stroke={Colors.dividerColor}
        ></rect>
        <path
          key={dataChangedCheck}
          id={dataChangedCheck}
          className='area-path'
          style={{ fill: 'url(#area-gradient)' }}
        />
        <linearGradient key={col1} className={`linearGradient`} />
        <line className='rule-x' />
        <line className='rule-y' />
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
            // backgroundColor: 'red'
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      )}
    </div>
  )
}

export default AreaChart
