import React, { useState, useEffect } from 'react'
import {
  ReactTableComp,
  FormGraphQL,
  Button,
  ModalAlert,
  ProcessingSpinner,
  IconButton,
  Text,
  MaterialInput
} from '../'
import { useColors, Metrics } from '../../Themes'
import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../../Graphql/Queries/localQueries'
// import { GET_FIREBASE_NOTIFICATION_SHOW } from '../../Graphql/Queries'
import LargeDivider from '../LargeDivider'
import FlexBox from '../FlexBox'

const pollIntervalOptions = [
  { label: 'None', value: 0 },
  { label: '5 seconds', value: 5000 },
  { label: '10 seconds', value: 10000 },
  { label: '30 seconds', value: 30000 }
]

const findIndex = (arr, item) => {
  for (var i in arr) {
    if (arr[i].id === item.id) return i
  }
  return -1
}

const TableGraphQL = ({
  title,
  dataState,
  setData,
  selectedObject,
  setSelectedObject,
  query,
  variables,
  skip,
  queryName,
  formatData,
  sortBy,
  defaultPageSize,
  handleRowClick,
  getBorderLeftStyles,
  columns,
  objectName,
  showMutationModal,
  setShowMutationModal,
  renderHeading,
  renderModalContent,
  MutationModalComponent,
  createMutation,
  updateMutation,
  mutationFormSchema,
  handleMutationSubmit,
  style,
  pollInterval,
  showPollIntervalSelect,
  showRefreshButton,
  hideNewButton,
  handleNewButton,
  disabled,
  fetchPolicy,
  createSubscription,
  updateSubscription,
  createSubscriptionName,
  updateSubscriptionName,
  refetchOnNotification,
  headerContentRight,
  hideColumnFilters,
  hideColumnHeaders
}) => {
  // const [pageIndex, setPageIndex] = useState(0)
  // const [pageRecords, setPageRecords] = useState(20)
  const [pollIntervalValue, setPollIntervalValue] = useState(0)
  const queryVariables = variables ? variables : { skip: 0, limit: 0 }

  const Colors = useColors()

  // const { data: dataDarkmode } = useQuery(GET_DARKMODE)
  // const darkmode = dataDarkmode ? dataDarkmode.darkmode : true

  const { data, loading, error, refetch, networkStatus, subscribeToMore } =
    useQuery(query, {
      variables: queryVariables,
      skip: skip,
      notifyOnNetworkStatusChange: true,
      pollInterval: pollInterval ? pollInterval : pollIntervalValue,
      fetchPolicy,
      onCompleted: (data) => setData(formatData(data[queryName]))
    })

  useEffect(() => {
    createSubscription &&
      subscribeToMore({
        document: createSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const newItem = subscriptionData.data[createSubscriptionName]
          const newData = [...prev[queryName], newItem]
          // console.log(newItem, newData)
          setData(formatData(newData))
          return {
            ...prev,
            [queryName]: newData
          }
        }
      })
    updateSubscription &&
      subscribeToMore({
        document: updateSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          const newItem = subscriptionData.data[updateSubscriptionName]
          const newData = [...prev[queryName]]
          const index = findIndex(prev[queryName], newItem)
          if (newItem.isActive !== undefined && !newItem.isActive)
            newData.splice(index, 1)
          // else if (index === -1) newData.push(newItem)
          else newData[index] = newItem
          setData(formatData(newData))
          return {
            ...prev,
            [queryName]: newData
          }
        }
      })
  }, [subscribeToMore])

  /*
  const {
    data: { firebaseNotification }
  } = useQuery(GET_FIREBASE_NOTIFICATION_SHOW)
  const [liveUpdate, setLiveUpdate] = useState({ visible: false, id: '' })
  

  useEffect(() => {
    if (refetchOnNotification && firebaseNotification.show) {
      // console.log('refetching...')
      refetch().then(() => {
        setLiveUpdate({ visible: true, id: firebaseNotification.contractId })
        setTimeout(() => setLiveUpdate({ visible: false, id: '' }), 3000)
      })
    }
  }, [refetchOnNotification, firebaseNotification, refetch])
  */

  // console.log(firebaseNotification, 'liveUpdate', liveUpdate)
  // console.log('error', error)

  return (
    <React.Fragment>
      <div style={style}>
        {error ? (
          <Text marginTop={2} align='center'>
            An error occured. Please refresh.
          </Text>
        ) : loading && networkStatus !== 4 && networkStatus !== 6 ? (
          <div
            style={{
              border: `1px solid ${Colors.subsectionBackground}`,
              borderRadius: Metrics.radius,
              marginTop: Metrics.base * 2
            }}
          >
            <div style={{ height: 200 }}>
              <ProcessingSpinner
                loading={`Loading ${title ? title : 'data'}...`}
                mb={3}
                size={36}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            {showPollIntervalSelect || !renderHeading ? (
              <div
                style={{
                  padding: Metrics.base * 1,
                  paddingLeft: Metrics.base * 4,
                  height: Metrics.base * 7,
                  // marginBottom: Metrics.base * 2,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom:
                    showPollIntervalSelect || showRefreshButton
                      ? `1px solid ${Colors.background}`
                      : 'none'
                }}
              >
                {!renderHeading && (
                  <div
                    style={{
                      display: 'flex',
                      width: 150,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    {networkStatus === 4 ? (
                      <>
                        <ProcessingSpinner ml={3} />

                        <Text
                          color={Colors.primary}
                          style={{ marginLeft: Metrics.base * 2 }}
                        >
                          refreshing...
                        </Text>
                      </>
                    ) : (
                      showRefreshButton && (
                        <>
                          <IconButton
                            buttonStyles={{
                              marginLeft: Metrics.base * 2
                            }}
                            iconName='refresh'
                            disabled={networkStatus === 6}
                            onClick={() => refetch()}
                            color={Colors.primary}
                          ></IconButton>
                          <Text
                            color={Colors.primaryLight}
                            style={{ marginLeft: Metrics.base * 1 }}
                          >
                            Refresh table
                          </Text>
                        </>
                      )
                    )}
                  </div>
                )}
                {!hideNewButton && <div> </div>}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  {networkStatus === 6 && (
                    <React.Fragment>
                      <ProcessingSpinner />
                      <Text
                        color={Colors.primary}
                        style={{
                          marginLeft: Metrics.base * 2,
                          marginRight: Metrics.base * 2
                        }}
                      >
                        fetching data...
                      </Text>
                    </React.Fragment>
                  )}
                  {showPollIntervalSelect && (
                    <MaterialInput
                      type='select'
                      label='Poll interval *'
                      styles={{
                        outerContainer: {
                          width: Metrics.base * 20,
                          marginRight: Metrics.base * 2,
                          marginLeft: Metrics.base * 4,
                          marginTop: Metrics.base
                        }
                      }}
                      value={pollIntervalValue}
                      selectValues={pollIntervalOptions.map(
                        (option) => option.value
                      )}
                      selectDisplayValues={pollIntervalOptions.map(
                        (option) => option.label
                      )}
                      handleChange={(value) => setPollIntervalValue(value)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div style={{ width: '100%', height: Metrics.base * 2 }}></div>
            )}
            <ReactTableComp
              networkStatus={networkStatus}
              loading={loading}
              defaultPageSize={defaultPageSize}
              // liveUpdate={liveUpdate}
              handleRowClick={(row) => {
                setSelectedObject &&
                  setSelectedObject({ ...row.original, index: row.index })
                handleRowClick(row)
              }}
              hideColumnHeaders={hideColumnHeaders}
              hideColumnFilters={hideColumnFilters}
              title={title}
              getBorderLeftStyles={(cell) => getBorderLeftStyles(cell)}
              renderHeading={
                renderHeading
                  ? () => {
                      return (
                        <FlexBox direction='column' style={{ width: '100%' }}>
                          <div
                            style={{
                              width: '100%',
                              height: Metrics.base * 10,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                              // borderBottom: `1px solid ${Colors.dividerColor}`
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: 350,
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                            >
                              {networkStatus === 4 ? (
                                <ProcessingSpinner ml={3} />
                              ) : (
                                showRefreshButton && (
                                  <IconButton
                                    buttonStyles={{
                                      marginLeft: Metrics.base * 2
                                    }}
                                    iconName='refresh'
                                    iconSize='large'
                                    disabled={networkStatus === 6}
                                    onClick={() => refetch()}
                                    color={Colors.primaryOpacity2}
                                  ></IconButton>
                                )
                              )}
                              <Text
                                color={Colors.primary}
                                marginLeft={3}
                                marginTop={3}
                                marginBottom={3}
                                font='heading'
                              >
                                {title} {/*<i>table</i>*/}
                              </Text>
                            </div>
                            {!hideNewButton && (
                              <Button
                                primary
                                title={'New ' + objectName}
                                id={`table-action-button-New-${objectName}`}
                                background={Colors.primary}
                                style={{
                                  marginLeft: Metrics.base * 2,
                                  marginRight: Metrics.base * 2
                                }}
                                iconLeft='add'
                                noBorder
                                rounded
                                textColor='white'
                                onClick={
                                  handleNewButton
                                    ? handleNewButton
                                    : () => {
                                        setSelectedObject(null)
                                        setShowMutationModal(true)
                                      }
                                }
                              />
                            )}
                            {headerContentRight}
                          </div>
                          <LargeDivider />
                        </FlexBox>
                      )
                    }
                  : null
              }
              columns={columns}
              data={data ? formatData(data[queryName]) : []}
              dataState={dataState}
              setData={setData}
              sortBy={sortBy}
            />
          </div>
        )}
      </div>
      {showMutationModal ? (
        <ModalAlert
          title={selectedObject ? `Update ${objectName}` : `New ${objectName}`}
          body='Enter details below'
        >
          {renderModalContent ? (
            renderModalContent({ data: selectedObject })
          ) : MutationModalComponent ? (
            <MutationModalComponent
              object={selectedObject}
              listQuery={{
                query: query,
                name: queryName,
                variables: queryVariables
              }}
              setListData={(data) => setData(formatData(data))}
              setListObject={(object, index) => {
                const newData = [...dataState]
                newData[index] = object
                setData(newData)
              }}
              onDelete={() => setShowMutationModal(false)}
              handleClose={() => setShowMutationModal(false)}
            />
          ) : (
            <FormGraphQL
              object={selectedObject}
              disabled={disabled}
              createMutation={createMutation}
              updateMutation={updateMutation}
              listQuery={{
                query: query,
                name: queryName,
                variables: queryVariables
              }}
              setListData={(data) => setData(formatData(data))}
              setListObject={(object, index) => {
                const newData = [...dataState]
                newData[index] = object
                setData(newData)
              }}
              onDelete={() => {
                var newData = [...dataState]
                newData.splice(selectedObject.index, 1)
                setData(newData)
                setShowMutationModal(false)
              }}
              handleCancel={() => setShowMutationModal(false)}
              handleSubmit={handleMutationSubmit}
              objectName={objectName}
              formSchema={mutationFormSchema}
            />
          )}
        </ModalAlert>
      ) : null}
    </React.Fragment>
  )
}

TableGraphQL.defaultProps = {
  // renderModalContent: () => {},
  handleRowClick: () => {},
  getBorderLeftStyles: () => {},
  formatData: (data) => data,
  renderHeading: true
}

export default TableGraphQL
