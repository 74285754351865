import React from 'react'
import { useColors, Metrics } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Card, { handleSelectCard } from '../Cards/Card'
import { useStatusSummary } from '../../../../Graphql/Queries/useStatusSummary'
import { checkVal, hhmmss } from '../../../../Helpers/Functions'
import { useAsset } from '../../../../Graphql/Queries/Asset/useAsset'
import { ErrorMessage, StatusContent } from '../Driver/LiveDriverCard'
import AssetCardTTList from './AssetCardTTList'
import {
  liveViewDeviceLocations,
  liveViewSpeed,
  selectedAssetId,
  selectedDeviceId,
  selectedVVTDeviceId
} from '../../../../Graphql/GraphQLClient'
import useRecentLocations from '../../../../Containers/LiveMonitoring/useRecentLocations'
import useAkimaAnimation from '../../../../Containers/LiveMonitoring/useAkimaAnimation'
import { FlexBox } from '../../..'
import { useReactiveVar } from '@apollo/client'

const LiveSpeed = ({ selected, locationsSpeed, Colors }) => {
  const trackingSpeed = useReactiveVar(liveViewSpeed)
  const speed = selected ? trackingSpeed : locationsSpeed
  return (
    <Text color={Colors.secondary}>
      {checkVal(speed) ? `${Math.round(speed)} km/h` : ''}
    </Text>
  )
}

const LiveAssetCard = ({
  assetId,
  navigateTo,
  onClick,
  selected,
  borderBottom
}) => {
  const Colors = useColors()
  const { data, loading: assetLoading } = useAsset(assetId)
  const { data: status, loading: statusLoading } = useStatusSummary(
    data.deviceId
  )
  const deviceLocations = useReactiveVar(liveViewDeviceLocations)
  const locations = deviceLocations && deviceLocations[data.deviceId]
  const speed = locations && locations[0] && locations[0].speed
  const signalDelay =
    locations &&
    locations.length > 0 &&
    new Date().getTime() - locations[locations.length - 1].timestamp
  /*const { speed, signalDelay } = useAkimaAnimation({
    locations,
    timeStep: 5000
  })*/
  return (
    <Card
      id={assetId}
      data={data}
      selected={selected}
      typeName='asset'
      setSelectedId={selectedAssetId}
      navigateTo={navigateTo}
      onClick={
        onClick
          ? onClick
          : () => {
              selectedDeviceId(data.deviceId)
              selectedVVTDeviceId(data.VVTdeviceId ? data.VVTdeviceId : null)
              handleSelectCard({
                typeName: 'asset',
                id: assetId,
                navigateTo: navigateTo,
                setSelectedId: selectedAssetId
              })
            }
      }
      TooltipContent={({ setShowTooltip }) => (
        <AssetCardTTList
          assetId={assetId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      borderBottom={borderBottom}
      loading={assetLoading}
      titleContent={
        <>
          {status && !status.isParked && signalDelay && (
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                backgroundColor:
                  signalDelay > 120000
                    ? Colors.HIGH_RISK
                    : signalDelay > 20000
                    ? Colors.LOW_RISK
                    : Colors.SAFE,
                marginRight: Metrics.base
              }}
            />
          )}
          <Title color={Colors.primary}>{data && data.name}</Title>
        </>
      }
      bodyContent={
        <FlexBox justifyContent='space-between' style={{ width: '100%' }}>
          <Text marginBottom={1}>{data && data.registrationNum}</Text>
          <Text font='caption' color={Colors.secondaryOpacity2}>
            {signalDelay > 120000
              ? `No signal for ${hhmmss(signalDelay / 1000)}`
              : signalDelay > 20000
              ? 'Slow signal'
              : 'Good signal'}
          </Text>
        </FlexBox>
      }
      topRightContent={
        <StatusContent data={data} status={status} Colors={Colors} />
      }
      topRightLoading={assetLoading || statusLoading}
      bottomRightContent={
        <>
          {!checkVal(data.deviceId) ? (
            <ErrorMessage text='No Linked Device' Colors={Colors} />
          ) : (
            signalDelay <= 120000 && (
              <LiveSpeed
                selected={selected}
                locationsSpeed={speed}
                Colors={Colors}
              />
            )
          )}
        </>
      }
      bottomRightLoading={assetLoading || statusLoading}
      bottomLeftContent={
        data && checkVal(data.driverName) ? (
          <Text font='caption' color={Colors.secondaryOpacity3}>
            Driver: {data.driverName}
          </Text>
        ) : (
          <ErrorMessage text='No Linked Driver' Colors={Colors} />
        )
      }
    />
  )
}

export default LiveAssetCard
