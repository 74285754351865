import React, { useEffect } from 'react'
import { FlexBox, ProcessingSpinner, Text } from '../../..'
import { Metrics, useColors } from '../../../../Themes'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { NetworkStatus } from '@apollo/client'

const handleFetchMore = ({ fetchMore, skip }) => {
  fetchMore({
    variables: {
      skip,
      limit: 10
    }
  })
}

export const InsetCardContainer = ({ name, children, style }) => {
  const Colors = useColors()
  return (
    <FlexBox
      style={{
        width: '100%',
        position: 'relative',
        ...style
      }}
    >
      <FlexBox
        style={{
          width: '100%',
          border: `1px solid ${Colors.dividerColor}`,
          borderRadius: Metrics.radius,
          overflow: 'hidden'
        }}
      >
        {children}
      </FlexBox>
      <div
        style={{
          zIndex: 500,
          position: 'absolute',
          backgroundColor: 'white',
          left: 15,
          top: -6,
          paddingLeft: Metrics.base,
          paddingRight: Metrics.base,
          borderRadius: Metrics.radius
        }}
      >
        <Text color={Colors.textSecondary} font='caption'>
          {name.toUpperCase()}
        </Text>
      </div>
    </FlexBox>
  )
}

const FetchMore = ({
  isVisible,
  fetchMore,
  fetchingMore,
  skip,
  shouldFetchMore
}) => {
  useEffect(() => {
    if (shouldFetchMore && isVisible) {
      handleFetchMore({ fetchMore, skip: skip })
    }
  }, [isVisible])
  return (
    <FlexBox
      style={{
        width: '100%',
        height: '100%'
        // backgroundColor: 'red'
      }}
    >
      {fetchingMore && <ProcessingSpinner size={30} />}
    </FlexBox>
  )
}

export const CardsContainer = ({
  children,
  style,
  innerContainerStyle,
  automaticallyFetchmore = true,
  networkStatus,
  fetchMore = () => {},
  horizontal
}) => {
  const Colors = useColors()
  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const items = children && children.props && children.props.items
  const shouldFetchMore =
    items && items.length >= 10 && items.length % 10 === 0 && !fetchingMore
  return (
    <FlexBox
      direction={'column'}
      fillSpace
      style={{
        position: 'relative',
        width: '100%',
        height: horizontal ? '100%' : 'auto',
        ...style
      }}
    >
      <FlexBox
        direction={horizontal ? 'row' : 'column'}
        id='selection_panel_scroll_container'
        fillSpace
        style={{
          justifyContent: 'flex-start',
          paddingTop: horizontal ? 0 : Metrics.base,
          paddingBottom: horizontal ? 0 : Metrics.base * 4,
          flex: 1,
          width: '100%',
          height: horizontal ? '100%' : 'auto',
          overflowY: horizontal ? 'auto' : 'scroll',
          overflowX: horizontal ? 'scroll' : 'auto',
          borderTop: `1px solid ${Colors.dividerColor}`,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          backgroundColor: Colors.background,
          ...innerContainerStyle
        }}
      >
        {children}
        <FlexBox
          style={{
            width: horizontal ? 70 : '100%',
            minWidth: horizontal ? 70 : 'auto',
            height: horizontal ? '100%' : 70,
            minHeight: horizontal ? 'auto' : 70
          }}
        >
          {automaticallyFetchmore && (
            <ReactVisibilitySensor>
              {({ isVisible }) => {
                return (
                  <FetchMore
                    shouldFetchMore={shouldFetchMore}
                    skip={items.length}
                    fetchMore={fetchMore}
                    fetchingMore={fetchingMore}
                    isVisible={isVisible}
                  />
                )
              }}
            </ReactVisibilitySensor>
          )}
        </FlexBox>
      </FlexBox>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Colors.backgroundOpacity2,
            zIndex: 100
          }}
        >
          <ProcessingSpinner size={50} />
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default CardsContainer
