import React from 'react'
import { Metrics, Colors } from '../../Themes'
import { Text, Subsection, Icon } from '../'
import Link from '@material-ui/core/Link'

export const Copyright = () => {
  return (
    <Text font='paragraph'>
      {'Copyright © '}
      <Link color='inherit' target='_blank' href='https://www.catenabloc.com'>
        Block<i>Harvest</i>
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Text>
  )
}

const Footer = (props) => {
  return (
    <Subsection
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        padding: Metrics.base * 2
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Icon
          size={'small'}
          name={'flash_on'}
          color={
            props.darkmode
              ? Colors.darkTheme.secondary
              : Colors.lightTheme.secondary
          }
          style={{
            paddingRight: Metrics.base * 0.5
          }}
        />
        <Text font='caption' style={{ marginRight: Metrics.base }}>
          {'Powered by '}
          <Link
            color='inherit'
            target='_blank'
            href='https://www.catenabloc.com'
          >
            <u>Catena Block Solutions</u>
          </Link>
          {'. '}
        </Text>
        {/* <Copyright /> */}
      </div>
    </Subsection>
  )
}

export default Footer
