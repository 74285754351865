import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const incidentFragment = gql`
  fragment incidentFragment on VehicleIncident {
    _id
    createdAt
    asset {
      _id
      name
      registrationNum
    }
    driver {
      _id
      firstName
      lastName
      idNumber
    }
    vehicleIncidentDate
    document {
      _id
      filename
      documentType
      url
    }
    incidentImages {
      _id
      filename
      documentType
      url
    }
    description
    latitude
    longitude
  }
`

export const GET_VEHICLE_INCIDENT = gql`
  query vehicleIncident($id: String!) {
    vehicleIncident(id: $id) {
      ...incidentFragment
    }
  }
  ${incidentFragment}
`

export const incidentDataTransform = (data) => {
  if (data) {
    return {
      ...data
    }
  }
}

export const useIncident = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_VEHICLE_INCIDENT, {
    variables: { id },
    queryName: 'vehicleIncident',
    errorMessage: 'Failed to fetch Incident',
    dataTransform: (data) => incidentDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
