import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_DRIVER = gql`
  mutation createDriver(
    $orgId: String
    $firstName: String
    $lastName: String
    $dob: GraphqlDateTime
    $gender: String
    $idNumber: String
    $isActive: Boolean
    $nationality: String
    $cellphoneNumber: String
    $medicalAidNumber: String
    $licenseNumber: String
    $driverRestriction: [Int]
    $vehicleRestriction: [Int]
    $professionalDrivingPermit: [String]
    $professionalDrivingPermitExpiryDate: GraphqlDateTime
    $doe: GraphqlDateTime
    $driverStatus: String
    $doe: GraphqlDateTime
    $userType: String
    $code: String
    $firstIssue: GraphqlDateTime
  ) {
    createDriver(
      orgId: $orgId
      firstName: $firstName
      lastName: $lastName
      dob: $dob
      gender: $gender
      idNumber: $idNumber
      isActive: $isActive
      nationality: $nationality
      cellphoneNumber: $cellphoneNumber
      medicalAidNumber: $medicalAidNumber
      licenseNumber: $licenseNumber
      driverRestriction: $driverRestriction
      vehicleRestriction: $vehicleRestriction
      professionalDrivingPermit: $professionalDrivingPermit
      professionalDrivingPermitExpiryDate: $professionalDrivingPermitExpiryDate
      doe: $doe
      driverStatus: $driverStatus
      doe: $doe
      userType: $userType
      code: $code
      firstIssue: $firstIssue
    ) {
      _id
      organization {
        _id
        name
      }
      firstName
      lastName
    }
  }
`
export const useCreateDriver = () => {
  const [createDriver, { loading: createDriverLoading }] = useMutation(
    CREATE_DRIVER,
    {
      mutationName: 'createDriver',
      successMessage: 'Driver created successfully',
      errorMessage: 'Driver creation failed'
    }
  )

  return [createDriver, { createDriverLoading }]
}
