import React, { useEffect, useState } from 'react'
import { Table } from '../../../Table'
import { useAssets } from '../../../../Graphql/Queries/Asset/useAssets'
import { checkVal, thousandsSeparator } from '../../../../Helpers/Functions'
import { FlexBox } from '../../..'
import { Metrics, useColors } from '../../../../Themes'
import AssetFilters, { handleClearFilters } from './AssetFilters'
import { NetworkStatus } from '@apollo/client'
import { Heading } from '../../../Typography'
import Button from '../../../Button'

const driverColumns = [
  {
    Header: 'Display Id',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Name',
    accessor: 'name',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Type',
    accessor: 'type',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'VIN',
    accessor: 'vin',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Registration',
    accessor: 'registrationNum',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const _handleRowClick = ({ row, setAssetId }) => {
  setAssetId(row.original._id)
}

const AssetSelectionTable = ({ setAssetId }) => {
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const {
    data: assetsData,
    totalCount,
    fetchMore,
    networkStatus
  } = useAssets({})
  const Colors = useColors()

  const assetsLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  useEffect(() => {
    if (assetsData && assetsData.length > 0) {
      setData(assetsData)
    } else {
      setData([])
    }
  }, [assetsData])

  useEffect(() => {
    if (
      checkVal(totalCount) &&
      totalCount > 0 &&
      checkVal(pageSize) &&
      pageSize > 0
    ) {
      const tempPageCount = Math.ceil(totalCount / pageSize)
      setPageCount(tempPageCount)
      setPageIndex(Math.min(tempPageCount - 1, pageIndex))
    } else {
      setPageCount(1)
      setPageIndex(0)
    }
  }, [totalCount, pageSize])

  return (
    <Table
      handleRowClick={(row) => _handleRowClick({ row, setAssetId })}
      loading={assetsLoading}
      fetchingMore={fetchingMore}
      fetchMore={fetchMore}
      totalCount={totalCount}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      manualPagination={true}
      setPageIndex={setPageIndex}
      columns={driverColumns}
      hideColumnFilters
      hideNewButton
      hideAssignButton
      showRefreshButton
      dataState={dataState}
      setData={setData}
      title={'Assets'}
      sortBy={[{ _id: '_id' }]}
      defaultPageSize={10}
      renderHeading={() => (
        <FlexBox
          style={{
            width: '100%',
            height: Metrics.base * 10,
            padding: Metrics.base * 2,
            justifyContent: 'space-between'
          }}
        >
          <Heading>
            {checkVal(totalCount)
              ? `${thousandsSeparator(totalCount)} Assets`
              : 'Assets'}
          </Heading>
          <Button
            condensed
            title='Reset'
            onClick={() => {
              handleClearFilters()
            }}
            textColor='white'
            background='red'
            style={{ marginRight: Metrics.base * 2 }}
          />
        </FlexBox>
      )}
      subHeaderContent={
        <FlexBox
          fillSpace
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${Colors.dividerColor}`,
            paddingTop: Metrics.base * 2
          }}
        >
          <AssetFilters direction='row' maxInputWidth={400} />
        </FlexBox>
      }
    />
  )
}

export default AssetSelectionTable
