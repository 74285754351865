import gql from 'graphql-tag'

export const GET_DRIVERS = gql`
  query drivers {
    drivers @client {
      id
      name
      locationName
      location
    }
  }
`

export const GET_DARKMODE = gql`
  {
    darkmode @client
  }
`
export const filtersFragment = gql`
  fragment filtersFragment on Filters {
    site
    inspectionZone
    unit
    eventType
    roadTypes
    startDate
    endDate
    center {
      lat
      lng
    }
  }
`

export const GET_FILTERS = gql`
  query {
    filters @client {
      ...filtersFragment
    }
  }
  ${filtersFragment}
`

export const GET_NOTIFICATION_SHOW = gql`
  query {
    notification @client {
      show
      level
      text
    }
  }
`

export const GET_SIDE_PANEL_EXPANDED = gql`
  {
    sidePanelExpanded @client
  }
`
