import { useD3 } from '../../Components/CustomHooks/useD3'
import React, { useEffect, useState } from 'react'
import * as d3 from 'd3'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../../Components/FlexBox'
import ProcessingSpinner from '../../Components/Processing/Spinner'
import { useTrip } from '../../Graphql/Queries/Trip/useTrip'
import { getSpeedChartData, getSpeedLimitData } from './EventBreakdown'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'
import { useReactiveVar } from '@apollo/client'
import {
  tripReplaySeekBarTime,
  tripReplayTime
} from '../../Graphql/GraphQLClient'
import {
  addGradient,
  addZoom,
  createAxes,
  createRulers
} from '../../Components/D3Charts/RunChart'
import { css } from 'aphrodite'
import { Styles } from '../../Components/D3Charts/Styles'

const getEventData = (events) => {
  const data = []
  for (var i = 0; i < events.length; i++) {
    if (events[i].startDate && events[i].endDate) {
      data.push({
        ...events[i],
        startDate: events[i].startDate,
        endDate: events[i].endDate,
        value: events[i].normScore
      })
    }
  }
  return data.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
}

let xRef = () => {}
let yRef = () => {}

let timeRef = 0

const drawData = ({
  svg,
  data,
  eventData,
  x,
  y,
  plotAreaX,
  plotAreaY,
  plotAreaWidth,
  plotAreaHeight,
  xAccessor,
  yAccessor,
  showPoints,
  Colors,
  vehicleMaxSpeed,
  margin,
  width,
  height,
  speedLimitData,
  strokeColor
}) => {
  xRef = x
  yRef = y
  if (showPoints) {
    svg
      .selectAll('.dot-outer-test')
      .data(data)
      .attr('cx', (d) => x(d[xAccessor]))
      .attr('cy', (d) => y(d[yAccessor]))
      .attr('r', 5)

    svg
      .selectAll('.dot-inner')
      .data(data)
      .attr('cx', (d) => x(d[xAccessor]))
      .attr('cy', (d) => y(d[yAccessor]))
      .attr('r', 3)
  }

  svg
    .select('.data-path')
    .datum(data)
    .attr(
      'd',
      d3
        .line()
        .x((d) => x(d[xAccessor]))
        .y((d) => y(d[yAccessor]))
    )

  const curve = d3.curveLinear
  const area = d3
    .area()
    .curve(curve)
    .x((d) => x(d[xAccessor]))
    .y0(y(0))
    .y1((d) => y(d[yAccessor]))

  svg.select('.area-path').datum(data).attr('d', area)

  svg
    .select('.max-speed-line')
    .attr('stroke', Colors.MODERATE_RISK)
    .attr('stroke-dasharray', 5)
    .attr('y1', y(vehicleMaxSpeed))
    .attr('y2', y(vehicleMaxSpeed))
    .attr('x1', margin.left)
    .attr('x2', width - margin.right)

  svg
    .select(`.time-cursor-outer`)
    .attr('x', x(timeRef) - 5)
    .attr('y', plotAreaY)
    .attr('width', 10)
    .attr('height', plotAreaHeight)
    .attr('rx', 5)
  // .attr('stroke', Colors.secondary)

  svg
    .select(`.time-cursor`)
    .attr('x1', x(timeRef))
    .attr('y1', plotAreaY)
    .attr('x2', x(timeRef))
    .attr('y2', plotAreaY + plotAreaHeight)
    .attr('stroke', '#4C525B')
    .attr('stroke-width', 1)

  svg.select(`.time-cursor-icon`).attr('x', x(timeRef) - 10)

  const curve2 = d3.curveLinear
  for (var i = 0; i < eventData.length; i++) {
    const eventAreaData = [
      {
        date: eventData[i].startDate,
        value: 140
      },
      {
        date: eventData[i].endDate,
        value: 140
      }
    ]

    const area2 = d3
      .area()
      .curve(curve2)
      .x((d) => x(d.date))
      .y0(y(0))
      .y1((d) => y(d.value))
    svg
      .select(`.area-path-${eventData[i]._id}`)
      .datum(eventAreaData)
      .attr('fill', Colors[`${eventData[i].type}Opacity3`])
      // .attr('stroke', Colors.speedingText)
      // .attr('fill-opacity', data[i].value / 10)
      .attr('d', area2)
  }

  for (var i = 0; i < speedLimitData.length; i++) {
    /*
    svg
      .select(`.speed-limit-line-${i}-start`)
      .attr('x1', x(speedLimitData[i].x1))
      .attr('y1', y(140))
      .attr('x2', x(speedLimitData[i].x1))
      .attr('y2', y(0))
      .attr('stroke', Colors.dividerColorOpacity2)
      .attr('stroke-width', 1)

    svg
      .select(`.speed-limit-line-${i}-end`)
      .attr('x1', x(speedLimitData[i].x2))
      .attr('y1', y(140))
      .attr('x2', x(speedLimitData[i].x2))
      .attr('y2', y(0))
      .attr('stroke', Colors.dividerColorOpacity2)
      .attr('stroke-width', 1)
      */

    /*
    svg
      .select(`.speed-limit-line-${i}-back`)
      .attr('x', x(speedLimitData[i].x1))
      .attr('y', y(140))
      .attr('height', y(10))
      .attr('width', x(speedLimitData[i].x2) - x(speedLimitData[i].x1))
      .attr('fill', Colors.subsectionBackground)
*/
    svg
      .select(`.speed-limit-line-${i}`)
      .attr('x1', x(speedLimitData[i].x1))
      .attr('y1', y(speedLimitData[i].y1))
      .attr('x2', x(speedLimitData[i].x2))
      .attr('y2', y(speedLimitData[i].y2))
      .attr('stroke', Colors.HIGH_RISK)
      .attr('stroke-width', 1)

    const area = d3
      .area()
      .curve(curve)
      .x((d) => x(d.date))
      .y0(y(0))
      .y1((d) => y(d.value))

    const areaSpeedLimit = d3
      .area()
      .curve(curve)
      .x((d) => x(d.date))
      .y0(y(0))
      .y1((d) => y(d.speedLimit))

    svg
      .select('.area-path')
      .datum(data)
      .attr('stroke', strokeColor)
      .attr('d', area)

    svg
      .select('.area-path-speed-limit')
      .datum(data)
      .attr('stroke', Colors.HIGH_RISK)
      .attr('stroke-dasharray', 2)
      .attr('opacity', 0.6)
      .attr('d', areaSpeedLimit)
  }
}

const SpeedChartTimeBar = ({
  tripId,
  events,
  strokeColor,
  gradientColors,
  gradientColor1,
  gradientColor2,
  maxYValue = 140,
  chartWidth = 300,
  chartHeight = 250,
  xAccessor = 'date',
  yAccessor = 'value',
  showPoints
}) => {
  const Colors = useColors()
  const { data: trip, loading } = useTrip(tripId)
  const [data, setData] = useState([])
  const [eventData, setEventData] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [speedLimitData, setSpeedLimitData] = useState([])
  const time = useReactiveVar(tripReplayTime)
  timeRef = time
  useEffect(() => {
    if (trip && trip.tripPoints && trip.tripPoints.length > 0) {
      const temp = getSpeedChartData(trip.tripPoints)
      setData(temp)
      setSpeedLimitData(getSpeedLimitData(temp))
    }
  }, [trip])
  useEffect(() => {
    if (trip) {
      setStartDate(new Date(trip.startTime))
      setEndDate(new Date(trip.endTime))
    }
  }, [trip])

  useEffect(() => {
    if (events && events.length > 0) {
      const temp = getEventData(events)
      setEventData(temp)
    }
  }, [events])

  const { data: asset } = useAsset(trip && trip.assetId)
  const vehicleMaxSpeed = asset && asset.maxSpeed ? asset.maxSpeed : 80

  const margin = { top: 35, right: 20, bottom: 20, left: 30 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom

  const dataString = JSON.stringify(data) + JSON.stringify(eventData)
  const minTime = startDate
    ? startDate.getTime()
    : d3.min(data, (d) => d[xAccessor].getTime())
  const maxTime = endDate
    ? endDate.getTime()
    : d3.max(data, (d) => d[xAccessor].getTime())
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth
      const { xAxis, yAxis, x, y } = createAxes({
        svg,
        data,
        width,
        height,
        plotAreaWidth,
        margin,
        maxYValue,
        startDate,
        endDate,
        Colors,
        xAccessor,
        yAccessor
      })
      createRulers({ svg, Colors, margin, width, height })
      addGradient({
        svg,
        data,
        x,
        y,
        maxYValue,
        id: tripId,
        Colors,
        gradientColors,
        gradientColor1,
        gradientColor2
      })
      drawData({
        svg,
        data,
        eventData,
        x,
        y,
        plotAreaX,
        plotAreaY,
        plotAreaWidth,
        plotAreaHeight,
        showPoints,
        xAccessor,
        yAccessor,
        Colors,
        vehicleMaxSpeed,
        margin,
        width,
        height,
        time,
        speedLimitData,
        strokeColor
      })
      addZoom({
        svg,
        redrawData: (newX, newY) =>
          drawData({
            svg,
            data,
            eventData,
            x: newX,
            y: newY,
            plotAreaX,
            plotAreaY,
            plotAreaWidth,
            plotAreaHeight,
            showPoints,
            xAccessor,
            yAccessor,
            Colors,
            vehicleMaxSpeed,
            margin,
            width,
            height,
            time,
            speedLimitData,
            strokeColor
          }),
        margin,
        extent: { x: width - margin.right, y: height - margin.bottom },
        scaleExtent: { min: 1, max: 30 },
        xAxis,
        yAxis,
        x,
        y,
        showPoints
      })
      svg.on('click', (event) => {
        const xPos = d3.pointer(event, svg.node())[0] + 0.5
        const yPos = d3.pointer(event, svg.node())[1] + 0.5
        const date = xRef && xRef.invert && xRef.invert(xPos)
        date && tripReplayTime(date.getTime())
        date && tripReplaySeekBarTime(date.getTime())
      })

      function dragstarted() {
        // d3.select(this).attr('stroke', 'black')
      }

      function dragged(event) {
        const xPos = event.x
        // d3.select(this).attr('x', xPos)
        const date = xRef && xRef.invert && xRef.invert(xPos)
        const time = data && date.getTime()
        if (time) {
          if (time < minTime) {
            tripReplayTime(minTime)
            tripReplaySeekBarTime(minTime)
          } else if (time > maxTime) {
            tripReplayTime(maxTime)
            tripReplaySeekBarTime(maxTime)
          } else {
            tripReplayTime(time)
            tripReplaySeekBarTime(time)
          }
        }
      }

      function dragended() {
        // d3.select(this).attr('stroke', null)
      }

      /*
      function dragmove(event) {
        var x = event.x
        var y = event.y
        console.log('hello')
        // d3.select(this).attr('transform', 'translate(' + x + ',' + y + ')')
      }
      var drag = d3.drag().on('drag', dragmove)
      */
      svg
        .select('.time-cursor-icon')
        .call(
          d3
            .drag()
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended)
        )
      svg
        .select('.time-cursor-outer')
        .call(
          d3
            .drag()
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended)
        )
    },
    [dataString, chartWidth, chartHeight, startDate, endDate, showPoints]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight + 50,
        width: chartWidth + Metrics.base * 2,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        ref={ref}
        style={{
          height: chartHeight + 50,
          width: chartWidth + Metrics.base * 2,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <svg
          width='20'
          x={xRef(time) - 10}
          y='-95'
          className='time-cursor-icon'
          style={{ cursor: 'pointer' }}
          viewBox='0 0 213 276'
        >
          <path
            d='M91.4286 262.096C91.4286 210.479 1 181.658 1 104.233C1 50.4657 46.2143 1 104.347 1C162.48 1 212 46.1644 212 104.233C212 183.808 117.265 210.479 117.265 262.096C117.265 275 104.347 275 104.347 275C104.347 275 91.4286 275 91.4286 262.096Z'
            fill='#268AFF'
            stroke='white'
          />
          <line
            x1='46'
            y1='80.5'
            x2='168.864'
            y2='80.5'
            stroke='white'
            stroke-width='15'
          />
          <line
            x1='46'
            y1='115.5'
            x2='168.864'
            y2='115.5'
            stroke='white'
            stroke-width='15'
          />
        </svg>
        <g className='x-axis' />
        <g className='y-axis' />
        <line
          x1={plotAreaX}
          y1={plotAreaY + plotAreaHeight}
          x2={plotAreaX + plotAreaWidth}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <line
          x1={plotAreaX}
          y1={plotAreaY}
          x2={plotAreaX}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <clipPath id={`${tripId}_clip_path_${chartWidth}_${chartHeight}`}>
          <rect
            x={plotAreaX}
            y={plotAreaY}
            width={plotAreaWidth}
            height={plotAreaHeight}
          />
        </clipPath>
        <g
          className={css(Styles({}).plotArea)}
          style={{
            clipPath: `url(#${tripId}_clip_path_${chartWidth}_${chartHeight})`
          }}
        >
          <rect
            className='plot-area-rect'
            x={plotAreaX}
            y={plotAreaY}
            width={plotAreaWidth}
            height={plotAreaHeight}
            fill={Colors.background}
            stroke={Colors.dividerColor}
          ></rect>
          <line className='rule-x' />
          <line className='rule-y' />
          {speedLimitData.map((line, index) => {
            return (
              <>
                <rect className={`speed-limit-line-${index}-back`} />
                <line className={`speed-limit-line-${index}-start`} />
                <line className={`speed-limit-line-${index}-end`} />
                <line className={`speed-limit-line-${index}`} />
              </>
            )
          })}
          <path className='area-path-speed-limit' style={{ fill: 'none' }} />
          <path
            key={`${tripId}_path`}
            className='area-path'
            style={{ fill: `url(#area-gradient-${tripId})` }}
          />
          <linearGradient key={tripId} className={`linearGradient-${tripId}`} />
          <line className='max-speed-line' />
          {eventData.map((a, i) => (
            <path key={i} className={`area-path-${a._id}`} />
          ))}
          <line
            className='time-cursor'
            x1={xRef(time)}
            y1={plotAreaY}
            x2={xRef(time)}
            y2={plotAreaY + plotAreaHeight}
          />
          <rect
            className={`${css(
              Styles({ Colors }).timeCursorOuter
            )} time-cursor-outer`}
            x={xRef(time) - 5}
            y={plotAreaY}
            width={10}
            height={plotAreaHeight}
          />
        </g>
      </svg>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
            // backgroundColor: 'red'
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      )}
    </div>
  )
}

export default SpeedChartTimeBar
