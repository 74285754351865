import { FlexBox } from '../../../Components'
import React, { useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { Display } from '../../../Components/Typography'
import AvgSafetyScore from '../../../Components/Custom/SafetyMetrics/AvgSafetyScore'
import AvgScoreVsTime from '../../../Components/Custom/SafetyMetrics/AvgScoreVsTime'
import CurrentScoreDist from '../../../Components/Custom/SafetyMetrics/CurrentScoreDist'
import { useAssetSafetyOverTime } from '../../../Graphql/Queries/Asset/useAssetSafetyOverTime'
import { useAssetSafetyScoreDistribution } from '../../../Graphql/Queries/Asset/useAssetSafetyScoreDistribution'
import { useAssetSafetyAverages } from '../../../Graphql/Queries/Asset/useAssetSafetyAverages'
import AssetRankingTable from './AssetRankingTable'

const AssetSafetyMetrics = () => {
  const Colors = useColors()
  const { data: assetSafetyOverTime, loading: assetSafetyOverTimeLoading } =
    useAssetSafetyOverTime({ period: 'year' })
  const {
    data: { average: currentScoreAvg, coordinates: currentScoreDist },
    loading: currentScoreDistLoading
  } = useAssetSafetyScoreDistribution({ period: 'none' })
  const { data: safetyAverages, loading: safetyAveragesLoading } =
    useAssetSafetyAverages()
  return (
    <>
      <FlexBox
        direction='row'
        fillspace
        style={{
          width: '100%',
          marginTop: Metrics.base * 2
        }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <AvgSafetyScore
            avgChangeMonth={safetyAverages && safetyAverages.averageDiffMonth}
            avgChangeWeek={safetyAverages && safetyAverages.averageDiffWeek}
            avgChangeYear={safetyAverages && safetyAverages.averageDiffYear}
            avgScore={
              safetyAverages &&
              safetyAverages.average &&
              Math.round(safetyAverages.average)
            }
          />
        </FlexBox>
        <FlexBox style={{ flex: 3, padding: Metrics.base }}>
          <AvgScoreVsTime
            data={assetSafetyOverTime}
            loading={assetSafetyOverTimeLoading || safetyAveragesLoading}
            id='assetSafetyOverTime'
            averageScore={safetyAverages && safetyAverages.average}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox style={{ padding: Metrics.base, width: '100%' }}>
        <AssetRankingTable />
      </FlexBox>
      <FlexBox
        direction='row'
        fillspace
        style={{
          width: '100%'
        }}
      >
        <FlexBox style={{ flex: 1, padding: Metrics.base }}>
          <CurrentScoreDist
            title='Current Scores Distribution'
            data={currentScoreDist}
            loading={currentScoreDistLoading}
          />
        </FlexBox>
      </FlexBox>
    </>
  )
}

export default AssetSafetyMetrics
