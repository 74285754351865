import gql from 'graphql-tag'
import { deviceFragment } from '../Queries/Device/useDevice'

export const UPDATE_DEVICE = gql`
  mutation updateDevice(
    $deviceId: Int
    $name: String
    $unitId: Int
    $siteId: Int
    $isActive: Boolean
  ) {
    updateDevice(
      deviceId: $deviceId
      name: $name
      unitId: $unitId
      siteId: $siteId
      isActive: $isActive
    ) {
      ...deviceFragment
    }
  }
  ${deviceFragment}
`
