import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { coachingLessonFragment } from './useCoachingLessonsByDriverId'

export const GET_COACHING_LESSON = gql`
  query coachingLesson($id: String!) {
    coachingLesson(id: $id) {
      ...coachingLessonFragment
    }
  }
  ${coachingLessonFragment}
`

export const coachingLessonDataTransform = ({ data }) => {
  if (data) {
    return {
      ...data
    }
  }
}

export const useCoachingLesson = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_COACHING_LESSON,
    {
      variables: { id },
      queryName: 'coachingLesson',
      errorMessage: 'Failed to fetch Coaching Lesson',
      dataTransform: (data) => coachingLessonDataTransform({ data }),
      skip: !id
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
