import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { deviceDataTransform, deviceFragment } from './useDevice'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import { assetFilters, filters } from '../../GraphQLClient'

export const GET_DEVICES = gql`
  query devicesPaginated(
    $orgIdsFilter: [String]
    $assetGroupId: String
    #$searchString: String
    $skip: Int
    $limit: Int
  ) {
    devicesPaginated(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      #searchString: $searchString
      skip: $skip
      limit: $limit
    ) {
      totalCount
      devices {
        ...deviceFragment
      }
    }
  }
  ${deviceFragment}
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data:
      data.devices && data.devices.map((a) => deviceDataTransform({ data: a }))
  }
}

const getDeviceVariables = ({
  groupId,
  orgIdsFilter,
  searchString,
  skip = 0,
  limit = 10000
}) => {
  let variables = {
    skip,
    limit
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(searchString)) {
    variables.searchString = searchString
  }
  return variables
}

export const useDevices = () => {
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { searchString } = useReactiveVar(assetFilters)
  const {
    data: devices,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DEVICES, {
    variables: getDeviceVariables({
      groupId,
      orgIdsFilter: orgIds,
      searchString
    }),
    queryName: 'devicesPaginated',
    errorMessage: 'Failed to fetch Devices',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    devices && devices.length > 0 ? setData(devices.devices) : setData([])
    devices && devices.totalCount
      ? setTotalCount(devices.totalCount)
      : setTotalCount(0)
  }, [devices])

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
