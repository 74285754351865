import { useReactiveVar } from '@apollo/client'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import {
  FlexBox,
  Icon,
  InfoBadge,
  ProcessingSpinner,
  Subsection,
  Text
} from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import SubsectionDivider from '../../../Components/Custom/SubsectionDivider'
import Status from '../../../Components/Status'
import {
  Display,
  Heading,
  Subheading,
  Title,
  Title2
} from '../../../Components/Typography'
import { selectedDriverId } from '../../../Graphql/GraphQLClient'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { useEventsByTripId } from '../../../Graphql/Queries/Event/useEventsByTripId'
import { useTrip } from '../../../Graphql/Queries/Trip/useTrip'
import { useTripSafetyByTripId } from '../../../Graphql/Queries/Trip/useTripSafetyByTripId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import {
  checkVal,
  hhmmss,
  thousandsSeparator
} from '../../../Helpers/Functions'
import { Images, Metrics, useColors } from '../../../Themes'
import { getScoreCategory } from '../Asset/Breakdown'
import TrendIndicator from '../TrendIndicator'
import TripsSafetyList from './TripsSafetyList'
import { useSafetyScoreTrends } from './useSafetyScoreTrends'
import { ScoreExplanation } from '../Trip/Overview'
import { DescriptionContent } from '../Trip/Breakdown'

export const CategoryScore = ({
  score,
  loading,
  category,
  hideBorderRight
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace
      style={{
        borderRight: hideBorderRight
          ? 'none'
          : `1px solid ${Colors.dividerColor}`,
        minHeight: 130,
        maxHeight: 300,
        paddingLeft: Metrics.base,
        paddingRight: Metrics.base
      }}
    >
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          padding: Metrics.base * 2
        }}
      >
        <Subheading
          loading={loading}
          loadingBarHeight={12}
          loadingBarWidth={50}
        >
          {checkVal(score) ? `${Math.round(score)}/100` : '-'}
        </Subheading>
        <Text
          loading={loading}
          loadingBarHeight={8}
          loadingBarWidth={120}
          color={Colors.textSecondary}
          marginTop={1}
        >
          {category}
        </Text>
      </FlexBox>
      <ScoreCircleChart
        score={checkVal(score) ? score : null}
        maxScore={100}
        color={Colors[getScoreCategory(score)]}
        backgroundColor={Colors.secondary}
        size={70}
      />
    </FlexBox>
  )
}

export const getTotalEvents = (eventTypeCounts) => {
  const keys = Object.keys(eventTypeCounts)
  var count = 0
  for (var i = 0; i < keys.length; i++) {
    const val = eventTypeCounts[keys[i]]
    if (checkVal(val) && !isNaN(Number(val))) count += Number(val)
  }
  return count
}

const DriverOverview = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const driverId = useReactiveVar(selectedDriverId)

  const {
    data: driverSafetyData,
    totalScore,
    healthAttitudeScore,
    educationScore,
    combinedScore: drivingBehaviourScore,
    loading: driverSafetyLoading
  } = useDriverSafetyByDriverId(driverId)

  const totalTrips = driverSafetyData ? driverSafetyData.totalTrips : 0
  const totalDist = driverSafetyData ? driverSafetyData.totalKilometers : 0
  const totalHoursDriven = driverSafetyData
    ? driverSafetyData.totalHoursDriven
    : 0
  const eventTypeCounts = driverSafetyData
    ? driverSafetyData.eventTypeCounts
    : null

  const driverSafetyCategory = driverSafetyData
    ? driverSafetyData.driverSafetyCategory
    : null

  const loading = false

  const { previousMonthChange, previousYearChange } = useSafetyScoreTrends({
    driverId
  })

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: mainContainerWidth - Metrics.base * 4,
        justifyContent: 'flex-start'
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        style={{
          width: '100%',
          // height: '100%',
          padding: 0,
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(driverId) ? (
          driverSafetyData ? (
            <>
              <FlexBox
                direction='column'
                style={{
                  width: '100%',
                  minHeight: 200
                }}
              >
                <FlexBox
                  style={{
                    width: '100%',
                    minHeight: 200,
                    borderBottom: `1px solid ${Colors.dividerColor}`
                  }}
                >
                  <FlexBox
                    fillSpace
                    direction='column'
                    style={{ flex: 1, alignItems: 'flex-start' }}
                  >
                    <FlexBox
                      direction='column'
                      style={{
                        width: '100%',
                        alignItems: 'flex-start',
                        padding: Metrics.base * 2,
                        paddingLeft: Metrics.base * 5,
                        paddingBottom: Metrics.base * 4,
                        marginBottom: Metrics.base * 2,
                        borderBottom: `1px solid ${Colors.dividerColor}`
                      }}
                    >
                      <FlexBox
                        direction='row'
                        style={{ marginBottom: Metrics.base }}
                      >
                        {/*<Icon
                      style={{ marginRight: Metrics.base }}
                      name='star'
                      color={Colors.EXCELLENT}
                      // style={styles.iconCenter}
                    />*/}
                        {/*<img
                      width={20}
                      style={{ marginRight: Metrics.base }}
                      alt='badge'
                      src={Images.excellentBadge1}
                    />*/}
                        <Status
                          status={driverSafetyCategory}
                          getStatusColors={({ status }) =>
                            Colors[driverSafetyCategory]
                          }
                        />
                      </FlexBox>
                      <FlexBox
                        direction='row'
                        style={{ marginBottom: Metrics.base }}
                      >
                        {/*<img
                      width={40}
                      style={{ marginRight: Metrics.base }}
                      alt='badge'
                      src={Images.excellentBadge1}
                    />*/}
                        <Display>
                          {totalScore ? `${Math.round(totalScore)}/100` : '-'}
                        </Display>
                      </FlexBox>
                      <Title2 color={Colors.textSecondary} marginTop={1}>
                        Total Driver Score
                      </Title2>
                    </FlexBox>
                    <FlexBox style={{ marginLeft: Metrics.base * 5 }}>
                      <ScoreCircleChart
                        score={checkVal(totalScore) ? totalScore : null}
                        maxScore={100}
                        color={Colors[getScoreCategory(totalScore)]}
                        backgroundColor={Colors.secondary}
                        size={130}
                      />
                    </FlexBox>
                  </FlexBox>
                  <FlexBox
                    direction='column'
                    style={{
                      flex: 3,
                      minWidth: 700,
                      borderLeft: `1px solid ${Colors.dividerColor}`
                    }}
                  >
                    {loading ? (
                      <FlexBox style={{ width: 500 }}>
                        <ProcessingSpinner size={40} />
                      </FlexBox>
                    ) : (
                      <FlexBox
                        direction='row'
                        style={{
                          width: '100%',
                          marginBottom: Metrics.base * 3,
                          marginTop: Metrics.base * 2
                        }}
                      >
                        <InfoBadge
                          size='small'
                          value={totalTrips}
                          label={'Total Trips'}
                        />
                        <InfoBadge
                          size='small'
                          value={
                            totalDist
                              ? `${thousandsSeparator(totalDist)} km`
                              : '-'
                          }
                          label={'Total Distance'}
                        />
                        <InfoBadge
                          size='small'
                          value={
                            totalHoursDriven
                              ? !isNaN(totalHoursDriven)
                                ? totalHoursDriven.toFixed(2)
                                : '-'
                              : '-'
                          }
                          label={'Hours Driven'}
                        />
                        {/*<InfoBadge
                      size='small'
                      value={`${averageSpeed} km/h`}
                      label={'Average Speed'}
                    />*/}
                      </FlexBox>
                    )}
                    <FlexBox
                      style={{
                        width: '100%',
                        borderTop: `1px solid ${Colors.dividerColor}`,
                        borderBottom: `1px solid ${Colors.dividerColor}`
                      }}
                    >
                      <CategoryScore
                        loading={driverSafetyLoading}
                        score={drivingBehaviourScore}
                        category='Driving Behaviour'
                      />
                      {/* <CategoryScore
                      score={healthAttitudeScore}
                      category='Health/Attitude'
                    /> */}
                      <CategoryScore
                        loading={driverSafetyLoading}
                        score={educationScore}
                        category='Education'
                      />
                    </FlexBox>
                    <FlexBox
                      style={{
                        width: '100%',
                        marginTop: Metrics.base * 3,
                        marginBottom: Metrics.base * 3
                      }}
                    >
                      <FlexBox style={{ flex: 1 }}>
                        <InfoBadge
                          size='small'
                          value={
                            eventTypeCounts
                              ? getTotalEvents(eventTypeCounts)
                              : 0
                          }
                          label={'Safety Events Recorded'}
                        />
                      </FlexBox>
                      <FlexBox style={{ flex: 1 }}>
                        <TrendIndicator
                          size='small'
                          value={previousMonthChange}
                          suffix='pts'
                          label={'Previous Month'}
                        />
                      </FlexBox>
                      <FlexBox style={{ flex: 1 }}>
                        <TrendIndicator
                          size='small'
                          value={previousYearChange}
                          suffix='pts'
                          label={'Previous Year'}
                        />
                      </FlexBox>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <ScoreExplanation
                  name='drivers'
                  title='The Total Driver Safety Score is the overall indicator of driver safety. It is a weighted sum of the two driver safety categories above.'
                />
              </FlexBox>
            </>
          ) : (
            <NoneSelected
              loading={driverSafetyLoading}
              height={
                mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
              }
              displayText='No Score'
              helperText='This driver has not yet been scored'
            />
          )
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select a driver from the list on the left'
          />
        )}
      </Subsection>
      {driverId && driverSafetyData && (
        <TripsSafetyList
          driverId={driverId}
          totalTrips={totalTrips}
          driverSafetyData={driverSafetyData}
        />
      )}
    </FlexBox>
  )
}

export default DriverOverview
