import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const MUTATION = gql`
  mutation inviteDriverToApp($userEmail: String!) {
    inviteDriverToApp(userEmail: $userEmail)
  }
`
export const useInviteDriverToApp = () => {
  const [mutate, { loading }] = useMutation(MUTATION, {
    mutationName: 'inviteDriverToApp',
    successMessage: 'Invitation Email Sent',
    errorMessage: 'Failed to send invitation'
  })

  return [mutate, { loading }]
}
