import _ from 'lodash'
import React, { useState, useEffect } from 'react'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { Colors, Metrics, useColors } from '../../Themes'
import {
  Text,
  Icon,
  ProcessingSpinner,
  ModalAlert,
  DataDisplay,
  IconButton
} from '../'

import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import Slider from '@material-ui/core/Slider'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

import Typography from '@material-ui/core/Typography'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import NumberFormat from 'react-number-format'

import inlineStyles from './Styles'
// import { darkmode } from '../../../Themes/Clients/blockHarvest'
import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../../Graphql/Queries/localQueries'
import { styled } from '@material-ui/core/node_modules/@material-ui/styles'

const filter = createFilterOptions()

const lightTheme = createMuiTheme({
  palette: {
    primary: { main: Colors.lightTheme.primary },
    secondary: { main: Colors.lightTheme.primary }
  },
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: 12
  },
  status: {
    danger: { main: Colors.danger }
  },
  overrides: {
    MuiInput: {
      root: {
        paddingBottom: 0,
        paddingTop: 0
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid ${Colors.lightTheme.dividerColor}`
        }
      }
    },
    MuiInputBase: {
      root: {
        color: Colors.lightTheme.secondary,
        borderColor: 'red'
      },
      input: {
        padding: 0,
        paddingBottom: 8,
        paddingTop: 8
      }
    },
    MuiFormControl: {
      root: { width: '100%', color: Colors.lightTheme.secondary }
    },
    MuiFormLabel: {
      root: {
        color: Colors.lightTheme.secondary
      }
    },
    MuiOutlinedInput: {
      input: {
        paddingBottom: 16,
        paddingTop: 16
      },
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: Colors.lightTheme.background,
        '& input + fieldset': {
          borderColor: Colors.lightTheme.dividerColor,
          borderWidth: 1,
          color: Colors.lightTheme.secondary
        },
        '& input:valid:focus + fieldset': {
          borderWidth: 1,
          borderColor: Colors.lightTheme.primary
        },
        '& input:valid:focus:hover + fieldset': {
          borderWidth: 1,
          borderColor: Colors.lightTheme.primary
        },
        '& input:valid:hover + fieldset': {
          borderWidth: 1,
          borderColor: Colors.lightTheme.primaryOpacity2
          // backgroundColor: Colors.selectHighlightOpacity3
        }
      },
      notchedOutline: {
        borderColor: Colors.lightTheme.dividerColor,
        borderRadius: 5
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.85)',
        color: Colors.lightTheme.primary
      }
    },
    MuiTypography: {
      colorTextSecondary: { color: Colors.lightTheme.secondary },
      body1: { fontSize: 12, color: Colors.lightTheme.secondary }
    }
  }
})

const darkTheme = createMuiTheme({
  palette: {
    primary: { main: Colors.darkTheme.primary },
    secondary: { main: Colors.darkTheme.primary }
  },
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: 12
  },
  status: {
    danger: { main: Colors.danger }
  },
  overrides: {
    MuiInput: {
      root: { paddingBottom: 0, paddingTop: 0 },
      underline: {
        '&:before': {
          borderBottom: `1px solid rgba(255,255,255,0.25)`
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: 0,
        paddingBottom: 8,
        paddingTop: 8,
        color: 'white'
      }
    },
    PrivateSwitchBase: {
      root: {
        color: Colors.darkTheme.secondary
      }
    },
    PrivateRadioButtonIcon: {
      root: {
        color: 'rgba(255,255,255,0.4)'
      },
      checked: {
        color: Colors.darkTheme.primary,
        disabled: {
          color: 'rgba(255,255,255,0.4)'
        }
      }
    },
    MuiIconButton: {
      root: { color: 'rgba(255,255,255,0.4)' }
      // label: { color: Colors.secondary },
      // selected: { color: Colors.primary }
    },
    MuiSelect: {
      icon: {
        color: 'rgba(255,255,255,0.4)'
      }
    },
    MuiFormControl: {
      root: { width: '100%', color: Colors.darkTheme.secondary }
    },
    MuiFormLabel: {
      root: {
        color: Colors.darkTheme.secondary
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.85)',
        color: Colors.darkTheme.primary
      }
    },
    MuiTypography: {
      colorTextSecondary: { color: Colors.darkTheme.secondary },
      body1: { fontSize: 12, color: Colors.grey4 }
    }
  }
})

const getSelected = (options, value) => {
  const option = options.filter((option) => {
    return option[0] === value
  })[0]
  return option ? option : ['', '']
}

const getSelectedObject = (options, value) => {
  return options.filter((option) => {
    return option.id === value
  })[0]
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value:
              values.value.length > 1 ? Number(values.value) + '' : values.value
          }
        })
      }}
      thousandSeparator={
        props.thousandSeparator ? props.thousandSeparator : ','
      }
      isNumericString
      decimalScale={3}
    />
  )
}

function NumberFormatCustomPrice(props) {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value:
              values.value.length > 1 ? Number(values.value) + '' : values.value
          }
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
    />
  )
}

export const TextComp = ({ darkmode, children }) => {
  return (
    <Text
      marginBottom={-1.5}
      font='small'
      color={darkmode ? Colors.darkTheme.primary : Colors.lightTheme.primary}
    >
      {children}
    </Text>
  )
}

const DisplayData = ({
  label,
  data,
  indicateChange,
  loading,
  display,
  prefix,
  suffix,
  firstItem,
  lastItem,
  borderTop
}) => {
  return display ? (
    <DataDisplay
      label={label}
      data={data}
      loading={loading}
      prefix={prefix}
      suffix={suffix}
      firstItem={firstItem}
      lastItem={lastItem}
      borderTop={borderTop}
    />
  ) : (
    ''
  )
}

DisplayData.defaultProps = {
  display: true
}

const DisplayDateData = ({ label, data, loading, display, darkmode }) => {
  return display ? (
    <div>
      <div
        style={{
          ...inlineStyles.dataDisplayContainer,
          borderBottom: `1px solid ${
            darkmode
              ? Colors.darkTheme.background
              : Colors.lightTheme.background
          }`,
          paddingLeft: Metrics.base * 3,
          paddingRight: Metrics.base * 3
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 3
          }}
        >
          <Text color={Colors.grey3}>
            <i>{label}</i>
          </Text>
        </div>
        <div
          style={{
            width: 1,
            height: Metrics.base * 5,
            backgroundColor: darkmode
              ? Colors.darkTheme.background
              : Colors.lightTheme.background,
            marginTop: -Metrics.base,
            marginBottom: -Metrics.base
          }}
        ></div>
        <div
          style={{ display: 'flex', flex: 4, paddingLeft: Metrics.base * 2 }}
        >
          {loading ? <ProcessingSpinner ml={2} size={16} /> : data}
        </div>
      </div>
    </div>
  ) : (
    ''
  )
}

DisplayDateData.defaultProps = {
  display: true
}

const Container = ({ children, indicateChange, editable, formbox }) => {
  return (
    <div
      style={{
        width: '100%',
        borderLeft: indicateChange ? `3px solid ${Colors.spot2}` : 'none',
        // marginLeft: indicateChange ? -Metrics.base * 3 : null,
        paddingLeft: editable && formbox ? Metrics.base * 4 : 0,
        paddingRight: editable && formbox ? Metrics.base * 4 : 0
      }}
    >
      {children}
    </div>
  )
}

const getDateFormat = (dateFormat) => {
  let views = ['year', 'month', 'date']
  let format = 'dd/MM/yyyy'
  if (dateFormat === 'my') {
    views = ['year', 'month']
    format = 'MM/yyyy'
  }
  return [format, views]
}

const ClearInputButton = ({ onClick, handleChange }) => {
  return (
    <IconButton
      onClick={onClick ? onClick : () => handleChange(null)}
      type='button'
      iconStyle={{
        cursor: 'pointer',
        marginBottom: 4,
        marginRight: 4
      }}
      iconSize='small'
      iconName='clear'
      iconColor={Colors.grey3}
    />
  )
}

const _renderInput = ({
  type,
  error,
  editable,
  fetching,
  label,
  name,
  value,
  display,
  prefix,
  suffix,
  min,
  max,
  unit,
  startDate,
  endDate,
  dateFormat,
  radioValues,
  radioDisplayValues,
  handleChange,
  onKeyPress,
  selectValues,
  selectDisplayValues,
  onBlur,
  disabled,
  disableClearable,
  styles,
  query,
  queryName,
  getOptionLabel,
  variables,
  objectName,
  filterOptions,
  showMutationModal,
  setShowMutationModal,
  selectedObject,
  setSelectedObject,
  MutationModalComponent,
  fetchPolicy,
  indicateChange,
  placeholder,
  decimalScale,
  groupBy,
  inputOptions,
  formbox,
  showClearButton,
  thousandSeparator,
  firstItem,
  lastItem,
  borderTop,
  direction,
  variant,
  defaultValue,
  inputRef
}) => {
  const { data } = useQuery(GET_DARKMODE)
  const darkmode = data ? data.darkmode : true
  const Colors = useColors()
  const [StyledTextArea] = useState(
    styled(TextField)({
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: Colors.dividerColor
        },
        '&:hover fieldset': {
          borderColor: Colors.primaryOpacity2
        },
        '&.Mui-focused fieldset': {
          borderColor: Colors.primaryOpacity1
        }
      }
    })
  )
  switch (type) {
    case 'textarea': {
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{
                  backgroundColor: Colors.subsectionBackground,
                  ...inlineStyles.formControl,
                  ...styles,
                  marginTop: 24
                }}
                onBlur={onBlur}
              >
                <StyledTextArea
                  error={error ? true : false}
                  id={`${name}-standard-multiline-input`}
                  label={label}
                  name={name}
                  autoComplete='off'
                  variant='outlined'
                  multiline
                  disabled={disabled}
                  value={value}
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyPress={onKeyPress}
                  helperText={error ? error : null}
                />
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={value ? value : 'None'}
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'date': {
      const options =
        dateFormat === 'my' ? { month: 'numeric', year: 'numeric' } : {}
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {editable ? (
                <FormControl
                  style={{ ...inlineStyles.formControl, ...styles }}
                  onBlur={onBlur}
                >
                  <DatePicker
                    // clearable
                    autoOk
                    // disablePast
                    disabled={disabled}
                    // showTodayButton
                    label={label}
                    value={value}
                    format={getDateFormat(dateFormat)[0]}
                    openTo='year'
                    views={getDateFormat(dateFormat)[1]}
                    onChange={(e) => {
                      handleChange(e.setUTCHours(0, 0, 0, 0))
                    }} //handleChange(e.getTime())
                    onKeyPress={onKeyPress}
                    error={error ? true : false}
                    helperText={error ? error : null}
                  />
                </FormControl>
              ) : (
                <DisplayData
                  label={label}
                  indicateChange={indicateChange}
                  data={
                    value
                      ? typeof value === 'object'
                        ? value.toLocaleDateString('en-GB', options)
                        : new Date(value).toLocaleDateString('en-GB', options)
                      : 'None'
                  }
                  display={display}
                  firstItem={firstItem}
                  lastItem={lastItem}
                  borderTop={borderTop}
                />
              )}
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Container>
      )
    }

    case 'dateBetween': {
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {editable ? (
                <FormControl
                  style={{ ...inlineStyles.formControl, ...styles }}
                  // style={{ marginTop: 16 }}
                  onBlur={onBlur}
                >
                  {/* {label ? <FormLabel component='label'>{label}</FormLabel> : null} */}
                  {/* label ? (
                    <TextComp darkmode={darkmode}>{label}</TextComp>
                  ) : null*/}
                  <div style={inlineStyles.dateBetweenContainer}>
                    {value && value.startDate && (
                      <ClearInputButton
                        onClick={() => [
                          handleChange({ ...value, startDate: null })
                        ]}
                      />
                    )}
                    <DatePicker
                      // clearable
                      autoOk
                      // showTodayButton
                      // disablePast
                      disabled={disabled}
                      label='Start Date'
                      value={value.startDate}
                      format={getDateFormat(dateFormat)[0]}
                      openTo='year'
                      views={getDateFormat(dateFormat)[1]}
                      onChange={(e) => {
                        handleChange({
                          ...value,
                          startDate: e.setUTCHours(0, 0, 0, 0)
                        })
                      }}
                      error={error ? true : false}
                      helperText={error ? error : null}
                    />
                    <Icon
                      name='arrow_right_alt'
                      style={{ padding: '0px 8px' }}
                      size='large'
                    />
                    {value && value.endDate && (
                      <IconButton
                        onClick={() =>
                          handleChange({ ...value, endDate: null })
                        }
                        type='button'
                        iconStyle={{
                          cursor: 'pointer',
                          marginBottom: 4,
                          marginRight: 4
                        }}
                        iconSize='small'
                        iconName='clear'
                        iconColor={Colors.grey3}
                      />
                    )}
                    <DatePicker
                      // clearable
                      autoOk
                      disabled={!value.startDate || disabled ? true : false}
                      // showTodayButton
                      label='End Date'
                      minDate={value.startDate}
                      value={value.endDate}
                      format='dd/MM/yyyy'
                      openTo='year'
                      views={['year', 'month', 'date']}
                      onChange={(e) => {
                        handleChange({
                          ...value,
                          endDate: e.setUTCHours(23, 59, 59, 999)
                        })
                      }}
                      error={error ? true : false}
                      helperText={error ? error : null}
                    />
                  </div>
                </FormControl>
              ) : (
                <DisplayDateData
                  label={label}
                  indicateChange={indicateChange}
                  data={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text
                        color={
                          darkmode
                            ? Colors.darkTheme.secondary
                            : Colors.lightTheme.secondary
                        }
                        marginLeft={1}
                      >
                        {value.startDate
                          ? typeof value.startDate === 'object'
                            ? value.startDate.toLocaleDateString()
                            : new Date(value.startDate).toLocaleDateString()
                          : 'None'}
                      </Text>
                      <Icon
                        name='arrow_right_alt'
                        style={{
                          padding: '0px 8px'
                        }}
                        size='medium'
                        color={Colors.grey3}
                      />{' '}
                      <Text
                        color={
                          darkmode
                            ? Colors.darkTheme.secondary
                            : Colors.lightTheme.secondary
                        }
                      >
                        {value.endDate
                          ? typeof value.endDate === 'object'
                            ? value.endDate.toLocaleDateString()
                            : new Date(value.endDate).toLocaleDateString()
                          : 'None'}
                      </Text>
                    </div>
                  }
                  display={display}
                  darkmode={darkmode}
                />
              )}
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Container>
      )
    }

    case 'radio': {
      const options = _.zip(radioValues, radioDisplayValues || radioValues)
      const selected = options.filter((option) => {
        return option[0] === value
      })[0]
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{
                  ...inlineStyles.formControl,
                  ...styles,
                  marginTop: 16
                }}
                onBlur={onBlur}
              >
                {label ? (
                  <TextComp darkmode={darkmode}>{label}</TextComp>
                ) : null}
                <RadioGroup
                  row={direction === 'row' ? true : false}
                  aria-label={label}
                  name={name}
                  value={value}
                  onChange={(e) => handleChange(e.target.value)}
                  // style={{ display: 'flex', flexDirection: 'row' }}
                >
                  {options.map((option, i) => (
                    <FormControlLabel
                      key={i}
                      disabled={disabled}
                      value={option[0]}
                      control={<Radio />}
                      label={option[1]}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={selected ? selected[1] : 'None'}
                loading={!selected ? true : false}
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'checkbox': {
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{
                  ...inlineStyles.formControl,
                  ...styles,
                  marginTop: 16
                }}
                onBlur={onBlur}
                disabled={disabled}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => handleChange(e.target.checked, name)}
                      name={name}
                      // color='primary'
                    />
                  }
                  label={label}
                />
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={value ? value : 'None'}
                display={display}
                prefix={prefix}
                suffix={suffix}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'slider': {
      function valuetext(value) {
        return `${value + ' ' + unit}`
      }
      let marks = []
      if (min !== undefined && min !== null && max && unit) {
        for (let i = min; i <= max; i++) {
          marks.push({ value: i, label: valuetext(i) })
        }
      }
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            <FormControl
              style={{ ...inlineStyles.formControl, ...styles, marginTop: 8 }}
              onBlur={onBlur}
            >
              {label ? <TextComp darkmode={darkmode}>{label}</TextComp> : null}
              <div style={{ padding: '0px 16px' }}>
                <Slider
                  defaultValue={value}
                  name={name}
                  // onChange={(e) => handleChange(e.target.value)}
                  disabled={disabled}
                  getAriaValueText={valuetext}
                  aria-labelledby='discrete-slider'
                  valueLabelDisplay='auto'
                  step={1}
                  marks={!_.isEmpty(marks) ? marks : true}
                  min={min || 0}
                  max={max || 20}
                />
              </div>
            </FormControl>
          </ThemeProvider>
        </Container>
      )
    }

    case 'select': {
      const options = _.zip(selectValues, selectDisplayValues || selectValues)
      const selected = getSelected(options, value)
      // options.unshift(['', 'None'])
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              fetching ? (
                <div style={inlineStyles.fakeTextFieldContainer}>
                  <Typography
                    variant='body2'
                    style={{
                      color: darkmode
                        ? Colors.darkTheme.secondaryOpacity3
                        : Colors.lightTheme.secondaryOpacity3
                    }}
                  >
                    {label}
                  </Typography>
                  <ProcessingSpinner size={16.5} />
                </div>
              ) : (
                <FormControl
                  disabled={disabled}
                  style={{ ...inlineStyles.formControl, ...styles }}
                  error={error ? true : false}
                  data-cy={`${name}-select`}
                >
                  <InputLabel
                    id='select_label'
                    focused={value ? true : false}
                    disableAnimation={false}
                  >
                    {label}
                  </InputLabel>
                  <Select
                    startAdornment={
                      showClearButton &&
                      value && <ClearInputButton handleChange={handleChange} />
                    }
                    onClose={onBlur}
                    labelId='select_label'
                    name={name}
                    id={`${name}-select`}
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    {fetching ? (
                      <ProcessingSpinner mt={8} mb={8} size={16} />
                    ) : (
                      _.map(options, (option, i) => (
                        <MenuItem
                          data-cy={`${name}-select-option-${option[0]}`}
                          key={i}
                          disabled={disabled}
                          value={option[0]}
                        >
                          {option[1]}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {error ? <FormHelperText>{error}</FormHelperText> : null}
                </FormControl>
              )
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={selected ? selected[1] : 'none selected'}
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'multipleSelect': {
      const options = _.zip(selectValues, selectDisplayValues || selectValues)
      // const selected = getSelected(options, value)
      // console.log(selected)
      // options.unshift(['', 'None'])
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                disabled={disabled}
                style={{ ...inlineStyles.formControl, ...styles }}
                error={error ? true : false}
              >
                <InputLabel id='select_label'>{label}</InputLabel>
                <Select
                  multiple
                  /*startAdornment={
                    value &&
                    value.length > 0 && (
                      <IconButton
                        onClick={() => handleChange([])}
                        iconStyle={{ cursor: 'pointer' }}
                        iconSize='small'
                        iconName='clear'
                        iconColor={Colors.grey3}
                      />
                    )
                  }*/
                  onClose={onBlur}
                  labelId='select_label'
                  name={name}
                  id={`${name}-select`}
                  value={value ? value : []}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((option) => (
                        <Chip
                          key={option}
                          label={getSelected(options, option)[1]}
                          // className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  {fetching ? (
                    <ProcessingSpinner mt={8} mb={8} size={16} />
                  ) : (
                    _.map(options, (option, i) => (
                      <MenuItem key={i} disabled={disabled} value={option[0]}>
                        <Checkbox
                          checked={value && value.indexOf(option[0]) > -1}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: { color: Colors.lightTheme.secondary }
                          }}
                          primary={option[1]}
                        />
                      </MenuItem>
                    ))
                  )}
                </Select>
                {error ? <FormHelperText>{error}</FormHelperText> : null}
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={
                  value && value.length > 0 ? value.toString() : 'none selected'
                }
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'autocomplete': {
      // these autocomplete components are quite inefficient with al the indexOf..
      // but only way I could get them to search properly for now
      const options = _.zip(selectValues, selectDisplayValues || selectValues)
      const selected = getSelected(options, value.value ? value.value : value)
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                disabled={disabled}
                style={{ ...inlineStyles.formControl, ...styles }}
                error={error ? true : false}
              >
                {fetching ? (
                  editable ? (
                    <div style={inlineStyles.fakeTextFieldContainer}>
                      <Typography
                        variant='body2'
                        style={{ color: Colors.grey6 }}
                      >
                        {label}
                      </Typography>
                      <ProcessingSpinner size={16.5} />
                    </div>
                  ) : (
                    <DisplayData
                      label={label}
                      loading={fetching}
                      indicateChange={indicateChange}
                      display={display}
                      firstItem={firstItem}
                      lastItem={lastItem}
                      borderTop={borderTop}
                    />
                  )
                ) : (
                  <Autocomplete
                    id={`${name}-autocomplete`}
                    name={name}
                    // error={error ? true : false}
                    options={selectDisplayValues}
                    autoHighlight
                    selectOnFocus
                    groupBy={groupBy}
                    // clearOnBlur={!freeSolo}
                    // openOnFocus
                    disabled={disabled}
                    value={value === '' || !selected ? null : selected[1]}
                    onChange={(e, newValue) => {
                      const selectValue =
                        selectValues[selectDisplayValues.indexOf(newValue)]
                      handleChange({
                        ...value,
                        value: selectValue,
                        selectValue: selectValue
                      })
                    }}
                    // getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>
                        {getOptionLabel
                          ? getOptionLabel(
                              option,
                              selectDisplayValues.indexOf(option)
                            )
                          : option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={label}
                        name={name}
                        autoComplete='off'
                        error={error ? true : false}
                        // variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off' // disable autocomplete and autofill
                        }}
                      />
                    )}
                  ></Autocomplete>
                )}

                {error ? <FormHelperText>{error}</FormHelperText> : null}
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                loading={fetching}
                data={selected ? selected[1] : value.value}
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'autocompleteComboBox': {
      const options = _.zip(selectValues, selectDisplayValues || selectValues)
      const selected = getSelected(options, value.value ? value.value : value)
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                disabled={disabled}
                style={{ ...inlineStyles.formControl, ...styles }}
                error={error ? true : false}
              >
                {fetching ? (
                  editable ? (
                    <div style={inlineStyles.fakeTextFieldContainer}>
                      <Typography
                        variant='body2'
                        style={{ color: Colors.grey6 }}
                      >
                        {label}
                      </Typography>
                      <ProcessingSpinner size={16.5} />
                    </div>
                  ) : (
                    <DisplayData
                      label={label}
                      loading={fetching}
                      indicateChange={indicateChange}
                      display={display}
                      firstItem={firstItem}
                      lastItem={lastItem}
                      borderTop={borderTop}
                    />
                  )
                ) : (
                  <Autocomplete
                    id={`${name}-combo-box`}
                    name={name}
                    // error={error ? true : false}
                    options={selectDisplayValues}
                    freeSolo
                    openOnFocus
                    value={
                      selectDisplayValues[
                        selectValues.indexOf(value.selectValue)
                      ]
                    }
                    inputValue={value.inputValue}
                    onChange={(e, newValue) => {
                      const selectValue =
                        selectValues[selectDisplayValues.indexOf(newValue)]
                      handleChange({
                        ...value,
                        value: selectValue,
                        selectValue: selectValue
                      })
                    }}
                    onInputChange={(e, newInputValue) => {
                      const inputValue =
                        selectDisplayValues.indexOf(newInputValue) >= 0
                          ? selectValues[
                              selectDisplayValues.indexOf(newInputValue)
                            ]
                          : newInputValue
                      handleChange({
                        ...value,
                        value: inputValue,
                        inputValue: newInputValue
                      })
                    }}
                    // getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={label}
                        name={name}
                        autoComplete='off'
                        error={error ? true : false}
                        // variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off' // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}

                {error ? <FormHelperText>{error}</FormHelperText> : null}
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={selected ? selected[1] : value.value}
                display={display}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }
    /*
    case 'autocompleteGraphQL': {
      return (
        <Query query={query} variables={variables} fetchPolicy={fetchPolicy}>
          {({ data, loading, error }) => {
            if (!data || loading)
              return (
                <Container
                  indicateChange={indicateChange}
                  editable={editable}
                  formbox={formbox}
                >
                  {editable ? (
                    <div style={inlineStyles.fakeTextFieldContainer}>
                      <Typography
                        variant='body2'
                        style={{ color: Colors.grey6 }}
                      >
                        {label}
                      </Typography>
                      <ProcessingSpinner size={16.5} />
                    </div>
                  ) : (
                    <DisplayData
                      label={label}
                      loading
                      indicateChange={indicateChange}
                      display={display}
                      firstItem={firstItem}
                      lastItem={lastItem}
                      borderTop={borderTop}
                    />
                  )}
                </Container>
              )
            const selected = getSelectedObject(data[queryName], value)
            const autoCompleteValues = []
            const autoCompleteDisplayValues = []
            for (var i in data[queryName]) {
              autoCompleteValues.push(data[queryName][i].id)
              autoCompleteDisplayValues.push(getOptionLabel(data[queryName][i]))
            }
            return (
              <Container
                indicateChange={indicateChange}
                editable={editable}
                formbox={formbox}
              >
                <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
                  {editable ? (
                    <Autocomplete
                      id={name} // {`${label}-combo-box`}
                      name={name}
                      // error={error ? true : false}
                      disabled={disabled}
                      options={autoCompleteDisplayValues}
                      autoHighlight
                      selectOnFocus
                      // clearOnBlur
                      // openOnFocus
                      value={
                        value === '' || !selected
                          ? null
                          : autoCompleteDisplayValues[
                              autoCompleteValues.indexOf(value)
                            ]
                      }
                      onChange={(e, newValue) => {
                        handleChange(
                          autoCompleteValues[
                            autoCompleteDisplayValues.indexOf(newValue)
                          ]
                        )
                        if (newValue === `**@Add**"${selectedObject.name}"`)
                          setShowMutationModal(true)
                      }}
                      onInputChange={(e, newInputValue) => {
                        setSelectedObject({ name: newInputValue })
                      }}
                      getOptionLabel={(option) => {
                        if (option.split('**')[1] === '@Add') {
                          return `Add ${option.split('**')[2]}`
                        }
                        return getOptionLabel(
                          data[queryName][
                            autoCompleteDisplayValues.indexOf(option)
                          ]
                        )
                      }}
                      filterOptions={(options, params) => {
                        if (filterOptions) return filterOptions(data[queryName])
                        const filtered = filter(options, params)
                        if (
                          params.inputValue !== '' &&
                          MutationModalComponent
                        ) {
                          filtered.push(`**@Add**"${params.inputValue}"`)
                        }
                        return filtered
                      }}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.split('**')[1] === '@Add'
                            ? `Add ${option.split('**')[2]}`
                            : getOptionLabel(
                                data[queryName][
                                  autoCompleteDisplayValues.indexOf(option)
                                ]
                              )}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <FormControl
                          disabled={disabled}
                          style={{ ...inlineStyles.formControl, ...styles }}
                          onBlur={onBlur}
                        >
                          <TextField
                            {...params}
                            label={label}
                            name={name}
                            autoComplete='off'
                            // variant='outlined'
                            // disabled={disabled}
                            error={error ? true : false}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off', // disable autocomplete and autofill
                              form: {
                                autoComplete: 'off'
                              }
                            }}
                          />
                          {error ? (
                            <FormHelperText>{error}</FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  ) : (
                    <DisplayData
                      label={label}
                      indicateChange={indicateChange}
                      data={
                        selected
                          ? autoCompleteDisplayValues[
                              autoCompleteValues.indexOf(value)
                            ]
                          : 'none selected'
                      }
                      display={display}
                      firstItem={firstItem}
                      lastItem={lastItem}
                      borderTop={borderTop}
                    />
                  )}
                  {showMutationModal && MutationModalComponent ? (
                    <ModalAlert
                      title={`New ${objectName}`}
                      body='Enter details below'
                    >
                      <MutationModalComponent
                        // object={selectedObject}
                        tableQuery={query}
                        tableQueryName={queryName}
                        handleClose={() => setShowMutationModal(false)}
                      />
                    </ModalAlert>
                  ) : null}
                </ThemeProvider>
              </Container>
            )
          }}
        </Query>
      )
    }
    */

    /*
    case 'autocompleteAlt': {
      const options = _.zip(selectValues, selectDisplayValues || selectValues)
      let autoCompleteOptions = []
      for (let j = 0; j < selectValues.length; j++) {
        autoCompleteOptions.push({
          label: selectDisplayValues[j]
            ? selectDisplayValues[j]
            : selectValues[j],
          value: selectValues[j]
        })
      }
      const selected = options.filter((option) => {
        return option[0] === value
      })[0]
      const autoCompleteSelected = autoCompleteOptions.filter((option) => {
        return option.value === value
      })[0]
      // options.unshift(['', 'None'])
      return (
        <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
          {editable ? (
            <FormControl
              disabled={disabled}
              style={{ ...inlineStyles.formControl, ...styles }}
              error={error ? true : false}
            >
              <Autocomplete
                id={`${label}-combo-box`}
                options={autoCompleteOptions}
                // value={
                //   autoCompleteSelected ? autoCompleteSelected.label : value
                // }
                inputValue={
                  autoCompleteSelected ? autoCompleteSelected.label : value
                }
                clearOnEscape
                disableClearable={disableClearable || false}
                // error={error ? true : false}
                // onInputChange={(event, newValue) =>
                //   console.log('onInputchange', newValue) ||
                //   handleChange({
                //     target: {
                //       value: newValue ? newValue.value : ''
                //     }
                //   })
                // }
                onChange={(event, newValue) =>
                  handleChange({
                    target: {
                      value: newValue ? newValue.value : ''
                    }
                  })
                }
                // getOptionSelected={(option, value) => option.value === value}
                getOptionLabel={(option) =>
                  option && option.label ? option.label : ''
                }
                renderInput={(params, i) => {
                  return fetching ? (
                    <div style={inlineStyles.fakeTextFieldContainer}>
                      <Typography
                        variant='subtitle1'
                        style={{ color: Colors.grey6 }}
                      >
                        {label}
                      </Typography>
                      <ProcessingSpinner size={16.5} />
                    </div>
                  ) : (
                    <TextField
                      {...params}
                      id={`${i}-combo-box`}
                      label={label}
                      disabled={disabled}
                      onChange={({ target }) => handleChange({ target })}
                      helperText={error ? error : null}
                    />
                  )
                }}
              />
              {error ? <FormHelperText>{error}</FormHelperText> : null}
            </FormControl>
          ) : (
            <DisplayData
              label={label}
              indicateChange={indicateChange}
              data={selected ? selected[1] : 'None'}
              loading={!selected ? true : false}
              display={display}
              firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
            />
          )}
        </ThemeProvider>
      )
    }
    */

    case 'number': {
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{ ...inlineStyles.formControl, ...styles }}
                onBlur={onBlur}
              >
                <TextField
                  error={error ? true : false}
                  id={`${name}-number-input`}
                  name={name}
                  label={label}
                  autoComplete='off'
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                  value={value}
                  thousandSeparator='%'
                  // InputProps={
                  //   //TODO This is causing the text area to deselect
                  //   prefix
                  //     ? {
                  //         startAdornment: (
                  //           <InputAdornment position={'start'}>
                  //             {prefix}
                  //           </InputAdornment>
                  //         ),
                  //         decimalScale: decimalScale,
                  //         inputComponent: (props) =>
                  //           NumberFormatCustom({
                  //             ...props,
                  //             thousandSeparator: thousandSeparator
                  //           })
                  //       }
                  //     : suffix
                  //     ? {
                  //         endAdornment: (
                  //           <InputAdornment position='end'>
                  //             {suffix}
                  //           </InputAdornment>
                  //         ),
                  //         inputComponent: (props) =>
                  //           NumberFormatCustom({
                  //             ...props,
                  //             thousandSeparator: thousandSeparator
                  //           })
                  //       }
                  //     : {
                  //         inputComponent: (props) =>
                  //           NumberFormatCustom({
                  //             ...props,
                  //             thousandSeparator: thousandSeparator
                  //           })
                  //       }
                  // }
                  onChange={(e) => handleChange(Number(e.target.value))}
                  decimalScale={decimalScale}
                  helperText={error ? error : null}
                />
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={value ? Number(value) : 'None'}
                display={display}
                prefix={prefix}
                suffix={suffix}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    case 'price': {
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{ ...inlineStyles.formControl, ...styles }}
                onBlur={onBlur}
              >
                <TextField
                  error={error ? true : false}
                  id={`${name}-price-input`}
                  name={name}
                  label={label}
                  autoComplete='off'
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                  value={value}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position={'start'}>R</InputAdornment>
                    ),
                    inputComponent: NumberFormatCustomPrice
                  }}
                  onChange={(e) => handleChange(e.target.value)}
                  helperText={error ? error : null}
                />
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={value ? value : 'None'}
                display={display}
                prefix='R'
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }

    default: {
      const inputProps = prefix
        ? {
            startAdornment: (
              <InputAdornment position={'start'}>{prefix}</InputAdornment>
            )
          }
        : suffix
        ? {
            endAdornment: (
              <InputAdornment position='end'>{suffix}</InputAdornment>
            )
          }
        : null
      return (
        <Container
          indicateChange={indicateChange}
          editable={editable}
          formbox={formbox}
        >
          <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
            {editable ? (
              <FormControl
                style={{ ...inlineStyles.formControl, ...styles }}
                onBlur={onBlur}
              >
                <TextField
                  error={error ? true : false}
                  name={name}
                  id={`${name}-standard-input`}
                  variant={variant ? variant : 'standard'}
                  defaultValue={defaultValue ? defaultValue : null}
                  label={label}
                  autoComplete='off'
                  onKeyPress={onKeyPress}
                  disabled={disabled}
                  type={type === 'password' ? type : null}
                  value={value}
                  inputRef={inputRef}
                  InputProps={{
                    ...inputProps,
                    placeholder: placeholder
                  }}
                  onChange={(e) => handleChange(e.target.value)}
                  helperText={error ? error : null}
                />
              </FormControl>
            ) : (
              <DisplayData
                label={label}
                indicateChange={indicateChange}
                data={value ? value : 'None'}
                display={display}
                prefix={prefix}
                suffix={suffix}
                firstItem={firstItem}
                lastItem={lastItem}
                borderTop={borderTop}
              />
            )}
          </ThemeProvider>
        </Container>
      )
    }
  }
}

const MaterialInput = ({
  type,
  editable,
  fetching,
  handleChange,
  onKeyPress,
  error,
  label,
  name,
  value,
  display,
  prefix,
  suffix,
  min,
  max,
  unit,
  startDate,
  endDate,
  dateFormat,
  radioValues,
  radioDisplayValues,
  selectValues,
  selectDisplayValues,
  onBlur,
  disabled,
  disableClearable,
  styles,
  query,
  queryName,
  getOptionLabel,
  variables,
  objectName,
  filterOptions,
  MutationModalComponent,
  fetchPolicy,
  indicateChange,
  placeholder,
  decimalScale,
  groupBy,
  inputOptions,
  formbox,
  showClearButton,
  thousandSeparator,
  firstItem,
  lastItem,
  borderTop,
  direction,
  variant,
  defaultValue,
  inputRef
}) => {
  const [showMutationModal, setShowMutationModal] = useState(false)
  const [selectedObject, setSelectedObject] = useState({ name: '' })
  return (
    <div
      style={
        styles
          ? { ...inlineStyles.mainContainer, ...styles.outerContainer }
          : inlineStyles.mainContainer
      }
    >
      {_renderInput({
        type,
        error,
        editable,
        fetching,
        label,
        name,
        value,
        display,
        prefix,
        suffix,
        min,
        max,
        unit,
        startDate,
        endDate,
        dateFormat,
        radioValues,
        radioDisplayValues,
        handleChange,
        onKeyPress,
        selectValues,
        selectDisplayValues,
        onBlur,
        disabled,
        disableClearable,
        styles,
        query,
        queryName,
        getOptionLabel,
        variables,
        objectName,
        filterOptions,
        MutationModalComponent,
        showMutationModal,
        setShowMutationModal,
        selectedObject,
        setSelectedObject,
        fetchPolicy,
        indicateChange,
        placeholder,
        decimalScale,
        groupBy,
        inputOptions,
        formbox,
        showClearButton,
        thousandSeparator,
        firstItem,
        lastItem,
        borderTop,
        direction,
        variant,
        defaultValue,
        inputRef
      })}
    </div>
  )
}

MaterialInput.defaultProps = {
  editable: true
}

export default MaterialInput
