import React, { useState, createRef, useEffect, useRef } from 'react'
import { Images, Metrics, useColors, Utils } from '../../Themes'
import { FlexBox, Status, Subsection, Text } from '../../Components'
import { useTrip } from '../../Graphql/Queries/Trip/useTrip'
import LineChart from '../../Components/Custom/LineChart'
import { useEvents } from '../../Graphql/Queries/Event/useEvents'
import AreaChart from '../../Components/D3Charts/AreaChart'
import BarChart from '../../Components/D3Charts/BarChart'
import { Slider, Tooltip, withStyles } from '@material-ui/core'
import LargeDivider from '../../Components/LargeDivider'
import { Display, Title } from '../../Components/Typography'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { checkVal } from '../../Helpers/Functions'
import EventChart from '../../Components/D3Charts/EventChart'
import { useHistory, useLocation } from 'react-router-dom'
import { useNavigation } from '../../Components/CustomHooks/useNavigation'
import { useReactiveVar } from '@apollo/client'
import { selectedTripId } from '../../Graphql/GraphQLClient'
import SpeedChart from '../../Components/D3Charts/SpeedChart'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'

const createEventData = ({ setEventData, events }) => {
  const data = []
  for (var i = 0; i < events.length; i++) {
    data.push({
      ...events[i],
      startDate: events[i].startDate,
      endDate: events[i].endDate,
      value: events[i].normScore
    })
  }
  setEventData(
    data.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
  )
}

const getLine = (points, startIndex) => {
  if (!points[startIndex]) return { line: null, nextStartPoint: -1 }
  if (!checkVal(points[startIndex].speedLimit))
    return { line: null, nextStartPoint: startIndex + 1 }
  let line = {
    x1: points[startIndex].date,
    y1: points[startIndex].speedLimit,
    x2: points[startIndex].date,
    y2: points[startIndex].speedLimit
  }
  for (var i = startIndex; i < points.length - 1; i++) {
    if (!checkVal(points[i].speedLimit))
      return { line: null, nextStartPoint: i + 1 }
    if (points[i].speedLimit !== points[i + 1].speedLimit)
      return { line, nextStartPoint: i + 1 }
    line.x2 = points[i + 1].date
    line.y2 = points[i + 1].speedLimit
  }
  return { line, nextStartPoint: -1 }
}

const getContinuousLines = (points, lines, startIndex) => {
  const result = getLine(points, startIndex)
  result.line && lines.push(result.line)
  if (result.nextStartPoint === -1) return lines
  return getContinuousLines(points, lines, result.nextStartPoint)
}

export const getSpeedLimitData = (points) => {
  const lines = []
  return getContinuousLines(points, lines, 0)
}

export const getSpeedChartData = (tripPoints) => {
  const data = []
  for (var i = 0; i < tripPoints.length; i++) {
    checkVal(tripPoints[i].timestamp) &&
      checkVal(tripPoints[i].speed) &&
      data.push({
        x: new Date(tripPoints[i].timestamp),
        y: tripPoints[i].speed,
        date: new Date(tripPoints[i].timestamp),
        value: tripPoints[i].speed,
        speedLimit:
          tripPoints[i].locationCache && tripPoints[i].locationCache.speedLimit
            ? tripPoints[i].locationCache.speedLimit
            : 30
      })
  }
  return data.sort((a, b) => a.x.getTime() - b.x.getTime())
}

const EventsOnTimeline = ({
  title,
  data,
  scale,
  color,
  strokeColor,
  startDate,
  endDate,
  loading,
  StyledTooltip,
  navigateTo
}) => {
  return (
    <FlexBox direction='row'>
      <FlexBox style={{ width: Metrics.base * 2 }}>
        {/*<Text color={strokeColor} style={{ transform: 'rotateZ(-90deg)' }}>
          <b>{title.toUpperCase()}</b>
  </Text>*/}
        <FlexBox style={{ transform: 'rotateZ(-90deg)' }}>
          <Status
            style={{ marginTop: Metrics.base * 3 }}
            status={title}
            getStatusColors={() => strokeColor}
          />
        </FlexBox>
      </FlexBox>
      <EventChart
        StyledTooltip={StyledTooltip}
        startDate={startDate}
        endDate={endDate}
        data={data}
        scale={scale}
        color={color}
        strokeColor={strokeColor}
        loading={loading}
        navigateTo={navigateTo}
      />
    </FlexBox>
  )
}

const EventBreakdown = (props) => {
  const Colors = useColors()
  const tripId = useReactiveVar(selectedTripId)
  const {
    data: events,
    loading: eventsLoading,
    speeding,
    phoneMotion,
    harshBraking,
    harshAccelerating,
    harshCornering
  } = useEventsByTripId(tripId)
  const { data: trip } = useTrip(tripId)
  const [eventData, setEventData] = useState()
  const [speedingChartData, setSpeedingChartData] = useState()
  const [speedLimitData, setSpeedLimitData] = useState()
  const [phoneMotionChartData, setPhoneMotionChartData] = useState()
  const [brakingChartData, setBrakingChartData] = useState()
  const [accelerationChartData, setAccelerationChartData] = useState()
  const [corneringChartData, setCorneringChartData] = useState()
  // const { data: trip } = useTrip(tripId)
  const [scale, setScale] = useState(1)
  const [speedChartData, setSpeedChartData] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (speeding && speeding.length > 0) {
      createEventData({ events: speeding, setEventData: setSpeedingChartData })
    } else {
      setSpeedingChartData([])
    }
    if (phoneMotion && phoneMotion.length > 0) {
      createEventData({
        events: phoneMotion,
        setEventData: setPhoneMotionChartData
      })
    } else {
      setPhoneMotionChartData([])
    }
    if (harshBraking && harshBraking.length > 0) {
      createEventData({
        events: harshBraking,
        setEventData: setBrakingChartData
      })
    } else {
      setBrakingChartData([])
    }
    if (harshAccelerating && harshAccelerating.length > 0) {
      createEventData({
        events: harshAccelerating,
        setEventData: setAccelerationChartData
      })
    } else {
      setAccelerationChartData([])
    }
    if (harshCornering && harshCornering.length > 0) {
      createEventData({
        events: harshCornering,
        setEventData: setCorneringChartData
      })
    } else {
      setCorneringChartData([])
    }
    setScale(1)
  }, [events, speeding, harshBraking, harshAccelerating, harshCornering])

  useEffect(() => {
    if (trip && trip.tripPoints && trip.tripPoints.length > 0) {
      const temp = getSpeedChartData(trip.tripPoints)
      setSpeedChartData(temp)
      setSpeedLimitData(getSpeedLimitData(temp))
    }
  }, [trip])

  const [StyledTooltip] = useState(
    withStyles((theme) => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  const navigateTo = useNavigation()

  const { mainContainerWidth } = useAppDimensions()

  const startDate = trip && new Date(trip.startTime)
  const endDate = trip && new Date(trip.endTime)

  const { data: asset } = useAsset(trip && trip.assetId)
  const vehicleMaxSpeed = asset && asset.maxSpeed ? asset.maxSpeed : 80
  return (
    <FlexBox
      direction='column'
      style={{
        alignItems: 'flex-start',
        paddingBottom: 0,
        paddingTop: Metrics.base * 2,
        paddingLeft: Metrics.base * 2,
        paddingRight: Metrics.base * 2,
        height: '100%',
        width: mainContainerWidth
      }}
    >
      <Subsection
        style={{
          width: '100%',
          marginBottom: Metrics.base * 2
        }}
      >
        {checkVal(tripId) ? (
          <>
            <FlexBox
              style={{
                width: '100%',
                padding: Metrics.base * 2,
                borderBottom: `1px solid ${Colors.dividerColor}`
              }}
            >
              <Title>Events vs Time</Title>
            </FlexBox>
            {/*<div
          direction='row'
          style={{
            alignItems: 'flex-start',
            paddingBottom: 0,
            height: 450,
            width: 950
            // overflowX: 'scroll'
          }}
        >
          <FlexBox
            direction='row'
            style={{
              alignItems: 'flex-start',
              paddingBottom: 0,
              height: 450,
              width: 950
            }}
          >
            <BulletChart data={eventData} />
          </FlexBox>
          </div>*/}
            <FlexBox
              style={{
                width: '100%',
                padding: Metrics.base * 2,
                paddingLeft: Metrics.base * 3,
                paddingRight: Metrics.base * 4
              }}
            >
              <Text style={{ minWidth: 130 }}>Horizontal scale:</Text>
              <Slider
                style={{ color: Colors.primary }}
                onChange={(e, value) => setScale(value)}
                value={scale}
                // ValueLabelComponent={ValueLabelComponent}
                aria-label='custom thumb label'
                defaultValue={0}
              />
            </FlexBox>
            <FlexBox
              direction='column'
              style={{
                alignItems: 'flex-start',
                paddingBottom: 0,
                // height: 600,
                width: '100%',
                overflowX: 'scroll'
              }}
            >
              <FlexBox
                direction='column'
                style={{
                  alignItems: 'flex-start'
                }}
              >
                <EventsOnTimeline
                  title='braking'
                  data={brakingChartData}
                  scale={scale}
                  color={Colors.harshBraking}
                  strokeColor={Colors.harshBrakingText}
                  startDate={startDate}
                  endDate={endDate}
                  loading={eventsLoading}
                  StyledTooltip={StyledTooltip}
                  navigateTo={navigateTo}
                />
                <EventsOnTimeline
                  title='acceleration'
                  data={accelerationChartData}
                  scale={scale}
                  color={Colors.harshAccelerating}
                  strokeColor={Colors.harshAcceleratingText}
                  startDate={startDate}
                  endDate={endDate}
                  loading={eventsLoading}
                  StyledTooltip={StyledTooltip}
                  navigateTo={navigateTo}
                />
                <EventsOnTimeline
                  title='cornering'
                  data={corneringChartData}
                  scale={scale}
                  color={Colors.HARSH_CORNERING}
                  strokeColor={Colors.harshCorneringText}
                  startDate={startDate}
                  endDate={endDate}
                  loading={eventsLoading}
                  StyledTooltip={StyledTooltip}
                  navigateTo={navigateTo}
                />
                <EventsOnTimeline
                  title='phone'
                  data={phoneMotionChartData}
                  scale={scale}
                  color={Colors.PHONE_MOTION}
                  strokeColor={Colors.phoneMotionText}
                  startDate={startDate}
                  endDate={endDate}
                  loading={eventsLoading}
                  StyledTooltip={StyledTooltip}
                  navigateTo={navigateTo}
                />
                <EventsOnTimeline
                  title='speeding'
                  data={speedingChartData}
                  scale={scale}
                  color={Colors.speeding}
                  strokeColor={Colors.speedingText}
                  startDate={startDate}
                  endDate={endDate}
                  loading={eventsLoading}
                  StyledTooltip={StyledTooltip}
                  navigateTo={navigateTo}
                />
                <FlexBox style={{ marginLeft: Metrics.base * 2 }}>
                  <SpeedChart
                    startDate={startDate}
                    endDate={endDate}
                    chartWidth={
                      mainContainerWidth - Metrics.base * 6 + scale * 100
                    }
                    vehicleMaxSpeed={vehicleMaxSpeed}
                    chartHeight={250}
                    color={Colors.primaryOpacity3}
                    strokeColor={Colors.primary}
                    data={speedChartData ? speedChartData : []}
                    speedLimitData={speedLimitData ? speedLimitData : []}
                    loading={eventsLoading}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            {/*<HorizonChart />*/}
          </>
        ) : (
          <FlexBox style={{ height: 500 }}>
            <Display color={Colors.dividerColor}>No Trip Selected</Display>
          </FlexBox>
        )}
      </Subsection>
      {/*<Subsection
        style={{
          width: '100%'
        }}
      >
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base * 2,
            borderBottom: `1px solid ${Colors.dividerColor}`
          }}
        >
          <Title>Speed vs Time</Title>
        </FlexBox>
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            width: '100%',
            overflowX: 'scroll'
          }}
        >
          <FlexBox
            style={{
              alignItems: 'flex-start',
              height: 300,
              width: 1300
            }}
          >
            {speedChartData && <LineChart data={speedChartData} />}
          </FlexBox>
          <FlexBox
            style={{
              alignItems: 'flex-start',
              height: 300,
              width: 1300
            }}
          >
            {speedChartData && (
              <AreaChart
                scale={scale}
                color={Colors.harshCornering}
                strokeColor={Colors.harshCorneringText}
                data={speedChartData[0].data}
              />
            )}
          </FlexBox>
        </FlexBox>
            </Subsection>*/}
    </FlexBox>
  )
}

export default EventBreakdown
