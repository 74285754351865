import React, { useState, useEffect } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useFilters } from '../useFilters'
import { eventFilters } from '../../GraphQLClient'
import { useReactiveVar } from '@apollo/client'

export const driverLicenceFragment = gql`
  fragment driverLicenceFragment on DriverLicence {
    _id
    countryOfIssue
    controlNumber
    dob
    dateIssued
    doe
    professionalDrivingPermit
    professionalDrivingPermitExpiryDate
    driverRestrictions
    vehicleRestrictions
    licenceCode
    driver {
      _id
    }
    isActive
    document {
      documentType
      _id
      url
    }
    licenceBackDocument {
      documentType
      _id
      url
    }
  }
`

export const GET_DRIVER_LICENCES = gql`
  query driverLicences(
    $driverId: String
    $isActive: Boolean
    $skip: Int
    $limit: Int
  ) {
    driverLicences(
      driverId: $driverId
      isActive: $isActive
      skip: $skip
      limit: $limit
    ) {
      ...driverLicenceFragment
    }
  }
  ${driverLicenceFragment}
`

export const driverLicenceTransform = ({ data }) => {
  let res = []
  if (data) {
    res = data.map((item) => {
      return { ...item, id: item._id ? item._id : null }
    })
  }
  return res
}
const getDriverLicenceVariables = (driverLicenceVariables) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (driverLicenceVariables && driverLicenceVariables.driverId) {
    variables = {
      ...variables,
      driverId: driverLicenceVariables.driverId
    }
  }
  if (
    driverLicenceVariables &&
    typeof driverLicenceVariables.isActive !== 'undefined'
  ) {
    variables = {
      ...variables,
      isActive: true
    }
  }

  return variables
}

export const useDriverLicences = (driverLicenceVariables) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])

  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_DRIVER_LICENCES, {
    variables: getDriverLicenceVariables(driverLicenceVariables),
    queryName: 'driverLicences',
    errorMessage: 'Failed to fetch driver licence',
    dataTransform: (data) => driverLicenceTransform({ data }),
    skip: !(driverLicenceVariables && driverLicenceVariables.driverId)
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
