import React from 'react'
import { FlexBox } from '..'
import { getScoreCategory } from '../../Containers/Safety/Asset/Breakdown'
import { checkVal } from '../../Helpers/Functions'
import { Images, Metrics, useColors } from '../../Themes'
import { ModifiedPieChart } from './ModifiedPieChart'

const ScoreCircleChart = ({
  color,
  backgroundColor,
  size = 140,
  score,
  maxScore = 10,
  cornerRadius = 0
}) => {
  const Colors = useColors()
  const category = getScoreCategory(score)
  return (
    <div
      style={{
        height: size,
        width: size,
        position: 'relative',
        borderRadius: size / 2
        // backgroundColor: backgroundColor
      }}
    >
      {checkVal(score) && (
        <>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }}
          >
            <FlexBox style={{ width: '100%', height: '100%' }}>
              <div
                style={{
                  width: size - 20,
                  height: size - 20,
                  borderRadius: (size - 20) / 2,
                  backgroundColor: color,
                  opacity: 0.15
                }}
              ></div>

              {/*<div
            style={{ width: '40%', height: '40%', backgroundColor: 'red' }}
          ></div>*/}
              {/*<img width={'60%'} alt='badge' src={Images.shield_green} />*/}
            </FlexBox>
          </div>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }}
          >
            <FlexBox style={{ width: '100%', height: '100%' }}>
              {/*<div
            style={{ width: '40%', height: '40%', backgroundColor: 'red' }}
          ></div>*/}
              <img
                width={size / 3.2}
                alt='badge'
                src={Images[`shield_${category}`]}
              />
            </FlexBox>
          </div>
        </>
      )}
      <ModifiedPieChart
        colors={[color, checkVal(score) ? backgroundColor : Colors.background]}
        hideLegend
        hideLabels
        cornerRadius
        padAngle={0}
        innerRadius={size > 120 ? 0.9 : size > 100 ? 0.87 : 0.85}
        startAngle={-180}
        endAngle={180}
        enableArcLinkLabels={false}
        enableSlicesLabels={false}
        enableArcLabels={false}
        isInteractive
        data={[
          {
            id: 'score',
            label: 'score',
            value: score
          },
          {
            id: 'empty',
            label: '',
            value: maxScore - score
          }
        ]}
      />
    </div>
  )
}
export default ScoreCircleChart
