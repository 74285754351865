import { useEffect, useState } from 'react'
import { useAssetScoreAtDate } from '../../../../Graphql/Queries/Asset/SafetyScoreHistory/useAssetScoreAtDate'
import { checkVal } from '../../../../Helpers/Functions'
import {
  getChange,
  getScoreHistory
} from '../../Driver/Categories/useScoreHistory'

const weekInMilliseconds = 6.048e8
const monthInMilliseconds = 2.628e9

export const useScoreHistory = ({ assetId, scoreHistoryData, category }) => {
  const [scoreHistory, setScoreHistory] = useState()
  const [previousWeekChange, setPreviousWeekChange] = useState()
  const [previousMonthChange, setPreviousMonthChange] = useState()
  const [previousYearChange, setPreviousYearChange] = useState()

  const [now] = useState(new Date().getTime())

  const { data: scoreSixMonthsAgo } = useAssetScoreAtDate({
    assetId,
    date: now - monthInMilliseconds * 6,
    category
  })
  const { data: scoreLastWeekData } = useAssetScoreAtDate({
    assetId,
    date: now - weekInMilliseconds,
    category
  })
  const { data: scoreLastMonthData } = useAssetScoreAtDate({
    assetId,
    date: now - monthInMilliseconds,
    category
  })
  const { data: scoreLastYearData } = useAssetScoreAtDate({
    assetId,
    date: now - monthInMilliseconds,
    category
  })

  const dataChangedCheck =
    scoreHistoryData && scoreHistoryData[0] && scoreHistoryData[0]._id
      ? scoreHistoryData[0]._id
      : false

  useEffect(() => {
    if (
      scoreHistoryData &&
      scoreHistoryData.length > 0 &&
      checkVal(scoreSixMonthsAgo)
    ) {
      setScoreHistory(
        getScoreHistory({
          data: scoreHistoryData,
          now,
          scoreSixMonthsAgo,
          id: dataChangedCheck
        })
      )
    } else {
      setScoreHistory([])
    }
  }, [scoreHistoryData, dataChangedCheck, scoreSixMonthsAgo, now, assetId])

  useEffect(() => {
    if (scoreHistoryData && scoreHistoryData.length > 0) {
      const latestScore = scoreHistoryData[scoreHistoryData.length - 1].value
      setPreviousWeekChange(getChange({ latestScore, data: scoreLastWeekData }))
      setPreviousMonthChange(
        getChange({ latestScore, data: scoreLastMonthData })
      )
      setPreviousYearChange(getChange({ latestScore, data: scoreLastYearData }))
    } else {
      setPreviousWeekChange(null)
      setPreviousMonthChange(null)
      setPreviousYearChange(null)
    }
  }, [
    scoreHistoryData,
    dataChangedCheck,
    scoreLastWeekData,
    scoreLastMonthData,
    scoreLastYearData
  ])

  return {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  }
}
