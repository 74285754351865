import { useState, useEffect } from 'react'
import {
  TOMTOM_API_KEY,
  tripReplayAkimaFunctions,
  tripReplaySpeedLimitData,
  tripReplayTime
} from '../../Graphql/GraphQLClient'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { useTrip } from '../../Graphql/Queries/Trip/useTrip'
import { checkVal } from '../../Helpers/Functions'
import { getAkimaSplineFunctions } from '../LiveMonitoring/useAkimaAnimation'
import { default as ttServices } from '@tomtom-international/web-sdk-services'

const getSpeedLimitData = (tripPoints, trip) => {
  const speedLimitData = []
  let lastSpeedLimit = -1
  let lastIndex = 0
  for (var i = 0; i <= tripPoints.length; i++) {
    const speedLimit =
      tripPoints[i] &&
      tripPoints[i].locationCache &&
      tripPoints[i].locationCache.speedLimit
    const time = tripPoints[i] && tripPoints[i].timestamp
    if (speedLimit !== lastSpeedLimit) {
      if (checkVal(time)) {
        speedLimitData.push({ startTime: time, speedLimit })
      }
      if (checkVal(lastIndex) && speedLimitData[lastIndex]) {
        speedLimitData[lastIndex].endTime = time
      }
      lastSpeedLimit = speedLimit
      lastIndex = speedLimitData.length - 1
    }
  }
  if (speedLimitData.length > 0 && tripPoints.length > 0) {
    speedLimitData[speedLimitData.length - 1].endTime =
      tripPoints[tripPoints.length - 1].timestamp
  }
  return speedLimitData
}

const getEventsWithPoints = ({ tripPoints, events }) => {
  const eventsWithPoints = []
  for (var i = 0; i < events.length; i++) {
    const eventStart = events[i].startDate && events[i].startDate.getTime()
    const eventEnd = events[i].endDate && events[i].endDate.getTime()
    if (checkVal(eventStart) && checkVal(eventEnd)) {
      const points = []
      for (var j = 0; j < tripPoints.length; j++) {
        if (
          tripPoints &&
          tripPoints[j] &&
          tripPoints[j].timestamp >= eventStart &&
          tripPoints[j].timestamp <= eventEnd
        ) {
          points.push({ ...tripPoints[j] })
        }
      }
      eventsWithPoints.push({ ...events[i], points })
    }
  }
  return eventsWithPoints
}

const getGeoJSON = (tripPoints, setRouteGeoJSON) => {
  ttServices.services
    .calculateRoute({
      key: TOMTOM_API_KEY,
      locations: [tripPoints[0], tripPoints[tripPoints.length - 1]],
      supportingPoints: tripPoints
    })
    .then((routeData) => {
      return setRouteGeoJSON(routeData.toGeoJson())
    })
}

const useTripData = (tripId) => {
  const [routeGeoJSON, setRouteGeoJSON] = useState([])
  const [eventsWithPoints, setEventsWithPoints] = useState([])
  const [events, setEvents] = useState([])
  const [trip, setTrip] = useState([])
  const [tripPoints, setTripPoints] = useState([])
  const [tripDuration, setTripDutation] = useState(0)
  const [startTimeUnix, setStartTimeUnix] = useState(0)

  const { data: tripData, loading: tripLoading } = useTrip(tripId)
  const { data: eventsData, loading: eventsLoading } = useEventsByTripId(tripId)
  useEffect(() => {
    setRouteGeoJSON(null)
    setEventsWithPoints(null)
    setEvents(null)
    setTrip(null)
    setTripPoints(null)
    setTripDutation(null)
    setStartTimeUnix(null)
    tripReplayAkimaFunctions({
      lng: null,
      lat: null,
      heading: null,
      speed: null
    })
    tripReplaySpeedLimitData([])
    // tripReplayTime(null)
  }, [tripId])

  useEffect(() => {
    setTrip(tripData)
    setTripPoints(
      tripData && tripData.tripPoints
        ? tripData.tripPoints.sort(
            (a, b) =>
              new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          )
        : null
    )
  }, [tripData])

  useEffect(() => {
    setEvents(eventsData)
  }, [eventsData])

  useEffect(() => {
    if (tripPoints && tripPoints.length > 4 && events && events.length > 0) {
      const startTime = trip.startTime
      const endTime = trip.endTime
      const tempStartTimeUnix = new Date(startTime).getTime()
      const endTimeUnix = new Date(endTime).getTime()
      const tempDuration = endTimeUnix - tempStartTimeUnix
      setStartTimeUnix(tempStartTimeUnix)
      setTripDutation(tempDuration)
      tripReplayAkimaFunctions(getAkimaSplineFunctions(tripPoints))
      tripReplaySpeedLimitData(getSpeedLimitData(tripPoints, trip))
      if (
        tripReplayTime() < tempStartTimeUnix ||
        tripReplayTime() > endTimeUnix
      ) {
        tripReplayTime(tempStartTimeUnix)
      }
      getGeoJSON(tripPoints, setRouteGeoJSON)
      setEventsWithPoints(getEventsWithPoints({ tripPoints, events }))
    }
  }, [events, trip, tripPoints])

  return {
    trip,
    tripPoints,
    events,
    routeGeoJSON,
    eventsWithPoints,
    tripDuration,
    startTimeUnix,
    loading: tripLoading || eventsLoading
  }
}

export default useTripData
