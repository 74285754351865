import React from 'react'
import { Text, ProcessingSpinner, Icon } from '../'
import { Styles } from './Styles'
import { Metrics, useColors } from '../../Themes'
import Status from '../Status'
import { checkVal } from '../../Helpers/Functions'
import { Link } from '@material-ui/core'

const DataDisplay = ({
  label,
  labelLink,
  data,
  status,
  statusColor,
  loading,
  prefix,
  suffix,
  fontSize = 'medium',
  color,
  textColor,
  columnWidthRatio,
  style,
  rowStyle = { height: Metrics.base * 6 },
  firstItem = false,
  lastItem = false,
  borderTop = false,
  borderLeft = false,
  borderRight = false,
  prefixIcon
}) => {
  const Colors = useColors()
  const styles = Styles({ Colors, Metrics })
  return (
    <div
      style={{
        borderLeft: borderLeft
          ? `5px solid ${color ? color : Colors.subsectionBackground}`
          : 'none',
        borderRight: borderRight ? `1px solid ${Colors.dividerColor}` : 'none',
        borderTop: borderTop ? `1px solid ${Colors.dividerColor}` : 'none',
        width: '100%',
        marginTop: firstItem ? 0 : -Metrics.base,
        paddingTop: Metrics.base,
        ...style
      }}
    >
      <div
        style={{
          ...styles.dataDisplayContainer,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          marginBottom: lastItem ? 0 : Metrics.base,
          ...rowStyle
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: columnWidthRatio ? Number(columnWidthRatio.split(':')[0]) : 3
          }}
        >
          {prefixIcon && (
            <Icon
              name='star'
              color={Colors.primaryOpacity2}
              size='small'
              style={{ marginLeft: Metrics.base }}
            />
          )}
          {labelLink && label ? (
            <Link
              underline='always'
              color={Colors.primary}
              style={{
                color: Colors.primary
              }}
              target='_blank'
              href={labelLink}
            >
              <Text color={Colors.primary} font={fontSize}>
                <i>{label && label !== '' ? label : ''}</i>
              </Text>
            </Link>
          ) : label ? (
            <Text
              color={textColor ? textColor : Colors.secondary}
              font={fontSize}
            >
              <i>{label && label !== '' ? label : ''}</i>
            </Text>
          ) : null}
        </div>
        <div
          style={{
            width: 1,
            height:
              rowStyle && rowStyle.height
                ? rowStyle.height + Metrics.base
                : Metrics.base * 5,
            backgroundColor: Colors.dividerColorOpacity2,
            marginTop: -Metrics.base,
            marginBottom: -Metrics.base
          }}
        ></div>
        <div style={{ display: 'flex', flex: 4, paddingLeft: Metrics.spacing }}>
          {loading ? (
            <ProcessingSpinner ml={2} size={16} />
          ) : (
            <Text
              color={textColor ? textColor : Colors.secondary}
              marginLeft={1}
              font={fontSize}
            >
              {checkVal(data) || checkVal(status) ? (
                <>
                  {prefix && prefix} {checkVal(data) && data}{' '}
                  {status && (
                    <Status
                      status={status}
                      getStatusColors={() => statusColor}
                    />
                  )}{' '}
                  {suffix && suffix}
                </>
              ) : (
                '-'
              )}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

export default DataDisplay
