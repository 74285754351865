import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'

export const safetyScoreOverTimeFragment = gql`
  fragment safetyScoreOverTimeFragment on SafetyScoreOverTime {
    avg
    d
  }
`
export const GET_SAFETY_OVER_TIME = gql`
  query driverSafetyOverTime(
    $orgIdsFilter: [String] #$assetGroupId: String
  ) {
    driverSafetyOverTime(
      orgIdsFilter: $orgIdsFilter #assetGroupId: $assetGroupId
    ) {
      ...safetyScoreOverTimeFragment
    }
  }
  ${safetyScoreOverTimeFragment}
`

const dataTransform = ({ data }) => {
  return data && data.map((a) => ({ ...a, d: new Date(a.d) }))
}

export const useDriverSafetyOverTime = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_SAFETY_OVER_TIME,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'driverSafetyOverTime',
      errorMessage: 'Failed to fetch driverSafetyOverTime',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
