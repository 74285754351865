import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { locationFragment } from '../useLocation'
import { getDisplayText } from '../Event/useEvent'
import { checkVal } from '../../../Helpers/Functions'

export const tripFragment = gql`
  fragment tripFragment on Trip {
    _id
    totalDistance
    totalTravelTime
    totalTomTomTravelTime
    startTime
    endTime
    startAddress
    endAddress
    tripStatus
    tripSafetyScore
    displayId
    averageSpeed
  }
`

export const GET_TRIP = gql`
  query trip($id: String!) {
    trip(id: $id) {
      tripPoints {
        locationCache {
          speedLimit
        }
        ...locationFragment
      }
      ...tripFragment
      device {
        _id
        deviceId
      }
      asset {
        _id
        name
      }
      driver {
        _id
        firstName
        lastName
      }
    }
  }
  ${locationFragment}
  ${tripFragment}
`

export const tripDataTransform = ({ data }) => {
  const tripPoints =
    data && data.tripPoints
      ? data.tripPoints.map((point, index) => {
          let timestamp = point.timestamp
          // here we make sure no two timestamps have exactly the same value
          // can break interpolation functions
          if (checkVal(data.tripPoints[index - 1])) {
            if (timestamp === data.tripPoints[index - 1].timestamp) {
              timestamp += 1
            }
          }
          return {
            ...point,
            timestamp: timestamp && new Date(timestamp).getTime()
          }
        })
      : null
  return {
    ...data,
    deviceId: data.device && data.device._id,
    driverId: data.driver && data.driver._id,
    driverName:
      data.driver && `${data.driver.firstName} ${data.driver.lastName}`,
    assetId: data.asset && data.asset._id,
    assetName: data.asset && data.asset.name,
    startTimeDisplay:
      data.startTime && new Date(data.startTime).toLocaleString('en-GB'),
    endTimeDisplay:
      data.endTime && new Date(data.endTime).toLocaleString('en-GB'),
    tripPoints: tripPoints ? tripPoints : data.tripPoints
  }
}

export const useTrip = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(GET_TRIP, {
    variables: { id },
    queryName: 'trip',
    errorMessage: 'Failed to fetch Trip',
    dataTransform: (data) => tripDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
