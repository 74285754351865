import React, { Component } from 'react'
import { Button, FormBox } from '../../../Components'
import Styles from '../Styles'
// import Client from '../../../Themes'
import { Colors, Metrics } from '../../../Themes'
import DocumentForm from './DocumentForm'

const driverDocumentTypeOptions = () => {
  return [
    { label: 'Driver Licence Front', value: 'DRIVER_LICENCE_FRONT' },
    { label: 'Driver Licence Back', value: 'DRIVER_LICENCE_BACK' },
    { label: 'Driver ID Front', value: 'DRIVER_ID' },
    { label: 'Driver ID Back', value: 'DRIVER_ID_BACK' },
    { label: 'Passport', value: 'DRIVER_PASSPORT' },
    { label: 'Health Check', value: 'HEALTH_CHECK' },
    { label: 'Other', value: 'OTHER' }
  ]
}

const assetDocumentTypeOptions = () => {
  return [
    { label: 'Vehicle Licence', value: 'VEHICLE_LICENCE' },
    { label: 'Vehicle Service', value: 'VEHICLE_SERVICE' },
    { label: 'Other', value: 'OTHER' }
  ]
}

const inspectionDocumentTypeOptions = () => {
  return [
    { label: 'Main Inspection', value: 'INSPECTION_MAIN' },
    { label: 'Extra Inspection Photos', value: 'INSPECTION_EXTRAS' }
  ]
}
const crashDocumentTypeOptions = () => {
  return [{ label: 'Vehicle Crash', value: 'VEHICLE_CRASH' }]
}
const incidentDocumentTypeOptions = () => {
  return [{ label: 'Vehicle Incident', value: 'VEHICLE_INCIDENT' }]
}

class Document extends Component {
  constructor(props) {
    super(props)
    this.state = {
      _id: '',
      documentType: '',
      filename: '',
      countryOfIssue: '',
      controlNumber: '',
      dob: '',
      dateIssued: '',
      professionalDrivingPermitExpiryDate: '',
      licenceCode: '',
      doe: '',
      professionalDrivingPermit: '',
      driverRestrictions: '',
      vehicleRestrictions: ''
    }
  }

  componentDidMount() {
    const { document } = this.props
    if (document) {
      this.setDetails(document)
    }
  }

  checkFormComplete = () => {
    const { documentType } = this.state
    return this.checkValue(documentType)
  }

  checkAcceptedFiles = () => {
    const { acceptedFiles } = this.props
    const { _id } = this.state
    if (_id) return false
    return acceptedFiles.length === 0 || acceptedFiles.length > 1
  }

  checkValue = (value) => {
    return value !== '' && value !== null && value !== undefined
  }

  setDetails = (data) => {
    this.setState({
      _id: data._id ? data._id : null,
      documentType: data.documentType ? data.documentType : '',
      filename: data.filename ? data.filename : null
    })
  }

  _handleChange = (key, value) => {
    this.setState({ [key]: !value ? '' : value })
  }
  render() {
    const { _id, documentType, filename } = this.state

    const {
      handleClose,
      handleDrop,
      acceptedFiles,
      uploading,
      removeFile,
      disabled,
      handleDownloadDocument,
      driverId,
      assetId,
      inspectionId,
      incidentId,
      crashId,
      typeOptions
    } = this.props
    let typeOptions1 =
      typeOptions === 'driver'
        ? driverDocumentTypeOptions()
        : typeOptions === 'asset'
        ? assetDocumentTypeOptions()
        : typeOptions === 'inspection'
        ? inspectionDocumentTypeOptions()
        : typeOptions === 'crash'
        ? crashDocumentTypeOptions()
        : typeOptions === 'incident'
        ? incidentDocumentTypeOptions()
        : null
    return (
      <DocumentForm
        handleChange={this._handleChange}
        handleDrop={handleDrop}
        acceptedFiles={acceptedFiles}
        disabled={disabled}
        removeFile={removeFile}
        _id={_id}
        documentType={documentType}
        driverId={driverId}
        assetId={assetId}
        inspectionId={inspectionId}
        incidentId={incidentId}
        crashId={crashId}
        // createdAt={createdAt}
        filename={filename}
        handleDownloadDocument={handleDownloadDocument}
        typeOptions={typeOptions1}
        isSubmitting={uploading}
        handleCancel={handleClose}
        handleSubmit={
          _id
            ? () =>
                this.props.handleUpdateDocument({
                  _id,
                  documentType,
                  driverId
                  // createdAt
                })
            : (data) =>
                this.props.handleNewDocument({
                  ...data
                })
        }
      />
    )
  }
}

export default Document
