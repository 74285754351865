import { Metrics } from '../../Themes'

export const Styles = {
  mainContainer: {
    minHeight: Metrics.window.height
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0, // Metrics.base * 3,
    minHeight: Metrics.window.height // - Metrics.navBarHeight
  },
  footerContainer: {
    width: '100%',
    padding: Metrics.base * 2,
    opacity: 0.6
  }
}
