import React from 'react'
import { MainLayout } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import {
  selectedAssetId,
  selectedDriverId,
  selectedTabs,
  selectedTripId
} from '../../Graphql/GraphQLClient'
import DriverScoreBreakdown from './Driver/Breakdown'
import TripScoreBreakdown from './Trip/Breakdown'
import AssetScoreBreakdown from './Asset/Breakdown'
import AssetOverview from './Asset/Overview'
import DriverOverview from './Driver/Overview'
import TripOverview from './Trip/Overview'
import Education from './Driver/Education'
import TabRoutes from '../../Components/TabRoutes'
import { useReactiveVar } from '@apollo/client'
import DriverHeader from '../../Components/Custom/DriverHeader'
import TripHeader from '../../Components/Custom/TripHeader'
import AssetHeader from '../../Components/Custom/AssetHeader'

const Safety = (props) => {
  const driverId = useReactiveVar(selectedDriverId)
  const tripId = useReactiveVar(selectedTripId)
  const assetId = useReactiveVar(selectedAssetId)
  const { driverSafetyTabs, assetSafetyTabs, tripSafetyTabs } =
    useReactiveVar(selectedTabs)

  return (
    <MainLayout {...props}>
      <TabRoutes
        renderTabBar={false}
        tabsData={[
          { id: 'drivers', name: 'Drivers' },
          { id: 'trips', name: 'Trips' },
          { id: 'assets', name: 'Assets' }
        ]}
      >
        <>
          {checkVal(driverId) && <DriverHeader driverId={driverId} />}
          <TabRoutes
            defaultId={driverSafetyTabs ? driverSafetyTabs : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), driverSafetyTabs: id })
            }
            tabsData={[
              { id: 'overview', name: 'Overview' },
              { id: 'driver-behaviour', name: 'Driver Behaviour' },
              { id: 'education', name: 'Education' }
            ]}
          >
            <DriverOverview />
            <DriverScoreBreakdown />
            <Education />
          </TabRoutes>
        </>
        <>
          {checkVal(tripId) && <TripHeader tripId={tripId} />}
          <TabRoutes
            defaultId={tripSafetyTabs ? tripSafetyTabs : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), tripSafetyTabs: id })
            }
            tabsData={[
              { id: 'overview', name: 'Overview' },
              { id: 'score-breakdown', name: 'Score Breakdown' }
            ]}
          >
            <TripOverview />
            <TripScoreBreakdown />
          </TabRoutes>
        </>
        <>
          {checkVal(assetId) && <AssetHeader assetId={assetId} />}
          <TabRoutes
            defaultId={assetSafetyTabs ? assetSafetyTabs : null}
            onChange={(id) =>
              selectedTabs({ ...selectedTabs(), assetSafetyTabs: id })
            }
            tabsData={[
              { id: 'overview', name: 'Overview' },
              { id: 'score-breakdown', name: 'Score Breakdown' }
            ]}
          >
            <AssetOverview />
            <AssetScoreBreakdown />
          </TabRoutes>
        </>
      </TabRoutes>
    </MainLayout>
  )
}
export default Safety
