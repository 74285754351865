import React, { useState, createRef, useEffect, useRef } from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import { FlexBox, GoogleMap, Subsection, Text } from '../../Components'
import {
  checkVal,
  findSelectedObject,
  getSelectedObject
} from '../../Helpers/Functions'
import { useSelectedDriver } from '../../Graphql/Queries/Driver/useSelectedDriver'
import { Marker, MarkerClusterer } from '@react-google-maps/api'
import { useDrivers } from '../../Graphql/Queries/Driver/useDrivers'
import {
  mapCamera,
  selectedAssetId,
  selectedDeviceId,
  selectedDriverId,
  selectedEventId,
  selectedTripId,
  selectedVVTDeviceId
} from '../../Graphql/GraphQLClient'
import { ModifiedPieChart } from '../../Components/Custom/ModifiedPieChart'
import { Display, Subheading } from '../../Components/Typography'
import SideBar from './MapSideBar'
import { useDevices } from '../../Graphql/Queries/Device/useDevices'
import { useLastLocations } from '../../Graphql/Queries/useLastLocations'
import { truckIcon } from '../LiveMonitoring/TruckIcon'
import TomTomMap from '../../Components/TomTomMap'
import { useTrip } from '../../Graphql/Queries/Trip/useTrip'
import { useEvents } from '../../Graphql/Queries/Event/useEvents'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { useSelectedTabs } from '../../Graphql/Queries/useSelectedTabs'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useMapCamera } from '../../Graphql/Queries/useMapCamera'
import { useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import useTripData from './useTripData'

const radians_to_degrees = (radians) => {
  return radians * (180 / Math.PI)
}

const handleMarkerClick = (location) => {
  const device = location && location.device
  const driver = device && device.driver
  const asset = device && device.asset
  const deviceId = device && device.deviceId
  const VVTdeviceId = device && device.VVTdeviceId
  const driverId = driver && driver._id
  const assetId = asset && asset._id
  selectedDriverId(driverId)
  selectedDeviceId(deviceId)
  selectedAssetId(assetId)
  selectedVVTDeviceId(VVTdeviceId)
}

const markerSizeLarge = 50
const markerSizeSmall = 40

const clusterStyles = [
  {
    textColor: '#ffffff',
    textSize: 14,
    width: markerSizeSmall,
    height: markerSizeSmall,
    url: `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="${markerSizeSmall}" width="${
      markerSizeSmall * 2
    }"%3E%3Ccircle cx="${markerSizeSmall / 2}" cy="${markerSizeSmall / 2}" r="${
      markerSizeSmall / 2 - 5
    }" stroke="rgba(38,138,255,0.4)" stroke-width="5" fill="rgba(38,138,255,1)" /%3E%3C/svg%3E`
  },
  {
    textColor: '#ffffff',
    textSize: 20,
    width: markerSizeSmall,
    height: markerSizeSmall,
    url: `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="${markerSizeSmall}" width="${
      markerSizeSmall * 2
    }"%3E%3Ccircle cx="${markerSizeSmall / 2}" cy="${markerSizeSmall / 2}" r="${
      markerSizeSmall / 2 - 5
    }" stroke="rgba(38,138,255,0.4)" stroke-width="5" fill="rgba(38,138,255,1)" /%3E%3C/svg%3E`
  },
  {
    textColor: '#ffffff',
    textSize: 25,
    width: markerSizeLarge,
    height: markerSizeLarge,
    url: `data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="${markerSizeLarge}" width="${
      markerSizeLarge * 2
    }"%3E%3Ccircle cx="${markerSizeLarge / 2}" cy="${markerSizeLarge / 2}" r="${
      markerSizeLarge / 2 - 5
    }" stroke="rgba(38,138,255,0.4)" stroke-width="7" fill="rgba(38,138,255,1)" /%3E%3C/svg%3E`
  }
]

const LiveLocationMarkers = () => {
  const { data: lastLocations } = useLastLocations()
  return (
    <MarkerClusterer
      options={{
        averageCenter: true,
        styles: clusterStyles
      }}
    >
      {(clusterer) =>
        lastLocations &&
        lastLocations.length > 0 &&
        lastLocations.map((location, index) => {
          return (
            <Marker
              // ref={markerRefs.current[index]}
              key={index}
              clusterer={clusterer}
              position={location.location}
              onClick={() => handleMarkerClick(location)}
              options={{
                icon: Images.mapIcon1
              }}
            />
          )
        })
      }
    </MarkerClusterer>
  )
}

const animateMapCenter = ({
  mapCenter,
  latIncrement,
  lngIncrement,
  count,
  setMapCenter
}) => {
  const newCenter = {
    lat: mapCenter.lat + latIncrement,
    lng: mapCenter.lng + lngIncrement
  }
  if (count === 100) return
  setTimeout(() => {
    setMapCenter(newCenter)
    animateMapCenter({
      mapCenter: newCenter,
      latIncrement,
      lngIncrement,
      count: count + 1,
      setMapCenter
    })
  }, 50)
}

const getCenterAndZoom = (data) => {
  const zoom = 9
  var lat = 0
  var lng = 0
  /*for (var i in data) {
    lat += data[i] ? data[i].latitude : 0
    lng += data[i] ? data[i].longitude : 0
  }*/

  // start
  lat += data[0] ? data[0].latitude : 0
  lng += data[0] ? data[0].longitude : 0

  // end
  lat += data[data.length - 1] ? data[data.length - 1].latitude : 0
  lng += data[data.length - 1] ? data[data.length - 1].longitude : 0
  return {
    center: {
      lat: lat / 2, // / data.length,
      lng: lng / 2 // / data.length
    },
    zoom
  }
}

export const markerPopupHTML = (data) => {
  return `<b>${data.eventType}</b>, severity: ${data.score}<br/><a href="/event-inbox?event-id=${data.eventId}">Open in event inbox</a>`
}

export const startPopupHTML = (data) => {
  return `<b>START</b>, ${data.startTimeDisplay}<br/>${data.startAddress}<br/>`
}

export const endPopupHTML = (data) => {
  return `<b>END</b>, ${data.endTimeDisplay}<br/>${data.endAddress}<br/>`
}

export const getInitialMarkers = ({
  markerData,
  tripPoints,
  trip,
  selectedEventTypes,
  eventId,
  Colors,
  showTruckIcon
}) => {
  const initialMarkers =
    tripPoints && tripPoints.length > 0
      ? [
          {
            location: [tripPoints[0].longitude, tripPoints[0].latitude],
            icon: Images.tripStartIcon,
            width: 35,
            startAddress: trip.startAddress,
            startTimeDisplay: trip.startTimeDisplay,
            hasPopup: true,
            popupHTML: startPopupHTML
          },
          {
            location: [
              tripPoints[tripPoints.length - 1].longitude,
              tripPoints[tripPoints.length - 1].latitude
            ],
            icon: Images.tripEndIcon,
            width: 35,
            endAddress: trip.endAddress,
            endTimeDisplay: trip.endTimeDisplay,
            hasPopup: true,
            popupHTML: endPopupHTML
          }
        ]
      : []
  showTruckIcon &&
    initialMarkers.push({
      location: [tripPoints[0].longitude, tripPoints[0].latitude],
      icon: Images.truckIcon1,
      width: 60,
      heigth: 80,
      anchor: 'center'
    })
  if (markerData && markerData.length > 0) {
    for (var i = 0; i < markerData.length; i++) {
      const marker = markerData[i]
      if (selectedEventTypes[marker.type]) {
        initialMarkers.push({
          location: [marker.startLong, marker.startLat],
          rotation: 0,
          color: marker.type && Colors[marker.type],
          eventType: marker.type,
          eventId: marker._id,
          score: marker.normScore && marker.normScore.toFixed(2),
          hasPopup: true,
          popupOpen: checkVal(eventId) && marker._id === eventId,
          popupHTML: markerPopupHTML,
          icon: marker.type && Images[`${marker.type}_mapIcon`],
          width: 35
          // heigth: 80
        })
      }
    }
  }
  return initialMarkers
}

const MapView = (props) => {
  const tripId = useReactiveVar(selectedTripId)
  const eventId = useReactiveVar(selectedEventId)
  const {
    trip,
    tripPoints,
    events,
    routeGeoJSON,
    eventsWithPoints,
    startTimeUnix,
    tripDuration,
    loading
  } = useTripData(tripId)
  const { tripHistoryMapCenter, tripHistoryMapZoom } = useMapCamera()
  const [markers, setMarkers] = useState(null)
  const [initialMarkersData, setInitialMarkersData] = useState([])
  const Colors = useColors()
  const [map, setMap] = useState(null)
  const [selectedEventTypes, setSelectedEventTypes] = useState({
    SPEEDING: true,
    PHONE_MOTION: true,
    HARSH_BRAKING: true,
    HARSH_ACCELERATION: true,
    HARSH_CORNERING: true
  })
  const { mainContainerHeight, mapWidth } = useAppDimensions()

  const { pathname } = useLocation()
  const ids = pathname.split('/')
  const { tripHistoryMapTab: selectedTab } = useSelectedTabs()
  useEffect(() => {
    if (
      tripId &&
      (selectedTab === 'event-details' || ids.includes('event-details'))
    ) {
      if (events && events.length > 0) {
        setInitialMarkersData(
          getInitialMarkers({
            tripPoints,
            trip,
            markerData: events,
            selectedEventTypes,
            eventId,
            Colors
          })
        )
      }
    } else if (tripPoints) {
      setInitialMarkersData(
        tripPoints && tripPoints.length > 0
          ? [
              {
                location: [tripPoints[0].longitude, tripPoints[0].latitude],
                icon: Images.tripStartIcon,
                width: 35,
                startAddress: trip.startAddress,
                startTimeDisplay: trip.startTimeDisplay,
                hasPopup: true,
                popupOpen: true,
                popupHTML: startPopupHTML
              },
              {
                location: [
                  tripPoints[tripPoints.length - 1].longitude,
                  tripPoints[tripPoints.length - 1].latitude
                ],
                icon: Images.tripEndIcon,
                width: 35,
                endAddress: trip.endAddress,
                endTimeDisplay: trip.endTimeDisplay,
                hasPopup: true,
                popupOpen: true,
                popupHTML: endPopupHTML
              }
            ]
          : []
      )
    }
  }, [tripPoints, tripId, trip, events, selectedTab, selectedEventTypes])

  useEffect(() => {
    if (map) {
      if (tripPoints && tripPoints.length > 1) {
        const bounds = [
          [tripPoints[0].longitude, tripPoints[0].latitude],
          [
            tripPoints[tripPoints.length - 1].longitude,
            tripPoints[tripPoints.length - 1].latitude
          ]
        ]
        const newMapCamera = map.cameraForBounds(bounds, {
          padding: { top: 100, bottom: 100, left: 100, right: 100 },
          maxZoom: 13
        })
        newMapCamera &&
          mapCamera({
            ...mapCamera(),
            tripHistory: {
              center: {
                lat: newMapCamera.center.lat,
                lng: newMapCamera.center.lng
              },
              zoom: newMapCamera.zoom
            }
          })
      }
    }
  }, [tripPoints])

  const mapHeight =
    mainContainerHeight -
    Metrics.base * 11 -
    (checkVal(tripId) ? /*Metrics.base * 13*/ 0 : 0)

  return (
    <FlexBox
      direction='row'
      style={{
        alignItems: 'flex-start',
        paddingTop: Metrics.base * 2,
        paddingLeft: Metrics.base * 2,
        paddingRight: Metrics.base * 2,
        paddingBottom: 0,
        height: '100%',
        width: '100%'
      }}
    >
      <TomTomMap
        map={map}
        setMap={setMap}
        initialMarkersData={initialMarkersData}
        markers={markers}
        setMarkers={setMarkers}
        center={
          tripHistoryMapCenter
            ? tripHistoryMapCenter
            : {
                lat: -33.9498945,
                lng: 19.5030406
              }
        }
        zoom={tripHistoryMapZoom ? tripHistoryMapZoom : 10}
        routeGeoJSON={routeGeoJSON}
        tripPoints={tripPoints}
        tripPointsLoading={loading}
        eventsWithPoints={eventsWithPoints}
        showEventLines
        tripId={tripId}
        width={mapWidth}
        height={mapHeight}
      />
      <SideBar
        selectedEventTypes={selectedEventTypes}
        setSelectedEventTypes={setSelectedEventTypes}
        height={mapHeight}
      />
    </FlexBox>
  )
}

export default MapView
