import { StyleSheet } from 'aphrodite'

export const Styles = ({
  Colors,
  Metrics,
  backgroundColor,
  selectedBackgroundColor,
  selectHighlightColor,
  selected
}) =>
  StyleSheet.create({
    selectable: {
      backgroundColor: selected
        ? selectedBackgroundColor
          ? selectedBackgroundColor
          : Colors.selectHighlight
        : backgroundColor,
      borderRadius: Metrics.radius,
      cursor: 'pointer',
      ':hover': {
        background: selected
          ? selectHighlightColor
            ? selectHighlightColor
            : Colors.selectHighlight
            ? Colors.selectHighlight
            : 'red'
          : selectHighlightColor
          ? selectHighlightColor
          : Colors.selectHighlightOpacity1
          ? Colors.selectHighlightOpacity1
          : 'red'
      }
    }
  })
