import React, { useState, useEffect } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { coachingEventFragment } from './useCoachingEventsByDriverId'
export const coachingLessonFragment = gql`
  fragment coachingLessonFragment on CoachingLesson {
    _id
    displayId
    createdAt
    name
    coachingStatus
    quiz {
      _id
      quizScore
    }
    driver {
      _id
    }
    lessonEvents {
      isActive
      comment
      coachingEvent {
        ...coachingEventFragment
      }
    }
    completedAt
  }
  ${coachingEventFragment}
`

export const GET_COACHING_LESSONS_BY_DRIVER_ID = gql`
  query coachingLessonsByDriverId($driverId: String!, $skip: Int, $limit: Int) {
    coachingLessonsByDriverId(driverId: $driverId, skip: $skip, limit: $limit) {
      ...coachingLessonFragment
    }
  }
  ${coachingLessonFragment}
`

export const coachingLessonDataTransform = ({ data }) => {
  let res = []
  if (data) {
    res = data.map((item) => {
      return {
        ...item,
        id: item._id ? item._id : null,
        score: item.quiz && item.quiz.quizScore ? item.quiz.quizScore : '-'
      }
    })
  }
  return res
}
const getCoachingLessonVariables = ({ driverId }) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (checkVal(driverId)) {
    variables.driverId = driverId
  }
  return variables
}

export const useCoachingLessonsByDriverId = (driverId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_COACHING_LESSONS_BY_DRIVER_ID,
    {
      variables: getCoachingLessonVariables({ driverId }),
      queryName: 'coachingLessonsByDriverId',
      errorMessage: 'Failed to fetch Coaching Lessons',
      dataTransform: (data) => coachingLessonDataTransform({ data }),
      skip: !driverId
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
