import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { NotFound } from '../../Components'
import { Style } from 'radium'
import { useColors } from '../../Themes'
import { Login } from '../Auth'
import LiveMonitoring from '../LiveMonitoring'
import Safety from '../Safety'
import TripHistory from '../TripHistory'
import EventInbox from '../EventInbox'
import FleetManagement from '../FleetManagement'
import NewInspection from '../FleetManagement/Inspections/NewInspection'
import ViewInspection from '../FleetManagement/Inspections/ViewInspection'
import Coaching from '../Coaching'
import ViewLesson from '../Coaching/ViewLesson'
import ViewCrash from '../FleetManagement/Crashes/ViewCrash'
import ViewIncident from '../FleetManagement/Incidents/ViewIncident'
import Settings from '../Settings'
import Welcome from '../Auth/Welcome'
import FAQ from '../FAQ'
import ResetPassword from '../Auth/ResetPassword'
import ForgotPassword from '../Auth/ForgotPassword'
import Dashboard from '../Dashboard'
import UpcomingFeatures from '../UpcomingFeatures'
import MobileAppPolicies from '../MobileAppPolicies'
import NewLiveView from '../LiveMonitoring/LiveView'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userId = localStorage.getItem('cybeleUserId')
  return (
    <Route
      {...rest}
      render={(props) =>
        !userId ? <Redirect to='/' /> : <Component {...props} />
      }
    />
  )
}

const RadiumStyle = () => {
  const Colors = useColors()
  return <Style rules={{ html: { background: Colors.background } }} />
}

const AppRoutes = () => {
  return (
    <React.Fragment>
      <RadiumStyle />
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={(props) => <Login {...props} />} />
          <Route
            path='/welcome'
            component={(props) => <Welcome {...props} />}
          />
          <Route
            path='/forgot-password'
            component={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path='/reset-password/:token'
            component={(props) => <ResetPassword {...props} />}
          />
          <PrivateRoute
            path='/dashboard'
            component={(props) => <Dashboard {...props} />}
          />
          <PrivateRoute
            path='/live-monitoring'
            component={(props) => <LiveMonitoring {...props} />}
          />
          <PrivateRoute
            path='/safety-score'
            component={(props) => <Safety {...props} />}
          />
          <PrivateRoute
            path='/event-inbox'
            component={(props) => <EventInbox {...props} />}
          />
          <PrivateRoute
            path='/trip-history'
            component={(props) => <TripHistory {...props} />}
          />
          <PrivateRoute
            path='/upcoming-features'
            component={(props) => <UpcomingFeatures {...props} />}
          />
          <PrivateRoute
            path='/fleet-management'
            component={(props) => <FleetManagement {...props} />}
          />
          <PrivateRoute
            path='/settings'
            component={(props) => <Settings {...props} />}
          />
          <PrivateRoute
            exact
            path='/new-inspection'
            component={(props) => <NewInspection {...props} />}
          />
          <PrivateRoute
            exact
            path='/view-inspection'
            component={(props) => <ViewInspection {...props} />}
          />
          <PrivateRoute
            exact
            path='/view-crash'
            component={(props) => <ViewCrash {...props} />}
          />
          <PrivateRoute
            exact
            path='/view-incident'
            component={(props) => <ViewIncident {...props} />}
          />
          <PrivateRoute
            path='/coaching'
            component={(props) => <Coaching {...props} />}
          />
          <PrivateRoute
            exact
            path='/view-lesson'
            component={(props) => <ViewLesson {...props} />}
          />
          <Route
            path='/mobile-app-policies'
            component={(props) => <MobileAppPolicies {...props} />}
          />
          <Route path='/knowledge' component={(props) => <FAQ {...props} />} />
          <Route component={(props) => <NotFound {...props} />} />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  )
}

// These routes are used by the Sidebar Navigation component, leave empty if not needed
export const SidebarPrimaryLinks = [
  {
    path: '/live-monitoring',
    title: 'Live Monitoring',
    icon: 'remove_red_eye'
  },
  {
    path: '/safety-score',
    title: 'Safety Score',
    icon: 'shield'
  },
  {
    path: '/event-inbox',
    title: 'Event Inbox',
    icon: 'inbox'
  },
  {
    path: '/coaching',
    title: 'Coaching',
    icon: 'school'
  },
  {
    path: '/trip-history',
    title: 'Trip History',
    icon: 'history'
  },
  {
    path: '/upcoming-features',
    title: 'Upcoming Features',
    icon: 'stars',
    color: '#38C8A5'
  },
  {
    path: '/fleet-management',
    title: 'Fleet Management',
    icon: 'people'
  },
  {
    path: '/settings',
    title: 'Settings',
    icon: 'settings'
  },
  {
    onClick: () =>
      window.open(
        'https://cybelefleet.atlassian.net/servicedesk/customer/portals'
      ),
    title: 'Knowledge base',
    icon: 'question_answer'
  }
]
export const SidebarSecondaryLinks = [
  /*
  { path: '/settings', title: 'Settings', icon: 'settings' }
  */
]

export default AppRoutes
