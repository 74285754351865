import { StyleSheet } from 'aphrodite'

export const Styles = ({ Colors, Metrics, selected }) =>
  StyleSheet.create({
    button: {
      minWidth: Metrics.base * 8,
      height: Metrics.base * 4.5,
      backgroundColor: selected
        ? Colors.selectHighlight
        : Colors.subsectionBackground,
      borderRadius: Metrics.radius,
      marginLeft: Metrics.base,
      marginRight: Metrics.base,
      paddingLeft: Metrics.base * 2,
      paddingRight: Metrics.base * 2,
      ':hover': {
        background: selected
          ? Colors.selectHighlight
          : Colors.selectHighlightOpacity1
      }
    }
  })
