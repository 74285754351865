import React, { useEffect, useState } from 'react'
import { FlexBox, Button, Form } from '../../../'
import { Metrics, useColors } from '../../../../Themes'
import {
  assetFilters,
  filters,
  selectedAssetId,
  selectedDeviceId
} from '../../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { SelectScoreBetween } from '../Event/EventFilters'
import { safetyCategoriesOptions } from '../Driver/DriverFilters'
import SearchBar from '../SearchBar'
import { useUser } from '../../../../Graphql/Queries/useUser'
import { useAssetGroups } from '../../../../Graphql/Queries/Asset/useAssetGroups'

const assetTypeOptions = [
  { value: 'TRUCK', label: 'Truck' },
  { value: 'CAR', label: 'Car' },
  { value: 'TRAILER', label: 'Trailer' },
  { value: 'FLATBED', label: 'Flatbed' },
  { value: 'STEP_DECK', label: 'Step Deck' },
  { value: 'REFRIGERATED', label: 'Refrigerated' },
  { value: 'DRY', label: 'Dry' },
  { value: 'VEHICLE_CARRIER', label: 'Vehicle Carrier' },
  { value: 'DUMP', label: 'Dump' },
  { value: 'TANKER', label: 'Tanker' }
]

export const handleClearFilters = () => {
  assetFilters({
    ...assetFilters(),
    searchString: null,
    minSafetyScore: null,
    maxSafetyScore: null,
    safetyCategories: null,
    assetTypes: null
  })
}

const AssetFilters = ({
  direction,
  innerFormStyles,
  minWidth,
  maxInputWidth,
  singleOrgSelect,
  searchInputRef,
  disableOrgFilter
}) => {
  const { orgId, orgIds, groupId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  const { data: assetGroups, loading } = useAssetGroups()
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    if (assetGroups && assetGroups.length > 0) {
      setGroupOptions([...assetGroups, { name: 'Ungrouped', _id: 'NO_GROUP' }])
    }
  }, [assetGroups])

  const {
    searchString,
    minSafetyScore,
    maxSafetyScore,
    safetyCategories,
    assetTypes
  } = useReactiveVar(assetFilters)

  const innerFormStyle = {
    minHeight: 0,
    flexGrow: 1,
    paddingLeft: Metrics.base * 3,
    paddingRight: Metrics.base * 3,
    ...innerFormStyles
  }

  return (
    <FlexBox
      style={{
        width: '100%',
        minWidth: minWidth ? minWidth : 'auto'
      }}
    >
      <Form
        hideSubmit
        formbox={false}
        style={{
          paddingTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          backgroundColor: 'none',
          boxShadow: 'none',
          width: '100%'
        }}
        maxInputWidth={maxInputWidth}
        innerFormStyles={{ paddingBottom: 0 }}
        hideFormBox
        darkmode={true}
        direction={direction ? direction : 'column'}
        data={[
          {
            name: 'searchAssets',
            renderInput: (props) => {
              return (
                <SearchBar
                  title='Assets'
                  inputString={searchString}
                  setInputString={(value) => {
                    assetFilters({ ...assetFilters(), searchString: value })
                  }}
                  searchInputRef={searchInputRef}
                />
              )
            }
          },
          {
            name: 'orgId',
            value: orgId ? orgId : null,
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgId: value })
            },
            label: 'Organisation',
            type: 'select',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            disabled: disableOrgFilter,
            display: () => singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'orgIds',
            value: orgIds ? orgIds : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgIds: [...value] })
              selectedAssetId(null)
              selectedDeviceId(null)
            },
            label: 'Organisation',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'groupIds',
            value: groupId ? groupId : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), groupId: value })
              selectedAssetId(null)
              selectedDeviceId(null)
            },
            label: 'Asset Group',
            type: 'select',
            style: innerFormStyle,
            options: groupOptions
              ? groupOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect
          },
          {
            name: 'safetyCategories',
            value: safetyCategories ? safetyCategories : [],
            controlled: true,
            handleChange: (value) => {
              assetFilters({ ...assetFilters(), safetyCategories: value })
              selectedAssetId(null)
              selectedDeviceId(null)
            },
            label: 'Safety Categories',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: safetyCategoriesOptions
          },
          {
            name: 'assetTypes',
            value: assetTypes ? assetTypes : [],
            controlled: true,
            style: innerFormStyle,
            handleChange: (value) => {
              assetFilters({ ...assetFilters(), assetTypes: value })
              selectedAssetId(null)
              selectedDeviceId(null)
            },
            label: 'Asset types',
            type: 'multipleSelect',
            options: assetTypeOptions
          }
          /*{
            name: 'scoreRange',
            label: 'Score Range',
            style: innerFormStyle,
            value: {
              minScore: minSafetyScore,
              maxScore: maxSafetyScore
            },
            controlled: true,
            handleChange: (value) => {
              driverFilters({
                ...driverFilters(),
                minSafetyScore: value.minScore,
                maxSafetyScore: value.maxScore
              })
            },
            renderInput: (props) => {
              return (
                <SelectScoreBetween
                  value={props.value}
                  handleChange={props.handleChange}
                  Colors={Colors}
                />
              )
            }
          }*/
        ]}
      />
    </FlexBox>
  )
}
export default AssetFilters
