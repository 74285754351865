import React, { useState } from 'react'
import { useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Status from '../../../Status'
import Card from '../Cards/Card'
import { useDriver } from '../../../../Graphql/Queries/Driver/useDriver'
import EmptyCard from '../Cards/EmptyCard'
import { checkVal } from '../../../../Helpers/Functions'
import { ErrorMessage } from './LiveDriverCard'
import DriverCardTTList from './DriverCardTTList'
import { useDriverSafetyCategory } from '../../../../Graphql/Queries/Driver/useDriverSafetyCategory'
import { selectedDriverId } from '../../../../Graphql/GraphQLClient'
import LinkAssetToDriver from '../../../../Containers/FleetManagement/LinkAssetToDriver'

const DriverCard = ({
  driverId,
  selected,
  borderBottom,
  backgroundColor,
  navigateTo,
  onClick
}) => {
  const Colors = useColors()
  const { category, loading: categoryLoading } =
    useDriverSafetyCategory(driverId)
  const { data, loading: driverLoading } = useDriver(driverId)
  const [showLinkAssetModal, setShowLinkAssetModal] = useState(false)
  return driverId ? (
    <>
      <Card
        id={driverId}
        data={data}
        selected={selected}
        onClick={onClick}
        typeName='driver'
        setSelectedId={selectedDriverId}
        navigateTo={navigateTo}
        TooltipContent={({ setShowTooltip }) => (
          <DriverCardTTList
            driverId={driverId}
            data={data}
            navigateTo={navigateTo}
            setShowLinkAssetModal={setShowLinkAssetModal}
          />
        )}
        borderBottom={borderBottom}
        backgroundColor={backgroundColor}
        loading={driverLoading}
        titleContent={<Title color={Colors.primary}>{data && data.name}</Title>}
        bodyContent={
          <Text marginBottom={1}>
            {data && data.displayId && `Driver ${data.displayId}`}
          </Text>
        }
        topRightContent={
          <Status
            status={category ? category : 'no safety score'}
            getStatusColors={({ status }) =>
              Colors[status] ? Colors[status] : Colors.textSecondary
            }
          />
        }
        topRightLoading={categoryLoading}
        bottomLeftContent={
          <Text font='caption' color={Colors.secondaryOpacity3}>
            Vehicle: {data.assetName}
          </Text>
        }
        bottomRightContent={
          data &&
          !driverLoading &&
          !checkVal(data.assetId) && (
            <ErrorMessage text={'No Linked Asset'} Colors={Colors} />
          )
        }
      />
      {showLinkAssetModal && (
        <LinkAssetToDriver
          setShowLinkAssetModal={setShowLinkAssetModal}
          driverId={driverId}
          showLinkAssetModal={showLinkAssetModal}
        />
      )}
    </>
  ) : (
    <EmptyCard borderBottom={borderBottom} text='No Driver ID' />
  )
}

export default DriverCard
