import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'
import { videoFetchSettingsFragment } from '../Queries/Device/useVideoFetchSensitivity'

export const CHANGE_SENSITIVITY = gql`
  mutation changeVideoFetchSensitivity(
    $internalDeviceId: String!
    $isActive: Boolean
    $thresholds: VideoThresholdsInput
  ) {
    changeVideoFetchSensitivity(
      internalDeviceId: $internalDeviceId
      isActive: $isActive
      thresholds: $thresholds
    ) {
      ...videoFetchSettingsFragment
    }
  }
  ${videoFetchSettingsFragment}
`
export const useChangeVideoFetchSensitivity = () => {
  const [changeSensitivity, { loading: changeSensitivityLoading }] =
    useMutation(CHANGE_SENSITIVITY, {
      mutationName: 'changeVideoFetchSensitivity',
      successMessage: 'Video Recording Thresholds updated',
      errorMessage: 'Failed to update video recording thresholds'
    })
  return [changeSensitivity, { changeSensitivityLoading }]
}
