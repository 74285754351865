import React from 'react'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'
import Text from '../Text'
import { Display, Subheading } from '../Typography'
import { ProcessingSpinner } from '../'

const NoneSelected = ({
  displayText = 'None Selected',
  helperText = '',
  width,
  height,
  loading
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: width ? width : '100%',
        height: height ? height : '100%'
      }}
    >
      {loading ? (
        <ProcessingSpinner size={50} />
      ) : (
        <>
          <Subheading
            color={Colors.secondaryOpacity2}
            style={{ textAlign: 'center', marginBottom: Metrics.base * 2 }}
          >
            {displayText}
          </Subheading>
          <Text
            color={Colors.secondaryOpacity2}
            style={{ textAlign: 'center' }}
          >
            {helperText}
          </Text>
        </>
      )}
    </FlexBox>
  )
}
export default NoneSelected
