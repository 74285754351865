import React from 'react'
import { Metrics, useColors, Utils } from '../../Themes'
import { FlexBox, Text } from '..'

const Subsection = ({ noBoxShadow, style, children, fillSpace, ...props }) => {
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace={fillSpace}
      direction='column'
      {...props}
      style={{
        backgroundColor: Colors.subsectionBackground,
        boxShadow: noBoxShadow
          ? ''
          : Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight,
        borderRadius: Metrics.radius,
        // zIndex: 1,
        ...style
      }}
    >
      {props.title ? (
        <div
          style={{
            width: '100%',
            paddingTop: Metrics.base * 3,
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2,
            borderBottom: `1px solid ${Colors.background}`,
            marginBottom: 0
          }}
        >
          <Text
            font={'subheading'}
            bold
            color={Colors.secondary}
            style={{
              paddingBottom: Metrics.base * 2
            }}
          >
            {props.title}
          </Text>
        </div>
      ) : null}
      {children}
    </FlexBox>
  )
}

export default Subsection
