import React, { useState } from 'react'
import axios from 'axios'
import {
  PRE_SIGN_DOCUMENT,
  CREATE_DOCUMENT,
  CREATE_DOCUMENTS,
  UPDATE_DOCUMENT
} from '../../../Graphql/Mutations/uploadDocument'
import { GET_DOCUMENT_BY_DRIVER_ID } from '../../../Graphql/Queries/useDocumentsByDriverId'
import { GET_DOCUMENT_BY_ASSET_ID } from '../../../Graphql/Queries/useDocumentsByAssetId'
import { GET_DOCUMENT_BY_INSPECTION_ID } from '../../../Graphql/Queries/Inspection/useDocumentsByInspectionId'
import { UPDATE_NOTIFICATION } from '../../../Graphql/Mutations'

import { useMutation } from '@apollo/client'
import Document from './Document'
import {
  updateCacheList,
  updateCacheListWithVariables
} from '../../../Components/CustomHooks/useMutation'

const cleanDetails = (data) => {
  return data.map((a) => {
    return {
      ...a,
      date: a.date ? new Date(parseInt(a.date)).toLocaleDateString() : '',
      modifiedAt: a.modifiedAt
        ? new Date(parseInt(a.modifiedAt)).toLocaleDateString()
        : '',
      value: a.type !== 'WEIGHBRIDGE_SLIP' && a.value ? a.value : '-',
      weight: a.type === 'WEIGHBRIDGE_SLIP' && a.value ? a.value : '-',
      grade: a.grade ? a.grade : '-'
    }
  })
}

const getExtraObjectVariables = (vars) => {
  let extraInformation = {}
  if (vars.documentType === 'DRIVER_LICENCE_FRONT') {
    extraInformation = {
      countryOfIssue: vars.countryOfIssue ? vars.countryOfIssue : null,
      controlNumber: vars.controlNumber ? vars.controlNumber : null,
      dob: vars.dob ? vars.dob : null,
      dateIssued: vars.dateIssued ? vars.dateIssued : null,
      professionalDrivingPermitExpiryDate:
        vars.professionalDrivingPermitExpiryDate
          ? vars.professionalDrivingPermitExpiryDate
          : null,
      licenceCode: vars.licenceCode ? vars.licenceCode : null,
      doe: vars.doe ? vars.doe : null,
      professionalDrivingPermit: vars.professionalDrivingPermit
        ? vars.professionalDrivingPermit
        : null,
      driverRestrictions: vars.driverRestrictions
        ? Number(vars.driverRestrictions)
        : null,
      vehicleRestrictions: vars.vehicleRestrictions
        ? Number(vars.vehicleRestrictions)
        : null
    }
  } else if (vars.documentType === 'VEHICLE_LICENCE') {
    extraInformation = {
      controlNumber: vars.controlNumber ? vars.controlNumber : null,
      registeringAuthority: vars.registeringAuthority
        ? vars.registeringAuthority
        : null,
      vin: vars.vin ? vars.vin : null,
      registrationNumber: vars.registrationNumber
        ? vars.registrationNumber
        : null,
      engineNumber: vars.engineNumber ? vars.engineNumber : null,
      gvm: vars.gvm ? Number(vars.gvm) : null,
      tare: vars.tare ? Number(vars.tare) : null,
      vehicleMake: vars.vehicleMake ? vars.vehicleMake : null,
      testingStation: vars.testingStation ? vars.testingStation : null,
      examinerIdentity: vars.examinerIdentity ? vars.examinerIdentity : null,
      dateOfTest: vars.dateOfTest ? vars.dateOfTest : null,
      maxPersons: vars.maxPersons ? Number(vars.maxPersons) : null,
      doe: vars.doe ? vars.doe : null,
      nvc: vars.nvc ? vars.nvc : null,
      vehicleCategory: vars.vehicleCategory ? vars.vehicleCategory : null,
      professionalDrivingPermit: vars.professionalDrivingPermit
        ? vars.professionalDrivingPermit
        : null,
      licenseNo: vars.licenseNo ? vars.licenseNo : null,
      vehicleModel: vars.vehicleModel ? vars.vehicleModel : null,
      vehicleYear: vars.vehicleYear ? vars.vehicleYear : null
    }
  } else if (vars.documentType === 'VEHICLE_SERVICE') {
    extraInformation = {
      vehicleServicedDate: vars.vehicleServicedDate
        ? vars.vehicleServicedDate
        : null
    }
  } else if (vars.documentType === 'DRIVER_ID') {
    extraInformation = {
      surname: vars.surname ? vars.surname : null,
      firstName: vars.firstName ? vars.firstName : null,
      gender: vars.gender ? vars.gender : null,
      nationality: vars.nationality ? vars.nationality : null,
      idNumber: vars.idNumber ? vars.idNumber : null,
      dob: vars.dob ? vars.dob : null,
      countryOfBirth: vars.countryOfBirth ? vars.countryOfBirth : null,
      citizenshipStatus: vars.citizenshipStatus ? vars.citizenshipStatus : null
    }
  } else if (vars.documentType === 'DRIVER_PASSPORT') {
    extraInformation = {
      surname: vars.surname ? vars.surname : null,
      firstName: vars.firstName ? vars.firstName : null,
      gender: vars.gender ? vars.gender : null,
      nationality: vars.nationality ? vars.nationality : null,
      passportNumber: vars.passportNumber ? vars.passportNumber : null,
      dob: vars.dob ? vars.dob : null,
      countryOfBirth: vars.countryOfBirth ? vars.countryOfBirth : null,
      visaExpiryDate: vars.visaExpiryDate ? vars.visaExpiryDate : null,
      dateOfIssue: vars.dateOfIssue ? vars.dateOfIssue : null,
      dateOfExpiry: vars.dateOfExpiry ? vars.dateOfExpiry : null,
      issuingCountry: vars.issuingCountry ? vars.issuingCountry : null,
      issuingAuthority: vars.issuingAuthority ? vars.issuingAuthority : null
    }
  }
  return extraInformation
}
// adds form object to GraphQL cache + list data
const addObject = (store, object, listQuery, setListData) => {
  if (listQuery) {
    const data = store.readQuery({
      query: listQuery.query,
      variables: listQuery.variables
    })
    data[listQuery.name].push(object)
    store.writeQuery({
      query: listQuery.query,
      variables: listQuery.variables,
      data
    })
    setListData(cleanDetails(data[listQuery.name]))
  }
}

// updates form object to GraphQL cache + list data
const updateObject = (store, object, listQuery, setListData) => {
  if (listQuery) {
    const data = store.readQuery({
      query: listQuery.query,
      variables: listQuery.variables
    })
    // data[listQuery.name].push(object)
    store.writeQuery({
      query: listQuery.query,
      variables: listQuery.variables,
      data
    })
    setListData(cleanDetails(data[listQuery.name]))
  }
}

const removeFile = (index, { acceptedFiles, setAcceptedFiles }) => {
  let files = acceptedFiles.filter((file) => file.id !== index)
  files.map((file, i) => (file.id = i))
  setAcceptedFiles(files)
}

const _handleDrop = ({
  files,
  rejectedFiles,
  acceptedFiles,
  setAcceptedFiles,
  documentType
}) => {
  if (
    documentType === 'INSPECTION_MAIN' ||
    documentType === 'INSPECTION_EXTRAS'
  ) {
    // let file = { id: acceptedFiles.length, file: files[0] }
    let spreadFiles = [...acceptedFiles, ...files] //[file]
    setAcceptedFiles(spreadFiles)
  } else {
    // let file = { ...files[0] } //{ id: acceptedFiles.length, file: files[0] }
    let spreadFiles = [files[0]] //[...acceptedFiles, file]
    setAcceptedFiles(spreadFiles)
  }
}

const uploadToS3 = async (file, signedRequest) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  }
  return await axios.put(signedRequest, file, options)
}

const _handleUpload = async (
  vars,
  preSignDocument,
  createDocument,
  createDocuments,
  updateNotification,
  {
    acceptedFiles,
    setAcceptedFiles,
    uploading,
    setUploading,
    setShowAddDocumentModal,
    refetchDocuments
  }
) => {
  if (acceptedFiles && acceptedFiles.length > 0) {
    if (acceptedFiles.length === 1) {
      let fileAccepted = acceptedFiles[0]
      if (fileAccepted) {
        let file = fileAccepted
        let variables = {
          filetype: file.type,
          driverId: vars.driverId ? vars.driverId : null,
          assetId: vars.assetId ? vars.assetId : null,
          inspectionId: vars.inspectionId ? vars.inspectionId : null,
          incidentId: vars.incidentId ? vars.incidentId : null,
          crashId: vars.crashId ? vars.crashId : null,
          documentType: vars.documentType ? vars.documentType : null
        }
        setUploading(true)
        preSignDocument({ variables })
          .then(async ({ data }) => {
            let res = file
              ? await uploadToS3(file, data.preSignDocument.url)
              : null
            if (res && res.status === 200) {
              let extraInformation = getExtraObjectVariables(vars)
              let varName = ''
              switch (vars.documentType) {
                case 'DRIVER_LICENCE_FRONT':
                  varName = 'driverLicence'
                  break
                case 'VEHICLE_LICENCE':
                  varName = 'vehicleLicence'
                  break
                case 'VEHICLE_SERVICE':
                  varName = 'vehicleService'
                  break
                case 'DRIVER_ID':
                  varName = 'idInfo'
                  break
                case 'DRIVER_PASSPORT':
                  varName = 'passportInfo'
                  break
                default:
                  break
              }
              createDocument({
                variables: {
                  driverId: vars.driverId ? vars.driverId : null,
                  assetId: vars.assetId ? vars.assetId : null,
                  inspectionId: vars.inspectionId ? vars.inspectionId : null,
                  incidentId: vars.incidentId ? vars.incidentId : null,
                  crashId: vars.crashId ? vars.crashId : null,
                  filename: data.preSignDocument.filename,
                  url: data.preSignDocument.documentUrl,
                  documentType: vars.documentType,
                  [varName]: { ...extraInformation }
                  // date: vars.date.toString()
                }
              })
                .then(({ data }) => {
                  updateNotification({
                    variables: {
                      show: true,
                      level: 'success',
                      text: `Document successfully created`
                    }
                  })
                  setAcceptedFiles([])
                  setUploading(false)
                  setShowAddDocumentModal(false)
                  // refetchDocuments()
                })
                .catch((e) => {
                  updateNotification({
                    variables: {
                      show: true,
                      level: 'danger',
                      text: 'Document creation failed'
                    }
                  })
                  console.log('createDocumentsError ', e)
                  setUploading(false)
                })
            } else {
              console.log('Upload Error', res)
              setUploading(false)
            }
          })
          .catch((e) => {
            console.log('preSignError', e)
            setUploading(false)
          })
      } else {
        setUploading(true)
        createDocument({
          variables: {
            filename: '',
            documentUrl: '',
            documentType: vars.documentType,
            driverId: vars.driverId ? vars.driverId : null,
            assetId: vars.assetId ? vars.assetId : null,
            inspectionId: vars.inspectionId ? vars.inspectionId : null,
            incidentId: vars.incidentId ? vars.incidentId : null,
            crashId: vars.crashId ? vars.crashId : null
          }
        })
          .then(({ data }) => {
            updateNotification({
              variables: {
                show: true,
                level: 'success',
                text: `Document successfully created`
              }
            })
            setAcceptedFiles([])
            setUploading(false)
            setShowAddDocumentModal(false)
          })
          .catch((e) => {
            updateNotification({
              variables: {
                show: true,
                level: 'danger',
                text: 'Document creation failed'
              }
            })
            console.log('createDocumentsError ', e)
            setUploading(false)
          })
      }
    } else {
      let docsArray = await acceptedFiles.map(async (fileAccepted) => {
        let createVar = {}
        if (fileAccepted && fileAccepted.file) {
          let file = fileAccepted ? fileAccepted.file : null
          let variables = {
            // filename: file.name,
            filetype: file.type,
            driverId: vars.driverId ? vars.driverId : null,
            assetId: vars.assetId ? vars.assetId : null,
            inspectionId: vars.inspectionId ? vars.inspectionId : null,
            incidentId: vars.incidentId ? vars.incidentId : null,
            crashId: vars.crashId ? vars.crashId : null,
            documentType: vars.documentType ? vars.documentType : null
          }
          setUploading(true)
          let preSignVars = await preSignDocument({ variables })
          if (preSignVars) {
            let res = file
              ? await uploadToS3(file, preSignVars.data.preSignDocument.url)
              : null
            if (res && res.status === 200) {
              createVar = {
                driverId: vars.driverId ? vars.driverId : null,
                assetId: vars.assetId ? vars.assetId : null,
                inspectionId: vars.inspectionId ? vars.inspectionId : null,
                incidentId: vars.incidentId ? vars.incidentId : null,
                crashId: vars.crashId ? vars.crashId : null,
                filename: preSignVars.data.preSignDocument.filename,
                url: preSignVars.data.preSignDocument.documentUrl,
                documentType: vars.documentType
              }
            } else {
              console.log('Upload Error', res)
              setUploading(false)
              return
            }
          }
        }
        return createVar
      })
      const responses = await Promise.all(docsArray)
      if (responses && responses.length > 0) {
        await createDocuments({ variables: { documents: responses } }).then(
          ({ data }) => {
            updateNotification({
              variables: {
                show: true,
                level: 'success',
                text: `Document successfully created`
              }
            })
            setAcceptedFiles([])
            setUploading(false)
            setShowAddDocumentModal(false)
          }
        )
      }
    }
  }
}

const _handleDownload = (data) => {
  window.open(data.documentUrl)
}

const _handleUpdateDocument = (
  updateDocument,
  setAcceptedFiles,
  setUploading,
  setShowAddDocumentModal,
  { id, type, value, docRefId, date }
) => {
  const variables = Number.isInteger(date)
    ? { id, type, value, docRefId, date: date.toString() }
    : { id, type, value, docRefId }
  setUploading(true)
  updateDocument({
    variables
  })
    .then(({ data }) => {
      setAcceptedFiles([])
      setUploading(false)
      setShowAddDocumentModal(false)
    })
    .catch((e) => {
      console.log('updateContractDocumentsError', e)
      setUploading(false)
    })
}

const UploadDocuments = ({
  fileData,
  renderLoading,
  setShowAddDocumentModal,
  driverId,
  assetId,
  inspectionId,
  incidentId,
  crashId,
  typeOptions
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([])
  // const [showModal, setShowAddDocumentModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState({})
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION)

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    update(cache, { data: mutationData }) {
      updateCacheListWithVariables(
        driverId
          ? GET_DOCUMENT_BY_DRIVER_ID
          : assetId
          ? GET_DOCUMENT_BY_ASSET_ID
          : GET_DOCUMENT_BY_INSPECTION_ID,
        {
          cache,
          data: mutationData.createDocument,
          variables: driverId
            ? { driverId, skip: 0, limit: 1000 }
            : assetId
            ? { assetId, skip: 0, limit: 1000 }
            : { inspectionId, skip: 0, limit: 1000 },
          listQueryName: driverId
            ? 'documentsByDriverId'
            : assetId
            ? 'documentsByAssetId'
            : 'documentsByInspectionId'
        }
      )
    }
  })

  const [createDocuments] = useMutation(CREATE_DOCUMENTS, {
    update(cache, { data: mutationData }) {
      updateCacheListWithVariables(
        driverId
          ? GET_DOCUMENT_BY_DRIVER_ID
          : assetId
          ? GET_DOCUMENT_BY_ASSET_ID
          : GET_DOCUMENT_BY_INSPECTION_ID,
        {
          cache,
          data: mutationData.createDocuments,
          variables: driverId
            ? { driverId, skip: 0, limit: 1000 }
            : assetId
            ? { assetId, skip: 0, limit: 1000 }
            : { inspectionId, skip: 0, limit: 1000 },
          listQueryName: driverId
            ? 'documentsByDriverId'
            : assetId
            ? 'documentsByAssetId'
            : 'documentsByInspectionId'
        }
      )
    }
  })

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    update(cache, { data: mutationData }) {
      updateCacheList({
        cache,
        data: mutationData.updateDocument,
        listQueryName: driverId
          ? 'documentsByDriverId'
          : assetId
          ? 'documentsByAssetId'
          : 'documentsByInspectionId'
      })
    }
  })
  const [preSignDocument] = useMutation(PRE_SIGN_DOCUMENT)

  return (
    <Document
      document={fileData}
      driverId={driverId}
      assetId={assetId}
      inspectionId={inspectionId}
      incidentId={incidentId}
      crashId={crashId}
      typeOptions={typeOptions}
      disabled={false}
      handleNewDocument={(vars) =>
        _handleUpload(
          vars,
          preSignDocument,
          createDocument,
          createDocuments,
          updateNotification,
          {
            acceptedFiles,
            setAcceptedFiles,
            // contractId: props.contractId,
            // refId: props.refId,
            uploading,
            setUploading,
            setShowAddDocumentModal
            // refetchDocuments
          }
        )
      }
      handleUpdateDocument={(data) =>
        _handleUpdateDocument(
          updateDocument,
          setAcceptedFiles,
          setUploading,
          setShowAddDocumentModal,
          data
        )
      }
      handleDownloadDocument={() => _handleDownload(fileData)}
      acceptedFiles={acceptedFiles}
      uploading={uploading}
      removeFile={(index) =>
        removeFile(index, {
          acceptedFiles,
          setAcceptedFiles
        })
      }
      handleClose={() => setShowAddDocumentModal(false)}
      handleDrop={(files, rejectedFiles, documentType) =>
        _handleDrop({
          files,
          rejectedFiles,
          acceptedFiles,
          setAcceptedFiles,
          documentType
        })
      }
    />
  )
}

export default UploadDocuments
