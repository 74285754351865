import { Metrics, Colors } from '../../Themes'

export const inlineStyles = {
  style: {
    padding: Metrics.spacing,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: Metrics.base * 10,
    border: `1px dashed ${Colors.dividerColor}`,
    borderRadius: Metrics.radius,
    cursor: 'pointer'
  },
  innerContainer: {
    padding: 20,
    outline: 'none',
    width: '100%'
  },
  acceptStyle: {
    borderColor: Colors.success
  },
  rejectStyle: {
    borderColor: Colors.danger,
    cursor: 'not-allowed'
  },
  disabledStyle: {
    cursor: 'not-allowed'
  }
}
