import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import {
  MaterialInput,
  Button,
  Dropzone,
  Text,
  IconButton,
  Icon,
  FlexBox,
  Subsection
} from '../'
import { inlineStyles } from './Styles'
import { FormBox } from '../'
import { Metrics, useColors } from '../../Themes'
import { Grid } from '@material-ui/core'
import { Button as MaterialButton } from '@material-ui/core'

const validationSchema = (schema) => {
  return Yup.object().shape(schema)
}

const validateForm = (values, data) => {
  const keys = Object.keys(values)
  for (var i = 0; i < keys.length; i++) {
    if (
      data[i] &&
      data[i].required &&
      (values[keys[i]] === '' ||
        values[keys[i]] === undefined ||
        values[keys[i]] === null)
    )
      return false
  }
  return true
}

const getAttributes = (data) => {
  const initialValues = {}
  const schema = {}
  for (var i in data) {
    if (data[i].name) {
      if (data[i].type === 'checkbox') {
        initialValues[data[i].name] = data[i].initialValue
          ? data[i].initialValue
          : false
      } else {
        initialValues[data[i].name] = data[i].initialValue
          ? data[i].initialValue
          : ''
      }
      if (data[i].required && data[i].validation) {
        if (data[i].type === 'checkbox') {
          schema[data[i].name] = Yup.boolean()
            .required('Required')
            .test(data[i].name, data[i].errorMessage, data[i]['validation'])
        } else {
          schema[data[i].name] = Yup.string()
            .required('Required')
            .test(data[i].name, data[i].errorMessage, data[i]['validation'])
        }
      } else if (!data[i].required && data[i].validation) {
        schema[data[i].name] = Yup.string().test(
          data[i].name,
          data[i].errorMessage,
          data[i]['validation']
        )
      } else if (data[i].required && !data[i].validation) {
        schema[data[i].name] = Yup.string().required('Required')
      }
    }
  }
  return { initialValues, schema }
}

const isChanged = (value1, value2) => {
  if (value1 && value2 && value1.value && value2.value)
    return !_.isEqual(value1.value, value2.value)
  return !_.isEqual(value1, value2)
}

const getChanged = (initialValues, values) => {
  for (var i in initialValues) {
    if (isChanged(initialValues[i], values[i])) return true
  }
  return false
}

const resetFields = (names, setFieldValue) => {
  for (var i in names) {
    setFieldValue(names[i], '')
  }
}

const Form = ({
  data,
  title,
  editable,
  onSubmit,
  isSubmitting,
  setSubmitting,
  setSubmitRef,
  showSubmitButton,
  submitButtonTitle,
  showChanges,
  outerContainerStyle,
  innerFormStyles,
  hideSubmit,
  showReset,
  resetRef,
  cancelButton,
  handleCancel = () => {},
  hideFormBox,
  children,
  debug,
  disabled,
  direction,
  formbox = true,
  style,
  setEditable = () => {},
  showEditButton = true,
  firstItemBorderTop,
  _id,
  filename,
  acceptedFiles,
  handleDrop,
  removeFile,
  handleDownloadDocument,
  documentType,
  maxInputWidth
}) => {
  const attributes = getAttributes(data)
  const Colors = useColors()
  return (
    <Formik
      initialValues={attributes.initialValues}
      validationSchema={validationSchema(attributes.schema)}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isValid
      }) => {
        const changed = getChanged(attributes.initialValues, values)
        return (
          <FormBox
            title={title}
            id={
              title
                ? `form-box-${title.replace(/\s/g, '-').toLowerCase()}`
                : null
            }
            editable={editable}
            style={{
              height: '100%',
              paddingTop: editable ? Metrics.base * 3 : 0,
              border:
                changed && showChanges && !hideFormBox
                  ? `1px dotted ${Colors.spot2Opacity3}`
                  : 'none',
              ...style
            }}
          >
            <FormikForm onSubmit={handleSubmit}>
              <Grid
                container
                direction={direction ? direction : 'column'}
                justify='flex-start'
                alignItems='flex-start'
                spacing={3}
                style={{
                  ...inlineStyles.innerFormContainer,
                  ...innerFormStyles,
                  padding: Metrics.base * 1.5
                }}
              >
                {data.map((inputData, index) => {
                  const {
                    type,
                    name,
                    value,
                    link,
                    label,
                    hideLabel,
                    options,
                    style,
                    handleChange,
                    onBlur,
                    query,
                    queryName,
                    variables,
                    getOptionLabel,
                    resetFieldsOnChange,
                    required,
                    dateFormat,
                    formatNumber,
                    startDate,
                    endDate,
                    freeSolo,
                    fetching,
                    prefix,
                    suffix,
                    display,
                    disabled,
                    header,
                    editable: inputEditable,
                    showClearButton,
                    //validation,
                    renderInput
                    //errorMessage,
                  } = inputData
                  const selectValues = options
                    ? options.map((option) => option.value)
                    : null
                  const selectDisplayValues = options
                    ? options.map((option) => option.label)
                    : null
                  const displayInput = display
                    ? typeof display === 'function'
                      ? display(values)
                      : display
                    : true
                  const indicateChange =
                    isChanged(values[name], attributes.initialValues[name]) &&
                    showChanges
                  return (
                    displayInput &&
                    name && (
                      <div
                        style={{
                          ...inlineStyles.inputContainer,
                          maxWidth: maxInputWidth ? maxInputWidth : 'auto',
                          ...style
                        }}
                        key={index}
                      >
                        {renderInput ? (
                          renderInput({
                            values,
                            value,
                            handleChange,
                            setFieldValue,
                            touched,
                            setFieldTouched,
                            errors
                          })
                        ) : (
                          <MaterialInput
                            key={index}
                            name={name}
                            type={type}
                            link={link}
                            query={query}
                            fetching={fetching}
                            indicateChange={indicateChange}
                            variables={variables}
                            queryName={queryName}
                            getOptionLabel={getOptionLabel}
                            label={label + (required ? '*' : '')}
                            hideLabel={hideLabel}
                            value={value ? value : values[name]}
                            selectValues={selectValues}
                            selectDisplayValues={selectDisplayValues}
                            radioValues={selectValues}
                            radioDisplayValues={selectDisplayValues}
                            dateFormat={dateFormat}
                            formatNumber={formatNumber}
                            startDate={startDate}
                            endDate={endDate}
                            freeSolo={freeSolo}
                            prefix={prefix}
                            suffix={suffix}
                            disabled={!editable || disabled}
                            editable={editable && inputEditable}
                            handleChange={(value) => {
                              handleChange && handleChange(value, name)
                              setFieldValue([name], value)
                              resetFieldsOnChange &&
                                resetFields(resetFieldsOnChange, setFieldValue)
                            }}
                            onBlur={(e) => [
                              setFieldTouched([name]),
                              type !== 'dateBetween' ? handleBlur(e) : null,
                              onBlur ? onBlur(values[name]) : null
                            ]}
                            error={
                              touched[name] && errors[name]
                              // ||
                              // (validation
                              //   ? touched[name] &&
                              //     !validation(value ? value : values[name])
                              //   : false)
                            }
                            errorMessage={
                              errors[name] === 'Required'
                                ? 'Required'
                                : errors[name]
                            }
                            showClearButton={showClearButton}
                            formbox={formbox}
                            firstItem={index === 0}
                            lastItem={index === data.length - 1}
                            borderTop={firstItemBorderTop}
                            header={header}
                          />
                        )}
                      </div>
                    )
                  )
                })}
                {documentType && documentType !== '' ? (
                  <div>
                    {!_id && !disabled ? (
                      <div style={{ marginTop: 16 }}>
                        <Dropzone
                          accept={
                            'image/png, image/jpg, image/jpeg, application/pdf'
                          }
                          onDrop={(files, rejectedFiles) =>
                            handleDrop(files, rejectedFiles, documentType)
                          }
                        />
                        {acceptedFiles.map((file, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginTop: 8
                              }}
                            >
                              <Text key={index}>
                                {/* <strong>{file.id + 1}.</strong>  */}
                                {file.name}
                              </Text>
                              <IconButton
                                onClick={() => removeFile(index)}
                                iconName='close'
                                disabled={isSubmitting}
                                iconColor={Colors.danger}
                                buttonStyles={{
                                  width: 20,
                                  cursor: 'pointer'
                                }}
                                iconSize='regular'
                              />
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                    {_id && filename ? (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          marginTop: 8,
                          justifyContent: 'center'
                        }}
                      >
                        <MaterialButton
                          onClick={handleDownloadDocument}
                          variant='text'
                          startIcon={
                            <Icon name='visibility' color={Colors.grey2} />
                          }
                          style={{ color: Colors.grey2 }}
                        >
                          {'Download'}
                        </MaterialButton>
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {children}
              </Grid>
              <div
                style={{
                  ...inlineStyles.innerFormContainer,
                  ...innerFormStyles
                }}
              >
                {!hideSubmit && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {(editable || !showEditButton) && cancelButton && (
                      <Button
                        title='CANCEL'
                        onClick={() => [handleCancel(), setEditable(false)]}
                        bold
                        radius
                        noBorder
                        textColor='white'
                        background={Colors.background}
                        disabled={isSubmitting}
                        style={{
                          ...inlineStyles.submitButton,
                          marginRight: Metrics.base * 2
                        }}
                      />
                    )}
                    {showReset && (
                      <Button
                        type='reset'
                        title='RESET'
                        bold
                        radius
                        noBorder
                        background={Colors.background}
                        textColor='white'
                        style={{
                          ...inlineStyles.submitButton,
                          marginRight: Metrics.base * 2
                        }}
                      />
                    )}
                    {editable ? (
                      <Button
                        type='submit'
                        title={submitButtonTitle ? submitButtonTitle : 'SUBMIT'}
                        primary
                        background={Colors.primary}
                        fetching={isSubmitting}
                        textColor='white'
                        disabled={
                          disabled ||
                          !isValid ||
                          isSubmitting ||
                          !validateForm(values, data) ||
                          (showChanges && !changed)
                        }
                        style={inlineStyles.submitButton}
                      />
                    ) : (
                      showEditButton && (
                        <Button
                          title='EDIT'
                          onClick={() => setEditable(true)}
                          background={Colors.primary}
                          textColor='white'
                          style={inlineStyles.submitButton}
                        />
                      )
                    )}
                  </div>
                )}
                <button
                  ref={resetRef}
                  style={{ display: 'none' }}
                  type='reset'
                  title='RESET'
                />
                {debug && <pre>{JSON.stringify(values, null, 2)}</pre>}
              </div>
            </FormikForm>
          </FormBox>
        )
      }}
    </Formik>
  )
}

Form.defaultProps = {
  editable: true
}

export default Form
