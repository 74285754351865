import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../Graphql/Queries/localQueries'

import { Colors } from './'

export const useColors = () => {
  const { data: darkmodeData } = useQuery(GET_DARKMODE)
  const darkmode = darkmodeData ? darkmodeData.darkmode : true
  let colors = darkmode
    ? { ...Colors, ...Colors.darkTheme }
    : { ...Colors, ...Colors.lightTheme }
  colors.isDarkmode = darkmode
  return colors
}
