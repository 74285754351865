import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import { assetFilters, filters } from '../../GraphQLClient'

export const GET_DEVICE_IDS = gql`
  query devices(
    $orgIdsFilter: [String]
    $assetGroupId: String
    #$searchString: String
    $skip: Int
    $limit: Int
  ) {
    devices(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      #searchString: $searchString
      skip: $skip
      limit: $limit
    ) {
      totalCount
      devices {
        _id
        displayId
      }
    }
  }
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data: data.devices && data.devices.map((a) => a)
  }
}

const getDeviceVariables = ({
  groupId,
  orgIdsFilter,
  searchString,
  skip = 0,
  limit = 10
}) => {
  let variables = {
    skip,
    limit
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(searchString)) {
    variables.searchString = searchString
  }
  return variables
}

export const useDeviceIds = () => {
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { searchString } = useReactiveVar(assetFilters)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DEVICE_IDS, {
    variables: getDeviceVariables({
      groupId,
      orgIdsFilter: orgIds,
      searchString
    }),
    queryName: 'devices',
    errorMessage: 'Failed to fetch Devices Ids',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
