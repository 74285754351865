import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../GraphQLClient'

export const totalCountsFragment = gql`
  fragment totalCountsFragment on TotalCounts {
    driversCount
    tripsCount
    assetsCount
    linkedDriversCount
    linkedAssetsCount
    drivingNowCount
    tripsThisWeek
    tripsThisMonth
  }
`
export const GET_TOTAL_COUNTS = gql`
  query totalCounts($period: String, $orgIdsFilter: [String]) {
    totalCounts(period: $period, orgIdsFilter: $orgIdsFilter) {
      ...totalCountsFragment
    }
  }
  ${totalCountsFragment}
`

const dataTransform = ({ data }) => {
  return data
}

export const useTotalCounts = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_TOTAL_COUNTS,
    {
      variables: {
        period,
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'totalCounts',
      errorMessage: 'Failed to totalCounts',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
