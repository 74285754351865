import React, { useEffect } from 'react'
import {
  ReactTableComp,
  FormGraphQL,
  Button,
  ModalAlert,
  Text,
  FlexBox,
  ProcessingSpinner
} from '../'
import { useColors, Metrics } from '../../Themes'
import ExportExcelButton from '../ExportExcelButton'
import LargeDivider from '../LargeDivider'
import { Heading } from '../Typography'
// import { useAppDimensions } from '../CustomHooks/useAppDimensions'
// import { useTheme } from '../CustomHooks/useTheme'

const handleRadioSelect = ({ selectedRow, dataState, setData }) => {
  setData(
    dataState.map((row) => ({
      ...row,
      selected:
        row.id === selectedRow.original.id
          ? selectedRow.original.selected
            ? false
            : true
          : false
    }))
  )
}

const handleCheckboxSelect = ({
  selectedRow,
  dataState,
  setData,
  checkboxArray,
  setCheckboxArray
}) => {
  if (checkboxArray.includes(selectedRow.original.id)) {
    checkboxArray.splice(checkboxArray.indexOf(selectedRow.original.id), 1)
    setCheckboxArray([...checkboxArray])
  } else {
    setCheckboxArray([...checkboxArray, selectedRow.original.id])
  }

  setData(
    dataState.map((row) => ({
      ...row,
      selected:
        row._id === selectedRow.original.id
          ? selectedRow.original.selected
            ? false
            : true
          : row.selected
    }))
  )
}

const Table = ({
  title,
  data,
  dataState,
  setData,
  selectedObject,
  setSelectedObject,
  formatData = (data) => data,
  sortBy,
  defaultPageSize,
  handleRowClick = () => {},
  fetchingMore,
  fetchMore,
  totalCount,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  manualPagination,
  setPageIndex,
  getBorderLeftStyles = () => {},
  getBackgroundBarStyles,
  columns,
  columnsMobileWidth,
  columnsTabletWidth,
  objectName,
  showMutationModal,
  setShowMutationModal,
  hideHeading,
  renderHeading,
  renderModalContent,
  MutationModalComponent,
  createMutation,
  updateMutation,
  mutationFormSchema,
  handleMutationSubmit,
  style,
  hideNewButton,
  newButtonTitle,
  handleNewButton,
  disabled,
  SubComponent,
  headerContentRight,
  subHeaderContent,
  showExportExcelButton,
  radioSelect,
  checkboxSelect,
  setCheckboxArray,
  checkboxArray,
  loading,
  hideColumnHeaders,
  hideColumnFilters
}) => {
  // const { Colors, Metrics } = useTheme()
  // const { width: appWidth, height: appHeight } = useAppDimensions()
  const Colors = useColors()
  const appWidth = Metrics.window.width

  useEffect(() => {
    if (radioSelect && selectedObject && selectedObject.id) {
      handleRadioSelect({
        selectedRow: { original: selectedObject },
        dataState,
        setData
      })
    }
  }, [radioSelect, selectedObject])
  useEffect(() => {
    if (
      checkboxSelect &&
      setCheckboxArray &&
      checkboxArray &&
      selectedObject &&
      selectedObject.id
    ) {
      handleCheckboxSelect({
        selectedRow: { original: selectedObject },
        dataState,
        setData,
        setCheckboxArray,
        checkboxArray
      })
    }
  }, [checkboxSelect, selectedObject])

  return (
    <div style={style}>
      <ReactTableComp
        loading={loading}
        defaultPageSize={defaultPageSize}
        handleRowClick={handleRowClick}
        setPageIndex={setPageIndex}
        fetchingMore={fetchingMore}
        fetchMore={fetchMore}
        totalCount={totalCount}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        manualPagination={manualPagination}
        hideColumnHeaders={hideColumnHeaders}
        hideColumnFilters={hideColumnFilters}
        SubComponent={SubComponent}
        title={title}
        getBorderLeftStyles={(cell) => getBorderLeftStyles(cell)}
        getBackgroundBarStyles={getBackgroundBarStyles}
        renderHeading={() => {
          return (
            <FlexBox direction='column' style={{ marginBottom: Metrics.base }}>
              {renderHeading ? (
                renderHeading()
              ) : !hideHeading ? (
                <FlexBox
                  style={{
                    width: '100%',
                    height: Metrics.base * 12,
                    justifyContent: 'space-between',
                    backgroundColor: Colors.subsectionBackground
                  }}
                >
                  <FlexBox
                    direction='row'
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      height: Metrics.base * 5
                    }}
                  >
                    <Heading marginLeft={3} marginTop={3} marginBottom={3}>
                      {title}
                    </Heading>
                  </FlexBox>
                  {!hideNewButton && (
                    <Button
                      primary
                      title={
                        newButtonTitle ? newButtonTitle : 'New ' + objectName
                      }
                      id={`table-action-button-New-${objectName}`}
                      background={Colors.primary}
                      style={{
                        marginLeft: Metrics.base * 2,
                        marginRight: Metrics.base * 2
                      }}
                      bold
                      // iconRight='add'
                      textColor={Colors.background}
                      onClick={
                        handleNewButton
                          ? handleNewButton
                          : () => {
                              setSelectedObject(null)
                              setShowMutationModal(true)
                            }
                      }
                    />
                  )}
                  {headerContentRight}
                  {showExportExcelButton && (
                    <FlexBox>
                      <ExportExcelButton data={dataState} />
                    </FlexBox>
                  )}
                </FlexBox>
              ) : (
                ''
              )}
              {subHeaderContent}
              <LargeDivider />
            </FlexBox>
          )
        }}
        columns={
          appWidth < Metrics.widthBreakpoint2 && columnsMobileWidth
            ? columnsMobileWidth
            : appWidth < Metrics.widthBreakpoint1 && columnsTabletWidth
            ? columnsTabletWidth
            : columns
        }
        data={data ? formatData(data) : []}
        dataState={dataState}
        setData={setData}
        sortBy={sortBy}
      />
      {showMutationModal ? (
        <ModalAlert
          title={selectedObject ? `Update ${objectName}` : `New ${objectName}`}
          body='Enter details below'
        >
          {renderModalContent ? (
            renderModalContent({ data: selectedObject })
          ) : MutationModalComponent ? (
            <MutationModalComponent
              object={selectedObject}
              setListData={(data) => setData(formatData(data))}
              setListObject={(object, index) => {
                const newData = [...dataState]
                newData[index] = object
                setData(newData)
              }}
              onDelete={() => setShowMutationModal(false)}
              handleClose={() => setShowMutationModal(false)}
            />
          ) : (
            <FormGraphQL
              object={selectedObject}
              disabled={disabled}
              createMutation={createMutation}
              updateMutation={updateMutation}
              setListData={(data) => setData(formatData(data))}
              setListObject={(object, index) => {
                const newData = [...dataState]
                newData[index] = object
                setData(newData)
              }}
              onDelete={() => {
                var newData = [...dataState]
                newData.splice(selectedObject.index, 1)
                setData(newData)
                setShowMutationModal(false)
              }}
              handleCancel={() => setShowMutationModal(false)}
              handleSubmit={handleMutationSubmit}
              objectName={objectName}
              formSchema={mutationFormSchema}
            />
          )}
        </ModalAlert>
      ) : null}
    </div>
  )
}

export default Table
