import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CHANGE_LESSON_STATUS = gql`
  mutation changeLessonStatus($lessonId: String!, $coachingStatus: String) {
    changeLessonStatus(lessonId: $lessonId, coachingStatus: $coachingStatus) {
      _id
      displayId
      name
      driver {
        _id
      }
      coachingStatus
      lessonEvents {
        coachingEvent {
          _id
          event {
            _id
          }
        }
      }
    }
  }
`
export const useChangeLessonStatus = () => {
  const [changeLessonStatus, { loading: changeLessonStatusLoading }] =
    useMutation(CHANGE_LESSON_STATUS, {
      mutationName: 'changeLessonStatus',
      successMessage: 'Coaching Lesson created successfully',
      errorMessage: 'Coaching Lesson creation failed',
      writeToListOnUpdate: true,
      listQueryName: 'coachingLessonsByDriverId'
    })
  return [changeLessonStatus, { changeLessonStatusLoading }]
}
