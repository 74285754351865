import React from 'react'
import { Button, MaterialInput, Text, FlexBox, Icon } from '../../Components'
import { Images, useColors, Metrics } from '../../Themes'
import { MainLayout, Subsection } from '../../Components'
import { Formik } from 'formik'
import { Subheading } from '../../Components/Typography'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useSetNewUserPasswordEmail } from '../../Graphql/Mutations/SetNewUserPasswordEmail'
import { useParams } from 'react-router-dom'

export const Copyright = () => {
  const Colors = useColors()
  return (
    <Text font='caption' color={Colors.textSecondary}>
      Copyright ©
      <Link
        style={{ color: Colors.textSecondary }}
        target='_blank'
        href='https://www.cybelefleet.tech'
      >
        {' '}
        Cybele{' '}
      </Link>
      {new Date().getFullYear()} | Version {Metrics.version}
    </Text>
  )
}

const setPassword = ({ updatePassword, history, values, token }) => {
  updatePassword({
    variables: { password: values.password, token },
    onCompleted: () => {
      history.push('/')
    }
  })
}

const validationSchema = () => {
  return Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .max(64)
      .required('This field is required'),
    retypePassword: Yup.string()
      .oneOf([Yup.ref('password', {})], 'Passwords must match')
      .required('This field is required')
  })
}

const ResetPassword = ({ history }) => {
  const [updatePassword, { updatePasswordLoading }] =
    useSetNewUserPasswordEmail()
  const Colors = useColors()
  const { token } = useParams()
  return (
    <MainLayout publicLayout>
      <FlexBox
        fillSpace
        direction='column'
        style={{
          backgroundColor: Colors.subsectionBackground,
          width: '100%',
          flex: 1
        }}
      >
        <FlexBox direction='row'>
          <img
            alt='Cybele Emblem'
            width={70}
            src={Images.CybeleEmblem}
            style={{
              marginTop: -Metrics.base,
              marginRight: Metrics.base * 3
            }}
          ></img>
          <img
            src={Images.CybeleLogotype}
            alt='BlockHarvest Logo'
            style={{
              width: 400,
              marginTop: -Metrics.base,
              marginLeft: Metrics.base * 2
            }}
            // style={Styles.logo}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        fillSpace
        style={{
          backgroundColor: Colors.background,
          borderTop: `1px solid ${Colors.dividerColor}`,
          width: '100%',
          flex: 1
        }}
      >
        <Subsection
          style={{
            width: '60%',
            paddingBottom: Metrics.base * 4,
            flexDirection: 'column'
          }}
        >
          <FlexBox
            style={{
              height: Metrics.navBarHeight,
              width: '100%',
              display: 'flex',
              borderBottom: `1px solid ${Colors.dividerColor}`,
              padding: Metrics.base * 2,
              marginBottom: Metrics.base * 6
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
                padding: Metrics.base * 2,
                justifyContent: 'space-between',
                backgroundColor: Colors.selectHighlightOpacity2
              }}
            >
              <FlexBox
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <FlexBox>
                  <Icon
                    name='lock'
                    style={{
                      marginRight: Metrics.base * 2
                    }}
                    size='large'
                    color={Colors.primaryOpacity2}
                  />
                  <Subheading>Reset Password</Subheading>
                </FlexBox>
                <Copyright />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <Formik
            initialValues={{
              password: '',
              retypePassword: ''
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              isValid
            }) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ width: '80%' }}>
                  <MaterialInput
                    id='login-password'
                    name='login-password'
                    label='Choose Password *'
                    type='password'
                    value={values.password}
                    handleChange={(value) => setFieldValue('password', value)}
                    onBlur={() => setFieldTouched('password')}
                    error={touched.password && errors.password}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        setPassword({
                          updatePassword,
                          history,
                          values,
                          token
                        })
                      }
                    }}
                  />
                  <MaterialInput
                    id='retype-password'
                    name='retype-password'
                    label='Re-Enter Password *'
                    type='password'
                    value={values.retypePassword}
                    handleChange={(value) =>
                      setFieldValue('retypePassword', value)
                    }
                    onBlur={() => setFieldTouched('retypePassword')}
                    error={touched.retypePassword && errors.retypePassword}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        setPassword({
                          updatePassword,
                          history,
                          values,
                          token
                        })
                      }
                    }}
                  />
                  <Button
                    primary
                    id='login-button'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                    onClick={() =>
                      setPassword({
                        updatePassword,
                        history,
                        values,
                        token
                      })
                    }
                    textColor='white'
                    center
                    fetching={isSubmitting || updatePasswordLoading}
                    expand
                    radius
                    title='Set Password'
                    type='submit'
                    disabled={
                      !isValid ||
                      values.password === '' ||
                      values.retypePassword === '' ||
                      values.password !== values.retypePassword ||
                      isSubmitting ||
                      updatePasswordLoading
                    }
                    background={Colors.primary}
                  />
                </div>
              </div>
            )}
          </Formik>
        </Subsection>
      </FlexBox>
    </MainLayout>
  )
}

export default ResetPassword
