import React, { useState, useEffect } from 'react'
import {
  FlexBox,
  Text,
  Subsection,
  Button,
  Status,
  DataDisplay,
  NavButton,
  IconButton
} from '../../../Components'
import InfoBadge from '../../../Components/Custom/InfoBadge'
import SubsectionDivider from '../../../Components/Custom/SubsectionDivider'
import { useSelectedDriver } from '../../../Graphql/Queries/Driver/useSelectedDriver'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import { Metrics, useColors, Images } from '../../../Themes'
import { useInspection } from '../../../Graphql/Queries/Inspection/useInspection'
import AddDocumentModal from '../../../Components/Custom/AddDocumentModal'
import { useDocumentsByInspectionId } from '../../../Graphql/Queries/Inspection/useDocumentsByInspectionId'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import { Grid } from '@material-ui/core'
import SecondarySidePanel from '../../../Components/SidePanel/SecondarySidePanel'
import { useHistory } from 'react-router-dom'
// import SelectedObjectHeader from '../../Components/Custom/SelectedObjectHeader'

import {
  BeforeStart,
  AncillaryEquipment,
  AfterStart,
  TrailerInspectionData
} from './InspectionData/InspectionData'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { Subheading } from '../../../Components/Typography'

const getUrlId = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('id')
}

const InteriorExteriorSection = ({ asset, latestInspection, Colors }) => {
  return (
    <Subsection
      style={{
        width: '45%',
        height: '100%',
        marginTop: Metrics.base * 2,
        alignItems: 'none'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={
          latestInspection &&
          latestInspection.reportedMaintenanceStatus &&
          latestInspection.reportedMaintenanceStatus === 'ATTENTION_UNECESSARY'
            ? Images.verifiedUser
            : Images.error
        }
        // leadIcon='check'
        value='Before Engine Start'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />
      {latestInspection && latestInspection._id ? (
        <BeforeStart latestInspection={latestInspection} Colors={Colors} />
      ) : (
        <Text font='subheading' style={{ textAlign: 'center' }}>
          No Before Start inspection data available
        </Text>
      )}
      <SubsectionDivider />
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='Ancillary Equipment'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <AncillaryEquipment
          latestInspection={latestInspection}
          Colors={Colors}
        />
      ) : (
        <Text font='subheading' style={{ textAlign: 'center' }}>
          No Ancillary Equipment inspection data available
        </Text>
      )}
      <SubsectionDivider />

      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='After Engine Start'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <AfterStart latestInspection={latestInspection} Colors={Colors} />
      ) : (
        <Text font='subheading' style={{ textAlign: 'center' }}>
          No After Start inspection data available
        </Text>
      )}
      <SubsectionDivider />

      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        leadImg={Images.verifiedUser}
        value='Trailer'
        actionButton='Edit Items'
        handleButtonClick={() => console.log('hi')}
      />
      <SubsectionDivider />

      {latestInspection && latestInspection._id ? (
        <TrailerInspectionData
          latestInspection={latestInspection}
          Colors={Colors}
        />
      ) : (
        <Text font='subheading' style={{ textAlign: 'center' }}>
          No Trailer inspection data available
        </Text>
      )}
      <SubsectionDivider />
    </Subsection>
  )
}

const ImageGrid = ({ inspectionImages }) => {
  return (
    <Grid container spacing={3} justifyContent='center' alignItems='flex-start'>
      {inspectionImages.map((image) => {
        return (
          <Grid item xs={12} md={6}>
            <img
              src={image.url}
              alt='Cybelelogo'
              style={{
                borderRadius: Metrics.base * 2,
                width: '100%'
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

const TruckImages = ({ Colors, inspectionImages, setShowAddDocumentModal }) => {
  return (
    <Subsection
      style={{
        width: '45%',
        height: '100%',
        marginTop: Metrics.base * 2
        // justifyContent: 'center',
        // alignItems: 'flex-start'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        value='Images'
        actionButton='Upload Images'
        handleButtonClick={() => setShowAddDocumentModal(true)}
      />
      <SubsectionDivider />

      {checkVal(inspectionImages) && inspectionImages.length > 0 ? (
        <FlexBox
          style={{
            width: '100%',
            padding: Metrics.base * 3
          }}
        >
          <ImageGrid
            inspectionImages={inspectionImages}
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </FlexBox>
      ) : (
        <Text
          font='subheading'
          style={{ padding: Metrics.base * 2, textAlign: 'center' }}
        >
          No Images Uploaded
        </Text>
      )}
    </Subsection>
  )
}

const ViewInspection = (props) => {
  const Colors = useColors()
  const id = getUrlId()
  const { data: inspection } = useInspection(id)
  const [viewInspection, setViewInspection] = useState()
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  const { data: inspectionDocuments } = useDocumentsByInspectionId({
    inspectionId: id,
    documentType: 'INSPECTION_MAIN'
  })
  const [inspectionImages, setInspectionImages] = useState([])
  const { mainContainerWidth } = useAppDimensions()
  const history = useHistory()
  useEffect(() => {
    if (checkVal(inspectionDocuments)) {
      setInspectionImages(inspectionDocuments)
    }
  }, [inspectionDocuments])

  useEffect(() => {
    if (inspection) {
      setViewInspection(inspection.data)
    }
  }, [inspection])
  return (
    <FlexBox
      direction='Column'
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%' // mainContainerWidth
      }}
    >
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          backgroundColor: Colors.subsectionBackground,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          height: Metrics.tabBarHeight
        }}
      >
        <IconButton
          iconStyle={{
            marginLeft: Metrics.base * 2,
            marginRight: Metrics.base * 4
          }}
          iconName={'arrow_back'}
          onClick={() =>
            history.push({ pathname: '/fleet-management/inspection-history' })
          }
        >
          {'Go Back'}
        </IconButton>
        <Subheading>View Inspection Detail</Subheading>
      </FlexBox>
      <FlexBox
        direction='Column'
        justifyContent='space-evenly'
        alignItems='center'
        style={{
          width: '100%'
        }}
      >
        {/* {checkVal(lesson) && checkVal(lesson.driver) && (
          <SelectedObjectHeader
            pageName='coachingRadioTabs'
            id={lesson.driver._id}
          />
        )} */}

        {inspection && checkVal(inspection.data) && (
          <Subsection
            style={{
              width: '92.5%',
              padding: Metrics.base * 2,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            <Text font='subheading'>
              {' '}
              Inspection Date:{' '}
              {formatDateTime(new Date(inspection.data.createdAt))}
            </Text>
            <FlexBox direction='row'>
              <Text font='subheading' style={{ marginRight: Metrics.base * 2 }}>
                Maintanance:{' '}
              </Text>
              <Status
                status={'REQUIRED'}
                getStatusColors={() => '#F88F15'}
                // style={{ marginRight: Metrics.base * 8 }}
              />
            </FlexBox>
          </Subsection>
        )}

        <FlexBox
          direction='row'
          justifyContent='space-evenly'
          alignItems='flex-start'
          style={{
            width: '100%',
            marginTop: Metrics.base * 2
          }}
        >
          {inspection && checkVal(inspection.data) && (
            <InteriorExteriorSection
              latestInspection={viewInspection}
              Colors={Colors}
            />
          )}

          {inspection && checkVal(inspection.data) && (
            <TruckImages
              inspectionImages={inspectionImages}
              Colors={Colors}
              setShowAddDocumentModal={setShowAddDocumentModal}
            />
          )}
          {inspection && checkVal(inspection.data) && showAddDocumentModal && (
            <AddDocumentModal
              typeOptions={'inspection'}
              showAddDocumentModal={showAddDocumentModal}
              setShowAddDocumentModal={setShowAddDocumentModal}
              inspectionId={inspection.data._id}
            />
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
export default ViewInspection
