import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { safetyScoreOverTimeFragment } from '../Driver/useDriverSafetyOverTime'

export const GET_SAFETY_OVER_TIME = gql`
  query assetSafetyOverTime(
    $orgIdsFilter: [String] #$assetGroupId: String
  ) {
    assetSafetyOverTime(
      orgIdsFilter: $orgIdsFilter #assetGroupId: $assetGroupId
    ) {
      ...safetyScoreOverTimeFragment
    }
  }
  ${safetyScoreOverTimeFragment}
`

const dataTransform = ({ data }) => {
  return data && data.map((a) => ({ ...a, d: new Date(a.d) }))
}

export const useAssetSafetyOverTime = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_SAFETY_OVER_TIME,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'assetSafetyOverTime',
      errorMessage: 'Failed to fetch assetSafetyOverTime',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
