import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import { CREATE_DEVICE } from '../../Graphql/Mutations/useCreateDevice'
import { UPDATE_DEVICE } from '../../Graphql/Mutations/useUpdateDevice'

import { Button, FlexBox, ModalAlert, Form, Subsection, FormGraphQL } from '../'

const handleSubmit = ({ data, setSubmitting, mutate, onSuccess, onError }) => {
  if (data) {
    if (!data.iccID1) {
      data.iccID1 = null
    }
    if (!data.iccID2) {
      data.iccID2 = null
    }
  }

  setSubmitting(true)
  mutate({
    variables: data,
    onComplete: (data) => {
      setSubmitting(false)
      onSuccess()
    },
    onError: (error) => {
      setSubmitting(false)
      onError()
    }
  })
  onSuccess()
}

const AddDeviceModal = ({
  setShowAddDeviceModal,
  object,
  showAddDeviceModal
}) => {
  const { data: deviceOrganizations } = useOrganizations()
  const [organizations, setOrganizations] = useState([])

  const handleCancel = () => {
    setShowAddDeviceModal(false)
  }

  useEffect(() => {
    if (deviceOrganizations && deviceOrganizations.length > 0) {
      let orgs = []
      deviceOrganizations.forEach((organization) => {
        const values = { value: organization._id, label: organization.name }
        orgs = [...orgs, values]
      })
      setOrganizations(orgs)
    }
  }, [deviceOrganizations])

  const deviceFormFields = [
    {
      name: 'orgId',
      required: false,
      label: 'Organization',
      type: 'select',
      options: organizations
    },
    // {
    //   name: 'deviceId',
    //   required: false,
    //   label: 'VVT Device Id'
    // },
    {
      name: 'name',
      required: false,
      // type: 'date',
      label: 'Name'
    },
    // {
    //   name: 'imei',
    //   required: false,
    //   label: 'IMEI'
    // },
    // {
    //   name: 'iccID1',
    //   required: false,
    //   type: 'number',
    //   label: 'iccID1'
    // },
    // {
    //   name: 'iccID2',
    //   required: false,
    //   type: 'number',
    //   label: 'iccID2'
    // },
    {
      name: 'isActive',
      required: false,
      type: 'checkbox',
      label: 'Is Active'
    }
  ]

  return (
    <ModalAlert
      title={`New Device`}
      body='Enter details below'
      openDialog={showAddDeviceModal}
      setOpenDialog={setShowAddDeviceModal}
    >
      <FormGraphQL
        formSchema={deviceFormFields}
        createMutation={CREATE_DEVICE}
        updateMutation={UPDATE_DEVICE}
        handleSubmit={({ data, setSubmitting, mutate, onSuccess, onError }) =>
          handleSubmit({ data, setSubmitting, mutate, onSuccess, onError })
        }
        onSuccess={() => setShowAddDeviceModal(false)}
        onError={() => setShowAddDeviceModal(false)}
        handleCancel={() => setShowAddDeviceModal(false)}
        object={object ? object : null}
      />
    </ModalAlert>
  )
}

export default AddDeviceModal
