import { css } from 'aphrodite'
import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Colors } from '../../Themes'
import { Text, Icon } from '../'
import Styles, { time, inlineStyles } from './Styles'
import { GET_NOTIFICATION_SHOW } from '../../Graphql/Queries/localQueries'
import { UPDATE_NOTIFICATION } from '../../Graphql/Mutations'

let timeout

const _startTimeout = (updateNotification) => {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    updateNotification({ variables: { show: false } })
  }, time)
}

const Notification = () => {
  const {
    data: { notification }
  } = useQuery(GET_NOTIFICATION_SHOW)
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION)
  const { text, level } = notification
  let newText = text && text.replace('GraphQL error: ', '')
  const backgroundColor = level === 'danger' ? Colors.danger : Colors.success

  if (notification.show) {
    _startTimeout(updateNotification)
    return (
      <div className={css(Styles.mainContainer)} style={{ backgroundColor }}>
        <Icon
          name={level === 'danger' ? 'error' : 'check_circle'}
          color='white'
        ></Icon>
        <Text
          bold
          color={Colors.white}
          style={inlineStyles.text}
          marginLeft={2}
        >
          {newText || 'Action completed successfully'}
        </Text>
      </div>
    )
  } else {
    return null
  }
}

export default Notification
