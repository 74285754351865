import React, { useEffect } from 'react'
import '@tomtom-international/web-sdk-maps/dist/maps.css'
import { default as ttMaps } from '@tomtom-international/web-sdk-maps'
import { Metrics, useColors, Utils } from '../../Themes'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'
import { selectedClusterCard } from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'

const removeMarkers = (markers) => {
  if (markers && markers.length > 0) {
    for (var i = 0; i < markers.length; i++) {
      markers[i].remove()
    }
  }
}

export const clusterOnClick = ({ map, clusterCenter, mapZoom }) => {
  map &&
    clusterCenter &&
    map.easeTo({ center: clusterCenter, zoom: mapZoom + 4 })
}

const addClusterMarkers = ({
  map,
  clusters,
  setClusterMarkers,
  clusterMarkers,
  Colors,
  mapZoom
}) => {
  removeMarkers(clusterMarkers)
  if (clusters && clusters.length > 0) {
    const temp = []
    for (var i = 0; i < clusters.length; i++) {
      if (clusters[i].geometry) {
        var element = document.createElement('div')
        element.id = 'marker'
        element.style = `
    width: 60px;
    height: 60px;
    background-color: ${Colors.primaryOpacity2};
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    `
        element.innerHTML = `<div style="
      font-size: 32px; 
      color: white;
      width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: ${Colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
      ">${
        clusters[i] &&
        clusters[i].properties &&
        clusters[i].properties.point_count
          ? clusters[i].properties.point_count
          : 1
      }</div>`
        const clusterCenter =
          clusters[i] &&
          clusters[i].geometry &&
          clusters[i].geometry.coordinates
        element.onclick = () => clusterOnClick({ map, clusterCenter, mapZoom })
        var marker = new ttMaps.Marker({
          rotation: 0,
          anchor: 'center',
          offset: [0, 0],
          element
        })
          .setLngLat(clusters[i].geometry.coordinates)
          .addTo(map)
        temp.push(marker)
      }
    }
    setClusterMarkers(temp)
  }
}

var popupOffsets = {
  top: [0, 0],
  bottom: [0, -51],
  'bottom-right': [0, -70],
  'bottom-left': [0, -70],
  left: [25, -35],
  right: [-25, -35]
}

export const assetPopupHTML = (assetName) => {
  return `<span style="color: #22557D"><b>${assetName}</b></span>`
}

const addMarkers = ({
  map,
  setMarkers,
  setDeviceMarkers,
  deviceIds,
  linkedAssetsData,
  markerData,
  shouldRemoveMarkers,
  markers,
  setMapUserInteraction
}) => {
  if (shouldRemoveMarkers) {
    removeMarkers(markers)
  }
  if (deviceIds && deviceIds.length > 0) {
    const temp = []
    const tempDeviceMarkers = {}
    for (var i = 0; i < deviceIds.length; i++) {
      const deviceId = deviceIds[i]
      const assetId =
        linkedAssetsData[deviceIds[i]] && linkedAssetsData[deviceIds[i]].id
      var element = document.createElement('div')
      element.id = 'marker'
      element.style = `background-image: url('${markerData.icon}');
      background-size: cover;
      width: ${markerData.width}px;
      height: 60px;
      cursor: pointer
      `
      //transition: transform 0.5s linear;
      element.onclick = () =>
        markerData.onClick({ deviceId, assetId, setMapUserInteraction })
      const markerProps = markerData.icon ? { element } : {}
      var marker = new ttMaps.Marker({
        rotation: 0,
        anchor: markerData.anchor ? markerData.anchor : 'bottom',
        rotationAlignment: 'map',
        // rotationAlignment: 'map',
        offset: markerData.offset ? markerData.offset : [0, 0],
        color: markerData.color,
        ...markerProps
      })
        .setLngLat({ lat: 0, lng: 0 })
        .addTo(map)

      var popup = new ttMaps.Popup({ offset: popupOffsets }).setHTML(
        assetPopupHTML(
          linkedAssetsData[deviceIds[i]] && linkedAssetsData[deviceIds[i]].name
        )
      )
      marker.setPopup(popup).togglePopup()

      tempDeviceMarkers[deviceId] = marker
      temp.push(marker)
    }
    setDeviceMarkers(tempDeviceMarkers)
    setMarkers(temp)
  }
}

export const getGeoJSON = (points, type = 'LineString') => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        geometry: {
          coordinates: [...points],
          type
        }
      }
    ]
  }
}

const addLine = ({ layerId, map, points, color, lineWidth }) => {
  if (points && points.length > 1) {
    if (!map.getLayer(layerId)) {
      map.addLayer({
        id: layerId,
        type: 'line',
        source: {
          type: 'geojson',
          data: getGeoJSON(points)
        },
        paint: {
          'line-color': color,
          'line-width': lineWidth
        }
      })
    } else {
      map.getSource(layerId).setData(getGeoJSON(points))
    }
  }
}

const LiveViewMap = ({
  map,
  mapElement,
  mapZoom,
  markerData,
  deviceIds,
  linkedAssetsData,
  markers,
  setDeviceMarkers,
  setMarkers,
  width,
  height,
  locationsLoading,
  setMapUserInteraction,
  clusters,
  clustersLoading,
  clusterMarkers,
  setClusterMarkers,
  clustersChanged,
  deviceIdsChanged,
  children
}) => {
  const Colors = useColors()
  const clusterCard = useReactiveVar(selectedClusterCard)

  useEffect(() => {
    if (map && deviceIds) {
      if (deviceIds.length > 0) {
        addMarkers({
          map,
          setMarkers,
          setDeviceMarkers,
          deviceIds,
          linkedAssetsData,
          markerData,
          shouldRemoveMarkers: true,
          markers,
          setMapUserInteraction
        })
      } else if (!clustersLoading && !locationsLoading) {
        removeMarkers(markers)
        setDeviceMarkers({})
      }
    }
  }, [map, deviceIdsChanged])

  useEffect(() => {
    if (map && clusters) {
      if (clusters.length > 0 && deviceIds.length === 0) {
        addClusterMarkers({
          map,
          clusters,
          setClusterMarkers,
          clusterMarkers,
          Colors,
          mapZoom
        })
      } else if (!clustersLoading && !locationsLoading) {
        removeMarkers(clusterMarkers)
      }
    }
  }, [clustersChanged, deviceIdsChanged])

  useEffect(() => {
    if (
      map &&
      clusterCard &&
      clusterCard.geometry &&
      clusterCard.geometry.coordinates
    ) {
      clusterOnClick({
        map,
        clusterCenter: clusterCard.geometry.coordinates,
        mapZoom
      })
    }
  }, [clusterCard])

  /*
  useEffect(() => {
    if (map && locationHistoryLines) {
      for (var i = 0; i < locationHistoryLines.length; i++) {
        //map.isStyleLoaded() &&
        mapLoaded &&
          locationHistoryLines[i] &&
          locationHistoryLines[i].points &&
          locationHistoryLines[i].points.length > 1 &&
          addLine({
            map,
            points: locationHistoryLines[i].points,
            layerId: locationHistoryLines[i].lineId,
            color: locationHistoryLines[i].color,
            lineWidth: locationHistoryLines[i].width
          })
      }
    }
    // if (map && linePoints && linePoints.length === 0 && map.getLayer(lineId)) {
    //   map.removeLayer(lineId)
    //   map.removeSource(lineId)
  }, [locationHistoryLines, mapLoaded])
  */

  return <>{children}</>
}

export default LiveViewMap
