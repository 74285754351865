import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const UPDATE_ASSET = gql`
  mutation updateAsset(
    $id: String!
    $orgId: String
    $name: String
    $assetType: String
    $assetNo: String
    $subVehicleType: String
    $maxSpeed: Int
    $fuelType: String
    $vehicleMake: String
    $vehicleModel: String
    $vehicleYear: String
    $dateOfPurchase: GraphqlDateTime
    $registrationNum: String
    $licenseNumber: String
    $vin: String
    $engineNumber: String
    $driven: String
    $vehicleDescription: String
    $gvm: Int #capacity
    $tare: Int #weight
    $nvc: String
    $controlNumber: String
    $registeringAuthority: String
    $doe: GraphqlDateTime
    $prdpCategory: [String]
    $vehicleColour: String
    $specialMarkings: String
    $branch: String
    $isActive: Boolean
  ) {
    updateAsset(
      id: $id
      orgId: $orgId
      name: $name
      assetType: $assetType
      assetNo: $assetNo
      subVehicleType: $subVehicleType
      maxSpeed: $maxSpeed
      fuelType: $fuelType
      vehicleMake: $vehicleMake
      vehicleModel: $vehicleModel
      vehicleYear: $vehicleYear
      dateOfPurchase: $dateOfPurchase
      registrationNum: $registrationNum
      licenseNumber: $licenseNumber
      vin: $vin
      engineNumber: $engineNumber
      driven: $driven
      vehicleDescription: $vehicleDescription
      gvm: $gvm #capacity
      tare: $tare #weight
      nvc: $nvc
      controlNumber: $controlNumber
      registeringAuthority: $registeringAuthority
      doe: $doe
      prdpCategory: $prdpCategory
      vehicleColour: $vehicleColour
      specialMarkings: $specialMarkings
      branch: $branch
      isActive: $isActive
    ) {
      _id
      name
      isActive
    }
  }
`
export const useUpdateAsset = () => {
  const [updateAsset, { loading: updateAssetLoading }] = useMutation(
    UPDATE_ASSET,
    {
      mutationName: 'updateAsset',
      successMessage: 'Asset updated successfully',
      errorMessage: 'Asset update failed'
    }
  )

  return [updateAsset, { updateAssetLoading }]
}
