import { StyleSheet } from 'aphrodite'

const animationKeyframes = {
  '0%': {
    transform: 'translateX(-100%)'
  },
  '85%': {
    transform: 'translateX(100%)'
  },
  '100%': {
    transform: 'translateX(100%)'
  }
}

export default StyleSheet.create({
  mainContainer: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    right: 0,
    left: 0,
    width: '100%',
    height: 2,
    animationName: animationKeyframes,
    animationDuration: '2.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(.755, .05, .755, .05)'
  }
})
