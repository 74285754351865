import React, { useState, useEffect } from 'react'
import { FlexBox, Button, Form, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import { useInspectionsByAssetId } from '../../../Graphql/Queries/Inspection/inspectionsByAssetId'
import { useCreateInspection } from '../../../Graphql/Mutations/useCreateInspection'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import { useHistory, useLocation, useParams } from 'react-router-dom'
const radioOptions = [
  { value: 'GOOD', label: 'Good Condition' },
  { value: 'REPAIR', label: 'Needs Repair' },
  { value: 'REPLACE', label: 'Needs Replacement' }
]

const getFormSchema = ({
  beforeStart,
  ancillaryEquipment,
  startEngine,
  trailerInspection,
  setBeforeStart,
  setAncillaryEquipment,
  setStartEngine,
  setTrailerInspection
}) => {
  return [
    {
      name: 'inspectionType',
      required: false,
      label: 'Inspection Type',
      style: { maxWidth: '10%', alignSelf: 'center' },
      type: 'select',
      options: [
        { label: 'Pre-trip', value: 'PRE_TRIP' },
        { label: 'Post-trip', value: 'POST_TRIP' }
      ]
    },
    {
      name: 'engineOilLevel',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Engine Oil Level',
      header: 'Before Start',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'engineOilLeaks',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Engine Oil Leaks',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'waterLevel',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Water Level',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'waterLeaks',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Water Leaks',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'fuelLeaksCap',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Fuel Leaks (Cap)',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },

    {
      name: 'fanBelts',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Fan Belts',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'hoses',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Hoses',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'brakeFluidLevel',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Brake Fluid Level',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'clutchFluidLevel',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Clutch Fluid Level',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'battery',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Battery',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'wheelNuts',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Wheel Nuts',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'tyres',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Tyres',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'brakes',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Brakes',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'bodyCondition',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Body Condition',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'rearUnderrun',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Rear Underrun',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'chevron',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Chevron',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'reflectorsAndStrips',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Reflectors and Yellow Strips',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'mirrors',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Mirrors',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'windows',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Windows',
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'otherBeforeStarting',
      required: false,
      type: 'textarea',
      label: 'Before starting inspection remarks',
      style: { width: '40%', alignSelf: 'left' },
      handleChange: (e, name) => {
        setBeforeStart({ ...beforeStart, [name]: e })
      }
    },
    // AUCILLARY -----------------------------------------------------------------------------------------------------------------------------------------
    {
      name: 'jack',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Jack',
      header: 'Ancillary Equipment',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'wheelChocks',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Wheel Chocks',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'fireExtinguisher',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Fire Extinguisher',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'spareFusesBulbsFanbelts',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Spare Fuses, Bulbs, Fan Belts',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'warningTriangles',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Warning Triangles',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },

    {
      name: 'placarding',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Placarding',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'permitsAndDocuments',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Permits and Documents',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'tachographCharts',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Tachograph Charts',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'loadAndFastenings',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Load and Fastenings',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'firstAidKit',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'First Aid Kit',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'wheelNutsAncillary',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Wheel Nuts Ancillary',
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'otherAncillary',
      required: false,
      type: 'textarea',
      label: 'Other ancillary inspection remarks',
      style: { width: '40%', alignSelf: 'left' },
      handleChange: (e, name) => {
        setAncillaryEquipment({
          ...ancillaryEquipment,
          [name]: e
        })
      }
    },
    //engine start -------------------------------------------------------------------------------------
    {
      name: 'handBrakeAction',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Hand Brake Action',
      header: 'Engine Start',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'footBrakeAction',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Foot Brake Action',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },

    {
      name: 'clutchPedalPlay',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Clutch Pedal Play',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'steering',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Steering',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'horn',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Horn',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'windscreenWipersAndWashers',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Windscreen, Wipers, and Washers',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },

    {
      name: 'warningLights',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Warning Lights',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'gauges',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Gauges',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'airPressureBuzzerGauges',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Air Pressure Buzzer Gauges',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'lights',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Lights',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'indicators',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Indicators',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'auxiliaryEquipment',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Auxiliary Equipment',
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: { inspectionCheckType: e } })
      }
    },
    {
      name: 'otherEngineStart',
      required: false,
      type: 'textarea',
      label: 'Other engine start inspection remarks',
      style: { width: '40%', alignSelf: 'left' },
      handleChange: (e, name) => {
        setStartEngine({ ...startEngine, [name]: e })
      }
    },
    //TRAILER ------------------------------------------------------------------------------------------------------------------------------------
    {
      name: 'trailerNo',
      required: false,
      type: 'textarea',
      label: 'Trailer No.',
      style: { width: '40%', alignSelf: 'left' },
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: e
        })
      }
    },
    {
      name: 'hookUpAndFifthWheel',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Hook up and Fifth Wheel',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'airHoseConnections',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Air Hose Connections',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'brakesTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Brakes',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'wheelNutsTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Wheel Nuts',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'tyresTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Tyres',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'semiTrailerLandingGear',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Semi Trailer Landing Gear',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'towBarSafetyChains',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Tow Bar Safety Chains',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'bodyConditionTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Body Condition',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'rearUnderrunTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Rear Underrun',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'chevronTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Chevron',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'reflectorsTrailer',
      required: false,
      type: 'radio',
      options: radioOptions,
      initialValue: null,
      label: 'Reflectors',
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: { inspectionCheckType: e }
        })
      }
    },
    {
      name: 'otherTrailer',
      required: false,
      type: 'textarea',
      label: 'Other trailer inspection remarks',
      style: { width: '40%', alignSelf: 'left' },
      handleChange: (e, name) => {
        setTrailerInspection({
          ...trailerInspection,
          [name]: e
        })
      }
    },
    {
      name: 'reportedMaintenanceStatus',
      required: false,
      type: 'select',
      options: [
        { value: 'ATTENTION_UNECESSARY', label: 'Vehicle is in order' },
        {
          value: 'ATTENTION_LATER_DATE',
          label: 'Vehicle needs attention at a later date'
        },
        { value: 'ATTENTION_IMMEDIATE', label: 'Needs immediate attention' }
      ],
      initialValue: null,
      label: 'Maintenance needed:'
    }
  ]
}

const InspectionHistory = (props) => {
  const assetId = useReactiveVar(selectedAssetId)
  const [beforeStart, setBeforeStart] = useState({})
  const [ancillaryEquipment, setAncillaryEquipment] = useState({})
  const [startEngine, setStartEngine] = useState({})
  const [trailerInspection, setTrailerInspection] = useState({})
  const [createInspection] = useCreateInspection()
  const history = useHistory()
  const handleSubmit = async (data) => {
    const createInspectionVariables = {
      ...data,
      beforeStart,
      ancillaryEquipment,
      startEngine,
      trailerInspection
    }
    if (assetId) {
      createInspectionVariables.assetId = assetId
    }
    await createInspection({
      variables: createInspectionVariables,
      onCompleted: (data) => {
        history.goBack()
      },
      onError: (data) => {}
    })
  }

  return (
    <FlexBox
      direction='column'
      alignItems='center'
      style={{
        justifyContent: 'space-evenly',
        width: '100%',
        padding: Metrics.base * 6
      }}
    >
      <Button
        condensed
        title=''
        icon='arrow_back'
        onClick={() => props.history.goBack()}
        textColor='white'
        background='red'
        style={{ marginRight: Metrics.base * 2, alignSelf: 'flex-start' }}
      />
      <Form
        hideSubmit={!assetId}
        formbox={false}
        style={{
          padding: Metrics.base * 2,
          width: '70%'
        }}
        innerFormStyles={{ paddingBottom: 0 }}
        hideFormBox
        darkmode={false}
        direction='column'
        onSubmit={(data) =>
          handleSubmit({
            ...data
          })
        }
        data={getFormSchema({
          beforeStart,
          ancillaryEquipment,
          startEngine,
          trailerInspection,
          setBeforeStart,
          setAncillaryEquipment,
          setStartEngine,
          setTrailerInspection
        })}
      />
    </FlexBox>
  )
}
export default InspectionHistory
