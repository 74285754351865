import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const UPDATE_DEVICE = gql`
  mutation updateDevice(
    $id: String!
    $deviceId: String
    $orgId: String
    $name: String
    $imei: String
    $isActive: Boolean
    $iccID1: Float
    $iccID2: Float
  ) {
    updateDevice(
      id: $id
      deviceId: $deviceId
      orgId: $orgId
      name: $name
      imei: $imei
      isActive: $isActive
      iccID1: $iccID1
      iccID2: $iccID2
    ) {
      _id
      deviceId
      name
      isActive
      imei
      iccID1
      iccID2
    }
  }
`
export const useUpdateDevice = () => {
  const [updateDevice, { loading: updateDeviceLoading }] = useMutation(
    UPDATE_DEVICE,
    {
      mutationName: 'updateDevice',
      successMessage: 'Device updated successfully',
      errorMessage: 'Device update failed'
    }
  )

  return [updateDevice, { updateDeviceLoading }]
}
