import React from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import {
  DataDisplay,
  FlexBox,
  InfoBadge,
  Status,
  Subsection,
  Text
} from '../../Components'
import { Display, Heading, Title } from '../../Components/Typography'
import { ModifiedPieChart } from '../../Components/Custom/ModifiedPieChart'
import LargeDivider from '../../Components/LargeDivider'
import { checkVal, hhmmss, formatDateTime } from '../../Helpers/Functions'
import { useEffect } from 'react'
import AreaChart from '../../Components/D3Charts/AreaChart'
import { useState } from 'react'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import VideoPlayer from '../../Components/Custom/VideoPlayer'
import EventHeader from '../../Components/Custom/EventHeader'
import { selectedEventId, tripReplayCamera } from '../../Graphql/GraphQLClient'
import { useVideoClipByEventId } from '../../Graphql/Queries/useVideoClipByEventId'
import TripReplayStreetView from '../../Components/Custom/TripReplayStreetView'

export const PhoneMotionEventInfo = ({ event }) => {
  const Colors = useColors()
  return (
    <>
      <FlexBox
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        {/*<FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
          <FlexBox>
            {event && event.type && Images[event.type] && (
              <img
                width={35}
                style={{
                  marginLeft: Metrics.base,
                  marginRight: Metrics.base * 2
                }}
                alt={`${event.type}_icon`}
                src={Images[event.type]}
              ></img>
            )}
            <Heading>{event.name}</Heading>
          </FlexBox>
          <Status
            status={event.severityCategoryDisplayText}
            getStatusColors={() => Colors[event.category]}
          ></Status>
              </FlexBox>*/}
        <EventHeader
          eventId={event && event._id}
          handleClose={() => selectedEventId(null)}
        />
      </FlexBox>
      <FlexBox
        style={{
          justifyContent: 'flex-start',
          paddingLeft: Metrics.base * 2,
          paddingTop: Metrics.base * 2,
          paddingBottom: Metrics.base * 2,
          width: '100%'
          // backgroundColor: 'red'
        }}
      >
        <div
          style={{
            height: 140,
            width: 140,
            position: 'relative',
            marginBottom: Metrics.base * 2
          }}
        >
          {/*<FlexBox
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0
            }}
          >
            <Display>{event.severity}</Display>
        </FlexBox>*/}
          <ModifiedPieChart
            color={Colors[event.category]}
            hideLegend
            hideLabels
            padAngle={0}
            innerRadius={0.9}
            startAngle={-180}
            endAngle={180}
            enableArcLinkLabels={false}
            enableSlicesLabels={false}
            enableArcLabels={false}
            isInteractive
            data={[
              {
                id: 'volumeDelivered',
                label: 'label1',
                value: event.normScore
              },
              {
                id: 'volumeRemaining',
                label: 'label2',
                value: 10 - event.normScore
              }
            ]}
          >
            hi
          </ModifiedPieChart>
        </div>
        <FlexBox direction='column' style={{ padding: Metrics.base * 2 }}>
          <Display>
            {checkVal(event.normScore) && `${event.normScore.toFixed(2)}/10`}
          </Display>
          <Text marginTop={1}>Severity score</Text>
        </FlexBox>
        <FlexBox
          direction='column'
          style={{
            marginLeft: Metrics.base * 4,
            paddingLeft: Metrics.base * 4,
            alignItems: 'flex-start',
            borderLeft: `1px solid ${Colors.dividerColor}`
          }}
        >
          <FlexBox style={{ width: 250 }}>
            <InfoBadge
              size='medium'
              value={event.duration}
              label={'Duration'}
              getDisplayVal={(value) => hhmmss(value)}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox
        style={{
          width: '100%',
          borderTop: `1px solid ${Colors.dividerColor}`
        }}
      >
        <DataDisplay
          firstItem
          lastItem
          style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
          data={event.startTime ? event.startTime : '-'}
          label='Start Time'
          textColor={Colors.secondaryOpacity1}
        ></DataDisplay>
        <DataDisplay
          firstItem
          lastItem
          style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
          data={event.endTime ? event.endTime : '-'}
          label='End Time'
          textColor={Colors.secondaryOpacity1}
        ></DataDisplay>
      </FlexBox>
      <DataDisplay
        firstItem
        lastItem
        data={
          event.startLat && event.startLong
            ? `Lat: ${event.startLat}, Lng: ${event.startLong}`
            : '-'
        }
        label='Start Location'
        textColor={Colors.secondaryOpacity1}
      ></DataDisplay>
      <DataDisplay
        firstItem
        lastItem
        rowStyle={{ borderBottom: 'none' }}
        data={
          event.endLat && event.endLong
            ? `Lat: ${event.endLat}, Lng: ${event.endLong}`
            : '-'
        }
        label='End Location'
        textColor={Colors.secondaryOpacity1}
      ></DataDisplay>
    </>
  )
}

const getSpeedChartData = (values) => {
  const data = []
  for (var i = 0; i < values[0].length; i++) {
    checkVal(values[0][i]) &&
      checkVal(values[1][i]) &&
      data.push({
        date: new Date(parseInt(values[1][i])),
        value: parseInt(values[0][i])
      })
  }
  return data.sort((a, b) => a.date.getTime() - b.date.getTime())
}

const PhoneMotionDetails = ({ eventId, event }) => {
  const [speedChartData, setSpeedChartData] = useState()
  const [mapLoaded, setMapHasLoaded] = useState()
  useEffect(() => {
    if (event && event.values && event.values.length === 2) {
      setSpeedChartData(getSpeedChartData(event.values))
    }
    if (mapLoaded && event && event.startLat && event.startLong) {
      tripReplayCamera({
        ...tripReplayCamera(),
        center: { lat: event.startLat, lng: event.startLong },
        heading: null
      })
    }
  }, [event, mapLoaded])
  const { mainContainerWidth } = useAppDimensions()
  const { data: videoClips } = useVideoClipByEventId(eventId)
  return (
    checkVal(eventId) &&
    event && (
      <Subsection
        style={{ width: '100%', paddingBottom: 0, overflow: 'hidden' }}
      >
        <PhoneMotionEventInfo event={event} />
        <LargeDivider />
        {videoClips && videoClips.length > 0 ? (
          <VideoPlayer
            eventId={eventId}
            size={mainContainerWidth - Metrics.sideBarWidth - Metrics.base * 2}
          />
        ) : (
          <FlexBox direction='column' style={{ width: '100%', height: 540 }}>
            <FlexBox
              style={{
                width: '100%',
                height: 40,
                backgroundColor: '#4C525B'
              }}
            >
              <Text style={{ marginLeft: Metrics.base }} color='white'>
                Streetview
              </Text>
            </FlexBox>
            <TripReplayStreetView
              setMapHasLoaded={setMapHasLoaded}
              throttle={false}
            />
          </FlexBox>
        )}
      </Subsection>
    )
  )
}

export default PhoneMotionDetails
