import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../Helpers/Functions'

export const documentFragment = gql`
  fragment documentFragment on Document {
    _id
    driver {
      _id
      firstName
    }
    url
    documentType
    filename
    createdAt
    isActive
    displayId
    driverLicence {
      countryOfIssue
      controlNumber
      dob
      dateIssued
      professionalDrivingPermitExpiryDate
      licenceCode
      doe
      professionalDrivingPermit
      driverRestrictions
      vehicleRestrictions
    }
  }
`

export const GET_DOCUMENT_BY_DRIVER_ID = gql`
  query documentsByDriverId($driverId: String, $skip: Int, $limit: Int) {
    documentsByDriverId(driverId: $driverId, skip: $skip, limit: $limit) {
      ...documentFragment
    }
  }
  ${documentFragment}
`

export const documentDataTransform = ({ data }) => {
  return [...data]
  // name: `${data.firstName} ${data.lastName}`
}

const getDocumentVariables = (driver) => {
  let variables = {
    skip: 0,
    limit: 1000
  }
  if (checkVal(driver) && checkVal(driver._id)) {
    variables.driverId = driver._id
  }
  return variables
}

export const useDocumentsByDriverId = (driver) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DOCUMENT_BY_DRIVER_ID,
    {
      variables: getDocumentVariables(driver),
      queryName: 'documentsByDriverId',
      errorMessage: 'Failed to fetch Document',
      dataTransform: (data) => documentDataTransform({ data }),
      skip: !driver
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
