import React from 'react'
import { MainLayout } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import EventQueue from './EventQueue'
import Lessons from './Lessons'
import { selectedDriverId, selectedTabs } from '../../Graphql/GraphQLClient'
import TabRoutes from '../../Components/TabRoutes'
import { useReactiveVar } from '@apollo/client'
import DriverHeader from '../../Components/Custom/DriverHeader'

const Coaching = (props) => {
  const { coachingTabs: selectedTab } = useReactiveVar(selectedTabs)
  const driverId = useReactiveVar(selectedDriverId)

  return (
    <MainLayout {...props}>
      {checkVal(driverId) && <DriverHeader driverId={driverId} />}
      <TabRoutes
        defaultId={selectedTab ? selectedTab : null}
        onChange={(id) => selectedTabs({ ...selectedTabs(), coachingTabs: id })}
        tabsData={[
          { id: 'event-queue', name: 'Event Queue' },
          { id: 'lessons', name: 'Lessons' }
        ]}
      >
        <EventQueue {...props} />
        <Lessons {...props} />
      </TabRoutes>
    </MainLayout>
  )
}
export default Coaching
