import React from 'react'
import { Metrics, useColors } from '../../../Themes'
import { FlexBox, Text } from '../../../Components'
import { Display } from '../../../Components/Typography'

const AssetTotalsTabButton = ({ data, loading, selected }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        borderRadius: Metrics.radius,
        border: selected
          ? 'none'
          : `1px solid ${Colors.selectHighlightOpacity3}`,
        padding: Metrics.base * 2,
        alignItems: 'flex-start'
      }}
    >
      <FlexBox
        direction='row'
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingBottom: Metrics.base * 2
        }}
      >
        <FlexBox direction='column' style={{ alignItems: 'flex-start' }}>
          <Display
            color='white'
            loading={loading}
            loadingBarWidth={100}
            loadingBarHeight={65}
            loadingBackgroundColor={Colors.selectHighlightOpacity4}
            loadingBarColor={Colors.selectHighlightOpacity4}
            Colors={Colors}
            style={{
              fontSize: 70
            }}
          >
            {data && data.assetsCount}
          </Display>

          <Text
            color={Colors.background}
            style={{
              fontSize: 20,
              alignSelf: 'left'
            }}
          >
            Assets
          </Text>
        </FlexBox>
        <FlexBox direction='column' style={{ alignItems: 'flex-start' }}>
          <Text
            color='#A7FFBF'
            loading={loading}
            loadingBarWidth={30}
            loadingBarHeight={24}
            loadingBackgroundColor={Colors.selectHighlightOpacity4}
            loadingBarColor={Colors.selectHighlightOpacity4}
            style={{
              fontSize: 26,
              paddingBottom: Metrics.base
            }}
          >
            {data && data.drivingNowCount ? data.drivingNowCount : 0}
          </Text>
          <Text
            color={Colors.background}
            style={{
              fontSize: 14
            }}
          >
            Driving Now
          </Text>
        </FlexBox>
      </FlexBox>
      <divs
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.selectHighlightOpacity3}`
        }}
      ></divs>
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          paddingTop: Metrics.base * 2,
          paddingLeft: Metrics.base
        }}
      >
        <Text
          color='white'
          loading={loading}
          loadingBarWidth={30}
          loadingBarHeight={20}
          loadingBackgroundColor={Colors.selectHighlightOpacity4}
          loadingBarColor={Colors.selectHighlightOpacity4}
          style={{
            fontSize: 22,
            paddingBottom: Metrics.base
          }}
        >
          {data && data.linkedAssetsCount}
        </Text>

        <Text
          color={Colors.background}
          style={{
            fontSize: 14
          }}
        >
          Linked to Devices
        </Text>
      </FlexBox>
    </FlexBox>
  )
}

export default AssetTotalsTabButton
