import React, { useState, useEffect } from 'react'
import { FlexBox, Button, Text, Subsection, Form } from '../../../Components'
import { useReactiveVar } from '@apollo/client'
import { selectedAssetId } from '../../../Graphql/GraphQLClient'
import { Metrics, useColors } from '../../../Themes'
import { useCrashByAssetId } from '../../../Graphql/Queries/useCrashByAssetId'
import CardsContainer from '../../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import CardsList from '../../../Components/Custom/SelectionPanel/Cards/CardsList'
import CrashCard from '../../../Components/Custom/SelectionPanel/CrashCard.js'
import { checkVal, formatDateTime } from '../../../Helpers/Functions'
import NoneSelected from '../../../Components/Custom/NoneSelected'

const getFilterCount = (filters) => {
  let filterCount = 0
  Object.keys(filters).map((key) => {
    if (checkVal(filters[key]) && key !== 'assetId') {
      filterCount += 1
    }
  })
  return filterCount
}
const CrashTimeline = ({ Colors, crashIds, crashesLoading, history }) => {
  return (
    <FlexBox
      direction='row'
      fillSpace
      style={{
        width: '100%',
        marginLeft: Metrics.base * 2,
        justifyContent: 'flex-start',
        alignItems: 'stretch'
      }}
    >
      <FlexBox
        style={{ width: 1, backgroundColor: Colors.dividerColor }}
      ></FlexBox>
      <FlexBox
        style={{
          width: '90%',
          paddingBottom: Metrics.base * 4,
          paddingTop: Metrics.base * 2
        }}
      >
        <CardsContainer
          innerContainerStyle={{
            borderTop: 'none',
            borderBottom: 'none',
            backgroundColor: 'none'
          }}
          loading={crashesLoading}
        >
          <CardsList items={crashIds} itemsName='Crashes'>
            {({ items, item, index }) => (
              <>
                <FlexBox
                  direction='column'
                  style={{ alignItems: 'flex-start', width: '100%' }}
                >
                  <FlexBox
                    direction='row'
                    style={{ width: '100%', marginTop: Metrics.base * 3 }}
                  >
                    <div
                      style={{
                        width: 30,
                        height: 1,
                        backgroundColor: Colors.dividerColor
                      }}
                    ></div>
                    <CrashCard
                      crashId={item._id}
                      index={index}
                      key={index}
                      selected={true}
                      noBoxShadow
                      backgroundColor={Colors.subsectionBackground}
                      borderBottom={
                        index + 1 >= items.length || items[index + 1]
                      }
                    />
                    <FlexBox
                      direction='column'
                      style={{ alignItems: 'flex-start', width: 300 }}
                    >
                      <Button
                        title='View In Detail'
                        textColor='white'
                        background='red'
                        onClick={() =>
                          history.push({
                            pathname: '/view-crash',
                            search: `?id=${item._id}`
                            // state: { rowData: history.location.state.rowData }
                          })
                        }
                        style={{ marginBottom: Metrics.base }}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </>
            )}
          </CardsList>
        </CardsContainer>
      </FlexBox>
    </FlexBox>
  )
}
const CrashHistory = (props) => {
  const assetId = useReactiveVar(selectedAssetId)
  const Colors = useColors(useColors)
  const [crashFilters, setCrashFilters] = useState({})
  const { data: crashes, loading: crashesLoading } =
    useCrashByAssetId(crashFilters)

  useEffect(() => {
    if (assetId) {
      setCrashFilters({ ...crashFilters, assetId })
    }
  }, [assetId])

  const filterCrashSchema = [
    {
      name: 'dateRange',
      type: 'dateBetween',
      label: 'Date range',
      style: { width: '20%', alignSelf: 'left', marginRight: Metrics.base * 3 },
      value: {
        startDate: crashFilters.startDate ? crashFilters.startDate : null,
        endDate: crashFilters.endDate ? crashFilters.endDate : null
      }, // filters.startTime,
      controlled: true,
      handleChange: (value) => {
        setCrashFilters({
          ...crashFilters,
          startDate: value.startDate,
          endDate: value.endDate
        })
      }
    }
  ]

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: '100%',
        padding: Metrics.base * 2
      }}
    >
      {assetId ? (
        <FlexBox
          fillSpace
          direction='column'
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Subsection
            style={{
              width: '100%',
              // paddingBottom: Metrics.base * 2,
              justifyContent: 'flex-start',
              alignItems: 'flex-start'
            }}
          >
            <Text
              font='subheading'
              style={{
                textAlign: 'left',
                paddingLeft: Metrics.base * 2,
                paddingRight: Metrics.base * 2,
                paddingTop: Metrics.base * 1
              }}
            >
              Filter Crashes By:
            </Text>
            <Form
              hideSubmit
              formbox={false}
              style={{
                backgroundColor: 'none',
                boxShadow: 'none',
                width: '100%'
              }}
              innerFormStyles={{
                paddingBottom: 0,
                paddingLeft: Metrics.base * 2,
                justifyContent: 'flex-start'
              }}
              hideFormBox
              darkmode={true}
              direction='row'
              data={filterCrashSchema}
            />
            <FlexBox direction='row' style={{ paddingLeft: Metrics.base * 2 }}>
              <Button
                condensed
                title='Clear Filters'
                textColor='white'
                background='red'
                onClick={() => {
                  setCrashFilters({ assetId: crashFilters.assetId })
                }}
                style={{
                  marginBottom: Metrics.base
                }}
              />
              <Text
                font='body'
                color={Colors.primary}
                style={{
                  paddingBottom: Metrics.base * 1,
                  paddingLeft: Metrics.base * 2
                }}
              >
                Filters Active: {getFilterCount(crashFilters)}
              </Text>
            </FlexBox>
          </Subsection>

          {crashes && crashes.length > 0 ? (
            <CrashTimeline
              Colors={Colors}
              crashIds={crashes}
              crashesLoading={crashesLoading}
              history={props.history}
              style={{ width: '100%' }}
            />
          ) : (
            <FlexBox
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Text
                font='heading'
                style={{
                  textAlign: 'left',
                  paddingTop: Metrics.base * 5
                }}
              >
                No Crashes Found
              </Text>
            </FlexBox>
          )}
        </FlexBox>
      ) : (
        <NoneSelected helperText='Please select an asset from the list on the left' />
      )}
    </FlexBox>
  )
}
export default CrashHistory
