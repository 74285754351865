import { Metrics } from '../../Themes'

export const inlineStyles = {
  mainContainer: {
    minHeight: Metrics.base * 20
  },
  icon: {
    fontSize: Metrics.base * 6
  }
}
