import { useReactiveVar } from '@apollo/client'
import React from 'react'
import {
  FlexBox,
  InfoBadge,
  ProcessingSpinner,
  Status,
  Subsection,
  Text
} from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import SubsectionDivider from '../../../Components/Custom/SubsectionDivider'
import LargeDivider from '../../../Components/LargeDivider'
import {
  Display,
  Heading,
  Subheading,
  Title,
  Title2
} from '../../../Components/Typography'
import { selectedTripId } from '../../../Graphql/GraphQLClient'
import { useEventsByTripId } from '../../../Graphql/Queries/Event/useEventsByTripId'
import { useTrip } from '../../../Graphql/Queries/Trip/useTrip'
import { useTripSafetyByTripId } from '../../../Graphql/Queries/Trip/useTripSafetyByTripId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import {
  checkVal,
  hhmmss,
  thousandsSeparator
} from '../../../Helpers/Functions'
import { Metrics, useColors } from '../../../Themes'
import { getScoreCategory } from '../Asset/Breakdown'
import { DescriptionContent } from './Breakdown'

export const CategoryScore = ({ score, category, hideBorderRight }) => {
  const Colors = useColors()
  return (
    <FlexBox
      fillSpace
      style={{
        borderRight: hideBorderRight
          ? 'none'
          : `1px solid ${Colors.dividerColor}`,
        minHeight: 120,
        maxHeight: 300
      }}
    >
      <FlexBox
        direction='column'
        style={{
          alignItems: 'flex-start',
          padding: Metrics.base * 2
        }}
      >
        <Heading>{checkVal(score) ? `${Math.round(score)}/100` : '-'}</Heading>
        <Title2 color={Colors.textSecondary} marginTop={1}>
          {category}
        </Title2>
      </FlexBox>
      <ScoreCircleChart
        score={checkVal(score) ? score : null}
        maxScore={100}
        color={Colors[getScoreCategory(score)]}
        backgroundColor={Colors.secondary}
        size={80}
      />
    </FlexBox>
  )
}

export const ScoreExplanation = ({ title, name = 'trips' }) => {
  const Colors = useColors()
  return (
    <>
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          paddingLeft: Metrics.base * 5,
          paddingRight: Metrics.base * 5
        }}
      >
        <Text marginTop={3}>{title}</Text>
      </FlexBox>
      <FlexBox
        style={{
          flexDirection: 'row',
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        <FlexBox style={{ flex: 1 }}>
          <FlexBox
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              padding: Metrics.base * 2
            }}
          >
            <Text color={Colors.secondaryOpacity1}>
              <ul>
                <li>
                  <FlexBox
                    direction='row'
                    style={{
                      justifyContent: 'flex-start',
                      marginBottom: Metrics.base / 2
                    }}
                  >
                    <Status
                      style={{ marginRight: Metrics.base }}
                      status={'HIGH_RISK'}
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    describes {name} with a score under 50.
                  </FlexBox>
                </li>
                <li>
                  <FlexBox
                    direction='row'
                    style={{
                      justifyContent: 'flex-start',
                      marginBottom: Metrics.base / 2
                    }}
                  >
                    <Status
                      style={{ marginRight: Metrics.base }}
                      status={'MODERATE_RISK'}
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    describes {name} with a score between 50 and 60.
                  </FlexBox>
                </li>
                <li>
                  <FlexBox
                    direction='row'
                    style={{ justifyContent: 'flex-start' }}
                  >
                    <Status
                      style={{ marginRight: Metrics.base }}
                      status={'ADEQUATE'}
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    describes {name} with a score between 60 and 75.
                  </FlexBox>
                </li>
              </ul>
            </Text>
          </FlexBox>
          {/*<DescriptionContent
    points={[
      `${(
        <Status
          status={'HIGH_RISK'}
          getStatusColors={({ status }) => Colors[status]}
        />
      )} Trips are trips with a score under 20.`,
      `Moderate Risk Trips are trips with a score between 20 and 40.`,
      `Adequate Trips are trips with a score between 40 and 60.`
    ]}
  />*/}
        </FlexBox>
        <FlexBox style={{ flex: 1 }}>
          <FlexBox
            style={{
              width: '100%',
              justifyContent: 'flex-start',
              padding: Metrics.base * 2
            }}
          >
            <Text color={Colors.secondaryOpacity1}>
              <ul>
                <li>
                  <FlexBox
                    direction='row'
                    style={{
                      justifyContent: 'flex-start',
                      marginBottom: Metrics.base / 2
                    }}
                  >
                    <Status
                      style={{ marginRight: Metrics.base }}
                      status={'SAFE'}
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    describes {name} with a score between 75 and 90.
                  </FlexBox>
                </li>
                <li>
                  <FlexBox
                    direction='row'
                    style={{
                      justifyContent: 'flex-start',
                      marginBottom: Metrics.base / 2
                    }}
                  >
                    <Status
                      style={{ marginRight: Metrics.base }}
                      status={'EXCELLENT'}
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    describes {name} with a score above 90.
                  </FlexBox>
                </li>
              </ul>
            </Text>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  )
}

const TripOverview = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const tripId = useReactiveVar(selectedTripId)
  const { data: trip, loading: tripLoading } = useTrip(tripId)
  const { tripDistance, travelTime, drivingTime } = useEventsByTripId(tripId)
  const {
    data: tripSafetyData,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    loading: tripSafetyLoading
  } = useTripSafetyByTripId(tripId)
  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: mainContainerWidth - Metrics.base * 4
      }}
    >
      <Subsection
        style={{
          width: '100%',
          height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(tripId) ? (
          tripSafetyData ? (
            <>
              <FlexBox
                style={{
                  justifyContent: 'space-between',
                  paddingLeft: Metrics.base * 2,
                  paddingTop: Metrics.base * 2,
                  paddingBottom: Metrics.base * 2,
                  width: '100%',
                  minHeight: 160,
                  borderBottom: `1px solid ${Colors.dividerColor}`
                  // backgroundColor: 'red'
                }}
              >
                <FlexBox>
                  <FlexBox
                    direction='column'
                    style={{
                      alignItems: 'flex-start',
                      padding: Metrics.base * 2,
                      marginRight: Metrics.base * 2
                    }}
                  >
                    <Status
                      status={
                        tripSafetyData && tripSafetyData.tripSafetyCategory
                      }
                      getStatusColors={({ status }) => Colors[status]}
                    />
                    <Display marginTop={1}>
                      {combinedScore ? `${Math.round(combinedScore)}/100` : '-'}
                    </Display>
                    <Subheading color={Colors.textSecondary} marginTop={1}>
                      Combined Score
                    </Subheading>
                  </FlexBox>
                  <ScoreCircleChart
                    score={checkVal(combinedScore) ? combinedScore : null}
                    maxScore={100}
                    color={Colors[getScoreCategory(combinedScore)]}
                    backgroundColor={Colors.secondary}
                    size={110}
                  />
                </FlexBox>
                {tripLoading ? (
                  <FlexBox style={{ width: 500 }}>
                    <ProcessingSpinner size={40} />
                  </FlexBox>
                ) : (
                  <FlexBox direction='row' style={{ minWidth: 650 }}>
                    {tripDistance ? (
                      <InfoBadge
                        size='medium'
                        value={`${thousandsSeparator(
                          tripDistance.totalDistance / 1000
                        )} km`}
                        label={tripDistance.name}
                        status={tripDistance.severityCategoryDisplayText}
                        statusColor={Colors[tripDistance.severityCategory]}
                      />
                    ) : checkVal(trip.totalDistance) ? (
                      <InfoBadge
                        size='medium'
                        value={`${thousandsSeparator(
                          trip.totalDistance / 1000
                        )} km`}
                        label={'Total Distance'}
                      />
                    ) : (
                      ''
                    )}
                    {travelTime ? (
                      <InfoBadge
                        size='small'
                        value={hhmmss(travelTime.totalTravelTime)}
                        label={travelTime.name}
                        status={travelTime.severityCategoryDisplayText}
                        statusColor={Colors[travelTime.severityCategory]}
                      />
                    ) : checkVal(trip.totalTravelTime) ? (
                      <InfoBadge
                        size='small'
                        value={hhmmss(trip.totalTravelTime)}
                        label={'Total Travel Time'}
                      />
                    ) : (
                      ''
                    )}
                    {drivingTime ? (
                      <InfoBadge
                        size='small'
                        value={hhmmss(drivingTime.totalTomTomTravelTime)}
                        label={'Estimated driving time'}
                        status={drivingTime.severityCategoryDisplayText}
                        statusColor={Colors[drivingTime.severityCategory]}
                      />
                    ) : trip.totalTomTomTravelTime ? (
                      <InfoBadge
                        size='small'
                        value={hhmmss(trip.totalTomTomTravelTime)}
                        label={'Estimated driving time'}
                      />
                    ) : (
                      ''
                    )}
                    {false && trip.totalTomTomTravelTime ? (
                      <InfoBadge
                        size='small'
                        value={hhmmss(trip.totalTomTomTravelTime)}
                        label={'Estimated driving time'}
                      />
                    ) : (
                      ''
                    )}
                  </FlexBox>
                )}
              </FlexBox>
              <ScoreExplanation title='The Combined Trip Safety Score is the overall indicator of trip safety.' />
              <FlexBox
                style={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  paddingLeft: Metrics.base * 5,
                  paddingRight: Metrics.base * 5,
                  paddingBottom: Metrics.base * 3
                }}
              >
                <Text marginTop={3}>
                  The Combined Trip Safety Score is a weighted sum of 5 core
                  trip safety categories:
                </Text>
              </FlexBox>
              <FlexBox
                style={{
                  width: '100%',
                  borderTop: `1px solid ${Colors.dividerColor}`,
                  borderBottom: `1px solid ${Colors.dividerColor}`
                }}
              >
                <FlexBox style={{ flex: 1 }}>
                  <CategoryScore
                    score={speedingScore}
                    category='Speeding Score'
                  />
                </FlexBox>
                <FlexBox style={{ flex: 1 }}>
                  <CategoryScore
                    score={smoothDrivingScore}
                    category='Smooth Driving Score'
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox
                style={{
                  width: '100%',
                  borderBottom: `1px solid ${Colors.dividerColor}`
                }}
              >
                <FlexBox style={{ flex: 1 }}>
                  <CategoryScore
                    score={restedFocusedScore}
                    category='Rested/Focused Score'
                  />
                </FlexBox>
                <FlexBox style={{ flex: 1 }}>
                  <CategoryScore
                    score={compliantScore}
                    category='Compliant Score'
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox
                style={{
                  width: '100%',
                  borderBottom: `1px solid ${Colors.dividerColor}`
                }}
              >
                <CategoryScore
                  score={contextualScore}
                  category='Contextual Score'
                />
              </FlexBox>
            </>
          ) : (
            <NoneSelected
              loading={tripSafetyLoading}
              height={
                mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
              }
              displayText='No Score'
              helperText='This trip has not yet been scored'
            />
          )
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select a trip from the list on the left'
          />
        )}
      </Subsection>
    </FlexBox>
  )
}

export default TripOverview
