import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Col extends Component {
  render () {
    const { xs, sm, md, lg, xsOffset, smOffset, mdOffset, lgOffset, first, last, noPadding, children, style } = this.props
    const classNames = `
      ${xs ? `col-xs${xs.length ? `-${xs}` : ''}` : 'col-xs-12'}
      ${sm ? `col-sm${sm.length ? `-${sm}` : ''}` : ''}
      ${md ? `col-md${md.length ? `-${md}` : ''}` : ''}
      ${lg ? `col-lg${lg.length ? `-${lg}` : ''}` : ''}
      ${xsOffset ? `col-xs-offset-${xsOffset}` : ''}
      ${smOffset ? `col-sm-offset-${smOffset}` : ''}
      ${mdOffset ? `col-md-offset-${mdOffset}` : ''}
      ${lgOffset ? `col-lg-offset-${lgOffset}` : ''}
      ${first ? `first-${first}` : ''}
      ${last ? `last-${last}` : ''}
    `

    return (
      <div className={classNames.replace(/(\n)/g, '').trim()} style={{padding: noPadding ? 0 : null, ...style}}>
        {children}
      </div>
    )
  }
}

Col.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object
  ])
}
