import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { avgAndCoordinatesFragment } from './useTotalDistances'
import { abbreviateNumber } from '../../../Helpers/Functions'

export const GET_TIME_DRIVEN = gql`
  query timeDriven(
    $period: String
    $maxTicks: Int
    $orgIdsFilter: [String]
    $assetGroupId: String
  ) {
    timeDriven(
      period: $period
      maxTicks: $maxTicks
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
    ) {
      ...avgAndCoordinatesFragment
    }
  }
  ${avgAndCoordinatesFragment}
`

const dataTransform = ({ data }) => {
  return (
    data && {
      average: data.average,
      coordinates:
        data.coordinates &&
        data.coordinates.map((a) => ({ x: abbreviateNumber(a.x), y: a.y }))
    }
  )
}

export const useTimeDriven = ({ period }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(GET_TIME_DRIVEN, {
    variables: {
      period,
      maxTicks: 20,
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      assetGroupId: groupId
    },
    queryName: 'timeDriven',
    errorMessage: 'Failed to fetch time driven',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
