import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const handleNavigation = ({
  pathname,
  queryParams,
  clearQueryParams,
  history,
  urlSearchParams
}) => {
  if (queryParams && queryParams.length > 0) {
    for (var i = 0; i < queryParams.length; i++) {
      urlSearchParams.set([queryParams[i].name], [queryParams[i].id])
    }
  }
  if (pathname) {
    history.push({
      pathname,
      search: clearQueryParams ? '' : urlSearchParams.toString()
    })
  } else {
    history.push({
      search: urlSearchParams.toString()
    })
  }
}

export const useNavigation = () => {
  const [navigateTo, setNavigateTo] = useState({ function: () => {} })
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    setNavigateTo({
      function: ({ pathname, queryParams, clearQueryParams = false }) =>
        handleNavigation({
          pathname,
          queryParams,
          clearQueryParams,
          history,
          urlSearchParams
        })
    })
  }, [history, location])

  return navigateTo.function
}
