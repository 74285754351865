import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import {
  alarmDataTransform,
  alarmFragment,
  GET_ALARM_SUBSCRIPTION
} from '../Subscriptions/useAlarmsSubscription'

export const GET_ALARMS_BY_DEVICE_ID = gql`
  query alarmsByDeviceId($internalDeviceId: String, $skip: Int, $limit: Int) {
    alarmsByDeviceId(
      internalDeviceId: $internalDeviceId
      skip: $skip
      limit: $limit
    ) {
      ...alarmFragment
    }
  }
  ${alarmFragment}
`

const dataTransform = ({ data }) => {
  return data.map((a) => alarmDataTransform({ data: a }))
}

export const useAlarmsByDeviceId = (internalDeviceId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    subscribeToMore
  } = useQuery(GET_ALARMS_BY_DEVICE_ID, {
    variables: { internalDeviceId, skip: 0, limit: 50 },
    // pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    queryName: 'alarmsByDeviceId',
    errorMessage: 'Failed to fetch alarmsByDeviceId',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    let unsubscribe = () => {}
    unsubscribe = subscribeToMore({
      document: GET_ALARM_SUBSCRIPTION,
      variables: { internalDeviceId },
      // skip: !deviceId,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newItem = subscriptionData.data['alarmUpdated']
        const newData = [...prev['alarmsByDeviceId']]
        let mergedData = { alarmsByDeviceId: [...newData, { ...newItem }] }
        return mergedData
      }
    })
    return () => unsubscribe()
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
