import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { assetDataTransform, assetFragment } from './useAsset'
import gql from 'graphql-tag'
import { useFilters } from '../useFilters'
import { checkVal } from '../../../Helpers/Functions'
import { getAssetsVariables } from './useAssetIds'
import { useReactiveVar } from '@apollo/client'
import { assetFilters, filters } from '../../GraphQLClient'

export const GET_ASSETS = gql`
  query assetsPaginated(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $assetType: [String]
    $skip: Int
    $limit: Int
    $searchString: String
    $safetyCategory: [String]
  ) {
    assetsPaginated(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      assetType: $assetType
      skip: $skip
      limit: $limit
      searchString: $searchString
      safetyCategory: $safetyCategory
    ) {
      assets {
        ...assetFragment
      }
      totalCount
    }
  }
  ${assetFragment}
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data: data.assets && data.assets.map((a) => assetDataTransform({ data: a }))
  }
}

export const useAssets = ({ orgId, groupId, skip, limit }) => {
  const { groupId: filtersGroupId, orgIds } = useReactiveVar(filters)
  const { assetTypes, searchString, safetyCategories } =
    useReactiveVar(assetFilters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_ASSETS, {
    variables: getAssetsVariables({
      orgId,
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId: groupId ? groupId : filtersGroupId,
      searchString,
      assetTypes,
      safetyCategory: safetyCategories,
      skip,
      limit
    }),
    queryName: 'assetsPaginated',
    errorMessage: 'Failed to fetch Assets',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, loading, totalCount, fetchMore, networkStatus }
}
