import { useD3 } from '../CustomHooks/useD3'
import React, { useState } from 'react'
import * as d3 from 'd3'
import { Metrics, useColors, Utils } from '../../Themes'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'
import { Tooltip, withStyles } from '@material-ui/core'
import { css } from 'aphrodite'
import { Styles } from './Styles'
import { abbreviateNumber } from '../../Helpers/Functions'
import { Text } from '..'
import DataCard from './DataCard'
import { ErrorMessage } from '../Typography'

const DotComponent = ({
  id = 'test',
  data,
  StyledTooltip,
  Colors,
  color,
  color1,
  color2,
  showPoints,
  units,
  yAxisLabel,
  xAccessor,
  yAccessor
}) => {
  return (
    <StyledTooltip
      key={id}
      interactive
      title={
        data && (
          <DataCard
            value={data[yAccessor]}
            size='small'
            hideColorBar
            units={units}
            description={yAxisLabel}
            title={data[xAccessor] && data[xAccessor].toLocaleString()}
            style={{ height: 120, boxShadow: 'none' }}
          />
        )
      }
      placement='top'
    >
      <g style={{ cursor: 'pointer' }}>
        {showPoints && (
          <circle
            style={{
              fill: 'white',
              stroke: color1 ? color1 : color ? color : Colors.primaryOpacity2
            }}
            className={'dot-outer-test'}
            onClick={() => {}}
          />
        )}
        {showPoints && (
          <circle
            style={{ fill: color2 ? color2 : color ? color : Colors.primary }}
            className={'dot-inner'}
          />
        )}
      </g>
    </StyledTooltip>
  )
}

const drawData = ({ svg, data, x, y, xAccessor, yAccessor, showPoints }) => {
  if (showPoints) {
    svg
      .selectAll('.dot-outer-test')
      .data(data)
      .attr('cx', (d) => x(d[xAccessor]))
      .attr('cy', (d) => y(d[yAccessor]))
      .attr('r', 5)

    svg
      .selectAll('.dot-inner')
      .data(data)
      .attr('cx', (d) => x(d[xAccessor]))
      .attr('cy', (d) => y(d[yAccessor]))
      .attr('r', 3)
  }

  svg
    .select('.data-path')
    .datum(data)
    .attr(
      'd',
      d3
        .line()
        .x((d) => x(d[xAccessor]))
        .y((d) => y(d[yAccessor]))
    )

  const curve = d3.curveLinear
  const area = d3
    .area()
    .curve(curve)
    .x((d) => x(d[xAccessor]))
    .y0(y(0))
    .y1((d) => y(d[yAccessor]))

  svg.select('.area-path').datum(data).attr('d', area)
}

export const createAxes = ({
  svg,
  data,
  width,
  height,
  plotAreaWidth,
  margin,
  maxYValue,
  startDate,
  endDate,
  Colors,
  xAccessor,
  yAccessor
}) => {
  const y = d3
    .scaleLinear()
    .domain([0, maxYValue ? maxYValue : d3.max(data, (d) => d[yAccessor])])
    .nice()
    .range([height - margin.bottom, margin.top])
  const x = d3
    .scaleTime()
    .domain([
      startDate ? startDate : d3.min(data, (d) => d[xAccessor]),
      endDate ? endDate : d3.max(data, (d) => d[xAccessor])
    ])
    .range([margin.left, width - margin.right])

  const xAxis = svg
    .select('.x-axis')
    .attr('transform', `translate(0,${height - margin.bottom})`)
    .attr('color', Colors.secondaryOpacity1)
    .call(
      d3
        .axisBottom(x)
        .ticks(plotAreaWidth / 80)
        .tickSizeOuter(0)
    )

  const yAxis = svg
    .select('.y-axis')
    .attr('transform', `translate(${margin.left},0)`)
    .attr('color', Colors.secondaryOpacity1)
    .call(d3.axisLeft(y).tickFormat((d) => abbreviateNumber(d))) // .ticks(null, '~s'))
    .call((g) => g.select('.domain').remove())

  return { xAxis, yAxis, x, y }
}

export const createRulers = ({ svg, Colors, margin, width, height }) => {
  const ruleX = svg
    .select('.rule-x')
    .attr('stroke', Colors.secondaryOpacity4)
    .attr('stroke-dasharray', 5)
    .attr('y1', margin.top - 6)
    .attr('y2', height - margin.bottom - 1)
    .attr('x1', -1)
    .attr('x2', -1)

  const ruleY = svg
    .select('.rule-y')
    .attr('stroke', Colors.secondaryOpacity4)
    .attr('stroke-dasharray', 5)
    .attr('y1', -1)
    .attr('y2', -1)
    .attr('x1', margin.left - 6)
    .attr('x2', width - margin.right - 1)

  svg.on('mousemove touchmove', (event) => {
    const x = d3.pointer(event, svg.node())[0] + 0.5
    const y = d3.pointer(event, svg.node())[1] + 0.5
    if (x > margin.left && x < width - margin.right)
      ruleX.attr('x1', x).attr('x2', x)
    if (y > margin.top && y < height - margin.bottom)
      ruleY.attr('y1', y).attr('y2', y)
  })
}

export const addGradient = ({
  svg,
  data,
  x,
  y,
  maxYValue,
  id,
  Colors,
  gradientColors,
  gradientColor1,
  gradientColor2
}) => {
  // set the gradient
  svg
    .select(`.linearGradient-${id}`)
    .attr('id', `area-gradient-${id}`)
    .attr('gradientUnits', 'userSpaceOnUse')
    .attr('x1', 0)
    .attr('y1', y(0))
    .attr('x2', 0)
    .attr('y2', y(maxYValue ? maxYValue : d3.max(data, (d) => d.value)))
    .selectAll('stop')
    .data([
      {
        offset: '0%',
        color: gradientColors
          ? gradientColors[0]
          : gradientColor1
          ? gradientColor1
          : Colors.primaryOpacity5
      },
      {
        offset: '100%',
        color: gradientColors
          ? gradientColors[1]
          : gradientColor2
          ? gradientColor2
          : Colors.primaryOpacity4
      }
    ])
    .enter()
    .append('stop')
    .attr('offset', function (d) {
      return d.offset
    })
    .attr('stop-color', function (d) {
      return d.color
    })
}

export const addZoom = ({
  svg,
  redrawData,
  margin = { top: 0, left: 0 },
  extent,
  scaleExtent,
  translateExtent,
  xAxis,
  yAxis,
  x,
  y
}) => {
  var zoom = d3
    .zoom()
    .scaleExtent([scaleExtent.min, scaleExtent.max])
    .translateExtent([
      [margin.left, margin.top],
      [extent.x, extent.y]
    ])
    .extent([
      [margin.left, margin.top],
      [extent.x, extent.y]
    ])
    .on('zoom', updateChart)
  svg.call(zoom)
  function updateChart() {
    var transform = d3.zoomTransform(this)
    var newX = transform.rescaleX(x)
    var newY = transform.rescaleY(y)
    xAxis.call(d3.axisBottom(newX))
    yAxis.call(d3.axisLeft(newY))
    redrawData(newX, newY)
  }
}

const RunChart = ({
  id,
  data = [],
  startDate,
  endDate,
  maxYValue,
  chartWidth = 300,
  chartHeight = 250,
  loading,
  color,
  color1,
  color2,
  gradientColors,
  gradientColor1,
  gradientColor2,
  showPoints = true,
  units,
  yAxisLabel,
  xAccessor = 'x',
  yAccessor = 'y'
}) => {
  const Colors = useColors()
  const [StyledTooltip] = useState(
    withStyles((theme) => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  const margin = { top: 15, right: 10, bottom: 20, left: 40 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const dataString = JSON.stringify(data)
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth
      const { xAxis, yAxis, x, y } = createAxes({
        svg,
        data,
        width,
        height,
        plotAreaWidth,
        margin,
        maxYValue,
        startDate,
        endDate,
        Colors,
        xAccessor,
        yAccessor
      })
      createRulers({ svg, Colors, margin, width, height })
      addGradient({
        svg,
        data,
        x,
        y,
        maxYValue,
        id,
        Colors,
        gradientColors,
        gradientColor1,
        gradientColor2
      })
      drawData({ svg, data, x, y, showPoints, xAccessor, yAccessor })
      addZoom({
        svg,
        redrawData: (newX, newY) =>
          drawData({
            svg,
            data,
            x: newX,
            y: newY,
            showPoints,
            xAccessor,
            yAccessor
          }),
        extent: { x: plotAreaWidth, y: plotAreaHeight },
        scaleExtent: { min: 0.5, max: 20 },
        xAxis,
        yAxis,
        x,
        y,
        showPoints
      })
    },
    [dataString, chartWidth, chartHeight, startDate, endDate, showPoints]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        key={`${id}_svg`}
        ref={ref}
        style={{
          height: chartHeight,
          width: chartWidth,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <g className='x-axis' />
        <g className='y-axis' />
        <line
          x1={plotAreaX}
          y1={plotAreaY + plotAreaHeight}
          x2={plotAreaX + plotAreaWidth}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <line
          x1={plotAreaX}
          y1={plotAreaY}
          x2={plotAreaX}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <clipPath id={`${id}_clip_path_${chartWidth}_${chartHeight}`}>
          <rect
            x={plotAreaX}
            y={plotAreaY}
            width={plotAreaWidth}
            height={plotAreaHeight}
          />
        </clipPath>
        <g
          className={css(Styles({}).plotArea)}
          style={{
            clipPath: `url(#${id}_clip_path_${chartWidth}_${chartHeight})`
          }}
        >
          <rect
            x={plotAreaX}
            y={plotAreaY}
            width={plotAreaWidth}
            height={plotAreaHeight}
            fill={Colors.background}
            stroke={Colors.dividerColor}
          ></rect>
          <line className='rule-x' />
          <line className='rule-y' />
          <path
            key={`${id}_path`}
            className='area-path'
            style={{ fill: `url(#area-gradient-${id})` }}
          />
          <linearGradient key={id} className={`linearGradient-${id}`} />
          <path
            style={{
              fill: 'none',
              stroke: color1 ? color1 : color ? color : Colors.primaryOpacity2,
              strokeWidth: 1
            }}
            className='data-path'
          />
          {data.map((a, i) => (
            <DotComponent
              xAccessor={xAccessor}
              yAccessor={yAccessor}
              key={`${a[xAccessor]}_${a[yAccessor]}_${i}`}
              yAxisLabel={yAxisLabel}
              data={a}
              index={i}
              StyledTooltip={StyledTooltip}
              Colors={Colors}
              color={color}
              color1={color1}
              color2={color2}
              showPoints={showPoints}
              units={units}
            />
          ))}
        </g>
      </svg>
      {loading ? (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      ) : !data || data.length === 0 ? (
        <FlexBox
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
          }}
        >
          <ErrorMessage>Data unavailable</ErrorMessage>
        </FlexBox>
      ) : (
        ''
      )}
    </div>
  )
}

export default RunChart
