import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FlexBox, MaterialInput } from '../../Components'
import Button from '../../Components/Button'
import AssetMultipleSelectionTable from '../../Components/Custom/SelectionPanel/Asset/AssetMultipleSelectionTable'
import {
  Display,
  Heading,
  Subheading,
  Title
} from '../../Components/Typography'
import { filters } from '../../Graphql/GraphQLClient'
import { useAddAssetsToGroup } from '../../Graphql/Mutations/useAddAssetsToGroup'
import { useRemoveAssetsFromGroup } from '../../Graphql/Mutations/useRemoveAssetsFromGroup'
import { useUser } from '../../Graphql/Queries/useUser'
import { checkVal } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'

const assetGroupActionOptions = [
  { value: 'VIEW', label: 'View Assets' },
  { value: 'ADD', label: 'Add Assets' },
  { value: 'REMOVE', label: 'Remove Assets' }
]

const handleUpdateGroupAssets = ({
  groupId,
  assetGroupAction,
  setAssetGroupAction,
  addAssets,
  removeAssets,
  selectedIds
}) => {
  const variables = { id: groupId, assetIds: selectedIds }
  if (assetGroupAction === 'ADD') {
    return addAssets({
      variables,
      onCompleted: () => setAssetGroupAction('VIEW')
    })
  }
  if (assetGroupAction === 'REMOVE') {
    return removeAssets({
      variables,
      onCompleted: () => setAssetGroupAction('VIEW')
    })
  }
}

const AssetGroup = ({ groupId, groupOrgId, groupName, setShowEditModal }) => {
  const Colors = useColors()
  const [assetGroupAction, setAssetGroupAction] = useState('VIEW')
  const [defaultSelected, setDefaultSelected] = useState(true)
  const [selectedIds, setSelectedIds] = useState([])
  const [addAssets, { loading: addAssetsLoading }] = useAddAssetsToGroup()
  const [removeAssets, { loading: removeAssetsLoading }] =
    useRemoveAssetsFromGroup()
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []

  useEffect(() => {
    if (orgOptions && orgOptions.length > 0) {
      filters({ ...filters(), orgId: orgOptions[0]._id })
    }
  }, [orgOptions])
  return (
    <AssetMultipleSelectionTable
      disableOrgFilter={true}
      orgId={assetGroupAction === 'ADD' ? groupOrgId : null}
      groupId={assetGroupAction === 'ADD' ? 'NO_GROUP' : groupId}
      defaultSelected={defaultSelected}
      setSelectedIds={setSelectedIds}
      selectionColor={
        assetGroupAction === 'REMOVE' ? Colors.HIGH_RISK : Colors.primary
      }
      hideSelection={assetGroupAction === 'VIEW' ? true : false}
      renderHeading={(totalCount) => (
        <FlexBox
          style={{
            width: '100%',
            height: Metrics.base * 10,
            padding: Metrics.base * 2,
            justifyContent: 'space-between',
            backgroundColor: Colors.subsectionBackground
          }}
        >
          <FlexBox
            direction='row'
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            <Heading>{groupName}</Heading>
            <FlexBox>
              <Subheading color={Colors.primary} marginRight={4}>
                {checkVal(totalCount)
                  ? `${totalCount} ${
                      assetGroupAction === 'ADD' ? 'Ungrouped' : ''
                    } Assets`
                  : 'Assets'}
              </Subheading>
              {(assetGroupAction === 'ADD' ||
                assetGroupAction === 'REMOVE') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      size='small'
                      checked={defaultSelected}
                      onChange={(event) => {
                        setDefaultSelected(event.target.checked)
                      }}
                      style={{
                        color: Colors.primary
                      }}
                    />
                  }
                  label='Select All'
                />
              )}
            </FlexBox>
            <FlexBox style={{ direction: 'row' }}>
              <MaterialInput
                type='select'
                name='assetGroupAction'
                label={`Action`}
                styles={{
                  outerContainer: {
                    maxWidth: 200
                  }
                }}
                value={assetGroupAction}
                selectValues={assetGroupActionOptions.map(
                  (action) => action.value
                )}
                selectDisplayValues={assetGroupActionOptions.map(
                  (action) => action.label
                )}
                handleChange={(value) => {
                  setAssetGroupAction(value)
                }}
              />
              {assetGroupAction === 'ADD' || assetGroupAction === 'REMOVE' ? (
                <Button
                  primary
                  fetching={addAssetsLoading || removeAssetsLoading}
                  disabled={!selectedIds || selectedIds.length === 0}
                  title={
                    assetGroupAction === 'REMOVE'
                      ? `remove ${selectedIds.length}`
                      : `Add ${selectedIds.length}`
                  }
                  background={
                    assetGroupAction === 'REMOVE'
                      ? Colors.HIGH_RISK
                      : Colors.primary
                  }
                  style={{
                    width: 200,
                    marginLeft: Metrics.base * 2,
                    marginRight: Metrics.base * 2
                  }}
                  bold
                  iconLeft='add'
                  textColor={Colors.background}
                  onClick={() =>
                    handleUpdateGroupAssets({
                      groupId,
                      assetGroupAction,
                      setAssetGroupAction,
                      addAssets,
                      removeAssets,
                      selectedIds
                    })
                  }
                />
              ) : (
                <Button
                  primary
                  title='Edit Details'
                  background={Colors.primary}
                  style={{
                    width: 250,
                    marginLeft: Metrics.base * 2,
                    marginRight: Metrics.base * 2
                  }}
                  bold
                  textColor={Colors.background}
                  onClick={() => setShowEditModal(true)}
                />
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    />
  )
}

export default AssetGroup
