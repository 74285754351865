import gql from 'graphql-tag'

export const PRE_SIGN_DOCUMENT = gql`
  mutation preSignDocument(
    $driverId: String
    $assetId: String
    $inspectionId: String
    $documentType: String
    $filetype: String
  ) {
    preSignDocument(
      driverId: $driverId
      assetId: $assetId
      inspectionId: $inspectionId
      documentType: $documentType
      filetype: $filetype
    ) {
      url
      documentUrl
      filename
    }
  }
`

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument(
    $id: String!
    $driverId: String
    $assetId: String
    $inspectionId: String
    $documentType: String
    $driverLicence: DriverLicenceInput
    $vehicleLicence: VehicleLicenceInput
  ) {
    updateDocument(
      id: $id
      driverId: $driverId
      assetId: $assetId
      inspectionId: $inspectionId
      documentType: $documentType
      driverLicence: $driverLicence
      vehicleLicence: $vehicleLicence
    ) {
      _id
      driver {
        _id
      }
      asset {
        _id
      }
      filename
      documentType
      url
      createdAt
      # updatedAt
    }
  }
`

export const CREATE_DOCUMENT = gql`
  mutation createDocument(
    $driverId: String
    $assetId: String
    $serviceId: String
    $inspectionId: String
    $crashId: String
    $incidentId: String
    $url: String
    $documentType: String!
    $filename: String
    $driverLicence: DriverLicenceInput
    $vehicleLicence: VehicleLicenceInput
    $vehicleService: VehicleServiceInput
    $idInfo: IDInput
    $vehicleIncident: VehicleIncidentInput
    $vehicleCrash: VehicleCrashInput
    $passportInfo: PassportInput
  ) {
    createDocument(
      driverId: $driverId
      assetId: $assetId
      inspectionId: $inspectionId
      serviceId: $serviceId
      crashId: $crashId
      incidentId: $incidentId
      url: $url
      documentType: $documentType
      filename: $filename
      driverLicence: $driverLicence
      vehicleLicence: $vehicleLicence
      vehicleService: $vehicleService
      idInfo: $idInfo
      vehicleIncident: $vehicleIncident
      vehicleCrash: $vehicleCrash
      passportInfo: $passportInfo
    ) {
      _id
      driver {
        _id
      }
      asset {
        _id
      }
      filename
      documentType
      url
      createdAt
      driverLicence {
        countryOfIssue
      }
      vehicleLicence {
        doe
      }
      # updatedAt
    }
  }
`
export const CREATE_DOCUMENTS = gql`
  mutation createDocuments($documents: [DocumentInput]) {
    createDocuments(documents: $documents) {
      _id
      filename
      documentType
      url
      createdAt
      # updatedAt
    }
  }
`
