import React, { useState } from 'react'
import { Images, Metrics, useColors } from '../../../Themes'
import { FlexBox, Text } from '../../../Components'
import { Display, Subheading, Title } from '../../../Components/Typography'
import { useEventsByTripId } from '../../../Graphql/Queries/Event/useEventsByTripId'
import { ResponsivePie } from '@nivo/pie'
import { Checkbox } from '@material-ui/core'
import { useReactiveVar } from '@apollo/client'
import { selectedTripId } from '../../../Graphql/GraphQLClient'

const EventListItem = ({
  title,
  type,
  color,
  count,
  selected,
  setSelected
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      style={{
        width: '100%',
        justifyContent: 'flex-start',
        padding: Metrics.base * 2,
        cursor: 'pointer',
        borderBottom: `1px solid ${Colors.dividerColor}`
      }}
      onClick={() => setSelected(!selected)}
    >
      <Checkbox
        size='small'
        style={{ color: Colors.primary }}
        checked={selected}
        // onChange={(e, value) => setSelected(value)}
      />
      {Images[type] && (
        <img
          width={30}
          style={{ marginLeft: Metrics.base, marginRight: Metrics.base * 2 }}
          alt={`${type}_icon`}
          src={Images[type]}
        ></img>
      )}
      {/*<div
        style={{
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: color,
          marginRight: Metrics.base,
          border: `1px solid ${Colors.dividerColor}`,
          boxShadow: `0px 0px 5px ${color}`
        }}
      ></div>*/}
      <Text color={Colors.secondary}>
        <b>{count}</b> {title}
      </Text>
    </FlexBox>
  )
}

const calcTotalEvents = ({
  selected,
  speeding,
  phoneMotion,
  harshBraking,
  harshAccelerating,
  harshCornering
}) => {
  return (
    (selected.SPEEDING ? speeding.length : 0) +
    (selected.PHONE_MOTION ? phoneMotion.length : 0) +
    (selected.HARSH_BRAKING ? harshBraking.length : 0) +
    (selected.HARSH_ACCELERATION ? harshAccelerating.length : 0) +
    (selected.HARSH_CORNERING ? harshCornering.length : 0)
  )
}

export const EventsSummary = ({ selected, setSelected }) => {
  const Colors = useColors()
  const tripId = useReactiveVar(selectedTripId)
  const {
    data: events,
    speeding,
    phoneMotion,
    harshBraking,
    harshAccelerating,
    harshCornering
  } = useEventsByTripId(tripId)
  const totalEvents = calcTotalEvents({
    selected,
    speeding,
    phoneMotion,
    harshBraking,
    harshAccelerating,
    harshCornering
  })
  return events && events.length > 0 ? (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        width: '100%',
        justifyContent: 'flex-start'
      }}
    >
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginBottom: Metrics.base * 6
        }}
      >
        <EventListItem
          selected={selected.SPEEDING}
          type='SPEEDING'
          setSelected={(value) => setSelected({ ...selected, SPEEDING: value })}
          title='Speeding'
          color={Colors.speeding}
          count={speeding.length}
        />
        <EventListItem
          selected={selected.PHONE_MOTION}
          type='PHONE_MOTION'
          setSelected={(value) =>
            setSelected({ ...selected, PHONE_MOTION: value })
          }
          title='Phone Motion'
          color={Colors.PHONE_MOTION}
          count={phoneMotion ? phoneMotion.length : 0}
        />
        <EventListItem
          selected={selected.HARSH_BRAKING}
          setSelected={(value) =>
            setSelected({ ...selected, HARSH_BRAKING: value })
          }
          title='Harsh Braking'
          type='HARSH_BRAKING'
          color={Colors.harshBraking}
          count={harshBraking.length}
        />
        <EventListItem
          selected={selected.HARSH_ACCELERATION}
          setSelected={(value) =>
            setSelected({ ...selected, HARSH_ACCELERATION: value })
          }
          title='Harsh Acceleration'
          type='HARSH_ACCELERATION'
          color={Colors.harshAccelerating}
          count={harshAccelerating.length}
        />
        <EventListItem
          selected={selected.HARSH_CORNERING}
          setSelected={(value) =>
            setSelected({ ...selected, HARSH_CORNERING: value })
          }
          title='Harsh Cornering'
          type='HARSH_CORNERING'
          color={Colors.harshCornering}
          count={harshCornering.length}
        />
      </FlexBox>
      <div style={{ width: '100%', height: 150 }}>
        <ResponsivePie
          innerRadius={0.8}
          padAngle={0}
          cornerRadius={0}
          // startAngle={-180}
          // endAngle={180}
          enableArcLinkLabels={false}
          enableSlicesLabels={false}
          enableArcLabels={false}
          isInteractive
          data={[
            {
              id: 'speeding',
              label: 'Speeding',
              value: selected.SPEEDING ? speeding.length : 0
            },
            {
              id: 'harshBraking',
              label: 'Harsh Braking',
              value: selected.HARSH_BRAKING ? harshBraking.length : 0
            },
            {
              id: 'harshAccelerating',
              label: 'Harsh Accelerating',
              value: selected.HARSH_ACCELERATION ? harshAccelerating.length : 0
            },
            {
              id: 'harshCornering',
              label: 'Harsh Cornering',
              value: selected.HARSH_CORNERING ? harshCornering.length : 0
            }
          ]}
          margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
          // colors={{ scheme: 'blue_green' }}
          colors={[
            Colors.speeding,
            Colors.harshBraking,
            Colors.harshAccelerating,
            Colors.harshCornering
          ]}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableRadialLabels={false}
        />
      </div>
      <FlexBox direction='column' style={{ padding: Metrics.base * 2 }}>
        <Display>{totalEvents}</Display>
        <Text>Total Events</Text>
      </FlexBox>
    </FlexBox>
  ) : (
    ''
  )
}

const EventDetails = ({ selectedEventTypes, setSelectedEventTypes }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      fillSpace
      style={{
        width: '100%',
        justifyContent: 'flex-start',
        height: '100%'
      }}
    >
      <EventsSummary
        selected={selectedEventTypes}
        setSelected={setSelectedEventTypes}
      />
    </FlexBox>
  )
}

export default EventDetails
