import React, { useEffect } from 'react'
import FlexBox from '../../FlexBox'
import { Images, Metrics, useColors } from '../../../Themes'
import Text from '../../Text'
import { Title } from '../../Typography'
import Status from '../../Status'
import Card, { TooltipMenuItem } from './Cards/Card'
import { selectedIds } from '../../../Graphql/GraphQLClient'
import { hhmmss, formatDateTime } from '../../../Helpers/Functions'
import { useIncident } from '../../../Graphql/Queries/useIncident'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Link } from '@material-ui/core'

const IncidentCard = ({
  incidentId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom
}) => {
  const Colors = useColors()
  const { data: incident, loading: incidentLoading } = useIncident(incidentId)
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const urlSearchParams = new URLSearchParams(location.search)

  return (
    <Card
      data={incident}
      selected={selected}
      onClick={() => [
        selectedIds({
          ...selectedIds(),
          incident: selected ? null : incident.data._id
        })
        //   urlSearchParams.set('event-id', incident.data._id),
        //   history.push({
        //     search: urlSearchParams.toString()
        //   })
      ]}
      TooltipContent={({ setShowTooltip }) => (
        <FlexBox direction='column' style={{ width: 200 }}>
          <TooltipMenuItem
            text='View in detail'
            Colors={Colors}
            onClick={() => {
              urlSearchParams.set('id', incident.data._id)
              history.push({
                pathname: '/view-incident',
                search: urlSearchParams.toString()
              })
            }}
            setShowTooltip={setShowTooltip}
          />
        </FlexBox>
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={incidentLoading}
      titleContent={
        <>
          {/* {incident.data.type && (
            <img
              width={25}
              style={{ marginRight: Metrics.base }}
              alt={`${incident.data.type}_icon`}
              src={Images[incident.data.type]}
            />
          )} */}
          <Title style={{ marginLeft: Metrics.base }} color={Colors.primary}>
            {incident.data &&
              incident.data.vehicleIncidentDate &&
              formatDateTime(new Date(incident.data.vehicleIncidentDate))}
          </Title>
        </>
      }
      bodyContent={
        <Text
          marginBottom={1}
          marginTop={1 / 2}
          style={{ paddingRight: Metrics.base }}
        >
          {incident.data && incident.data.description
            ? 'Description: ' + incident.data.description
            : 'No description given'}{' '}
        </Text>
      }
      topRightContent={
        <Status
          status={
            incident.data && incident.data.latitude
              ? 'Location: ' +
                incident.data.latitude +
                ',' +
                incident.data.longitude
              : 'Location: -'
          }
          getStatusColors={() => 'green'}
        ></Status>
      }
      topRightLoading={incidentLoading}
      //   bottomLeftContent={
      //     incident.data &&
      //     incident.data.incidentDocument &&
      //     incident.data.incidentDocument.url ? (
      //       <Link
      //         underline='always'
      //         color={Colors.primary}
      //         style={{
      //           color: Colors.primary,
      //           marginBottom: Metrics.base * 2,
      //           marginRight: Metrics.base / 2,
      //           marginLeft: Metrics.base / 2
      //         }}
      //         target='_blank'
      //         href={incident.data.incidentDocument.url}
      //       >
      //         <Text color={Colors.primary}>
      //           {incident.data.incidentDocument.filename}
      //         </Text>
      //       </Link>
      //     ) : (
      //       ''
      //     )
      //   }
    />
  )
}

export default IncidentCard
