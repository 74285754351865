import React, { useEffect, useState } from 'react'
import { selectedTabs, filters } from '../../../Graphql/GraphQLClient'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import TabBar from '../../TabBar'
import AddDriverModal from '../AddDriverModal'
import AddAssetModal from '../AddAssetModal'
import AddDeviceModal from '../AddDeviceModal'
import FlexBox from '../../FlexBox'
import { Metrics, useColors } from '../../../Themes'
import DriversList from './Driver/DriversList'
import AssetsList from './Asset/AssetsList'
import DevicesList from './Device/DevicesList'
import { handleSetSelectedTab } from './LiveDriversAndAssets'
import { useHistory, useLocation } from 'react-router-dom'
import { Radio } from '@material-ui/core'
import { Subheading } from '../../Typography'
import TabRoutes from '../../TabRoutes'

export const SettingsSidePanel = ({ selectedSettingsPage, Colors }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <FlexBox
        direction='row'
        style={{
          width: '100%',
          padding: Metrics.base * 2,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          justifyContent: 'flex-start'
        }}
      >
        <Radio
          size='small'
          checked={selectedSettingsPage === 'Admin Settings'}
          onChange={() =>
            selectedTabs({
              ...selectedTabs(),
              settingsPage: 'Admin Settings'
            })
          }
          style={{
            color: Colors.primary,
            marginLeft: -Metrics.base
          }}
        />
        <Subheading>{'Admin Settings'}</Subheading>
      </FlexBox>
      <FlexBox
        direction='row'
        style={{
          width: '100%',
          marginBottom: Metrics.base,
          padding: Metrics.base * 2,
          borderBottom: `1px solid ${Colors.dividerColor}`,
          justifyContent: 'flex-start'
        }}
      >
        <Radio
          size='small'
          checked={selectedSettingsPage === 'Fleet Management'}
          onChange={() =>
            selectedTabs({
              ...selectedTabs(),
              settingsPage: 'Fleet Management'
            })
          }
          style={{
            color: Colors.primary,
            marginLeft: -Metrics.base
          }}
        />
        <Subheading>{'Fleet Management'}</Subheading>
      </FlexBox>
    </FlexBox>
  )
}

const FleetManagementSelectionPanel = () => {
  const { settingsADDTabs: selectedTab } = useSelectedTabs()
  const [showAddDriverModal, setShowAddDriverModal] = useState(false)
  const [showAddAssetModal, setShowAddAssetModal] = useState(false)
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false)
  const Colors = useColors()
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const { settingsPage: selectedSettingsPage } = useSelectedTabs()
  const disabled = selectedSettingsPage === 'Admin Settings'

  useEffect(() => {
    filters({
      ...filters(),
      assetTypes: [],
      eventTypes: [],
      assetId: null,
      driverId: null,
      eventScoreGreaterThan: null,
      eventScoreLessThan: null
    })
  }, [selectedTab])

  return (
    <FlexBox
      direction='column'
      fillSpace
      style={{ position: 'relative', width: '100%' }}
    >
      {/*<SettingsSidePanel
        selectedSettingsPage={selectedSettingsPage}
        Colors={Colors}
      />*/}
      <FlexBox
        direction='column'
        fillSpace
        style={{ position: 'relative', width: '100%' }}
      >
        {showAddDriverModal && (
          <AddDriverModal
            setShowAddDriverModal={setShowAddDriverModal}
            showAddDriverModal={showAddDriverModal}
          />
        )}
        {showAddAssetModal && (
          <AddAssetModal
            setShowAddAssetModal={setShowAddAssetModal}
            showAddAssetModal={showAddAssetModal}
          />
        )}
        {showAddDeviceModal && (
          <AddDeviceModal
            setShowAddDeviceModal={setShowAddDeviceModal}
            showAddDeviceModal={showAddDeviceModal}
          />
        )}
        {/*<TabBar
        tabs={[
          {
            tabRender: () => {
              return <AssetsList setShowAddAssetModal={setShowAddAssetModal} />
            },
            name: 'Assets'
          },
          {
            tabRender: () => {
              return (
                <DriversList setShowAddDriverModal={setShowAddDriverModal} />
              )
            },
            name: 'Drivers'
          },
          {
            tabRender: () => {
              return (
                <DevicesList setShowAddDeviceModal={setShowAddDeviceModal} />
              )
            },
            name: 'Devices'
          }
        ]}
        selectedIndex={
          selectedTab === 'asset' ? 0 : selectedTab === 'driver' ? 1 : 2
        }
        setSelectedIndex={(index) =>
          handleSetSelectedTab({
            tabsName: 'settingsADDTabs',
            searchParamsName: 'settings-add-tab',
            selectedTabName:
              index === 0 ? 'asset' : index === 1 ? 'driver' : 'device',
            urlSearchParams,
            history
          })
        }
        tabInnerStyle={{}}
        containerStyle={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          flexGrow: 1
        }}
        tabContainerStyle={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
          flexGrow: 1
        }}
      />*/}
        <TabRoutes
          tabsData={[
            { id: 'assets', name: 'Assets' },
            { id: 'drivers', name: 'Drivers' },
            { id: 'devices', name: 'Devices' }
          ]}
        >
          <AssetsList />
          <DriversList />
          <DevicesList />
        </TabRoutes>
        {disabled && (
          <FlexBox
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: Colors.backgroundOpacity2,
              zIndex: 100
            }}
          ></FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default FleetManagementSelectionPanel
