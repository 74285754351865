export const Images = {
  CybeleEmblem: require('./Images/Emblem_lt.svg'),
  CybeleEmblemBeta: require('./Images/cybele_beta_logo.svg'),
  // CybeleEmblemDark: require('./Images/Emblem_dt.svg'),
  CybeleLogotype: require('./Images/CybeleFleetLogotype_lt.svg'),
  CybeleLogotypeAlt: require('./Images/CybeleFleetLogotype_alt.svg'),
  CybeleB: require('./Images/cybele_b.svg'),
  Cybele: require('./Images/Cybele_dt.svg'),
  markerPrimary1: require('./Images/MarkerPrimary1.svg'),
  mapIcon1: require('./Images/MapIcon1.svg'),
  tripStartIcon: require('./Images/tripStartIcon.svg'),
  tripEndIcon: require('./Images/tripEndIcon.svg'),
  mapHeadingIcon: require('./Images/map_heading_arrow_2.svg'),
  HARSH_BRAKING: require('./Images/HARSH_BRAKING.svg'),
  HARSH_CORNERING: require('./Images/HARSH_CORNERING.svg'),
  HARSH_ACCELERATING: require('./Images/HARSH_ACCELERATING.svg'),
  HARSH_ACCELERATION: require('./Images/HARSH_ACCELERATING.svg'),
  SPEEDING: require('./Images/SPEEDING.svg'),
  PHONE_MOTION: require('./Images/PHONE_MOTION.svg'),
  HARSH_BRAKING_mapIcon: require('./Images/HARSH_BRAKING_mapIcon.svg'),
  HARSH_CORNERING_mapIcon: require('./Images/HARSH_CORNERING_mapIcon.svg'),
  HARSH_ACCELERATING_mapIcon: require('./Images/HARSH_ACCELERATING_mapIcon.svg'),
  HARSH_ACCELERATION_mapIcon: require('./Images/HARSH_ACCELERATING_mapIcon.svg'),
  SPEEDING_mapIcon: require('./Images/SPEEDING_mapIcon.svg'),
  PHONE_MOTION_mapIcon: require('./Images/PHONE_MOTION_mapIcon.svg'),
  truckIcon1: require('./Images/truckIcon1.svg'),
  driverProfile: require('./Images/driverProfile.png'),
  inspection1: require('./Images/inspection1.png'),
  inspection2: require('./Images/inspection2.png'),
  inspection3: require('./Images/inspection3.png'),
  inspection4: require('./Images/inspection4.png'),
  inspection5: require('./Images/inspection5.png'),
  inspection6: require('./Images/inspection6.png'),
  verifiedUser: require('./Images/verified_user.png'),
  error: require('./Images/error.png'),
  starBadge: require('./Images/star_badge.png'),
  truckInsideBehind: require('./Images/truckinsidebehind.jpeg'),
  truckInside: require('./Images/truckinside.jpeg'),
  truckInsidePassenger: require('./Images/truckinsidepassenger.jpeg'),
  truckLeftBack: require('./Images/truckleftback.jpeg'),
  truckRightBack: require('./Images/truckrightback.jpeg'),
  truckRightSide: require('./Images/truckrightside.jpeg'),
  truckLeftSide: require('./Images/truckleftside.jpeg'),
  truckFrontPlate: require('./Images/truckfrontplate.jpeg'),
  excellentBadge1: require('./Images/ExcellentBadge1.svg'),
  shield_EXCELLENT: require('./Images/shield_blue.svg'),
  shield_SAFE: require('./Images/shield_green.svg'),
  shield_ADEQUATE: require('./Images/shield_yellow.svg'),
  shield_MODERATE_RISK: require('./Images/shield_orange.svg'),
  shield_HIGH_RISK: require('./Images/shield_red.svg'),
  shieldIcon: require('./Images/ShieldIcon1.svg'),
  fuelGraphic: require('./Images/Fuel_Graphic.svg'),
  timeGraphic: require('./Images/Time_Graphic.svg')
}
