import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const driverFragment = gql`
  fragment driverFragment on Driver {
    _id
    organization {
      _id
      name
    }
    firstName
    lastName
    dob
    doe
    idNumber
    gender
    nationality
    cellphoneNumber
    licenseNumber
    driverRestriction
    vehicleRestriction
    professionalDrivingPermit
    professionalDrivingPermitExpiryDate
    medicalAidNumber
    isActive
    displayId
    profilePictureUrl
    code
    firstIssue
  }
`

export const GET_DRIVER = gql`
  query driver($id: String!) {
    driver(id: $id) {
      ...driverFragment
      asset {
        _id
        name
        registrationNum
        device {
          _id
          hasVVT
          deviceId
        }
      }
    }
  }
  ${driverFragment}
`

export const driverDataTransform = ({ data }) => {
  const asset = data && data.asset
  const device = asset && asset.device ? asset.device : null
  return {
    ...data,
    name: `${data.firstName} ${data.lastName}`,
    assetId: asset ? asset._id : null,
    assetName: asset ? asset.name : null,
    deviceId: device ? device._id : null,
    hasVVT: device ? device.hasVVT : null,
    orgId: data.organization ? data.organization._id : null,
    VVTdeviceId: device && device.hasVVT ? device.deviceId : null
  }
}

export const useDriver = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_DRIVER, {
    variables: { id },
    queryName: 'driver',
    errorMessage: 'Failed to fetch Driver',
    dataTransform: (data) => driverDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
