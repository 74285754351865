import React from 'react'
import { MainLayout } from '../../Components'
import { selectedAssetId, selectedDriverId } from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { Switch, Route } from 'react-router-dom'
import AssetHeader from '../../Components/Custom/AssetHeader'
import DriverHeader from '../../Components/Custom/DriverHeader'
import LiveView from './LiveView'

const LiveMonitoring = () => {
  const assetId = useReactiveVar(selectedAssetId)
  const driverId = useReactiveVar(selectedDriverId)
  return (
    <MainLayout name='mainLayout1'>
      <Switch>
        <Route path='/live-monitoring/assets'>
          {assetId && <AssetHeader assetId={assetId} />}
        </Route>
        <Route path='/live-monitoring/drivers'>
          {driverId && <DriverHeader driverId={driverId} />}
        </Route>
        <Route path='/live-monitoring'>
          {assetId && <AssetHeader assetId={assetId} />}
        </Route>
      </Switch>
      <LiveView />
    </MainLayout>
  )
}

export default LiveMonitoring
