import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from '..'
import { Metrics } from '../../Themes'
import FlexBox from '../FlexBox'
import { css } from 'aphrodite'
import { Styles } from './Styles'

const TabButton = ({
  id,
  name,
  linkToUrl,
  selected,
  Colors,
  style,
  onChange
}) => {
  const styles = Styles({
    Colors,
    Metrics,
    selected
  })
  return (
    <Link
      className={css(styles.button)}
      to={linkToUrl}
      onClick={() => onChange(id)}
      style={{
        ...style
      }}
    >
      <FlexBox style={{ width: '100%', height: '100%' }}>
        <Text color={selected ? Colors.primary : Colors.secondary}>
          <b>{name}</b>
        </Text>
      </FlexBox>
    </Link>
  )
}

export default TabButton
