import { Metrics } from '../../Themes'

const Styles = {
  modalInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Metrics.base * 2,
    paddingBottom: Metrics.base * 2
  },
  documentFormButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
export default Styles
