import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'

export const documentFragment = gql`
  fragment documentFragment on Document {
    _id
    driver {
      _id
    }
    url
    documentType
    filename
    createdAt
    isActive
    displayId
    vehicleLicence {
      controlNumber
      registeringAuthority
      vin
      registrationNumber
      engineNumber
      gvm
      tare
      vehicleMake
      testingStation
      examinerIdentity
      dateOfTest
      maxPersons
      doe
    }
  }
`

export const GET_DOCUMENT_BY_INSPECTION_ID = gql`
  query documentsByInspectionId(
    $inspectionId: String!
    $documentType: String
    $skip: Int
    $limit: Int
  ) {
    documentsByInspectionId(
      inspectionId: $inspectionId
      documentType: $documentType
      skip: $skip
      limit: $limit
    ) {
      ...documentFragment
    }
  }
  ${documentFragment}
`

export const documentDataTransform = ({ data }) => {
  return [...data]
  // name: `${data.firstName} ${data.lastName}`
}

const getDocumentVariables = (inspectionVars) => {
  let variables = {
    skip: 0,
    limit: 1000
  }
  if (checkVal(inspectionVars.inspectionId)) {
    variables.inspectionId = inspectionVars.inspectionId
  }
  if (checkVal(inspectionVars.documentType)) {
    variables.documentType = inspectionVars.documentType
  }
  return variables
}

export const useDocumentsByInspectionId = (inspectionVars) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DOCUMENT_BY_INSPECTION_ID,
    {
      variables: getDocumentVariables(inspectionVars),
      queryName: 'documentsByInspectionId',
      errorMessage: 'Failed to fetch Document',
      dataTransform: (data) => documentDataTransform({ data }),
      skip: !(inspectionVars && inspectionVars.inspectionId)
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
