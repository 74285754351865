import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const CREATE_ASSET = gql`
  mutation createAsset(
    $orgId: String
    # plateType: String
    $assetType: String
    $vin: String
    $registrationNum: String
    # assetReferenceNo: String
    $name: String
    $assetStatus: String
    $weight: Float
    $capacity: Float
    # length: Float
    $fuelType: String
    # axleCount: Int
    $maxSpeed: Int
    $linkedAssets: [String]
    $assetReferenceNo: String
    $controlNumber: String
    $registeringAuthority: String
    $engineNumber: String
    $gvm: Int #capacity
    $tare: Int #weight
    $vehicleMake: String
    $maxPersons: Int
    $nvc: String
    $isActive: Boolean
    $professionalDrivingPermit: [String]
    $vehicleCategory: [String]
    $vehicleSubCategory: String
    $drivenVehicle: String
    $prdpTypeCode: [String]
    $licenseNo: String
    $vehicleModel: String
    $vehicleYear: String
    $description: String
    $dateOfPurchase: GraphqlDateTime
  ) {
    createAsset(
      orgId: $orgId
      assetType: $assetType
      vin: $vin
      registrationNum: $registrationNum
      name: $name
      assetStatus: $assetStatus
      weight: $weight
      capacity: $capacity
      fuelType: $fuelType
      maxSpeed: $maxSpeed
      linkedAssets: $linkedAssets
      isActive: $isActive
      assetReferenceNo: $assetReferenceNo
      controlNumber: $controlNumber
      registeringAuthority: $registeringAuthority
      engineNumber: $engineNumber
      gvm: $gvm
      tare: $tare
      vehicleMake: $vehicleMake
      maxPersons: $maxPersons
      nvc: $nvc
      professionalDrivingPermit: $professionalDrivingPermit
      vehicleCategory: $vehicleCategory
      prdpTypeCode: $prdpTypeCode
      vehicleSubCategory: $vehicleSubCategory
      vehicleModel: $vehicleModel
      vehicleYear: $vehicleYear
      licenseNo: $licenseNo
      drivenVehicle: $drivenVehicle
      description: $description
      dateOfPurchase: $dateOfPurchase
    ) {
      _id
      organization {
        _id
        name
      }
      assetType
      vin
      registrationNum
      name
      assetStatus
      weight
      capacity
      fuelType
      maxSpeed
      linkedAssets {
        _id
        name
      }
      isActive
    }
  }
`
export const useCreateAsset = () => {
  const [createAsset, { loading: createAssetLoading }] = useMutation(
    CREATE_ASSET,
    {
      mutationName: 'createAsset',
      successMessage: 'Asset created successfully',
      errorMessage: 'Asset creation failed'
    }
  )

  return [createAsset, { createAssetLoading }]
}
