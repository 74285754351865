import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../../Helpers/Functions'
import { useFilters } from '../useFilters'
import { filters, tripFilters } from '../../GraphQLClient'
import { useReactiveVar } from '@apollo/client'

export const GET_TRIP_IDS = gql`
  query trips(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $driverId: String
    $assetId: String
    $startDate: Float
    $endDate: Float
    $skip: Int
    $limit: Int
    $searchString: String
    $safetyCategory: [String]
    $onlyScoredTrips: Boolean
  ) {
    trips(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      driverId: $driverId
      assetId: $assetId
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
      searchString: $searchString
      safetyCategory: $safetyCategory
      onlyScoredTrips: $onlyScoredTrips
    ) {
      totalCount
      trips {
        _id
        displayId
      }
    }
  }
`

const dataTransform = ({ data }) => {
  const tripIds = []
  const tripsData =
    data.trips &&
    data.trips.map((a) => {
      tripIds.push(a._id)
      return a
    })
  return {
    totalCount: data.totalCount,
    data: tripsData,
    tripIds
  }
}

export const getTripsVariables = ({
  orgIdsFilter,
  groupId,
  searchString,
  onlyScoredTrips,
  startDate,
  endDate,
  driverId,
  assetId,
  safetyCategory
}) => {
  let variables = {
    skip: 0,
    limit: 10
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(searchString)) {
    variables = { ...variables, searchString }
  }
  if (checkVal(onlyScoredTrips)) {
    variables = { ...variables, onlyScoredTrips }
  }
  if (checkVal(driverId)) {
    variables = { ...variables, driverId }
  }
  if (checkVal(assetId)) {
    variables = { ...variables, assetId }
  }
  if (checkVal(startDate)) {
    variables = { ...variables, startDate }
  }
  if (checkVal(endDate)) {
    variables = { ...variables, endDate }
  }
  if (checkVal(safetyCategory)) {
    variables = { ...variables, safetyCategory }
  }
  return variables
}

export const useTripIds = (tripsVariables) => {
  const { orgIds, groupId, startDate, endDate } = useReactiveVar(filters)
  const { driverId, assetId, safetyCategories, searchString } =
    useReactiveVar(tripFilters)
  const [data, setData] = useState([])
  const [tripIds, setTripIds] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_TRIP_IDS, {
    variables: getTripsVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId,
      onlyScoredTrips: tripsVariables.onlyScoredTrips,
      searchString,
      safetyCategory: safetyCategories,
      startDate,
      endDate,
      driverId,
      assetId
    }),
    queryName: 'trips',
    errorMessage: 'Failed to fetch Trips Ids',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTripIds(queryData.tripIds)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, tripIds, totalCount, loading, fetchMore, networkStatus }
}
