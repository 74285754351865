import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../Helpers/Functions'

export const documentFragment = gql`
  fragment documentFragment on Document {
    _id
    driver {
      _id
    }
    url
    documentType
    filename
    createdAt
    isActive
    displayId
    vehicleLicence {
      controlNumber
      registeringAuthority
      vin
      registrationNumber
      engineNumber
      gvm
      tare
      vehicleMake
      testingStation
      examinerIdentity
      dateOfTest
      maxPersons
      doe
    }
  }
`

export const GET_DOCUMENT_BY_ASSET_ID = gql`
  query documentsByAssetId($assetId: String, $skip: Int, $limit: Int) {
    documentsByAssetId(assetId: $assetId, skip: $skip, limit: $limit) {
      ...documentFragment
    }
  }
  ${documentFragment}
`

export const documentDataTransform = ({ data }) => {
  return [...data]
  // name: `${data.firstName} ${data.lastName}`
}

const getDocumentVariables = ({ assetId }) => {
  let variables = {
    skip: 0,
    limit: 1000
  }
  if (checkVal(assetId)) {
    variables.assetId = assetId
  }
  return variables
}

export const useDocumentsByAssetId = (assetId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_DOCUMENT_BY_ASSET_ID,
    {
      variables: getDocumentVariables({ assetId }),
      queryName: 'documentsByAssetId',
      errorMessage: 'Failed to fetch Document',
      dataTransform: (data) => documentDataTransform({ data }),
      skip: !assetId
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
