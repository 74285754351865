import React from 'react'
import styled from 'styled-components'
import {
  useTable,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from 'react-table'
import { DefaultColumnFilter } from '.'
import { Text, IconButton, Icon, ProcessingSpinner } from '../../'
import { Colors, Fonts, Metrics, useColors } from '../../../Themes'
// import { darkmode } from '../../../Themes/Clients/blockHarvest'
import Subsection from '../../Subsection'
import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../../../Graphql/Queries/localQueries'
import { css } from 'aphrodite'
import { styles, inlineStyles } from './Styles'
import FlexBox from '../../FlexBox'

const Styles = ({ Colors, children }) => {
  const StyledDiv = styled.div`
    .table {
      margin: auto;
      width: 100%;
      border-spacing: 0;
      border-radius: 5px;
      overflow: hidden;
      .thead {
        ${
          '' /* These styles are required for a scrollable body to align with the header properly */
        }
        overflow-y: auto;
        overflow-x: hidden;
      }

      .tbody {
        ${'' /* These styles are required for a scrollable table body */}
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        border-radius: 5px;
      }

      .tr {
        td {
          min-height: ${Metrics.base * 5}px;
          border-bottom: 1px solid ${Colors.dividerColorOpacity1};
          border-right: 1px solid ${Colors.backgroundOpacity2};
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
        :first-child {
        }
        :nth-child(even) {
          td {
            :first-child {
              border-left: 5px solid ${Colors.primary};
            }
          }
        }
        :nth-child(odd) {
          td {
            :first-child {
              border-left: 5px solid ${Colors.primary};
            }
          }
        }
        :hover {
          td {
            background: ${Colors.selectHighlightOpacity2};
            cursor: pointer;
          }
        }
        :nth-child(odd):hover {
          td {
            background: ${Colors.selectHighlightOpacity2};
            cursor: pointer;
          }
        }
      }
      .th {
        margin: 0;
        padding: 1rem;
        color: ${Colors.secondary};
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${Colors.dividerColorOpacity1};
      }

      .td {
        margin: 0;
        padding: 0.5rem;
        color: ${Colors.secondary};
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        ${
          '' /* In this example we use an absolutely position resizer,
       so this is required. */
        }
        position: relative;

        :last-child {
          border-right: 0;
        }

        .resizer {
          right: 0;
          background: ${Colors.background};
          width: 1px;
          height: 100%;
          position: absolute;
          bottom: 0;
          z-index: 1;
          ${'' /* prevents from scrolling while dragging on touch devices */}
          touch-action:none;

          &.isResizing {
            background: ${Colors.primaryLight};
          }
        }

        input {
          font-size: 0.9rem;
          border-color: ${Colors.background};
          color: ${Colors.secondary};
          width: 100%;
          border-radius: 4px;
          border-width: 1px;
          outline: none;
          ::placeholder {
            color: ${Colors.placeholderColor};
          }
        }

        select {
          width: 100%;
          font-size: 0.9rem;
        }
      }

      .th {
        color: ${Colors.secondary};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:last-of-type {
          .resizer {
            ${
              '' /* note that the 15 is the scroll width and if also referenced in the getHeaderGroupProps for the header row below */
            }
            ${
              '' /* todo: resolve this value dynamicaly from element.scrollWidth to account for OS/Browser differences  */
            }
          right: -2px;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }

    .paginationItem {
      padding-left: 4px;
      padding-right: 4px;
    }
  `
  return <StyledDiv>{children}</StyledDiv>
}

// Create an editable cell renderer
const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (!editable) {
    return `${initialValue}`
  }

  return <input value={value} onChange={onChange} onBlur={onBlur} />
}

// Create a default prop getter
const defaultPropGetter = () => ({})

// Be sure to pass our updateMyData and the skipReset option
const Table = ({
  defaultPageSize,
  columns,
  data,
  sortBy,
  handleRowClick,
  fetchingMore,
  fetchMore,
  totalCount,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  manualPagination,
  setPageIndex = () => {},
  title,
  liveUpdate,
  loading,
  getBorderLeftStyles,
  renderHeading,
  hideColumnHeaders,
  hideColumnFilters,
  updateMyData,
  skipReset,
  getColumnProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  SubComponent
}) => {
  const Colors = useColors()
  const { data: dataDarkmode } = useQuery(GET_DARKMODE)
  const darkmode = dataDarkmode ? dataDarkmode.darkmode : true
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      Cell: EditableCell,
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 100, // width is used for both the flex-basis and flex-grow
      maxWidth: 200 // maxWidth is only used as a limit for resizing
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: internalPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize: setInternalPageSize,
    state: { pageIndex: internalPageIndex, pageSize: internalPageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      handleRowClick,
      title,
      liveUpdate,
      getBorderLeftStyles,
      renderHeading,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      manualPagination,
      pageCount,
      initialState: {
        pageIndex: pageIndex ? pageIndex : 0,
        pageSize: manualPagination
          ? pageSize
          : defaultPageSize
          ? defaultPageSize
          : 20,
        sortBy
      },
      backgroundColor: darkmode
        ? Colors.darkTheme.background
        : Colors.lightTheme.background,
      secondaryColor: darkmode
        ? Colors.darkTheme.secondary
        : Colors.lightTheme.secondary
    },
    useFilters,
    useGroupBy,
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
    // Here we will use a plugin to add our selection column
    // hooks => {
    //   hooks.flatColumns.push(columns => {
    //     return [
    //       {
    //         id: 'selection',
    //         // Make this column a groupByBoundary. This ensures that groupBy columns
    //         // are placed after it
    //         groupByBoundary: true,
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllRowsSelectedProps }) => (
    //           <div>
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         )
    //       },
    //       ...columns
    //     ]
    //   })
    // }
  )

  const getSkip = (index, pageSize) => {
    return index * pageSize
  }

  const renderPagination = (Colors) => {
    return (
      <div
        style={{
          borderRadius: 5,
          height: Metrics.base * 6
          // backgroundColor: Colors.backgroundTintLight
        }}
      >
        <div className='pagination'>
          <div className='paginationItem'>
            <IconButton
              iconName='double_arrow'
              onClick={() => {
                fetchMore &&
                  fetchMore({
                    variables: {
                      skip: getSkip(0, pageSize),
                      limit: pageSize
                    }
                  })
                setPageIndex(0)
                gotoPage(0)
              }}
              disabled={manualPagination ? pageIndex <= 0 : !canPreviousPage}
              iconSize='large'
              buttonStyles={{
                transform: 'rotate(180deg)'
              }}
            />
          </div>
          <div className='paginationItem'>
            <IconButton
              iconName='keyboard_arrow_left'
              iconSize='large'
              onClick={() => {
                previousPage()
                canPreviousPage && setPageIndex(internalPageIndex - 1)
                fetchMore &&
                  fetchMore({
                    variables: {
                      skip: getSkip(pageIndex - 1, pageSize),
                      limit: pageSize
                    }
                  })
              }}
              disabled={!canPreviousPage}
            />
          </div>
          <div style={{ display: 'flex', flex: 1 }}></div>
          <div
            className='paginationItem'
            style={{
              ...inlineStyles.textContainer,
              color: Colors.secondary
            }}
          >
            <Text>Page</Text>
            <Text marginLeft={0.5} bold>
              {internalPageIndex + 1} of{' '}
              {manualPagination ? pageCount : pageOptions.length}
            </Text>
            <Text marginLeft={1} marginRight={1}>
              |
            </Text>
            <Text marginRight={0.5}>Go to page:</Text>
            <input
              type='number'
              defaultValue={internalPageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                setPageIndex(page)
                gotoPage(page)
                fetchMore &&
                  fetchMore({
                    variables: {
                      skip: getSkip(page, pageSize),
                      limit: pageSize
                    }
                  })
              }}
              style={{
                width: '50px',
                borderRadius: 5,
                border: `1px solid ${Colors.background}`,
                color: Colors.secondary,
                backgroundColor: 'transparent',
                outline: 'none',
                padding: '0px 4px'
              }}
            />
            <Text marginLeft={1} marginRight={1}>
              |
            </Text>
            <select
              className='paginationItem'
              style={{
                color: Colors.secondary,
                borderRadius: 5,
                border: `1px solid ${Colors.background}`,
                backgroundColor: 'transparent',
                outline: 'none'
              }}
              value={manualPagination ? pageSize : internalPageSize}
              onChange={(e) => {
                manualPagination
                  ? setPageSize(Number(e.target.value))
                  : setInternalPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', flex: 1 }}></div>
          <div className='paginationItem'>
            <IconButton
              iconName='keyboard_arrow_right'
              iconSize='large'
              onClick={() => {
                nextPage()
                canNextPage && setPageIndex(internalPageIndex + 1)
                if (fetchMore) {
                  fetchMore({
                    variables: {
                      skip: getSkip(pageIndex + 1, pageSize),
                      limit: pageSize
                    }
                  })
                }
              }}
              disabled={!canNextPage}
            />
          </div>
          <div className='paginationItem'>
            <IconButton
              iconName='double_arrow'
              onClick={() => {
                gotoPage(internalPageCount - 1)
                setPageIndex(internalPageCount - 1)
                fetchMore &&
                  fetchMore({
                    variables: {
                      skip: getSkip(pageCount - 1, pageSize),
                      limit: pageSize
                    }
                  })
              }}
              disabled={!canNextPage}
              iconSize='large'
            />
          </div>
        </div>
        {/* <pre>
      <code>
        {JSON.stringify(
          {
            pageIndex,
            pageSize,
            pageCount,
            canNextPage,
            canPreviousPage,
            groupBy,
            expanded: expanded,
            filters,
            selectedRowIds: selectedRowIds
          },
          null,
          2
        )}
      </code>
    </pre> */}
      </div>
    )
  }

  // Render the UI for your table
  return (
    <Subsection>
      <table {...getTableProps()} className='table' id={`table-${title}`}>
        {hideColumnHeaders ? (
          renderHeading && renderHeading()
        ) : (
          <thead>
            {renderHeading && renderHeading()}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='tr'>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: 'th',
                        style: column.style
                      },
                      getColumnProps(column),
                      getHeaderProps(column)
                    ])}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {column.canGroupBy
                        ? // If the column can be grouped, let's add a toggle
                          /*
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? (
                          <IconButton
                            iconColor={Colors.danger}
                            iconName='cancel'
                            buttonStyles={{ padding: 0, paddingRight: 4 }}
                          />
                        ) : (
                          <IconButton
                            iconSize='small'
                            iconName='account_tree'
                            buttonStyles={{ padding: 0, paddingRight: 4 }}
                          />
                        )}
                      </span>
                      */
                          null
                        : null}
                      <div
                        {...column.getSortByToggleProps()}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        {column.render('Header')}
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? 'isResizing' : ''
                            }`}
                          />
                        )}
                        {/* Add a sort direction indicator */}
                        {column.isSorted && !column.isResizing ? (
                          column.isSortedDesc ? (
                            <IconButton
                              buttonStyles={{ padding: 0 }}
                              iconName='keyboard_arrow_down'
                            />
                          ) : (
                            <IconButton
                              buttonStyles={{ padding: 0 }}
                              iconName='keyboard_arrow_up'
                            />
                          )
                        ) : (
                          <IconButton
                            buttonStyles={{ padding: 0 }}
                            disabled
                            iconName='keyboard_arrow_right'
                          />
                        )}
                      </div>
                    </div>
                    {/* Render the columns filter UI */}
                    {!hideColumnFilters && (
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        {data.length === 0 || loading ? (
          <div
            style={{
              width: '100%',
              // height: 100,
              backgroundColor: Colors.backgroundOpacity3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: Metrics.base * 4,
              paddingBottom: Metrics.base * 2
            }}
          >
            {/*<Text font='jumbo' style={{ opacity: 0.1 }}>
            <i>No Data</i>
        </Text>*/}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {loading ? (
                <div
                  style={{
                    border: `1px solid ${Colors.subsectionBackground}`,
                    borderRadius: Metrics.radius,
                    marginTop: Metrics.base * 2
                  }}
                >
                  <div style={{ height: 200 }}>
                    <ProcessingSpinner
                      loading={`Loading ${title ? title : 'data'}...`}
                      mb={3}
                      size={36}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <Icon
                    size={'large'}
                    name={'information'}
                    color={Colors.secondary}
                    style={{
                      paddingRight: Metrics.base * 1,
                      marginRight: Metrics.base * 3,
                      marginTop: Metrics.base * 1,
                      width: 20,
                      opacity: 0.6
                    }}
                  />
                  <Text
                    color={Colors.textSecondary}
                    marginBottom={2}
                    marginTop={3}
                    font='heading'
                  >
                    No {title}.
                  </Text>
                </>
              )}
            </div>
            {/* 
            <Text
              marginBottom={6}
              color={
                darkmode
                  ? Colors.darkTheme.secondaryOpacity2
                  : Colors.lightTheme.secondaryOpacity2
              }
            >
              Use the button in the top right to create.
            </Text>
            */}
          </div>
        ) : (
          <tbody {...getTableBodyProps()} className='tbody'>
            {page.map((row) => {
              prepareRow(row)
              const rowStyle =
                liveUpdate &&
                liveUpdate.visible &&
                row.original.id === liveUpdate.id
                  ? {
                      style: inlineStyles.liveUpdate,
                      className: `${css(styles({ darkmode }).liveUpdate)} tr`
                    }
                  : row.original.selected
                  ? {
                      style: {
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: Colors.selectHighlightOpacity3
                      }
                    }
                  : null
              return (
                <>
                  <tr
                    {...row.getRowProps(getRowProps(row))}
                    className='tr'
                    {...rowStyle}
                    onClick={() => handleRowClick(row, updateMyData)}
                  >
                    {row.cells.map((cell, index) => {
                      const borderLeftStyle =
                        index === 0 ? getBorderLeftStyles(cell) : {}
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: 'td',
                              style: {
                                display: 'flex',
                                alignItems: 'center',
                                ...cell.column.style,
                                ...cell.column.styles,
                                ...borderLeftStyle
                              }
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell)
                          ])}
                        >
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getExpandedToggleProps()}>
                                {row.isExpanded ? (
                                  <IconButton
                                    buttonStyles={{ padding: 0 }}
                                    iconName='keyboard_arrow_down'
                                  />
                                ) : (
                                  <IconButton
                                    buttonStyles={{ padding: 0 }}
                                    iconName='keyboard_arrow_right'
                                  />
                                )}
                              </span>{' '}
                              {cell.render('Cell', { editable: false })} (
                              {row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render('Aggregated')
                          ) : (
                            // REPEATED VALUE !!!!
                            // cell.isRepeatedValue ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render('Cell', { editable: false })
                          )}
                        </td>
                      )
                    })}
                  </tr>
                  {SubComponent && <SubComponent row={row} />}
                </>
              )
            })}
            {fetchingMore && (
              <FlexBox
                style={{
                  backgroundColor: Colors.backgroundOpacity2,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: Metrics.base * 6,
                  right: 0
                }}
              >
                <ProcessingSpinner loading='Loading...' mb={3} size={36} />
              </FlexBox>
            )}
            {renderPagination(Colors)}
          </tbody>
        )}
      </table>
    </Subsection>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

// // This is a custom aggregator that
// // takes in an array of values and
// // returns the rounded median
// function roundedMedian(values) {
//   let min = values[0] || ''
//   let max = values[0] || ''

//   values.forEach((value) => {
//     min = Math.min(min, value)
//     max = Math.max(max, value)
//   })

//   return Math.round((min + max) / 2)
// }

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef()
//     const resolvedRef = ref || defaultRef

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate
//     }, [resolvedRef, indeterminate])

//     return (
//       <>
//         <input type='checkbox' ref={resolvedRef} {...rest} />
//       </>
//     )
//   }
// )

function ReactTableComp({
  columns,
  data,
  dataState,
  setData,
  sortBy,
  handleRowClick,
  fetchingMore,
  fetchMore,
  totalCount,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  manualPagination,
  setPageIndex,
  title,
  liveUpdate,
  loading,
  getBorderLeftStyles,
  renderHeading,
  SubComponent,
  hideColumnHeaders,
  hideColumnFilters,
  defaultPageSize
}) {
  // const [dataState, setData] = React.useState(data)

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false)

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    skipResetRef.current = true
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          }
        }
        return row
      })
    )
  }

  const handleRowClickFunction = handleRowClick ? handleRowClick : () => {}
  const getBorderLeftStylesFunction = getBorderLeftStyles
    ? getBorderLeftStyles
    : () => {}

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false
  }, [data])

  const Colors = useColors()
  return (
    <Styles Colors={Colors}>
      <Table
        defaultPageSize={defaultPageSize}
        columns={columns}
        hideColumnHeaders={hideColumnHeaders}
        hideColumnFilters={hideColumnFilters}
        data={dataState}
        sortBy={sortBy}
        handleRowClick={handleRowClickFunction}
        fetchingMore={fetchingMore}
        fetchMore={fetchMore}
        totalCount={totalCount}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        manualPagination={manualPagination}
        setPageIndex={setPageIndex}
        title={title}
        loading={loading}
        liveUpdate={liveUpdate}
        getBorderLeftStyles={getBorderLeftStylesFunction}
        renderHeading={renderHeading}
        updateMyData={updateMyData}
        skipReset={skipResetRef.current}
        SubComponent={SubComponent}
        getColumnProps={(column) => ({
          // background: 'white'
        })}
        getHeaderProps={(column) => ({
          style: {
            ...Fonts.tableHeaderText
            // background: 'white'
          }
        })}
        getRowProps={(row) => ({
          style: {
            // ...Fonts.tableHeaderText,
            // background: row.index % 2 === 0 ? 'rgba(0,0,0,0)' : 'white'
          }
        })}
        getCellProps={(cellInfo) => ({
          style: {
            ...Fonts.tableCellText
          }
        })}
      />
    </Styles>
  )
}

export default ReactTableComp
