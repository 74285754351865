import React, { useEffect, useRef } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  assetFilters,
  liveViewMapIds,
  selectedAssetId,
  selectedTabs
} from '../../../../Graphql/GraphQLClient'
import { useAssetIds } from '../../../../Graphql/Queries/Asset/useAssetIds'
import AssetCard from './AssetCard'
import { useRouteMatch } from 'react-router-dom'
import { NetworkStatus, useReactiveVar } from '@apollo/client'
import FiltersContainer from '../FiltersContainer'
import AssetFilters, { handleClearFilters } from './AssetFilters'
import { getValCount } from '../Event/EventFilters'
import TabRoutes from '../../../TabRoutes'
import { removeTrailingSlashes } from '../../../../Helpers/Functions'
import { useNavigation } from '../../../CustomHooks/useNavigation'

const getActiveFiltersCount = (filters) => {
  let count = 0
  if (filters.safetyCategories && filters.safetyCategories.length > 0) count++
  if (filters.assetTypes && filters.assetTypes.length > 0) count++
  count += getValCount(filters.minSafetyScore)
  count += getValCount(filters.maxSafetyScore)
  return count
}

const AssetsList = ({
  CardComponent = AssetCard,
  activePage,
  setShowAddAssetModal
}) => {
  const assetId = useReactiveVar(selectedAssetId)
  const { data, totalCount, liveViewIds, fetchMore, networkStatus } =
    useAssetIds()
  const { path } = useRouteMatch()
  const filters = useReactiveVar(assetFilters)
  const { assetMoreFiltersTab } = useReactiveVar(selectedTabs)
  const navigateTo = useNavigation()
  const searchInputRef = useRef()

  useEffect(() => {
    if (data.length === 1) {
      selectedAssetId(data[0]._id)
    }
  }, [data])
  useEffect(() => {
    if (liveViewIds) {
      liveViewMapIds(liveViewIds)
    }
  }, [liveViewIds])

  return (
    <TabRoutes
      renderTabBar={false}
      defaultId={assetMoreFiltersTab ? assetMoreFiltersTab : null}
      tabsData={[
        { id: 'select-card', name: 'Select Card' },
        { id: 'more-filters', name: 'More Filters' }
      ]}
    >
      <>
        <CardsHeader
          title='Assets'
          number={totalCount ? totalCount : 0}
          items={data}
          searchInputString={filters.searchString}
          setSearchInputString={(value) => [
            assetFilters({ ...assetFilters(), searchString: value }),
            selectedAssetId(null)
          ]}
          searchInputRef={searchInputRef}
          addButton={activePage === 'settings' ? 'Asset' : null}
          addButtonOnClick={() => setShowAddAssetModal(true)}
          moreFiltersPath={`${removeTrailingSlashes(path)}/more-filters`}
          activeFiltersCount={getActiveFiltersCount(filters)}
          moreFiltersTabName='assetMoreFiltersTab'
        />
        <CardsContainer fetchMore={fetchMore} networkStatus={networkStatus}>
          <CardsList items={data} itemsName='Assets' selectedItemId={assetId}>
            {({ items, item, index }) => (
              <CardComponent
                data={item}
                assetId={item._id}
                navigateTo={navigateTo}
                index={index}
                key={index}
                selected={item._id === assetId}
                borderBottom={
                  index + 1 >= items.length ||
                  (items[index + 1] && items[index + 1]._id !== assetId)
                }
              />
            )}
          </CardsList>
        </CardsContainer>
      </>
      <FiltersContainer
        totalCount={totalCount}
        title='Asset Filters'
        loading={
          networkStatus === NetworkStatus.loading ||
          networkStatus === NetworkStatus.setVariables
        }
        searchInputRef={searchInputRef}
        moreFiltersTabName='assetMoreFiltersTab'
        handleClearFilters={handleClearFilters}
        returnToResultsPath={`${removeTrailingSlashes(path)}/select-card`}
      >
        <AssetFilters searchInputRef={searchInputRef} />
      </FiltersContainer>
    </TabRoutes>
  )
}

export default AssetsList
