import React, { useState } from 'react'
import { useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import Status from '../../../Status'
import Card from '../Cards/Card'
import { useAsset } from '../../../../Graphql/Queries/Asset/useAsset'
import EmptyCard from '../Cards/EmptyCard'
import { checkVal } from '../../../../Helpers/Functions'
import { ErrorMessage } from '../Driver/LiveDriverCard'
import AssetCardTTList from './AssetCardTTList'
import { useAssetSafetyCategory } from '../../../../Graphql/Queries/Asset/useAssetSafetyCategory'
import { selectedAssetId } from '../../../../Graphql/GraphQLClient'
import LinkDriverToAsset from '../../../../Containers/FleetManagement/LinkDriverToAsset'

export const getAssetDescription = (data) => {
  return `${data.vehicleColour ? data.vehicleColour : ''} ${
    data.vehicleMake ? data.vehicleMake : ''
  } ${data.vehicleModel ? data.vehicleModel : ''} ${
    data.vehicleDescription ? data.vehicleDescription : ''
  }`
}

const AssetCard = ({
  assetId,
  selected,
  navigateTo,
  borderBottom,
  backgroundColor,
  onClick
}) => {
  const Colors = useColors()
  const { category, loading: categoryLoading } = useAssetSafetyCategory(assetId)
  const { data, loading: assetLoading } = useAsset(assetId)
  const [showLinkDriverModal, setShowLinkDriverModal] = useState(false)
  return assetId ? (
    <>
      <Card
        id={assetId}
        data={data}
        selected={selected}
        typeName='asset'
        setSelectedId={selectedAssetId}
        navigateTo={navigateTo}
        onClick={onClick}
        TooltipContent={({ setShowTooltip }) => (
          <AssetCardTTList
            assetId={assetId}
            data={data}
            navigateTo={navigateTo}
            showLinkDriverModal={showLinkDriverModal}
            setShowLinkDriverModal={setShowLinkDriverModal}
          />
        )}
        backgroundColor={backgroundColor}
        borderBottom={borderBottom}
        loading={assetLoading}
        titleContent={<Title color={Colors.primary}>{data && data.name}</Title>}
        bodyContent={
          <Text marginBottom={1}>{data && getAssetDescription(data)}</Text>
        }
        topRightContent={
          <Status
            status={category ? category : 'no safety score'}
            getStatusColors={({ status }) =>
              Colors[status] ? Colors[status] : Colors.textSecondary
            }
          />
        }
        topRightLoading={categoryLoading}
        bottomLeftContent={
          data && checkVal(data.driverName) ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Driver: {data.driverName}
            </Text>
          ) : (
            <ErrorMessage text='No Linked Driver' Colors={Colors} />
          )
        }
        bottomRightContent={
          data &&
          !assetLoading &&
          !checkVal(data.deviceId) && (
            <ErrorMessage text='No Linked Device' Colors={Colors} />
          )
        }
      />
      {showLinkDriverModal && (
        <LinkDriverToAsset
          setShowLinkDriverModal={setShowLinkDriverModal}
          assetId={assetId}
          showLinkDriverModal={showLinkDriverModal}
        />
      )}
    </>
  ) : (
    <EmptyCard borderBottom={borderBottom} text={'No Asset ID'} />
  )
}

export default AssetCard
