import React from 'react'
import DataDisplay from './'
import { checkVal } from '../../Helpers/Functions'
import { FlexBox } from '..'

const ObjectDataDisplay = ({ data }) => {
  if (typeof data !== 'object') return ''
  const keys = Object.keys(data)
  return (
    <FlexBox direction='column' style={{ display: 'flex', width: '100%' }}>
      {keys.map((key, index) => {
        const displayData = checkVal(data[key]) ? data[key] : '-'
        return (
          <DataDisplay
            firstItem={index === 0}
            key={key}
            label={key}
            data={displayData}
          />
        )
      })}
    </FlexBox>
  )
}

export default ObjectDataDisplay
