import React from 'react'
import { Metrics, useColors } from '../../Themes'
import { FlexBox, MainLayout } from '../../Components'
import EventDetails from './EventDetails'
import SidePanel from './SidePanel'
import {
  selectedEventId,
  selectedOtherEventId,
  selectedTripTotalEventId
} from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import TabRoutes from '../../Components/TabRoutes'
import { useHistory } from 'react-router-dom'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import EventDetailsTripTotals from './EventDetailsTripTotals'
import EventDetailsInTrip from './EventDetailsInTrip'

const EventScreen = ({ eventId, children }) => {
  const Colors = useColors()
  const history = useHistory()
  const { mainContainerWidth } = useAppDimensions()
  return (
    <FlexBox
      direction='row'
      style={{ width: '100%', alignItems: 'flex-start' }}
    >
      <FlexBox
        direction='column'
        style={{ width: mainContainerWidth - Metrics.sideBarWidth }}
      >
        <FlexBox style={{ width: '100%', padding: Metrics.base * 2 }}>
          {children}
        </FlexBox>
      </FlexBox>
      <SidePanel eventId={eventId} history={history} />
    </FlexBox>
  )
}

const EventInbox = (props) => {
  const inTripEventId = useReactiveVar(selectedEventId)
  const otherEventId = useReactiveVar(selectedOtherEventId)
  const tripTotalEventId = useReactiveVar(selectedTripTotalEventId)
  return (
    <MainLayout {...props}>
      <TabRoutes
        renderTabBar={false}
        tabsData={[
          { id: 'in-trip', name: 'In Trip' },
          { id: 'trip-totals', name: 'Trip Totals' },
          { id: 'other', name: 'Other' }
        ]}
      >
        <EventScreen eventId={inTripEventId}>
          <EventDetailsInTrip eventId={inTripEventId} />
        </EventScreen>
        <EventScreen eventId={tripTotalEventId}>
          <EventDetailsTripTotals eventId={tripTotalEventId} />
        </EventScreen>
        <EventScreen eventId={otherEventId}>
          <EventDetails eventId={otherEventId} />
        </EventScreen>
      </TabRoutes>
    </MainLayout>
  )
}

export default EventInbox
