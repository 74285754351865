import { useEffect } from 'react'

var timeout
export const debounce = (func, wait, immediate) => {
  return function executedFunction() {
    var context = this
    var args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const useDebounce = ({ func, milliseconds, immediate, dependency }) => {
  const debouncedFunc = debounce(func, milliseconds, immediate)

  useEffect(() => {
    clearTimeout(timeout)
  }, [dependency])

  return debouncedFunc
}
