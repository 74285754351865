import React from 'react'
import { Text, Subsection } from '../'
import FlexBox from '../FlexBox'
import { checkVal } from '../../Helpers/Functions'
import { useColors, Metrics } from '../../Themes'
import Status from '../Status'

const InfoBadge = ({
  value,
  getDisplayVal = (val) => val,
  label,
  bold,
  displayTextProps,
  suffix,
  style,
  displayFont,
  labelFont,
  color,
  size,
  labelColor,
  status,
  statusColor
}) => {
  const Colors = useColors()
  return (
    <FlexBox
      style={{
        flex: 1,
        paddingTop: Metrics.base,
        // backgroundColor: Colors.backgroundOpacity3,
        boxShadow: 'none',
        position: 'relative',
        ...style
      }}
    >
      <FlexBox
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: color,
          zIndex: 0,
          borderRadius: Metrics.radius
        }}
      ></FlexBox>
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          zIndex: 10
          // alignItems: 'space-between',
          // backgroundColor: 'red'
        }}
      >
        <FlexBox
          style={{
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2,
            paddingTop: size === 'small' ? Metrics.base / 2 : Metrics.base,
            paddingBottom: Metrics.base / 2
            // backgroundColor: 'blue'
          }}
        >
          <Text
            font={
              displayFont
                ? displayFont
                : size === 'large'
                ? 'display'
                : size === 'medium'
                ? 'heading'
                : size === 'small'
                ? 'subheading'
                : 'display'
            }
            // marginBottom={size === 'small' ? 0 : 1}
            style={{
              textAlign: 'left',
              ...displayTextProps
            }} /*color={Colors.primary}*/
          >
            {checkVal(value) ? (
              <>
                {bold ? (
                  <b>
                    {getDisplayVal(value)} {suffix && suffix}
                  </b>
                ) : (
                  <>
                    {getDisplayVal(value)} {suffix && suffix}
                  </>
                )}
              </>
            ) : (
              '-'
            )}
          </Text>
        </FlexBox>
        <FlexBox
          style={{
            width: '100%',
            borderRadius: Metrics.radius,
            // backgroundColor: Colors.subsectionBackground,
            // border: `1px solid ${Colors.backgroundOpacity2}`,
            paddingBottom: size === 'small' ? Metrics.base / 2 : Metrics.base,
            paddingTop: Metrics.base / 2,
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2
            // backgroundColor: 'red'
          }}
        >
          <Text
            color={labelColor ? labelColor : Colors.primary}
            font={
              labelFont
                ? labelFont
                : size === 'large'
                ? 'title'
                : size === 'medium'
                ? 'medium'
                : size === 'small'
                ? 'caption'
                : 'medium'
            }
          >
            {label}
          </Text>
        </FlexBox>
        {status && (
          <Status status={status} getStatusColors={() => statusColor} />
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default InfoBadge
