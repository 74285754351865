import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { checkVal } from '../../Helpers/Functions'

export const GET_CRASH_BY_ASSET_ID = gql`
  query vehicleCrashByAssetId(
    $assetId: String!
    $startDate: Float
    $endDate: Float
    $skip: Int
    $limit: Int
  ) {
    vehicleCrashByAssetId(
      assetId: $assetId
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
    ) {
      _id
    }
  }
`
export const crashDataTransform = (data) => {
  if (data) {
    return {
      ...data
    }
  }
}

const dataTransform = ({ data }) => {
  return data.map((a) => crashDataTransform(a))
}

const getCrashVariables = ({ assetId, startDate, endDate }) => {
  let variables = {
    skip: 0,
    limit: 20
  }
  if (checkVal(assetId)) {
    variables.assetId = assetId
  }
  if (checkVal(startDate)) {
    variables.startDate = startDate
  }
  if (checkVal(endDate)) {
    variables.endDate = endDate
  }
  return variables
}

export const useCrashByAssetId = (crashVariables) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_CRASH_BY_ASSET_ID,
    {
      variables: getCrashVariables(crashVariables),
      queryName: 'vehicleCrashByAssetId',
      skip: !crashVariables.assetId,
      errorMessage: 'Failed to fetch Crashes',
      dataTransform: (data) => dataTransform({ data })
    }
  )
  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    }
  }, [queryData])

  return { data, loading }
}
