import React from 'react'
import MaterialInput from '../MaterialInput'

// Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter }
}) => {
  // const count = preFilteredRows.length
  return (
    <>
      {/*<input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search`} // ${count} records...`}
    />*/}
      <MaterialInput
        styles={{
          outerContainer: {
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0
          }
        }}
        value={filterValue || ''}
        handleChange={(value) => {
          setFilter(value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search`} // ${count} records...`}
      />
    </>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  backgroundColor,
  secondaryColor
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      if (row.values[id]) {
        options.add(row.values[id])
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  const selectValues = options.map((option) => option)
  selectValues.push('')
  const selectDisplayValues = options.map((option) => option)
  selectDisplayValues.push('All')
  return (
    <>
      <select
        value={filterValue}
        style={{
          outline: 'none',
          borderRadius: 4,
          marginTop: 16,
          color: secondaryColor,
          borderColor: backgroundColor,
          backgroundColor: 'transparent'
        }}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value=''>All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      {/*
      <MaterialInput
        type='select'
        styles={{
          outerContainer: {
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
            width: '100%'
          }
        }}
        value={filterValue}
        selectValues={selectValues}
        selectDisplayValues={selectDisplayValues}
        handleChange={(value) => setFilter(value || undefined)}
      />
      */}
    </>
  )
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  backgroundColor,
  secondaryColor
}) => {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <>
      <input
        type='range'
        min={min}
        max={max}
        value={filterValue || min}
        style={{
          outline: 'none',
          borderRadius: 4,
          marginTop: 16,
          color: secondaryColor,
          borderColor: backgroundColor,
          backgroundColor: 'transparent'
        }}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10))
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  )
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export const NumberRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
  backgroundColor,
  secondaryColor
}) => {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop: 8
      }}
    >
      <input
        value={filterValue[0] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        placeholder={`Min (${min})`}
        style={{
          margin: 0,
          width: '50%',
          marginRight: '0.5rem',
          border: `1px solid ${backgroundColor}`,
          backgroundColor: 'transparent'
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type='number'
        onChange={(e) => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        placeholder={`Max (${max})`}
        style={{
          margin: 0,
          width: '50%',
          marginLeft: '0.5rem',
          border: `1px solid ${backgroundColor}`,
          backgroundColor: 'transparent'
        }}
      />
    </div>
  )
}
