import gql from 'graphql-tag'

export const UPDATE_FILTERS = gql`
  mutation updateFilters(
    $site: Int
    $inspectionZone: Int
    $unit: Int
    $eventType: String
    $roadTypes: [Int]
    $startDate: String
    $endDate: String
    $center: Center
  ) {
    updateFilters(
      site: $site
      inspectionZone: $inspectionZone
      unit: $unit
      eventType: $eventType
      roadTypes: $roadTypes
      startDate: $startDate
      endDate: $endDate
      center: $center
    ) @client
  }
`
