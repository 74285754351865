import React, { useState, useEffect } from 'react'
import { FlexBox, Text, Button, MaterialInput } from '../../..'
import { Metrics, useColors } from '../../../../Themes'
import { Subheading } from '../../../Typography'
import SearchBar from '../SearchBar'
import { checkVal, thousandsSeparator } from '../../../../Helpers/Functions'
import { Link } from 'react-router-dom'
import {
  filters,
  selectedAssetId,
  selectedDeviceId,
  selectedDriverId,
  selectedEventId,
  selectedTabs,
  selectedTripId
} from '../../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { useAssetGroups } from '../../../../Graphql/Queries/Asset/useAssetGroups'
import { useUser } from '../../../../Graphql/Queries/useUser'

const CardsHeader = ({
  title,
  number,
  items,
  searchInputString,
  setSearchInputString,
  addButton,
  addButtonOnClick,
  moreFiltersPath,
  activeFiltersCount,
  moreFiltersTabName,
  hideFilterButton,
  searchInputRef
}) => {
  const Colors = useColors()
  const { orgIds, groupId } = useReactiveVar(filters)
  const { data: assetGroups, loading } = useAssetGroups()
  const [groupOptions, setGroupOptions] = useState([])
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []

  useEffect(() => {
    if (assetGroups && assetGroups.length > 0) {
      setGroupOptions([...assetGroups, { name: 'Ungrouped', _id: 'NO_GROUP' }])
    }
  }, [assetGroups])

  return (
    <FlexBox
      direction='column'
      style={{
        alignItems: 'flex-start',
        width: '100%',
        padding: Metrics.base * 2,
        paddingTop: Metrics.base * 3,
        paddingBottom: 0
        // backgroundColor: 'red'
        // borderBottom: `1px solid ${Colors.dividerColor}`
        // marginBottom: Metrics.base * 2
      }}
    >
      <FlexBox
        direction='row'
        justifyContent='space-between'
        style={{
          alignItems: 'flex-start',
          width: '100%',
          marginBottom: Metrics.base * 2
        }}
      >
        <FlexBox direction='column' style={{ alignItems: 'flex-start' }}>
          <Subheading marginBottom={1}>
            {checkVal(number) && !isNaN(number)
              ? thousandsSeparator(number)
              : 0}{' '}
            {title}
          </Subheading>
          {user && user.userType === 'ADMIN' && (
            <MaterialInput
              type='multipleSelect'
              label='Organisation'
              showClearButton
              styles={{
                outerContainer: {
                  marginRight: Metrics.base,
                  width: 150
                  // marginLeft: Metrics.base * 4,
                  // marginTop: Metrics.base
                }
              }}
              fetching={userLoading}
              value={orgIds ? orgIds : []}
              selectValues={orgOptions.map((org) => org._id)}
              selectDisplayValues={orgOptions.map((org) => org.name)}
              handleChange={(value) => {
                filters({ ...filters(), orgIds: [...value] })
                selectedAssetId(null)
                selectedDriverId(null)
                selectedDeviceId(null)
                selectedTripId(null)
                selectedEventId(null)
              }}
            />
          )}
          <MaterialInput
            type='select'
            label='Asset Group'
            showClearButton
            styles={{
              outerContainer: {
                marginRight: Metrics.base,
                width: 150
                // marginLeft: Metrics.base * 4,
                // marginTop: Metrics.base
              }
            }}
            fetching={loading}
            value={groupId}
            selectValues={groupOptions.map((group) => group._id)}
            selectDisplayValues={groupOptions.map((group) => group.name)}
            handleChange={(value) => {
              filters({ ...filters(), groupId: value })
              selectedAssetId(null)
              selectedDriverId(null)
              selectedDeviceId(null)
              selectedTripId(null)
              selectedEventId(null)
            }}
          />
          {/*<Text marginBottom={2}>
            {checkVal(number) && !isNaN(number)
              ? thousandsSeparator(number)
              : 0}{' '}
            results
            </Text>*/}
        </FlexBox>
        <FlexBox direction='column' style={{ alignItems: 'flex-end' }}>
          {!hideFilterButton && (
            <Link
              to={moreFiltersPath}
              onClick={() =>
                moreFiltersTabName &&
                selectedTabs({
                  ...selectedTabs(),
                  [moreFiltersTabName]: 'more-filters'
                })
              }
              style={{
                border: `1px solid ${Colors.dividerColor}`,
                borderRadius: Metrics.radius,
                marginBottom: Metrics.base
              }}
            >
              <FlexBox
                selectable
                Colors={Colors}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: Metrics.radius,
                  padding: Metrics.base
                }}
              >
                <Text color={Colors.secondary}>
                  <b>More Filters</b>
                </Text>
              </FlexBox>
            </Link>
          )}
          {!hideFilterButton && (
            <Text
              color={
                activeFiltersCount > 0
                  ? Colors.MODERATE_RISK
                  : Colors.textSecondary
              }
              font='caption'
            >
              {activeFiltersCount > 0 ? (
                <>
                  <b>{activeFiltersCount} FILTERS ACTIVE</b>
                </>
              ) : (
                <>{activeFiltersCount} FILTERS ACTIVE</>
              )}
            </Text>
          )}
          {addButton && (
            <Button
              secondary
              condensed
              title={`Add ${addButton}`}
              textColor='white'
              background={Colors.primary}
              onClick={() => {
                addButtonOnClick()
              }}
            />
          )}
        </FlexBox>
      </FlexBox>
      <SearchBar
        title={title}
        inputString={searchInputString}
        setInputString={setSearchInputString}
        searchInputRef={searchInputRef}
      />
    </FlexBox>
  )
}

export default CardsHeader
