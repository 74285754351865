import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { validateEmail } from '../../Helpers/Functions'

export const GET_USER_BY_USERNAME = gql`
  query userByUsername($username: String!) {
    userByUsername(username: $username) {
      userFound
      isActive
    }
  }
`

export const userDataTransform = ({ data }) => {
  return {
    ...data,
    fullName: data.firstName + ' ' + data.lastName
  }
}

export const useUserByUsername = (username) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_USER_BY_USERNAME,
    {
      variables: { username },
      queryName: 'userByUsername',
      errorMessage: 'Failed to fetch user',
      dataTransform: (data) => userDataTransform({ data }),
      skip: !username || !validateEmail(username)
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    } else setData(null)
  }, [queryData])

  return { data, loading }
}
