import { useEffect, useState } from 'react'
import { useDriverScoreAtDate } from '../../../Graphql/Queries/Driver/SafetyScoreHistory/useDriverScoreAtDate'
import { checkVal } from '../../../Helpers/Functions'

const monthInMilliseconds = 2.628e9

export const getChange = ({ latestScore, data }) => {
  const change = latestScore - (!checkVal(data) || data === -1 ? 50 : data)
  return !isNaN(change) ? change.toFixed(2) : null
}

export const useSafetyScoreTrends = ({ driverId }) => {
  const [previousMonthChange, setPreviousMonthChange] = useState()
  const [previousYearChange, setPreviousYearChange] = useState()

  const [now] = useState(new Date().getTime())

  const { data: latestScore } = useDriverScoreAtDate({
    driverId,
    date: now,
    category: 'COMBINED'
  })
  const { data: scoreLastMonthData } = useDriverScoreAtDate({
    driverId,
    date: now - monthInMilliseconds,
    category: 'COMBINED'
  })
  const { data: scoreLastYearData } = useDriverScoreAtDate({
    driverId,
    date: now - monthInMilliseconds,
    category: 'COMBINED'
  })

  useEffect(() => {
    if (
      checkVal(latestScore) &&
      checkVal(scoreLastMonthData) &&
      checkVal(scoreLastYearData)
    ) {
      setPreviousMonthChange(
        getChange({ latestScore, data: scoreLastMonthData })
      )
      setPreviousYearChange(getChange({ latestScore, data: scoreLastYearData }))
    }
  }, [latestScore, scoreLastMonthData, scoreLastYearData])

  return {
    previousMonthChange,
    previousYearChange
  }
}
