import React from 'react'
import { FormGraphQL } from '../../Components'
import {
  CREATE_ASSET_GROUP,
  UPDATE_ASSET_GROUP
} from '../../Graphql/Mutations/useMutateAssetGroup'

const handleSubmit = ({
  handleClose,
  data,
  id,
  orgId,
  assetIds,
  setSubmitting,
  mutate
}) => {
  setSubmitting(true)
  const variables = {
    id,
    name: data.name,
    description: data.description,
    orgId,
    assetIds
  }
  mutate({
    variables,
    onCompleted: () => [setSubmitting(false), handleClose()],
    onError: () => [setSubmitting(false), handleClose()]
  })
}

const AssetGroupForm = ({
  object,
  orgId,
  assetIds,
  handleClose = () => {}
}) => {
  return (
    <FormGraphQL
      object={object}
      objectName='Asset Gruop'
      createMutation={CREATE_ASSET_GROUP}
      updateMutation={UPDATE_ASSET_GROUP}
      writeToListOnUpdate={true}
      listQueryName='assetGroups'
      queryName='assetGroup'
      handleCancel={handleClose}
      handleSubmit={(data) =>
        handleSubmit({
          handleClose,
          data: data.data,
          id: object.id,
          orgId,
          assetIds,
          setSubmitting: data.setSubmitting,
          mutate: data.mutate,
          type: object ? 'CREATE' : 'NEW'
        })
      }
      formSchema={[
        {
          name: 'name',
          label: 'Name'
        },
        {
          name: 'description',
          label: 'Description'
        }
      ]}
    />
  )
}

export default AssetGroupForm
