import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useFilters } from '../useFilters'
import { checkVal } from '../../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import { assetFilters, filters } from '../../GraphQLClient'

export const GET_ASSETS = gql`
  query assets(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $assetType: [String]
    $skip: Int
    $limit: Int
    $searchString: String
    $safetyCategory: [String]
  ) {
    assets(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      assetType: $assetType
      skip: $skip
      limit: $limit
      searchString: $searchString
      safetyCategory: $safetyCategory
    ) {
      assets {
        _id
        displayId
        device {
          _id
        }
        driver {
          _id
        }
      }
      totalCount
    }
  }
`

const dataTransform = ({ data }) => {
  const deviceIds = []
  const assetIds = []
  const driverIds = []
  const queryData =
    data &&
    data.assets &&
    data.assets.map((a) => {
      if (a.device && a.device._id) {
        deviceIds.push(a.device._id)
        assetIds.push(a._id)
        driverIds.push(a.driver ? a.driver._id : null)
      }
      return a
    })
  return {
    queryData,
    liveViewIds: {
      deviceIds,
      assetIds,
      driverIds
    },
    totalCount: data.totalCount
  }
}

export const getAssetsVariables = ({
  orgId,
  groupId,
  orgIdsFilter,
  assetTypes,
  safetyCategory,
  searchString,
  skip,
  limit
}) => {
  let variables = {
    skip: skip ? skip : 0,
    limit: limit ? limit : 10
  }
  if (checkVal(orgId)) {
    variables = { ...variables, orgIdsFilter: [orgId] }
  } else if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(assetTypes)) {
    variables = { ...variables, assetType: assetTypes }
  }
  if (checkVal(safetyCategory)) {
    variables = { ...variables, safetyCategory }
  }
  if (checkVal(searchString)) {
    variables = { ...variables, searchString }
  }
  return variables
}

export const useAssetIds = () => {
  const { assetTypes, searchString, safetyCategories } =
    useReactiveVar(assetFilters)
  const { groupId, orgIds } = useReactiveVar(filters)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [liveViewIds, setLiveViewIds] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_ASSETS, {
    variables: getAssetsVariables({
      groupId,
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      searchString,
      assetTypes,
      safetyCategory: safetyCategories
    }),
    queryName: 'assets',
    errorMessage: 'Failed to fetch Asset Ids',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData.queryData)
      setLiveViewIds(queryData.liveViewIds)
      setTotalCount(queryData.totalCount)
    }
  }, [queryData])

  return {
    data,
    liveViewIds,
    totalCount,
    loading,
    fetchMore,
    networkStatus
  }
}
