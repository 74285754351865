import React from 'react'
import { FlexBox, DataDisplay } from '../../../../Components'

import { checkVal } from '../../../../Helpers/Functions'

const inspectionFieldValueKeys = {
  GOOD: 'Good Condition',
  REPAIR: 'Repair Required',
  REPLACE: 'Replace Required'
}

export const BeforeStart = ({ latestInspection, Colors }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        // paddingBottom: Metrics.base * 3,
        // height: '100%',
        maxHeight: 200,
        overflow: 'scroll'
      }}
    >
      <DataDisplay
        label='Engine Oil Level'
        labelLink={
          checkVal(latestInspection.engineOilLevel) &&
          checkVal(latestInspection.engineOilLevel.itemImage) &&
          checkVal(latestInspection.engineOilLevel.itemImage.url)
            ? latestInspection.engineOilLevel.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.engineOilLevel)
            ? inspectionFieldValueKeys[
                latestInspection.engineOilLevel.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.engineOilLevel) &&
          Colors[latestInspection.engineOilLevel.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Engine Oil Leaks'
        labelLink={
          checkVal(latestInspection.engineOilLeaks) &&
          checkVal(latestInspection.engineOilLeaks.itemImage) &&
          checkVal(latestInspection.engineOilLeaks.itemImage.url)
            ? latestInspection.engineOilLeaks.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.engineOilLeaks)
            ? inspectionFieldValueKeys[
                latestInspection.engineOilLeaks.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.engineOilLeaks) &&
          Colors[latestInspection.engineOilLeaks.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Water level'
        labelLink={
          checkVal(latestInspection.waterLevel) &&
          checkVal(latestInspection.waterLevel.itemImage) &&
          checkVal(latestInspection.waterLevel.itemImage.url)
            ? latestInspection.waterLevel.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.waterLevel)
            ? inspectionFieldValueKeys[
                latestInspection.waterLevel.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.waterLevel) &&
          Colors[latestInspection.waterLevel.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Water Leaks'
        labelLink={
          checkVal(latestInspection.waterLeaks) &&
          checkVal(latestInspection.waterLeaks.itemImage) &&
          checkVal(latestInspection.waterLeaks.itemImage.url)
            ? latestInspection.waterLeaks.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.waterLeaks)
            ? inspectionFieldValueKeys[
                latestInspection.waterLeaks.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.waterLeaks) &&
          Colors[latestInspection.waterLeaks.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Fuel Leak (Caps)'
        labelLink={
          checkVal(latestInspection.fuelLeaksCap) &&
          checkVal(latestInspection.fuelLeaksCap.itemImage) &&
          checkVal(latestInspection.fuelLeaksCap.itemImage.url)
            ? latestInspection.fuelLeaksCap.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.fuelLeaksCap)
            ? inspectionFieldValueKeys[
                latestInspection.fuelLeaksCap.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.fuelLeaksCap) &&
          Colors[latestInspection.fuelLeaksCap.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Fan Belts'
        labelLink={
          checkVal(latestInspection.fanBelts) &&
          checkVal(latestInspection.fanBelts.itemImage) &&
          checkVal(latestInspection.fanBelts.itemImage.url)
            ? latestInspection.fanBelts.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.fanBelts)
            ? inspectionFieldValueKeys[
                latestInspection.fanBelts.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.fanBelts) &&
          Colors[latestInspection.fanBelts.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Hoses'
        labelLink={
          checkVal(latestInspection.hoses) &&
          checkVal(latestInspection.hoses.itemImage) &&
          checkVal(latestInspection.hoses.itemImage.url)
            ? latestInspection.hoses.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.hoses)
            ? inspectionFieldValueKeys[
                latestInspection.hoses.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.hoses) &&
          Colors[latestInspection.hoses.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Brake Fluid Oil'
        labelLink={
          checkVal(latestInspection.brakeFluidLevel) &&
          checkVal(latestInspection.brakeFluidLevel.itemImage) &&
          checkVal(latestInspection.brakeFluidLevel.itemImage.url)
            ? latestInspection.brakeFluidLevel.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.brakeFluidLevel)
            ? inspectionFieldValueKeys[
                latestInspection.brakeFluidLevel.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          Colors[
            checkVal(latestInspection.brakeFluidLevel) &&
              latestInspection.brakeFluidLevel.inspectionCheckType
          ]
        }
      />
      <DataDisplay
        label='Clutch Fluid Level'
        labelLink={
          checkVal(latestInspection.clutchFluidLevel) &&
          checkVal(latestInspection.clutchFluidLevel.itemImage) &&
          checkVal(latestInspection.clutchFluidLevel.itemImage.url)
            ? latestInspection.clutchFluidLevel.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.clutchFluidLevel)
            ? inspectionFieldValueKeys[
                latestInspection.clutchFluidLevel.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          Colors[
            checkVal(latestInspection.clutchFluidLevel) &&
              latestInspection.clutchFluidLevel.inspectionCheckType
          ]
        }
      />
      <DataDisplay
        label='Battery'
        labelLink={
          checkVal(latestInspection.battery) &&
          checkVal(latestInspection.battery.itemImage) &&
          checkVal(latestInspection.battery.itemImage.url)
            ? latestInspection.battery.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.battery)
            ? inspectionFieldValueKeys[
                latestInspection.battery.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.battery) &&
          Colors[latestInspection.battery.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Wheel Nuts'
        labelLink={
          checkVal(latestInspection.wheelNuts) &&
          checkVal(latestInspection.wheelNuts.itemImage) &&
          checkVal(latestInspection.wheelNuts.itemImage.url)
            ? latestInspection.wheelNuts.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.wheelNuts)
            ? inspectionFieldValueKeys[
                latestInspection.wheelNuts.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.wheelNuts) &&
          Colors[latestInspection.wheelNuts.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Tyres'
        labelLink={
          checkVal(latestInspection.tyres) &&
          checkVal(latestInspection.tyres.itemImage) &&
          checkVal(latestInspection.tyres.itemImage.url)
            ? latestInspection.tyres.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.tyres)
            ? inspectionFieldValueKeys[
                latestInspection.tyres.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.tyres) &&
          Colors[latestInspection.tyres.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Brakes'
        labelLink={
          checkVal(latestInspection.brakes) &&
          checkVal(latestInspection.brakes.itemImage) &&
          checkVal(latestInspection.brakes.itemImage.url)
            ? latestInspection.brakes.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.brakes)
            ? inspectionFieldValueKeys[
                latestInspection.brakes.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.brakes) &&
          Colors[latestInspection.brakes.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Body Condition'
        labelLink={
          checkVal(latestInspection.bodyCondition) &&
          checkVal(latestInspection.bodyCondition.itemImage) &&
          checkVal(latestInspection.bodyCondition.itemImage.url)
            ? latestInspection.bodyCondition.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.bodyCondition)
            ? inspectionFieldValueKeys[
                latestInspection.bodyCondition.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          Colors[
            checkVal(latestInspection.bodyCondition) &&
              latestInspection.bodyCondition.inspectionCheckType
          ]
        }
      />
      <DataDisplay
        label='Rear Underrun'
        labelLink={
          checkVal(latestInspection.rearUnderrun) &&
          checkVal(latestInspection.rearUnderrun.itemImage) &&
          checkVal(latestInspection.rearUnderrun.itemImage.url)
            ? latestInspection.rearUnderrun.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.rearUnderrun)
            ? inspectionFieldValueKeys[
                latestInspection.rearUnderrun.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          Colors[
            checkVal(latestInspection.rearUnderrun) &&
              latestInspection.rearUnderrun.inspectionCheckType
          ]
        }
      />
      <DataDisplay
        label='Chevron'
        labelLink={
          checkVal(latestInspection.chevron) &&
          checkVal(latestInspection.chevron.itemImage) &&
          checkVal(latestInspection.chevron.itemImage.url)
            ? latestInspection.chevron.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.chevron)
            ? inspectionFieldValueKeys[
                latestInspection.chevron.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.chevron) &&
          Colors[latestInspection.chevron.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Reflectors and Yellow Strips'
        labelLink={
          checkVal(latestInspection.reflectorsAndStrips) &&
          checkVal(latestInspection.reflectorsAndStrips.itemImage) &&
          checkVal(latestInspection.reflectorsAndStrips.itemImage.url)
            ? latestInspection.reflectorsAndStrips.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.reflectorsAndStrips)
            ? inspectionFieldValueKeys[
                latestInspection.reflectorsAndStrips.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.reflectorsAndStrips) &&
          Colors[latestInspection.reflectorsAndStrips.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Mirrors'
        labelLink={
          checkVal(latestInspection.mirrors) &&
          checkVal(latestInspection.mirrors.itemImage) &&
          checkVal(latestInspection.mirrors.itemImage.url)
            ? latestInspection.mirrors.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.mirrors)
            ? inspectionFieldValueKeys[
                latestInspection.mirrors.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.mirrors) &&
          Colors[latestInspection.mirrors.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Windows'
        labelLink={
          checkVal(latestInspection.windows) &&
          checkVal(latestInspection.windows.itemImage) &&
          checkVal(latestInspection.windows.itemImage.url)
            ? latestInspection.windows.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.windows)
            ? inspectionFieldValueKeys[
                latestInspection.windows.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.windows) &&
          Colors[latestInspection.windows.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Other Comments'
        data={
          checkVal(latestInspection.otherBeforeStarting)
            ? latestInspection.otherBeforeStarting
            : '-'
        }
      />
    </FlexBox>
  )
}

export const AncillaryEquipment = ({ latestInspection, Colors }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxHeight: 200,
        overflow: 'scroll'
      }}
    >
      <DataDisplay
        label='Jack'
        labelLink={
          checkVal(latestInspection.jack) &&
          checkVal(latestInspection.jack.itemImage) &&
          checkVal(latestInspection.jack.itemImage.url)
            ? latestInspection.jack.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.jack)
            ? inspectionFieldValueKeys[
                latestInspection.jack.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.jack) &&
          Colors[latestInspection.jack.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Wheel Chocks'
        labelLink={
          checkVal(latestInspection.wheelChocks) &&
          checkVal(latestInspection.wheelChocks.itemImage) &&
          checkVal(latestInspection.wheelChocks.itemImage.url)
            ? latestInspection.wheelChocks.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.wheelChocks)
            ? inspectionFieldValueKeys[
                latestInspection.wheelChocks.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.wheelChocks) &&
          Colors[latestInspection.wheelChocks.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Fire Extinguishers'
        labelLink={
          checkVal(latestInspection.fireExtinguisher) &&
          checkVal(latestInspection.fireExtinguisher.itemImage) &&
          checkVal(latestInspection.fireExtinguisher.itemImage.url)
            ? latestInspection.fireExtinguisher.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.fireExtinguisher)
            ? inspectionFieldValueKeys[
                latestInspection.fireExtinguisher.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.fireExtinguisher) &&
          Colors[latestInspection.fireExtinguisher.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Spare fuses, Bulbs, and Fan belts'
        labelLink={
          checkVal(latestInspection.spareFusesBulbsFanbelts) &&
          checkVal(latestInspection.spareFusesBulbsFanbelts.itemImage) &&
          checkVal(latestInspection.spareFusesBulbsFanbelts.itemImage.url)
            ? latestInspection.spareFusesBulbsFanbelts.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.spareFusesBulbsFanbelts)
            ? inspectionFieldValueKeys[
                latestInspection.spareFusesBulbsFanbelts.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.spareFusesBulbsFanbelts) &&
          Colors[latestInspection.spareFusesBulbsFanbelts.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Warning Triangles'
        labelLink={
          checkVal(latestInspection.warningTriangles) &&
          checkVal(latestInspection.warningTriangles.itemImage) &&
          checkVal(latestInspection.warningTriangles.itemImage.url)
            ? latestInspection.warningTriangles.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.warningTriangles)
            ? inspectionFieldValueKeys[
                latestInspection.warningTriangles.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.warningTriangles) &&
          Colors[latestInspection.warningTriangles.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Placarding'
        labelLink={
          checkVal(latestInspection.placarding) &&
          checkVal(latestInspection.placarding.itemImage) &&
          checkVal(latestInspection.placarding.itemImage.url)
            ? latestInspection.placarding.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.placarding)
            ? inspectionFieldValueKeys[
                latestInspection.placarding.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.placarding) &&
          Colors[latestInspection.placarding.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Permits and Documents'
        labelLink={
          checkVal(latestInspection.permitsAndDocuments) &&
          checkVal(latestInspection.permitsAndDocuments.itemImage) &&
          checkVal(latestInspection.permitsAndDocuments.itemImage.url)
            ? latestInspection.permitsAndDocuments.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.permitsAndDocuments)
            ? inspectionFieldValueKeys[
                latestInspection.permitsAndDocuments.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.permitsAndDocuments) &&
          Colors[latestInspection.permitsAndDocuments.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Tachograph Charts'
        labelLink={
          checkVal(latestInspection.tachographCharts) &&
          checkVal(latestInspection.tachographCharts.itemImage) &&
          checkVal(latestInspection.tachographCharts.itemImage.url)
            ? latestInspection.tachographCharts.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.tachographCharts)
            ? inspectionFieldValueKeys[
                latestInspection.tachographCharts.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.tachographCharts) &&
          Colors[latestInspection.tachographCharts.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Load and Fastenings'
        labelLink={
          checkVal(latestInspection.loadAndFastenings) &&
          checkVal(latestInspection.loadAndFastenings.itemImage) &&
          checkVal(latestInspection.loadAndFastenings.itemImage.url)
            ? latestInspection.loadAndFastenings.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.loadAndFastenings)
            ? inspectionFieldValueKeys[
                latestInspection.loadAndFastenings.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.loadAndFastenings) &&
          Colors[latestInspection.loadAndFastenings.inspectionCheckType]
        }
      />
      <DataDisplay
        label='First Aid Kit'
        labelLink={
          checkVal(latestInspection.firstAidKit) &&
          checkVal(latestInspection.firstAidKit.itemImage) &&
          checkVal(latestInspection.firstAidKit.itemImage.url)
            ? latestInspection.firstAidKit.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.firstAidKit)
            ? inspectionFieldValueKeys[
                latestInspection.firstAidKit.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.firstAidKit) &&
          Colors[latestInspection.firstAidKit.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Wheel nuts'
        labelLink={
          checkVal(latestInspection.wheelNutsAncillary) &&
          checkVal(latestInspection.wheelNutsAncillary.itemImage) &&
          checkVal(latestInspection.wheelNutsAncillary.itemImage.url)
            ? latestInspection.wheelNutsAncillary.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.wheelNutsAncillary)
            ? inspectionFieldValueKeys[
                latestInspection.wheelNutsAncillary.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.wheelNutsAncillary) &&
          Colors[latestInspection.wheelNutsAncillary.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Other comments'
        data={
          checkVal(latestInspection.otherAncillary)
            ? latestInspection.otherAncillary
            : '-'
        }
      />
    </FlexBox>
  )
}

export const AfterStart = ({ latestInspection, Colors }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxHeight: 200,
        overflow: 'scroll'
      }}
    >
      <DataDisplay
        label='Hand Brake Action'
        labelLink={
          checkVal(latestInspection.handBrakeAction) &&
          checkVal(latestInspection.handBrakeAction.itemImage) &&
          checkVal(latestInspection.handBrakeAction.itemImage.url)
            ? latestInspection.handBrakeAction.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.handBrakeAction)
            ? inspectionFieldValueKeys[
                latestInspection.handBrakeAction.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.handBrakeAction) &&
          Colors[latestInspection.handBrakeAction.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Foot Brake Action'
        labelLink={
          checkVal(latestInspection.footBrakeAction) &&
          checkVal(latestInspection.footBrakeAction.itemImage) &&
          checkVal(latestInspection.footBrakeAction.itemImage.url)
            ? latestInspection.footBrakeAction.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.footBrakeAction)
            ? inspectionFieldValueKeys[
                latestInspection.footBrakeAction.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.footBrakeAction) &&
          Colors[latestInspection.footBrakeAction.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Clutch Pedal Play'
        labelLink={
          checkVal(latestInspection.clutchPedalPlay) &&
          checkVal(latestInspection.clutchPedalPlay.itemImage) &&
          checkVal(latestInspection.clutchPedalPlay.itemImage.url)
            ? latestInspection.clutchPedalPlay.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.clutchPedalPlay)
            ? inspectionFieldValueKeys[
                latestInspection.clutchPedalPlay.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.clutchPedalPlay) &&
          Colors[latestInspection.clutchPedalPlay.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Steering'
        labelLink={
          checkVal(latestInspection.steering) &&
          checkVal(latestInspection.steering.itemImage) &&
          checkVal(latestInspection.steering.itemImage.url)
            ? latestInspection.steering.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.steering)
            ? inspectionFieldValueKeys[
                latestInspection.steering.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.steering) &&
          Colors[latestInspection.steering.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Horn'
        labelLink={
          checkVal(latestInspection.horn) &&
          checkVal(latestInspection.horn.itemImage) &&
          checkVal(latestInspection.horn.itemImage.url)
            ? latestInspection.horn.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.horn)
            ? inspectionFieldValueKeys[
                latestInspection.horn.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.horn) &&
          Colors[latestInspection.horn.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Windscreen, Wipers, and Washers'
        labelLink={
          checkVal(latestInspection.windscreenWipersAndWashers) &&
          checkVal(latestInspection.windscreenWipersAndWashers.itemImage) &&
          checkVal(latestInspection.windscreenWipersAndWashers.itemImage.url)
            ? latestInspection.windscreenWipersAndWashers.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.windscreenWipersAndWashers)
            ? inspectionFieldValueKeys[
                latestInspection.windscreenWipersAndWashers.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.windscreenWipersAndWashers) &&
          Colors[
            latestInspection.windscreenWipersAndWashers.inspectionCheckType
          ]
        }
      />
      <DataDisplay
        label='Warning lights'
        labelLink={
          checkVal(latestInspection.warningLights) &&
          checkVal(latestInspection.warningLights.itemImage) &&
          checkVal(latestInspection.warningLights.itemImage.url)
            ? latestInspection.warningLights.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.warningLights)
            ? inspectionFieldValueKeys[
                latestInspection.warningLights.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.warningLights) &&
          Colors[latestInspection.warningLights.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Gauges'
        labelLink={
          checkVal(latestInspection.gauges) &&
          checkVal(latestInspection.gauges.itemImage) &&
          checkVal(latestInspection.gauges.itemImage.url)
            ? latestInspection.gauges.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.gauges)
            ? inspectionFieldValueKeys[
                latestInspection.gauges.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.gauges) &&
          Colors[latestInspection.gauges.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Air pressure, Buzzer, and Gauges'
        labelLink={
          checkVal(latestInspection.airPressureBuzzerGauges) &&
          checkVal(latestInspection.airPressureBuzzerGauges.itemImage) &&
          checkVal(latestInspection.airPressureBuzzerGauges.itemImage.url)
            ? latestInspection.airPressureBuzzerGauges.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.airPressureBuzzerGauges)
            ? inspectionFieldValueKeys[
                latestInspection.airPressureBuzzerGauges.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.airPressureBuzzerGauges) &&
          Colors[latestInspection.airPressureBuzzerGauges.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Lights'
        labelLink={
          checkVal(latestInspection.lights) &&
          checkVal(latestInspection.lights.itemImage) &&
          checkVal(latestInspection.lights.itemImage.url)
            ? latestInspection.lights.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.lights)
            ? inspectionFieldValueKeys[
                latestInspection.lights.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.lights) &&
          Colors[latestInspection.lights.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Indicators'
        labelLink={
          checkVal(latestInspection.indicators) &&
          checkVal(latestInspection.indicators.itemImage) &&
          checkVal(latestInspection.indicators.itemImage.url)
            ? latestInspection.indicators.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.indicators)
            ? inspectionFieldValueKeys[
                latestInspection.indicators.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.indicators) &&
          Colors[latestInspection.indicators.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Auxillary Equipment'
        labelLink={
          checkVal(latestInspection.auxiliaryEquipment) &&
          checkVal(latestInspection.auxiliaryEquipment.itemImage) &&
          checkVal(latestInspection.auxiliaryEquipment.itemImage.url)
            ? latestInspection.auxiliaryEquipment.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.auxiliaryEquipment)
            ? inspectionFieldValueKeys[
                latestInspection.auxiliaryEquipment.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.auxiliaryEquipment) &&
          Colors[latestInspection.auxiliaryEquipment.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Other comments'
        data={
          checkVal(latestInspection.otherEngineStart)
            ? latestInspection.otherEngineStart
            : '-'
        }
      />
    </FlexBox>
  )
}
export const TrailerInspectionData = ({ latestInspection, Colors }) => {
  return (
    <FlexBox
      direction='column'
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        maxHeight: 200,
        overflow: 'scroll'
      }}
    >
      <DataDisplay
        label='Trailer No.'
        labelLink={
          checkVal(latestInspection.trailerNo) &&
          checkVal(latestInspection.trailerNo.itemImage) &&
          checkVal(latestInspection.trailerNo.itemImage.url)
            ? latestInspection.trailerNo.itemImage.url
            : null
        }
        data={
          checkVal(latestInspection.trailerNo)
            ? latestInspection.trailerNo
            : '-'
        }
      />
      <DataDisplay
        label='Hook up and Fifth Wheel'
        labelLink={
          checkVal(latestInspection.hookUpAndFifthWheel) &&
          checkVal(latestInspection.hookUpAndFifthWheel.itemImage) &&
          checkVal(latestInspection.hookUpAndFifthWheel.itemImage.url)
            ? latestInspection.hookUpAndFifthWheel.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.hookUpAndFifthWheel)
            ? inspectionFieldValueKeys[
                latestInspection.hookUpAndFifthWheel.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.hookUpAndFifthWheel) &&
          Colors[latestInspection.hookUpAndFifthWheel.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Air Hose Connections'
        labelLink={
          checkVal(latestInspection.airHoseConnections) &&
          checkVal(latestInspection.airHoseConnections.itemImage) &&
          checkVal(latestInspection.airHoseConnections.itemImage.url)
            ? latestInspection.airHoseConnections.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.airHoseConnections)
            ? inspectionFieldValueKeys[
                latestInspection.airHoseConnections.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.airHoseConnections) &&
          Colors[latestInspection.airHoseConnections.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Brakes Trailer'
        labelLink={
          checkVal(latestInspection.brakesTrailer) &&
          checkVal(latestInspection.brakesTrailer.itemImage) &&
          checkVal(latestInspection.brakesTrailer.itemImage.url)
            ? latestInspection.brakesTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.brakesTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.brakesTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.brakesTrailer) &&
          Colors[latestInspection.brakesTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Wheel Nuts Trailer'
        labelLink={
          checkVal(latestInspection.wheelNutsTrailer) &&
          checkVal(latestInspection.wheelNutsTrailer.itemImage) &&
          checkVal(latestInspection.wheelNutsTrailer.itemImage.url)
            ? latestInspection.wheelNutsTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.wheelNutsTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.wheelNutsTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.wheelNutsTrailer) &&
          Colors[latestInspection.wheelNutsTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Tyres trailer'
        labelLink={
          checkVal(latestInspection.tyresTrailer) &&
          checkVal(latestInspection.tyresTrailer.itemImage) &&
          checkVal(latestInspection.tyresTrailer.itemImage.url)
            ? latestInspection.tyresTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.tyresTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.tyresTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.tyresTrailer) &&
          Colors[latestInspection.tyresTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Semi Trailer Landing Gear'
        labelLink={
          checkVal(latestInspection.semiTrailerLandingGear) &&
          checkVal(latestInspection.semiTrailerLandingGear.itemImage) &&
          checkVal(latestInspection.semiTrailerLandingGear.itemImage.url)
            ? latestInspection.semiTrailerLandingGear.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.semiTrailerLandingGear)
            ? inspectionFieldValueKeys[
                latestInspection.semiTrailerLandingGear.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.semiTrailerLandingGear) &&
          Colors[latestInspection.semiTrailerLandingGear.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Tow Bar Safety Chains'
        labelLink={
          checkVal(latestInspection.towBarSafetyChains) &&
          checkVal(latestInspection.towBarSafetyChains.itemImage) &&
          checkVal(latestInspection.towBarSafetyChains.itemImage.url)
            ? latestInspection.towBarSafetyChains.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.towBarSafetyChains)
            ? inspectionFieldValueKeys[
                latestInspection.towBarSafetyChains.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.towBarSafetyChains) &&
          Colors[latestInspection.towBarSafetyChains.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Body Condition Trailer'
        labelLink={
          checkVal(latestInspection.bodyConditionTrailer) &&
          checkVal(latestInspection.bodyConditionTrailer.itemImage) &&
          checkVal(latestInspection.bodyConditionTrailer.itemImage.url)
            ? latestInspection.bodyConditionTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.bodyConditionTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.bodyConditionTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.bodyConditionTrailer) &&
          Colors[latestInspection.bodyConditionTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Rear Underrun'
        labelLink={
          checkVal(latestInspection.rearUnderrunTrailer) &&
          checkVal(latestInspection.rearUnderrunTrailer.itemImage) &&
          checkVal(latestInspection.rearUnderrunTrailer.itemImage.url)
            ? latestInspection.rearUnderrunTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.rearUnderrunTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.rearUnderrunTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.rearUnderrunTrailer) &&
          Colors[latestInspection.rearUnderrunTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Chevron'
        labelLink={
          checkVal(latestInspection.chevronTrailer) &&
          checkVal(latestInspection.chevronTrailer.itemImage) &&
          checkVal(latestInspection.chevronTrailer.itemImage.url)
            ? latestInspection.chevronTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.chevronTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.chevronTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.chevronTrailer) &&
          Colors[latestInspection.chevronTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Trailer Reflectors'
        labelLink={
          checkVal(latestInspection.reflectorsTrailer) &&
          checkVal(latestInspection.reflectorsTrailer.itemImage) &&
          checkVal(latestInspection.reflectorsTrailer.itemImage.url)
            ? latestInspection.reflectorsTrailer.itemImage.url
            : null
        }
        status={
          checkVal(latestInspection.reflectorsTrailer)
            ? inspectionFieldValueKeys[
                latestInspection.reflectorsTrailer.inspectionCheckType
              ]
            : '-'
        }
        statusColor={
          checkVal(latestInspection.reflectorsTrailer) &&
          Colors[latestInspection.reflectorsTrailer.inspectionCheckType]
        }
      />
      <DataDisplay
        label='Other comments'
        data={
          checkVal(latestInspection.otherTrailer)
            ? latestInspection.otherTrailer
            : '-'
        }
      />
    </FlexBox>
  )
}
