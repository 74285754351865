import React, { useEffect, useState } from 'react'
import { FlexBox, Subsection } from '../../../../Components'
import { Metrics } from '../../../../Themes'
import EventsVsTimeChart from './EventsVsTimeChart'
import ScoreVsTimeChart from './ScoreVsTimeChart'
import CategorySummarySingleEvent from './CategorySummarySingleEvent'
import { useSpeedingScoreHistory } from '../../../../Graphql/Queries/Driver/SafetyScoreHistory/useSpeedingScoreHistory'
import { useScoreHistory } from './useScoreHistory'
import EventsVsTimeScatterPlot from './EventsVsTimeScatterPlot'

export const getEventCategoryCounts = (events) => {
  const eventCategoryCounts = {
    SEVERE: 0,
    HEAVY: 0,
    MODERATE: 0,
    LIGHT: 0,
    VERY_LIGHT: 0
  }
  for (var i = 0; i < events.length; i++) {
    eventCategoryCounts[events[i].category] += 1
  }
  return eventCategoryCounts
}

const Speeding = ({
  driverId,
  score,
  events,
  eventsLoading,
  eventTypeCounts
}) => {
  const { data: scoreHistoryData, loading: scoreHistoryLoading } =
    useSpeedingScoreHistory({ driverId })
  const {
    scoreHistory,
    previousWeekChange,
    previousMonthChange,
    previousYearChange
  } = useScoreHistory({ driverId, scoreHistoryData, category: 'SPEEDING' })

  return (
    <FlexBox direction='column'>
      <CategorySummarySingleEvent
        previousWeekChange={previousWeekChange}
        previousMonthChange={previousMonthChange}
        previousYearChange={previousYearChange}
        eventsCounts={eventTypeCounts ? eventTypeCounts : {}}
        eventCategoryCounts={getEventCategoryCounts(events)}
      />
      <ScoreVsTimeChart
        currentScore={score}
        data={scoreHistory}
        loading={scoreHistoryLoading}
      />
      <EventsVsTimeScatterPlot
        events={events}
        colorType='severity'
        loading={eventsLoading}
      />
      <EventsVsTimeChart
        events={events}
        loading={eventsLoading}
        groupBy='category'
        keys={['SEVERE', 'HEAVY', 'MODERATE', 'LIGHT', 'VERY_LIGHT']}
      />
    </FlexBox>
  )
}

export default Speeding
