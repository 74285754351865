import React, { useEffect, useRef, useState } from 'react'
import { Metrics, useColors, Utils } from '../../Themes'
import { FlexBox, Icon, IconButton, Subsection, Text } from '../../Components'
import {
  selectedEventId,
  selectedTripId,
  tripReplayCamera,
  tripReplaySeekBarTime
} from '../../Graphql/GraphQLClient'
import NoneSelected from '../../Components/Custom/NoneSelected'
import { checkVal } from '../../Helpers/Functions'
import { useReactiveVar } from '@apollo/client'
import { SpeedometerDisplay } from '../LiveMonitoring/SideBar'
import {
  tripReplayAkimaFunctions,
  tripReplaySpeedLimitData,
  tripReplayTime
} from '../../Graphql/GraphQLClient'
import { useEventsByTripId } from '../../Graphql/Queries/Event/useEventsByTripId'
import { Display, Title, Title2 } from '../../Components/Typography'
import { InsetCardContainer } from '../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import EventCard from '../../Components/Custom/SelectionPanel/Event/EventCard'
import VideoPlayer from '../../Components/Custom/VideoPlayer'
import { useAsset } from '../../Graphql/Queries/Asset/useAsset'
import { useVideoClipByEventId } from '../../Graphql/Queries/useVideoClipByEventId'
import { useNavigation } from '../../Components/CustomHooks/useNavigation'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import TripReplayStreetView from '../../Components/Custom/TripReplayStreetView'
import { ButtonBase } from '@material-ui/core'

const getSpeedLimit = ({ time, speedLimitData }) => {
  for (var i = 0; i < speedLimitData.length; i++) {
    if (
      time <= speedLimitData[i].endTime &&
      time >= speedLimitData[i].startTime
    ) {
      return speedLimitData[i].speedLimit
    }
  }
}

const eventTimePadding = 20000

const getCurrentEvents = ({ time, events }) => {
  for (var i = 0; i < events.length; i++) {
    const startTime = events[i].startDate
      ? events[i].startDate.getTime() - eventTimePadding
      : null
    const endTime = events[i].endDate
      ? events[i].endDate.getTime() + eventTimePadding
      : null
    if (time <= endTime && time >= startTime) {
      return {
        event: events[i],
        prevEvent: i > 0 ? events[i - 1] : null,
        nextEvent: i < events.length - 1 ? events[i + 1] : null
      }
    }
    if (time < startTime) {
      return {
        event: null,
        prevEvent: i > 0 ? events[i - 1] : null,
        nextEvent: events[i]
      }
    }
  }
  return {
    event: null,
    prevEvent: events && events.length > 0 ? events[events.length - 1] : null,
    nexEvent: null
  }
}

export const isPlaying = (player) => {
  return !!(player.currentTime > 0 && !player.paused && !player.ended) // && this.readyState > 2);
}

let timer = null
const timeStep = 200
export const ReplayControls = ({
  map,
  markers,
  startTimeUnix,
  tripDuration,
  play,
  setPlay,
  videoPlayerRef,
  videoClips,
  eventId,
  prevEvent,
  nextEvent,
  popupOpen,
  setPopupOpen
}) => {
  const Colors = useColors()
  const time = useReactiveVar(tripReplayTime)
  const akimaData = useReactiveVar(tripReplayAkimaFunctions)

  useEffect(() => {
    if (checkVal(time) && akimaData && akimaData.lng && markers) {
      try {
        const lng = akimaData.lng(time)
        const lat = akimaData.lat(time)
        if (checkVal(lng) && checkVal(lat)) {
          const heading = akimaData.heading(time)
          const mapBearing = map ? map.getBearing() : 0
          markers[2].setLngLat({
            lng,
            lat
          })
          markers[2].setRotation(90 - mapBearing + heading)
          map &&
            map.easeTo({
              center: [lng, lat],
              bearing: heading,
              zoom: 15 // play ? 15 : 10
              // duration: 500,
              // easing: (time) => time
            })
          tripReplayCamera({ center: { lng, lat }, heading, zoom: 15 })
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, [map, akimaData, markers, time])

  return (
    <FlexBox
      style={{
        width: '100%'
      }}
    >
      <FlexBox direction='column' style={{ width: '100%' }}>
        <FlexBox
          style={{
            width: '100%',
            paddingTop: Metrics.base,
            // justifyContent: 'space-between',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            backgroundColor: Colors.background,
            paddingBottom: Metrics.base
          }}
        >
          <IconButton
            iconSize='large'
            onClick={() => {
              if (prevEvent && prevEvent.startDate) {
                tripReplayTime(prevEvent.startDate.getTime())
                tripReplaySeekBarTime(prevEvent.startDate.getTime())
              }
            }}
            iconName={'skip_previous'}
            disabled={!prevEvent}
            iconStyle={{
              marginRight: Metrics.base,
              color: prevEvent ? Colors.secondary : Colors.secondaryOpacity3
            }}
          />
          <IconButton
            iconSize='large'
            onClick={() => {
              tripReplayTime(time - 10000)
              tripReplaySeekBarTime(time - 10000)
            }}
            iconName={'replay_10'}
            iconStyle={{
              color: Colors.primary,
              marginRight: Metrics.base
            }}
          />
          <IconButton
            iconStyle={{
              fontSize: 52,
              color: Colors.primary
            }}
            onClick={() => {
              const newPlay = !play
              setPlay(newPlay)
              if (videoPlayerRef.current && videoPlayerRef.current.play) {
                newPlay
                  ? videoPlayerRef.current.play()
                  : videoPlayerRef.current.pause()
              }
            }}
            iconName={play ? 'pause' : 'play_arrow'}
          />
          <IconButton
            iconSize='large'
            onClick={() => {
              tripReplayTime(time + 10000)
              tripReplaySeekBarTime(time + 10000)
            }}
            iconName={'forward_10'}
            iconStyle={{ marginLeft: Metrics.base, color: Colors.primary }}
          />
          <IconButton
            iconSize='large'
            onClick={() => {
              if (nextEvent && nextEvent.startDate) {
                tripReplayTime(nextEvent.startDate.getTime())
                tripReplaySeekBarTime(nextEvent.startDate.getTime())
              }
            }}
            disabled={!nextEvent}
            iconName={'skip_next'}
            iconStyle={{
              marginLeft: Metrics.base,
              color: nextEvent ? Colors.secondary : Colors.secondaryOpacity3
            }}
          />
        </FlexBox>
        <FlexBox style={{ width: '100%' }}>
          {/*eventId && !(videoClips && videoClips.length > 0) && (
            <>
              <Icon
                color={Colors.primaryOpacity1}
                style={{ marginRight: Metrics.base }}
                name={'videocam_off'}
              />
              <Text
                color={Colors.textSecondary}
                style={{ marginRight: Metrics.base * 2 }}
              >
                No Video
              </Text>
            </>
          )*/}
          <ButtonBase
            onClick={() => setPopupOpen(!popupOpen)}
            style={{
              border: `1px solid ${Colors.dividerColor}`,
              padding: Metrics.base / 2,
              borderRadius: Metrics.radius
            }}
          >
            {videoClips && videoClips.length > 0 ? (
              <>
                <Icon
                  color={Colors.primaryOpacity1}
                  style={{ marginRight: Metrics.base }}
                  name={'videocam'}
                />
                <Text
                  font='caption'
                  color={Colors.textSecondary}
                  style={{ marginRight: Metrics.base * 2 }}
                >
                  {popupOpen ? 'Close Video' : 'Open Video'}
                </Text>
              </>
            ) : (
              <>
                <Icon
                  color={Colors.primaryOpacity1}
                  style={{ marginRight: Metrics.base }}
                  name={'streetview'}
                />
                <Text
                  font='caption'
                  color={Colors.textSecondary}
                  style={{ marginRight: Metrics.base * 2 }}
                >
                  {popupOpen ? 'Close Street View' : 'Open Street View'}
                </Text>
              </>
            )}
          </ButtonBase>
          <Text
            font='caption'
            color={Colors.textSecondary}
            style={{
              margin: Metrics.base * 2
              // fontSize: 14
            }}
          >
            Current Time:{' '}
            {startTimeUnix &&
              tripDuration &&
              checkVal(time) &&
              new Date(Math.round(time)).toLocaleTimeString()}
          </Text>
        </FlexBox>
      </FlexBox>
      {/*<Slider
        style={{ color: Colors.primary }}
        onChange={(e, value) => {
          const newTime = startTimeUnix + value * 0.01 * tripDuration
          tripReplayTime(newTime)
          timer && clearTimeout(timer)
        }}
        value={(time - startTimeUnix) / (0.01 * tripDuration)}
        // ValueLabelComponent={ValueLabelComponent}
        aria-label='custom thumb label'
        defaultValue={0}
    />*/}
    </FlexBox>
  )
}

const getSpeed = ({ time, akimaData }) => {
  try {
    return checkVal(time)
      ? akimaData && akimaData.speed && akimaData.speed(time)
      : 0
  } catch (e) {
    console.log(e)
    return null
  }
}

const ReplaySideBar = ({
  map,
  markers,
  height,
  trip,
  startTimeUnix,
  tripDuration
}) => {
  const tripId = useReactiveVar(selectedTripId)
  const time = useReactiveVar(tripReplayTime)
  const seekTime = useReactiveVar(tripReplaySeekBarTime)
  const akimaData = useReactiveVar(tripReplayAkimaFunctions)
  const speed = getSpeed({ time, akimaData })
  const speedLimitData = useReactiveVar(tripReplaySpeedLimitData)
  const [speedLimit, setSpeedLimit] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [nextEvent, setNextEvent] = useState(null)
  const [prevEvent, setPrevEvent] = useState(null)
  const { data: event } = useEvent(eventId)
  const [eventStartTime, setEventStartTime] = useState(null)
  const { inTrip: events, loading: eventsLoading } = useEventsByTripId(tripId)
  const Colors = useColors()
  const videoPlayerRef = useRef({ current: null })
  const [play, setPlay] = useState(false)
  const navigateTo = useNavigation()
  const [popupOpen, setPopupOpen] = useState(true)
  useEffect(() => {
    if (play) {
      clearTimeout(timer)
      if (videoPlayerRef.current && !isPlaying(videoPlayerRef.current)) {
        timer = setTimeout(() => tripReplayTime(time + timeStep), timeStep)
        if (videoPlayerRef.current && videoPlayerRef.current.play) {
          videoPlayerRef.current.play()
        }
      } else {
        timer = setTimeout(() => tripReplayTime(time + timeStep), timeStep)
      }
    }
  }, [time, play])
  useEffect(() => {
    if (checkVal(time)) {
      setSpeedLimit(getSpeedLimit({ speedLimitData, time }))
    }
  }, [time, speedLimitData])
  useEffect(() => {
    if (checkVal(time) && events && events.length > 0) {
      const { event, prevEvent, nextEvent } = getCurrentEvents({ events, time })
      setNextEvent(nextEvent)
      setPrevEvent(prevEvent)
      setEventId(event ? event.id : null)
      setEventStartTime(
        event ? event.startDate && event.startDate.getTime() : 0
      )
    }
  }, [time, events])
  useEffect(() => {
    if (checkVal(seekTime) && videoPlayerRef.current) {
      videoPlayerRef.current.currentTime =
        (seekTime - eventStartTime + eventTimePadding) / 1000
    }
  }, [seekTime])
  const { data: asset } = useAsset(trip && trip.assetId)
  const vehicleMaxSpeed = asset && asset.maxSpeed ? asset.maxSpeed : 80
  const { data: videoClips } = useVideoClipByEventId(eventId)
  return (
    <Subsection
      style={{
        width: Metrics.sideBarWidth,
        height: height ? height : '100%',
        marginLeft: Metrics.base * 2,
        justifyContent: 'flex-start'
      }}
    >
      {checkVal(tripId) ? (
        <FlexBox
          direction='column'
          style={{ width: '100%', justifyContent: 'flex-start' }}
        >
          <SpeedometerDisplay
            speed={speed}
            speedLimit={speedLimit}
            vehicleMaxSpeed={vehicleMaxSpeed}
          />
          <FlexBox
            direction='column'
            style={{
              width: '100%'
            }}
          >
            <ReplayControls
              map={map}
              markers={markers}
              startTimeUnix={startTimeUnix}
              tripDuration={tripDuration}
              play={play}
              setPlay={setPlay}
              videoPlayerRef={videoPlayerRef}
              videoClips={videoClips}
              eventId={eventId}
              nextEvent={nextEvent}
              prevEvent={prevEvent}
              popupOpen={popupOpen}
              setPopupOpen={setPopupOpen}
            />
            {eventId && (
              <FlexBox
                direction='column'
                style={{
                  width: '100%',
                  // paddingTop: Metrics.base * 2,
                  paddingLeft: Metrics.base,
                  paddingRight: Metrics.base
                }}
              >
                <InsetCardContainer name='Event'>
                  <EventCard
                    eventId={eventId}
                    navigateTo={navigateTo}
                    onClick={() => [
                      selectedEventId(eventId),
                      navigateTo({
                        pathname: '/event-inbox/in-trip',
                        queryParams: [{ name: 'event', id: eventId }]
                      })
                    ]}
                    backgroundColor={Colors.background}
                  />
                </InsetCardContainer>
              </FlexBox>
            )}
          </FlexBox>
        </FlexBox>
      ) : (
        <NoneSelected helperText='Select a trip from the list on the left' />
      )}
      {
        /*videoClips && videoClips.length > 0 &&*/ popupOpen && (
          <Subsection
            style={{
              width: 500,
              height: videoClips && videoClips.length > 0 ? 500 : 540,
              backgroundColor:
                videoClips && videoClips.length > 0 ? '#FFFFFF' : '#4C525B',
              position: 'fixed',
              top: 100,
              left: 0,
              zIndex: 500,
              boxShadow: Utils.boxShadowDark,
              overflow: 'hidden'
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                height: 40,
                backgroundColor: '#4C525B'
              }}
            >
              <IconButton
                iconSize='small'
                onClick={() => setPopupOpen(!popupOpen)}
                iconColor='white'
                iconName='close'
                iconStyle={
                  {
                    // marginLeft: Metrics.base * 2,
                  }
                }
              />
              {event && (
                <>
                  <Title color='white'>{event.type}</Title>
                  <Text style={{ marginLeft: Metrics.base }} color='white'>
                    {videoClips && videoClips.length > 0
                      ? 'Video Clip'
                      : 'Street View'}
                  </Text>
                </>
              )}
            </FlexBox>

            {videoClips && videoClips.length > 0 ? (
              <VideoPlayer
                eventId={eventId}
                size={500}
                maxSize={1000}
                time={(time - eventStartTime + eventTimePadding) / 1000}
                forwardRef={videoPlayerRef}
                autoplay={false}
                noBorder
                onTimeUpdate={(e) => {
                  // console.log(eventStartTime + e.target.currentTime * 1000)
                  if (isPlaying(e.target)) {
                    if (!play) {
                      setPlay(true)
                    }
                    tripReplayTime(
                      eventStartTime +
                        e.target.currentTime * 1000 -
                        eventTimePadding
                    )
                  }
                }}
                onPause={() => {
                  console.log('pause')
                  setPlay(false)
                }}
                onSeeking={(e) => {
                  tripReplayTime(
                    eventStartTime +
                      e.target.currentTime * 1000 -
                      eventTimePadding
                  )
                }}
                onLoadedMetadata={() => {
                  if (checkVal(seekTime) && videoPlayerRef.current) {
                    videoPlayerRef.current.currentTime =
                      (seekTime - eventStartTime + eventTimePadding) / 1000
                  }
                }}
              />
            ) : (
              map && <TripReplayStreetView tomtomMap={map} />
            )}
          </Subsection>
        )
      }
    </Subsection>
  )
}

export default ReplaySideBar
