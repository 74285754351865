import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const assetGroupFragment = gql`
  fragment assetGroupFragment on AssetGroup {
    _id
    name
    description
    organization {
      _id
    }
  }
`

export const GET_ASSET_GROUPS = gql`
  query assetGroups($skip: Int, $limit: Int) {
    assetGroups(skip: $skip, limit: $limit) {
      ...assetGroupFragment
    }
  }
  ${assetGroupFragment}
`

const dataTransform = ({ data }) => {
  return data.map((item) => ({ ...item, label: item.name }))
}

export const getAssetsVariables = () => {
  let variables = {
    skip: 0,
    limit: 1000
  }
  return variables
}

export const useAssetGroups = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ASSET_GROUPS,
    {
      variables: getAssetsVariables(),
      queryName: 'assetGroups',
      errorMessage: 'Failed to fetch Asset Groups',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    } else {
      setData([])
    }
  }, [queryData])

  return { data, loading }
}
