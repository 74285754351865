import { ResponsivePie } from '@nivo/pie'
import React, { useEffect, useState } from 'react'
import {
  FlexBox,
  InfoBadge,
  MaterialInput,
  Status,
  Subsection,
  Text
} from '../../../Components'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import { Heading, Title2 } from '../../../Components/Typography'
import { useTrips } from '../../../Graphql/Queries/Trip/useTrips'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { checkVal } from '../../../Helpers/Functions'
import { Metrics, useColors } from '../../../Themes'
import { Table } from '../../../Components/Table'
import { handleSelectCard } from '../../../Components/Custom/SelectionPanel/Cards/Card'
import { useHistory, useLocation } from 'react-router-dom'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { Radio } from '@material-ui/core'
import {
  selectedTripId,
  selectedDeviceId,
  selectedVVTDeviceId
} from '../../../Graphql/GraphQLClient'
import { useNavigation } from '../../../Components/CustomHooks/useNavigation'

const columns = (Colors) => [
  {
    Header: 'Name',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Start Address',
    accessor: 'startAddress',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'End Address',
    accessor: 'endAddress',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Score',
    accessor: 'tripSafetyScore',
    Cell: ({ cell: { value } }) => (value ? value.toFixed(2) : '-'),
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Status',
    accessor: 'tripSafetyCategory',
    Cell: ({ cell: { value } }) =>
      value ? (
        <Status
          style={{ marginLeft: Metrics.base * 2 }}
          status={value}
          getStatusColors={() => Colors[value]}
        />
      ) : (
        '-'
      ),
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const getBorderLeftStyles = (cell, Colors) => {
  if (cell.row.original.tripSafetyScore)
    return {
      borderLeft: `5px solid ${Colors[cell.row.original.tripSafetyCategory]}`
    }
  return {}
}

const orderingOptions = [
  { label: 'Top Trips', value: 'topTrips' },
  { label: 'Problem Trips', value: 'problemTrips' }
]

const SafetyCategoryBreakdowns = ({ safetyCounts, totalTrips }) => {
  const Colors = useColors()
  const minWidth = 130
  return (
    <FlexBox
      fillSpace
      direction='row'
      style={{
        width: '100%',
        justifyContent: 'space-between',
        borderTop: `1px solid ${Colors.dividerColor}`
      }}
    >
      {safetyCounts && (
        <>
          <FlexBox>
            <div
              style={{
                margin: Metrics.base * 3,
                width: 110,
                height: 110
              }}
            >
              <ResponsivePie
                innerRadius={0.85}
                padAngle={0}
                cornerRadius={0}
                // startAngle={-180}
                // endAngle={180}
                enableArcLinkLabels={false}
                enableSlicesLabels={false}
                enableArcLabels={false}
                isInteractive
                data={[
                  {
                    id: 'highRisk',
                    label: 'High Risk',
                    value: safetyCounts[0]
                  },
                  {
                    id: 'moderateRisk',
                    label: 'Moderate Risk',
                    value: safetyCounts[1]
                  },
                  {
                    id: 'adequate',
                    label: 'Adequate',
                    value: safetyCounts[2]
                  },
                  {
                    id: 'safe',
                    label: 'Safe',
                    value: safetyCounts[3]
                  },
                  {
                    id: 'excellent',
                    label: 'Excellent',
                    value: safetyCounts[4]
                  }
                ]}
                margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
                // colors={{ scheme: 'blue_green' }}
                colors={[
                  Colors.HIGH_RISK,
                  Colors.MODERATE_RISK,
                  Colors.ADEQUATE,
                  Colors.SAFE,
                  Colors.EXCELLENT
                ]}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                enableRadialLabels={false}
              />
            </div>
            <InfoBadge
              size='medium'
              value={totalTrips}
              getDisplayVal={(value) => `${value} of 100`}
              label={'Recent Trips Scored'}
            />
          </FlexBox>
          <FlexBox
            direction='row'
            style={{
              paddingTop: Metrics.base * 2,
              paddingBottom: Metrics.base * 2
            }}
          >
            <FlexBox
              style={{
                minWidth: minWidth,
                borderLeft: `1px solid ${Colors.dividerColor}`,
                borderRight: `1px solid ${Colors.dividerColor}`
              }}
            >
              <InfoBadge
                // bold
                size='medium'
                value={safetyCounts[0]}
                status='High Risk'
                statusColor={Colors.HIGH_RISK}
              />
            </FlexBox>
            <FlexBox
              style={{
                minWidth: minWidth,
                borderRight: `1px solid ${Colors.dividerColor}`
              }}
            >
              <InfoBadge
                // bold
                size='medium'
                value={safetyCounts[1]}
                status='Moderate Risk'
                statusColor={Colors.MODERATE_RISK}
              />
            </FlexBox>
            <FlexBox
              style={{
                minWidth: minWidth,
                borderRight: `1px solid ${Colors.dividerColor}`
              }}
            >
              <InfoBadge
                // bold
                size='medium'
                value={safetyCounts[2]}
                status='Adequate'
                statusColor={Colors.ADEQUATE}
              />
            </FlexBox>
            <FlexBox
              style={{
                minWidth: minWidth,
                borderRight: `1px solid ${Colors.dividerColor}`
              }}
            >
              <InfoBadge
                // bold
                size='medium'
                value={safetyCounts[3]}
                status='Safe'
                statusColor={Colors.SAFE}
              />
            </FlexBox>
            <FlexBox
              style={{
                minWidth: minWidth,
                marginRight: Metrics.base * 2
              }}
            >
              <InfoBadge
                // bold
                size='medium'
                value={safetyCounts[4]}
                status='EXCELLENT'
                statusColor={Colors.EXCELLENT}
              />
            </FlexBox>
          </FlexBox>
        </>
      )}
    </FlexBox>
  )
}

const TripsSafetyList = ({ driverId, totalTrips }) => {
  const Colors = useColors()
  // const { data: trips, loading: tripsLoading } = useTrips({ driverId })
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState({})
  const [safetyCounts, setSafetyCounts] = useState([0, 0, 0, 0, 0])
  const [orderBy, setOrderBy] = useState('topTrips')
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const navigateTo = useNavigation()
  const { data: driverSafetyData } = useDriverSafetyByDriverId(driverId)

  useEffect(() => {
    if (checkVal(driverSafetyData) && driverSafetyData.tripIDList) {
      let safetyCountsTemp = [0, 0, 0, 0, 0]
      let trips = driverSafetyData.tripIDList.filter((trip) => {
        switch (trip.tripSafetyCategory) {
          case 'HIGH_RISK':
            safetyCountsTemp[0] = safetyCountsTemp[0] + 1
            break
          case 'MODERATE_RISK':
            safetyCountsTemp[1] = safetyCountsTemp[1] + 1

            break
          case 'ADEQUATE':
            safetyCountsTemp[2] = safetyCountsTemp[2] + 1

            break
          case 'SAFE':
            safetyCountsTemp[3] = safetyCountsTemp[3] + 1

            break
          case 'EXCELLENT':
            safetyCountsTemp[4] = safetyCountsTemp[4] + 1
            break
          default:
            break
        }
        return (
          checkVal(trip.tripSafetyScore) && checkVal(trip.tripSafetyCategory)
        )
      })
      setData(trips.sort((a, b) => a.tripSafetyScore - b.tripSafetyScore))
      setSafetyCounts(safetyCountsTemp)
    }
  }, [driverSafetyData])

  return (
    <>
      <Table
        style={{ width: '100%', marginTop: Metrics.base * 2 }}
        handleRowClick={(row) => {
          selectedTripId(row.original._id)
          navigateTo({
            pathname: '/trip-history',
            queryParams: [{ name: `trip-id`, id: row.original._id }],
            clearQueryParams: true
          })
        }}
        // setShowModal(true), trip-history?trip-id=6184e52cb57970b8c0017864
        // handleSelectCard({
        //   typeName: 'trip',
        //   id: row.original._id,
        //   navigateTo:
        //   setSelectedId: selectedTripId
        //   // name: 'trip',
        //   // pathName: '/trip-history',
        //   // id: row.original._id,
        //   // urlSearchParams,
        //   // history
        // }),
        // setSelectedObject(row.original)

        subHeaderContent={
          <>
            <SafetyCategoryBreakdowns
              safetyCounts={safetyCounts}
              totalTrips={dataState && dataState.length}
            />
            <FlexBox
              fillSpace
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                borderTop: `1px solid ${Colors.dividerColor}`
              }}
            >
              <FlexBox
                direction='row'
                style={{ padding: Metrics.base, marginLeft: Metrics.base }}
              >
                <Radio
                  size='small'
                  checked={orderBy === 'topTrips'}
                  onChange={() => {
                    setData(
                      dataState.sort((a, b) =>
                        b.tripSafetyScore && a.tripSafetyScore
                          ? a.tripSafetyScore - b.tripSafetyScore
                          : 0
                      )
                    )
                    setOrderBy('topTrips')
                  }}
                  style={{
                    color: Colors.primary,
                    marginLeft: Metrics.base
                  }}
                />
                <Text> Top Trips </Text>
                <Radio
                  size='small'
                  checked={orderBy === 'problemTrips'}
                  onChange={() => {
                    setData(
                      dataState.sort(
                        (a, b) => b.tripSafetyScore - a.tripSafetyScore
                      )
                    )
                    setOrderBy('problemTrips')
                  }}
                  style={{
                    color: Colors.primary,
                    marginLeft: Metrics.base
                  }}
                />
                <Text> Problem Trips </Text>
              </FlexBox>
            </FlexBox>
          </>
        }
        loading={!driverSafetyData}
        columns={columns(Colors)}
        getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, Colors)}
        hideNewButton
        dataState={dataState}
        setData={setData}
        title='Recent Trips Overview'
        sortBy={[{ id: 'none', desc: true }]}
        defaultPageSize={20}
        selectedObject={selectedObject}
        setSelectedObject={setSelectedObject}
      />
    </>
  )
}

export default TripsSafetyList
