import { useD3 } from '../../CustomHooks/useD3'
import React, { useState } from 'react'
import * as d3 from 'd3'
import { useColors, Utils } from '../../../Themes'
import { Tooltip, withStyles } from '@material-ui/core'
import { FlexBox, ProcessingSpinner } from '../..'
import { css } from 'aphrodite'
import { abbreviateNumber } from '../../../Helpers/Functions'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'
import { Styles } from './Styles'
import DataCard from '../../D3Charts/DataCard'
const BarComponent = ({
  id,
  data,
  StyledTooltip,
  Colors,
  color,
  color1,
  color2,
  handleClick,
  xAccessor,
  yAccessor,
  units,
  yAxisLabel
}) => {
  //const color = data.map((d) => Colors[getScoreCategory(d.xAccessor)])
  return (
    <StyledTooltip
      key={id}
      interactive
      title={
        data && (
          <DataCard
            value={data[yAccessor]}
            size='small'
            hideColorBar
            units={units}
            description={yAxisLabel}
            title={data[xAccessor]}
            style={{ height: 120, boxShadow: 'none' }}
          />
        )
      }
      placement='top'
    >
      <rect
        // style={{ transition: 2000, opacity: 0.5 }}
        onClick={() => handleClick && handleClick(data)}
        className={[
          'bar-component',
          css(
            Styles({
              Color1: color1 ? color1 : color ? color : Colors.primaryOpacity2,
              Color2: color2
                ? color2
                : color
                ? color
                : Colors.primary /*'#51A1FF'*/
            }).selectable
          )
        ]}
      />
    </StyledTooltip>
  )
}
const BarChart = ({
  data,
  color,
  color1,
  color2,
  chartWidth = 300,
  chartHeight = 250,
  showIdInLabel,
  id,
  loading,
  units,
  handleClick,
  yAxisLabel,
  xAccessor = 'x',
  yAccessor = 'y'
}) => {
  const Colors = useColors()
  const [StyledTooltip] = useState(
    withStyles(() => ({
      tooltip: {
        backgroundColor: Colors.subsectionBackground,
        padding: 0,
        border: `1px solid ${Colors.dividerColor}`,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }
    }))(Tooltip)
  )
  const margin = { top: 15, right: 10, bottom: 40, left: 40 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const dataString = JSON.stringify(data)
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth
      const x = d3
        .scaleBand()
        .domain(data.map((d) => d[xAccessor]))
        .rangeRound([margin.left, width - margin.right])
        .paddingInner(0)
        .paddingOuter(0)
      const paddingOuter =
        width - margin.right - margin.left - x.bandwidth() * x.domain().length
      const xLinear = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d[xAccessor])])
        .rangeRound([
          margin.left + paddingOuter / 2,
          width - margin.right - paddingOuter / 2
        ])
      console.log('paddingOuter', paddingOuter)
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d[yAccessor])])
        .rangeRound([height - margin.bottom, margin.top])
      const xAxis = (g) =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .style('color', Colors.textSecondary)
          .call(d3.axisBottom(xLinear))
      const yAxis = (g) =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .style('color', Colors.textSecondary)
          .call(d3.axisLeft(y).tickFormat((d) => abbreviateNumber(d))) // .ticks(null, '~s'))
          .call((g) => g.select('.domain').remove())
      svg
        .select('.x-axis')
        .call(xAxis)
        .selectAll('text')
        .attr('transform', 'translate(-10,10)rotate(-45)')
        .style('text-anchor', 'end')
        .style('width', 200)
      svg.select('.y-axis').call(yAxis)
      svg
        .select('.plot-area')
        // .attr('fill', Colors.primary)
        .selectAll('.bar-component')

        .data(data)
        .attr('x', (d) =>
          x(
            showIdInLabel && d.id ? `${d[xAccessor]} ID: ${d.id}` : d[xAccessor]
          )
        )
        .attr('width', x.bandwidth())
        .attr('y', (d) => y(d[yAccessor]))
        .attr('height', (d) => y(0) - y(d[yAccessor]))
      // .attr('fill', Colors[getScoreCategory(d[xAccessor])])

      /*
        for (var i = 0; i < data.length; i++) {  
          svg
            .select(`.bar-${data[i]._id}`)
            .x1(data[i].x)
            .attr('fill', color)
            .attr('stroke', strokeColor)
            .attr('fill-opacity', data[i].value / 10)
            .attr('d', area)
        }*/
    },
    [dataString, data.length, id, chartWidth, chartHeight, xAccessor, yAccessor]
  )
  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        ref={ref}
        key={id}
        style={{
          height: chartHeight,
          width: chartWidth,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        {/*<rect
        x={plotAreaX}
        y={plotAreaY}
        width={plotAreaWidth}
        height={plotAreaHeight}
        fill={Colors.background}
        stroke={Colors.dividerColor}
      />*/}
        <g className='plot-area'>
          {data.map((a, i) => (
            <BarComponent
              key={`${a[xAccessor]}_${a[yAccessor]}_${i}`}
              yAxisLabel={yAxisLabel}
              id={a._id}
              data={a}
              index={i}
              StyledTooltip={StyledTooltip}
              Colors={Colors}
              color={color ? color : Colors[getScoreCategory(a[xAccessor])]}
              color1={color1}
              color2={color2}
              units={units}
              handleClick={handleClick}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
            />
          ))}
        </g>
        <g className='x-axis' />
        <g className='y-axis' />
        <line
          x1={plotAreaX}
          y1={plotAreaY + plotAreaHeight}
          x2={plotAreaX + plotAreaWidth}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
        <line
          x1={plotAreaX}
          y1={plotAreaY}
          x2={plotAreaX}
          y2={plotAreaY + plotAreaHeight}
          stroke={Colors.dividerColor}
        />
      </svg>
      {loading ? (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      ) : !data || data.length === 0 ? (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
          }}
        >
          no data
        </FlexBox>
      ) : (
        ''
      )}
    </div>
  )
}
export default BarChart
