import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const userFragment = gql`
  fragment userFragment on User {
    _id
    organizations {
      _id
      name
      isActive
    }
    firstName
    lastName
    username
    userType
    isActive
    displayId
  }
`

export const GET_USER_BY_USERNAME = gql`
  query user($id: String!) {
    user(id: $id) {
      ...userFragment
    }
  }
  ${userFragment}
`

export const userDataTransform = ({ data }) => {
  return {
    ...data,
    fullName: data.firstName + ' ' + data.lastName
  }
}

export const useUser = () => {
  const userId = localStorage.getItem('cybeleUserId')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_USER_BY_USERNAME,
    {
      variables: { id: userId },
      queryName: 'user',
      errorMessage: 'Failed to fetch user',
      dataTransform: (data) => userDataTransform({ data }),
      skip: !userId
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    } else setData(null)
  }, [queryData])

  return { data, loading }
}
