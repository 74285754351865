import React from 'react'
import { Text, Subsection, Icon, Button, Status } from '../'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'
import { checkVal } from '../../Helpers/Functions'

const InfoBadge = ({
  value,
  getDisplayVal = (val) => val,
  label,
  style,
  displayFont = 'heading',
  labelFont = 'medium',
  actionButton,
  handleButtonClick,
  leadIcon,
  status,
  noBoxShadow,
  leadImg,
  btnDisabled
}) => {
  const Colors = useColors()
  return (
    <Subsection
      noBoxShadow={noBoxShadow}
      style={{
        // paddingTop: Metrics.base,
        marginTop: Metrics.base * 3,
        backgroundColor: Colors.backgroundOpacity2,
        ...style
      }}
    >
      <FlexBox
        direction='column'
        // justifyContent='space-evenly'
        alignItems='left'
        style={{ width: '100%' }}
      >
        <FlexBox
          direction='row'
          justifyContent='space-between'
          style={{
            paddingLeft: Metrics.base * 1,
            paddingRight: Metrics.base * 1
          }}
        >
          <FlexBox>
            {leadIcon && (
              <Icon
                name='star'
                color={Colors.primaryOpacity2}
                size='large'
                style={{ marginLeft: Metrics.base }}
              />
            )}
            {leadImg && (
              <img
                src={leadImg}
                alt='leadImg'
                style={{
                  // marginTop: -Metrics.base,
                  marginLeft: Metrics.base * 2
                  // marginBottom: Metrics.base * 2
                }}
              />
            )}
            <Text
              font={displayFont}
              // marginBottom={1}
              style={{ textAlign: 'left', marginLeft: Metrics.base * 2 }}
            >
              {checkVal(value) ? getDisplayVal(value) : '-'}
            </Text>
          </FlexBox>
          <div></div>
          <FlexBox>
            {actionButton && (
              <Button
                condensed
                title={actionButton}
                onClick={handleButtonClick}
                disabled={btnDisabled}
                textColor='white'
                background='red'
                style={{ marginRight: Metrics.base * 4 }}
              />
            )}
            {status && (
              <Status
                status={status}
                getStatusColors={() => '#F88F15'}
                style={{ marginRight: Metrics.base * 8 }}
              ></Status>
            )}
          </FlexBox>
        </FlexBox>
        <FlexBox
          style={{
            width: '100%',
            borderRadius: Metrics.radius,
            backgroundColor: Colors.subsectionBackground,
            border: `1px solid ${Colors.backgroundOpacity2}`,
            paddingBottom: Metrics.base,
            paddingTop: Metrics.base,
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2
          }}
        >
          <Text font={labelFont}>
            <i>{label}</i>
          </Text>
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

export default InfoBadge
