import React, { useEffect, useState } from 'react'
import { useColors } from '../../../../Themes'
import Text from '../../../Text'
import { Title } from '../../../Typography'
import {
  selectedAssetId,
  selectedClusterCard,
  TOMTOM_API_KEY
} from '../../../../Graphql/GraphQLClient'
import ClusterCard from '../Cards/ClusterCard'
import tt from '@tomtom-international/web-sdk-services'
import { useGetLocationAddress } from '../../../../Graphql/Queries/useGetLocationAddress'

const AssetClusterCard = ({
  data,
  selected,
  navigateTo,
  borderBottom,
  backgroundColor,
  onClick
}) => {
  const Colors = useColors()
  const [near, setNear] = useState()

  const location = data && data.location
  const { data: addresData, loading: addressLoading } =
    useGetLocationAddress(location)
  useEffect(() => {
    if (addresData) {
      setNear(`${addresData.town}, ${addresData.province}`)
    }
  }, [addresData])

  return (
    <ClusterCard
      points={data && data.points}
      selected={selected}
      typeName='asset'
      setSelectedId={selectedAssetId}
      navigateTo={navigateTo}
      onClick={onClick ? onClick : () => selectedClusterCard(data)}
      backgroundColor={backgroundColor}
      borderBottom={borderBottom}
      titleContent={<Title color={Colors.primary}>Assets</Title>}
      bodyContent={<Text marginBottom={1}>{near && `Near ${near}`}</Text>}
      loading={addressLoading}
    />
  )
}

export default AssetClusterCard
