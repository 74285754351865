import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_CMS_LOGIN = gql`
  query {
    getCMSlogin
  }
`

export const useCMSjsession = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(GET_CMS_LOGIN, {
    queryName: 'getCMSlogin',
    errorMessage: 'Failed to get jsession Token'
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData ? setData(queryData) : setData(null)
  }, [queryData])

  return { data, loading }
}
