import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_MAP_CAMERA = gql`
  query mapCamera {
    mapCamera @client {
      liveMonitoring {
        center
        zoom
      }
      tripHistory {
        center
        zoom
      }
    }
  }
`

export const useMapCamera = () => {
  const [liveMonitoringMapCenter, setLiveMonitoringMapCenter] = useState(null)
  const [liveMonitoringMapZoom, setLiveMonitoringMapZoom] = useState(null)
  const [tripHistoryMapCenter, setTripHistoryMapCenter] = useState(null)
  const [tripHistoryMapZoom, setTripHistoryMapZoom] = useState(null)

  const { data: mapCamera } = useQuery(GET_MAP_CAMERA, {
    queryName: 'mapCamera'
  })

  useEffect(() => {
    const liveMonitoring = mapCamera ? mapCamera.liveMonitoring : null
    setLiveMonitoringMapCenter(liveMonitoring ? liveMonitoring.center : null)
    setLiveMonitoringMapZoom(liveMonitoring ? liveMonitoring.zoom : null)

    const tripHistory = mapCamera ? mapCamera.tripHistory : null
    setTripHistoryMapCenter(tripHistory ? tripHistory.center : null)
    setTripHistoryMapZoom(tripHistory ? tripHistory.zoom : null)
  }, [mapCamera])

  return {
    liveMonitoringMapCenter,
    liveMonitoringMapZoom,
    tripHistoryMapCenter,
    tripHistoryMapZoom
  }
}
