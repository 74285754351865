import React, { useEffect } from 'react'
import FlexBox from '../../FlexBox'
import { Images, Metrics, useColors } from '../../../Themes'
import Text from '../../Text'
import { Title } from '../../Typography'
import Status from '../../Status'
import Card, { TooltipMenuItem } from './Cards/Card'
import { selectedInspectionId } from '../../../Graphql/GraphQLClient'
import { hhmmss, formatDateTime } from '../../../Helpers/Functions'
import { useInspection } from '../../../Graphql/Queries/Inspection/useInspection'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const InspectionCard = ({
  inspectionId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom
}) => {
  const Colors = useColors()
  const { data: inspection, loading: inspectionLoading } =
    useInspection(inspectionId)
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const urlSearchParams = new URLSearchParams(location.search)

  return (
    <Card
      data={inspection}
      selected={selected}
      onClick={() => [
        selectedInspectionId(selected ? null : inspection.data._id)
        //   urlSearchParams.set('event-id', inspection.data._id),
        //   history.push({
        //     search: urlSearchParams.toString()
        //   })
      ]}
      TooltipContent={({ setShowTooltip }) => (
        <FlexBox direction='column' style={{ width: 200 }}>
          <TooltipMenuItem
            text='View in detail'
            Colors={Colors}
            // onClick={() => {
            //   urlSearchParams.set('event-id', inspection.data._id)
            //   history.push({
            //     pathname: '/trip-history',
            //     search: urlSearchParams.toString()
            //   })
            // }}
          />
          <TooltipMenuItem text='item 2' Colors={Colors} />
          <TooltipMenuItem text='item 3' Colors={Colors} />
        </FlexBox>
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={inspectionLoading}
      titleContent={
        <>
          {/* {inspection.data.type && (
            <img
              width={25}
              style={{ marginRight: Metrics.base }}
              alt={`${inspection.data.type}_icon`}
              src={Images[inspection.data.type]}
            />
          )} */}
          <Title style={{ marginLeft: Metrics.base }} color={Colors.primary}>
            {inspection.data &&
              formatDateTime(new Date(inspection.data.createdAt))}
          </Title>
        </>
      }
      bodyContent={
        <Text
          marginBottom={1}
          marginTop={1 / 2}
          style={{ paddingRight: Metrics.base }}
        >
          {inspection.data && inspection.data.displayId
            ? 'Inspection ID: ' + inspection.data.displayId
            : 'Inspection ID: -'}{' '}
        </Text>
      }
      topRightContent={
        <Status
          status={inspection.data && inspection.data.inspectionType}
          getStatusColors={() =>
            inspection.data &&
            inspection.data.inspectionType &&
            Colors[inspection.data.inspectionType]
          }
        ></Status>
      }
      topRightLoading={inspectionLoading}
      bottomLeftContent={
        inspection.data && inspection.data.reportedMaintenanceStatus ? (
          <Status
            status={
              inspection.data &&
              inspection.data.reportedMaintenanceStatus &&
              inspection.data.reportedMaintenanceStatus ===
                'ATTENTION_UNNECESSARY'
                ? 'Good Condition'
                : inspection.data.reportedMaintenanceStatus ===
                  'ATTENTION_LATER_DATE'
                ? 'Medium Condition'
                : 'Bad Condition'
            }
            getStatusColors={() =>
              Colors[
                inspection.data && inspection.data.reportedMaintenanceStatus
              ]
            }
          ></Status>
        ) : (
          ''
        )
      }
    />
  )
}

export default InspectionCard
