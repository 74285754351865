import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { eventDataTransform, eventFragment } from './useEvent'
import gql from 'graphql-tag'

export const GET_EVENTS = gql`
  query events(
    $tripId: String
    $driverId: String
    $assetId: String
    $startDate: Float
    $endDate: Float
    $skip: Int
    $limit: Int
  ) {
    events(
      tripId: $tripId
      driverId: $driverId
      assetId: $assetId
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
    ) {
      totalCount
      events {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`

const dataTransform = ({ data }) => {
  const events = []
  const timeBased = []
  const tripTotals = []
  if (data.events) {
    for (var i = 0; i < data.events.length; i++) {
      const event = eventDataTransform({ data: data.events[i] })
      events.push(event)
      if (event.type === 'ACCELERATION') timeBased.push(event)
      if (
        event.type === 'TRIP_DISTANCE' ||
        event.type === 'TRAVEL_TIME' ||
        event.type === 'DRIVING_TIME' ||
        event.type === 'PERCENT_SPEEDING'
      )
        tripTotals.push(event)
    }
  }
  return { events, totalCount: data.totalCount, timeBased, tripTotals }
}

const getEventsVariables = (eventVariables) => {
  let variables = {
    skip: 0,
    limit: 50
  }

  if (eventVariables) {
    if (eventVariables.tripId) {
      variables = {
        ...variables,
        tripId: eventVariables.tripId
      }
    }
    if (eventVariables.driverId) {
      variables = {
        ...variables,
        driverId: eventVariables.driverId
      }
    }
    if (eventVariables.assetId) {
      variables = {
        ...variables,
        assetId: eventVariables.assetId
      }
    }
    if (eventVariables.startDate) {
      variables = {
        ...variables,
        startDate: eventVariables.startDate
      }
    }
    if (eventVariables.endDate) {
      variables = {
        ...variables,
        endDate: eventVariables.endDate
      }
    }
  }
  return variables
}

export const useEvents = (eventVariables) => {
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [timeBased, setTimeBased] = useState([])
  const [tripTotals, setTripTotals] = useState([])
  const [loading, setLoading] = useState(false)
  const { data: queryData, loading: queryLoading } = useQuery(GET_EVENTS, {
    variables: getEventsVariables(eventVariables),
    queryName: 'events',
    errorMessage: 'Failed to fetch Events',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData && queryData.events) {
      setData(queryData.events)
      setTotalCount(queryData.totalCount)
      setTimeBased(queryData.timeBased)
      setTripTotals(queryData.tripTotals)
    }
  }, [queryData])

  return { data, totalCount, timeBased, tripTotals, loading }
}
