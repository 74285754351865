import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const LINK_ASSET_TO_DEVICE = gql`
  mutation linkAssetToDevice($assetId: String!, $internalDeviceId: String!) {
    linkAssetToDevice(assetId: $assetId, internalDeviceId: $internalDeviceId) {
      _id
      name
      asset {
        _id
        name
      }
    }
  }
`
export const useLinkAssetToDevice = () => {
  const [linkAssetToDevice, { loading: linkAssetToDeviceLoading }] =
    useMutation(LINK_ASSET_TO_DEVICE, {
      mutationName: 'linkAssetToDevice',
      successMessage: 'Asset linked successfully',
      errorMessage: 'Asset link failed'
    })

  return [linkAssetToDevice, { linkAssetToDeviceLoading }]
}
