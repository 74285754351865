import React from 'react'
import { FlexBox, ProcessingSpinner } from '../../../../Components'
import { useAppDimensions } from '../../../../Graphql/Queries/useAppDimensions'
import { Metrics, useColors } from '../../../../Themes'
import BarChart from '../../../../Components/Custom/BarChart'
import { checkVal } from '../../../../Helpers/Functions'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const getPreviousMonths = (num, keys) => {
  const data = []
  var today = new Date()
  var d
  var month
  var year

  for (var i = num - 1; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1)
    month = monthNames[d.getMonth()]
    year = d.getFullYear()
    const dataObject = { name: `${month}, ${year}` }
    for (var j = 0; j < keys.length; j++) {
      dataObject[keys[j]] = 0
    }
    data.push(dataObject)
  }
  return data
}

const getData = (events, groupBy, keys) => {
  const numPreviousMonths = 6
  const previousMonths = getPreviousMonths(numPreviousMonths, keys)
  for (var i = 0; i < events.length; i++) {
    const date = events[i].startDate
    if (date && checkVal(date.getMonth())) {
      const monthNum = date.getMonth()
      const startDate = new Date(
        new Date().getTime() - (numPreviousMonths - 1) * 2.628e9
      )
      const startMonth = startDate.getMonth()
      const yearDiff = date.getFullYear() - startDate.getFullYear()
      const monthDiff = monthNum + (12 * yearDiff - startMonth)
      previousMonths[monthDiff][events[i][groupBy]] += Number(
        events[i].normScore
      )
    }
  }
  for (i = 0; i < previousMonths.length; i++) {
    for (var j = 0; j < keys.length; j++) {
      previousMonths[i][keys[j]] = Math.round(previousMonths[i][keys[j]])
    }
  }
  return previousMonths
}

const EventsVsTimeChart = ({ events, keys, groupBy = 'type', loading }) => {
  const { mainContainerWidth } = useAppDimensions()
  const Colors = useColors()
  return (
    <FlexBox
      style={{
        marginLeft: Metrics.base * 2,
        marginTop: Metrics.base * 2,
        marginBottom: Metrics.base * 2
      }}
    >
      <div
        style={{ height: 400, width: mainContainerWidth - Metrics.base * 8 }}
      >
        {loading ? (
          <FlexBox style={{ width: '100%', height: '100%' }}>
            <ProcessingSpinner />
          </FlexBox>
        ) : (
          <BarChart
            data={events ? getData(events, groupBy, keys) : []}
            keys={keys}
            colors={keys.map((key) => Colors[key])}
            xAxisTitle='Months'
            yAxisTitle='Number of events'
          />
        )}
      </div>
    </FlexBox>
  )
}

export default EventsVsTimeChart
