import React from 'react'
import { IconButton, Icon } from '../'
import { Button as MaterialButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Colors, Metrics, useColors } from '../../Themes'
import { useQuery } from '@apollo/client'
import { GET_DARKMODE } from '../../Graphql/Queries/localQueries'
import Tooltip from '@material-ui/core/Tooltip'
import Text from '../Text'

export const MaterialNavButton = withStyles({
  root: {
    color: Colors.lightTheme.secondary,
    // backgroundColor: '',
    width: '100%',
    borderRadius: 0,
    height: Metrics.base * 7
    /*
    '&:hover,&:active': {
      backgroundColor: darkmode
        ? Colors.darkTheme.selectHighlight
        : Colors.lightTheme.selectHighlight
    }
    */
  }
})(MaterialButton)

const NavButton = ({
  title,
  iconName,
  iconColor,
  children,
  style,
  onClick,
  expanded,
  selected
}) => {
  const Colors = useColors()
  const StyledTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: Colors.subsectionBackground,
      border: `1px solid ${Colors.primaryOpacity3}`
    }
  }))(Tooltip)
  return expanded ? (
    <MaterialNavButton
      id={`nav-button-${title}`}
      style={style}
      onClick={onClick}
      children={children}
      startIcon={
        <Icon
          name={iconName}
          color={iconColor ? iconColor : Colors.secondary}
          // size={iconSize}
          // style={iconStyle}
        />
      }
    />
  ) : (
    <StyledTooltip
      title={
        <React.Fragment>
          <div style={{ padding: Metrics.base }}>
            <Text>{title}</Text>
          </div>
        </React.Fragment>
      }
      placement='right'
    >
      <div
        style={{
          width: '100%',
          height: Metrics.base * 7,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: Metrics.radius,
          padding: Metrics.base
        }}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: style ? style.backgroundColor : 'none',
            borderRadius: Metrics.radius,
            ...style
          }}
        >
          <IconButton
            iconName={iconName}
            id={`nav-button-${title}`}
            iconColor={
              style ? Colors.primary : iconColor ? iconColor : Colors.secondary
            }
            onClick={onClick}
            buttonStyles={{
              width: '100%',
              height: Metrics.base * 5,
              '&:hover,&$active': {}
            }}
          ></IconButton>
        </div>
      </div>
    </StyledTooltip>
  )
}

export default NavButton
