import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import {
  Button,
  FlexBox,
  InfoBadge,
  Subsection,
  TabBar,
  Text
} from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import CardsContainer from '../../../Components/Custom/SelectionPanel/Cards/CardsContainer'
import CardsList from '../../../Components/Custom/SelectionPanel/Cards/CardsList'
import EventCard, {
  handleEventCardOnClick
} from '../../../Components/Custom/SelectionPanel/Event/EventCard'
import { useNavigation } from '../../../Components/CustomHooks/useNavigation'
import LargeDivider from '../../../Components/LargeDivider'
import { Subheading } from '../../../Components/Typography'
import { selectedTripId } from '../../../Graphql/GraphQLClient'
import { useTrip } from '../../../Graphql/Queries/Trip/useTrip'
import { useTripSafetyByTripId } from '../../../Graphql/Queries/Trip/useTripSafetyByTripId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { checkVal, hhmmss } from '../../../Helpers/Functions'
import { Metrics, useColors } from '../../../Themes'
import { EventsSummary } from '../../TripHistory/MapSideBar/EventDetails'
import { CategoryScore } from './Overview'
import EventTimestamp from '../../../Components/Custom/EventTimestamp'
const EventsTimeline = ({ eventIds, eventsLoading = false, Colors, trip }) => {
  const navigateTo = useNavigation()
  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        alignItems: 'flex-start',
        paddingLeft: Metrics.base * 4,
        paddingTop: Metrics.base * 4
      }}
    >
      <FlexBox
        style={{
          marginLeft: Metrics.base * 1.5,
          marginBottom: -Metrics.base * 2
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: Colors.dividerColor
          }}
        ></div>
        <FlexBox
          direction='column'
          style={{ alignItems: 'flex-start', marginLeft: Metrics.base * 2 }}
        >
          <Subheading>Trip Start</Subheading>
          <Text>
            {trip && trip.startTimeDisplay ? trip.startTimeDisplay : '00:00:00'}
          </Text>
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction='row'
        fillSpace
        style={{
          width: '100%',
          marginLeft: Metrics.base * 2,
          justifyContent: 'flex-start',
          alignItems: 'stretch'
        }}
      >
        <FlexBox
          style={{ width: 1, backgroundColor: Colors.dividerColor }}
        ></FlexBox>
        <FlexBox
          style={{
            width: '90%',
            paddingBottom: Metrics.base * 4,
            paddingTop: Metrics.base * 2
          }}
        >
          <CardsContainer
            innerContainerStyle={{
              borderTop: 'none',
              borderBottom: 'none',
              backgroundColor: 'none'
            }}
            loading={eventsLoading}
          >
            <CardsList items={eventIds} itemsName='Events'>
              {({ items, item, index }) => (
                <>
                  <FlexBox
                    direction='column'
                    style={{ alignItems: 'flex-start', width: '100%' }}
                  >
                    <EventTimestamp
                      eventId={item._id}
                      startTime={trip.startTime}
                      endTime={trip.endTime}
                    />
                    <FlexBox direction='row' style={{ width: '100%' }}>
                      <div
                        style={{
                          width: 30,
                          height: 1,
                          backgroundColor: Colors.dividerColor
                        }}
                      ></div>
                      <EventCard
                        eventId={item._id}
                        onClick={() =>
                          handleEventCardOnClick({
                            type: item.type,
                            id: item._id,
                            navigateTo
                          })
                        }
                        navigateTo={navigateTo}
                        index={index}
                        key={index}
                        selected={true}
                        noBoxShadow
                        backgroundColor={Colors.subsectionBackground}
                        borderBottom={
                          index + 1 >= items.length || items[index + 1]
                        }
                      />
                      {/*<FlexBox
                        direction='column'
                        style={{ alignItems: 'flex-start', width: 300 }}
                      >
                        <Button
                          condensed
                          title='Add to coaching'
                          textColor='white'
                          background='red'
                          // onClick={() => setShowMoreFilters(true)}
                          style={{ marginBottom: Metrics.base }}
                        />
                        <Button
                          condensed
                          title='Open in trip history'
                          textColor='white'
                          background='red'
                          // onClick={() => setShowMoreFilters(true)}
                          style={{ marginBottom: Metrics.base }}
                      />
                      </FlexBox>*/}
                    </FlexBox>
                  </FlexBox>
                </>
              )}
            </CardsList>
          </CardsContainer>
        </FlexBox>
      </FlexBox>
      <FlexBox
        style={{
          marginLeft: Metrics.base * 1.5,
          marginTop: -Metrics.base * 2,
          marginBottom: Metrics.base * 6
        }}
      >
        <div
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: Colors.dividerColor
          }}
        ></div>
        <FlexBox
          direction='column'
          style={{ alignItems: 'flex-start', marginLeft: Metrics.base * 2 }}
        >
          <Subheading>Trip End</Subheading>
          <Text>
            {trip && trip.endTimeDisplay ? trip.endTimeDisplay : '00:00:00'}
          </Text>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

const BreakdownExtraData = ({
  value1,
  value2,
  value3,
  value4,
  value5,
  value6,
  value1No10,
  value1Total,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  label1No10,
  label1Total
}) => {
  const Colors = useColors()
  return (
    <>
      {checkVal(value1No10) ? (
        <FlexBox
          style={{
            width: '100%',
            borderTop: `1px solid ${Colors.dividerColor}`,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value1No10}
            getDisplayVal={(value) => value}
            label={label1No10}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value1Total) ? (
        <FlexBox
          style={{
            width: '100%',
            borderTop: `1px solid ${Colors.dividerColor}`,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value1Total}
            getDisplayVal={(value) => `${value.toFixed(2)}/100`}
            label={label1Total}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value1) ? (
        <FlexBox
          style={{
            width: '100%',
            borderTop: `1px solid ${Colors.dividerColor}`,
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value1}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label1}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value2) ? (
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value2}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label2}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value3) ? (
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value3}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label3}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value4) ? (
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value4}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label4}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value5) ? (
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value5}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label5}
          />
        </FlexBox>
      ) : (
        ''
      )}
      {checkVal(value6) ? (
        <FlexBox
          style={{
            width: '100%',
            borderBottom: `1px solid ${Colors.dividerColor}`,
            padding: Metrics.base
          }}
        >
          <InfoBadge
            size='small'
            value={value6}
            getDisplayVal={(value) => `${value.toFixed(2)}/10`}
            label={label6}
          />
        </FlexBox>
      ) : (
        ''
      )}
    </>
  )
}

export const DescriptionContent = ({ points = [] }) => {
  const Colors = useColors()
  return (
    <FlexBox
      style={{
        width: '100%',
        justifyContent: 'flex-start',
        padding: Metrics.base * 2
      }}
    >
      <Text color={Colors.secondaryOpacity1}>
        <ul>
          {points.map((point, index) => (
            <>
              <li>{point}</li>
              {index !== points.length - 1 && <br />}
            </>
          ))}
        </ul>
      </Text>
    </FlexBox>
  )
}

const SpeedingBreakdownExtraData = ({ tripSafetyData }) => {
  return (
    <>
      {tripSafetyData && (
        <BreakdownExtraData
          value1={tripSafetyData.numberSpeedingEventsScore}
          value2={tripSafetyData.averageSpeedingScore}
          value3={tripSafetyData.worstSpeedingScore}
          value4={tripSafetyData.percentSpeedingScore}
          label1={'Number Of Events Score'}
          label2={'Average Speeding Score'}
          label3={'Worst Speeding Score'}
          label4={'Percent Speeding Score'}
        />
      )}
    </>
  )
}

const SmoothDrivingBreakdownExtraData = ({ tripSafetyData }) => {
  return (
    <>
      {tripSafetyData && (
        <BreakdownExtraData
          value1={tripSafetyData.averageAccScore}
          value2={tripSafetyData.worstAccScore}
          value3={tripSafetyData.averageCorneringScore}
          value4={tripSafetyData.worstCorneringScore}
          value5={tripSafetyData.averageBrakingScore}
          value6={tripSafetyData.worstBrakingScore}
          label1={'Average Harsh Acceleration Score'}
          label2={'Worst Harsh Acceleration Score'}
          label3={'Average Harsh Cornering Score'}
          label4={'Worst Harsh Cornering Score'}
          label5={'Average Harsh Braking Score'}
          label6={'Worst Harsh Braking Score'}
        />
      )}
    </>
  )
}

const RestedFocusedExtraData = ({ tripSafetyData }) => {
  return (
    <>
      {tripSafetyData && (
        <BreakdownExtraData
          value1No10={
            tripSafetyData.restedFocusedEventIDList
              ? tripSafetyData.restedFocusedEventIDList.length
              : 0
          }
          label1No10={'Number Of Rested/Focused Events'}
          value1Total={tripSafetyData.phoneMotionCategoryNormScore100}
          value3={tripSafetyData.averagePhoneMotionScore}
          value4={tripSafetyData.worstPhoneMotionScore}
          label1Total={'Phone Motion Category Score'}
          label3={'Average Phone Motion Score'}
          label4={'Worst Phone Motion Score'}
        />
      )}
    </>
  )
}

const CompliantExtraData = ({ tripSafetyData }) => {
  return (
    <>
      {tripSafetyData && (
        <BreakdownExtraData
          value1={tripSafetyData.vehicleInspectionEventScore}
          value2={tripSafetyData.driverLicenseEventScore}
          value3={tripSafetyData.vehicleLicenseEventScore}
          label1={'Daily Vehicle Inspection Score'}
          label2={'Driver`s Licence Validity Score'}
          label3={'Vehicle Licence Validity Score'}
        />
      )}
    </>
  )
}

const ContextualExtraData = ({ tripSafetyData }) => {
  return (
    <>
      {tripSafetyData && (
        <BreakdownExtraData
          value1No10={
            tripSafetyData.generalSafetyIDList
              ? tripSafetyData.generalSafetyIDList.length
              : 0
          }
          label1No10={'Number of Contextual Events'}
        />
      )}
    </>
  )
}

const BreakDown = ({ score, eventIds, tripSafetyData, safetyType, trip }) => {
  const [selectedEventTypes, setSelectedEventTypes] = useState({
    SPEEDING: true,
    HARSH_BRAKING: true,
    HARSH_ACCELERATION: true,
    HARSH_CORNERING: true
  })
  const { mainContainerHeight } = useAppDimensions()
  const Colors = useColors()

  return (
    <FlexBox
      fillSpace
      direction='column'
      style={{
        alignItems: 'flex-start',
        width: '100%'
      }}
    >
      <FlexBox
        style={{
          width: '100%',
          justifyContent: 'flex-start',
          padding: Metrics.base * 2,
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        {safetyType === 'speeding' ? (
          <DescriptionContent
            points={[
              `This score takes into account both the number, individual severity, worst and average of all speeding events during a trip. This score also includes the percentage of time spent speeding during a trip. `,
              'The Speeding Score accounts for 35% of the Combined Trip Safety Score.',
              'Drivers who score low in the Speeding Category are regularly exceeding the local speed limit on the routes that they are travelling. '
            ]}
          />
        ) : safetyType === 'smooth' ? (
          <DescriptionContent
            points={[
              `This score takes into account both the number, individual severity, worst and average of all acceleration events in a trip. Acceleration events are typed into harsh braking, harsh cornering and harsh acceleration. `,
              `The Smooth Driving Score accounts for 30% of the Combined Trip Safety Score.`,
              `Drivers who score low in the Smooth Driving Category are regularly braking, accelerating and cornering harshly during their trips. `
            ]}
          />
        ) : safetyType === 'rested' ? (
          <DescriptionContent
            points={[
              `This score takes into account how rested and focused a driver is during a trip. This score combines driving time, continuous driving time and phone motion events that occur during a trip. `,
              `This accounts for 15% of the Combined Trip Safety Score. `,
              `Drivers who score low in the Rested/Focused Category are regularly checking their phones and are travelling for long durations and without rest breaks. `
            ]}
          />
        ) : safetyType === 'compliant' ? (
          <DescriptionContent
            points={[
              `This score takes into account legal and compliance aspects for a trip. This score combines vehicle license, driver license and inspection events.  `,
              `This accounts for 10% of the Combined Trip Safety Score.`,
              `Drivers who score low in the Compliance Category are conducting trips with expired vehicle or drivers licenses and are not doing pre or post-trip inspections on their vehicles. `
            ]}
          />
        ) : safetyType === 'contextual' ? (
          <DescriptionContent
            points={[
              `This score takes into account general safety aspects of a trip. This score combines trip distance, night driving, incident and crash events that occur during a trip. `,
              `This accounts for 10% of the Combined Trip Safety Score. `,
              `Drivers who score low in the Contextual Category are either driving long distances, at night or are involved in incidents and crashes. `
            ]}
          />
        ) : (
          ''
        )}
      </FlexBox>
      <FlexBox
        fillSpace
        direction='row'
        style={{
          width: '100%',
          alignItems: 'stretch'
        }}
      >
        <FlexBox
          fillSpace
          direction='column'
          style={{
            width: 400,
            justifyContent: 'flex-start'
          }}
        >
          <CategoryScore hideBorderRight score={score} category='Total Score' />
          {/*<EventsSummary
          selected={selectedEventTypes}
          setSelected={setSelectedEventTypes}
        />*/}
          {safetyType === 'speeding' ? (
            <SpeedingBreakdownExtraData tripSafetyData={tripSafetyData} />
          ) : safetyType === 'smooth' ? (
            <SmoothDrivingBreakdownExtraData tripSafetyData={tripSafetyData} />
          ) : safetyType === 'rested' ? (
            <RestedFocusedExtraData tripSafetyData={tripSafetyData} />
          ) : safetyType === 'compliant' ? (
            <CompliantExtraData tripSafetyData={tripSafetyData} />
          ) : safetyType === 'contextual' ? (
            <ContextualExtraData tripSafetyData={tripSafetyData} />
          ) : (
            ''
          )}
        </FlexBox>
        <FlexBox fillSpace style={{}}>
          <LargeDivider vertical />
        </FlexBox>
        <FlexBox
          fillSpace
          style={{
            //height:
            //  mainContainerHeight - Metrics.tabBarHeight * 2 - Metrics.base * 15,
            width: 600,
            // overflowY: 'scroll',
            marginTop: Metrics.base
          }}
        >
          {eventIds && eventIds.length > 0 ? (
            <EventsTimeline eventIds={eventIds} Colors={Colors} trip={trip} />
          ) : (
            <NoneSelected
              displayText='No Events'
              helperText='No events found for this category'
            />
          )}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

const TripScoreBreakdown = () => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const tripId = useReactiveVar(selectedTripId)
  const { data: trip } = useTrip(tripId)
  const {
    data: tripSafetyData,
    combinedScore,
    speedingScore,
    smoothDrivingScore,
    restedFocusedScore,
    compliantScore,
    contextualScore,
    speedingEventsIds,
    smoothDrivingEventsIds,
    restedFocusedEventsIds,
    complianceEventsIds,
    contextualEventsIds
  } = useTripSafetyByTripId(tripId)
  return (
    <FlexBox
      fillSpace
      direction='column'
      justifyContent='space-evenly'
      style={{
        width: mainContainerWidth - Metrics.base * 4,
        height: '100%'
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        fillSpace
        style={{
          width: '100%',
          // height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(tripId) ? (
          <TabBar
            tabs={[
              {
                tabRender: () => {
                  return (
                    <BreakDown
                      score={speedingScore}
                      eventIds={speedingEventsIds}
                      tripSafetyData={tripSafetyData}
                      trip={trip}
                      safetyType={'speeding'}
                    />
                  )
                },
                name: 'Speeding'
              },
              {
                tabRender: () => {
                  return (
                    <BreakDown
                      score={smoothDrivingScore}
                      eventIds={smoothDrivingEventsIds}
                      tripSafetyData={tripSafetyData}
                      trip={trip}
                      safetyType={'smooth'}
                    />
                  )
                },
                name: 'Smooth Driving'
              },
              {
                tabRender: () => {
                  return (
                    <BreakDown
                      score={restedFocusedScore}
                      eventIds={restedFocusedEventsIds}
                      tripSafetyData={tripSafetyData}
                      trip={trip}
                      safetyType={'rested'}
                    />
                  )
                },
                name: 'Rested/Focused'
              },
              {
                tabRender: () => {
                  return (
                    <BreakDown
                      score={compliantScore}
                      eventIds={complianceEventsIds}
                      tripSafetyData={tripSafetyData}
                      trip={trip}
                      safetyType={'compliant'}
                    />
                  )
                },
                name: 'Compliant'
              },
              {
                tabRender: () => {
                  return (
                    <BreakDown
                      score={contextualScore}
                      eventIds={contextualEventsIds}
                      tripSafetyData={tripSafetyData}
                      trip={trip}
                      safetyType={'contextual'}
                    />
                  )
                },
                name: 'Contextual'
              }
            ]}
            tabInnerStyle={{
              // marginTop: Metrics.base * 2
              borderRadius: 0
            }}
            containerStyle={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
              flexGrow: 1
            }}
            tabContainerStyle={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
              flexGrow: 1
            }}
          />
        ) : (
          <NoneSelected helperText='Select a trip from the list on the left' />
        )}
      </Subsection>
    </FlexBox>
  )
}

export default TripScoreBreakdown
