import React from 'react'
import { FlexBox, Icon, Text } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'

const TrendIndicator = ({
  value,
  getDisplayVal = (val) => val,
  displayTextProps,
  suffix,
  style,
  innerStyle,
  displayFont,
  labelFont,
  label,
  size = 'medium',
  direction
}) => {
  const Colors = useColors()
  const spacing =
    size === 'small'
      ? Metrics.base / 2
      : size === 'medium'
      ? Metrics.base
      : Metrics.base * 2

  const color = value >= 0 ? '#62B27A' : '#D2402C'
  return (
    <FlexBox
      direction={direction ? direction : 'column'}
      fillSpace
      style={{ ...style }}
    >
      <FlexBox
        direction='row'
        fillSpace
        style={{
          padding: spacing,
          ...innerStyle
        }}
      >
        <Icon
          name={value >= 0 ? 'arrow_drop_up' : 'arrow_drop_down'}
          color={color}
          style={{
            marginRight: spacing,
            fontSize: size === 'small' ? 20 : size === 'medium' ? 28 : 35
          }}
        />
        <Text
          font={
            displayFont
              ? displayFont
              : size === 'large'
              ? 'display'
              : size === 'medium'
              ? 'heading'
              : size === 'small'
              ? 'subheading'
              : 'display'
          }
          color={color}
          style={{
            textAlign: 'left',
            ...displayTextProps,
            whiteSpace: 'nowrap'
          }}
        >
          {checkVal(value) ? (
            <>
              {getDisplayVal(Math.abs(value))} {suffix && suffix}
            </>
          ) : (
            '-'
          )}
        </Text>
      </FlexBox>
      {label && (
        <FlexBox
          style={{
            width: '100%',
            paddingBottom: size === 'small' ? Metrics.base / 2 : Metrics.base,
            paddingTop: Metrics.base / 2,
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2
          }}
        >
          <Text
            color={Colors.secondary}
            font={
              labelFont
                ? labelFont
                : size === 'large'
                ? 'title'
                : size === 'medium'
                ? 'medium'
                : size === 'small'
                ? 'caption'
                : 'medium'
            }
          >
            {label}
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default TrendIndicator
