import React from 'react'
import { Images, Metrics, useColors } from '../../../../../Themes'
import Text from '../../../../Text'
import { Title } from '../../../../Typography'
import Status from '../../../../Status'
import Card from '../../Cards/Card'
import { checkVal, hhmmss } from '../../../../../Helpers/Functions'
import { useEvent } from '../../../../../Graphql/Queries/Event/useEvent'
import EventCardTTList from '../EventCardTTList'
import { selectedTripTotalEventId } from '../../../../../Graphql/GraphQLClient'
import { FlexBox } from '../../../..'

const EventCardTripTotals = ({
  eventId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom,
  navigateTo,
  onClick
}) => {
  const Colors = useColors()
  const { data, loading: eventLoading } = useEvent(eventId)

  return (
    <Card
      id={eventId}
      data={data}
      selected={selected}
      typeName={'trip-total-event'}
      setSelectedId={selectedTripTotalEventId}
      navigateTo={navigateTo}
      onClick={onClick}
      TooltipContent={({ setShowTooltip }) => (
        <EventCardTTList
          eventId={eventId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={eventLoading}
      titleContent={
        <>
          <Title color={Colors.primary}>{data.name}</Title>
        </>
      }
      bodyContent={
        data &&
        data.trip &&
        data.trip.displayId && (
          <Text marginBottom={1} marginTop={1 / 2}>
            Trip {data.trip.displayId}
          </Text>
        )
      }
      topRightContent={
        <Status
          status={data.severityCategoryDisplayText}
          getStatusColors={() => Colors[data.severityCategory]}
        ></Status>
      }
      topRightLoading={eventLoading}
      bottomLeftContent={
        <>
          {' '}
          {data.type === 'PERCENT_SPEEDING' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Percentage: {data.rawScore} %
            </Text>
          ) : data.type === 'TRIP_DISTANCE' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Distance:{' '}
              {data.totalDistance > 0 ? data.totalDistance / 1000 + ' km' : ''}
            </Text>
          ) : data.type === 'TRAVEL_TIME' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Travel Time: {hhmmss(data.totalTravelTime)}
            </Text>
          ) : data.type === 'DRIVING_TIME' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Driving Time: {hhmmss(data.totalTomTomTravelTime)}
            </Text>
          ) : data.type === 'CONTINUOUS_DRIVING' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Continuous Time: {hhmmss(data.rawScore * 60 * 60)}
            </Text>
          ) : data.type === 'NIGHT_DRIVING' ? (
            <Text font='caption' color={Colors.secondaryOpacity3}>
              Night Driving Time: {hhmmss(data.nightDrivingHours * 60 * 60)}
            </Text>
          ) : (
            ''
          )}
        </>
      }
      bottomRightContent={
        checkVal(data.normScore) && (
          <FlexBox>
            <img
              width={13}
              style={{ marginRight: Metrics.base / 2, opacity: '0.8' }}
              alt={'shield'}
              src={Images.shieldIcon}
            />
            <Text>{Math.round(data.normScore * 100) / 100}/10</Text>
          </FlexBox>
        )
      }
    />
  )
}

export default EventCardTripTotals
