import { Metrics } from '../../Themes'

export const Styles = {
  exportButton: {
    display: 'flex',
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: Metrics.base,
    borderRadius: Metrics.radius,
    marginRight: Metrics.base * 2,
    width: 150,
    cursor: 'pointer'
    // backgroundColor: 'red'
  }
}
