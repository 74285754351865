import React, { useEffect } from 'react'
import { css } from 'aphrodite'
import FlexBox from '../../../FlexBox'
import { Metrics, useColors, Utils } from '../../../../Themes'
import { Styles } from '../Styles'
import { LinearProgress, withStyles } from '@material-ui/core'
import Text from '../../../Text'
import Icon from '../../../Icon'

const EmptyCard = ({
  text,
  borderBottom,
  backgroundColor,
  cardHeight = 90
}) => {
  const Colors = useColors()
  const shadow = Colors.isDarkmode ? Utils.boxShadowDark : Utils.boxShadowLight
  const styles = Styles({
    Colors,
    Metrics,
    selected: false,
    borderBottom,
    backgroundColor,
    cardHeight,
    shadow
  })
  return (
    <FlexBox className={css(styles.card)}>
      <FlexBox direction='column' className={css(styles.cardInner)}>
        <FlexBox>
          <Icon
            color={Colors.secondaryOpacity3}
            name='error'
            style={{ marginRight: Metrics.base }}
          ></Icon>
          <Text>{text}</Text>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default EmptyCard
