import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const organizationFragment = gql`
  fragment organizationFragment on Organization {
    _id
    name
    displayId
    isActive
  }
`

export const GET_ORGANIZATIONS = gql`
  query organizations($skip: Int, $limit: Int) {
    organizations(skip: $skip, limit: $limit) {
      ...organizationFragment
    }
  }
  ${organizationFragment}
`

const dataTransform = ({ data }) => {
  let temp = data.map((a) => organizationDataTransform({ data: a }))

  return temp
}

export const organizationDataTransform = ({ data }) => {
  if (data) {
    return {
      ...data,
      id: data._id ? data._id : null,
      name: data.name ? data.name : null
    }
  }
}

const getOrganizationVariables = () => {
  let variables = {
    skip: 0,
    limit: 50
  }
  return variables
}

export const useOrganizations = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ORGANIZATIONS,
    {
      variables: getOrganizationVariables(),
      queryName: 'organizations',
      errorMessage: 'Failed to fetch Organizations',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
