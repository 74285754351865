import React from 'react'
import { Button, MaterialInput, Text, FlexBox, Icon } from '../../Components'
import { useMutation } from '@apollo/client'
import { Images, useColors, Metrics } from '../../Themes'
import { LOGIN } from '../../Graphql/Mutations'
import { MainLayout, Subsection } from '../../Components'
import { Formik } from 'formik'
import { Subheading } from '../../Components/Typography'
import { Link } from 'react-router-dom'
import { client, getLink } from '../../Graphql/GraphQLClient'

export const Copyright = () => {
  const Colors = useColors()
  return (
    <Text font='caption' color={Colors.textSecondary}>
      Copyright ©
      <Link
        style={{ color: Colors.textSecondary }}
        target='_blank'
        href='https://www.cybelefleet.tech'
      >
        {' '}
        Cybele{' '}
      </Link>
      {new Date().getFullYear()} | Version {Metrics.version}
    </Text>
  )
}

export const handleLogin = (data, history) => {
  localStorage.setItem('cybeleUserId', data._id)
  localStorage.setItem('username', data.username)
  localStorage.setItem('token', data.jwt)
  client.setLink(getLink(data.jwt))
  history.push('/dashboard')
}

const handleLoginButton = ({ login, history, updateNotification, values }) => {
  login({ variables: values })
    .then(({ data }) => handleLogin(data.login, history))
    .catch((e) =>
      updateNotification({
        variables: {
          show: true,
          level: 'danger',
          text: e.message
        }
      })
    )
}

const Login = ({ history }) => {
  const [login, { loading }] = useMutation(LOGIN)
  const Colors = useColors()
  return (
    <MainLayout publicLayout>
      <FlexBox
        fillSpace
        style={{
          backgroundColor: Colors.subsectionBackground,
          width: '100%',
          flex: 1
        }}
      >
        <FlexBox direction='row'>
          <img
            alt='Cybele Emblem'
            width={80}
            src={Images.CybeleEmblemBeta}
            style={{
              marginTop: -Metrics.base,
              marginRight: Metrics.base * 3
            }}
          ></img>
          <img
            src={Images.CybeleLogotype}
            alt='BlockHarvest Logo'
            style={{
              width: 400,
              marginTop: -Metrics.base,
              marginLeft: Metrics.base * 2
            }}
            // style={Styles.logo}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        fillSpace
        style={{
          backgroundColor: Colors.background,
          borderTop: `1px solid ${Colors.dividerColor}`,
          width: '100%',
          flex: 1
        }}
      >
        <Subsection
          style={{
            width: '60%',
            paddingBottom: Metrics.base * 4,
            flexDirection: 'column'
          }}
        >
          <FlexBox
            style={{
              height: Metrics.navBarHeight,
              width: '100%',
              display: 'flex',
              borderBottom: `1px solid ${Colors.dividerColor}`,
              padding: Metrics.base * 2,
              marginBottom: Metrics.base * 6
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
                padding: Metrics.base * 2,
                justifyContent: 'space-between',
                backgroundColor: Colors.selectHighlightOpacity2
              }}
            >
              <FlexBox
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <FlexBox>
                  <Icon
                    name='lock'
                    style={{
                      marginRight: Metrics.base * 2
                    }}
                    size='large'
                    color={Colors.primaryOpacity2}
                  />
                  <Subheading>Login</Subheading>
                </FlexBox>
                <Copyright />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <Formik
            initialValues={{
              username: '', // email ? email : '',
              password: ''
            }}
            // validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              isValid
            }) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ width: '80%' }}>
                  <MaterialInput
                    id='login-email'
                    name='login-email'
                    label='Email *'
                    value={values.username}
                    handleChange={(value) => setFieldValue('username', value)}
                    onBlur={() => setFieldTouched('username')}
                    error={touched.username && errors.username}
                  />
                  <MaterialInput
                    id='login-password'
                    name='login-password'
                    label='Password *'
                    type='password'
                    value={values.password}
                    handleChange={(value) => setFieldValue('password', value)}
                    onBlur={() => setFieldTouched('password')}
                    error={touched.password && errors.password}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleLoginButton({
                          login,
                          history,
                          updateNotification: () => {},
                          values,
                          updateIsTourOpen: () => {}
                        })
                      }
                    }}
                  />
                  <Button
                    primary
                    id='login-button'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                    onClick={() =>
                      handleLoginButton({
                        login,
                        history,
                        updateNotification: () => {},
                        values,
                        updateIsTourOpen: () => {}
                      })
                    }
                    textColor='white'
                    center
                    fetching={isSubmitting || loading}
                    expand
                    radius
                    title='Login'
                    type='submit'
                    disabled={
                      !isValid ||
                      values.username === '' ||
                      values.password === '' ||
                      isSubmitting ||
                      loading
                    }
                    background={Colors.primary}
                  />
                </div>
              </div>
            )}
          </Formik>
          <Link to='/forgot-password' style={{ marginTop: Metrics.base * 2 }}>
            <Text color={Colors.primary} bold>
              <u>Forgot Password?</u>
            </Text>
          </Link>
        </Subsection>
      </FlexBox>
    </MainLayout>
  )
}

export default Login
