import { Colors, Metrics } from '../../Themes'

export const inlineStyles = {
  innerFormContainer: {
    padding: Metrics.base * 2,
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%'
  },
  changeIndicator: {
    width: 3,
    height: 30,
    marginLeft: -15,
    marginRight: 12,
    borderRadius: 5,
    backgroundColor: Colors.spot2
  },
  submitButton: { marginTop: 20 }
}
