import React from 'react'
import { Metrics, useColors } from '../../../Themes'
import { FlexBox, Text } from '../../../Components'
import { Display } from '../../../Components/Typography'
import { abbreviateNumber } from '../../../Helpers/Functions'

const TripTotalsTabButton = ({ data, loading, selected }) => {
  const Colors = useColors()
  return (
    <FlexBox
      direction='column'
      style={{
        width: '100%',
        borderRadius: Metrics.radius,
        border: selected
          ? 'none'
          : `1px solid ${Colors.selectHighlightOpacity3}`,
        padding: Metrics.base * 2,
        alignItems: 'flex-start'
      }}
    >
      <FlexBox
        direction='row'
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingBottom: Metrics.base * 2
        }}
      >
        <FlexBox direction='column' style={{ alignItems: 'flex-start' }}>
          <Display
            color='white'
            loading={loading}
            loadingBarWidth={100}
            loadingBarHeight={65}
            loadingBackgroundColor={Colors.selectHighlightOpacity4}
            loadingBarColor={Colors.selectHighlightOpacity4}
            Colors={Colors}
            style={{
              fontSize: 70
            }}
          >
            {data && abbreviateNumber(data.tripsCount)}
          </Display>

          <Text
            color={Colors.background}
            style={{
              fontSize: 20,
              alignSelf: 'left'
            }}
          >
            Trips
          </Text>
        </FlexBox>
      </FlexBox>
      <div
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.selectHighlightOpacity3}`
        }}
      ></div>
      <FlexBox>
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            paddingTop: Metrics.base * 2,
            paddingLeft: Metrics.base
          }}
        >
          <Text
            color='white'
            loading={loading}
            loadingBarWidth={30}
            loadingBarHeight={20}
            loadingBackgroundColor={Colors.selectHighlightOpacity4}
            loadingBarColor={Colors.selectHighlightOpacity4}
            style={{
              fontSize: 22,
              paddingBottom: Metrics.base
            }}
          >
            {data && data.tripsThisWeek}
          </Text>

          <Text
            color={Colors.background}
            style={{
              fontSize: 14
            }}
          >
            Previous Week
          </Text>
        </FlexBox>
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            paddingTop: Metrics.base * 2,
            paddingLeft: Metrics.base,
            marginLeft: Metrics.base * 2
          }}
        >
          <Text
            color='white'
            loading={loading}
            loadingBarWidth={30}
            loadingBarHeight={20}
            loadingBackgroundColor={Colors.selectHighlightOpacity4}
            loadingBarColor={Colors.selectHighlightOpacity4}
            style={{
              fontSize: 22,
              paddingBottom: Metrics.base
            }}
          >
            {data && data.tripsThisMonth}
          </Text>

          <Text
            color={Colors.background}
            style={{
              fontSize: 14
            }}
          >
            Previous Month
          </Text>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default TripTotalsTabButton
