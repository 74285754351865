import React, { useState } from 'react'
import { FlexBox, Subsection, Text } from '../../../Components'
import NoneSelected from '../../../Components/Custom/NoneSelected'
import ScoreCircleChart from '../../../Components/Custom/ScoreCircleChart'
import TabRoutes from '../../../Components/TabRoutes'
import { getSelectedId } from '../../../Components/TabRoutes/TabRoutingBar'
import { useTabs } from '../../../Components/Tabs/useTabs'
import {
  Display,
  Heading,
  Subheading,
  Title2
} from '../../../Components/Typography'
import { selectedAssetId, selectedTabs } from '../../../Graphql/GraphQLClient'
import { useDriverSafetyByDriverId } from '../../../Graphql/Queries/Driver/useDriverSafetyByDriverId'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { useSelectedTabs } from '../../../Graphql/Queries/useSelectedTabs'
import {
  checkVal,
  getSelectedObject,
  removeTrailingSlashes
} from '../../../Helpers/Functions'
import { Metrics, useColors } from '../../../Themes'
import { useRouteMatch, useLocation, Link } from 'react-router-dom'
import ResponsiveGrid from '../../../Components/ResponsiveGrid'
import Maintenance from './Categories/Maintenance'
import Legal from './Categories/Legal'
import History from './Categories/History'
import { useAssetSafetyByAssetId } from '../../../Graphql/Queries/Asset/useAssetSafetyByAssetId'
import { useReactiveVar } from '@apollo/client'

export const getScoreCategory = (score) => {
  if (score >= 90) return 'EXCELLENT'
  if (score >= 75) return 'SAFE'
  if (score >= 60) return 'ADEQUATE'
  if (score >= 50) return 'MODERATE_RISK'
  return 'HIGH_RISK'
}

export const CategoryScoreTabButton = ({
  score,
  name,
  hideBorderRight,
  selected,
  linkToUrl
}) => {
  const Colors = useColors()
  return (
    <Link to={linkToUrl} style={{ width: '100%', height: '100%' }}>
      <FlexBox
        fillSpace
        selectable
        Colors={Colors}
        selected={selected}
        backgroundColor={Colors.subsectionBackground}
        style={{
          width: '100%',
          height: '100%',
          minHeight: 90,
          borderRadius: 10,
          justifyContent: 'space-between',
          paddingLeft: Metrics.base * 2,
          paddingRight: Metrics.base * 4
        }}
      >
        <FlexBox
          direction='column'
          style={{
            alignItems: 'flex-start',
            padding: Metrics.base * 2
          }}
        >
          <Subheading>
            {checkVal(score) ? `${Math.round(score)}/100` : '-'}
          </Subheading>
          <Text color={Colors.textSecondary} marginTop={1}>
            {name}
          </Text>
        </FlexBox>
        <ScoreCircleChart
          score={checkVal(score) ? score : null}
          maxScore={100}
          color={Colors[getScoreCategory(score)]}
          backgroundColor={Colors.secondary}
          size={70}
        />
      </FlexBox>
    </Link>
  )
}

const AssetScoreBreakdown = ({}) => {
  const Colors = useColors()
  const { mainContainerWidth, mainContainerHeight } = useAppDimensions()
  const assetId = useReactiveVar(selectedAssetId)
  // const { driverBehaviour: selectedTabId } = useSelectedTabs()

  const {
    data: assetSafetyData,
    maintenanceScore,
    legalScore,
    historyScore,
    loading: eventsLoading
  } = useAssetSafetyByAssetId(assetId)

  const eventTypeCounts = null

  const tabsData = [
    {
      id: 'maintenance-score',
      name: 'Maintenance Score',
      score: maintenanceScore,
      description: 'This is a description for maintenance score'
    },
    {
      id: 'legal-score',
      name: 'Legal Score',
      score: legalScore,
      description: 'This is a description for legal score'
    },
    {
      id: 'history-score',
      name: 'History Score',
      score: historyScore,
      description: 'This is a description for history score'
    }
  ]
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const pathIds = pathname.split('/')
  const selectedId = getSelectedId({ ids: pathIds, tabsData })
  const selectedTabObject = getSelectedObject({
    id: selectedId,
    key: 'id',
    list: tabsData
  })

  return (
    <FlexBox
      fillSpace
      direction='column'
      justifyContent='flex-start'
      style={{
        width: mainContainerWidth - Metrics.base * 4
        // height: mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 10
      }}
    >
      <Subsection
        style={{
          width: '100%',
          // height: '100%',
          marginTop: Metrics.base * 2,
          justifyContent: 'flex-start'
        }}
      >
        {checkVal(assetId) ? (
          <>
            <FlexBox
              direction='column'
              style={{
                paddingLeft: Metrics.base * 2,
                paddingRight: Metrics.base * 2,
                paddingTop: Metrics.base * 2,
                paddingBottom: Metrics.base * 2,
                width: '100%',
                minHeight: 200
              }}
            >
              {selectedTabObject && (
                <FlexBox
                  direction='column'
                  style={{
                    width: '100%',
                    alignItems: 'flex-start',
                    padding: Metrics.base * 2,
                    paddingBottom: Metrics.base * 4,
                    marginBottom: Metrics.base * 2,
                    borderBottom: `1px solid ${Colors.dividerColor}`
                  }}
                >
                  <Heading>
                    {selectedTabObject.name}:{' '}
                    {Math.round(selectedTabObject.score)}/100
                  </Heading>
                  {/*<Title2>{selectedTabObject.description}</Title2>*/}
                </FlexBox>
              )}
              <FlexBox
                style={{
                  width: '100%',
                  borderBottom: `1px solid ${Colors.dividerColor}`,
                  paddingBottom: Metrics.base * 3,
                  marginBottom: Metrics.base * 4
                }}
              >
                <ResponsiveGrid style={{ width: '100%' }}>
                  {tabsData.map((item) => (
                    <FlexBox style={{ width: '100%' }}>
                      <CategoryScoreTabButton
                        score={item.score}
                        selected={selectedId === item.id}
                        name={item.name}
                        linkToUrl={`${removeTrailingSlashes(url)}/${item.id}`}
                        Colors={Colors}
                        hideBorderRight
                      />
                    </FlexBox>
                  ))}
                </ResponsiveGrid>
              </FlexBox>
              <TabRoutes renderTabBar={false} tabsData={tabsData}>
                <Maintenance
                  assetSafetyData={assetSafetyData}
                  score={maintenanceScore}
                  assetId={assetId}
                  events={[]}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <Legal
                  assetSafetyData={assetSafetyData}
                  score={legalScore}
                  assetId={assetId}
                  events={[]}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
                <History
                  assetSafetyData={assetSafetyData}
                  score={historyScore}
                  assetId={assetId}
                  events={[]}
                  eventsLoading={eventsLoading}
                  eventTypeCounts={eventTypeCounts}
                />
              </TabRoutes>
            </FlexBox>
          </>
        ) : (
          <NoneSelected
            height={
              mainContainerHeight - Metrics.tabBarHeight - Metrics.base * 12
            }
            helperText='Select an asset from the list on the left'
          />
        )}
      </Subsection>
      {/*selectedTabId && <SelectedTabContent />*/}
    </FlexBox>
  )
}

export default AssetScoreBreakdown
