import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_ASSET_SAFETY_BY_ASSET_ID = gql`
  query assetSafetyByAssetId($assetId: String!) {
    assetSafetyByAssetId(assetId: $assetId) {
      assetSafetyCategory
      assetSafetyScore100
    }
  }
`

const dataTransform = ({ data }) => {
  const category = data.assetSafetyCategory
  const score = data.assetSafetyScore100
  return {
    category,
    score
  }
}

const getVariables = (assetId) => {
  let variables = {
    assetId
  }
  return variables
}

export const useAssetSafetyCategory = (assetId) => {
  const [category, setCategory] = useState(null)
  const [score, setScore] = useState(null)
  const [loading, setLoading] = useState([])

  const { data: queryData, loading: queryLoading } = useQuery(
    GET_ASSET_SAFETY_BY_ASSET_ID,
    {
      variables: getVariables(assetId),
      skip: !assetId,
      queryName: 'assetSafetyByAssetId',
      errorMessage: 'Failed to fetch Asset Category',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setCategory(queryData.category)
      setScore(queryData.score)
    } else {
      setCategory(null)
    }
  }, [queryData])

  return {
    category,
    score,
    loading
  }
}
