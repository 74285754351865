import { StyleSheet } from 'aphrodite'
import { Metrics } from '../../Themes'

export const Styles = (props) =>
  StyleSheet.create({
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: Metrics.base * 4,
      // paddingLeft: Metrics.spacing,
      // paddingRight: Metrics.spacing,
      backgroundColor: 'transparent',
      paddingBottom: Metrics.base * 3,
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      borderWidth: 0,
      borderStyle: 'solid',
      minWidth: Metrics.base,
      transition: Metrics.speed.lightning,

      ':enabled': {
        // backgroundImage: `linear-gradient(${Colors.backgroundTintLight}, ${Colors.backgroundTintLight},  ${Colors.primaryOpacity0})`,
        borderBottom: '3px solid ' + props.Colors.primary, // props.borderColor,
        paddingBottom: Metrics.base * 3
      },
      ':focus': {
        outline: 0
      },
      ':hover': {
        paddingBottom: props.disabled ? null : Metrics.base * 3,
        borderBottom: props.disabled
          ? null
          : '3px solid ' + props.Colors.primary // + props.borderColor
      },
      ':disabled': {
        backgroundColor: 'transparent'
      }
    },
    buttonNotSelected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: Metrics.base * 4,
      // paddingLeft: Metrics.spacing,
      // paddingRight: Metrics.spacing,
      paddingBottom: Metrics.base * 3,
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      // borderWidth: 0,
      // borderStyle: 'solid',
      border: 'none',
      // borderColor: Colors.backgroundTint,
      minWidth: Metrics.base,
      transition: Metrics.speed.lightning,

      ':enabled': {
        // backgroundColor: props.backgroundColor
        backgroundColor: 'transparent'
      },
      ':focus': {
        outline: 0
      },
      ':hover': {
        paddingBottom: props.disabled ? null : Metrics.base * 3,
        borderBottom: props.disabled
          ? null
          : '3px solid ' + props.Colors.dividerColor
      },
      ':disabled': {
        backgroundColor: 'transparent'
      }
    },
    icon: {
      marginRight: Metrics.base * 2
    },
    image: {
      marginRight: Metrics.base * 2,
      width: 28,
      height: 28
    },
    iconCenter: {
      marginRight: 0
    },
    iconRight: {
      marginLeft: Metrics.base
    }
  })
