import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon, Text, ProcessingSpinner } from '../'
import { Styles } from './Styles'
import { Metrics, useColors } from '../../Themes'

const ButtonLinks = ({
  cond,
  type,
  title,
  bold,
  image,
  textSize,
  noBorder,
  borderColorHover,
  onClick,
  fetching,
  disabled,
  icon,
  iconCenter,
  iconRight,
  iconRightSize,
  iconLeftSize,
  background,
  backgroundHover,
  gradientColor,
  border,
  textColor,
  center,
  radius,
  rounded,
  expand,
  formNoValidate,
  style,
  noUpperCase
}) => {
  const Colors = useColors()
  const backgroundColor = background || Colors.transparent
  const backgroundColorHover = backgroundHover || 'transparent'
  const borderColor = gradientColor
    ? 'transparent'
    : border || background || borderColorHover || Colors.primary
  const borderRadius = radius ? Metrics.radius : rounded ? '100%' : null
  const text = disabled ? Colors.grey2 : textColor || border || Colors.white
  const backgroundGradient = gradientColor
    ? {
        background: `linear-gradient(90deg, ${backgroundColor} 0%, ${gradientColor} 100%)`
      }
    : null
  const buttonTextSize = textSize || 'button'
  const styles = Styles({
    backgroundColor,
    backgroundColorHover,
    borderColor,
    disabled,
    Colors,
    Metrics
  })
  return (
    <button
      type={type || 'button'}
      id={`button-link-${title.replace(/\s/g, '-')}`}
      onClick={onClick}
      disabled={fetching || disabled}
      formNoValidate={formNoValidate || null}
      className={cond ? css(styles.button) : css(styles.buttonNotSelected)}
      style={{
        borderRadius,
        width: expand ? '100%' : null,
        margin: center ? 'auto' : null,
        border: noBorder ? 'none' : null,
        ...backgroundGradient,
        ...style
      }}
    >
      {fetching ? (
        <ProcessingSpinner color={text} />
      ) : (
        [
          icon ? (
            <Icon
              key={1}
              size={iconLeftSize || 'small'}
              name={icon}
              color={text}
              style={styles.icon}
            />
          ) : image && !icon ? (
            <img key={1} alt='Profile' src={image} style={styles.image} />
          ) : iconCenter ? (
            <Icon
              key={1}
              size={iconLeftSize || 'small'}
              name={iconCenter}
              color={text}
              style={styles.iconCenter}
            />
          ) : null,
          noUpperCase ? (
            <Text
              key={2}
              bold={cond ? true : false}
              font={buttonTextSize}
              disabled={disabled}
              color={cond ? Colors.primary : text}
            >
              {title}
            </Text>
          ) : (
            <Text
              key={2}
              bold={cond ? true : false}
              font={buttonTextSize}
              disabled={disabled}
              color={cond ? Colors.primary : text}
            >
              {title.toUpperCase()}
            </Text>
          )
        ]
      )}
      {iconRight ? (
        <Icon
          key={1}
          size={iconRightSize || 'small'}
          name={iconRight}
          color={text}
          style={styles.iconRight}
        />
      ) : null}
    </button>
  )
}

ButtonLinks.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  background: PropTypes.string,
  border: PropTypes.string,
  textColor: PropTypes.string,
  center: PropTypes.bool,
  radius: PropTypes.bool,
  rounded: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}

export default ButtonLinks
