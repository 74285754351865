import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const LINK_DRIVER_TO_ASSET = gql`
  mutation linkDriverToAsset($assetId: String!, $driverId: String!) {
    linkDriverToAsset(assetId: $assetId, driverId: $driverId) {
      _id
      name
      driver {
        _id
        firstName
        lastName
        idNumber
      }
    }
  }
`
export const useLinkDriverToAsset = () => {
  const [linkDriverToAsset, { loading: linkDriverToAssetLoading }] =
    useMutation(LINK_DRIVER_TO_ASSET, {
      mutationName: 'linkDriverToAsset',
      successMessage: 'Driver linked successfully',
      errorMessage: 'Driver link failed'
    })

  return [linkDriverToAsset, { linkDriverToAssetLoading }]
}
