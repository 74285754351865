import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const videoClipFragment = gql`
  fragment videoClipFragment on VideoClip {
    _id
    fileName
    eventId
  }
`
export const GET_VIDEO_CLIP_BY_EVENT_ID = gql`
  query videoClipsByEventId($eventId: String!, $skip: Int, $limit: Int) {
    videoClipsByEventId(eventId: $eventId, skip: $skip, limit: $limit) {
      ...videoClipFragment
    }
  }
  ${videoClipFragment}
`

export const videoClipTransform = ({ data }) => {
  if (data && data.length > 0) {
    return data.map((clip) => {
      return {
        ...clip,
        id: clip._id ? clip._id : null,
        fileName: clip.fileName ? clip.fileName : null,
        eventId: clip.eventId ? clip.eventId : null
      }
    })
  }
}

export const useVideoClipByEventId = (eventId) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_VIDEO_CLIP_BY_EVENT_ID,
    {
      variables: { eventId, skip: 0, limit: 0 },
      queryName: 'videoClipsByEventId',
      errorMessage: 'Failed to fetch video clips',
      dataTransform: (data) => videoClipTransform({ data }),
      skip: !eventId
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    if (queryData) {
      setData(queryData)
    } else {
      setData(null)
    }
  }, [queryData])

  return { data, loading }
}
