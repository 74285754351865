import React from 'react'
import { MainLayout } from '../../Components'
import MapView from './MapView'
import VideoReplay from './VideoReplay'
import EventBreakdown from './EventBreakdown'
import TabRoutes from '../../Components/TabRoutes'
import { useReactiveVar } from '@apollo/client'
import { selectedTabs, selectedTripId } from '../../Graphql/GraphQLClient'
import { checkVal } from '../../Helpers/Functions'
import TripHeader from '../../Components/Custom/TripHeader'
import TripReplay from './TripReplay'

const TripHistory = (props) => {
  const { tripHistory: selectedTab } = useReactiveVar(selectedTabs)
  const tripId = useReactiveVar(selectedTripId)
  return (
    <MainLayout {...props}>
      {checkVal(tripId) && <TripHeader tripId={tripId} />}
      <TabRoutes
        defaultId={selectedTab ? selectedTab : null}
        onChange={(id) => selectedTabs({ ...selectedTabs(), tripHistory: id })}
        tabsData={[
          { id: 'overview', name: 'Overview', alwaysMounted: true },
          { id: 'trip-replay', name: 'Trip Replay' },
          // { id: 'video-replay', name: 'Video Replay' },
          { id: 'timeline-charts', name: 'Timeline Charts' }
        ]}
      >
        <MapView {...props} />
        <TripReplay {...props} />
        {/*<VideoReplay {...props} />*/}
        <EventBreakdown {...props} />
      </TabRoutes>
      {/*<TabBar
        tabs={[
          {
            tabRender: () => {
              return <MapView {...props} />
            },
            name: 'Map View'
          },
          {
            tabRender: () => {
              return <VideoReplay {...props} />
            },
            name: 'Video Replay',
            hidden: !checkVal(tripId)
          },
          {
            tabRender: () => {
              return <EventBreakdown {...props} />
            },
            name: 'Event Breakdown',
            hidden: !checkVal(tripId)
          }
        ]}
        selectedIndex={
          tripHistory === 'map-view'
            ? 0
            : tripHistory === 'video-replay'
            ? 1
            : tripHistory === 'event-details'
            ? 2
            : 0
        }
        setSelectedIndex={(index) => [
          selectedTabs({
            ...selectedTabs(),
            tripHistory:
              index === 0
                ? 'map-view'
                : index === 1
                ? 'video-replay'
                : index === 2
                ? 'event-details'
                : 'map-view'
          })
        ]}
        tabInnerStyle={{
          // marginTop: Metrics.base * 2
          borderRadius: 0
        }}
        containerStyle={{
          height: '100%'
          // paddingRight: Metrics.base * 2
        }}
        tabContainerStyle={
          {
            // paddingTop: Metrics.base * 2,
            // paddingLeft: Metrics.base * 2,
            // paddingRight: Metrics.base * 2
          }
        }
      />*/}
    </MainLayout>
  )
}

export default TripHistory
