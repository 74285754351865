import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'

export const GET_SAFETY_AVERAGES = gql`
  query assetSafetyAverages(
    $orgIdsFilter: [String] #$assetGroupId: String
  ) {
    assetSafetyAverages(
      orgIdsFilter: $orgIdsFilter #assetGroupId: $assetGroupId
    ) {
      average
      averageDiffWeek
      averageDiffMonth
      averageDiffYear
    }
  }
`

const dataTransform = ({ data }) => {
  return data
}

export const useAssetSafetyAverages = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { groupId, orgIds } = useReactiveVar(filters)
  const { data: queryData, loading: queryLoading } = useQuery(
    GET_SAFETY_AVERAGES,
    {
      variables: {
        orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
        assetGroupId: groupId
      },
      queryName: 'assetSafetyAverages',
      errorMessage: 'Failed to fetch assetSafetyAverages',
      dataTransform: (data) => dataTransform({ data })
    }
  )

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
