import React from 'react'
import { Subsection } from '../../../Components'
import ObjectDataDisplay from '../../../Components/DataDisplay/ObjectDataDisplay'

const AlarmForm = ({ object }) => {
  return (
    <Subsection>
      <ObjectDataDisplay
        data={object ? { id: object.id, name: object.name } : {}}
      />
    </Subsection>
  )
}

export default AlarmForm
