import React from 'react'
import FlexBox from '../FlexBox'

const TabRoutesContainer = ({ children, style }) => {
  return (
    <FlexBox
      fillSpace
      direction={'column'}
      style={{ width: '100%', justifyContent: 'flex-start', ...style }}
    >
      {children}
    </FlexBox>
  )
}

export default TabRoutesContainer
