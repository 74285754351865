import React, { useEffect, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import { FlexBox, Text } from '..'

export const googleMapsApiKey = 'AIzaSyCfvt-eUHjCQFO1vcHHCrp2_Fgeiwrkezk'

const updatePanorama = ({ panorama, data, center, heading }) => {
  panorama.setPosition(center)
  panorama.setPov({ heading, pitch: 0 })
}

function processSVData({
  data,
  status,
  panorama,
  center,
  heading,
  setHasStreetview
}) {
  if (status == window.google.maps.StreetViewStatus.OK) {
    setHasStreetview(true)
    updatePanorama({ data, center, heading, panorama })
  } else {
    setHasStreetview(false)
  }
}

const loadPano = ({ center, setSvClient, setPanorama, eventId }) => {
  const sv = new window.google.maps.StreetViewService()
  const pano = new window.google.maps.StreetViewPanorama(
    document.getElementById(`pano_${eventId}`),
    {
      position: center,
      pov: {
        heading: 34,
        pitch: 10
      }
    }
  )
  setSvClient(sv)
  setPanorama(pano)
}

function EventIdStreetView({ eventId }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey
  })
  const [panorama, setPanorama] = useState()
  const [svClient, setSvClient] = useState(null)
  const [hasStreetview, setHasStreetview] = useState()
  const { data: event } = useEvent(eventId)
  const lat = event && event.startLat ? event.startLat : null
  const lng = event && event.startLong ? event.startLong : null

  useEffect(() => {
    if (isLoaded && lat && lng) {
      loadPano({
        center: { lat, lng },
        setSvClient,
        setPanorama,
        eventId
      })
    }
  }, [isLoaded, lat, lng, eventId])

  useEffect(() => {
    if (svClient && panorama && lat && lng) {
      svClient.getPanoramaByLocation({ lat, lng }, 50, (data, status) =>
        processSVData({
          data,
          status,
          panorama,
          center: { lat, lng },
          setHasStreetview
        })
      )
    }
  }, [lat, lng, svClient, panorama])

  return isLoaded ? (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 450
      }}
    >
      <div
        id={`pano_${eventId}`}
        key={`pano_${eventId}`}
        style={{
          width: '100%',
          height: 450
        }}
      ></div>
      <FlexBox
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 500,
          backgroundColor: '#4C525BAA',
          display: lat && lng && hasStreetview ? 'none' : true
        }}
      >
        <FlexBox style={{ width: '100%', height: '100%' }}>
          <Text font='caption' color='white'>
            No street view data for this location.
          </Text>
        </FlexBox>
      </FlexBox>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(EventIdStreetView)
