import {
  GET_SIDE_PANEL_EXPANDED,
  GET_NOTIFICATION_SHOW,
  GET_DARKMODE
} from './Queries/localQueries'

export const resolvers = {
  Mutation: {
    updateNotification: (_, variables, { cache }) => {
      cache.writeQuery({
        query: GET_NOTIFICATION_SHOW,
        data: {
          notification: {
            show: variables.show ? variables.show : false,
            level: variables.level ? variables.level : '',
            text: variables.text ? variables.text : '',
            __typename: 'Notification'
          }
        }
      })
    },
    updateSidePanelExpanded: (_, variables, { cache }) => {
      const sidePanelExpanded = cache.readQuery({
        query: GET_SIDE_PANEL_EXPANDED
      })
      const data = { sidePanelExpanded: !sidePanelExpanded.sidePanelExpanded }
      cache.writeQuery({
        query: GET_SIDE_PANEL_EXPANDED,
        data
      })
      return data
    },
    updateDarkmode: (_, variables, { cache }) => {
      const darkmode = cache.readQuery({
        query: GET_DARKMODE
      })
      const data = { darkmode: !darkmode.darkmode }
      cache.writeQuery({
        query: GET_DARKMODE,
        data
      })
      return data
    }
  }
}
