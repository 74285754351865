import React, { useEffect, useState } from 'react'
import { css } from 'aphrodite'
import FlexBox from '../../../FlexBox'
import { Metrics, useColors, Utils } from '../../../../Themes'
import { Styles } from '../Styles'
import { LinearProgress, withStyles } from '@material-ui/core'
import { Display } from '../../../Typography'

const ClusterCard = ({
  loading,
  titleContent,
  bodyContent,
  topRightContent,
  topRightLoading,
  bottomRightContent,
  bottomRightLoading,
  bottomLeftContent,
  points,
  id,
  selected,
  onClick,
  width,
  borderBottom,
  backgroundColor,
  noBoxShadow,
  cardHeight = 90
}) => {
  const Colors = useColors()
  const shadow = Colors.isDarkmode ? Utils.boxShadowDark : Utils.boxShadowLight
  const styles = Styles({
    Colors,
    Metrics,
    selected,
    width,
    borderBottom,
    backgroundColor,
    noBoxShadow,
    cardHeight,
    shadow
  })
  const [BorderLinearProgress] = useState(
    withStyles(() => ({
      root: {
        borderRadius: 2
      },
      colorPrimary: {
        backgroundColor: Colors.dividerColor
      },
      bar: {
        // borderRadius: 5,
        backgroundColor: Colors.selectHighlightOpacity2
      }
    }))(LinearProgress)
  )
  return (
    <FlexBox
      className={css(styles.card)}
      id={id}
      onClick={onClick ? onClick : () => {}}
    >
      <FlexBox direction='row' className={css(styles.cardInner)}>
        <Display>{points}</Display>
        <FlexBox
          direction='column'
          style={{
            height: '100%',
            flex: 1,
            alignItems: 'flex-start',
            marginLeft: Metrics.base * 2
          }}
        >
          <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
            <FlexBox
              style={{
                marginBottom: Metrics.base
              }}
            >
              {!loading ? (
                titleContent && titleContent
              ) : (
                <BorderLinearProgress
                  style={{
                    height: 12,
                    width: 120,
                    marginBottom: Metrics.base / 2
                  }}
                />
              )}
            </FlexBox>
            {!topRightLoading ? (
              topRightContent && topRightContent
            ) : (
              <BorderLinearProgress style={{ height: 10, width: 40 }} />
            )}
          </FlexBox>
          {!loading ? (
            bodyContent && bodyContent
          ) : (
            <BorderLinearProgress
              style={{ height: 10, width: 90, marginBottom: Metrics.base }}
            />
          )}
          {/*children */}
          <FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
            {!loading ? (
              bottomLeftContent && bottomLeftContent
            ) : (
              <BorderLinearProgress style={{ height: 8, width: 70 }} />
            )}
            <FlexBox>
              {!bottomRightLoading ? (
                bottomRightContent && bottomRightContent
              ) : (
                <BorderLinearProgress style={{ height: 8, width: 70 }} />
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default ClusterCard
