import { StyleSheet } from 'aphrodite'

const animationKeyframes = {
  '0%': {
    transform: 'rotate(0deg)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  }
}

export const inlineStyles = {
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}

export default StyleSheet.create({
  mainContainer: {
    borderWidth: 3,
    borderStyle: 'solid',
    borderRadius: '50%',
    animationName: animationKeyframes,
    animationDuration: '1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease'
  }
})
