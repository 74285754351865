import React from 'react'
import { Images, Metrics, useColors } from '../../Themes'
import {
  DataDisplay,
  FlexBox,
  InfoBadge,
  Status,
  Subsection,
  Text
} from '../../Components'
import { Display, Heading, Title } from '../../Components/Typography'
import { ModifiedPieChart } from '../../Components/Custom/ModifiedPieChart'
import LargeDivider from '../../Components/LargeDivider'
import { checkVal, hhmmss, formatDateTime } from '../../Helpers/Functions'
import { useEffect } from 'react'
import AreaChart from '../../Components/D3Charts/AreaChart'
import { useState } from 'react'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import EventHeader from '../../Components/Custom/EventHeader'
import { selectedTripTotalEventId } from '../../Graphql/GraphQLClient'

export const TripTotalsEventInfo = ({ event }) => {
  const Colors = useColors()
  return (
    <>
      <FlexBox
        style={{
          width: '100%',
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        {/*<FlexBox style={{ width: '100%', justifyContent: 'space-between' }}>
          <FlexBox>
            {event && event.type && Images[event.type] && (
              <img
                width={35}
                style={{
                  marginLeft: Metrics.base,
                  marginRight: Metrics.base * 2
                }}
                alt={`${event.type}_icon`}
                src={Images[event.type]}
              ></img>
            )}
            <Heading>{event.name}</Heading>
          </FlexBox>
          <Status
            status={event.severityCategoryDisplayText}
            getStatusColors={() => Colors[event.category]}
          ></Status>
              </FlexBox>*/}
        <EventHeader
          eventId={event && event._id}
          handleClose={() => selectedTripTotalEventId(null)}
        />
      </FlexBox>
      <FlexBox
        style={{
          justifyContent: 'flex-start',
          paddingLeft: Metrics.base * 2,
          paddingTop: Metrics.base * 2,
          paddingBottom: Metrics.base * 2,
          width: '100%'
          // backgroundColor: 'red'
        }}
      >
        <div
          style={{
            height: 140,
            width: 140,
            position: 'relative',
            marginBottom: Metrics.base * 2,
            marginLeft: Metrics.base * 2
          }}
        >
          <ModifiedPieChart
            color={Colors[event.category]}
            hideLegend
            hideLabels
            padAngle={0}
            innerRadius={0.9}
            startAngle={-180}
            endAngle={180}
            enableArcLinkLabels={false}
            enableSlicesLabels={false}
            enableArcLabels={false}
            isInteractive
            data={[
              {
                id: 'volumeDelivered',
                label: 'label1',
                value: event.normScore
              },
              {
                id: 'volumeRemaining',
                label: 'label2',
                value: 10 - event.normScore
              }
            ]}
          >
            hi
          </ModifiedPieChart>
        </div>
        <FlexBox
          direction='column'
          style={{ padding: Metrics.base * 2, paddingLeft: Metrics.base * 4 }}
        >
          <Display>
            {checkVal(event.normScore) && `${event.normScore.toFixed(2)}/10`}
          </Display>
          <Text marginTop={1}>Severity score</Text>
        </FlexBox>
        <FlexBox
          direction='column'
          fillSpace
          style={{
            marginLeft: Metrics.base * 4,
            // paddingLeft: Metrics.base * 4,
            // alignItems: 'flex-start',
            borderLeft: `1px solid ${Colors.dividerColor}`
          }}
        >
          {event.type === 'TRIP_DISTANCE' ? (
            <InfoBadge
              size='medium'
              value={
                event.totalDistance > 0 ? event.totalDistance / 1000 : null
              }
              suffix='km'
              label={'Total Distance'}
            />
          ) : event.type === 'TRAVEL_TIME' ? (
            <InfoBadge
              size='medium'
              value={event.totalTravelTime}
              getDisplayVal={(value) => hhmmss(value)}
              label={'Total Travel Time'}
            />
          ) : event.type === 'DRIVING_TIME' ? (
            <InfoBadge
              size='medium'
              value={event.totalTomTomTravelTime}
              getDisplayVal={(value) => hhmmss(value)}
              label={'Total Driving Time'}
            />
          ) : event.type === 'PERCENT_SPEEDING' ? (
            <InfoBadge
              size='medium'
              value={event.rawScore}
              suffix='%'
              label={'Percent Speeding'}
            />
          ) : event.type === 'CONTINUOUS_DRIVING' ? (
            <InfoBadge
              size='medium'
              value={event.rawScore * 60 * 60}
              getDisplayVal={(value) => hhmmss(value)}
              label={'Continuous Driving Time'}
            />
          ) : event.type === 'NIGHT_DRIVING' ? (
            <InfoBadge
              size='medium'
              value={event.nightDrivingHours * 60 * 60}
              getDisplayVal={(value) => hhmmss(value)}
              label={'Night Driving Time'}
            />
          ) : (
            ''
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox
        direction='column'
        style={{
          width: '100%',
          borderTop: `1px solid ${Colors.dividerColor}`
        }}
      >
        <DataDisplay
          firstItem
          lastItem
          rowStyle={{ borderBottom: 'none' }}
          style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
          data={
            event && event.violationTimestamp
              ? formatDateTime(new Date(event.violationTimestamp))
              : event &&
                event.trip &&
                formatDateTime(new Date(event.trip.startTime))
          }
          label='Date'
          textColor={Colors.secondaryOpacity1}
        />
        {event && event.violationLat && event.violationLong && (
          <DataDisplay
            firstItem
            lastItem
            rowStyle={{ borderBottom: 'none' }}
            style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
            data={`Lat: ${event.violationLat}, Lng: ${event.violationLong}`}
            label='Violation Location'
            textColor={Colors.secondaryOpacity1}
          />
        )}
        {event && checkVal(event.dayDrivingHours) && (
          <DataDisplay
            firstItem
            lastItem
            style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
            data={hhmmss(event.dayDrivingHours * 60 * 60)}
            label='Day Driving Time'
            textColor={Colors.secondaryOpacity1}
          />
        )}
        {event && checkVal(event.percentDayDriving) && (
          <DataDisplay
            firstItem
            lastItem
            style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
            data={`${Math.round(event.percentDayDriving)}%`}
            label='Day Driving Percentage'
            textColor={Colors.secondaryOpacity1}
          />
        )}
        {event && checkVal(event.percentNightDriving) && (
          <DataDisplay
            firstItem
            lastItem
            rowStyle={{ borderBottom: 'none' }}
            style={{ borderRight: `1px solid ${Colors.dividerColor}` }}
            data={`${Math.round(event.percentNightDriving)}%`}
            label='Night Driving Percentage'
            textColor={Colors.secondaryOpacity1}
          />
        )}
      </FlexBox>
    </>
  )
}

const getSpeedChartData = (values) => {
  const data = []
  for (var i = 0; i < values[0].length; i++) {
    checkVal(values[0][i]) &&
      checkVal(values[1][i]) &&
      data.push({
        date: new Date(parseInt(values[1][i])),
        value: parseInt(values[0][i])
      })
  }
  return data.sort((a, b) => a.date.getTime() - b.date.getTime())
}

const EventDetailsTripTotals = ({ eventId }) => {
  const [speedChartData, setSpeedChartData] = useState()
  const { data: event } = useEvent(eventId)
  useEffect(() => {
    if (event && event.values && event.values.length === 2) {
      setSpeedChartData(getSpeedChartData(event.values))
    }
  }, [event])
  const { mainContainerWidth } = useAppDimensions()
  return (
    checkVal(eventId) &&
    event && (
      <Subsection
        style={{
          width: '100%',
          paddingBottom: Metrics.base,
          overflow: 'hidden'
        }}
      >
        <TripTotalsEventInfo event={event} />
      </Subsection>
    )
  )
}

export default EventDetailsTripTotals
