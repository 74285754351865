import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Text } from '../../Components'
// import { useQuery, useMutation } from '@apollo/client'
// import { GET_DARKMODE } from '../../../Graphql/Queries'
import { abbreviateNumber } from '../../Helpers/Functions'
import { Metrics, useColors, Utils } from '../../Themes'

const BarChart = ({
  data,
  keys,
  indexBy = 'name',
  colors,
  onClick,
  xAxisTitle,
  yAxisTitle
}) => {
  const Colors = useColors()
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      onClick={onClick}
      indexBy={indexBy}
      margin={{ top: 50, right: 16, bottom: 150, left: 24 }}
      padding={0.3}
      colors={
        colors
          ? colors
          : [
              Colors.SPEEDING,
              Colors.HARSH_BRAKING,
              Colors.HARSH_ACCELERATION,
              Colors.HARSH_CORNERING,

              Colors.PERCENT_SPEEDING,
              Colors.TRIP_DISTANCE,
              Colors.TRAVEL_TIME,
              Colors.DRIVING_TIME
            ]
      }
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: Colors.secondary
            },
            text: {
              fill: Colors.secondary
            }
          },
          legend: {
            text: {
              fill: Colors.secondary
            }
          }
        },
        grid: {
          line: {
            stroke: Colors.dividerColor
          }
        }
      }}
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickRotation: -45,
        tickPadding: 5
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: abbreviateNumber
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor='white'
      labelFormat={abbreviateNumber}
      animate={true}
      tooltip={({ id, value, index, indexValue, color, data }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: Colors.subsectionBackground,
              padding: Metrics.base * 2,
              boxShadow: Utils.boxShadowLight
            }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                marginRight: 8,
                backgroundColor: color
              }}
            ></div>
            <Text color={Colors.secondary}>
              <b>
                {id}, {indexValue}
              </b>
              , <br />
              value: {value}
            </Text>
          </div>
        )
      }}
      motionStiffness={90}
      motionDamping={15}
    />
  )
}

export default BarChart
