import React, { useEffect } from 'react'
import FlexBox from '../../FlexBox'
import { Images, Metrics, useColors } from '../../../Themes'
import Text from '../../Text'
import { Title } from '../../Typography'
import Status from '../../Status'
import Card, { TooltipMenuItem } from './Cards/Card'
import { selectedIds } from '../../../Graphql/GraphQLClient'
import { hhmmss, formatDateTime } from '../../../Helpers/Functions'
import { useCrash } from '../../../Graphql/Queries/useCrash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Link } from '@material-ui/core'

const CrashCard = ({
  crashId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom
}) => {
  const Colors = useColors()
  const { data: crash, loading: crashLoading } = useCrash(crashId)
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const urlSearchParams = new URLSearchParams(location.search)

  return (
    <Card
      data={crash}
      selected={selected}
      onClick={() => [
        selectedIds({
          ...selectedIds(),
          crash: selected ? null : crash.data._id
        })
      ]}
      TooltipContent={({ setShowTooltip }) => (
        <FlexBox direction='column' style={{ width: 200 }}>
          <TooltipMenuItem
            text='View in detail'
            Colors={Colors}
            onClick={() => {
              urlSearchParams.set('id', crash.data._id)
              history.push({
                pathname: '/view-crash',
                search: urlSearchParams.toString()
              })
            }}
            setShowTooltip={setShowTooltip}
          />
        </FlexBox>
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={crashLoading}
      titleContent={
        <>
          {/* {crash.data.type && (
            <img
              width={25}
              style={{ marginRight: Metrics.base }}
              alt={`${crash.data.type}_icon`}
              src={Images[crash.data.type]}
            />
          )} */}
          <Title style={{ marginLeft: Metrics.base }} color={Colors.primary}>
            {crash.data &&
              crash.data.vehicleCrashDate &&
              formatDateTime(new Date(crash.data.vehicleCrashDate))}
          </Title>
        </>
      }
      bodyContent={
        <Text
          marginBottom={1}
          marginTop={1 / 2}
          style={{ paddingRight: Metrics.base }}
        >
          {crash.data && crash.data.description
            ? 'Description: ' + crash.data.description
            : 'No description given'}{' '}
        </Text>
      }
      topRightContent={
        <Status
          status={
            crash.data && crash.data.latitude
              ? 'Location: ' + crash.data.latitude + ',' + crash.data.longitude
              : 'Location: -'
          }
          getStatusColors={() => 'green'}
        ></Status>
      }
      topRightLoading={crashLoading}
      //   bottomLeftContent={
      //     crash.data &&
      //     crash.data.crashDocument &&
      //     crash.data.crashDocument.url ? (
      //       <Link
      //         underline='always'
      //         color={Colors.primary}
      //         style={{
      //           color: Colors.primary,
      //           marginBottom: Metrics.base * 2,
      //           marginRight: Metrics.base / 2,
      //           marginLeft: Metrics.base / 2
      //         }}
      //         target='_blank'
      //         href={crash.data.crashDocument.url}
      //       >
      //         <Text color={Colors.primary}>
      //           {crash.data.crashDocument.filename}
      //         </Text>
      //       </Link>
      //     ) : (
      //       ''
      //     )
      //   }
    />
  )
}

export default CrashCard
