import React from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { removeTrailingSlashes } from '../../Helpers/Functions'
import { Metrics, useColors } from '../../Themes'
import FlexBox from '../FlexBox'
import TabButton from './TabButton'

export const getSelectedId = ({ ids, tabsData, defaultId }) => {
  for (var i = 0; i < ids.length; i++) {
    for (var j = 0; j < tabsData.length; j++) {
      if (ids[i].includes(tabsData[j].id)) return tabsData[j].id
    }
  }
  if (defaultId) return defaultId
  return tabsData[0].id
}

const TabRoutingBar = ({ style, tabsData = [], defaultId, onChange }) => {
  const Colors = useColors()
  const { url } = useRouteMatch()
  const { pathname } = useLocation()
  const ids = pathname.split('/')
  const selectedId = getSelectedId({ ids, tabsData, defaultId })
  return (
    <div
      style={{
        width: '100%',
        height: Metrics.tabBarHeight,
        ...style
      }}
    >
      <FlexBox
        style={{
          height: Metrics.tabBarHeight,
          justifyContent: 'flex-start',
          paddingLeft: Metrics.base,
          paddingRight: Metrics.base,
          backgroundColor: Colors.subsectionBackground,
          borderBottom: `1px solid ${Colors.dividerColor}`
        }}
      >
        {tabsData.map(
          (item) =>
            !item.hidden && (
              <TabButton
                id={item.id}
                selected={selectedId === item.id}
                name={item.name}
                onChange={onChange}
                linkToUrl={`${removeTrailingSlashes(url)}/${item.id}`}
                Colors={Colors}
              />
            )
        )}
      </FlexBox>
    </div>
  )
}

export default TabRoutingBar
