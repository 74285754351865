import React, { useEffect } from 'react'
import { Footer } from '../'
import { Header, SidePanel } from '../'
import { Styles } from './Styles'
import { Metrics, Colors } from '../../Themes'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_SIDE_PANEL_EXPANDED,
  GET_DARKMODE
} from '../../Graphql/Queries/localQueries'
import SelectionPanel from '../Custom/SelectionPanel'
import { appDimensions } from '../../Graphql/GraphQLClient'
import FlexBox from '../FlexBox'
import { debounce } from '../../Helpers/Functions'

const handleResize = debounce((e) => {
  appDimensions({ width: e.target.innerWidth, height: e.target.innerHeight })
}, 100)

const MainLayout = ({
  name,
  publicLayout = false,
  hideSelectionPanel,
  style,
  userType,
  children
}) => {
  const { data } = useQuery(GET_SIDE_PANEL_EXPANDED)
  const sidePanelExpanded = data ? data.sidePanelExpanded : true

  const { data: darkmodeData } = useQuery(GET_DARKMODE)
  const darkmode = darkmodeData ? darkmodeData.darkmode : true

  const selectionPanelExpanded = true

  useEffect(() => {
    window.addEventListener('resize', (e) => handleResize(e))
    return () => {
      window.removeEventListener('resize', (e) => handleResize(e))
    }
  }, [])

  /*
  const userId = localStorage.getItem('userId')
  const { data: userData } = useQuery(GET_USER, {
    variables: { id: userId }
  })
  const user = userData ? userData.user : null
  const userName = user ? user.userName.split(' ')[0] : null
  */
  name && console.log(name)

  return (
    <FlexBox
      fillSpace
      style={{
        ...Styles.mainContainer,
        paddingTop: 0, // publicLayout ? 0 : Metrics.navBarHeight,
        backgroundColor: darkmode
          ? Colors.darkTheme.background
          : Colors.lightTheme.background,
        ...style
      }}
    >
      {/*!publicLayout && <Header userType={userType} darkmode={darkmode} />*/}
      <FlexBox
        fillSpace
        direction='row'
        style={{
          width: '100%',
          position: 'relative',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}
      >
        {!publicLayout && (
          <>
            <div
              style={{
                width: sidePanelExpanded
                  ? Metrics.sidePanelExpandedWidth
                  : Metrics.sidePanelWidth,
                padding: 0,
                position: 'relative',
                zIndex: 2
              }}
            >
              <SidePanel sidePanelExpanded={sidePanelExpanded} />
            </div>
            {!hideSelectionPanel && (
              <div
                style={{
                  width: selectionPanelExpanded
                    ? Metrics.base * 40
                    : Metrics.base * 8,
                  padding: 0,
                  position: 'relative',
                  zIndex: 1
                }}
              >
                <SelectionPanel expanded={selectionPanelExpanded} />
              </div>
            )}
          </>
        )}
        <FlexBox fillSpace>
          <FlexBox fillSpace style={Styles.pageContainer}>
            {children}
            <div
              style={{
                ...Styles.footerContainer
              }}
            >
              <Footer darkmode={darkmode} />
            </div>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default React.memo(MainLayout)
