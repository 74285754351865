import React, { useEffect } from 'react'
import { Metrics, useColors } from '../../../Themes'
import { DataDisplay, FlexBox, InfoBadge } from '../../../Components'
import { useTrip } from '../../../Graphql/Queries/Trip/useTrip'
import { useEventsByTripId } from '../../../Graphql/Queries/Event/useEventsByTripId'
import {
  checkVal,
  hhmmss,
  thousandsSeparator
} from '../../../Helpers/Functions'
import DriverCard from '../../../Components/Custom/SelectionPanel/Driver/DriverCard'
import AssetCard from '../../../Components/Custom/SelectionPanel/Asset/AssetCard'
import {
  selectedAssetId,
  selectedDriverId,
  selectedTripId
} from '../../../Graphql/GraphQLClient'
import { useNavigation } from '../../../Components/CustomHooks/useNavigation'
import { useReactiveVar } from '@apollo/client'
import { InsetCardContainer } from '../../../Components/Custom/SelectionPanel/Cards/CardsContainer'

const TripDetails = ({ hideMetadata }) => {
  const Colors = useColors()
  const tripId = useReactiveVar(selectedTripId)
  const { data: trip } = useTrip(tripId)
  const { tripDistance, travelTime, drivingTime } = useEventsByTripId(tripId)
  const navigateTo = useNavigation()

  return (
    <FlexBox
      direction='column'
      style={{ width: '100%', justifyContent: 'flex-start' }}
    >
      {trip && trip.tripPoints && (
        <>
          <FlexBox
            direction='column'
            style={{
              // alignItems: 'flex-start',
              width: '100%',
              padding: Metrics.base * 2,
              paddingTop: Metrics.base
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                borderBottom: `1px solid ${Colors.dividerColor}`,
                marginBottom: Metrics.base * 2,
                paddingBottom: Metrics.base * 2
              }}
            >
              {tripDistance ? (
                <InfoBadge
                  size='medium'
                  value={`${thousandsSeparator(
                    tripDistance.totalDistance / 1000
                  )} km`}
                  label={tripDistance.name}
                  status={tripDistance.severityCategoryDisplayText}
                  statusColor={Colors[tripDistance.severityCategory]}
                />
              ) : checkVal(trip.totalDistance) ? (
                <InfoBadge
                  size='medium'
                  value={`${thousandsSeparator(trip.totalDistance / 1000)} km`}
                  label={'Total Distance'}
                />
              ) : (
                ''
              )}
            </FlexBox>
            <FlexBox
              direction='row'
              style={{
                width: '100%',
                borderBottom: `1px solid ${Colors.dividerColor}`,
                paddingBottom: Metrics.base * 2,
                marginBottom: Metrics.base * 2
              }}
            >
              {travelTime ? (
                <InfoBadge
                  size='small'
                  value={hhmmss(travelTime.totalTravelTime)}
                  label={travelTime.name}
                  status={travelTime.severityCategoryDisplayText}
                  statusColor={Colors[travelTime.severityCategory]}
                />
              ) : checkVal(trip.totalTravelTime) ? (
                <InfoBadge
                  size='small'
                  value={hhmmss(trip.totalTravelTime)}
                  label={'Total Travel Time'}
                />
              ) : (
                ''
              )}
              {drivingTime ? (
                <InfoBadge
                  size='small'
                  value={hhmmss(drivingTime.totalTomTomTravelTime)}
                  label={'Estimated Driving Time'}
                  status={drivingTime.severityCategoryDisplayText}
                  statusColor={Colors[drivingTime.severityCategory]}
                />
              ) : trip.totalTomTomTravelTime ? (
                <InfoBadge
                  size='small'
                  value={hhmmss(trip.totalTomTomTravelTime)}
                  label={'Estimated Driving Time'}
                />
              ) : (
                ''
              )}
            </FlexBox>
            {trip.averageSpeed && (
              <InfoBadge
                size='small'
                value={trip.averageSpeed.toFixed(2) + ' km/h'}
                label={'Average Speed'}
              />
            )}
          </FlexBox>
          <FlexBox
            direction='column'
            style={{
              width: '100%',
              paddingTop: Metrics.base * 2,
              paddingLeft: Metrics.base,
              paddingRight: Metrics.base
            }}
          >
            <InsetCardContainer
              name='Linked Driver'
              style={{
                marginBottom: Metrics.base * 2
              }}
            >
              <DriverCard
                driverId={trip.driverId}
                navigateTo={navigateTo}
                onClick={() => [
                  selectedDriverId(trip.driverId),
                  navigateTo({
                    pathname: '/fleet-management/drivers',
                    queryParams: [{ name: 'driver', id: trip.driverId }]
                  })
                ]}
                backgroundColor={Colors.background}
              />
            </InsetCardContainer>
            <InsetCardContainer name='Linked Asset'>
              <AssetCard
                assetId={trip.assetId}
                navigateTo={navigateTo}
                onClick={() => [
                  selectedAssetId(trip.assetId),
                  navigateTo({
                    pathname: '/fleet-management/assets',
                    queryParams: [{ name: 'asset', id: trip.assetId }]
                  })
                ]}
                backgroundColor={Colors.background}
              />
            </InsetCardContainer>
          </FlexBox>
          {!hideMetadata && (
            <FlexBox
              direction='column'
              style={{
                alignItems: 'flex-start',
                width: '100%',
                paddingTop: Metrics.base * 2
              }}
            >
              {/* 
              <DataDisplay
                // rowStyle={{ height: Metrics.base * 4 }}
                data={trip.startTimeDisplay ? trip.startTimeDisplay : '-'}
                label='Start time'
              />
              <DataDisplay
                // rowStyle={{ height: Metrics.base * 4 }}
                data={trip.endTimeDisplay ? trip.endTimeDisplay : '-'}
                label='End time'
              />*/}
              {/*<DataDisplay
                rowStyle={{ height: Metrics.base * 4 }}
                data={trip.safetyScore ? trip.safetyScore : '-'}
                label='Safety score'
              />*/}
              {/*<DataDisplay
                rowStyle={{ height: Metrics.base * 4 }}
                data={
                  trip.averageSpeed
                    ? trip.averageSpeed.toFixed(2) + ' km/h'
                    : '-'
                }
                label='Average Speed'
              />*/}
            </FlexBox>
          )}
        </>
      )}
    </FlexBox>
  )
}

export default TripDetails
