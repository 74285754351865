import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../GraphQLClient'
import { checkVal } from '../../../Helpers/Functions'
import { getScoreCategory } from '../../../Containers/Safety/Asset/Breakdown'

export const topBottomAssetsFragment = gql`
  fragment topBottomAssetsFragment on TopBottomAssets {
    _id
    assetId
    displayId
    safetyScore
    name
    vehicleMake
    registrationNum
    historyCategoryNormScore100
    legalCategoryNormScore100
    maintenanceCategoryNormScore100
  }
`

export const GET_DRIVERS = gql`
  query topAndBottomAssetSafeties(
    $sortByOrder: String
    $assetSafetyCategory: String
    $orgIdsFilter: [String]
    $skip: Int
    $limit: Int
  ) {
    topAndBottomAssetSafeties(
      sortByOrder: $sortByOrder
      assetSafetyCategory: $assetSafetyCategory
      orgIdsFilter: $orgIdsFilter
      skip: $skip
      limit: $limit
    ) {
      count
      assets {
        ...topBottomAssetsFragment
      }
    }
  }
  ${topBottomAssetsFragment}
`

export const assetDataTransform = ({ data }) => {
  return {
    ...data,
    safetyScore: checkVal(data.safetyScore)
      ? Number(data.safetyScore).toFixed(2)
      : 0,
    assetSafetyCategory: getScoreCategory(data.safetyScore)
  }
}

const dataTransform = ({ data }) => {
  return {
    count: data.count,
    data: data.assets && data.assets.map((a) => assetDataTransform({ data: a }))
  }
}

export const getVariables = ({
  groupId,
  orgIdsFilter,
  orderBy,
  safetyCategory,
  skip = 0,
  limit = 10
}) => {
  let variables = {
    skip,
    limit
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(orderBy)) {
    variables = { ...variables, sortByOrder: orderBy }
  }
  if (checkVal(safetyCategory)) {
    variables = { ...variables, assetSafetyCategory: safetyCategory }
  }
  return variables
}

export const useTopAndBottomAssets = ({ orderBy, safetyCategory }) => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DRIVERS, {
    variables: getVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      orderBy,
      safetyCategory,
      groupId
    }),
    queryName: 'topAndBottomAssetSafeties',
    errorMessage: 'Failed to fetch Assets',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.count)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
