import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { useFilters } from '../useFilters'
import { checkVal } from '../../../Helpers/Functions'
import { driverFilters, filters } from '../../GraphQLClient'
import { useReactiveVar } from '@apollo/client'

export const GET_DRIVERS = gql`
  query drivers(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $searchString: String
    $safetyCategory: [String]
    $skip: Int
    $limit: Int
  ) {
    drivers(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      searchString: $searchString
      safetyCategory: $safetyCategory
      skip: $skip
      limit: $limit
    ) {
      totalCount
      drivers {
        _id
        displayId
        asset {
          _id
          device {
            _id
          }
        }
      }
    }
  }
`

const dataTransform = ({ data }) => {
  const deviceIds = []
  const assetIds = []
  const driverIds = []
  const queryData =
    data &&
    data.drivers &&
    data.drivers.map((a) => {
      if (a.asset && a.asset.device && a.asset.device._id) {
        deviceIds.push(a.asset.device._id)
        assetIds.push(a.asset._id)
        driverIds.push(a._id)
      }
      return a
    })
  return {
    totalCount: data.totalCount,
    data: queryData,
    liveViewIds: {
      deviceIds,
      assetIds,
      driverIds
    }
  }
}

export const getDriversVariables = ({
  groupId,
  orgIdsFilter,
  safetyCategory,
  searchString,
  skip = 0,
  limit = 10
}) => {
  let variables = {
    skip,
    limit
  }
  if (checkVal(orgIdsFilter)) {
    variables = { ...variables, orgIdsFilter }
  }
  if (checkVal(groupId)) {
    variables = { ...variables, assetGroupId: groupId }
  }
  if (checkVal(safetyCategory)) {
    variables = { ...variables, safetyCategory }
  }
  if (checkVal(searchString)) {
    variables = { ...variables, searchString }
  }
  return variables
}

export const useDriverIds = () => {
  const { orgIds, groupId } = useReactiveVar(filters)
  const { searchString, safetyCategories } = useReactiveVar(driverFilters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [liveViewIds, setLiveViewIds] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_DRIVERS, {
    variables: getDriversVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId,
      searchString,
      safetyCategory: safetyCategories
    }),
    queryName: 'drivers',
    errorMessage: 'Failed to fetch Driver Ids',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
    queryData && setLiveViewIds(queryData.liveViewIds)
  }, [queryData])

  return { data, loading, totalCount, liveViewIds, fetchMore, networkStatus }
}
