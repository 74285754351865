import React from 'react'
import { Images, Metrics, useColors } from '../../../../../Themes'
import Text from '../../../../Text'
import { Title } from '../../../../Typography'
import Status from '../../../../Status'
import Card from '../../Cards/Card'
import { checkVal, hhmmss } from '../../../../../Helpers/Functions'
import { useEvent } from '../../../../../Graphql/Queries/Event/useEvent'
import EventCardTTList from '../EventCardTTList'
import { selectedEventId } from '../../../../../Graphql/GraphQLClient'
import { FlexBox, Icon } from '../../../..'

const EventCardInTrip = ({
  eventId,
  selected,
  width,
  noBoxShadow,
  backgroundColor,
  borderBottom,
  navigateTo,
  onClick
}) => {
  const Colors = useColors()
  const { data, loading: eventLoading } = useEvent(eventId)

  return (
    <Card
      id={eventId}
      data={data}
      selected={selected}
      typeName={'event'}
      setSelectedId={selectedEventId}
      navigateTo={navigateTo}
      onClick={onClick}
      TooltipContent={({ setShowTooltip }) => (
        <EventCardTTList
          eventId={eventId}
          data={data}
          navigateTo={navigateTo}
          setShowTooltip={setShowTooltip}
        />
      )}
      width={width}
      borderBottom={borderBottom}
      backgroundColor={backgroundColor}
      noBoxShadow={noBoxShadow}
      loading={eventLoading}
      titleContent={
        <>
          {data.type && Images[data.type] && (
            <img
              width={18}
              style={{ marginRight: Metrics.base }}
              alt={`${data.type}_icon`}
              src={Images[data.type]}
            />
          )}
          <Title
            // style={{ marginLeft: Metrics.base / 2 }}
            color={Colors.primary}
          >
            {data.name}
          </Title>
        </>
      }
      bodyContent={
        <Text marginBottom={1} marginTop={1 / 2}>
          {data && data.startTime}{' '}
        </Text>
      }
      topRightContent={
        <FlexBox>
          <Status
            status={data.severityCategoryDisplayText}
            getStatusColors={() => Colors[data.severityCategory]}
          ></Status>
        </FlexBox>
      }
      topRightLoading={eventLoading}
      bottomLeftContent={
        <Text font='caption' color={Colors.secondaryOpacity3}>
          {data.durationSeconds
            ? `Duration: ${hhmmss(data.durationSeconds)}`
            : `Event ${data.displayId}`}
        </Text>
      }
      bottomRightContent={
        checkVal(data.normScore) && (
          <FlexBox>
            <img
              width={13}
              style={{ marginRight: Metrics.base / 2, opacity: '0.8' }}
              alt={'shield'}
              src={Images.shieldIcon}
            />
            <Text>{Math.round(data.normScore * 100) / 100}/10</Text>
          </FlexBox>
        )
      }
    />
  )
}

export default EventCardInTrip
