import React, { useEffect, useState } from 'react'
import { FlexBox, Form } from '../../../'
import { Metrics, useColors } from '../../../../Themes'
import { driverFilters, filters } from '../../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { SelectScoreBetween } from '../Event/EventFilters'
import SearchBar from '../SearchBar'
import { useUser } from '../../../../Graphql/Queries/useUser'
import { useAssetGroups } from '../../../../Graphql/Queries/Asset/useAssetGroups'

export const safetyCategoriesOptions = [
  { value: 'EXCELLENT', label: 'Excellent' },
  { value: 'SAFE', label: 'Safe' },
  { value: 'ADEQUATE', label: 'Adequate' },
  { value: 'MODERATE_RISK', label: 'Moderate Risk' },
  { value: 'HIGH_RISK', label: 'High Risk' }
]

export const handleClearFilters = () => {
  driverFilters({
    ...driverFilters(),
    searchString: null,
    minSafetyScore: null,
    maxSafetyScore: null,
    safetyCategories: null
  })
}

const DriverFilters = ({
  direction,
  innerFormStyles,
  minWidth,
  maxInputWidth,
  singleOrgSelect,
  searchInputRef
}) => {
  const { searchString, minSafetyScore, maxSafetyScore, safetyCategories } =
    useReactiveVar(driverFilters)
  const { orgId, orgIds, groupId } = useReactiveVar(filters)
  const { data: user, loading: userLoading } = useUser()
  const orgOptions = user && user.organizations ? user.organizations : []
  const { data: assetGroups, loading } = useAssetGroups()
  const [groupOptions, setGroupOptions] = useState([])

  useEffect(() => {
    if (assetGroups && assetGroups.length > 0) {
      setGroupOptions([...assetGroups, { name: 'Ungrouped', _id: 'NO_GROUP' }])
    }
  }, [assetGroups])

  const innerFormStyle = {
    minHeight: 0,
    flexGrow: 1,
    paddingLeft: Metrics.base * 3,
    paddingRight: Metrics.base * 3,
    ...innerFormStyles
  }

  return (
    <FlexBox
      style={{
        width: '100%',
        minWidth: minWidth ? minWidth : 'auto'
      }}
    >
      <Form
        hideSubmit
        formbox={false}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'none',
          boxShadow: 'none',
          width: '100%'
        }}
        maxInputWidth={maxInputWidth}
        innerFormStyles={{ paddingBottom: 0 }}
        hideFormBox
        darkmode={true}
        direction={direction ? direction : 'column'}
        data={[
          {
            name: 'searchDrivers',
            renderInput: (props) => {
              return (
                <SearchBar
                  title='Drivers'
                  inputString={searchString}
                  setInputString={(value) => {
                    driverFilters({ ...driverFilters(), searchString: value })
                  }}
                  searchInputRef={searchInputRef}
                />
              )
            }
          },
          {
            name: 'orgId',
            value: orgId ? orgId : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgId: [...value] })
            },
            label: 'Organisation',
            type: 'select',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'orgIds',
            value: orgIds ? orgIds : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), orgIds: [...value] })
            },
            label: 'Organisation',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: orgOptions
              ? orgOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect && user && user.userType === 'ADMIN'
          },
          {
            name: 'groupIds',
            value: groupId ? groupId : [],
            controlled: true,
            handleChange: (value) => {
              filters({ ...filters(), groupId: value })
            },
            label: 'Asset Group',
            type: 'select',
            style: innerFormStyle,
            options: groupOptions
              ? groupOptions.map((option) => ({
                  label: option.name,
                  value: option._id
                }))
              : [],
            display: () => !singleOrgSelect
          },
          {
            name: 'safetyCategories',
            value: safetyCategories ? safetyCategories : [],
            controlled: true,
            handleChange: (value) => {
              driverFilters({ ...driverFilters(), safetyCategories: value })
            },
            label: 'Safety Categories',
            type: 'multipleSelect',
            style: innerFormStyle,
            options: safetyCategoriesOptions
          }
          /*{
            name: 'scoreRange',
            label: 'Score Range',
            style: innerFormStyle,
            value: {
              minScore: minSafetyScore,
              maxScore: maxSafetyScore
            },
            controlled: true,
            handleChange: (value) => {
              driverFilters({
                ...driverFilters(),
                minSafetyScore: value.minScore,
                maxSafetyScore: value.maxScore
              })
            },
            renderInput: (props) => {
              return (
                <SelectScoreBetween
                  value={props.value}
                  handleChange={props.handleChange}
                  Colors={Colors}
                />
              )
            }
          }*/
        ]}
      />
    </FlexBox>
  )
}
export default DriverFilters
