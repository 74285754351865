import React, { useState, useEffect } from 'react'
import { useOrganizations } from '../../Graphql/Queries/useOrganizations'
import {
  useCreateDriver,
  CREATE_DRIVER
} from '../../Graphql/Mutations/useCreateDriver'
import { UPDATE_DRIVER } from '../../Graphql/Mutations/useUpdateDriver'
import { Button, FlexBox, ModalAlert, Form, Subsection, FormGraphQL } from '../'

const handleSubmit = ({ data, setSubmitting, mutate, onSuccess, onError }) => {
  if (data) {
    if (!data.driverRestriction) {
      data.driverRestriction = null
    }
    if (!data.vehicleRestriction) {
      data.vehicleRestriction = null
    }
    if (!data.professionalDrivingPermit) {
      data.professionalDrivingPermit = null
    }
  }

  setSubmitting(true)
  mutate({
    variables: data,
    onComplete: (data) => {
      setSubmitting(false)
      onSuccess()
    },
    onError: (error) => {
      setSubmitting(false)
      onError()
    }
  })
  onSuccess()
}

const AddDriverModal = ({
  setShowAddDriverModal,
  object,
  showAddDriverModal
}) => {
  const { data: driverOrganizations } = useOrganizations()
  const [organizations, setOrganizations] = useState([])
  const [driverOrg, setDriverOrg] = useState(null)

  useEffect(() => {
    if (driverOrganizations && driverOrganizations.length > 0) {
      let orgs = []
      driverOrganizations.forEach((organization) => {
        const values = { value: organization._id, label: organization.name }
        orgs = [...orgs, values]
        if (object) {
          if (object.orgId === organization._id) {
            setDriverOrg({ value: organization._id, label: organization.name })
          }
        }
      })
      setOrganizations(orgs)
    }
  }, [driverOrganizations])

  const drivingPermitValues = [
    { label: 'G', value: 'GOODS' },
    { label: 'P', value: 'PASSENGER' },
    { label: 'D', value: 'DANGEROUS_GOODS' }
  ]

  const driverRestrictionsValues = [
    { label: 'None', value: 0 },
    { label: 'Glasses/Contact Lenses', value: 1 },
    { label: 'Artificial Limb', value: 2 }
  ]

  const vehicleRestrictionValues = [
    { label: 'None', value: 0 },
    { label: 'Automatic Transmission', value: 1 },
    { label: 'Electrically Powered', value: 2 },
    { label: 'Physically Disabled', value: 3 },
    {
      label: 'Bus Above 16000 kg GVM (on EC1 licence)',
      value: 4
    },
    { label: 'Tractor only (on B licence)', value: 5 },
    {
      label: 'Mobile equipment only (on B licence)',
      value: 7
    }
  ]

  const licenceCodeValues = [
    { label: 'A1: Motorcycle up to 125cc', value: 'A1' },
    { label: 'A: Motorcycle, tricycle or quadrucycle above 125cc', value: 'A' },
    { label: 'B: Light motor vehicle (not articulated)', value: 'B' },
    { label: 'B5: B with authorization for a tractor', value: 'B5' },
    {
      label: 'B7: B with authorization to mobile machinery',
      value: 'B7'
    },
    { label: 'C1: Heavy motor vehicle (not articulated)', value: 'C1' },
    {
      label: 'C4: C1 with authorization to drive buses ',
      value: 'C4'
    },
    { label: 'C: Extra heavy vehicle (not articulated)', value: 'C' },
    {
      label:
        'EB: Combination- articulated vehicle up to 3500kg or rigid vehicle above 3500kg with trailer above 750kg ',
      value: 'EB'
    },
    {
      label:
        'EC1 Combination- articulated vehicle up to 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
      value: 'EC1'
    },
    {
      label:
        'EC: Combination- articulated vehicle above 16000kg or rigid vehicle above 16000kg with trailer above 750kg',
      value: 'EC'
    }
  ]

  const driverFormFields = [
    {
      name: 'orgId',
      required: false,
      label: 'Organization',
      type: 'select',
      options: organizations
    },
    {
      name: 'firstName',
      required: false,
      label: 'First Name'
    },
    {
      name: 'lastName',
      required: false,
      label: 'Last Name'
    },
    {
      name: 'dob',
      required: false,
      type: 'date',
      label: 'Date of Birth'
    },
    {
      name: 'idNumber',
      required: false,
      label: 'ID Number'
    },
    {
      name: 'gender',
      required: false,
      type: 'select',
      options: [
        { label: 'Male', value: 'MALE' },
        { label: 'Female', value: 'FEMALE' },
        { label: 'Other', value: 'Other' }
      ],
      label: 'Gender'
    },
    {
      name: 'nationality',
      required: false,
      label: 'Nationality'
    },
    {
      name: 'licenseNumber',
      required: false,
      label: 'License Number'
    },
    {
      name: 'doe',
      required: false,
      type: 'date',
      label: 'License Expiry Date'
    },

    {
      name: 'cellphoneNumber',
      required: false,
      label: 'Phone Number'
    },
    {
      name: 'medicalAidNumber',
      required: false,
      label: 'Medical Aid Number'
    },
    {
      name: 'licenceCode',
      required: false,
      label: 'Driver Licence Code',
      type: 'multipleSelect',
      options: licenceCodeValues
    },
    {
      name: 'professionalDrivingPermit',
      required: false,
      label: 'Professional Driving Permit',
      type: 'multipleSelect',
      options: drivingPermitValues
    },
    {
      name: 'professionalDrivingPermitExpiryDate',
      required: false,
      type: 'date',
      label: 'Professional Permit Expiry Date'
    },
    {
      name: 'driverRestriction',
      required: false,
      label: 'Driver Restrictions',
      type: 'multipleSelect',
      options: driverRestrictionsValues
    },
    {
      name: 'vehicleRestriction',
      required: false,
      label: 'Vehicle Restrictions',
      type: 'multipleSelect',
      options: vehicleRestrictionValues
    },
    {
      name: 'isActive',
      required: false,
      type: 'checkbox',
      label: 'Is Active'
    }
  ]

  return (
    <ModalAlert
      title={`New driver`}
      body='Enter details below'
      openDialog={showAddDriverModal}
      setOpenDialog={setShowAddDriverModal}
    >
      <FormGraphQL
        formSchema={driverFormFields}
        createMutation={CREATE_DRIVER}
        updateMutation={UPDATE_DRIVER}
        handleSubmit={({ data, setSubmitting, mutate, onSuccess, onError }) =>
          handleSubmit({ data, setSubmitting, mutate, onSuccess, onError })
        }
        onSuccess={() => setShowAddDriverModal(false)}
        onError={() => setShowAddDriverModal(false)}
        handleCancel={() => setShowAddDriverModal(false)}
        object={object ? object : null}
      />
    </ModalAlert>
  )
}

export default AddDriverModal
