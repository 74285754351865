import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import { tripDataTransform, tripFragment } from './useTrip'
import gql from 'graphql-tag'
import { getTripsVariables } from './useTripIds'
import { useReactiveVar } from '@apollo/client'
import { filters, tripFilters } from '../../GraphQLClient'

export const GET_TRIPS = gql`
  query tripsPaginated(
    $orgIdsFilter: [String]
    $assetGroupId: String
    $driverId: String
    $assetId: String
    $startDate: Float
    $endDate: Float
    $skip: Int
    $limit: Int
    $searchString: String
    $safetyCategory: [String]
    $onlyScoredTrips: Boolean
  ) {
    tripsPaginated(
      orgIdsFilter: $orgIdsFilter
      assetGroupId: $assetGroupId
      driverId: $driverId
      assetId: $assetId
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
      searchString: $searchString
      safetyCategory: $safetyCategory
      onlyScoredTrips: $onlyScoredTrips
    ) {
      totalCount
      trips {
        ...tripFragment
      }
    }
  }
  ${tripFragment}
`

const dataTransform = ({ data }) => {
  return {
    totalCount: data.totalCount,
    data: data.trips && data.trips.map((a) => tripDataTransform({ data: a }))
  }
}

export const useTrips = (tripsVariables) => {
  const { orgIds, groupId, startDate, endDate } = useReactiveVar(filters)
  const { driverId, assetId, safetyCategories, searchString } =
    useReactiveVar(tripFilters)
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null)
  const [loading, setLoading] = useState(false)
  const {
    data: queryData,
    loading: queryLoading,
    fetchMore,
    networkStatus
  } = useQuery(GET_TRIPS, {
    variables: getTripsVariables({
      orgIdsFilter: orgIds && orgIds.length > 0 ? orgIds : null,
      groupId,
      onlyScoredTrips: tripsVariables.onlyScoredTrips,
      searchString,
      safetyCategory: safetyCategories,
      startDate,
      endDate,
      driverId,
      assetId
    }),
    queryName: 'tripsPaginated',
    errorMessage: 'Failed to fetch Trips',
    dataTransform: (data) => dataTransform({ data })
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData.data)
    queryData && setTotalCount(queryData.totalCount)
  }, [queryData])

  return { data, totalCount, loading, fetchMore, networkStatus }
}
