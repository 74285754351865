import React, { useEffect, useRef } from 'react'
import CardsContainer from '../Cards/CardsContainer'
import CardsHeader from '../Cards/CardsHeader'
import CardsList from '../Cards/CardsList'
import {
  driverFilters,
  liveViewMapIds,
  selectedDriverId,
  selectedTabs
} from '../../../../Graphql/GraphQLClient'
import { useOrganizations } from '../../../../Graphql/Queries/useOrganizations'
import { useDriverIds } from '../../../../Graphql/Queries/Driver/useDriverIds'
import DriverCard from './DriverCard'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { handleUrlIdChange } from '..'
import FiltersContainer from '../FiltersContainer'
import DriverFilters, { handleClearFilters } from './DriverFilters'
import { NetworkStatus, useReactiveVar } from '@apollo/client'
import { getValCount } from '../Event/EventFilters'
import TabRoutes from '../../../TabRoutes'
import { removeTrailingSlashes } from '../../../../Helpers/Functions'
import { useNavigation } from '../../../CustomHooks/useNavigation'

const getActiveFiltersCount = (filters) => {
  let count = 0
  if (filters.safetyCategories && filters.safetyCategories.length > 0) count++
  count += getValCount(filters.minSafetyScore)
  count += getValCount(filters.maxSafetyScore)
  return count
}

const DriversList = ({ CardComponent = DriverCard, setShowAddDriverModal }) => {
  const driverId = useReactiveVar(selectedDriverId)
  const { data: driverOrganizations } = useOrganizations()
  const {
    data: driverIds,
    totalCount,
    liveViewIds,
    fetchMore,
    networkStatus
  } = useDriverIds()
  const location = useLocation()
  const { path } = useRouteMatch()
  const filters = useReactiveVar(driverFilters)
  const { driverMoreFiltersTab } = useReactiveVar(selectedTabs)
  const navigateTo = useNavigation()
  useEffect(() => {
    if (driverIds.length === 1) {
      selectedDriverId(driverIds[0]._id)
    }
  }, [driverIds])

  useEffect(() => {
    if (liveViewIds) {
      liveViewMapIds(liveViewIds)
    }
  }, [liveViewIds])

  /*
  useEffect(() => {
    handleUrlIdChange({ location, selectedId: driverId, IdName: 'driver' })
  }, [location])
  */

  const searchInputRef = useRef()

  return (
    <TabRoutes
      renderTabBar={false}
      defaultId={driverMoreFiltersTab ? driverMoreFiltersTab : null}
      tabsData={[
        { id: 'select-card', name: 'Select Card' },
        { id: 'more-filters', name: 'More Filters' }
      ]}
    >
      <>
        <CardsHeader
          title='Drivers'
          number={totalCount ? totalCount : 0}
          items={driverIds}
          searchInputString={filters.searchString}
          setSearchInputString={(value) => [
            driverFilters({ ...driverFilters(), searchString: value }),
            selectedDriverId(null)
          ]}
          searchInputRef={searchInputRef}
          addButton={
            location && location.pathname === 'settings' ? 'Driver' : null
          }
          addButtonOnClick={() => setShowAddDriverModal(true)}
          driverOrganizations={driverOrganizations}
          moreFiltersPath={`${removeTrailingSlashes(path)}/more-filters`}
          activeFiltersCount={getActiveFiltersCount(filters)}
          moreFiltersTabName='driverMoreFiltersTab'
        />
        <CardsContainer fetchMore={fetchMore} networkStatus={networkStatus}>
          <CardsList
            items={driverIds}
            itemsName='Drivers'
            selectedItemId={driverId}
          >
            {({ items, item, index }) => (
              <CardComponent
                driverId={item._id}
                index={index}
                key={item._id}
                selected={item._id === driverId}
                navigateTo={navigateTo}
                borderBottom={
                  index + 1 >= items.length ||
                  (items[index + 1] && items[index + 1]._id !== driverId)
                }
              />
            )}
          </CardsList>
        </CardsContainer>
      </>
      <FiltersContainer
        totalCount={totalCount}
        title='Driver Filters'
        loading={
          networkStatus === NetworkStatus.loading ||
          networkStatus === NetworkStatus.setVariables
        }
        searchInputRef={searchInputRef}
        moreFiltersTabName='driverMoreFiltersTab'
        handleClearFilters={handleClearFilters}
        returnToResultsPath={`${removeTrailingSlashes(path)}/select-card`}
      >
        <DriverFilters searchInputRef={searchInputRef} />
      </FiltersContainer>
    </TabRoutes>
  )
}

export default DriversList
