import { StyleSheet } from 'aphrodite'

import { Colors, Fonts, Metrics } from '../../../Themes'

const animationKeyframes = (props) => {
  return {
    '0%': {
      backgroundColor: props.darkmode
        ? Colors.darkTheme.primary
        : Colors.lightTheme.primary,
      opacity: 0.7
      // height: 5
    },
    '100%': {
      backgroundColor: props.darkmode
        ? Colors.darkTheme.subsectionBackground
        : Colors.lightTheme.subsectionBackground,
      opacity: 1
      // height: 20
    }
  }
}

export const styles = (props) =>
  StyleSheet.create({
    liveUpdate: {
      // backgroundColor: Colors.primary,
      display: 'flex',
      flexDirection: 'row',
      animationName: animationKeyframes(props),
      animationDuration: `${1000}ms`,
      animationTimingFunction: 'ease-in-out'
    }
  })

export default StyleSheet.create({
  // Table
  tableContainer: {
    display: 'block',
    overflowX: 'auto',
    width: '100% !important',
    minHeight: 0
  },
  table: {
    ...Fonts.caption,
    color: Colors.font,
    width: '100%',
    maxWidth: '100%',
    borderCollapse: 'collapse',
    textAlign: 'left'
  },
  th: {
    ...Fonts.bodyBold,
    padding: Metrics.base,
    paddingBottom: Metrics.base / 2
  },
  tr: {
    ':nth-of-type(odd)': {
      background: Colors.background
    }
  },
  trHover: {
    ':hover': {
      cursor: 'pointer'
    }
  },
  td: {
    padding: Metrics.base * 1.5
  },

  // Pagination
  paginationContainer: {
    marginTop: Metrics.base * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  paginationButtons: {
    ...Fonts.body,
    color: Colors.text,
    display: 'flex',
    alignItems: 'center',
    marginBottom: Metrics.base / 2
  },
  paginationIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: Metrics.base * 4,
    height: Metrics.base * 4,
    cursor: 'pointer'
  },
  paginationNumbers: {
    display: 'flex'
  },
  paginationNumber: {
    ...Fonts.body,
    color: Colors.grey2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: Metrics.base * 3.5,
    height: Metrics.base * 3.5,
    margin: 1,
    cursor: 'pointer',

    ':hover': {
      color: Colors.grey1,
      boxShadow: `inset 0px -2px 0px 0px ${Colors.grey1}`
    }
  },
  paginationNumberActive: {
    cursor: 'default',
    color: Colors.grey1,
    boxShadow: `inset 0px -2px 0px 0px ${Colors.grey1}`
  }
})

export const inlineStyles = {
  textContainer: {
    color: Colors.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    //marginBottom: 32
  }
}
