import React, { useEffect, useState } from 'react'
import {
  Button,
  MaterialInput,
  Text,
  FlexBox,
  Icon,
  ProcessingSpinner
} from '../../Components'
import { useMutation } from '@apollo/client'
import { Images, useColors, Metrics } from '../../Themes'
import { LOGIN } from '../../Graphql/Mutations'
import { MainLayout, Subsection } from '../../Components'
import { Formik } from 'formik'
import { Display, Heading, Subheading } from '../../Components/Typography'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useForgotUserPasswordEmail } from '../../Graphql/Mutations/ForgotUserPasswordEmail'

export const Copyright = () => {
  const Colors = useColors()
  return (
    <Text font='caption' color={Colors.textSecondary}>
      Copyright ©
      <Link
        style={{ color: Colors.textSecondary }}
        target='_blank'
        href='https://www.cybelefleet.tech'
      >
        {' '}
        Cybele{' '}
      </Link>
      {new Date().getFullYear()} | Version {Metrics.version}
    </Text>
  )
}

const handleSendEmail = ({ sendEmail, history, values }) => {
  sendEmail({
    variables: values,
    onCompleted: () => history.push('/')
  })
}

const validationSchema = () => {
  return Yup.object().shape({
    userEmail: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required')
  })
}

const ForgotPassword = ({ history }) => {
  const Colors = useColors()
  const [sendEmail, { sendLoading }] = useForgotUserPasswordEmail()
  return (
    <MainLayout publicLayout>
      <FlexBox
        fillSpace
        direction='column'
        style={{
          backgroundColor: Colors.subsectionBackground,
          width: '100%',
          flex: 1
        }}
      >
        <FlexBox direction='row'>
          <img
            alt='Cybele Emblem'
            width={70}
            src={Images.CybeleEmblem}
            style={{
              marginTop: -Metrics.base,
              marginRight: Metrics.base * 3
            }}
          ></img>
          <img
            src={Images.CybeleLogotype}
            alt='BlockHarvest Logo'
            style={{
              width: 400,
              marginTop: -Metrics.base,
              marginLeft: Metrics.base * 2
            }}
            // style={Styles.logo}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox
        fillSpace
        style={{
          backgroundColor: Colors.background,
          borderTop: `1px solid ${Colors.dividerColor}`,
          width: '100%',
          flex: 1
        }}
      >
        <Subsection
          style={{
            width: '60%',
            paddingBottom: Metrics.base * 4,
            flexDirection: 'column'
          }}
        >
          <FlexBox
            style={{
              height: Metrics.navBarHeight,
              width: '100%',
              display: 'flex',
              borderBottom: `1px solid ${Colors.dividerColor}`,
              padding: Metrics.base * 2,
              marginBottom: Metrics.base * 6
            }}
          >
            <FlexBox
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 5,
                padding: Metrics.base * 2,
                justifyContent: 'space-between',
                backgroundColor: Colors.selectHighlightOpacity2
              }}
            >
              <FlexBox
                style={{ width: '100%', justifyContent: 'space-between' }}
              >
                <FlexBox>
                  <Icon
                    name='lock'
                    style={{
                      marginRight: Metrics.base * 2
                    }}
                    size='large'
                    color={Colors.primaryOpacity2}
                  />
                  <Subheading>Forgot Password</Subheading>
                </FlexBox>
                <Copyright />
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <Formik
            initialValues={{
              userEmail: ''
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              isValid
            }) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div style={{ width: '80%' }}>
                  <MaterialInput
                    id='user-email'
                    name='user-email'
                    label='Email *'
                    value={values.userEmail}
                    handleChange={(value) => [
                      setFieldValue('userEmail', value)
                    ]}
                    onBlur={() => setFieldTouched('userEmail')}
                    error={touched.userEmail && errors.userEmail}
                  />
                  <Text
                    font='caption'
                    color={Colors.textSecondary}
                    style={{ marginBottom: Metrics.base * 4 }}
                  >
                    In order to reset your password, follow the link you receive
                    via email.
                  </Text>
                  <Button
                    primary
                    id='login-button'
                    style={{
                      marginTop: Metrics.base * 2,
                      marginBottom: Metrics.base * 2
                    }}
                    onClick={() =>
                      handleSendEmail({
                        sendEmail,
                        history,
                        values
                      })
                    }
                    textColor='white'
                    center
                    fetching={isSubmitting || sendLoading}
                    expand
                    radius
                    title='Send Email'
                    type='submit'
                    disabled={
                      !isValid ||
                      values.userEmail === '' ||
                      isSubmitting ||
                      sendLoading
                    }
                    background={Colors.primary}
                  />
                </div>
              </div>
            )}
          </Formik>
        </Subsection>
      </FlexBox>
    </MainLayout>
  )
}

export default ForgotPassword
