import { StyleSheet } from 'aphrodite'
export const Styles = ({ Color1, Color2 }) =>
  StyleSheet.create({
    selectable: {
      fill: Color1,
      cursor: 'pointer',
      ':hover': {
        fill: Color2
      }
      // transition: 'height 0.2s'
    },
    dotOuter: {
      fill: 'white',
      stroke: Color1,
      cursor: 'pointer',
      ':hover': {
        stroke: Color2
      }
      // transition: 'height 0.2s'
    },
    dotInner: {
      fill: Color1
    }
  })