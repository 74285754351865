import React, { useEffect, useState } from 'react'
import { Table } from '../../../Table'
import { useAssets } from '../../../../Graphql/Queries/Asset/useAssets'
import { checkVal } from '../../../../Helpers/Functions'
import { FlexBox } from '../../..'
import { useColors } from '../../../../Themes'
import AssetFilters from './AssetFilters'
import { Checkbox } from '@material-ui/core'
import { useReactiveVar } from '@apollo/client'
import { filters } from '../../../../Graphql/GraphQLClient'

const selectionColumn = ({ selectionColor, defaultSelected }) => [
  {
    Header: 'Selected',
    accessor: 'selected',
    Aggregated: ({ cell: { value } }) => `-`,
    Cell: ({ cell: { value, row, column }, updateMyData }) => {
      return (
        <Checkbox
          size='small'
          checked={defaultSelected ? !value : value}
          style={{
            color: selectionColor
          }}
        />
      )
    }
  }
]

const otherColumns = [
  {
    Header: 'Display Id',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Name',
    accessor: 'name',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Type',
    accessor: 'type',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'VIN',
    accessor: 'vin',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Registration',
    accessor: 'registrationNum',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const getBorderLeftStyles = (cell, selectionColor) => {
  return { borderLeft: `5px solid ${selectionColor}` }
}

const getColumns = ({ selectionColor, hideSelection, defaultSelected }) =>
  hideSelection
    ? otherColumns
    : [...selectionColumn({ selectionColor, defaultSelected }), ...otherColumns]

const getSelectedIds = (dataState, defaultSelected) => {
  const selectedIds = []
  for (var i = 0; i < dataState.length; i++) {
    if (defaultSelected) {
      !dataState[i].selected && selectedIds.push(dataState[i]._id)
    } else {
      dataState[i].selected && selectedIds.push(dataState[i]._id)
    }
  }
  return selectedIds
}

const AssetMultipleSelectionTable = ({
  orgId,
  groupId,
  renderHeading,
  selectionColor,
  hideSelection,
  defaultSelected,
  setSelectedIds,
  disableOrgFilter
}) => {
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState(null)
  const [savedPageIndex, setPageIndex] = useState(0)
  const { orgId: filtersOrgId } = useReactiveVar(filters)
  const {
    data: assetsData,
    totalCount,
    loading: assetsLoading
  } = useAssets({
    orgId: disableOrgFilter ? orgId : filtersOrgId,
    groupId,
    limit: 10000
  })
  const Colors = useColors()

  useEffect(() => {
    if (assetsData && assetsData.length > 0) {
      setData(assetsData)
      setSelectedIds(getSelectedIds(assetsData, defaultSelected))
    } else {
      setData([])
      setSelectedIds([])
    }
  }, [assetsData, defaultSelected])

  useEffect(() => {
    if (dataState) {
      setSelectedIds(getSelectedIds(dataState, defaultSelected))
    } else setSelectedIds([])
  }, [dataState])

  console.log({ savedPageIndex })

  return (
    <Table
      getBorderLeftStyles={(cell) => getBorderLeftStyles(cell, selectionColor)}
      handleRowClick={(row, updateMyData) => {
        // setSelectedObject(row.original)
        updateMyData(row.index, 'selected', !row.values.selected)
      }}
      savedPageIndex={savedPageIndex}
      setPageIndex={setPageIndex}
      loading={assetsLoading}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      columns={getColumns({
        selectionColor,
        hideSelection,
        defaultSelected
      })}
      hideColumnFilters
      hideNewButton
      hideAssignButton
      showRefreshButton
      dataState={dataState}
      setData={setData}
      title={'Assets'}
      sortBy={[{ _id: '_id' }]}
      defaultPageSize={10}
      renderHeading={renderHeading ? () => renderHeading(totalCount) : null}
      subHeaderContent={
        <FlexBox
          fillSpace
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${Colors.dividerColor}`
          }}
        >
          <AssetFilters
            singleOrgSelect={true}
            disableOrgFilter={disableOrgFilter}
            direction='row'
            maxInputWidth={400}
          />
        </FlexBox>
      }
    />
  )
}

export default AssetMultipleSelectionTable
