import React, { useEffect, useRef, useState } from 'react'
import '@tomtom-international/web-sdk-maps/dist/maps.css'
import { default as ttServices } from '@tomtom-international/web-sdk-services'
import { default as ttMaps } from '@tomtom-international/web-sdk-maps'
import { checkVal } from '../../Helpers/Functions'
import { Images, Metrics, useColors, Utils } from '../../Themes'
import { mapCamera, TOMTOM_API_KEY } from '../../Graphql/GraphQLClient'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'

var popupOffsets = {
  top: [0, 0],
  bottom: [0, -51],
  'bottom-right': [0, -70],
  'bottom-left': [0, -70],
  left: [25, -35],
  right: [-25, -35]
}

const MAX_ZOOM = 10

const increaseZoom = ({ mapZoom, setMapZoom }) => {
  if (mapZoom < MAX_ZOOM) {
    setMapZoom(mapZoom + 1)
  }
}

const decreaseZoom = ({ mapZoom, setMapZoom }) => {
  if (mapZoom > 1) {
    setMapZoom(mapZoom - 1)
  }
}

const updateMap = ({ map, mapLongitude, mapLatitude, mapZoom }) => {
  map.easeTo({
    center: [parseFloat(mapLongitude), parseFloat(mapLatitude)],
    zoom: mapZoom
  })
  // map.setZoom(mapZoom)
}

const setMapCenter = ({ center }) => {}

const removeMarkers = (markers) => {
  if (markers && markers.length > 0) {
    for (var i = 0; i < markers.length; i++) {
      markers[i].remove()
    }
  }
}

const addMarkers = ({
  map,
  setMarkers,
  markerData,
  shouldRemoveMarkers,
  markers
}) => {
  if (shouldRemoveMarkers) {
    removeMarkers(markers)
  }
  if (markerData && markerData.length > 0) {
    const temp = []
    for (var i = 0; i < markerData.length; i++) {
      var element = document.createElement('div')
      element.id = 'marker'
      element.style = `background-image: url('${markerData[i].icon}');
      background-size: cover;
      width: ${markerData[i].width}px;
      height: 51px;`
      element.onclick = markerData[i].onClick
      const markerProps = markerData[i].icon ? { element } : {}
      var marker = new ttMaps.Marker({
        rotation: markerData[i].rotation,
        anchor: markerData[i].anchor ? markerData[i].anchor : 'bottom',
        // rotationAlignment: 'map',
        offset: markerData[i].offset ? markerData[i].offset : [0, 0],
        color: markerData[i].color,
        ...markerProps
      })
        .setLngLat(markerData[i].location)
        .addTo(map)
      if (markerData[i].hasPopup) {
        var popup = new ttMaps.Popup({ offset: popupOffsets }).setHTML(
          markerData[i].popupHTML && markerData[i].popupHTML(markerData[i])
        )
        marker.setPopup(popup) // .togglePopup()
        markerData[i].popupOpen && marker.togglePopup()
      }
      temp.push(marker)
    }
    setMarkers(temp)
  }
}

const addTripPointMarkers = ({ map, tripPoints, color }) => {
  if (tripPoints && tripPoints.length > 0) {
    for (var i = 0; i < tripPoints.length; i++) {
      if (
        checkVal(tripPoints[i].longitude) &&
        checkVal(tripPoints[i].latitude)
      ) {
        const location = [tripPoints[i].longitude, tripPoints[i].latitude]
        var element = document.createElement('div')
        element.id = 'marker'
        element.style = `background-image: url('${Images.mapHeadingIcon}');
        background-size: cover;
      width: 5px;
      height: 5px;`
        element.onclick = tripPoints[i].onClick
        var marker = new ttMaps.Marker({
          rotation: tripPoints[i] ? tripPoints[i].heading : 0,
          anchor: 'center',
          element
          // rotationAlignment: 'map',
          // offset: markerData[i].offset ? markerData[i].offset : [0, 0],
          // color: markerData[i].color,
        })
          .setLngLat(location)
          .addTo(map)
      }
    }
  }
}

const removeMapLayer = (map, id) => {
  if (map && map.getLayer(id)) {
    map.removeLayer(id)
    map.removeSource(id)
  }
}

export const addRoute = ({ map, points, layerId, color, stroke }) => {
  ttServices.services
    .calculateRoute({
      key: TOMTOM_API_KEY,
      locations: [points[0], points[points.length - 1]],
      supportingPoints: points && points.length > 1 ? points : null
    })
    .then((routeData) => {
      if (!map.getLayer(layerId)) {
        map.addLayer({
          id: layerId,
          type: 'line',
          source: {
            type: 'geojson',
            data: routeData.toGeoJson()
          },
          paint: {
            'line-color': color,
            'line-width': stroke
          }
        })
      }
    })
}

const addEventLines = ({ map, eventsWithPoints, Colors, setMapLayerIds }) => {
  const layerIds = []
  for (var i = 0; i < eventsWithPoints.length; i++) {
    const event = eventsWithPoints[i]
    if (event && event.points && event.points.length > 0) {
      layerIds.push(event._id)
      const points = event.points.map(
        (a) => a.longitude && a.latitude && [a.longitude, a.latitude]
      )
      addLine({
        map,
        points,
        layerId: event._id,
        color: Colors[event.type],
        stroke: 12
      })
    }
  }
  setMapLayerIds(layerIds)
}

const addTripRoute = ({
  map,
  routeGeoJSON,
  eventsWithPoints,
  Colors,
  setMapLayerIds
}) => {
  if (!map.getLayer('route-layer')) {
    map.addLayer({
      id: `route-layer`,
      type: 'line',
      source: {
        type: 'geojson',
        data: routeGeoJSON
      },
      paint: {
        'line-color': Colors.primary,
        'line-width': 6
      }
    })
  }
  eventsWithPoints &&
    eventsWithPoints.length > 0 &&
    addEventLines({ map, eventsWithPoints, Colors, setMapLayerIds })
  /*addTripPointMarkers({
        map,
        tripPoints: sortedTripPoints,
        color
      })*/
}

export const getGeoJSON = (points, type = 'LineString') => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        geometry: {
          coordinates: [...points],
          type
        }
      }
    ]
  }
}

export const addLine = ({ map, points, layerId, color, stroke }) => {
  if (points && points.length > 1) {
    if (!map.getLayer(layerId)) {
      map.addLayer({
        id: layerId,
        type: 'line',
        source: {
          type: 'geojson',
          data: getGeoJSON(points)
        },
        paint: {
          'line-color': color,
          'line-width': stroke
        }
      })
    } else {
      map.getSource(layerId).setData(getGeoJSON(points))
    }
  }
}

const TomTomMap = ({
  map,
  setMap,
  center,
  zoom,
  initialMarkersData,
  markers,
  setMarkers,
  tripPoints,
  tripPointsLoading,
  routeGeoJSON,
  width,
  height,
  eventsWithPoints,
  locationsLoading
}) => {
  const Colors = useColors()
  const mapElement = useRef()
  const [mapLayerIds, setMapLayerIds] = useState([])
  const [mapLongitude, setMapLongitude] = useState(19.50304069)
  const [mapLatitude, setMapLatitude] = useState(-33.9498945)
  const [mapZoom, setMapZoom] = useState(8)
  const [mapLoaded, setMapLoaded] = useState(false)
  useEffect(() => {
    let map = ttMaps.map({
      key: TOMTOM_API_KEY,
      container: mapElement.current,
      center: [mapLongitude, mapLatitude],
      zoom: mapZoom
    })
    map.on('load', () => {
      setMapLoaded(true)
    })
    setMap(map)
    return () => map.remove()
  }, [])

  useEffect(() => {
    map && map.resize()
  }, [width, height])

  useEffect(() => {
    if (map && mapLoaded) {
      addMarkers({
        map,
        setMarkers,
        markerData: initialMarkersData,
        shouldRemoveMarkers: true,
        markers
      })
    }
  }, [map, mapLoaded, tripPoints, initialMarkersData])

  useEffect(() => {
    if (map && routeGeoJSON && mapLoaded) {
      removeMapLayer(map, 'route-layer')
      if (mapLayerIds) {
        for (var i = 0; i < mapLayerIds.length; i++) {
          removeMapLayer(map, mapLayerIds[i])
        }
      }
      addTripRoute({
        map,
        routeGeoJSON,
        eventsWithPoints,
        Colors,
        setMapLayerIds
      })
    }
  }, [mapLoaded, routeGeoJSON, eventsWithPoints])

  useEffect(() => {
    if (checkVal(center)) {
      setMapZoom(zoom)
      setMapLongitude(center.lat)
      setMapLatitude(center.lng)
      if (map) {
        updateMap({
          map,
          mapLongitude: center.lng,
          mapLatitude: center.lat,
          mapZoom: zoom
        })
      }
    }
  }, [map, center, zoom])
  return (
    <div
      ref={mapElement}
      className='mapDiv'
      style={{
        position: 'relative',
        width: width ? width : '100%',
        height: height ? height : 600,
        borderRadius: Metrics.radius,
        boxShadow: Colors.isDarkmode
          ? Utils.boxShadowDark
          : Utils.boxShadowLight
      }}
    >
      {(locationsLoading || tripPointsLoading) && (
        <FlexBox
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Colors.backgroundOpacity3
          }}
        >
          <ProcessingSpinner size={40} />
        </FlexBox>
      )}
    </div>
  )
}

export default TomTomMap
