import React, { useState, useEffect } from 'react'
import { Metrics, useColors } from '../../Themes'
import { FlexBox, Subsection } from '../../Components'
import { checkVal } from '../../Helpers/Functions'
import { selectedEventId, selectedTripId } from '../../Graphql/GraphQLClient'
import TomTomMap from '../../Components/TomTomMap'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import { useMapCamera } from '../../Graphql/Queries/useMapCamera'
import { useReactiveVar } from '@apollo/client'
import SpeedChartTimeBar from './SpeedChartTimeBar'
import ReplaySideBar from './ReplaySideBar'
import useTripData from './useTripData'
import { getInitialMarkers } from './MapView'

const TripReplay = (props) => {
  const tripId = useReactiveVar(selectedTripId)
  const eventId = useReactiveVar(selectedEventId)
  const {
    trip,
    tripPoints,
    events,
    routeGeoJSON,
    eventsWithPoints,
    startTimeUnix,
    tripDuration,
    loading
  } = useTripData(tripId)
  const { tripHistoryMapCenter, tripHistoryMapZoom } = useMapCamera()
  const [markers, setMarkers] = useState(null)
  const [initialMarkersData, setInitialMarkersData] = useState([])
  const Colors = useColors()
  const [map, setMap] = useState(null)
  const [selectedEventTypes, setSelectedEventTypes] = useState({
    SPEEDING: true,
    PHONE_MOTION: true,
    HARSH_BRAKING: true,
    HARSH_ACCELERATION: true,
    HARSH_CORNERING: true
  })
  const { mainContainerWidth, mainContainerHeight, mapWidth } =
    useAppDimensions()

  useEffect(() => {
    if (tripPoints && tripPoints.length > 1)
      setInitialMarkersData(
        getInitialMarkers({
          markerData: events,
          trip,
          tripPoints,
          selectedEventTypes,
          eventId,
          Colors,
          showTruckIcon: true
        })
      )
  }, [tripPoints, tripId, trip, events, selectedEventTypes])

  const mapHeight =
    mainContainerHeight -
    Metrics.base * 11 -
    (checkVal(tripId) ? /*Metrics.base * 13*/ 0 : 0)

  return (
    <FlexBox
      style={{
        paddingTop: Metrics.base * 2,
        paddingLeft: Metrics.base * 2,
        paddingRight: Metrics.base * 2,
        paddingBottom: 0,
        height: '100%',
        width: '100%'
      }}
      direction='column'
    >
      <FlexBox
        direction='row'
        style={{
          alignItems: 'flex-start',
          height: '100%',
          width: '100%'
        }}
      >
        <TomTomMap
          map={map}
          setMap={setMap}
          initialMarkersData={initialMarkersData}
          markers={markers}
          setMarkers={setMarkers}
          center={
            tripHistoryMapCenter
              ? tripHistoryMapCenter
              : {
                  lat: -33.9498945,
                  lng: 19.5030406
                }
          }
          zoom={tripHistoryMapZoom ? tripHistoryMapZoom : 15}
          routeGeoJSON={routeGeoJSON}
          tripPoints={tripPoints}
          tripPointsLoading={loading}
          eventsWithPoints={eventsWithPoints}
          showEventLines
          tripId={tripId}
          width={mapWidth}
          height={mapHeight - 212}
        />
        <ReplaySideBar
          selectedEventTypes={selectedEventTypes}
          setSelectedEventTypes={setSelectedEventTypes}
          height={mapHeight - 212}
          map={map}
          markers={markers}
          startTimeUnix={startTimeUnix}
          tripDuration={tripDuration}
          trip={trip}
        />
      </FlexBox>
      <Subsection style={{ height: 200, marginTop: Metrics.base * 2 }}>
        <SpeedChartTimeBar
          tripId={tripId}
          events={events}
          chartWidth={mainContainerWidth - Metrics.base * 6}
          chartHeight={180}
          color={Colors.primaryOpacity3}
          strokeColor={Colors.primary}
          gradientColor1={Colors.primaryOpacity5}
          gradientColor2={Colors.primaryOpacity3}
        />
      </Subsection>
    </FlexBox>
  )
}

export default TripReplay
