import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'
import { locationDataTransform, locationFragment } from './useLocation'
import { GET_LOCATION_UPDATED } from '../Subscriptions/useLocationUpdated'
// import devices from '../tempData/Devices'

const findIndex = (arr, deviceId) => {
  for (var i in arr) {
    if (arr[i].device._id === deviceId) return i
  }
  return -1
}

export const GET_LAST_LOCATIONS = gql`
  query lastLocations($deviceIds: [String]) {
    lastLocations(deviceIds: $deviceIds) {
      _id
      locations {
        locationCache {
          speedLimit
        }
        ...locationFragment
        device {
          _id
          deviceId
        }
      }
    }
  }
  ${locationFragment}
`

const dataTransform = ({ data }) => {
  return data.map((a) => ({
    ...a,
    locations:
      a &&
      a.locations &&
      a.locations.map((b) => locationDataTransform({ data: b }))
  }))
}

export const useLastLocations = ({ deviceIds, skip, fetchPolicy }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    subscribeToMore
  } = useQuery(GET_LAST_LOCATIONS, {
    variables: { deviceIds },
    notifyOnNetworkStatusChange: true,
    queryName: 'lastLocations',
    errorMessage: 'Failed to fetch lastLocations',
    dataTransform: (data) => dataTransform({ data }),
    fetchPolicy: fetchPolicy ? fetchPolicy : 'cache-first',
    skip: !deviceIds || deviceIds.length <= 0 || skip
  })

  /*
  useEffect(() => {
    let unsubscribe = () => {}
    unsubscribe = subscribeToMore({
      document: GET_LOCATION_UPDATED,
      variables: { internalDeviceId },
      skip: !internalDeviceId,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newItem = subscriptionData.data['locationUpdated']
        // if (prev && prev.length >= 0) {
        const newData = [...prev['lastLocations']]
        const index = findIndex(prev['lastLocations'], internalDeviceId)
        newData[index] = newItem
        return {
          ...prev,
          lastLocations: newData
        }
        // }
        // return prev
      }
    })
    return () => unsubscribe()
  })
  */

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading }
}
