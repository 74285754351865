import React from 'react'
import { Metrics, useColors } from '../../Themes'
import { FlexBox, Text } from '..'
import Styles from './Styles'

// export const getStatusText = (status) => {
//   return status.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
//   })
// }
export const getStatusText = (status) => {
  return status ? status.toUpperCase() : ''
}

const Status = ({ style, status, getStatusColors }) => {
  const Colors = useColors()
  const color = getStatusColors({ status, Colors })
  return (
    <FlexBox
      direction='row'
      style={{
        padding: Metrics.base / 2,
        position: 'relative',
        ...style
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: color,
          borderRadius: Metrics.radius,
          zIndex: 0,
          opacity: 0.1
        }}
      ></div>
      <FlexBox style={{ zIndex: 10, minWidth: 50 }}>
        <Text font='status' color={color}>
          {status && getStatusText(status)}
        </Text>
      </FlexBox>
    </FlexBox>
  )
}

export default Status
