import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { hexOpacity } from '../../../Helpers/Constants'
import { Colors, Metrics } from '../../../Themes'
import { ProcessingSpinner, Row, Text } from '../../'
import Styles, { inlineStyles } from './Styles'

export default class ProcessingOverlay extends Component {
  render() {
    const {
      backgroundColor,
      spinnerColor,
      spinnerSize,
      textColor,
      loading
    } = this.props

    return (
      <div className={css(Styles.mainContainer)}>
        <Row
          center='xs'
          middle='xs'
          style={{
            ...inlineStyles.container,
            backgroundColor: `${backgroundColor}${hexOpacity._50}`
          }}
        >
          <ProcessingSpinner color={spinnerColor} size={spinnerSize} />
          {loading ? (
            <Text marginTop={1} color={textColor} font={'subheading'}>
              {loading}
            </Text>
          ) : null}
        </Row>
      </div>
    )
  }
}

ProcessingOverlay.propTypes = {
  backgroundColor: PropTypes.string,
  spinnerColor: PropTypes.string,
  spinnerSize: PropTypes.number
}

ProcessingOverlay.defaultProps = {
  backgroundColor: Colors.white,
  spinnerSize: Metrics.base * 4
}
