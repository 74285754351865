import React, { useState, useEffect } from 'react'
import { FlexBox, DataDisplay, Subsection, Text, ProgressBar } from '../..'
import InfoBadge from '../InfoBadge'
import { Metrics, useColors, Images } from '../../../Themes'
import { assertEnumType } from 'graphql'
import { Link } from '@material-ui/core'
import SubsectionDivider from '../SubsectionDivider'
import AddAssetModal from '../AddAssetModal'
import AddDocumentModal from '../AddDocumentModal'
import { useDocumentsByAssetId } from '../../../Graphql/Queries/useDocumentsByAssetId'
import LinkDriverToAsset from '../../../Containers/FleetManagement/LinkDriverToAsset'
import {
  formatDate,
  formatDateTime,
  timeUntilDate,
  checkVal
} from '../../../Helpers/Functions'
import { useAsset } from '../../../Graphql/Queries/Asset/useAsset'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import {
  selectedAssetId,
  selectedDriverId
} from '../../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'
import { useVehicleLicences } from '../../../Graphql/Queries/Document/useVehicleLicences'
import { InsetCardContainer } from '../SelectionPanel/Cards/CardsContainer'
import DriverCard from '../SelectionPanel/Driver/DriverCard'
import { useNavigation } from '../../CustomHooks/useNavigation'
import NoneSelected from '../../Custom/NoneSelected'

const LicenceInfo = ({
  Colors,
  vehicleLicence,
  assetDocuments,
  setShowAddDocumentModal,
  showAddDocumentModal,
  asset
}) => {
  const excludeLinks = [
    'VEHICLE_INCIDENT_IMAGES',
    'VEHICLE_INCIDENT',
    'VEHICLE_CRASH_IMAGES',
    'VEHICLE_CRASH',
    'INSPECTION_EXTRAS',
    'INSPECTION_MAIN'
  ]

  const licenceExpiry = ({ asset, setExpiryPercent, setExpiryTime }) => {
    if (asset && asset.doe) {
      let percent = percentageUntilExpiry({
        doe: asset.doe ? asset.doe : null
      })
      if (percent > 100) {
        setExpiryPercent([percent, Colors.HIGH_RISK])
      } else if (percent <= 100 && percent > 49) {
        setExpiryPercent([percent, Colors.MODERATE_RISK])
      } else {
        setExpiryPercent([percent, Colors.SAFE])
      }

      let timeUntil = timeUntilDate({
        dateEnd: asset.doe
      })

      if (timeUntil > 0) {
        setExpiryTime([timeUntil, 'VALID'])
      } else {
        setExpiryTime([timeUntil * -1, 'EXPIRED'])
      }
    } else {
      setExpiryPercent([0, Colors.disabled])
      setExpiryTime([0, 'NOT_AVAILABLE'])
    }
  }

  const percentageUntilExpiry = ({ doe }) => {
    const timeFromToday = timeUntilDate({ dateEnd: doe })
    const dateStart = new Date(doe).getTime() - 31557600000
    const issueToExpiry = timeUntilDate({ dateStart: dateStart, dateEnd: doe })
    let percent = 100 - (timeFromToday / issueToExpiry) * 100
    if (percent < 0) {
      percent = 0
    }
    return Math.floor(percent)
  }

  const [licenceExpiryPercent, setLicenceExpiry] = useState([])
  const [timeUntilExpiry, setTimeUntilExpiry] = useState([])
  useEffect(() => {
    licenceExpiry({
      asset,
      setExpiryPercent: setLicenceExpiry,
      setExpiryTime: setTimeUntilExpiry
    })
  }, [asset])

  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        // justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%'
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Licence'
        // leadIcon
        btnDisabled={!(asset && asset._id)}
        actionButton='Upload Licence'
        handleButtonClick={() => setShowAddDocumentModal(true)}
        status='Valid'
        leadImg={
          timeUntilExpiry[1] === 'VALID' ? Images.verifiedUser : Images.error
        }
        // label='Driver details'
      />
      <SubsectionDivider />
      <FlexBox direction='column' style={{ width: '100%' }}>
        {vehicleLicence && vehicleLicence.document ? (
          <FlexBox direction='column' style={{ width: '100%' }}>
            {vehicleLicence.document.url.includes('.pdf') ? (
              <embed
                src={vehicleLicence.document.url}
                width='400'
                height='620px'
              />
            ) : (
              <img
                src={vehicleLicence.document.url}
                alt='Licence'
                width='70%'
                height='70%'
                style={{
                  padding: 10,
                  marginBottom: Metrics.base * 2,
                  marginTop: Metrics.base * 2
                }}
              />
            )}
          </FlexBox>
        ) : (
          <FlexBox
            direction='column'
            style={{
              width: '100%',
              // marginBottom: Metrics.base * 2,
              marginTop: Metrics.base * 2
            }}
          >
            <Text font={'subheading'} style={{ padding: Metrics.base * 3 }}>
              Please Upload The Vehicle's Licence
            </Text>
          </FlexBox>
        )}
        <SubsectionDivider />
        <FlexBox
          direction='column'
          style={{ width: '70%', marginTop: Metrics.base * 2 }}
        >
          <ProgressBar
            completed={licenceExpiryPercent[0]}
            bgcolor={licenceExpiryPercent[1]}
            // style={{ paddingTop: Metrics.base * 2 }}
          />
          <Text
            font='subheading'
            style={{
              textAlign: 'center',
              marginLeft: Metrics.base * 3,
              paddingBottom: Metrics.base * 3,
              paddingTop: Metrics.base * 1
            }}
          >
            {timeUntilExpiry[1] === 'EXPIRED'
              ? 'Licence expired: ' + timeUntilExpiry[0] + ' days ago'
              : timeUntilExpiry[1] === 'VALID'
              ? 'Licence valid for: ' + timeUntilExpiry[0] + ' days'
              : 'No licence expiry date available'}
          </Text>
        </FlexBox>
        <SubsectionDivider />
        <InfoBadge
          style={{
            width: '100%',
            alignItems: 'left',
            justifyContent: 'flex-start'
          }}
          value='Docs and Links'
          noBoxShadow
          btnDisabled={!(asset && asset._id)}
          actionButton='Edit Items'
          handleButtonClick={() => setShowAddDocumentModal(true)}
        />
        {showAddDocumentModal && (
          <AddDocumentModal
            typeOptions={'asset'}
            showAddDocumentModal={showAddDocumentModal}
            setShowAddDocumentModal={setShowAddDocumentModal}
            assetId={asset._id}
          />
        )}
        <SubsectionDivider />
        <FlexBox
          direction='column'
          alignItems='left'
          style={{
            width: '100%',
            marginLeft: Metrics.base * 6,
            marginTop: Metrics.base * 2
          }}
        >
          {assetDocuments &&
            assetDocuments.map((link) => {
              return (
                <>
                  {link.isActive && !excludeLinks.includes(link.documentType) && (
                    <Link
                      underline='always'
                      color={Colors.primary}
                      style={{
                        // color: Colors.primary,
                        marginBottom: Metrics.base * 2,
                        marginRight: Metrics.base / 2,
                        marginLeft: Metrics.base / 2
                      }}
                      target='_blank'
                      href={link.url}
                    >
                      <Text color={Colors.primary}>{link.documentType}</Text>
                    </Link>
                  )}
                </>
              )
            })}
        </FlexBox>
      </FlexBox>
    </Subsection>
  )
}

const LinkedDriver = ({ Colors, setShowLinkDriverModal, asset }) => {
  const navigateTo = useNavigation()
  const driverId = asset && asset.driver && asset.driver._id
  return (
    <Subsection
      style={{
        // backgroundColor: 'white',
        // justifyContent: 'space-between',
        // radiusBorder: '30',
        width: '100%',
        marginTop: Metrics.base * 2
      }}
    >
      <InfoBadge
        style={{
          width: '100%',
          alignItems: 'left',
          justifyContent: 'flex-start'
        }}
        noBoxShadow
        //   color={getImportanceScoreColor(aggregatedScore)}
        // colorOpacity={1}
        value='Linked Driver'
        // leadIcon
        btnDisabled={!(asset && asset._id)}
        actionButton='Link Driver'
        handleButtonClick={() => setShowLinkDriverModal(true)}
        // label='Driver details'
      />
      <FlexBox
        direction='column'
        style={{ width: '100%', padding: Metrics.base * 2, paddingBottom: 0 }}
      >
        <InsetCardContainer
          name='Linked Driver'
          style={{
            marginBottom: Metrics.base * 2
          }}
        >
          <DriverCard
            driverId={driverId}
            onClick={() => [
              selectedDriverId(driverId),
              navigateTo({
                pathname: '/fleet-management/drivers',
                queryParams: [{ name: 'driver', id: driverId }]
              })
            ]}
            backgroundColor={Colors.background}
          />
        </InsetCardContainer>
      </FlexBox>
    </Subsection>
  )
}

const AssetInfo = () => {
  const Colors = useColors()
  const assetId = useReactiveVar(selectedAssetId)
  const { data: asset, refetch: assetRefetch } = useAsset(assetId)
  const [showAddAssetModal, setShowAddAssetModal] = useState(false)
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false)
  const [licenceVariables, setLicenceVariables] = useState()
  const [vehicleLicence, setVehicleLicence] = useState([])
  const { data: vehicleLicenceDocument, refetch: licenceRefetch } =
    useVehicleLicences(licenceVariables)

  const { data: assetDocuments } = useDocumentsByAssetId(asset._id)
  const [linkDriverModal, setShowLinkDriverModal] = useState(false)
  const { mainContainerWidth } = useAppDimensions()

  useEffect(() => {
    if (checkVal(assetId)) {
      setLicenceVariables({ assetId: assetId, isActive: true })
    }
  }, [assetId])

  useEffect(() => {
    licenceRefetch()
  }, [assetDocuments])

  useEffect(() => {
    assetRefetch()
  }, [vehicleLicence])

  useEffect(() => {
    if (!showAddAssetModal) {
      assetRefetch()
    }
  }, [showAddAssetModal])

  useEffect(() => {
    if (vehicleLicenceDocument && vehicleLicenceDocument.length > 0) {
      setVehicleLicence(vehicleLicenceDocument[0])
    } else {
      setVehicleLicence([])
    }
  }, [assetId, vehicleLicenceDocument])

  return (
    <FlexBox fillSpace>
      {assetId ? (
        <FlexBox
          fillSpace
          direction='row'
          style={{
            alignItems: 'flex-start',
            width: mainContainerWidth,
            paddingLeft: Metrics.base * 2,
            paddingRight: Metrics.base * 2,
            marginTop: Metrics.base * 2
          }}
        >
          <>
            <FlexBox style={{ flex: 1, paddingRight: Metrics.base }}>
              <Subsection
                style={{
                  width: '100%'
                }}
              >
                <InfoBadge
                  noBoxShadow
                  //   color={getImportanceScoreColor(aggregatedScore)}
                  // colorOpacity={1}
                  value='Asset details'
                  // leadIcon
                  actionButton='Edit Details'
                  btnDisabled={!assetId}
                  handleButtonClick={() => setShowAddAssetModal(true)}
                  style={{
                    width: '100%',
                    alignItems: 'left',
                    justifyContent: 'flex-start'
                  }}
                />
                {showAddAssetModal && (
                  <AddAssetModal
                    showAddAssetModal={showAddAssetModal}
                    setShowAddAssetModal={setShowAddAssetModal}
                    object={asset}
                  />
                )}
                <SubsectionDivider />
                <FlexBox direction='column' style={{ width: '100%' }}>
                  <DataDisplay
                    firstItem={true}
                    label='Name'
                    data={asset.name ? asset.name : '-'}
                  />
                  <DataDisplay
                    label='Registration'
                    data={asset.registrationNum ? asset.registrationNum : '-'}
                  />
                  <DataDisplay
                    label='Vehicle Reference no.'
                    data={asset.assetNo ? asset.assetNo : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='VIN'
                    data={asset.vin ? asset.vin : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Type'
                    data={asset.assetType ? asset.assetType : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Sub Vehicle Type'
                    data={asset.subVehicleType ? asset.subVehicleType : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Make'
                    data={asset.vehicleMake ? asset.vehicleMake : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Model'
                    data={asset.vehicleModel ? asset.vehicleModel : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Fuel Type'
                    data={asset.fuelType ? asset.fuelType : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Year'
                    data={asset.vehicleYear ? asset.vehicleYear : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Description'
                    data={
                      asset.vehicleDescription ? asset.vehicleDescription : '-'
                    }
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Vehicle Colour'
                    data={asset.vehicleColour ? asset.vehicleColour : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Special Markings'
                    data={asset.specialMarkings ? asset.specialMarkings : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Branch'
                    data={asset.branch ? asset.branch : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='License Number'
                    data={asset.licenseNumber ? asset.licenseNumber : '-'}
                  />
                  <DataDisplay
                    label='Date of Purchase'
                    data={
                      asset.dateOfPurchase
                        ? formatDate(asset.dateOfPurchase)
                        : '-'
                    }
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Tare'
                    data={asset.tare ? asset.tare + ' kg' : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='GVM'
                    data={asset.gvm ? asset.gvm + ' kg' : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Control Number'
                    data={asset.controlNumber ? asset.controlNumber : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Registering Authority'
                    data={
                      asset.registeringAuthority
                        ? asset.registeringAuthority
                        : '-'
                    }
                    lastItem={true}
                  />
                  <DataDisplay
                    label='Engine Number'
                    data={asset.engineNumber ? asset.engineNumber : '-'}
                    lastItem={true}
                  />
                  {/*
                  <DataDisplay
                    label='Max Persons'
                    data={asset.maxPersons ? asset.maxPersons : '-'}
                    lastItem={true}
                  />
                  */}
                  <DataDisplay
                    label='Licence Expiry Date'
                    data={asset.doe ? formatDate(asset.doe) : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='NVC'
                    data={asset.nvc ? asset.nvc : '-'}
                    lastItem={true}
                  />
                  <DataDisplay
                    label='PrDP Category'
                    data={
                      asset.prdpCategory
                        ? asset.prdpCategory.map((value, index) => {
                            if (index + 1 === asset.prdpCategory.length) {
                              return value
                            } else {
                              return value + ' | '
                            }
                          })
                        : '-'
                    }
                    lastItem={true}
                  />
                  {showAddAssetModal && (
                    <AddAssetModal
                      showAddAssetModal={showAddAssetModal}
                      setShowAddAssetModal={setShowAddAssetModal}
                      object={asset}
                    />
                  )}
                </FlexBox>
              </Subsection>
            </FlexBox>
            <FlexBox
              direction='column'
              style={{ flex: 1, paddingLeft: Metrics.base }}
            >
              <LicenceInfo
                Colors={Colors}
                asset={asset}
                vehicleLicence={vehicleLicence}
                setShowAddDocumentModal={setShowAddDocumentModal}
                assetDocuments={assetDocuments}
                showAddDocumentModal={showAddDocumentModal}
              />
              <LinkedDriver
                asset={asset}
                Colors={Colors}
                setShowLinkDriverModal={setShowLinkDriverModal}
              />
              {linkDriverModal && (
                <LinkDriverToAsset
                  showLinkDriverModal={linkDriverModal}
                  setShowLinkDriverModal={setShowLinkDriverModal}
                  assetId={assetId}
                />
              )}
            </FlexBox>
          </>
        </FlexBox>
      ) : (
        <NoneSelected
          alignSelf='center'
          helperText='Please select an asset from the list on the left'
        />
      )}
    </FlexBox>
  )
}

export default AssetInfo
