import { useEffect, useState } from 'react'
import { useQuery } from '../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

export const GET_SELECTED_TABS = gql`
  query selectedTabs {
    selectedTabs @client {
      liveMonitoring
      liveMonitoringAD
      tripHistory
      tripHistoryMapTab
      settingsAdminTab
      settingsFleetTab
      settingsPage
      settingsADDTabs
      safetyScoreADTabs
      safetyScoreOverviewSelectTabs
      safetyScoreMainTabs
      eventTypeTab
      coachingRadioTabs
      coachingTabs
      driverBehaviour
    }
  }
`

export const useSelectedTabs = () => {
  const [liveMonitoring, setLiveMonitoring] = useState([])
  const [liveMonitoringAD, setLiveMonitoringAD] = useState(null)
  const [settingsADDTabs, setSettingsADDTabs] = useState(null)
  const [tripHistory, setTripHistory] = useState()
  const [tripHistoryMapTab, setTripHistoryMapTab] = useState()
  const [settingsAdminTab, setSettingsAdminTab] = useState([])
  const [settingsFleetTab, setSettingsFleetTab] = useState([])
  const [settingsPage, setSettingsPage] = useState([])
  const [safetyScoreADTabs, setSafetyScoreADTabs] = useState([]) // ASSET DRIVER TRIP (ADT)
  const [safetyScoreOverviewSelectTabs, setSafetyScoreOverviewSelectTabs] =
    useState([]) // OVERVIEW || SELECT
  const [safetyScoreMainTabs, setSafetyScorePage] = useState([]) // OVERVIEW || SCORE BREAKDOWN
  const [eventTypeTab, setEventTypeTab] = useState(null) // OVERVIEW || SCORE BREAKDOWN
  const [coachingRadioTabs, setCoachingRadioTabs] = useState(null) // OVERVIEW || SCORE BREAKDOWN
  const [coachingTabs, setCoachingTabs] = useState(null) // OVERVIEW || SCORE BREAKDOWN
  const [driverBehaviour, setDriverBehaviour] = useState(null)

  const { data: selectedTabs } = useQuery(GET_SELECTED_TABS, {
    queryName: 'selectedTabs'
  })

  useEffect(() => {
    setSettingsADDTabs(selectedTabs ? selectedTabs.settingsADDTabs : null)
    setLiveMonitoring(selectedTabs ? selectedTabs.liveMonitoring : null)
    setLiveMonitoringAD(selectedTabs ? selectedTabs.liveMonitoringAD : null)
    setLiveMonitoring(selectedTabs ? selectedTabs.liveMonitoring : null)
    setTripHistory(selectedTabs ? selectedTabs.tripHistory : null)
    setTripHistoryMapTab(selectedTabs ? selectedTabs.tripHistoryMapTab : null)
    setSettingsAdminTab(selectedTabs ? selectedTabs.settingsAdminTab : null)
    setSettingsFleetTab(selectedTabs ? selectedTabs.settingsFleetTab : null)
    setSettingsPage(selectedTabs ? selectedTabs.settingsPage : null)
    setSafetyScoreADTabs(selectedTabs ? selectedTabs.safetyScoreADTabs : null)
    setSafetyScoreOverviewSelectTabs(
      selectedTabs ? selectedTabs.safetyScoreOverviewSelectTabs : null
    )
    setSafetyScorePage(selectedTabs ? selectedTabs.safetyScoreMainTabs : null)
    setEventTypeTab(selectedTabs ? selectedTabs.eventTypeTab : null)
    setCoachingRadioTabs(selectedTabs ? selectedTabs.coachingRadioTabs : null)
    setCoachingTabs(selectedTabs ? selectedTabs.coachingTabs : null)
    setDriverBehaviour(selectedTabs ? selectedTabs.driverBehaviour : null)
  }, [selectedTabs])

  return {
    liveMonitoring,
    tripHistory,
    tripHistoryMapTab,
    settingsAdminTab,
    settingsPage,
    settingsFleetTab,
    settingsADDTabs,
    liveMonitoringAD,
    safetyScoreADTabs,
    safetyScoreOverviewSelectTabs,
    safetyScoreMainTabs,
    eventTypeTab,
    coachingRadioTabs,
    coachingTabs,
    driverBehaviour
  }
}
