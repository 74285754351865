import { useReactiveVar } from '@apollo/client'
import { useState, useEffect } from 'react'
import { selectedDeviceId } from '../../Graphql/GraphQLClient'
import { useLastLocations } from '../../Graphql/Queries/useLastLocations'
import { useLocationUpdated } from '../../Graphql/Subscriptions/useLocationUpdated'
import { checkVal, removeDuplicateObjects } from '../../Helpers/Functions'

const updateMarker = ({ marker, location }) => {
  if (marker && location) {
    const newLngLat = [location.longitude, location.latitude]
    marker.setLngLat(newLngLat)
    const newHeading = location ? location.heading : null
    if (checkVal(newHeading)) {
      const newRot = (90 + newHeading) % 360
      marker.setRotation(newRot)
    }
  }
}

const getNewLocations = ({ additions, locations, maxLength }) => {
  const rawLocations = [...locations, ...additions]
  const newLocations = removeDuplicateObjects(
    removeDuplicateObjects(rawLocations, '_id'),
    'timestamp'
  ).sort((a, b) => a.timestamp - b.timestamp)
  return newLocations.slice(Math.max(newLocations.length - maxLength, 0))
}

const useRecentLocationsAllDevices = ({
  deviceIds,
  maxLength,
  deviceMarkers
}) => {
  const [deviceLocations, setDeviceLocations] = useState([])
  const { data: lastLocations, loading } = useLastLocations({
    deviceIds,
    fetchPolicy: 'cache-and-network'
  })
  const { data: locationUpdate } = useLocationUpdated(deviceIds)
  const deviceId = useReactiveVar(selectedDeviceId)

  useEffect(() => {
    if (deviceMarkers) {
      const tempDeviceLocations = {}
      for (var i = 0; i < lastLocations.length; i++) {
        const tempLocations = lastLocations[i].locations
        const tempId =
          tempLocations && tempLocations[0] && tempLocations[0].deviceId
        const newLocations = getNewLocations({
          additions: tempLocations,
          locations:
            deviceLocations && deviceLocations[tempId]
              ? deviceLocations[tempId]
              : [],
          maxLength
        })
        tempDeviceLocations[tempId] = newLocations
        updateMarker({
          marker: deviceMarkers[tempId],
          location: newLocations[newLocations.length - 1]
        })
      }
      setDeviceLocations(tempDeviceLocations)
    }
  }, [lastLocations, deviceMarkers])

  useEffect(() => {
    if (deviceMarkers) {
      if (locationUpdate && locationUpdate.location) {
        const tempId = locationUpdate && locationUpdate.deviceId
        const tempDeviceLocations = { ...deviceLocations }
        const newLocations = getNewLocations({
          additions: [locationUpdate],
          locations:
            deviceLocations && deviceLocations[tempId]
              ? deviceLocations[tempId]
              : [],
          maxLength
        })
        tempDeviceLocations[tempId] = newLocations
        setDeviceLocations(tempDeviceLocations)
        tempId !== deviceId &&
          updateMarker({
            marker: deviceMarkers[tempId],
            location: newLocations[newLocations.length - 1]
          })
      }
    }
  }, [locationUpdate, deviceMarkers])

  return { deviceLocations, loading }
}

export default useRecentLocationsAllDevices
