import { FlexBox, ProcessingSpinner } from '../../../Components'
import React, { useRef, useState } from 'react'
import { Subsection, TableGraphQL, Text } from '../../../Components'
import { Metrics, useColors } from '../../../Themes'
import GeneralMetricsHistogram from '../../../Components/Custom/GeneralMetrics/GeneralMetricsHistogram'
import { Display } from '../../../Components/Typography'
import GeneralMetricsCard from '../../../Components/Custom/GeneralMetrics/GeneralMetricsCard'
import GeneralMetricsAvg from '../../../Components/Custom/GeneralMetrics/GeneralMetricsAvg'
import { useTotalDistances } from '../../../Graphql/Queries/Asset/useTotalDistances'
import { useAssetsAge } from '../../../Graphql/Queries/Asset/useAssetsAge'
import {
  abbreviateNumber,
  checkVal,
  thousandsSeparator
} from '../../../Helpers/Functions'
import { useTimeDriven } from '../../../Graphql/Queries/Asset/useTimeDriven'
import { useAverageSpeed } from '../../../Graphql/Queries/Asset/useAverageSpeed'
import BarChart from '../../../Components/Custom/BarChart'
import { useAppDimensions } from '../../../Graphql/Queries/useAppDimensions'
import { useAssetModels } from '../../../Graphql/Queries/Asset/useAssetModels'
import { useTotalCounts } from '../../../Graphql/Queries/useTotalCounts'
import AssetCorrelation from '../../../Components/Custom/GeneralMetrics/AssetCorrelation'

const getData = (events, groupBy, keys) => {
  return []
}

const AssetMetrics = ({ totalCountsData, totalCountsLoading }) => {
  const Colors = useColors()
  const [distanceInputValue, setDistanceInputValue] = useState('all')
  const [timeInputValue, setTimeInputValue] = useState('all')
  const [averageSpeedInputValue, setAverageSpeedInputValue] = useState('all')
  const {
    data: { average: totalDistancesAverage, coordinates: totalDistances },
    loading: totalDistancesLoading
  } = useTotalDistances({ period: distanceInputValue })
  const {
    data: { average: timeDrivenAverage, coordinates: timeDriven },
    loading: timeDrivenLoading
  } = useTimeDriven({ period: timeInputValue })
  const {
    data: { average: averageSpeedAverage, coordinates: averageSpeed },
    loading: averageSpeedLoading
  } = useAverageSpeed({ period: averageSpeedInputValue })
  const {
    data: { average: assetAgesAverage, coordinates: assetAges },
    loading: assetAgesLoading
  } = useAssetsAge()
  const keys = [
    'SPEEDING',
    'HARSH_BRAKING',
    'HARSH_ACCELERATION',
    'HARSH_CORNERING',
    'PERCENT_SPEEDING',
    'TRIP_DISTANCE',
    'TRAVEL_TIME',
    'DRIVING_TIME',
    'PHONE_MOTION',
    'CONTINUOUS_DRIVING',
    'NIGHT_DRIVING',
    'CRASH',
    'INCIDENT'
  ]
  const { mainContainerWidth } = useAppDimensions()
  const {
    data: { data: vehicleMakeData, modelKeys },
    loading: vehicleMakeDataLoading
  } = useAssetModels()
  return (
    <>
      <FlexBox direction='row' style={{ width: '100%' }}>
        <Subsection
          fillSpace
          style={{
            margin: Metrics.base
          }}
        >
          <GeneralMetricsCard
            title={'Distance Driven'}
            inputValue={distanceInputValue}
            setInputValue={setDistanceInputValue}
          >
            <FlexBox
              direction='column'
              fillSpace
              style={{
                width: '100%'
              }}
            >
              <GeneralMetricsAvg
                average={
                  checkVal(totalDistancesAverage) &&
                  thousandsSeparator(Math.round(totalDistancesAverage))
                }
                units={'km'}
                title={'distance driven'}
              />
              <GeneralMetricsHistogram
                data={totalDistances}
                loading={totalDistancesLoading}
              />
            </FlexBox>
          </GeneralMetricsCard>
        </Subsection>

        <Subsection
          fillSpace
          style={{
            margin: Metrics.base
          }}
        >
          <GeneralMetricsCard
            title={'Time Driven'}
            inputValue={timeInputValue}
            setInputValue={setTimeInputValue}
          >
            <FlexBox
              direction='column'
              fillSpace
              style={{
                width: '100%'
              }}
            >
              <GeneralMetricsAvg
                average={
                  checkVal(timeDrivenAverage) &&
                  thousandsSeparator(Math.round(timeDrivenAverage))
                }
                units={'hrs'}
                title={'time driven'}
              />
              <GeneralMetricsHistogram
                data={timeDriven}
                loading={timeDrivenLoading}
              />
            </FlexBox>
          </GeneralMetricsCard>
        </Subsection>
      </FlexBox>

      <FlexBox direction='row' style={{ width: '100%' }}>
        <Subsection
          fillSpace
          style={{
            margin: Metrics.base
          }}
        >
          <GeneralMetricsCard
            title={'Average Speed'}
            inputValue={averageSpeedInputValue}
            setInputValue={setAverageSpeedInputValue}
          >
            <FlexBox
              direction='column'
              fillSpace
              style={{
                width: '100%'
              }}
            >
              <GeneralMetricsAvg
                average={
                  checkVal(averageSpeedAverage) &&
                  averageSpeedAverage.toFixed(2)
                }
                units={'km/h'}
                title={'speed driven'}
              />
              <GeneralMetricsHistogram
                data={averageSpeed}
                loading={averageSpeedLoading}
              />
            </FlexBox>
          </GeneralMetricsCard>
        </Subsection>
        <Subsection
          fillSpace
          style={{
            margin: Metrics.base
          }}
        >
          <GeneralMetricsCard title={'Average Age'} hideTimeFilter>
            <FlexBox
              direction='column'
              fillSpace
              style={{
                width: '100%'
              }}
            >
              <GeneralMetricsAvg
                average={
                  checkVal(assetAgesAverage) && assetAgesAverage.toFixed(2)
                }
                units={'years'}
                title={'asset age'}
              />
              <GeneralMetricsHistogram
                data={assetAges}
                loading={assetAgesLoading}
              />
            </FlexBox>
          </GeneralMetricsCard>
        </Subsection>
      </FlexBox>
      <Subsection
        style={{
          margin: Metrics.base,
          width: '100%'
        }}
      >
        <Text
          color={Colors.textSecondary}
          style={{
            fontSize: 16,
            minWidth: 100,
            marginTop: Metrics.base * 2
          }}
        >
          Make and model breakdown
        </Text>
        <div
          style={{
            height: 400,
            width: Math.min(1500, mainContainerWidth - Metrics.base * 8)
          }}
        >
          {false ? (
            <FlexBox style={{ width: '100%', height: '100%' }}>
              <ProcessingSpinner />
            </FlexBox>
          ) : (
            <BarChart
              data={vehicleMakeData ? vehicleMakeData : []}
              keys={modelKeys}
              indexBy={'make'}
              colors={keys.map((key) => Colors[key])}
              xAxisTitle='Months'
              yAxisTitle='Number of events'
            />
          )}
        </div>
      </Subsection>
      {/*<FlexBox>
        <AssetCorrelation />
      </FlexBox>*/}
    </>
  )
}

export default AssetMetrics
