import React from 'react'
import {
  otherTypes,
  selectedEventId,
  selectedOtherEventId,
  selectedTripTotalEventId,
  tripTotalTypes
} from '../../../../Graphql/GraphQLClient'
import EventCardInTrip from './InTrip/EventCardInTrip'

export const handleEventCardOnClick = ({ type, id, navigateTo }) => {
  if (otherTypes.includes(type)) {
    selectedOtherEventId(id)
    return navigateTo({
      pathname: '/event-inbox/other',
      queryParams: [{ name: 'other-event-id', id }]
    })
  }
  if (tripTotalTypes.includes(type)) {
    selectedTripTotalEventId(id)
    return navigateTo({
      pathname: '/event-inbox/trip-totals',
      queryParams: [{ name: 'trip-total-event-id', id }]
    })
  }

  selectedEventId(id)
  return navigateTo({
    pathname: '/event-inbox/in-trip',
    queryParams: [{ name: 'event-id', id }]
  })
}

const EventCard = (props) => {
  return props.eventCategory === 'other' ? (
    <EventCardInTrip {...props} />
  ) : props.eventCategory === 'trip=totals' ? (
    <EventCardInTrip {...props} />
  ) : (
    <EventCardInTrip {...props} />
  )
}

export default EventCard
