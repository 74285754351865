import React from 'react'
import { useSelectedTabs } from '../../../../Graphql/Queries/useSelectedTabs'
import { useColors } from '../../../../Themes'
import { ToolTipMenuContainer, TooltipMenuItem } from '../Cards/Card'
import { useCreateCoachingEvent } from '../../../../Graphql/Mutations/useCreateCoachingEvent'
import {
  selectedEventId,
  selectedTripId,
  tripReplaySeekBarTime,
  tripReplayTime
} from '../../../../Graphql/GraphQLClient'
import { AssetLink } from '../Asset/AssetCardTTList'
import { DriverLink } from '../Driver/DriverCardTTList'

const handleAddToDriverCoaching = async ({
  driverId,
  eventId,
  createCoachingEvent
}) => {
  const createCoachingEventVariables = {
    driverId,
    eventId
  }
  await createCoachingEvent({
    variables: createCoachingEventVariables,
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (data) => {}
  })
}

const EventCardTTList = ({ eventId, data, pathname, navigateTo }) => {
  const Colors = useColors()
  const { tripHistory } = useSelectedTabs()
  const [createCoachingEvent, { createCoachingEventLoading }] =
    useCreateCoachingEvent()

  return (
    <ToolTipMenuContainer>
      {(pathname !== 'trip-history' || tripHistory !== 'trip-replay') &&
        data.startDate && (
          <TooltipMenuItem
            text='Trip Replay'
            iconName='videocam'
            Colors={Colors}
            onClick={() => {
              selectedEventId(data._id)
              selectedTripId(data.tripId)
              tripReplayTime(data.startDate.getTime())
              tripReplaySeekBarTime(data.startDate.getTime())
              navigateTo({
                pathname: '/trip-history/trip-replay',
                queryParams: [
                  { name: 'event-id', id: data._id },
                  { name: 'trip-id', id: data.tripId }
                ]
              })
            }}
          />
        )}
      {(pathname !== 'trip-history' || tripHistory !== 'event-details') &&
        data.startDate && (
          <TooltipMenuItem
            text='Timeline Charts'
            iconName='insert_chart'
            Colors={Colors}
            onClick={() => {
              selectedEventId(data._id)
              selectedTripId(data.tripId)
              tripReplayTime(data.startDate.getTime())
              tripReplaySeekBarTime(data.startDate.getTime())
              navigateTo({
                pathname: '/trip-history/timeline-charts',
                queryParams: [
                  { name: 'event-id', id: data._id },
                  { name: 'trip-id', id: data.tripId }
                ]
              })
            }}
          />
        )}
      {/*(pathname !== 'trip-history' || tripHistory !== 'video-replay') && (
        <TooltipMenuItem
          text='Video Clip'
          iconName='videocam'
          Colors={Colors}
          onClick={() => {
            selectedEventId(data._id)
            selectedTripId(data.tripId)
            navigateTo({
              pathname: '/trip-history/video-replay',
              queryParams: [
                { name: 'event-id', id: data._id },
                { name: 'trip-id', id: data.tripId }
              ]
            })
          }}
        />
        )*/}
      <AssetLink
        text='Asset'
        assetId={data.assetId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      <DriverLink
        text='Driver'
        driverId={data.driverId}
        Colors={Colors}
        navigateTo={navigateTo}
      />
      {data.driver && (
        <TooltipMenuItem
          text='Add to Driver Coaching'
          iconName='school'
          Colors={Colors}
          loading={createCoachingEventLoading}
          onClick={() => {
            handleAddToDriverCoaching({
              driverId: data.driver._id,
              eventId,
              createCoachingEvent
            })
          }}
        />
      )}
    </ToolTipMenuContainer>
  )
}

export default EventCardTTList
