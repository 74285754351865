import { useD3 } from '../CustomHooks/useD3'
import React, { useState } from 'react'
import * as d3 from 'd3'
import { Metrics, useColors } from '../../Themes'
import { useAppDimensions } from '../../Graphql/Queries/useAppDimensions'
import FlexBox from '../FlexBox'
import ProcessingSpinner from '../Processing/Spinner'
import EventCard, {
  handleEventCardOnClick
} from '../Custom/SelectionPanel/Event/EventCard'
import { ClickAwayListener } from '@material-ui/core'
import {
  otherTypes,
  tripTotalTypes,
  selectedEventId,
  selectedOtherEventId,
  selectedTripTotalEventId
} from '../../Graphql/GraphQLClient'
import { useReactiveVar } from '@apollo/client'

const GraphEventComponent = ({
  svgRef,
  id,
  index,
  StyledTooltip,
  Colors,
  strokeColor,
  selected,
  selectedId,
  setSelectedId,
  navigateTo,
  type
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(id === selectedId)
  const extraProps = selected
    ? {
        PopperProps: {
          disablePortal: true
        },
        disableFocusListener: true,
        disableHoverListener: true
      }
    : {}

  const handleUnselect = () => {
    svgRef.current &&
      d3
        .select(svgRef.current)
        .select(`.area-path-${id}`)
        .attr('stroke', strokeColor)
        .attr('stroke-width', 1)
    setSelectedId(null)
    setTooltipOpen(false)
  }
  const handleSelect = () => {
    svgRef.current &&
      d3
        .select(svgRef.current)
        .select(`.area-path-${selectedId}`)
        .attr('stroke', strokeColor)
    svgRef.current &&
      d3
        .select(svgRef.current)
        .select(`.area-path-${id}`)
        .attr('stroke', Colors.primary)
        .attr('stroke-width', 2)
    setSelectedId(id)
    setTooltipOpen(true)
  }
  return (
    <ClickAwayListener onClickAway={() => handleUnselect()}>
      <StyledTooltip
        key={id}
        /*
        disableFocusListener={selected ? true : false}
        disableHoverListener={selected ? true : false}
        PopperProps={{
          disablePortal: selected ? true : false
        }}
        */
        // {...extraProps}
        onOpen={() => setTooltipOpen(true)}
        onClose={() => setTooltipOpen(false)}
        open={tooltipOpen}
        interactive
        title={
          <EventCard
            eventId={id}
            backgroundColor={Colors.subsectionBackground}
            onClick={() => handleEventCardOnClick({ type, id, navigateTo })}
            navigateTo={navigateTo}
          />
        }
        placement='top'
      >
        <path
          className={`area-path-${id}`}
          onClick={() => {
            if (selected) {
              handleUnselect()
            } else {
              handleSelect()
            }
          }}
        />
      </StyledTooltip>
    </ClickAwayListener>
  )
}

const EventChart = ({
  data = [],
  startDate,
  endDate,
  scale,
  color,
  strokeColor,
  loading,
  StyledTooltip,
  navigateTo
}) => {
  // const [selectedId, setSelectedId] = useState(null)
  const selectedId = useReactiveVar(selectedEventId)
  const Colors = useColors()
  const { mainContainerWidth } = useAppDimensions()
  const chartWidth = mainContainerWidth - Metrics.base * 6 + scale * 100
  const chartHeight = 80
  const margin = { top: 15, right: 20, bottom: 20, left: 30 }
  const plotAreaX = margin.left
  const plotAreaY = margin.top
  const plotAreaWidth = chartWidth - margin.left - margin.right
  const plotAreaHeight = chartHeight - margin.top - margin.bottom
  const ref = useD3(
    (svg) => {
      const height = chartHeight
      const width = chartWidth

      const curve = d3.curveLinear
      const y = d3
        .scaleLinear()
        .domain([0, 1])
        .nice()
        .range([height - margin.bottom, margin.top])
      const x = d3
        .scaleTime()
        .domain([
          /*d3.min(data, (d) => d.startDate)*/ startDate,
          /*d3.max(data, (d) => d.endDate)*/ endDate
        ])
        .range([margin.left, width - margin.right])

      for (var i = 0; i < data.length; i++) {
        const eventAreaData = [
          {
            date: data[i].startDate,
            value: 1
          },
          {
            date: data[i].endDate,
            value: 1
          }
        ]

        const area = d3
          .area()
          .curve(curve)
          .x((d) => x(d.date))
          .y0(y(0))
          .y1((d) => y(d.value))

        svg
          .select(`.area-path-${data[i]._id}`)
          .datum(eventAreaData)
          .attr('fill', color)
          .attr('stroke', strokeColor)
          .attr('fill-opacity', data[i].value / 10)
          .attr('d', area)
      }

      const xAxis = (g) =>
        g
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .attr('color', Colors.secondaryOpacity2)
          .call(
            d3
              .axisBottom(x)
              .ticks(width / 80)
              .tickSizeOuter(0)
          )
      svg.select('.x-axis').call(xAxis)
    },
    [data.length, scale, startDate, endDate]
  )

  return (
    <div
      style={{
        position: 'relative',
        height: chartHeight,
        width: chartWidth + Metrics.base * 2,
        marginRight: '0px',
        marginLeft: '0px'
      }}
    >
      <svg
        ref={ref}
        style={{
          height: chartHeight,
          width: chartWidth + Metrics.base * 2,
          marginRight: '0px',
          marginLeft: '0px'
        }}
      >
        <rect
          x={plotAreaX}
          y={plotAreaY}
          width={plotAreaWidth}
          height={plotAreaHeight}
          fill={Colors.background}
          stroke={Colors.dividerColor}
        ></rect>
        {data.map((a, i) => (
          <GraphEventComponent
            key={a._id}
            svgRef={ref}
            id={a._id}
            index={i}
            StyledTooltip={StyledTooltip}
            Colors={Colors}
            strokeColor={strokeColor}
            selected={a._id === selectedId}
            selectedId={selectedId}
            setSelectedId={(id) => selectedEventId(id)}
            navigateTo={navigateTo}
            type={a.type}
          />
        ))}
        <line className='rule-x' />
        <line className='rule-y' />
        <g className='x-axis' />
        <g className='y-axis' />
      </svg>
      {loading && (
        <FlexBox
          style={{
            position: 'absolute',
            backgroundColor: Colors.backgroundOpacity2,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0
            // backgroundColor: 'red'
          }}
        >
          <ProcessingSpinner />
        </FlexBox>
      )}
    </div>
  )
}

export default EventChart
