import React, { useState, useEffect } from 'react'
import FlexBox from '../FlexBox'
import { Images, Metrics, useColors } from '../../Themes'
import Text from '../Text'
import { useEvent } from '../../Graphql/Queries/Event/useEvent'
import { checkVal } from '../../Helpers/Functions'

const msToTime = (duration) => {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

const EventTimestamp = ({ eventId, startTime, endTime }) => {
  const { data: event } = useEvent(eventId)
  const [timeInTrip, setTimeInTrip] = useState('')

  useEffect(() => {
    if (event && checkVal(event.startDate)) {
      let timeIn = event.startDate.getTime() - new Date(startTime).getTime()
      const time = msToTime(timeIn)
      setTimeInTrip(time)
    }
  }, [event])

  return (
    <Text marginTop={3} style={{ marginLeft: 50, marginBottom: Metrics.base }}>
      {timeInTrip}
    </Text>
  )
}

export default EventTimestamp
