import React from 'react'
// import Card from './Card'

const CardsList = ({ items, itemName, children, insertedTopCard }) => {
  return (
    <>
      {/*<AutoSizer>
    {({ height, width }) => (
      <FixedSizeList
        height={height}
        width={width}
        itemSize={91 + Metrics.base * 2}
        itemCount={200}
      >
        {({ style, index }) => (
          <Card
            style={style}
            data={data[index]}
            index={index}
            key={index}
            selected={data[index].id === selectedId}
            borderBottom={
              index + 1 < data.length &&
              data[index + 1] &&
              data[index + 1].id !== selectedId
            }
            setSelected={setSelected}
          />
        )}
      </FixedSizeList>
    )}
          </AutoSizer>*/}
      {insertedTopCard && insertedTopCard}
      {items && items.length > 0 ? (
        items.map((item, index) =>
          children ? children({ items, item, index }) : ''
        )
      ) : (
        <div>{itemName}</div>
      )}
    </>
  )
}

export default CardsList
