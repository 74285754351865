import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const REMOVE_ASSETS_FROM_GROUP = gql`
  mutation removeAssetsFromGroup($id: String!, $assetIds: [String!]) {
    removeAssetsFromGroup(id: $id, assetIds: $assetIds) {
      _id
    }
  }
`
export const useRemoveAssetsFromGroup = () => {
  const [removeAssets, { loading }] = useMutation(REMOVE_ASSETS_FROM_GROUP, {
    mutationName: 'removeAssetsFromGroup',
    refetchQueries: ['assetsPaginated'],
    successMessage: 'Assets removed successfully',
    errorMessage: 'Failed to remove assets'
  })

  return [removeAssets, { loading }]
}
