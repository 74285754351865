import React, { useState } from 'react'
import { FlexBox, Subsection, TableGraphQL, Text } from '../../Components'
import Button from '../../Components/Button'
import { handleLogout } from '../../Components/Header'
import LargeDivider from '../../Components/LargeDivider'
import { Heading, Subheading } from '../../Components/Typography'
import { GET_ASSETS } from '../../Graphql/Queries/Asset/useAssets'
import { useUser } from '../../Graphql/Queries/useUser'
import { Metrics, useColors } from '../../Themes'

const driverColumns = [
  {
    Header: 'Display Id',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Name',
    accessor: 'name',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Type',
    accessor: 'type',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'VIN',
    accessor: 'vin',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Registration',
    accessor: 'registrationNum',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const cleanDetails = ({ data }) => {
  const temp = data.assets.map((asset) => {
    const name = asset.name ? asset.name : '-'
    const assetType = asset.assetType ? asset.assetType : '-'
    const displayId = asset.displayId ? asset.displayId : '-'
    const vin = asset.vin ? asset.vin : '-'
    const registrationNum = asset.registrationNum ? asset.registrationNum : '-'

    return {
      ...asset,
      displayId,
      name,
      assetType,
      vin,
      registrationNum
    }
  })
  return temp
}

const Users = ({ setAssetId }) => {
  const { data: user } = useUser()
  const username = user && user.username ? user.username : ''
  const fullName = user && user.fullName ? user.fullName : ''
  const userType = user && user.userType ? user.userType : ''
  const isActive = user && user.isActive ? user.isActive : ''
  const Colors = useColors()
  return (
    <FlexBox fillSpace style={{ width: '100%' }}>
      <Subsection style={{ minWidth: 500 }}>
        <Heading marginTop={4} marginBottom={2}>
          User: {fullName}
        </Heading>
        <LargeDivider />
        <Subheading marginTop={4} marginBottom={2}>
          {username}
        </Subheading>
        <Text marginBottom={1}>User Type: {userType}</Text>
        <Text marginBottom={4}>Active: {isActive ? 'TRUE' : 'FALSE'}</Text>
        <LargeDivider />
        <Button
          primary
          id='logout-button'
          style={{
            marginTop: Metrics.base * 4,
            marginBottom: Metrics.base * 4
          }}
          onClick={() => handleLogout()}
          textColor='white'
          title='Log out'
          background={Colors.primary}
        />
      </Subsection>
    </FlexBox>
  )
}

export default Users
