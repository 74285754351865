import { StyleSheet } from 'aphrodite'
import { Colors } from '../../Themes'
export default StyleSheet.create({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0,
    backgroundColor: 'transparent',

    ':focus': {
      outline: 'none'
    }
  }
})
