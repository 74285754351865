import gql from 'graphql-tag'
import { useMutation } from '../../Components/CustomHooks/useMutation'

export const SET_USER_NEW_PASSWORD_EMAIL = gql`
  mutation setNewUserPasswordEmail($token: String!, $password: String!) {
    setNewUserPasswordEmail(token: $token, password: $password)
  }
`
export const useSetNewUserPasswordEmail = () => {
  const [updatePassword, { loading }] = useMutation(
    SET_USER_NEW_PASSWORD_EMAIL,
    {
      mutationName: 'setNewUserPasswordEmail',
      successMessage: 'Password updated',
      errorMessage: 'Failed to update password'
    }
  )

  return [updatePassword, { loading }]
}
