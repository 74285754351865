import React, { useState, useEffect } from 'react'
import { Table } from '../../../Table'
import { useTrips } from '../../../../Graphql/Queries/Trip/useTrips'
import { checkVal, thousandsSeparator } from '../../../../Helpers/Functions'
import { FlexBox } from '../../..'
import { Metrics, useColors } from '../../../../Themes'
import TripFilters, { handleClearFilters } from './TripFilters'
import { NetworkStatus } from '@apollo/client'
import { Heading } from '../../../Typography'
import Button from '../../../Button'

const driverColumns = [
  {
    Header: 'Display Id',
    accessor: 'displayId',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'Start Address',
    accessor: 'startAddress',
    Aggregated: ({ cell: { value } }) => `-`
  },
  {
    Header: 'End Address',
    accessor: 'endAddress',
    Aggregated: ({ cell: { value } }) => `-`
  }
]

const _handleRowClick = ({ row, setTripId }) => {
  setTripId(row.original._id)
}

const TripSelectionTable = ({ setTripId }) => {
  const [dataState, setData] = useState([])
  const [selectedObject, setSelectedObject] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPageCount] = useState(1)
  const { data: tripsData, totalCount, fetchMore, networkStatus } = useTrips({})
  const Colors = useColors()

  const tripsLoading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables

  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  useEffect(() => {
    if (tripsData && tripsData.length > 0) {
      setData(tripsData)
    } else {
      setData([])
    }
  }, [tripsData])

  useEffect(() => {
    if (
      checkVal(totalCount) &&
      totalCount > 0 &&
      checkVal(pageSize) &&
      pageSize > 0
    ) {
      const tempPageCount = Math.ceil(totalCount / pageSize)
      setPageCount(tempPageCount)
      setPageIndex(Math.min(tempPageCount - 1, pageIndex))
    } else {
      setPageCount(1)
      setPageIndex(0)
    }
  }, [totalCount, pageSize])

  return (
    <Table
      handleRowClick={(row) => _handleRowClick({ row, setTripId })}
      loading={tripsLoading}
      fetchingMore={fetchingMore}
      fetchMore={fetchMore}
      totalCount={totalCount}
      selectedObject={selectedObject}
      setSelectedObject={setSelectedObject}
      pageIndex={pageIndex}
      pageCount={pageCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      manualPagination={true}
      setPageIndex={setPageIndex}
      columns={driverColumns}
      hideColumnFilters
      hideNewButton
      hideAssignButton
      showRefreshButton
      dataState={dataState}
      setData={setData}
      title={'Trips'}
      sortBy={[{ _id: '_id' }]}
      defaultPageSize={10}
      renderHeading={() => (
        <FlexBox
          style={{
            width: '100%',
            height: Metrics.base * 10,
            padding: Metrics.base * 2,
            justifyContent: 'space-between'
          }}
        >
          <Heading>
            {checkVal(totalCount)
              ? `${thousandsSeparator(totalCount)} Trips`
              : 'Trips'}
          </Heading>
          <Button
            condensed
            title='Reset'
            onClick={() => {
              handleClearFilters()
            }}
            textColor='white'
            background='red'
            style={{ marginRight: Metrics.base * 2 }}
          />
        </FlexBox>
      )}
      subHeaderContent={
        <FlexBox
          fillSpace
          style={{
            width: '100%',
            justifyContent: 'flex-start',
            borderTop: `1px solid ${Colors.dividerColor}`,
            paddingTop: Metrics.base * 2
          }}
        >
          <TripFilters direction='row' maxInputWidth={400} />
        </FlexBox>
      }
    />
  )
}

export default TripSelectionTable
