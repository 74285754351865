import { useEffect, useState } from 'react'
import { useQuery } from '../../../Components/CustomHooks/useQuery'
import gql from 'graphql-tag'

const baseAssetFragment = gql`
  fragment baseAssetFragment on Asset {
    _id
    organization {
      _id
      name
    }
    driver {
      _id
      firstName
      lastName
      idNumber
    }
    device {
      _id
      deviceId
      hasVVT
    }
    name
    assetNo
    assetType
    subVehicleType
    maxSpeed
    fuelType
    vehicleMake
    vehicleModel
    vehicleYear
    dateOfPurchase
    registrationNum
    licenseNumber
    vin
    engineNumber
    driven
    vehicleDescription
    tare
    gvm
    nvc
    registeringAuthority
    controlNumber
    doe
    prdpCategory
    vehicleColour
    specialMarkings
    branch
    isActive
    displayId
  }
`

export const assetFragment = gql`
  fragment assetFragment on Asset {
    ...baseAssetFragment
    #linkedAssets {
    #  ...baseAssetFragment
    #}
  }
  ${baseAssetFragment}
`

export const GET_ASSET = gql`
  query asset($id: String!) {
    asset(id: $id) {
      ...assetFragment
    }
  }
  ${assetFragment}
`

export const assetDataTransform = ({ data }) => {
  const driver = data ? data.driver : null
  const device = data ? data.device : null
  const linkedAssets =
    data && data.linkedAssets
      ? data.linkedAssets.map((linked) => {
          return linked._id
        })
      : null
  const name = data.name ? data.name : '-'
  const assetType = data.assetType ? data.assetType : '-'
  const displayId = data.displayId ? data.displayId : '-'
  const vin = data.vin ? data.vin : '-'
  const registrationNum = data.registrationNum ? data.registrationNum : '-'
  return {
    ...data,
    name,
    assetType,
    displayId,
    vin,
    registrationNum,
    driverId: driver ? driver._id : null,
    driverName: driver ? `${driver.firstName} ${driver.lastName}` : null,
    deviceId: device ? device._id : null,
    hasVVT: device ? device.hasVVT : null,
    orgId: data.organization ? data.organization._id : null,
    linkedAssets: linkedAssets,
    VVTdeviceId: device && device.hasVVT ? device.deviceId : null,
    selected: false
  }
}

export const useAsset = (id) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState([])
  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery(GET_ASSET, {
    variables: { id },
    queryName: 'asset',
    errorMessage: 'Failed to fetch Asset',
    dataTransform: (data) => assetDataTransform({ data }),
    skip: !id
  })

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    queryData && setData(queryData)
  }, [queryData])

  return { data, loading, refetch }
}
