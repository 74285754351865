import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Icon, Text } from '../'
import { Colors } from '../../Themes'
import Styles from './Styles'
export default class IconButton extends Component {
  render() {
    const {
      text,
      type,
      iconName,
      iconColor,
      iconSize,
      iconStyle,
      onClick,
      disabled,
      buttonStyles
    } = this.props
    return (
      <button
        {...this.props}
        cy={iconName}
        type={type || null}
        disabled={disabled}
        onClick={onClick}
        className={css(Styles.button)}
        style={{
          ...buttonStyles,
          cursor: disabled ? 'disabled' : 'pointer'
        }}
      >
        <Icon
          name={iconName}
          color={disabled ? Colors.dividerColor : iconColor || Colors.primary}
          size={iconSize}
          style={iconStyle}
        />
        {text ? <Text marginLeft={1}>{text}</Text> : null}
      </button>
    )
  }
}

IconButton.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  iconStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
}
